<template>
  <div>
    <p class="card-title mt-3">{{ records.length }} Previous Client Tests</p>

    <ul class="list-group">

      <template v-for="(record, record_index) in records">

        <li :key="'client_test_'+record_index" class="list-group-item">

          <a :href="'/tests/'+record.uuid">Test ID {{ record.test_id || record.uuid }}</a>

          <template v-if="record.language">
            <p class="m-0">Language {{ record.language }}</p>
          </template>

          <template v-if="record.completed_at">
            <br><small class="text-muted">Completed {{ record.completed_at | date }}</small>
          </template>

          <template v-if="record.created_at">
            <br><small class="text-muted">Created {{ record.created_at | datetime }}</small>
          </template>

          <template v-if="record.template">
            <test-template-card :template="record.template" />
          </template>

        </li>

      </template>

    </ul>

  </div>
</template>
<script>
export default {

props: ['client'],

data() {
    return {
        processing: false,
        base_url: '/tests',
        records: []
    }
},

created() {
    this.getRecords();
},

methods: {

   getRecords() {

    if(!this.client) {
      return;
    }

    this.$http.get('/tests?sort=created_at&sortDirection=descclient='+this.client)
    .then(response => {
      if(response && response.data) {

        var self = this;

        response.data.forEach(function(r) {
          if(self.$route.params.id == r.uuid) {
            return;
          }

          self.records.push(r);
        })

        this.processing = false;

      }
    })

   },
}

}
</script>