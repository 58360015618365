<template>
  <div>

      <div id="main" class="row">
        <div class="col-md-9 mx-auto">

          <div class="row mb-3">
            <div class="col-12">

              <div class="progress" :hidden="progress == 0">
                <div class="progress-bar" :class="setProgressClass()" role="progressbar" :style="'width:'+progress+'%;'" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"><template v-if="progress == 100">🙌</template> {{ progress }}%</div>
              </div>

            </div><!--Col-->
          </div><!--Row-->

          <div class="row">

            <div class="col-6">

            <h1>{{ $route.meta.title }}</h1>

            </div><!--Col-->

            <div class="col-6 text-right">

              <div class="btn-group">
              <router-link tag="button" :to="{name: 'setup.index'}" class="btn btn-outline-secondary"><i class="fa fa-rocket-launch"></i> Agency Setup</router-link>
              <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
              </div>

            </div><!--Col-->

          </div><!--Row-->

        <div class="card">
          <div class="card-body">

            <router-link
              class="h4"
              :to="{ name: 'settings.index' }"
            >
              <i class="fa fa-cog"></i> General Settings
            </router-link>

            <template v-if="settings && settings.name">

            <p class="lead">The general settings used for the agency <em>{{ settings.name }}</em>.</p>

            <template v-for="(field, index) in settings_fields">
              <p :key="'field_'+index">
                <strong class="text-uppercase mr-1">{{ field }}</strong>
                <template v-if="settings[field]">
                  {{ settings[field] }}
                </template>
                <template v-else>
                  <span class="badge badge-pill badge-danger">MISSING</span>
                </template>
              </p>
            </template>

            <p>
              <template v-if="settings.languages && settings.languages.length > 0">

                <p class="m-0"><strong class="text-uppercase mr-1"><i class="fa fa-language"></i> {{ settings.languages.length }} of {{ languages.length}} Languages Enabled</strong></p>

                <template v-for="(language, index) in settings.languages">
                  <span
                    :key="'language_'+index"
                    class="badge badge-pill badge-secondary my-1"
                  >
                {{ language.label }}</span>
                </template>
              </template>

              <template v-if="!settings.languages || !settings.languages.length">
                <span class="badge badge-pill badge-danger">Multilingual disabled</span>
              </template>
            </p>

            </template>

            <hr>

            <template v-if="settings.send_mode">

            <template v-if="settings.send_mode == 'live'">
              <p class="mt-0 h5"><i class="fa fa-check-double text-success"></i> Send Mode: LIVE</p>
            </template>
            <template v-if="settings.send_mode == 'simulate'">
              <p class="mt-0 h5"><i class="fa fa-circle-xmark text-primary"></i> Send Mode: SIMULATE</p>
            </template>

            <p class="lead m-0">
              The Send Mode determines whether outbound messages are sent live or simulated (the message is not actually sent to the recipient).
            </p>

            <template v-if="settings.send_mode == 'simulate' && $root.current_region != 'staging'">
              The current send mode is simulate. To switch to live: click <span class="badge badge-pill badge-primary">SIMULATE</span> below, then click <strong>Switch to Live Mode</strong>.
            </template>

              <template v-if="settings.send_mode == 'simulate' && $root.current_region == 'staging'">
                The current region is staging, which is always in simulate mode.
              </template>

            <template v-if="settings.send_mode == 'live'">
              The current send mode is live. To switch to simulate: click <span class="badge badge-pill badge-success">LIVE</span> below, then click <strong>Switch to Simulate Mode</strong>.
            </template>

            <hr>

            </template>

            <router-link
              class="h4"
              :to="{ name: 'agency_phones.index' }"
            >
              <i class="fa fa-mobile-notch"></i> Agency Phones
            </router-link>

            <p class="lead">The phone numbers used to send and receive texts and calls.</p>

            <template v-if="agency_phones && agency_phones.length > 0">
              <p class="m-0 text-muted">
                {{ agency_phones.length }} Total
              </p>

              <ul class="list-group mb-3">
                <template v-for="(agency_phone, index) in agency_phones">
                  <router-link
                      tag="a"
                      :key="'phone_'+index"
                      class="list-group-item list-group-item-action"
                      :to="{name: 'agency_phones.edit', params: {id: agency_phone.uuid}}"
                  >

                  <strong>{{ agency_phone.phone_number }}</strong>

                  <template v-if="agency_phone.name != agency_phone.phone_number">
                    ({{ agency_phone.name }})
                  </template>

                    <p class="text-muted small m-0 mt-3">Inbound Settings</p>
                    <template v-if="agency_phone.forward_calls">
                      <p class="m-0">Forward Calls {{ agency_phone.forward_calls }}</p>
                    </template>

                      <p class="m-0">Forward to Assigned Users

                        <template v-if="agency_phone.forward_assigned == 0">
                          <i class="fa fa-times-circle text-danger"></i>
                        </template>
                        <template v-else>
                        <i class="fa fa-check-circle text-success"></i>
                        </template>

                      </p>

                    <template v-if="agency_phone.forward_roles">
                      <p class="m-0">Forward Roles {{ agency_phone.forward_roles }}</p>
                    </template>

                  </router-link>
                </template>
              </ul>
            </template>

            <hr>

            <router-link
              class="h4"
              :to="{ name: 'agency_emails.index' }"
            >
            <i class="fa fa-envelope"></i> Agency Emails
            </router-link>

            <p class="lead">The from email addresses used to send and receive email messages.</p>

            <template v-if="agency_emails && agency_emails.length > 0">
              <p class="m-0 text-muted">
                {{ agency_emails.length }} Total
              </p>

              <ul class="list-group mb-3">
                <template v-for="(agency_email, index) in agency_emails">
                  <router-link
                      tag="a"
                      :key="'email_'+index"
                      class="list-group-item list-group-item-action"
                      :to="{name: 'agency_emails.edit', params: {id: agency_email.uuid}}"
                  >
                    <strong>{{ agency_email.email }}</strong>

                    <template v-if="agency_email.name != agency_email.email">
                      ({{ agency_email.name }})
                    </template>

                    <p class="text-muted small m-0">Inbound Settings</p>

                    <template v-if="agency_email.forward">
                      <p class="m-0">Forward Emails {{ agency_email.forward }}</p>
                    </template>

                    <template v-if="agency_email.forward_roles">
                      <p class="m-0">Forward Roles {{ agency_email.forward_roles }}</p>
                    </template>

                    <template v-if="agency_email.auto_reply">
                      <p class="m-0">Auto Reply <p class="outbound">{{ agency_email.auto_reply }}</p></p>
                    </template>

                  </router-link>
                </template>
              </ul>
            </template>

            <template v-if="!agency_emails || !agency_emails.length">
              <div class="alert alert-danger">
                No agency emails configured.
              </div>
            </template>

            <hr>

              <h4><a href="https://console.ecourtdate.com/domains" target="_blank"><i class="fa fa-lock-hashtag"></i> Domains</a></h4>

            <p class="lead">Configure custom domains to use throughout eCourtDate.</p>

            <template v-if="domains && domains.length > 0">
              <p class="m-0 text-muted">
                {{ domains.length }} Total
              </p>

              <ul class="list-group mb-3">
                <template v-for="(domain, index) in domains">
                 {{ domain.domain }}
                </template>
              </ul>
            </template>

            <template v-if="!domains || !domains.length">
              <div class="alert alert-danger">
                No domains configured.
              </div>
            </template>

            <hr>

            <router-link
              class="h4"
              :to="{ name: 'users.index' }"
            >
              <i class="fa fa-users"></i> Users
            </router-link>

            <p class="lead">Add internal users who can access eCourtDate applications.</p>

            <template v-if="users && users.length > 0">
              <p class="m-0 text-muted">
                {{ users.length }} Total
              </p>

              <ul class="list-group mb-3">
                <template v-for="(user, index) in users">
                  <li
                    :key="'user_'+index"
                    class="list-group-item list-group-item-action"
                  >
                    <a :href="'/users/'+user.uuid">{{ user.name }} {{ user.email }}</a> 
                  </li>
                </template>
              </ul>
            </template>

            <template v-if="!users || !users.length">
              <div class="alert alert-danger">
                No users configured.
              </div>
            </template>


            <hr>

            <router-link
              class="h4"
              :to="{ name: 'roles.index' }"
            >
             <i class="fa fa-shield-check"></i> Roles
            </router-link>

            <p class="lead">Assign roles to users to restrict user access based on configurable permissions.</p>

            <template v-if="roles && roles.length > 0">
              <p class="m-0 text-muted">
                {{ roles.length }} Total
              </p>

              <ul class="list-group mb-3">
                <template v-for="(role, index) in roles">
                  <li
                    :key="'role_'+index"
                    class="list-group-item"
                  >
                    <a :href="'/roles/'+role.uuid">{{ role.name }}</a>
                  </li>
                </template>
              </ul>
            </template>

            <template v-if="!roles || !roles.length">
              <div class="alert alert-danger">
                No roles configured.
              </div>
            </template>

            <hr>

            <router-link
              class="h4"
              :to="{ name: 'locations.index' }"
            >
             <i class="fa fa-building-columns"></i> Locations
            </router-link>

            <p class="lead">Link physical locations to messages and embed interactive maps in portal pages.</p> 

            <template v-if="locations && locations.length > 0">
              <p class="m-0 text-muted">
                {{ locations.length }} Total
              </p>

              <ul class="list-group mb-3">
                <template v-for="(location, index) in locations">
                  <router-link
                    tag="li"
                    :key="'location_'+index"
                    class="list-group-item list-group-item-action link-hover"
                    :to="{name: 'locations.edit', params: {id: location.uuid}}"
                  >
                  {{ location.name || location.uuid }}

                    <template v-if="location.location_reference">
                      <br><span class="badge badge-secondary">{{ location.location_reference }}</span>
                    </template>

                    <br><small>{{ location.address }} {{ location.city }} {{ location.state }} {{ location.zip }}</small>
                  </router-link>
                </template>
              </ul>
            </template>

            <template v-if="!locations || !locations.length">
              <div class="alert alert-danger">
                No locations configured.
              </div>
            </template>

            <hr>

            <router-link
              class="h4"
              :to="{ name: 'portals.index' }"
            >
             <i class="fa fa-browser"></i> Portals
            </router-link>

            <p class="lead">Launch web-based portals that can work standalone or automatically linked to outbound messages.</p>

            <template v-if="portals && portals.length > 0">
              <p class="m-0 text-muted">
                {{ portals.length }} Total
              </p>

              <ul class="list-group mb-3">
                <template v-for="(portal, index) in portals">
                  <li
                    :key="'portal_'+index"
                    class="list-group-item"
                  >
                    <a :href="'/portals/'+portal.uuid">{{ portal.name }}</a> <template v-if="!portal.name">
                      <span class="badge badge-pill badge-danger">Missing Portal Name</span>
                    </template>
                    <br>
                    <template v-if="portal.domain == 'ecase.io'">
                      <span class="badge badge-pill badge-primary">Web Portal</span>
                    </template>
                    <template v-if="portal.domain == 'ecourtpayment.com'">
                      <span class="badge badge-pill badge-primary">Payment Portal</span>
                    </template>
                    <template v-if="portal.link">
                      <span class="badge badge-pill badge-secondary"><a :href="'https://'+portal.link+'.'+portal.domain">{{ portal.link }}.{{ portal.domain }}</a></span>
                    </template>
                  </li>
                </template>
              </ul>
            </template>

            <template v-if="!portals || !portals.length">
              <div class="alert alert-danger">
                No portals configured.
              </div>
            </template>

            <hr>

            <router-link
              class="h4"
              :to="{ name: 'statuses.index' }"
            >
             <i class="fa fa-ballot-check"></i> Statuses
            </router-link>

            <p class="lead">Configure statuses to disable messages or trigger specific Auto Messages.</p>

            <template v-if="statuses && statuses.length > 0">
              <p class="m-0 text-muted">
                {{ statuses.length }} Total
              </p>

              <ul class="list-group mb-3" style="max-height:500px;overflow-y:auto">
                <template v-for="(status, index) in statuses">
                    <router-link
                        tag="a"
                        :key="'status_'+index"
                        class="list-group-item list-group-item-action"
                        :to="{name: 'statuses.edit', params: {id: status.uuid}}"
                    >
                        ({{ status.name }})

                      <template v-if="status.disable_messages == 1">
                        <span class="badge badge-pill bg-danger text-uppercase text-white">Messages Disabled</span>
                      </template>

                      <template v-if="status.archive == 1">
                        <span class="badge badge-pill bg-danger text-uppercase text-white">Auto-Archive</span>
                      </template>

                      <template v-if="status.fta == 1">
                        <span class="badge badge-pill bg-danger text-uppercase text-white">Fail-to-Appear</span>
                      </template>

                    </router-link>
                </template>
              </ul>
            </template>

            <template v-if="!statuses || !statuses.length">
              <div class="alert alert-danger">
                No statuses configured.
              </div>
            </template>

            <hr>

            <router-link
              class="h4"
              :to="{ name: 'flows.index' }"
            >
             <i class="fa fa-code"></i> Flows
            </router-link>

            <p class="lead">Send a sequence of messages based on an event, case, or payment.</p>

            <template v-if="flows && flows.length > 0">
              <p class="m-0 text-muted">
                {{ flows.length }} Total
              </p>

              <ul class="list-group mb-3">
                <template v-for="(flow, index) in flows">
                    <router-link :key="'flow_'+index" tag="li" :to="{ 'name': 'flows.edit', 'params': {'id': flow.uuid} }" class="list-group-item list-group-item-action">
                      {{ flow.name }}
                    </router-link>
                </template>
              </ul>
            </template>

            <template v-if="!flows || !flows.length">
              <div class="alert alert-danger">
                No flows configured.
              </div>
            </template>

            <hr>

            <router-link
              class="h4"
              :to="{ name: 'auto_messages.index' }"
            >
              <i class="fa fa-clipboard"></i> Auto Messages
            </router-link>

            <p class="lead">Send one-off messages based on configurable triggers. Can also be used as a canned template when sending a manual one-off or bulk one-off.</p>

            <template v-if="auto_messages && auto_messages.length > 0">
              <p class="m-0 text-muted">
                {{ auto_messages.length }} Total
              </p>

              <ul class="list-group mb-3">
                <template v-for="(auto_message, index) in auto_messages">
                  <router-link :key="'auto_message_'+index" tag="li" :to="{ 'name': 'auto_messages.edit', 'params': {'id': auto_message.uuid} }" class="list-group-item list-group-item-action">
                    {{ auto_message.name }}
                  </router-link>
                </template>
              </ul>
            </template>

            <template v-if="!auto_messages || !auto_messages.length">
              <div class="alert alert-danger">
                No auto messages configured.
              </div>
            </template>

            <router-link
                class="h4"
                :to="{ name: 'upload_templates.index'}"
            >
              <i class="fa fa-file-upload"></i> Upload Templates
            </router-link>

            <p class="lead">Configure upload templates to upload bulk data from flat files.</p>

            <template v-if="upload_templates && upload_templates.length > 0">
              <p class="m-0 text-muted">
                {{ upload_templates.length }} Total
              </p>

              <ul class="list-group mb-3">
                <template v-for="(upload_template, index) in upload_templates">
                  <li
                      :key="'upload_template_'+index"
                      class="list-group-item"
                  >
                    <router-link :to="{ 'name': 'upload_templates.edit', 'params': {'id': upload_template.uuid} }">
                      {{ upload_template.name }}
                    </router-link>
                  </li>
                </template>
              </ul>
            </template>


            <hr>

            <hr>

            <router-link
                class="h4"
                :to="{ name: 'dashboards.index' }"
            >
              <i class="fa fa-line-chart"></i> Dashboards
            </router-link>

            <p class="lead">Launch web-based analytical dashboards to track real-time metrics.</p>

            <template v-if="dashboards && dashboards.length > 0">
              <p class="m-0 text-muted">
                {{ dashboards.length }} Total
              </p>

              <ul class="list-group mb-3">
                <template v-for="(dashboard, index) in dashboards">
                  <li
                      :key="'dashboard_'+index"
                      class="list-group-item"
                  >
                    <router-link :to="{ 'name': 'dashboards.edit', 'params': {'id': dashboard.uuid} }">
                      {{ dashboard.name }}
                    </router-link>
                  </li>
                </template>
              </ul>
            </template>

            <template v-if="!dashboards || !dashboards.length">
              <div class="alert alert-danger">
                No dashboards configured.
              </div>
            </template>

            <hr>

            <router-link
                class="h4"
                :to="{ name: 'reports.index' }"
            >
              <i class="fa fa-clipboard"></i> Reports
            </router-link>

            <p class="lead">Export data from eCourtDate on an automatic schedule. Share reports with users, roles, or servers.</p>

            <template v-if="reports && reports.length > 0">
              <p class="m-0 text-muted">
                {{ reports.length }} Total
              </p>

              <ul class="list-group mb-3">
                <template v-for="(report, index) in reports">
                  <li
                      :key="'report_'+index"
                      class="list-group-item list-group-item-action"
                  >
                    <router-link :to="{ 'name': 'reports.edit', 'params': {'id': report.uuid} }">
                      {{ report.name }} {{ report.auto_export }}
                      <template v-if="report.last_active">
                        <span class="badge badge-pill badge-success">{{ report.last_active | datetime }}</span>
                      </template>
                      <template v-if="!report.last_active">
                        <span class="badge badge-pill badge-danger">Never Exported</span>
                      </template>
                    </router-link>
                  </li>
                </template>
              </ul>
            </template>

            <template v-if="!reports || !reports.length">
              <div class="alert alert-danger">
                No reports configured.
              </div>
            </template>

            <hr>

            <router-link
                class="h4"
                :to="{ name: 'files.index' }"
            >
              <i class="fa fa-cloud-arrow-down"></i> Files
            </router-link>

            <p class="lead">Manage a multimedia file library, share publicly or privately with clients, and require eSignatures.</p>

            <template v-if="files && files.length > 0">
              <p class="m-0 text-muted">
                {{ files.length }} Total
              </p>

              <ul class="list-group mb-3">
                <template v-for="(file, index) in files">
                  <li
                      :key="'file_'+index"
                      class="list-group-item list-group-item-action"
                  >
                    <router-link :to="{ 'name': 'files.edit', 'params': {'id': file.uuid} }">
                      {{ file.name }}
                    </router-link>
                  </li>
                </template>
              </ul>
            </template>

            <template v-if="!files || !files.length">
              <div class="alert alert-danger">
                No files uploaded.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import confetti from "canvas-confetti";

export default {

  data () {
    return {
      processing: false,
      downloading: false,
      progress: 0,
      settings_fields: ['name', 'phone', 'email', 'website', 'address', 'city', 'state', 'zip', 'timezone'],
      settings: {
        name: '',
        phone: '',
        email: ''
      },
      agency_phones: {},
      agency_emails: {},
      users: {},
      roles: {},
      locations: {},
      portals: {},
      flows: {},
      auto_messages: {},
      statuses: {},
      upload_templates: [],
      dashboards: [],
      reports: [],
      languages: [],
      domains: [],
      files: []
    }
  },

  created() {
    this.getSettings();
    this.getLanguages();
  },

  methods: {

    setProgressClass() {

      if(this.progress <= 10) {
        return 'bg-danger';
      }

      if(this.progress <= 20) {
        return 'bg-secondary';
      }

      if(this.progress <= 40) {
        return 'bg-primary';
      }

      if(this.progress <= 80) {
        return 'bg-info';
      }

      if(this.progress == 100) {
        return 'bg-success';
      }
    },

    getLanguages () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
          .then(response => {
            if(response && response.data) {
              this.languages = response.data;
            }
          })
    },

    getSettings() {
      this.processing = true;
      this.$http.get('/settings')
      .then(response => {
        if(response && response.status) {

          this.processing = false;

          if(response.data) {
            this.settings = response.data;            
          }

          if(this.settings.phone && this.settings.email && this.settings.website && this.settings.languages) {
            this.progress += 10;
          }

          if(this.$root.current_region == 'staging' && this.settings.send_mode == 'simulate') {
            this.progress += 10;
          }

          if(this.settings.send_mode && this.settings.send_mode == 'live' && this.$root.current_region != 'staging') {
            this.progress += 10;
          }

          this.setTitle();

          this.getPhones();
        }
      })
    },

    setTitle() {
      document.title = this.settings.name + ' | Agency Checklist';
    },

    getPhones() {
      this.$http.get('/agency_phones?slim=true&fields=phone_number,uuid,name,forward_calls,forward_roles,forward_assigned,primary')
      .then(response => {
        if(response && response.status) {

          if(response.data) {
            this.agency_phones = response.data;

            if(this.agency_phones.length > 0) {
              this.progress += 10;
            }

          }

          this.getEmails();
        }
      })
    },

    getEmails() {
      this.$http.get('/agency_emails?slim=true&fields=name,email,uuid,forward,forward_roles,auto_reply')
      .then(response => {
        if(response && response.status) {

          if(response.data) {
  
            this.agency_emails = response.data;            
  
            if(this.agency_emails.length > 0) {
              this.progress += 10;
            }
          }

          this.getUsers();

        }
      })
    },

    getUsers() {
      this.$http.get('/users?slim=true&fields=name,uuid,email&limit=1000')
      .then(response => {
        if(response && response.status) {

          if(response.data) {

          this.users = response.data;

          if(this.users.length >= 2) {
            this.progress += 10;
          }

          }

          this.getRoles();
        }
      })
    },


    getRoles() {
      this.$http.get('/roles?slim=true&fields=name,uuid&sort=name&sortDirection=asc&limit=1000')
      .then(response => {
        if(response && response.status) {

          if(response.data) {
          this.roles = response.data;

          if(this.roles.length > 0) {
            this.progress += 5;
          }            
          }

          this.getLocations();

        }
      })
    },

    getLocations() {
      this.$http.get('/locations?slim=true&fields=uuid,name,location_reference,address,city,state,zip&sort=name&sortDirection=asc&limit=1000')
      .then(response => {
        if(response && response.status) {

          if(response.data) {

          this.locations = response.data;

          if(this.locations.length > 0) {
            this.progress += 10;
          }

          }

          this.getFlows();
        }
      })
    },

    getFlows() {
      this.$http.get('/flows?fields=name,uuid&sort=name&sortDirection=asc&limit=1000')
      .then(response => {
        if(response && response.status) {

          if(response.data) {
          this.flows = response.data;

          if(this.flows.length > 0) {
            this.progress += 10;
          }            
          }

          this.getAutoMessages();
        }
      })
    },

    getAutoMessages() {
      this.$http.get('/auto_messages?slim=true&fields=name,uuid&sort=name&sortDirection=asc&limit=1000')
      .then(response => {
        if(response && response.status) {

          if(response.data) {
          this.auto_messages = response.data;

          if(this.auto_messages.length > 0) {
            this.progress += 10;
          }

          this.getStatuses();            
          }
        }
      })
    },

    getFiles() {
      this.$http.get('/files?slim=true&fields=name,uuid&sort=name&sortDirection=asc&limit=1000')
          .then(response => {
            if(response && response.data) {
              this.files = response.data;
            }
          })
    },

    getStatuses() {
      this.$http.get('/statuses?slim=true&fields=uuid,name,disable_messages,archive,fta&sort=name&sortDirection=asc&limit=1000')
      .then(response => {
        if(response && response.status) {

          if(response.data) {
          this.statuses = response.data;

          if(this.statuses.length > 0) {
            this.progress += 5;
          }

          this.getPortals();
          }
        }
      })
    },

    getPortals() {
      this.$http.get('/portals?slim=true&fields=name,domain,link,uuid&limit=1000')
      .then(response => {
        if(response && response.status) {

          if(response.data) {
            this.portals = response.data;

            if(this.portals.length > 0) {
              this.progress += 10;
            }

            this.shouldCelebrate();

            this.getUploadTemplates();
            this.getDomains();
            this.getFiles();
          }
        }
      })
    },

    getDomains() {
      this.$http.get('/domains?limit=1000&fields=domain,name')
          .then(response => {
            if(response && response.data) {
              this.domains = response.data;
            }
          })
    },

    getUploadTemplates() {
      this.$http.get('/upload_templates?slim=true&fields=name,format,uuid&limit=1000')
          .then(response => {
            if(response && response.status) {

              if(response.data) {
                this.upload_templates = response.data;
              }

              this.getDashboards();
            }
          })
    },

    getDashboards() {
      this.$http.get('/dashboards?slim=true&fields=name,format,uuid&limit=1000')
          .then(response => {
            if(response && response.status) {

              if(response.data) {
                this.dashboards = response.data;
              }

              this.getReports();
            }
          })
    },

    getReports() {
      this.$http.get('/reports?slim=true&fields=name,uuid,auto_export,last_active&limit=1000')
          .then(response => {
            if(response && response.status) {

              if(response.data) {
                this.reports = response.data;
              }
            }
          })
    },

    shouldCelebrate() {

      if(this.progress < 100) {
        return;
      }

      confetti({
        particleCount: 150
      });

      setTimeout(() => {
        confetti.reset();
      }, 10000);    

    },

    async downloadPDF() {
      try {

        window.scrollTo(0,0);

        this.downloading = true;

        const element = document.getElementById('main');

        const canvas = await this.$html2canvas(element, {
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          scale: window.devicePixelRatio,
          useCORS: true,
          logging: false
        });

        const imgData = canvas.toDataURL('image/png');

        const pdf = new this.$jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        var name = this.$options.filters.slugString(this.$route.meta.title+'-'+this.$root.current_user.current_agency.name);

        pdf.save(name+'.pdf');

        this.downloading = false;
      } catch (error) {
        new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
        console.log(error);
        this.downloading = false;
      }
    },
   

  },
}
</script>