<template>
  <div>

    <page-tabs page="payments" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>

            <form
                @submit.prevent="postForm"
            >

              <div class="form-group">
                <label for="brand">Brand</label>
                <select id="brand" class="form-control" v-model="form.brand" @change="resetNewForm()" required>
                  <option value=""></option>
                  <option v-for="(gift_card, gift_index) in gift_cards" :key="'gift_'+gift_index" :value="gift_card.name">{{ gift_card.name }}</option>
                </select>
              </div>

              <template v-if="currentCard && currentCard.id">

                <template v-if="currentCard.type == 'range'">

                  <div class="form-group">
                    <label for="amount">Amount</label>
                    <div class="input-group">
                      <span
                        class="input-group-text"
                        style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
                      >$</span>
                      <input
                        id="amount"
                        v-model="form.amount"
                        type="number"
                        class="form-control"
                        @change="resetNewForm()"
                        :min="currentCard.min"
                        :max="currentCard.max"
                        step="1"
                        required
                      >
                    </div>
                    <template v-if="currentCard.min && currentCard.max">
                      <p class="form-text text-muted small">${{ currentCard.min }} min. ${{ currentCard.max }} max.</p>
                    </template>
                  </div>

                </template>

                <template v-else>
                  <div class="form-group">
                  <label for="amount_option">Choose Amount</label>
                  <select id="amount_option" class="form-control" v-model="form.amount" @change="resetNewForm()" required>
                    <option v-for="amount in amounts" :key="amount" :value="amount">${{ amount }}.00</option>
                  </select>
                </div>
                </template>

              <div class="form-group">
                <label for="quantity">Quantity</label>
                <input id="quantity" type="number" class="form-control" v-model="form.quantity" @change="resetNewForm()" step="1" min="1" max="100" required>
              </div>

              <div class="form-group">
                <label for="contact">Phone or Email (optional)</label>
                <input id="contact" type="text" class="form-control" v-model="form.contact" @change="resetNewForm()">
              </div>

              <div class="form-group custom-control custom-switch mt-3">
                 <input
                     id="test_mode"
                     v-model="form.test_mode"
                     type="checkbox"
                     class="custom-control-input"
                     :disabled="processing"
                     :readonly="$root.current_region == 'staging'"
                 >
                 <label
                     class="custom-control-label"
                     for="test_mode"
                 >Test Mode</label>
               </div>

              </template>

              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                Add
              </button>
            </form>

            <template v-if="new_form && new_form.uuid">
              <router-link tag="button" :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': new_form.uuid}}" class="btn btn-primary my-3">
                Edit {{ $route.meta.title }}
              </router-link>
            </template>

            <template v-if="errors && errors.message">
              <div v-if="errors.message != 'Network Error'">
                <div class="alert alert-danger">
                  {{ errors.message }}
                </div>
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords()">
              <div class="form-group">
                <label for="search">Search</label>
                <input
                    id="search"
                    v-model="queries.search"
                    class="form-control"
                    type="search"
                >
              </div>

              <div class="form-group">
                <label for="search_contact">Contact</label>
                <input id="search_contact" type="text" class="form-control" v-model="queries.contact" placeholder="Phone or email">
              </div>

              <div class="form-group">
                <label for="search_brand">Brand</label>
                <select id="search_brand" class="form-control" v-model="queries.brand">
                  <option value=""></option>
                  <template v-if="gift_cards && gift_cards.length > 0">
                  <option v-for="search_brand in gift_cards" :key="'search_brand_'+search_brand.id" :value="search_brand.name">{{ search_brand.name }}</option>
                  </template>
                </select>
              </div>

              <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
      <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <sort-columns
                    :columns="columns"
                    :queries="queries"
                    :processing="processing"
                    :base_url="$route.meta.base_url"
                />

                <tbody>
                <template v-for="(record, index) in records">
                  <tr :key="'gift_card_'+index">
                    <td>
                      <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                        <i class="fa fa-edit"></i> ${{ record.amount }} {{ record.reference }}
                      </router-link>
                    </td>
                    <td>{{ record.contact }}</td>
                    <td>
                      <template v-if="record.brand">
                        <img :src="$root.assets_url+'images/gift_cards/'+record.brand.toLowerCase()+'.svg'" width="28" :alt="record.brand">
                      </template>
                    </td>
                    <td>
                      <template v-if="record.status">
                        <span class="badge badge-pill text-uppercase" :class="setStatusClass(record.status)">{{ record.status }}</span>
                      </template>
                    </td>
                    <td>{{ record.client }}</td>
                    <td>{{ record.created_by }}</td>
                    <td>{{ record.created_at | datetime }}</td>
                    <td>{{ record.updated_at | datetime }}</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div><!--Responsive-->

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="records && records.length < total">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                  type="button"
                  class="btn btn-outline-primary d-print-none"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      records: [],
      downloads: [],
      processing: false,
      downloading: false,
      total: 0,
      queries: {},
      form: {},
      new_form: {},
      columns: [
        {key: 'amount', label: 'Amount'},
        {key: 'contact', label: 'Contact'},
        {key: 'brand', label: 'Brand'},
        {key: 'status', label: 'Status'},
        {key: 'client', label: 'Client'},
        {key: 'created_by', label: 'Creator'},
        {key: 'created_at', label: 'Created'},
        {key: 'updated_at', label: 'Updated'}
      ],
      amounts: [15, 20, 25, 50, 75, 100],
      errors: null,
      gift_cards: []
    }
  },

  computed: {

    currentCard() {
      if(!this.form.brand) {
        return;
      }

      var card = this.gift_cards.find(card => card.name === this.form.brand);

      if(!card) {
        return;
      }

      return card;
    }

  },

  mounted() {

    var self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

      if(view !== self.$route.meta.base_url) {
        return;
      }

      self.resetRecords();

      self.getRecords();

    });

    this.$eventBus.$on('errors', function (errors) {
      self.errors = errors.data || errors;
      self.processing = false;
    });

  },

  created () {
    this.resetRecords();
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getRecords();
    this.getGiftCards();
  },

  methods: {

    setURLParams() {
      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    searchRecords() {
      this.queries.skip = 0;
      this.resetRecords();
      this.getRecords();
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    resetForm() {
      this.form = {
        brand: null,
        product: null,
        amount: null,
        quantity: 1,
        contact: null,
        test_mode: false
      }

      if(this.$root.current_region == 'staging') {
        this.form.test_mode = true;
      }

      this.errors = null;
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'desc',
        search: null,
        contact: null,
        brand: null
      }
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
    },

    getRecords () {

      this.processing = true;

      this.updateURL();

      this.totalRecords();

      this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.data.length) {

                this.queries.skip += response.data.length;

                var self = this;

                response.data.forEach(function(data) {
                  self.records.push(data);
                });

              }
            }
          })
    },

    totalRecords() {
      this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              this.setTitle();
            }
          })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    postForm() {
      this.processing = true;

      this.new_form = null;

      this.errors = null;

      if(this.currentCard.id) {
        this.form.product = this.currentCard.id;
      }

      this.$http.post(this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.status) {

              if(response.status === 201) {
                this.resetForm();
                this.total++;
                this.setTitle();
                this.records.unshift(response.data);
                this.new_form = response.data;
                new this.$noty({text: this.$route.meta.title + ' added'}).show();
              }

              this.processing = false;

            }
          });
    },

    resetNewForm() {
      this.new_form = null;
    },

    setStatusClass(status) {
      if(!status) {
        return;
      }

      status = status.toLowerCase();

      if(status == 'successful') {
        return 'badge-primary';
      }

      if(status == 'failed') {
        return 'badge-danger';
      }

      if(status == 'pending') {
        return 'badge-warning';
      }

      if(status == 'activated') {
        return 'badge-success';
      }

      return 'badge-secondary';
    },

    async downloadPDF() {
      try {

        window.scrollTo(0,0);

        this.downloading = true;

        const element = document.getElementById('main');

        const canvas = await this.$html2canvas(element, {
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          scale: window.devicePixelRatio,
          useCORS: true,
          logging: false
        });

        const imgData = canvas.toDataURL('image/png');

        const pdf = new this.$jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

        pdf.save(name+'.pdf');

        this.downloading = false;
      } catch (error) {
        new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
        console.log(error);
        this.downloading = false;
      }
    },

    downloadCSV () {
      this.downloading = true;

      var queries = this.queries;

      queries.limit = this.total;

      queries.skip = this.downloads.length;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
          .then(response => {
            if(response && response.data && response.data) {
              this.downloads = this.downloads.concat(response.data);

              var csvFile, data, link;

              data = this.convertCSV(this.downloads);

              var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

              csvFile = new Blob([data], {type: "text/csv"});
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(csvFile, file);
              } else {
                link = document.createElement("a");
                link.download = file;
                link.href = window.URL.createObjectURL(csvFile);
                link.style.display = "none";
                document.body.appendChild(link);
                link.click();
              }

              this.downloading = false;

            } else {
              this.downloadCSV();
            }

          });
    },

    convertCSV(records) {

      var results = [];

      if(records[0] && records[0]['uuid']) {
        var headers = Object.keys(records[0]);
      }

      results += headers.join(',')

      results += '\n';

      records.forEach(function(item) {
        var record = '';

        if(!item) {
          return;
        }

        Object.keys(item).forEach(function(key) {
          record += '"' + (item[key] ? item[key] : '') + '",';
        });

        results += record;
        results += '\n';
      });

      return results
    },

    getGiftCards () {
      this.$axios.get(this.$root.assets_url+'js/gift_cards.json')
      .then(response => {
          if(response && response.data) {
            this.gift_cards = response.data;
          }
      })
    }


  }
}
</script>
