<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row mb-3">
      <div class="col-lg-9 col-md-7 order-1 order-md-0 mb-1 mt-2 mt-md-0">
        <h1 class="m-0">
          {{ form.name }}
        </h1>

        <template v-if="form.trigger">
          <span class="badge badge-pill bg-secondary text-uppercase">Trigger: {{ form.trigger | rmDashes }}</span>
        </template>

        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->
      <div class="col-lg-3 col-md-5 text-right">
        <div class="btn-group">

          <a 
          :href="'/preview?auto_message='+form.uuid" 
          class="btn btn-outline-primary"
          target="_blank"
          :disabled="processing"
          >
          Preview
          </a>

          <button
            type="button"
            class="btn btn-secondary dropdown-toggle radius-right-0"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">
            <a
              v-if="form.deleted_at || form.archived_at"
              class="dropdown-item"
              @click="restoreRecord"
            >Restore</a>
            <a
              v-if="!form.deleted_at"
              class="dropdown-item"
              @click="trashRecord"
            >Trash</a>
          </div>
          <button
            v-if="!form.deleted_at"
            type="submit"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>
          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            
            <p class="card-title">Edit {{ $route.meta.title }}</p>

            <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="trigger">Trigger</label> <br>
                <select
                  id="trigger"
                  v-model="form.trigger"
                  class="form-control"
                  @change="getStatuses()"
                  :disabled="processing"
                >
                  <option
                    v-for="trigger in triggers"
                    :key="trigger.label"
                    :value="trigger.value"
                  >
                    {{ trigger.label }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="value">Status Value</label> <br>
                <select
                    id="value"
                    v-model="form.value"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <option
                      v-for="(status, status_index) in statuses"
                      :key="'status_'+status_index"
                      :value="status.name"
                  >
                    {{ status.name }}
                  </option>
                </select>
              </div>

              <template v-if="!processing && !statuses.length">
                <div class="alert alert-info">
                  <router-link
                    class="alert-link"
                    :to="{ 'name': 'statuses.index' }"
                    :disabled="processing"
                >Create statuses here.</router-link>
                </div>
              </template>

              <div class="form-group">
                <label for="recipients">Recipients</label>
                <input id="recipients" v-model="form.recipients" type="text" class="form-control">
                <p class="form-text text-muted">Leave blank to only notify clients</p>
              </div>

              <div class="form-group">
                <label for="delay">Delay</label>
                <input
                  id="delay"
                  v-model="form.delay"
                  type="number"
                  class="form-control"
                  min="0"
                  step="1"
                >
                <small class="form-text text-muted">In minutes. Defaults to 5</small>
              </div>

              <div class="form-group">
                <label for="subject">Subject</label>
                <input
                  id="subject"
                  v-model="form.subject"
                  type="text"
                  class="form-control"
                >
              </div>

              <template v-if="!processing && !languages.length">
                <div class="alert alert-danger">Choose enabled languages in
                  <a href="/settings">General Settings</a>
                </div>
              </template>

              <div class="form-group">
                <label for="file_url">Add File

                  <template v-if="form.file_url">
                    <a 
                    :href="'/files?url='+form.file_url" 
                    target="_blank"
                    >
                      <i class="fa fa-link"></i>
                      <span class="sr-only">Link to file</span>
                    </a>
                  </template>

                </label>
                <select
                  id="file_url"
                  v-model="form.file_url"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-if="file_links && file_links.length > 0">
                    <template v-for="(file_link, index) in file_links">
                      <option
                        :key="'file_link_'+index"
                        :value="file_link.url"
                      >
                        {{ file_link.name }}
                      </option>
                    </template>
                  </template>
                </select>
                <p class="small text-muted">
                  <a
                    href="/files"
                    target="_blank"
                  >Upload files</a>
                </p>
              </div>

              <div class="form-group">
                <label for="max_messages">Max Messages</label>
                <input
                  id="max_messages"
                  v-model="form.max"
                  type="number"
                  class="form-control"
                  :disabled="processing"
                >
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="delay_business"
                  v-model="form.delay_business"
                  type="checkbox"
                  class="custom-control-input"
                  @change="postForm()"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="delay_business"
                >Delay to Business Hours</label>
              </div>
              <div class="form-group custom-control custom-switch">
                <input
                  id="ignore_disabled"
                  v-model="form.ignore_disabled"
                  type="checkbox"
                  class="custom-control-input"
                  @change="postForm()"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="ignore_disabled"
                >Ignore Disabled Statuses</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="simulate"
                  v-model="form.simulate"
                  type="checkbox"
                  class="custom-control-input"
                  @change="postForm()"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="simulate"
                >Simulate Mode</label>
              </div>

              <template v-if="form.trigger === 'client_created' || form.trigger === 'event_created' || form.trigger === 'case_created' || form.trigger == 'event_status'">
                 <div class="form-group custom-control custom-switch">
                  <input
                    id="future_events"
                    v-model="form.future_events"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                  >
                  <label
                    class="custom-control-label"
                    for="future_events"
                  >Require Future Date</label>
                </div>
                </template>

                <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="form && form.uuid">
          <audit-logs
            :form="form"
            class="mt-3"
          />
        </template>
      </div><!--Col-->


      <div class="col-sm-12 col-md-6">

        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">Language Templates</p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-right">
                <button
                    type="button"
                    class="btn btn-outline-primary btn-sm"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="mergeTagButton"
                >
                  <i class="fa fa-code"></i> Merge Tags
                </button>
                <div class="dropdown-menu" aria-labelledby="mergeTagButton" style="max-height:400px;overflow-y:auto">
                  <h6 class="dropdown-header">
                    Use a Merge Tag
                  </h6>
                  <template v-for="(merge_tag, index) in merge_tags">
                    <a
                        :key="'merge_tag_'+index"
                        class="dropdown-item"
                        @click="copyToClipboard(merge_tag.value)"
                    >{{ merge_tag.value }}</a>
                  </template>
                </div>
              </div><!--Col-->
            </div>


              <template v-for="(language, index) in languages">

                <div :key="'language_template_'+index" class="form-group mt-3">
                  <label
                    for="language_templates"
                    class="text-capitalize"
                  >
                  {{ language.label }} Template</label>
                  <textarea
                    id="language_templates"
                    ref="templatesText"
                    v-model="templates[language.key]"
                    class="form-control"
                    :class="setValidateClass(templates[language.key])"
                    rows="5"
                  />


                  <template v-if="language.key !== 'en' && templates['en']">
                    <button 
                      type="button" 
                      class="btn btn-outline-success mt-3 btn-sm text-capitalize"
                      @click="autoTranslate(language)"
                      :disabled="processing"
                      >
                      <i class="fa fa-language"></i> Auto Translate {{ language.label }}
                    </button>
                  </template>

                </div>

              </template>

            </div><!--Body-->
          </div><!--Card-->

      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

        <template v-if="total_messages >= 0">
          <p class="card-title"><a :href="'/messages?type=auto_message:'+form.name">{{ messages.length }} of {{ total_messages.toLocaleString() }} Messages</a></p>
        </template>

        <template v-for="(message, message_index) in messages">

          <message
            :key="'message_'+message_index"
            :message="message"
          />

        </template>

      </div><!--Col-->

    </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data() {
        return {
            processing: false,
            form: {},
            queries: {
                limit: 1000,
                slim: 'name,uuid,trigger',
                sort: 'name',
                sortDirection: 'asc'
            },
            templates: {
                'en': ''
            },
            languages: [],
            records: [],
            triggers: [],
            file_links: [],
            current_language: {label: 'English', key: 'en'},
            total: 0,
            messages: [],
            total_messages: 0,
            merge_tags: [],
            statuses: []
        }
    },

    created() {
        this.resetForm();
        this.setURLParams();
        this.getRecord();
        this.getLanguages();
        this.getTriggers();
        this.getMergeTags();
        this.getFiles();
    },
    
    methods: {

    resetForm() {
        this.form = {
            templates: {
                'en': ''
            },
            name: null,
            subject: null,
            trigger: null,
            value: null,
            delay: null,
            delay_business: null,
            future_events: null,
            file_url: null,
            ignore_disabled: null,
            recipients: null,
            simulate: 0,
            max: 1
        };

        this.templates = {
            'en': ''
        }

        this.messages = [];

        this.total_messages = 0;
    },

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        })
    },

    getRecord() {

        this.processing = true;

        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                
                this.form = response.data;
                
                if(!response.data.templates) {
                    this.templates = {
                        'en': ''
                    }
                }

                this.setTitle();

                if(response.data.templates) {
                    this.templates = response.data.templates;
                }

                this.totalMessages();

                this.getStatuses();
                
                this.processing = false;
            }
        })
    },

      setTitle() {
        document.title = this.form.name + ' | ' + this.$route.meta.title;
      },


    postForm() {

        this.setForm();

        this.processing = true;
        this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
            .then(response => {
              if(response && response.data) {

                  this.processing = false;

                  if(response.data.updated_at) {
                    new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                    this.form.updated_at = response.data.updated_at;
                  }
              }
            })
      },

    setForm() {
        this.form.templates = this.templates;
    },

    getLanguages() {
        this.$http.get('/languages')
        .then(response => {
            if(response && response.data) {
                this.languages = response.data;
            }
        })
    },

    getTriggers() {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
      .then(response => {
        if(response && response.data && response.data.auto_message_triggers) {
          this.triggers = response.data.auto_message_triggers;
        }
      })
    },

      getMergeTags () {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/mergetags.json')
            .then(response => {
              if(response && response.data) {
                this.merge_tags = response.data;
              }
            })
      },

    getFiles() {
        this.$http.get('/files?slim=true&fields=name,url')
        .then(response => {
            if(response && response.data) {
                this.file_links = response.data;
            }
        })
    },

      getStatuses() {

      if(!this.form.trigger) {
        return;
      }

      var types = ['client', 'case', 'payment', 'contact', 'event'];

      this.statuses = [];

      types.forEach(type => {
        if(this.form.trigger.includes(type)) {
          this.processing = true;
          this.$http.get('/statuses?fields=name&slim=true&type='+type)
          .then(response => {
            if(response && response.data) {
              this.statuses = response.data;
              this.processing = false;
            }
          })
        }
      })
      },

    getMessages() {

      if(!this.total_messages || this.total_messages === 0) {
        return;
      }

      this.$http.get('/messages?sort=scheduled_at&sortDirection=desc&slim=true&type=auto_message:'+this.form.name)
      .then(response => {
        if(response && response.data) {

          if(response.data.data.length > 0) {
            this.messages = response.data.data; 
          }
        }
      })
    },


    totalMessages() {

      if(!this.form.name) {
        return;
      }

      this.$http.get('/messages/total?type=auto_message:'+this.form.name)
      .then(response => {
        if(response && response.status === 200) {
          console.log('total' + response.data)
          this.total_messages = response.data;

          this.getMessages();
        }
      })
    },

    trashRecord() {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
      .then(response => {
        if (response && response.status) {

          if(response.status === 204) {
            new this.$noty({ text: this.$route.meta.title + ' trashed', type: 'error' }).show();
            this.form.deleted_at = new Date().toISOString();
          }

          this.processing = false;
        }
      })
    },

    restoreRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
      .then(response => {
        if (response && response.status) {

          if(response.status === 200) {
            new this.$noty({ text: this.$route.meta.title + ' restored'}).show();
            this.form.deleted_at = null;
            this.form.archived_at = null;
            this.form.updated_at = new Date().toISOString();
          }

          this.processing = false;
        }
      })
    },

    previewAutoMessage(content) {
        window.location.href = '/preview?content='+content;
    },

    autoTranslate(language) {

      this.processing = true;

      var translator = {
        source_language: 'en',
        target_language: language.key,
        content: this.templates['en']
      }

      this.$http.post('/translator', translator)
      .then(response => {
        if(response && response.data) {

          if(response.data.content) {
            this.templates[language.key] = response.data.content;
            new this.$noty({text: 'Added translation to '+language.label}).show();
          }

          this.processing = false;
        }
      })
    },

      setValidateClass(value) {

        if(!value) {
          return;
        }

        var tags = this.extractMergeTags(value);

        if(!tags) {
          return;
        }

        var values = this.merge_tags.map(item => item['value']);

        values = values.map(item => item.replace(/\[|\]/g, ''));

        function hasInvalidTags(tags, values) {
          return tags.some(item => !values.includes(item));
        }

        if(hasInvalidTags(tags, values)) {
          return 'is-invalid';
        }

      },

      extractMergeTags(value) {
        const mergeTagRegex = /\[([^\]]+)\]/g;
        const mergeTags = [];
        let match;

        while ((match = mergeTagRegex.exec(value)) !== null) {
          mergeTags.push(match[1].replace(/%.*?%/g, '').trim());
        }

        return mergeTags;
      },

      copyToClipboard(value) {
        navigator.clipboard.writeText(value);
        new this.$noty({text: 'Copied to clipboard'}).show();
      },


    }
}
</script>