<template>
  <div>

    <page-tabs :page="$route.meta.base_url" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <template v-if="client && client.uuid">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-6">
                  <p class="card-title">
                    Client 
                    <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                      {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}

                      {{ client.language }}
                    </router-link>
                  </p>
                </div><!--Col-->

                <div class="col-md-6 text-right">
                  <button
                    type="submit"
                    class="btn btn-danger btn-sm"
                    :disabled="processing"
                    @click="closeClient"
                  >
                    <i class="fa fa-times" /> <span class="sr-only">Close</span>
                  </button>
                </div><!--Col-->
              </div><!--Row-->

              <ul class="list-group">
                <template v-if="client.client_reference">
                  <li class="list-group-item">
                    Reference {{ client.client_reference }}
                  </li>
                </template>

                <template v-if="client.type">
                  <li class="list-group-item">
                    Type {{ client.type }}
                  </li>
                </template>

                <template v-if="client.group">
                  <li class="list-group-item">
                    Group {{ client.group }}
                  </li>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="client && !client.uuid">
          <client-search />
        </template>

        <div class="card">
          <div class="card-body">

                <p class="card-title">
                  Add {{ $route.meta.title }}
                  <template v-if="client && client.uuid">
                    for {{ client.client_reference }} {{ client.first_name }} {{ client.last_name }}
                  </template>
                </p>

            
            <form @submit.prevent="postForm">

              <template v-for="(field, field_index) in fields">

                <div :key="'event_field_'+field_index" v-if="field.show">

                  <template v-if="['date', 'time', 'text'].includes(field.type)">

                    <div class="form-group">
                      <label :for="field.key">{{ setFieldTitle(field) }}</label>
                      <input
                          :id="field.key"
                          v-model="form[field.key]"
                          :type="field.type"
                          class="form-control"
                      >
                    </div>

                  </template>

                  <template v-if="field.type == 'checkbox'">

                  <div class="form-group custom-control custom-switch">
                    <input
                        :id="field.key"
                        v-model="form[field.key]"
                        type="checkbox"
                        class="custom-control-input"
                    >
                    <label
                        class="custom-control-label"
                        :for="field.key"
                    >{{ field.title }}</label>
                  </div>

                  </template>

                  <template v-if="field.type == 'longtext'">

                    <div class="form-group">
                      <label :for="field.key">{{ setFieldTitle(field) }}</label>
                      <textarea
                          :id="field.key"
                          v-model="form[field.key]"
                          class="form-control"
                      ></textarea>
                    </div>

                  </template>

                  <template v-if="field.type == 'list'">

                    <div class="form-group">
                      <label :for="field.key">{{ field.title }}</label>
                      <select
                          :id="field.key"
                          v-model="form[field.key]"
                          class="form-control"
                          :disabled="processing"
                      >
                        <option value="" />
                        <template v-for="(option, index) in options[field.key+'s']">
                          <option
                              :key="field.key+'_'+index"
                              :value="option.uuid"
                          >
                            {{ option.name }}
                          </option>
                        </template>
                      </select>
                    </div>

                  </template>

                  <template v-if="field.key == 'judge'">
                    <div class="form-group">
                      <label :for="field.key">{{ field.title }}</label>
                      <select
                          :id="field.key"
                          v-model="form[field.key]"
                          class="form-control"
                          :disabled="processing"
                      >
                          <option value="" />
                          <template v-for="(judge, judge_index) in judges">
                            <option
                                :key="'judge_'+judge_index"
                                :value="judge"
                            >
                              {{ judge.name }}
                            </option>
                          </template>
                      </select>
                    </div>
                  </template>

                </div>

              </template>

              <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <p class="card-title">
                  Search {{ $route.meta.title }}s
                </p>
              </div><!--Col-->

              <div class="col-md-6 text-right">
                <button
                  type="button"
                  data-tooltip="Reset search fields"
                  class="btn btn-outline-secondary"
                  :disabled="processing"
                  @click="resetSearch"
                >
                  <i class="fa fa-eraser" /> <span class="sr-only">Reset Form</span>
                </button>
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                  placeholder="Event Reference"
                >
              </div>

              <div class="form-group">
                <label for="event_reference">Event Reference</label>
                <input
                  id="event_reference"
                  v-model="queries.event_reference"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="case_number">Case Number</label>
                <input
                  id="case_number"
                  v-model="queries.case_number"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_type">Type</label>
                <select
                  id="search_type"
                  v-model="queries.type"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-if="event_types && event_types.length > 0">
                    <template v-for="(event_type, index) in event_types">
                      <option
                        :key="index"
                        :value="event_type.type"
                      >
                        {{ event_type.type }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_status">Event Status</label>
                <select
                  id="search_status"
                  v-model="queries.status"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-if="statuses && statuses.length > 0">
                    <template v-for="(search_status, search_status_index) in statuses">
                        <option
                          :key="'search_status_'+search_status_index"
                          :value="search_status.name"
                        >
                          {{ search_status.name }}
                        </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_appeared">Appearance Status</label>
                <select
                    id="search_appeared"
                    v-model="queries.appeared"
                    class="form-control"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-if="statuses && statuses.length > 0">
                    <template v-for="(search_status, search_status_index) in statuses">
                      <option
                          :key="'appeared_status_'+search_status_index"
                          :value="search_status.name"
                      >
                        {{ search_status.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="flow">Flow</label>
                <select
                  id="flow"
                  v-model="queries.flow"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value=""></option>
                  <option value="none">
                    No Flow
                  </option>
                  <template v-if="options['flows'] && options['flows'].length > 0">
                    <template v-for="(flow, index) in options['flows']">
                      <option
                        :key="'event_flow_'+index"
                        :value="flow.uuid"
                      >
                        {{ flow.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="location">Location</label>
                <select
                  id="location"
                  v-model="queries.location"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value=""></option>
                  <option value="none">
                    No Location
                  </option>
                  <template v-if="options['locations'] && options['locations'].length > 0">
                    <template v-for="(location, index) in options['locations']">
                      <option
                        :key="'location_'+index"
                        :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_user">Assigned User</label>
                <select
                    id="search_user"
                    v-model="queries.user"
                    class="form-control"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-if="options['users'] && options['users'].length > 0">
                    <template v-for="(user, index) in options['users']">
                      <option
                          :key="'user_'+index"
                          :value="user.uuid"
                      >
                        {{ user.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_judge">Assigned Judge</label>
                <select
                  id="search_judge"
                  v-model="queries.judge"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(judge, judge_index) in judges">
                    <option
                        :key="'search_judge_'+judge_index"
                        :value="judge"
                    >
                      {{ judge.name }}
                    </option>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="from_date">From Date</label>
                <input
                  id="from_date"
                  v-model="queries.from_date"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="to_date">To Date</label>
                <input
                  id="to_date"
                  v-model="queries.to_date"
                  type="date"
                  class="form-control"
                >
              </div>
              
              <div class="form-group">
                <label for="reason">Unreachable Reason</label>
                <select
                  id="reason"
                  v-model="queries.reached"
                  class="form-control text-capitalize"
                  :disabled="processing"
                >
                  <option value="" />

                  <template v-for="(reason, index) in reasons">
                    <template v-if="index != 1">
                      <option
                        :key="'reason_'+index"
                        :value="index"
                      >
                        {{ reason }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="search_virtual"
                  v-model="queries.virtual"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="search_virtual"
                >Virtual</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="archived"
                  v-model="queries.archived"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="archived"
                >Archived</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="trashed"
                >Trashed</label>
              </div>


              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
            <table class="table table-hover table-striped">
              <thead>
                <tr>
                  <template v-for="(column, index) in columns">
                    <th
                      :key="'column_'+index"
                      :class="setHiddenColumn(column.key)"
                    >
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key && column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>

              <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="(record, index) in records">
                    <tr :key="'event_'+index">
                      <td>
                        <router-link
                            :to="{ 'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid} }"
                            class="link-hover"
                        >
                          <i class="fa fa-edit"></i> <span class="sr-only">Edit</span> {{ record.date | date }}
                        </router-link>
                      </td>

                      <td>{{ record.time | time }}</td>
                      <td><span v-if="record.end_time">{{ record.end_time | time }}</span></td>
                      <td>
                        <template v-if="record.event_reference">
                          <a :href="'/'+$route.meta.base_url+'/'+record.uuid">{{ record.event_reference }}</a>
                        </template>
                      </td>
                      <td><a :href="'/cases?case_number='+record.case_number">{{ record.case_number }}</a></td>

                      <td>
                        
                        <template v-if="record.virtual == 1">
                          <span data-tooltip="Virtual Event"><i class="fa fa-check-circle text-success"></i></span>
                        </template>

                        <template v-if="record.virtual != 1">
                          <span data-tooltip="In-Person Event"><i class="fa fa-times-circle text-danger"></i></span>
                        </template>

                        <template v-if="record.virtual_notes">
                          <br><small class="text-muted">{{ record.virtual_notes }}</small>
                        </template>

                      </td>


                      <td>

                        <template v-if="record.location">
                          <a :href="'/locations/'+record.location">{{ setLocationName(record) }}</a>
                        </template>

                        <template v-if="record.location_notes">
                          <small class="text-muted">{{ record.location_notes }}</small>
                        </template>
                      </td>

                      <td>
                        <template v-if="record.flow">
                          <a :href="'/flows/'+record.flow">{{ setFlowName(record) }}</a>
                        </template>
                      </td>

                      <td>
                        <template v-if="record.portal">
                          <a :href="'/portals/'+record.portal">{{ setPortalName(record) }}</a>
                        </template>
                      </td>

                      <td>
                        <template v-if="record.client">
                          <client-button :uuid="record.client" />
                        </template>
                      </td>

                      <td>
                        <template v-if="record.uuid">
                          <total-messages :event="record.uuid" />
                        </template>
                      </td>

                      <td>{{ record.status }}</td>

                      <td>{{ record.appeared }}</td>

                      <td>{{ record.type }}</td>

                      <td>{{ record.description }}</td>

                      <td>{{ record.judge_name }}</td>

                      <td>
                        <template v-if="record.reached">
                          <unreachable-reason :reason="record.reached" />
                        </template>
                      </td>

                      <td>
                        <template v-if="record.created_at">
                          {{ record.created_at | datetime }}
                        </template>

                        <template v-if="record.deleted_at">
                          <span class="badge badge-pill badge-danger">Trashed {{ record.deleted_at | datetime }}</span>
                        </template>

                        <template v-if="record.archived_at">
                          <span class="badge badge-pill badge-danger">Archived {{ record.archived_at | datetime }}</span>
                        </template>


                      </td>
                      <td>
                        <template v-if="record.updated_at">
                          {{ record.updated_at | datetime }}
                        </template>
                      </td>
                      <td :class="setHiddenColumn('archived_at')">
                        <template v-if="record.archived_at">
                          {{ record.archived_at | datetime }}
                        </template>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
            </div><!--Responsive-->


              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found.
                </div>
              </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

data() {
    return {
        processing: false,
        downloading: false,
        downloads: [],
        records: [],
        queries: {},
        options: {},
        event_types: [],
        statuses: [],
        event_results: [],
        flows: [],
        locations: [],
        portals: [],
        reasons: [],
        judges: [],
        users: [],
        total: 0,
        form: {},
        client: {},
        columns: [
            {label: 'Event Date', key: 'date'},
            {label: 'Start Time', key: 'time'},
            {label: 'End Time', key: 'end_time'},
            {label: 'Reference', key: 'event_reference'},
            {label: 'Case Number', key: 'case_number'},
            {label: 'Virtual', key: 'virtual'},
            {label: 'Location', key: 'location'},
            {label: 'Flow', key: 'flow'},
            {label: 'Portal', key: 'portal'},
            {label: 'Client'},
            {label: 'Messages'},
            // {label: 'Contacts'},
            {label: 'Event Status', key: 'status'},
            {label: 'Appearance Status', key: 'appeared'},
            {label: 'Event Type', key: 'type'},
            {label: 'Description', key: 'description'},
            {label: 'Judge', key: 'judge_name'},
            {label: 'Reached', key: 'reached'},
            {label: 'Created', key: 'created_at'},
            {label: 'Updated', key: 'updated_at'},
            {label: 'Archived', key: 'archived_at'}
        ],
        availableFields: [
          {title: 'Start Date', key: 'date', type: 'date', show: true},
          {title: 'End Date', key: 'end_date', type: 'date', show: true},
          {title: 'Start Time', key: 'time', type: 'time', show: true},
          {title: 'End Time', key: 'end_time', type: 'time', show: true},
          {title: 'Event Reference', key: 'event_reference', type: 'text', show: true},
          {title: 'Case Number', key: 'case_number', type: 'text', show: true},
          {title: 'Virtual', key: 'virtual', type: 'checkbox', show: true},
          {title: 'Virtual Notes', key: 'virtual_notes', type: 'longtext', show: true},
          {title: 'Flow', key: 'flow', type: 'list', show: true},
          {title: 'Location', key: 'location', type: 'list', show: true},
          {title: 'Location Notes', key: 'location_notes', type: 'longtext', show: true},
          {title: 'Portal', key: 'portal', type: 'list', show: true},
          {title: 'Judge', key: 'judge', type: 'computed', show: true},
          {title: 'Description', key: 'description', type: 'longtext', show: true},
          {title: 'Internal Notes', key: 'internal_notes', type: 'longtext', show: true}
        ],
        fields: [],
    }
},

mounted() {

    if(this.fields.length < 1) {
      this.fields = this.availableFields;
    }

    var self = this;

    this.$eventBus.$on('eventCreated', function (data) {
        self.records.unshift(data);
        self.total++;
    });

    this.$eventBus.$on('search_clients', function(uuid) {

       self.queries.client = uuid;

       self.getClient();

    });

},

created() {

    this.resetForm();
    this.resetQueries();
    this.getReasons();
    this.getEventTypes();
    this.getEventStatuses();
    this.setURLParams();

    if(this.queries.client) {
        this.getClient();
    } else {
        this.getRecords();        
    }

    this.getOptions('flows');
    this.getOptions('locations');
    this.getOptions('portals');
    this.getOptions('users');

    this.getJudges();
},

methods: {

    resetSearch() {
        this.resetQueries();
        this.searchRecords();
    },

    resetQueries() {
        this.queries = {
            date: '',
            from_date: '',
            to_date: '',
            from_time: '',
            to_time: '',
            search: '',
            event_reference: '',
            case_number: '',
            type: '',
            flow: null,
            location: null,
            judge: null,
            user: null,
            client: null,
            virtual: null,
            sort: 'created_at',
            sortDirection: 'desc',
            limit: 10,
            slim: true,
            archived: false,
            trashed: false,
            upload: null
        }
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    setHiddenColumn(key) {
        if(key == 'archived_at' && !this.queries.archived) {
            return 'd-none';
        }
    },

    getReasons() {
        this.$axios.get("https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/unreachables.json")
        .then(response => {
            if(response && response.data) {
                this.reasons = response.data;
            }
        })
    },

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {

            if(!value || value == 'null') {
              return;
            }

            self.queries[key] = value;
        });
    },


    getEventTypes() {
        this.$http.get('/event_types')
        .then(response => {
            if(response && response.data) {
                this.event_types = response.data;
            }
        })
    },

    getEventStatuses() {
        this.$http.get('/statuses?limit=1000&slim=true&fields=name&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.statuses = response.data;
            }
        })
    },

    // getEventResults() {
    //     this.$http.get('/event_appearances')
    //     .then(response => {
    //         if(response && response.data) {
    //             this.event_results = response.data;
    //         }
    //     })
    // },

    getOptions(key) {
      this.$http.get('/'+key+'?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.options[key] = response.data;
            }
          })
    },

    setPortalName(record) {

      if(!record.portal) {
        return;
      }

      var portal = _.find(this.portals, {'uuid': record.portal});

      if(!portal || !portal.name) {
        return;
      }

      return portal.name;
    },

    setFlowName(record) {

      if(!record.flow) {
        return;
      }

      var flow = _.find(this.options['flows'], {'uuid': record.flow});

      if(!flow || !flow.name) {
        return;
      }

      return flow.name;
    },

    setLocationName(record) {

      if(!record.location) {
        return;
      }

      var location = _.find(this.options['locations'], {'uuid': record.location});

      if(!location || !location.name) {
        return;
      }

      return location.name;
    },

    searchRecords() {
        this.resetRecords();
        this.getRecords();
    },

    resetRecords() {
      this.queries.skip = 0;
      this.total = 0;
      this.records = [];
    },

    getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
            if(response && response.status === 200) {
                this.total = response.data;
                this.setTitle();
            }
        })
    },

    updateURL() {
        history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords() {

        this.processing = true;

        this.updateURL();
        this.getTotal();

        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
            if(response && response.data) {

                if(response.data.length) {

                    var self = this;

                    response.data.forEach(function(data) {
                        self.records.push(data);
                    });
                
                }

                this.queries.skip = this.records.length;

                this.processing = false;
            }
        })
    },

    toggleArchivedEvents() {

        if(this.queries.archived === true) {
            this.queries.archived = false;
        } else {
            this.queries.archived = true;
        }

        this.getRecords();
    },

    toggleTrashedEvents() {

        if(this.queries.trashed === true) {
            this.queries.trashed = false
        } else {
            this.queries.trashed = true
        }

        this.getRecords();
    },

    getJudges () {
      this.$http.get('/judges?slim=true&fields=name&sort=name&sortDirection=asc&limit=1000&fields=name,uuid')
      .then(response => {
        if(response && response.data) {
          this.judges = response.data;
        }
      })
    },

    getUsers() {
        this.$http.get('/users?slim=true&fields=name,uuid&sort=name&sortDirection=desc&limit=250')
        .then(response => {
            if(response && response.data) {
                this.users = response.data;
            }
        })
    },

    updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();
      
      this.getRecords();
    },

    sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    getClient() {

        if(!this.queries.client) {
            return;
        }

        this.$http.get('/clients/'+this.queries.client+'?slim=true&fields=uuid,client_reference,first_name,last_name,group,type,language')
        .then(response => {
            if(response && response.data) {
                this.client = response.data;

                if(!this.client.language) {
                  this.client.language = 'en';
                }

                this.queries.client = this.client.uuid;
                this.searchRecords();
            }
        })
    },

    resetClient() {
        this.queries.client = null;
        this.client = {};
    },

    closeClient() {
        this.resetClient();
        this.resetRecords();
        this.getRecords();
    },

  activeField(field) {

    var activeField = _.find(this.fields, { 'key': field });

    if(activeField && activeField.show && activeField.show === true) {
      return 'active';
    }
  },

  toggleField(index) {

    if(this.fields[index].show) {
      this.fields[index].show = false;
    } else {
      this.fields[index].show = true;
    }

    localStorage.setItem('event_fields', JSON.stringify(this.fields));

    this.$eventBus.$emit('updateEventFields', this.fields);

  },

  setFieldTitle(field) {

    var fields = localStorage.getItem('fields');

    if(!fields || !fields.length) {
      return field.title;
    }

    var search = field.key;

    var title = _.find(JSON.parse(fields), { 'key': 'events.'+search });

    if(!title || !title.name) {
      return field.title;
    }

    return title.name;
  },

  setEventFields() {
    if(localStorage.getItem('event_fields')) {
      this.fields = JSON.parse(localStorage.getItem('event_fields'));
    } else {
      this.fields = this.availableFields;
    }
  },

  resetForm() {
    this.form = {
      client: null,
      date: null,
      time: null,
      event_reference: null,
      case_number: null,
      flow: null,
      location: null,
      location_notes: null,
      description: null,
      type: null,
      status: null,
      appeared: null,
      virtual: 0,
      judge_name: null,
      internal_notes: null,
      url: null
    }
  },

  postForm() {

    if(this.client && this.client.uuid) {
      this.form.client = this.client.uuid;
    }

    if(this.client_uuid && !this.form.client) {
      this.form.client = this.client_uuid;
    }

    this.processing = true;

    this.form.skip_sync = true;

    this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {

            if(response.data.uuid) {
              new this.$noty({text: this.$route.meta.title + ' created'}).show();
              this.$eventBus.$emit('eventCreated', response.data);
              this.resetForm();
              this.syncForm(response.data.uuid);
            }

            this.processing = false;

          }
        })
  },

  syncForm(uuid) {
    this.$http.post('/'+this.$route.meta.base_url+'/'+uuid+'/sync')
        .then(response => {
          if(response && response.status === 202) {
            new this.$noty({text: this.$route.meta.title + ' synced'}).show();
          }
        })
  },

  async downloadPDF() {
    try {

      window.scrollTo(0,0);

      this.downloading = true;

      const element = document.getElementById('main');

      const canvas = await this.$html2canvas(element, {
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight,
        scrollY: -window.scrollY,
        scrollX: -window.scrollX,
        scale: window.devicePixelRatio,
        useCORS: true,
        logging: false
      });

      const imgData = canvas.toDataURL('image/png');

      const pdf = new this.$jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height]
      });

      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

      var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

      pdf.save(name+'.pdf');

      this.downloading = false;
    } catch (error) {
      new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
      console.log(error);
      this.downloading = false;
    }
  },

  downloadCSV () {
    this.downloading = true;

    var queries = this.queries;

    queries.limit = this.total;

    queries.skip = this.downloads.length;

    this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
        .then(response => {
          if(response && response.data && response.data) {
            this.downloads = this.downloads.concat(response.data);

            var csvFile, data, link;

            data = this.convertCSV(this.downloads);

            var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

            csvFile = new Blob([data], {type: "text/csv"});
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(csvFile, file);
            } else {
              link = document.createElement("a");
              link.download = file;
              link.href = window.URL.createObjectURL(csvFile);
              link.style.display = "none";
              document.body.appendChild(link);
              link.click();
            }

            this.downloading = false;

          } else {
            this.downloadCSV();
          }

        });
  },

  convertCSV(records) {

    var results = [];

    if(records[0] && records[0]['uuid']) {
      var headers = Object.keys(records[0]);
    }

    results += headers.join(',')

    results += '\n';

    records.forEach(function(item) {
      var record = '';

      if(!item) {
        return;
      }

      Object.keys(item).forEach(function(key) {
        record += '"' + (item[key] ? item[key] : '') + '",';
      });

      results += record;
      results += '\n';
    });

    return results
  },

}

}
</script>