<template>
  <div>

    <page-tabs page="messages" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">Add {{ $route.meta.title }}</p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="lookup">Lookup</label>
                <input id="lookup" type="text" class="form-control" v-model="form.lookup" placeholder="Phone or Email" required>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="social"
                    v-model="form.social"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="social"
                > Social Media</label>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

            </form>

            <template v-if="errors && errors.message">
              <div v-if="errors.message != 'Network Error'">
                <div class="alert alert-danger mt-3">
                  {{ errors.message }}
                </div>
              </div>
            </template>

          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <form @submit.prevent="searchRecords()">
              <p class="card-title">
                Search {{ $route.meta.title}}s
              </p>

               <div class="form-group">
                <label for="search" class="form-label">Search</label>
                <input id="search" type="search" class="form-control" v-model="queries.search">
              </div>

              <div class="form-group">
                <label for="search_type" class="form-label">Type</label>
                <select id="search_type" class="form-control" v-model="queries.type">
                  <option value=""></option>
                  <option value="phone">Phone</option>
                  <option value="email">Email</option>
                </select>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="valid"
                        v-model="queries.valid"
                        type="checkbox"
                        class="custom-control-input"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="valid"
                    >Valid</label>
                  </div>
                </div><!--Col-->

                <div class="col-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="invalid"
                        v-model="queries.invalid"
                        type="checkbox"
                        class="custom-control-input"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="invalid"
                    >Invalid</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="archived"
                        v-model="queries.archived"
                        type="checkbox"
                        class="custom-control-input"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="trashed"
                        v-model="queries.trashed"
                        type="checkbox"
                        class="custom-control-input"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

          <div class="card mb-3" :hidden="searched || total !== 0">
            <div class="card-body">
              <div class="row">
                <div class="col-9 mx-auto text-center">
                  <h4>{{ $route.meta.title }}s Overview</h4>

                  <p class="lead">Run a reverse lookup on a phone number or email address.</p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">

                  <p>Enter the phone number or email address to get the following information.</p>

                  <p>Phone Number Lookups:</p>
                  <ul class="list-unstyled">
                  <li>Carrier Name</li>
                  <li>Caller ID Name</li>
                  <li>Line Type (mobile, landline, VoIP)</li>
                  </ul>

                  <p>Email Address Lookups:</p>
                  <ul class="list-unstyled">
                    <li>ISP Name</li>
                    <li>Email Validity</li>
                    <li>Spam Risk</li>
                  </ul>

                  <template v-if="$root.current_user && $root.current_user.current_agency && $root.current_user.current_agency.settings">

                    <template v-if="$root.current_user.current_agency.settings.auto_lookups && $root.current_user.current_agency.settings.auto_lookups == 1">
                      <p><i class="fa fa-check text-success"></i> Auto-Lookups are currently enabled.</p>
                      <button type="button" class="btn btn-danger" @click="updateLookups(0)" :disabled="processing">Disable Auto-Lookups</button>
                    </template>
                    <template v-else>

                      <p class="m-0">Automatically lookup newly created contacts.</p>
                      <p class="m-0"><i class="fa fa-check text-success"></i> Prevent failed messages and messages sent to fake contacts.</p>
                      <p class="m-0"><i class="fa fa-check text-success"></i> Instantly validate and verify contacts provided by clients.</p>
                      <p class="m-0 mb-3"><i class="fa fa-check text-success"></i> Switch to voice calls for landlines that can't receive SMS.</p>
                      <button type="button" class="btn btn-success" @click="updateLookups(1)" :disabled="processing">Enable Auto-Lookups</button>

                    </template>

                  </template>

                </div>
                <div class="col-sm-12 col-md-6">
                  <img src="https://ecdassets.s3.amazonaws.com/images/features/search-lookups.webp" class="img-fluid" alt="Run Contact Lookups">
                </div><!--Col-->
              </div><!--Row-->
            </div><!--Body-->
          </div><!--Card-->

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title m-0">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

                <template v-if="$root.current_user && $root.current_user.current_agency && $root.current_user.current_agency.settings">

                  <template v-if="$root.current_user.current_agency.settings.auto_lookups && $root.current_user.current_agency.settings.auto_lookups == 1">
                    <span class="badge badge-pill badge-success">Auto-Lookups Enabled</span>
                  </template>
                  <template v-else>
                    <span class="badge badge-pill badge-danger">Auto-Lookups Disabled</span>
                  </template>

                </template>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <sort-columns
                    :columns="columns"
                    :queries="queries"
                    :processing="processing"
                    :base_url="'/'+$route.meta.base_url"
                />

                <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="(record, index) in records">
                    <tr :key="'lookup_'+index">
                      <td>
                        <router-link :to="{ name: $route.meta.base_url+'.edit', params: {id: record.uuid}}">
                          {{ record.lookup }}
                        </router-link>
                      </td>
                      <td>{{ record.type }}</td>
                      <td>{{ record.status }}</td>
                      <td>
                        <template v-if="record.valid == 1">
                          <span class="badge badge-success">VALID</span>
                        </template>
                        <template v-else>
                          <span class="badge badge-danger">INVALID</span>
                        </template>
                      </td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.created_at | datetime }}
                      <template v-if="record.archived_at">
                        <span class="badge badge-pill badge-warning">Archived</span>
                      </template>
                      <template v-if="record.deleted_at">
                        <span class="badge badge-pill badge-danger">Trashed</span>
                      </template>
                      </td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div><!--Responsive-->

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      downloading: false,
      searched: false,
      queries: {},
      records: [],
      downloads: [],
      total: 0,
      columns: [
        {key: 'lookup', label: 'Lookup'},
        {key: 'type', label: 'type'},
        {key: 'status', label: 'status'},
        {key: 'valid', label: 'valid'},
        {key: 'created_by', label: 'Creator'},
        {key: 'created_at', label: 'Created'}
      ],
      form: {},
      errors: null
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

      if(view !== self.$route.meta.base_url) {
        return;
      }

      self.resetRecords();

      self.getRecords();

    });

    this.$eventBus.$on('errors', function (errors) {
      self.errors = errors.data || errors;
      self.processing = false;
    });

  },

  created() {
    this.resetQueries();
    this.setURLParams();
    this.resetForm();
    this.getRecords();
  },

  methods: {

    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    resetQueries() {
      this.queries = {
        search: null,
        type: null,
        client: null,
        valid: null,
        invalid: null,
        archived: false,
        trashed: false,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'desc',
      }
    },

    resetForm() {
      this.form = {
        lookup: null,
        social: true
      }

      this.errors = null;
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    searchRecords() {
      this.searched = true;
      this.resetRecords();
      this.getRecords();
    },

    getRecords() {
      this.getTotal();
      this.processing = true;
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {

              this.records = this.records.concat(response.data);
              this.queries.skip = this.records.length;

              this.processing = false;

            }
          })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              document.title = this.total + ' ' +this.$route.meta.title + 's | eCourtDate.com';
            }
          })
    },

    postForm() {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {
              this.processing = false;

              this.resetForm();

              if(response.data.uuid) {
                location.href = '/'+this.$route.meta.base_url+'/'+response.data.uuid;
              }
            }
          })
    },

    updateLookups(lookup) {
      this.processing = true;

      this.$root.current_user.current_agency.settings.auto_lookups = lookup;

        this.$http.patch('/settings?auto_lookups='+lookup)
            .then(response => {
                if(response && response.status === 200) {
                    new this.$noty({text: 'Auto-lookups updated.', type: 'success', timeout: 3000}).show();
                    this.processing = false;
                }
            })
    },

    async downloadPDF() {
      try {

        window.scrollTo(0,0);

        this.downloading = true;

        const element = document.getElementById('main');

        const canvas = await this.$html2canvas(element, {
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          scale: window.devicePixelRatio,
          useCORS: true,
          logging: false
        });

        const imgData = canvas.toDataURL('image/png');

        const pdf = new this.$jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

        pdf.save(name+'.pdf');

        this.downloading = false;
      } catch (error) {
        new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
        console.log(error);
        this.downloading = false;
      }
    },

    downloadCSV () {
      this.downloading = true;

      var queries = this.queries;

      queries.limit = this.total;

      queries.skip = this.downloads.length;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
          .then(response => {
            if(response && response.data && response.data) {
              this.downloads = this.downloads.concat(response.data);

              var csvFile, data, link;

              data = this.convertCSV(this.downloads);

              var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

              csvFile = new Blob([data], {type: "text/csv"});
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(csvFile, file);
              } else {
                link = document.createElement("a");
                link.download = file;
                link.href = window.URL.createObjectURL(csvFile);
                link.style.display = "none";
                document.body.appendChild(link);
                link.click();
              }

              this.downloading = false;

            } else {
              this.downloadCSV();
            }

          });
    },

    convertCSV(records) {

      var results = [];

      if(records[0] && records[0]['uuid']) {
        var headers = Object.keys(records[0]);
      }

      results += headers.join(',')

      results += '\n';

      records.forEach(function(item) {
        var record = '';

        if(!item) {
          return;
        }

        Object.keys(item).forEach(function(key) {
          record += '"' + (item[key] ? item[key] : '') + '",';
        });

        results += record;
        results += '\n';
      });

      return results
    },
  },
}
</script>