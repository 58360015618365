<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row">
      <div class="col-md-4">
          <h1>{{ form.name }}</h1>
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-md-4">

      </div><!--Col-->

      <div class="col-md-4 text-right">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">

                <a
                  v-if="form.deleted_at || form.archived_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>

                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <div class="card">
          <div class="card-body">

    <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name">{{ $route.meta.title }} Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="max_payments">Max # of Payments</label>
                <input
                  id="max_payments"
                  v-model="form.max_payments"
                  type="number"
                  class="form-control"
                  required
                >
              </div>
       
              <div class="form-group">
                <label for="max_duration">Max Duration</label>
                <input
                  id="max_duration"
                  v-model="form.max_duration"
                  type="number"
                  class="form-control"
                  required
                >
                <small class="text-muted">In Days</small>
              </div>

              <div class="form-group">
                <label for="fee">Pay Plan Fee</label>
                <input
                  id="fee"
                  v-model="form.fee"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="fee_calculation">Fee Calculation</label>
                <input
                  id="fee_calculation"
                  v-model="form.fee_calculation"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="transaction_fee">Transaction Fee</label>
                <input
                  id="transaction_fee"
                  v-model="form.transaction_fee"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="transaction_fee_calculation">Transaction Fee Calculation</label>
                <input
                  id="transaction_fee_calculation"
                  v-model="form.transaction_fee_calculation"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="autopay"
                  v-model="form.autopay"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="autopay"
                > Require AutoPay</label>
              </div>

              <div class="form-group">
                <label for="terms">Terms</label>
                <textarea id="terms" class="form-control" v-model="form.terms" rows="3"></textarea>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-lg-6 col-sm-12">

        <div class="card">
          <div class="card-body">

            <p class="card-title">{{ client_plans.length }} Client Plans</p>

            <ul class="list-group">

              <template v-for="(client_plan, plan_index) in client_plans">
              <li :key="'client_plan_'+plan_index" class="list-group-item list-group-item-action"><a :href="'/client_plans/'+client_plan.uuid">{{ client_plan.amount }} {{ client_plan.created_at | datetime }}</a></li>
              </template>

            </ul>

          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      uploading: false,
      form: {},
      queries: {},
      portals: [],
      locations: [],
      languages: [],
      client_plans: [],
      total: 0
    }
  },

  created() {
    this.resetForm();
    this.getLanguages();
    this.getRecord();
    this.getPortals();
    this.getLocations();
  },

  methods: {

    resetForm() {
      this.form = {
        name: null,
        max_payments: 12,
        max_duration: 365,
        fee: 1,
        fee_calculation: 'one-time',
        transaction_fee: 0,
        transaction_fee_calculation: 'one-time',
        autopay: true,
        terms: null
      }
    },

    getLocations () {
        this.$http.get('/locations?limit=1000&sort=name&sortDirection=asc&slim=true&fields=uuid,name')
        .then(response => {
            if (response && response.data) {
                this.locations = response.data;
            }
        })
    },

    getPortals() {
      this.$http.get('/portals?slim=true&limit=150&fields=name,uuid')
      .then(response => {
        if(response && response.data) {
          this.portals = response.data;
        }
      })
    },

    getRecord() {

        this.processing = true;

        this.$http.get(this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                
                this.form = response.data;

                if(!response.data.fields) {
                  this.form.fields = {};
                }

                this.processing = false;

                if(this.form.uuid) {
                  this.getClientPlans();
                }
            }
        })
    },

    getClientPlans() {
      this.$http.get('/client_plans?plan='+this.form.uuid+'&sort=created_at&sortDirection=desc')
      .then(response => {
        if(response && response.data) {
          this.client_plans = response.data;
        }
      })
    },

    getLanguages() {
        this.$http.get('/languages')
        .then(response => {
            if(response && response.data) {
                this.languages = response.data;
            }
        })
    },

    postForm() {

      this.processing = true;

      this.$http.patch(this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
          }

        }
      })
    },


    restoreRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
      .then(response => {
        if(response && response.data) {
              
              this.processing = false;

              if(response.status === 200) {
                new this.$noty({'text': this.$route.meta.title + ' restored'}).show()
                this.form.deleted_at = null;
                this.form.archived_at = null;
                this.form.updated_at = new Date().toISOString();
              }

        }
      })
    },


  trashRecord () {
      this.processing = true;
      this.$http.delete(this.$route.meta.base_url + '/' + this.form.uuid)
      .then((response) => {
        if(response && response.status) {
          this.processing = false;

          if(response.status === 204) {
            new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
            this.form.deleted_at = new Date().toISOString();
          }
        }
      })
  }

  }
}
</script>