<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <template v-if="form.photo">
          <img :src="form.photo" class="img-thumbnail rounded float-left mr-3" width="75" :alt="form.name + ' photo'">
        </template>
        <h1>{{ form.name }}</h1>
        <h3>{{ form.phone_number }}</h3>
        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-2">
        <template v-if="setPortalLink()">
          <a :href="setPortalLink()" target="_blank" class="btn btn-outline-primary btn-block">
            <i class="fa fa-external-link-alt"></i> View on Portal
          </a>
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-6 text-right">
        <div class="btn-group">

          <div class="btn-group">
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">
              <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
              >Archive</a>
              <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
              >Restore</a>
              <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
              >Trash</a>
            </div>
          </div><!--BtnGroup-->

          <button
              v-if="!form.archived_at && !form.deleted_at"
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
          >
            Save
          </button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title">
                  Edit {{ $route.meta.title }}
                </p>
              </div><!--Col-->

              <div class="col-6 text-right">
              </div><!--Col-->
            </div><!--Row-->

            <form
                @submit.prevent="postForm"
            >

              <div class="form-group">
                <label for="name">Name</label>
                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    required
                >
              </div>

              <div class="form-group">
                <label for="reference">Reference</label>
                <input
                    id="reference"
                    v-model="form.reference"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="photo">Photo URL</label>
                <input id="photo" type="text" class="form-control" v-model="form.photo">
              </div>

              <div class="form-group">
                <label for="type">Type</label>
                <select
                    id="type"
                    v-model="form.type"
                    class="form-control text-capitalize"
                >
                  <option value=""></option>
                  <template v-if="(types && types.length > 0)">
                    <template v-for="(type, index) in types">
                      <option :key="'search_type_'+index" :value="type">{{ type }}</option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="location">Default Location</label>
                <select
                    id="location"
                    v-model="form.location"
                    class="form-control text-capitalize"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-if="(locations && locations.length > 0)">
                    <template v-for="(location, location_index) in locations">
                      <option :key="'location_'+location_index" :value="location.uuid">{{ location.name }}</option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="portal">Portal</label>
                <select
                    id="portal"
                    v-model="form.portal"
                    class="form-control text-capitalize"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-if="(portals && portals.length > 0)">
                    <template v-for="(portal, portal_index) in portals">
                      <option :key="'portal_'+portal_index" :value="portal.uuid">{{ portal.name }} {{ portal.link }} {{ portal.domain }}</option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="address">Address</label>
                <input
                    id="address"
                    v-model="form.address"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="address_2">Address 2</label>
                <input
                    id="address_2"
                    v-model="form.address_2"
                    type="text"
                    class="form-control">
              </div>

              <div class="form-group">
                <label for="city">City</label>
                <input
                    id="city"
                    v-model="form.city"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="state">State</label>
                <input
                    id="state"
                    v-model="form.state"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="zip">Zip</label>
                <input
                    id="zip"
                    v-model="form.zip"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="county">County</label>
                <input
                    id="county"
                    v-model="form.county"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="phone">Phone</label>
                <input
                    id="phone"
                    v-model="form.phone"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="email">Email</label>
                <input
                    id="email"
                    v-model="form.email"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="website">Website</label>
                <input
                    id="website"
                    v-model="form.website"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="virtual_link">Virtual Link</label>
                <input
                    id="virtual_link"
                    v-model="form.virtual_link"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="description">Description</label>
                <textarea
                    v-if="form"
                    id="description"
                    v-model="form.description"
                    type="text"
                    class="form-control"
                ></textarea>
              </div>

              <div class="form-group">
                <label for="url">URL</label>
                <input id="url" type="text" class="form-control" v-model="form.url">
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
            class="mt-3"
            :form="form"
        />
      </div><!--Col-->


      <div class="col-sm-12 col-md-3">

        <template v-if="form.location">
          <location-card :uuid="form.location" />
          <br>
        </template><!--Location-->

        <div class="card" v-if="form.name">
          <div class="card-body">

            <div class="row mb-3">
              <div class="col-sm-12 col-md-6">
                <p class="card-title"><a :href="'/events?judge_name='+form.name">{{ events.length }} Events</a></p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-right">
              </div><!--Col-->
            </div><!--Row-->

            <ul class="list-group" v-if="events && events.length > 0">

              <template v-for="(event, index) in events">
                <li :key="'event_'+index" class="list-group-item list-group-item-action">
                  <router-link :to="{'name': 'events.edit', 'params': {'id': event.uuid}}">
                    <i class="fa fa-edit"></i> <span class="sr-only">Edit</span> {{ event.date | date }} {{ event.time | time }} {{ event.type }} {{ event.description }}

                    <template v-if="event.location">
                      {{ setLocationName(event.location) }}
                    </template>
                  </router-link>
                </li>
              </template>
            </ul>

            <template v-if="!processing && events && events.length === 0">
             <div class="alert alert-warning">No events found</div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from "lodash";

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      total: 0,
      queries: {
        limit: 100,
        slim: true,
        sort: 'name',
        sortDirection: 'asc'
      },
      events: [],
      locations: [],
      portal: {},
      portals: [],
      form: {},
      types: ['supervision', 'guardian', 'law enforcement', 'employer', 'school', 'court', 'medical', 'other'],
    }
  },

  computed: {

  },

  created() {
    this.resetForm();
    this.setURLParams();
    this.getRecord();
    this.getLocations();
    this.getPortals();
  },

  methods: {

    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    postForm() {
      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.data.updated_at) {
                this.form = {...this.form, ...response.data};
                this.setTitle();
                this.getPortal();
                new this.$noty({text: this.$route.meta.title + ' updated'}).show();
              }

            }
          })
    },

    getPortal() {
      if(!this.form.portal) {
        return;
      }

      this.$http.get('/portals/'+this.form.portal)
      .then(response => {
        if(response && response.data) {
          this.portal = response.data;
        }
      })
    },

    resetForm() {
      this.form = {
        name: null,
        type: null,
        address: null,
        address_2: null,
        city: null,
        state: null,
        zip: null,
        county: null,
        phone: null,
        email: null,
        website: null,
        virtual_link: null,
        description: null
      }
    },

    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {

              if(response.data.uuid) {
                this.form = response.data;
                this.setTitle();
                this.getEvents();
                this.getPortal();
              }

              this.processing = false;
            }
          })
    },

    setTitle() {
      document.title = this.form.name + ' | ' + this.$route.meta.title;
    },

    restoreRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
              }

            }
          })
    },

    archiveRecord () {
      this.processing = true
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                this.form.archived_at = new Date().toISOString();
              }

            }

          })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
    },

    getEvents() {
      this.$http.get('/events?judge_name='+this.form.name+'&limit=10&sort=created_at&sortDirection=desc&slim=true&fields=uuid,date,time,type,description,location')
          .then(response => {
            if(response && response.data) {
              this.events = response.data;
            }
          })
    },

    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      new this.$noty({text: 'Copied to clipboard'}).show();
    },

    getLocations() {
      this.$http.get('/locations?slim=true&fields=name,uuid&limit=1000&sort=name&sortDirection=desc')
          .then(response => {
            if(response && response.data) {
              this.locations = response.data;
            }
          })
    },

    getPortals() {
      this.$http.get('/portals?slim=true&fields=name,uuid&limit=1000&sort=name&sortDirection=desc&domain=ecase.io')
          .then(response => {
            if(response && response.data) {
              this.portals = response.data;
            }
          })
    },

    setLocationName(value) {

      if(!value) {
        return;
      }

      var location = _.find(this.locations, {'uuid': value});

      if(!location || !location.name) {
        return;
      }

      return location.name;
    },

    setPortalLink() {

      if(!this.portal || !this.portal.uuid) {
        return;
      }

      return 'https://'+this.portal.link+'.'+this.portal.domain+'/judges/'+this.form.url;
    }


  },
}
</script>