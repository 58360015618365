<template>
  <div>
    <div class="row mb-3">
      <div class="col-lg-9 col-md-7 order-1 order-md-0 mt-2 mt-md-0">
        <h4 class="m-0">
          {{ form.firm_name }} {{ form.bar_number }}
        </h4>
        <span
          v-if="form.first_name"
          class="mr-1"
        >{{ form.first_name }}</span>
        <span
          v-if="form.last_name"
          class="mr-1"
        >{{ form.last_name }}</span>
        <span v-text="form.email" />
      </div><!--Col-->
      <div class="col-lg-3 col-md-5 text-right" /><!--Col-->
    </div><!--Row-->
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Edit Attorney
            </p>

            <form @submit.prevent="postForm">
              <div class="row">
                <div class="form-group col-6">
                  <label for="firm_name">Firm Name</label>
                  <input
                    id="firm_name"
                    v-model="form.firm_name"
                    type="text"
                    class="form-control"
                    required
                  >
                </div>
                <div class="form-group col-6">
                  <label for="bar_number">Bar Number</label>
                  <input
                    id="bar_number"
                    v-model="form.bar_number"
                    type="text"
                    class="form-control"
                  >
                </div>
                <div class="form-group col">
                  <label for="first_name">First Name</label>
                  <input
                    id="first_name"
                    v-model="form.first_name"
                    type="text"
                    class="form-control"
                  >
                </div>
                <div class="form-group col">
                  <label for="last_name">Last Name</label>
                  <input
                    id="last_name"
                    v-model="form.last_name"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div><!--Row-->
              <div class="row">
                <div class="form-group col">
                  <label for="email">Email</label>
                  <input
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="form-control"
                  >
                </div>
                <div class="form-group col">
                  <label for="phone">Phone</label>
                  <input
                    id="phone"
                    v-model="form.phone"
                    type="tel"
                    class="form-control"
                  >
                </div>
              </div><!--Row-->

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Save
              </button>
            </form>

            <button
              type="button"
              class="btn btn-danger btn-sm mt-3"
              :disabled="processing"
              @click="trashForm"
            >
              TRASH
            </button>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title m-0">
              Notification Settings
            </p>

            <p class="m-0 text-muted">
              Send automated notifications of upcoming case events.
            </p>

            <form @submit.prevent="postNotifies()">
              <div class="mt-3 form-group">
                <label for="frequency">Frequency</label>
                <select
                  id="frequency"
                  v-model="notify.frequency"
                  class="form-control"
                >
                  <option value=""></option>
                  <option value="daily">
                    Daily
                  </option>
                  <option value="weekly">
                    Weekly
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="from_email">From Email</label>
                <select
                  id="from_email"
                  v-model="notify.from_email"
                  class="form-control"
                >
                  <option value="" />
                  <template v-if="from_emails && from_emails.length">
                    <template v-for="(from_email, index) in from_emails">
                      <option
                        :key="'from_email_'+index"
                        :value="from_email.email"
                      >
                        {{ from_email.email }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Save
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
              <p class="card-title">
                {{ total_cases }} Cases
              </p>

            
            <form @submit.prevent="searchCases()">

            <div class="form-group">
              <label for="case_number">Search Cases</label>
              <input id="case_number" type="text" class="form-control" @change="searchCases()" v-model="addCase.case_number" required>
            </div>

            <button type="submit" class="btn btn-primary mb-3" :disabled="processing">Search</button>

            </form>

            <template v-if="search_cases && search_cases.length">
              <template v-for="(search_case, search_case_index) in search_cases">
                <li :key="'search_case_'+search_case_index" class="list-group-item list-group-item-action"><p class="fw-bold">{{ search_case.case_number }}</p>

                  <template v-if="search_case.attorneys">
                    <p class="m-0 text-muted small">Attorneys:</p>
                    <template v-for="(attorney, attorney_index) in search_case.attorneys">
                      <p class="m-0">{{ attorney.firm_name }} {{ attorney.bar_number }}</p>
                    </template>
                  </template>
                
                  <div class="btn-group">
                    <button type="button" class="btn btn-success btn-sm" @click="attachCase(search_case)" :disabled="processing">Attach</button>
                    <a :href="'/cases/'+search_case.uuid" target="_blank" class="btn btn-secondary btn-sm" :disabled="processing"><i class="fa fa-edit"></i> <span class="sr-only">Edit Case</span></a>
                  </div>
                </li>
              </template>
            </template>

            <template v-if="attorney_cases && attorney_cases.length">
              <template v-for="(attorney_case, index) in attorney_cases">
                <router-link
                  tag="li"
                  :to="{'name': 'cases.edit', 'params': {'id': attorney_case.uuid }}"
                  :key="'attorney_case_'+index"
                  class="list-group-item list-group-item-action link-hover"
                >
                  <template v-if="attorney_case.case_number">
                    <p class="m-0">
                      Case Number: {{ attorney_case.case_number }}
                    </p>
                  </template>

                  <template v-if="attorney_case.client_reference">
                    <p class="m-0">
                      Client: {{ attorney_case.client_reference }} {{ attorney_case.first_name }} {{ attorney_case.last_name }}
                    </p>
                  </template>

                  </router-link>
              </template>
            </template>

          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
export default {

  data () {
    return {
      processing: false,
      form: {},
      attorney_cases: [],
      search_cases: [],
      total_cases: 0,
      notify: {},
      addCase: {
        case_number: ''
      },
      from_emails: []
    }
  },
  
  created () {
    this.getRecord();
    this.getNotifies();
    this.getFromEmails();
  },

  methods: {

    getRecord () {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
      .then((response) => {
        if (response && response.data) {
          this.form = response.data;
          this.processing = false;

          this.getCases();

          if(this.form.bar_number) {

            var name = this.form.firm_name || this.form.last_name || 'Attorney';

            document.title = this.form.bar_number + ' ' + name + ' | eCourtDate.com';
          }
        }
      })
    },

    getNotifies() {
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/notifies')
      .then(response => {
        if(response && response.data && response.data[0]) {
          this.notify = response.data[0];
        }
      })
    },

    postForm() {
      this.processing = true;
      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.status === 200) {
          this.processing = false;
          this.form = {...this.form, ...response.data};
          new this.$noty({text: this.$route.meta.title + ' updated'}).show();
        }
      })
    },

    trashForm () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
      .then(response => {
        if (response) {
          new this.$noty({text: this.title + ' trashed', type: 'error'}).show();
          this.processing = false;
          this.$router.push({name: this.$route.meta.base_url+'.index'});
        }
      })
    },

    getCases() {
      this.totalCases();
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/cases')
      .then(response => {
        if(response && response.data) {
          this.attorney_cases = response.data;
        }
      })
    },

    totalCases() {
      this.$http.get('/cases/total?attorneys='+this.form.uuid)
      .then(response => {
        if(response && response.status === 200) {
          this.total_cases = response.data;
        }
      })
    },

    getFromEmails() {
      this.$http.get('/agency_emails?slim=true&limit=100&fields=email,uuid&template=html')
      .then(response => {
        if(response && response.data) {
          this.from_emails = response.data;
        }
      })
    },

    searchCases() {
      this.processing = true;
      this.$http.get('/cases?slim=false&fields=uuid,case_number,attorneys&search='+this.addCase.case_number)
      .then(response => {
        if(response && response.data) {
          this.search_cases = response.data;
          this.processing = false;
        }
      })
    },

    postNotifies() {
      this.processing = true;

      this.notify.uuid = this.form.uuid;

      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/notifies', this.notify)
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          this.notify = response.data;
          new this.$noty({text: 'Notification settings updated'}).show();
        }
      })
    },

    attachCase(search_case) {
      this.processing = true;

      var form = {};

      form.attorneys = [this.form.uuid];

      this.$http.patch('/cases/'+search_case.uuid, form)
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          this.search_cases = [];
          this.addCase.case_number = '';
          this.getCases();
          new this.$noty({text: 'Case attached'}).show();
        }
      })
    }
  }
}
</script>
