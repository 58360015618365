<template>
  <div>

    <page-tabs page="messages" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="searchRecords()">
              <p class="card-title">
                Search {{ $route.meta.title}}s
              </p>


              <div class="form-group">
                <label for="from_date">From Date</label>
                <input
                  id="from_date"
                  v-model="queries.from_date"
                  placeholder="mm/dd/yyyy"
                  type="date"
                  class="form-control"
                >
              </div>
                          
              <div class="form-group">
                <label for="from_time">From Time</label>
                <input
                  id="from_time"
                  v-model="queries.from_time"
                  placeholder="hh:mm AM"
                  type="time"
                  class="form-control"
                  step="1"
                >
              </div>
                          
              <div class="form-group">
                <label for="to_date">To Date</label>
                <input
                  id="to_date"
                  v-model="queries.to_date"
                  placeholder="mm/dd/yyyy"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="to_time">To Time</label>
                <input
                  id="to_time"
                  v-model="queries.to_time"
                  placeholder="hh:mm AM"
                  type="time"
                  class="form-control"
                  step="1"
                >
              </div>

              <div class="form-group">
                <label for="country">Country</label>
                <select
                  id="country"
                  v-model="queries.country"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-if="countries && countries.length > 0">
                    <template v-for="(country, index) in countries">
                      <option
                        :key="'country_'+index"
                        :value="country.code"
                      >
                        {{ country.code }} - {{ country.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>
              
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->


        <ip-address-card ip="self" class="mt-3" />

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <template v-if="total >= 0">
                  <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
                  </p>
                </template>
              </div><!--Col-->
            </div><!--Row-->

            <div class="table-responsive">
            <table class="table table-striped table-hover">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="'/'+$route.meta.base_url"
              />

              <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="(record, index) in records">
                    <tr :key="'open_'+index">
                      <td>
                        <router-link :to="{ name: $route.meta.base_url+'.edit', params: {id: record.uuid}}">
                          {{ record.ip_address }}
                        </router-link>
                      </td>

                      <td>
                        <template v-if="record.message">
                          <router-link :to="{ name: 'messages.edit', params: {id: record.uuid}}">
                            <i class="fa fa-comment" /> <span class="sr-only">Message</span>
                          </router-link>
                        </template>
                      </td>

                      <td>{{ record.url }}</td>
                      <td>{{ record.created_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      queries: {
        skip: 0,
        slim: true,
        country: null,
        sort: 'created_at',
        sortDirection: 'desc',
        fields: 'uuid,client,message,ip_address,url,created_at'
      },
      records: [],
      total: 0,
      columns: [
          {key: 'ip_address', label: 'Open IP Address'},
          {key: 'message', label: 'Message'},
          {key: 'url', label: 'URL'},
          {key: 'created_at', label: 'Created'}
      ],
      countries: []
    }
  },

    mounted() {

    var self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

        if(view !== self.$route.meta.base_url) {
            return;
        }

        self.resetRecords();

        self.getRecords();

    });

    },

  created() {
    this.setURLParams();
    this.getRecords();
    this.getCountries();
  },

  methods: {

    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    resetRecords() {
        this.records = [];
        this.total = 0;
        this.queries.skip = 0;
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    getRecords() {
      this.getTotal();
      this.processing = true;
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
          if(response && response.data) {

              this.records = this.records.concat(response.data);
              this.queries.skip = this.records.length;

              this.processing = false;

          }
      })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          document.title = this.total + ' ' +this.$route.meta.title + 's | eCourtDate.com';
        }
      })
    },

    getCountries() {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/country_codes.json')
        .then(response => {
            if(response && response.data) {
                this.countries = response.data;
            }
        })
    }
  },
}
</script>