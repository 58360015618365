<template>
  <div>
    <div
      :key="message.uuid"
      class="row mb-1"
    >
      <div class="col-12">
        <router-link
          tag="p"
          class="m-0 link-hover"
          :class="getClass(message)"
          :to="{'name': 'messages.edit', 'params': {'id': message.uuid}}"
        >
          <template v-if="message.subject">
            <em>{{ message.subject }}</em><br>
          </template>

          <div ref="content"></div>

        </router-link>
      </div><!--Col-->
    </div><!--Row-->

    <div
      :key="`${message.uuid}_row`"
      class="row mb-3"
    >
      <div class="col-sm-12 col-md-9">

        <template v-if="message.last_status && !message.error_code">
          <a
            :href="'/messages/'+message.uuid"
            :class="setStatusClass(message.last_status)"
            class="badge-pill text-uppercase link-hover mr-1"
          >
            {{ message.last_status }}
          </a>
        </template>

          <template v-if="message.last_status && message.error_code">
            <span class="badge badge-pill badge-danger mr-1"><span class="text-uppercase">{{ message.last_status }}</span> <small>{{ setErrorCode(message.error_code) }}</small></span>
          </template>


        <template v-if="message.uuid && !message.last_status && message.direction == 'outbound'">
          <div
            class="spinner-grow spinner-grow-sm text-primary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </template>

        <template v-if="message.test === 1">
          <span class="badge badge-pill badge-primary mr-1">SIMULATED</span>
        </template>
                            
        <template v-if="message.mms && message.mms > 0">
          <a :href="'/messages/'+message.uuid"><span class="badge badge-primary mr-1"><i class="fa fa-photo-video" /> MEDIA</span></a>
        </template>

        <template v-if="message.last_open">
          <a :href="'/opens?message='+message.uuid">
            <span class="badge badge-pill badge-success">                                    
              OPENED
              {{ message.last_open | dateTimezone | timeago }}
            </span>
          </a>
        </template>

        <strong class="mr-1">From</strong>

        <template v-if="message.direction == 'outbound' && message.channel == 'email'">
          <a :href="'/agency_emails/'+message.from" class="mr-1">{{ message.from }}</a>
        </template>

        <template v-if="message.direction == 'outbound' && message.channel != 'email'">
          <a :href="'/agency_phones/'+message.from" class="mr-1">{{ message.from }}</a>
        </template>

        <template v-if="message.direction == 'inbound'">
          <a :href="'/messages?search_field=from&search='+message.from" class="mr-1">{{ message.from }}</a>
        </template>

                                
        <strong class="mr-1">To</strong>

        <template v-if="message.direction == 'inbound' && message.channel == 'email'">
          <a :href="'/agency_emails/'+message.to" class="mr-1">{{ message.to }}</a>
        </template>

        <template v-if="message.direction == 'inbound' && message.channel != 'email'">
          <a :href="'/agency_phones/'+message.to" class="mr-1">{{ message.to }}</a>
        </template>

        <template v-if="message.direction == 'outbound'">
          <a :href="'/messages?search_field=to&search='+message.to" class="mr-1">{{ message.to }}</a>
        </template>

        {{ message.scheduled_at | dateTimezone | timeago }}

      </div><!--Col-->

      <div class="col-sm-12 col-md-3 text-right">
        <template v-if="message.client && $route.name != 'clients.edit'">
          <client-button :uuid="message.client" />
        </template>
      </div>
    </div><!--Row-->       
  </div>
</template>
<script>
import _ from 'lodash';
export default {

    props: [ 'message' ],

    data() {
    return {        
        processing: false,
        form: {}
      }
    },

    mounted() {

      var self = this;
  
      this.$eventBus.$on('messages', function (data) {
          self.updateRecord(data);
      });


      this.$eventBus.$on('opens', function(data) {
          self.addNewOpen(data);
      });

      this.attachContent();

      },

methods: {

    setStatusClass(status) {
        if(_.includes(['undelivered', 'failed', 'duplicate', 'bounced', 'missed', 'invalid'], status)) {
            return 'badge badge-danger'
        }
        if(_.includes(['delivered', 'opened', 'answered'], status)) {
            return 'badge badge-success'
        }
        if(_.includes(['dispatched', 'sent'], status)) {
            return 'badge badge-primary'
        }
        return 'badge badge-primary'
    }, 

    getClass(message) {
        if(message && message.direction) {
            return message.direction
        } 
        return 'outbound';
    },

    addNewOpen(data) {

      if(!data.message) {
        return;
      }

      if(data.message != this.message.uuid) {
        return;
      }

      if(data.created_at) {
        this.message.last_open = data.created_at;
      }
    },

    updateRecord(data) {

      if(!data.uuid) {
        return;
      }

      if(data.uuid != this.message.uuid) {
        return;
      }

      this.message = {...this.message, ...data};
      
    },

    setErrorCode(code) {

      if(!code) {
        return;
      }

      if(!this.$root['error_codes']) {
        return;
      }

      if(!this.$root['error_codes'][code]) {
        return;
      }

      return this.$root['error_codes'][code];
    },

    attachContent() {

      if(!this.message.content) {
        return;
      }

      const shadow = this.$refs.content.attachShadow({ mode: 'open' });
      shadow.innerHTML = this.$DOMPurify.sanitize(this.message.content);
    }
}
}
</script>