<template>
  <div id="main" class="app">
    <nav-bar />

    <template v-if="$route.meta.requiresAuth">
      <div class="container-fluid mb-3">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <agency-switcher />
        </div><!--Col-->

        <div class="col-sm-12 col-md-4">
          <deactivated-alert />
        </div><!--Col-->

        <div class="col-sm-12 col-md-4 text-right">
          <div class="btn-group d-print-none" data-html2canvas-ignore>
          <router-link tag="button" :to="{'name': 'locations.index' }" class="btn" :class="setToolClass('locations')" data-toggle="tooltip" data-tooltip="Go to Locations" onclick="this.blur()"><i class="fa fa-location-dot"></i> <span class="sr-only">Go to Locations</span></router-link>
          <router-link tag="button" :to="{ 'name': 'files.index' }" class="btn" :class="setToolClass('files')" data-toggle="tooltip" data-tooltip="Go to Files" onclick="this.blur()"><i class="fa fa-folder-bookmark"></i> <span class="sr-only">Go to Files</span></router-link>
          <router-link tag="button" :to="{ 'name': 'calendars.index' }" class="btn" :class="setToolClass('calendars')" data-toggle="tooltip" data-tooltip="Go to Calendars" onclick="this.blur()"><i class="fa fa-calendar"></i> <span class="sr-only">Go to Calendars</span></router-link>
          <router-link tag="button" :to="{ 'name': 'phones.index' }" class="btn" :class="setToolClass('phones')" data-toggle="tooltip" data-tooltip="Make Calls" onclick="this.blur()"><i class="fa fa-headset"></i> <span class="sr-only">Make Calls</span></router-link>
          </div>
        </div><!--Col-->
      </div><!--Row-->

        <template v-if="$root.current_region && $root.current_region == 'staging'">
          <div class="alert alert-secondary my-3 d-print-none" data-html2canvas-ignore>You are currently in the staging region. Any actions performed will not affect live regions or send any outbound messages.</div>
        </template>

      </div><!--Container-->
    </template>

    <div class="px-3">
      <app-errors />
      <router-view />
    </div>

    <template v-if="$route.meta.requiresAuth">
      <help-center />
      <chats-feed />
      <footer-bar />
    </template>
  </div>
  
</template>
<script>
import auth from '../mixins/auth';
import Noty from "noty";
import DeactivatedAlert from "@/components/DeactivatedAlert.vue";
import ChatsFeed from "@/components/ChatsFeed.vue";
export default {
  components: {ChatsFeed, DeactivatedAlert},

  mixins: [ auth ],

    data() {
        return {
          processing: false,
          preferences: {}
        }
    },

  mounted() {

    // var self = this;

    // this.$eventBus.$on('preferencesUpdated', function (form) {
    //   localStorage.removeItem('preferences');
    //   localStorage.removeItem('theme');
    //   var preferences = {...self.preferences, ...form};
    //   self.preferences = null;
    //   self.setUserPreferences(preferences);
    //   self.subPub();
    // });

  },

  created() {

      if(this.$route.meta.requiresAuth) {
        this.getCurrentUser();
        this.getPreferences();
      }

    },

  methods: {

    getPreferences() {

      if(this.$route.name == 'no-agencies') {
        return;
      }

      // if(this.preferences && this.preferences.theme) {
      //   return;
      // }

      // if(localStorage.getItem('preferences')) {
      //   this.setUserPreferences(JSON.parse(localStorage.getItem('preferences')));
      //   return;
      // }

      this.processing = true;

      this.$http.get('/users/preferences')
          .then(response => {
            if(response && response.data) {
              localStorage.setItem('preferences', JSON.stringify(response.data));
              this.setUserPreferences(response.data);
              this.processing = false;
            }
          })
    },

    setUserPreferences(preferences) {
      this.preferences = preferences;
      this.$root.preferences = preferences;
      this.setNoty();
      this.setAppTheme();
      this.setAppFields();
    },

    setAppTheme() {

      let theme = this.preferences['theme'] || localStorage.getItem('theme') || 'light';

      localStorage.setItem('theme', theme);

      if (theme == 'auto' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        theme = 'dark';
      }

      document.body.setAttribute('data-theme', theme);
    },

    setAppFields() {
      if(this.preferences['client_fields']) {
        localStorage.setItem('client_fields', JSON.stringify(this.preferences['client_fields']));
        this.$eventBus.$emit('setClientFields');
      }
    },

    setNoty() {
      Noty.overrideDefaults({
        timeout: this.preferences['notify_timeout'] || 750,
        type: 'success',
        layout: this.preferences['notify_layout'] || 'topRight',
        theme: 'bootstrap-v4',
      });
    },

    setToolClass(path) {
      if(this.$route.path == '/'+path) {
        return 'btn-outline-primary';
      }

      return 'btn-outline-secondary';
    }


  }
}
</script>
