<template>
  <div>

      <div class="row">
        <div class="col-md-6 mx-auto">

          <div class="row">

            <div class="col-6">

            <h1>{{ $route.meta.title }}</h1>

            </div><!--Col-->

            <div class="col-6 text-right">

              <div class="btn-group">
              <router-link tag="button" :to="{name: 'checklist.index'}" class="btn btn-outline-secondary"><i class="fa fa-tasks"></i> Setup Checklist</router-link>
              </div>

            </div><!--Col-->

          </div><!--Row-->

          <div class="card">
            <div class="card-body">

              <form @submit.prevent="postSetup()">

                  <div class="form-group">
                    <label for="name">Agency Name</label>
                    <input id="name" class="form-control" type="text" v-model="form.name" required>
                  </div>

                  <div class="form-group">
                    <label for="address">Agency Address</label>
                    <input id="address" class="form-control" v-model="form.address" autocomplete="street-address">
                  </div>

                  <div class="form-group">
                    <label for="address_2">Address 2</label>
                    <input id="address_2" class="form-control" v-model="form.address_2" autocomplete="address-line2">
                  </div>

                  <div class="form-group">
                    <label for="city">City</label>
                    <input id="city" class="form-control" name="city" v-model="form.city" autocomplete="address-level2">
                  </div>

                  <div class="form-group">
                    <label for="state">State</label>
                    <select
                      id="state"
                      v-model="form.state"
                      class="form-control"
                      autocomplete="address-level1"
                      :disabled="processing"
                    >
                      <option value="" />
                      <option
                        v-for="state in states"
                        :key="state.key"
                        :value="state.key"
                      >
                        {{ state.label }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="zip">Zip</label>
                    <input id="zip" class="form-control" v-model="form.zip" autocomplete="postal-code">
                  </div>

                  <h4>Use Cases</h4>

                  <template v-for="(use_case, case_index) in use_cases">

                  <div :key="'use_case_'+case_index" class="form-group custom-control custom-switch">
                    <input
                      :id="'case_'+case_index"
                      v-model="form.use_cases[use_case]"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      :for="'case_'+case_index"
                      class="custom-control-label text-capitalize"
                    >{{ use_case | rmDashes }}</label>
                  </div>

                  </template>

                  <h4>Data Sources</h4>

                  <p class="lead">Use eCourtDate with any combination of data sources. Integrate through SFTP or API to operate 100% automatically.</p>

                  <div class="row">

                    <div class="col-sm-12 col-md-4">

                      <div class="card">
                        <div class="card-body">

                          <h4>Manual Entry</h4>

                          <p>Add clients and related data manually one at a time through this application.</p>

                        </div><!--Body-->
                      </div><!--Card-->

                    </div><!--Col-->

                    <div class="col-sm-12 col-md-4">

                      <div class="card">
                        <div class="card-body">

                          <h4>Manual Bulk Uploads</h4>

                          <p>Add clients and related data by manually uploading flat files (CSV and Excel).</p>

                        </div><!--Body-->
                      </div><!--Card-->

                    </div><!--Col-->

                   <div class="col-sm-12 col-md-4">

                      <div class="card">
                        <div class="card-body">

                          <h4>Manual Bulk Actions</h4>

                          <p>Send mass messages and manually upload contact lists for each notification.</p>

                        </div><!--Body-->
                      </div><!--Card-->

                    </div><!--Col-->

                  </div><!--Row-->

                  <div class="row my-3">

                    <div class="col-sm-12 col-md-4">

                      <div class="card">
                        <div class="card-body">

                          <h4>Portal Self-Enroll</h4>

                          <p>Allow clients to self-enroll directly through a multilingual web portal.</p>

                        </div><!--Body-->
                      </div><!--Card-->

                      </div><!--Col-->


                      <div class="col-sm-12 col-md-4">

                        <div class="card">
                          <div class="card-body">

                            <h4>Inbound Self-Enroll</h4>

                            <p>Allow clients to self-enroll by texting or emailing their information to your agency.</p>

                          </div><!--Body-->
                        </div><!--Card-->

                        </div><!--Col-->

                        <div class="col-sm-12 col-md-4">

                            <div class="card">
                              <div class="card-body">

                                <h4>Chatbots</h4>

                                <p>Launch multilingual chatbots to provide guided enrollment on your website.</p>

                              </div><!--Body-->
                            </div><!--Card-->

                            </div><!--Col-->


                  </div><!--Row-->

                  <div class="row my-3">

                    <div class="col-sm-12 col-md-4">

                      <div class="card">
                        <div class="card-body">

                          <h4>Automated Uploads</h4>

                          <p>Upload flat files automatically through our SFTP Gateway.</p>

                        </div><!--Body-->
                      </div><!--Card-->

                      </div><!--Col-->


                      <div class="col-sm-12 col-md-4">

                        <div class="card">
                          <div class="card-body">

                            <h4>API Integration</h4>

                            <p>Integrate with our API to send data, create messages, and manage settings.</p>

                          </div><!--Body-->
                        </div><!--Card-->

                        </div><!--Col-->

                        <div class="col-sm-12 col-md-4">

                            <div class="card">
                              <div class="card-body">

                                <h4>Partner Integrations</h4>

                                <p>Use one of our partner integrations to integrate eCourtDate with your system.</p>

                              </div><!--Body-->
                            </div><!--Card-->

                            </div><!--Col-->


                  </div><!--Row-->

                  <div class="row my-3">

                      <div class="col-sm-12 col-md-4">

                        <div class="card">
                          <div class="card-body">

                            <h4>Purchase Contact Lists</h4>

                            <p>Purchase contacts based on your geographic area. Import in one click.</p>

                          </div><!--Body-->
                        </div><!--Card-->

                        </div><!--Col-->


                        <div class="col-sm-12 col-md-4">

                          <div class="card">
                            <div class="card-body">

                              <h4>Docket Sync</h4>

                              <p>Integrate a third-party portal or case management system to sync case data.</p>

                            </div><!--Body-->
                          </div><!--Card-->

                          </div><!--Col-->

                          <div class="col-sm-12 col-md-4">

                              <div class="card">
                                <div class="card-body">

                                  <h4>Social Integrations</h4>

                                  <p>Connect your agency's Facebook, LinkedIn, YouTube, and X accounts.</p>

                                </div><!--Body-->
                              </div><!--Card-->

                              </div><!--Col-->


                      </div><!--Row-->

                      <p>Don't see a data source or integration that you're interested in? Click the help to chat with our team.</p>


                <div class="row">

                  <div class="col-6">

                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                  <template v-if="$root.current_user && $root.current_user.current_agency && $root.current_user.current_agency.status == 2">
                    Start
                  </template>
                  <template v-else>
                    Update
                  </template>
                    Setup
                  </button>

                  </div><!--Col-->

                  <div class="col-6 text-right">

                  <template v-if="$root.current_user && $root.current_user.current_agency">

                    <button type="button" class="btn btn-danger" @click="skipSetup()" :disabled="processing" v-if="$root.current_user.current_agency.status == 2">
                        Skip Setup
                    </button>

                    <button type="button" class="btn btn-danger" @click="resetSetup()" :disabled="processing" v-if="$root.current_user.current_agency.status == 1 && !reset.name">
                        Reset Setup
                    </button>

                    <button type="button" class="btn btn-danger" @click="undoReset()" :disabled="processing" v-if="$root.current_user.current_agency.status == 1 && reset.name">
                        Undo Reset
                    </button>


                  </template>

                  </div><!--Col-->

                </div><!--Row-->

              </form>

            </div><!--Body-->
          </div><!--Card-->
        </div><!--Col-->
      </div><!--Row-->

  </div>
</template>

<script>
import { autofill } from '@mapbox/search-js-web';

export default {

data() {
    return {
        processing: false,
        success: false,
        response_message: null,
        base_url: '/setup',
        form: {},
        states: [],
        autofill: {},
        general_settings: {
          name: null,
          address: null,
          send_mode: 'live',
          business_from_time: '08:00',
          business_to_time: '18:00',
          business_days: ['mon', 'tue', 'wed', 'thu', 'fri'],
          detect_language: true,
          assign_clients_creator: true,
          languages: [
            {key: 'en', label: 'English'},
            {key: 'es', label: 'Español (spanish)'}
          ]
        },
        agency_phone: {
          name: null,
          phone_number: null,
          primary: true,
          forward_assigned: false,
          record: false,
          outside_business: false
        },
        agency_email: {
          name: null,
          email: null,
          template: 'default',
          primary: true
        },
        search_phones: {
          state: null,
          city: null
        },
        use_cases: ['court_reminders', 'payment_reminders', 'victim_notifications', 'juror_notifications', 'closure_notifications', 'drug_test_notifications'],
        reset: {}
    }
},

mounted() {
  this.autoFill();
},

created() {
    document.title = this.$route.meta.title + ' | eCourtDate.com';
    this.resetForm();
    this.getStates();
    this.getSettings();
},

methods: {

    resetForm() {
        this.form = {
            name: null,
            address: null,
            address_2: null,
            city: null,
            state: null,
            zip: null,
            auto_trash_archived: 90,
            auto_delete_trashed: 90,
            use_cases: {}
        }

    },

    getSettings() {
      this.processing = true;
      this.$http.get('/settings')
      .then(response => {
        if(response && response.data) {
          this.form = response.data;

          if(!this.form.use_cases) {
            this.form.use_cases = {};
          }

          this.processing = false;
        }
      })
    },

    autoFill() {
      this.autofill = new autofill({
        accessToken: this.$root.mapbox_token,
        options: { country: 'us' }
      });

    },

    getStates () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/states.json')
      .then(response => {
        if (response && response.data) {
          this.states = response.data;

          if(this.$root.current_user && this.$root.current_user.current_agency) {
            this.form.name = this.$root.current_user.current_agency.name;
          }
        }
      })
    },

    undoReset() {
      this.form = this.reset;

      this.reset = {};

      this.postSettings();
    },

    resetSetup() {

      this.reset = this.form;

      this.resetForm();

      this.form.name = this.reset.name;

      new this.$noty({text: 'Resetting Settings'}).show();

      this.postSettings();
      
    },

    skipSetup() {
      this.processing = true;
      this.$http.put('/agencies/skip_setup')
      .then(response => {
        if(response && response.status) {

          new this.$noty({text: 'Setup complete'}).show();

          this.processing = false;

          this.resetForm();

          this.$router.push({ name: 'checklist.index' });
        }
      })
    },

    postSetup() {

      if(!this.$root.current_user || !this.$root.current_user.current_agency) {
        return;
      }

      if(this.$root.current_user.current_agency.status == 2) {

      this.getAgencyPhones();

      this.getAgencyEmails();

      }

      this.postSettings();

    },

    postSettings() {

      this.processing = true;

      this.$http.put('/settings', this.form)
      .then(response => {
        if(response && response.status) {

          if(response.status == 200) {
            new this.$noty({text: 'General Settings configured'}).show();
          }

          if(response.data.send_mode) {
            this.$eventBus.$emit('send_mode_changed', response.data.send_mode);
          }

          this.processing = false;

          if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.status == 2) {
            this.skipSetup();
          }
        }
      })
    },

    getAgencyEmails() {
      this.$http.get('/agency_emails/total')
      .then(response => {
        if(response && response.status) {

          if(response.data == 0) {
            this.postAgencyEmail();
          }

        }
      })

    },

    getAgencyPhones() {
      this.$http.get('/agency_phones/total')
      .then(response => {
        if(response && response.status) {

          if(response.data == 0) {

            this.search_phones.state = this.form.state;
            this.search_phones.city = this.form.city;

            this.searchPhoneNumbers();
          }

        }
      })

    },

    searchPhoneNumbers() {
      this.$http.post('/agency_phones/search', this.search_phones)
      .then(response => {
        if(response && response.status) {

          if(response.data && response.data[0] && response.data[0].phone_number) {
            this.postAgencyPhone(response.data[0].phone_number);
          } else if(this.search_phones.state && this.search_phones.city) {
            this.search_phones.city = null;
            this.searchPhoneNumbers();
          } else if(!this.search_phones.city) {
            this.search_phones.state = 'FL';
            this.searchPhoneNumbers();
          }
        }
      })
    },

    postAgencyPhone(phone) {

      this.agency_phone.phone_number = phone;
      this.agency_phone.name = this.form.name;

      this.$http.post('/agency_phones', this.agency_phone)
      .then(response => {
        if(response && response.status) {

          if(response.status === 201) {
            new this.$noty({text: 'Agency Phone activated'}).show();
          }
        }
      })
    },

    postAgencyEmail() {

      this.agency_email.name = this.form.name;
      this.agency_email.email = this.$root.current_user.current_agency.reference+'@ecase.io';

      this.$http.post('/agency_emails', this.agency_email)
      .then(response => {
        if(response && response.status) {
          if(response.status === 201) {
            new this.$noty({text: 'Agency Email activated'}).show();
          }
        }
      })
    }
    
}

}
</script>