<template>
    <div>
  
      <page-tabs :page="$route.meta.tabs" />

      <template v-if="errors && errors.message">
            <div v-if="errors.message != 'Network Error'">
              <div class="alert alert-danger">
                {{ errors.message }}
              </div>
            </div>
      </template>
  
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <div class="card">
            <div class="card-body">
              <p class="card-title">
                Add {{ $route.meta.title }}
              </p>
  
              <form @submit.prevent="postForm()">

                <template v-for="(field, field_index) in fields">

                  <div :key="'field_'+field_index">

                    <template v-if="!field.type">
                    </template>

                    <template v-else-if="field.type == 'text'">

                  <div class="form-group">
                  <label :for="field.key">{{ field.label }}</label>
                  <input
                    :id="field.key"
                    :type="field.type"
                    v-model="form[field.key]"
                    class="form-control"
                    :required="field.required"
                    :hidden="field.hidden"
                    :disabled="processing"
                  >
                  </div>

                    </template>

                    <template v-if="field.type == 'select'">

                      <div class="form-group">
                <label :for="field.key">{{ field.label }}

                <template v-if="form[field.key]">
                  <a
                  :href="'/'+field.options+'/'+form[field.key]"
                  target="_blank"
                  :data-tooltip="'Edit '+field.key">
                  <i class="fa fa-edit"></i> <span class="sr-only">Edit {{ field.label }}</span>
                  </a>
                </template>
                
                </label>
                <select
                  :id="field.key"
                  v-model="form[field.key]"
                  class="form-control"
                  :disabled="processing"
                  :required="field.required"
                >
                  <option value="">
                    Choose {{ field.label }}
                  </option>
                  <template v-for="option in options[field.options]">
                    <option
                      :key="'option_'+option.uuid"
                      :value="option.uuid"
                    >
                      {{ option.name }}
                    </option>
                  </template>
                </select>
              </div>

                    </template>

                  </div>

                </template>
  
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    Add
                  </button>
  
              </form>
            </div><!--Body-->
          </div><!--Card-->
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-9">
          <div class="card">
            <div class="card-body">

              <div class="row">

                <div class="col-sm-12 col-md-6">

                  <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                  </p>

                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-right">

                  <div class="btn-group d-print-none">
                    <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                    <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  </div>
                </div><!--Col-->

                </div><!--Row-->
  
  
              <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <template v-for="(column, index) in fields">
                      <th
                        :key="'column_'+index"
                        class="text-uppercase"
                      >
                        <template v-if="column.key">
                          <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                          >{{ column.label }}</span>
                        </template>
  
                        <template v-if="!column.key">
                          <span class="text-capitalize">{{ column.label }}</span>
                        </template>
  
                        <template v-if="column.key == queries.sort">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                          >
                            <template v-if="queries.sortDirection == 'asc'">
                              <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                            </template>
  
                            <template v-if="queries.sortDirection == 'desc'">
                              <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                            </template>
                          </button>
                        </template>
                      </th>
                    </template>
                  </tr>
                </thead>
  
                <tbody>
                  <template v-if="records && records.length > 0">
                    <template v-for="(record, index) in records">
                      <tr :key="'record_'+index">
                        <template v-for="(column, index) in fields">
                          <td
                            :key="'row_field_'+index"
                            v-if="column.key"
                          >

                          <template v-if="column.key == 'uuid'">
                            <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid}}">
                              <i class="fa fa-edit"></i> Edit
                            </router-link>
                          </template>

                          <template v-else-if="column.key == 'portal'">
                            <router-link :to="{'name': 'portals.edit', 'params': {'id': record.portal}}">
                              {{ setPortalName(record) }}
                            </router-link>
                          </template>

                          <template v-else>
                            {{ record[column.key] }}
                          </template>

                          </td>
                        </template>

                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
              </div><!--Responsive-->
  
              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found.
                </div>
              </template>
            </div><!--Body-->
          </div><!--Card-->
  
          <template v-if="records && records.length && total > records.length">
  
          <div class="row mt-3">
            <div class="col-12 text-center">
  
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
                >
                  Load more
                </button>
  
            </div><!--Col-->
          </div><!--Row-->
  
          </template>
  
        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  <script>
  import _ from "lodash";
  import queries from '../mixins/queries';
  
  export default {
  
    mixins: [ queries ],
  
    data () {
      return {
        processing: false,
        downloading: false,
        errors: null,
        total: 0,
        queries: {},
        downloads: [],
        records: [],
        fields: [],
        form: {},
        options: {
          portals: []
        }
      }
    },

    mounted() {

    var self = this;

    this.$eventBus.$on('errors', function (errors) {
      self.processing = false;
      self.downloading = false;
      self.errors = errors.data || errors;
    });

    },
  
    created() {
      this.setFields();
      this.resetForm();
      this.resetQueries();
      this.resetRecords();
      this.setURLParams();
      this.getRecords();
      this.getPortals();
    },
  
    methods: {
  
  
      setURLParams() {
  
          var searchParams = new URLSearchParams(window.location.search);
  
          var self = this;
  
          searchParams.forEach(function(value, key) {
              self.queries[key] = value;
          });
      },

      setFields () {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/app_fields.json')
          .then(response => {
              if(response && response.data) {
                  this.fields = response.data[this.$route.meta.base_url];
              }
          })
        },
  
      resetQueries() {
        this.queries = {
          limit: 10,
          slim: false,
          sort: 'created_at',
          sortDirection: 'desc'
        }
      },
  
      resetForm() {
        this.form = {
          name: '',
          redirect_url: '',
        }
      },
  
      resetRecords() {
        this.records = [];
        this.total = 0;
        this.queries.skip = 0;
      },
  
      updateSortDirection() {
  
        if(this.processing) {
          return;
        }
  
        if(this.queries.sortDirection == 'asc') {
          this.queries.sortDirection = 'desc';
        } else {
          this.queries.sortDirection = 'asc';
        }
  
        this.resetRecords();
  
        this.getRecords();
      },
  
      sortColumn(column) {
  
        if(this.processing) {
          return;
        }
  
        this.queries.sort = column.key;
  
        this.updateSortDirection();
      },
  
      getRecords() {
  
        this.processing = true;
  
        this.updateURL();
        this.getTotal();
  
        this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {
              
              if(response.data.length) {
  
                  var self = this;
  
                  response.data.forEach(function(data) {
                      self.records.push(data);
                  });
              }
              
              this.queries.skip = this.records.length;
  
              this.processing = false;
          }
        })
      },
  
      getTotal() {
        this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
          if(response && response.status === 200) {
            this.total = response.data;
            this.setTitle();
          }
        })
      },
  
      updateURL() {
        history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
      },
  
      setTitle() {
        document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
      },
  
      postForm() {
        this.processing = true;

        if(!this.form.name) {
            this.form.name = this.form.redirect_url;
        }

        this.$http.post(this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {
  
            this.processing = false;
  
            if(response.status === 201) {
              this.resetForm();
              this.total++;
              this.records.unshift(response.data);
              new this.$noty({text: this.$route.meta.title + ' created'}).show();
            }
  
          }
        })
      },
  
      setPortalName(record) {
  
        if(!record.portal) {
          return;
        }
  
        var portal = _.find(this.options['portals'], {'uuid': record.portal});
  
        if(!portal || !portal.name) {
          return;
        }
  
        return portal.name;
      },
  
      getPortals () {
        this.$http.get('/portals?slim=true&limit=1000&sort=default&sortDirection=desc&fields=uuid,name,link&domain=ecase.io')
            .then(response => {
              if (response && response.data) {
                this.options['portals'] = response.data;

                if(!this.form.portal && this.options['portals'] && this.options['portals'].length) {
                  this.form.portal = this.options['portals'][0].uuid;
                }
              }
            })
      },

      
    async downloadPDF() {
    try {

      window.scrollTo(0,0);

      this.downloading = true;

      const element = document.getElementById('main');

      const canvas = await this.$html2canvas(element, {
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight,
        scrollY: -window.scrollY,
        scrollX: -window.scrollX,
        scale: window.devicePixelRatio,
        useCORS: true,
        logging: false
      });

      const imgData = canvas.toDataURL('image/png');

      const pdf = new this.$jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height]
      });

      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

      var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

      pdf.save(name+'.pdf');

      this.downloading = false;
    } catch (error) {
      new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
      console.log(error);
      this.downloading = false;
    }
  },

  downloadCSV () {
    this.downloading = true;

    var queries = this.queries;

    queries.limit = this.total;

    queries.skip = this.downloads.length;

    this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
        .then(response => {
          if(response && response.data && response.data) {
            this.downloads = this.downloads.concat(response.data);

            var csvFile, data, link;

            data = this.convertCSV(this.downloads);

            var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

            csvFile = new Blob([data], {type: "text/csv"});
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(csvFile, file);
            } else {
              link = document.createElement("a");
              link.download = file;
              link.href = window.URL.createObjectURL(csvFile);
              link.style.display = "none";
              document.body.appendChild(link);
              link.click();
            }

            this.downloading = false;

          } else {
            this.downloadCSV();
          }

        });
  },

  convertCSV(records) {

    var results = [];

    if(records[0] && records[0]['uuid']) {
      var headers = Object.keys(records[0]);
    }

    results += headers.join(',')

    results += '\n';

    records.forEach(function(item) {
      var record = '';

      if(!item) {
        return;
      }

      Object.keys(item).forEach(function(key) {
        record += '"' + (item[key] ? item[key] : '') + '",';
      });

      results += record;
      results += '\n';
    });

    return results
  },
  
    },
  }
  </script>