<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-3">

          <p class="card-title">
            Add {{ $route.meta.title }}
          </p>

          <div class="input-group-append text-right">
            <button
              class="btn btn-outline-secondary btn-sm"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Fields
            </button>
            <div class="dropdown-menu">
              <h6 class="dropdown-header">
                Fields
              </h6>
              <a
                v-for="(availableField, index) in availableFields"
                :key="availableField.key"
                class="dropdown-item text-capitalize" 
                :class="activeField(availableField.key)"
                @click="toggleField(index)"
              >{{ availableField.title }}</a>
            </div>
          </div>
        </div>

        <form
          ref="addClient"
          @submit.prevent="postForm()"
        >
          <template v-for="(client_field, client_field_index) in clientFields">

            <div :key="'client_field_'+client_field_index" v-if="client_field.show">

              <template v-if="client_field.type != 'tags'">

              <div class="form-group">
                <label :for="client_field.key">{{ setFieldTitle(client_field) }}</label>
                <input
                  :id="client_field.key"
                  v-model="form[client_field.key]"
                  :type="client_field.type || 'text'"
                  class="form-control"
                >
              </div>

              </template>


              <template v-if="client_field.type == 'tags'">

              <div class="form-group">
                <label for="phones">Phones</label>
                <input
                  id="phones"
                  type="text"
                  class="form-control"
                  @keydown.enter="addValue"
                  @keydown.188="addValue"
                />
                <template v-if="phones && phones.length">
                <template v-for="(phone, phone_index) in phones">
                <span :key="'phone_'+phone_index" class="badge badge-pill badge-primary link-hover" @click="removeValue(phone_index)">
                  {{ phone }}
                  x
                </span>
                </template>
                </template>
              </div>

            </template>



            </div>
            </template>


          <template v-if="errors && errors.message">
            <div v-if="errors.message != 'Network Error'">
              <div class="alert alert-danger">
                {{ errors.message }}
              </div>
            </div>
          </template>

          <button
            type="submit"
            class="btn btn-success"
            :disabled="processing"
          >
            Add
          </button>
        </form>
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data() {
        
    return {
        base_url: '/clients',
        processing: false,
        form: {},
        availableFields: [
            {title: 'Client Reference', key: 'client_reference', show: true},
            {title: 'Client Group', key: 'group', show: false},
            {title: 'Client Type', key: 'type', show: false},
            {title: 'First Name', key: 'first_name', show: true},
            {title: 'Middle Name', key: 'middle_name', show: false},
            {title: 'Last Name', key: 'last_name', show: true},
            {title: 'Date of Birth', key: 'dob', show: false, type: 'date'},
            {title: 'Client Gender', key: 'gender', show: false},
            {title: 'Phone', key: 'phone', show: true, type: 'tel'},
            {title: 'Phones', key: 'phones', show: false, type: 'tags'},
            {title: 'Email', key: 'email', show: true, type: 'email'}
        ],
        clientFields: [],
        errors: null,
        auto_message: {},
        phones: []
    }
},


mounted() {

    this.setClientFields();

    if(this.clientFields.length < 1) {
        this.clientFields = this.availableFields
    }

    var self = this;

    this.$eventBus.$on('errors', function (errors) {
        self.errors = errors.data || errors;
    });

    this.$eventBus.$on('setClientFields', function() {
        self.setClientFields();
    });
},


methods: {

    setClientFields() {
        if(localStorage.getItem('client_fields')) {
            this.clientFields = JSON.parse(localStorage.getItem('client_fields'));
        } else {
            this.clientFields = this.availableFields;
        }
    },

    postForm() {

        this.processing = true;

        // this.form.skip_auto = true;

        if(this.phones && this.phones.length) {
          this.form.phones = this.phones.toString();
        }

        this.$http.post(this.base_url, this.form)
        .then(response => {
            if(response && response.data) {

                this.resetForm();
                
                if(response.data.uuid) {
                  this.$eventBus.$emit("addClient", response.data);
                  new this.$noty({text: this.$route.meta.title + ' added'}).show();
                }

                this.processing = false;
            }
        })

    },

    sendAutoMessage(record) {

      if(!this.auto_message.uuid) {
        return;
      }

      this.$http.post('/auto_messages/'+this.auto_message.uuid+'/send?client='+record.uuid)
      .then(response => {
        if(response && response.data) {
          if(response.type) {
            new this.$noty({text: 'Auto Message Triggered ' +response.type}).show();
          }
        }
      })
    },

    resetForm() {
      this.form = {};
      this.errors = null;
      this.phones = [];
    },

    activeField(field) {

        var activeField = _.find(this.clientFields, { 'key': field });

        if(activeField && activeField.show && activeField.show === true) {
            return 'active';
        }
    },

    toggleField(index) {

        if(this.clientFields[index].show) {
            this.clientFields[index].show = false;
        } else {
            this.clientFields[index].show = true;
        }

        localStorage.setItem('client_fields', JSON.stringify(this.clientFields));

        this.$eventBus.$emit('updateClientFields', this.clientFields);

    },

    setFieldTitle(field) {

        var fields = localStorage.getItem('fields');

        if(!fields || !fields.length) {
          return field.title;
        }

        var search = field.key;

        var title = _.find(JSON.parse(fields), { 'key': 'clients.'+search });      

        if(!title || !title.name) {
          return field.title;
        }

        return title.name;
    },

      addValue(event) {

        if(!this.phones) {
          this.phones = [];
        }

        event.preventDefault();

        let val = event.target.value.trim();

        if(!val) {
          return;
        }

        if(this.phones.includes(val)) {
          return;
        }

        this.phones.unshift(val);

        event.target.value = '';
      },

      removeValue(index) {
        this.phones.splice(index, 1);
      }
}
}
</script>