<template>
  <div>

    <page-tabs page="events" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-9">

                <p class="card-title">Add {{ $route.meta.title }}</p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-3 text-right">


              </div><!--Col-->

            </div><!--Row-->

            <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="name">Name</label>
                <input id="name" type="text" class="form-control" v-model="form.name" required>
              </div>

              <div class="form-group">
                <label for="service">Service</label>
                <input id="service" type="text" class="form-control" v-model="form.service">
              </div>

              <div class="form-group">
                <label for="location">Location</label>
                <select
                    id="location"
                    v-model="form.location"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-if="locations && locations.length > 0">
                    <template v-for="(location, index) in locations">
                      <option
                          :key="'location_'+index"
                          :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="language">Language</label>
                <select
                    id="language"
                    v-model="form.language"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-if="languages && languages.length > 0">
                    <template v-for="(language, index) in languages">
                      <option
                          :key="'language_'+index"
                          :value="language.key"
                      >
                        {{ language.label }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="virtual"
                    v-model="form.virtual"
                    type="checkbox"
                    class="custom-control-input"
                >
                <label
                    class="custom-control-label"
                    for="virtual"
                >Virtual</label>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-9">
                <p class="card-title">
                  Search {{ $route.meta.title }}s
                </p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-3 text-right">
                <button
                    type="button"
                    data-tooltip="Reset search fields"
                    class="btn btn-outline-secondary"
                    :disabled="processing"
                    @click="resetSearch()"
                >
                  <i class="fa fa-eraser" /> <span class="sr-only">Reset Form</span>
                </button>
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input
                    id="search"
                    v-model="queries.search"
                    type="search"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_language">Language</label>
                <select
                    id="search_language"
                    v-model="queries.language"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-if="languages && languages.length > 0">
                    <template v-for="(language, index) in languages">
                      <option
                          :key="'search_language_'+index"
                          :value="language.key"
                      >
                        {{ language.label }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_location">Location</label>
                <select
                    id="search_location"
                    v-model="queries.location"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <template v-if="locations && locations.length > 0">
                    <template v-for="(location, index) in locations">
                      <option
                          :key="'search_location_'+index"
                          :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_virtual"
                    v-model="queries.virtual"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing || queries.inperson"
                >
                <label
                    class="custom-control-label"
                    for="search_virtual"
                >Virtual</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_inperson"
                    v-model="queries.inperson"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing || queries.virtual"
                >
                <label
                    class="custom-control-label"
                    for="search_inperson"
                >In-Person</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="archived"
                    v-model="queries.archived"
                    type="checkbox"
                    class="custom-control-input"
                >
                <label
                    class="custom-control-label"
                    for="archived"
                >Archived</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="trashed"
                    v-model="queries.trashed"
                    type="checkbox"
                    class="custom-control-input"
                >
                <label
                    class="custom-control-label"
                    for="trashed"
                >Trashed</label>
              </div>


              <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-hover table-striped">
                <sort-columns
                    :columns="columns"
                    :queries="queries"
                    :processing="processing"
                    :base_url="$route.meta.base_url"
                />
                <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="(record, index) in records">
                    <tr :key="'checkin_'+index">
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          {{ record.name }}
                        </a>
                      </td>
                      <td>{{ record.phone }}</td>
                      <td>{{ record.email }}</td>
                      <td>{{ record.status }}</td>
                      <td>
                        <template v-if="record.language">
                          {{ setLanguageName(record.language) }}
                        </template>
                      </td>
                      <td>{{ record.service }}</td>
                      <td>
                        <template v-if="record.location">
                          {{ setLocationName(record.location) }}
                        </template>
                      </td>
                      <td>
                        <template v-if="record.virtual == 1">
                          <span class="badge badge-pill badge-info">Virtual</span>
                        </template>
                        <template v-else>
                          <span class="badge badge-pill badge-secondary">In-Person</span>
                        </template>
                      </td>
                      <td>{{ record.created_by }}</td>
                      <td>
                        <template v-if="record.created_at">
                          {{ record.created_at | datetime }}
                        </template>

                        <template v-if="record.deleted_at">
                          <span class="badge badge-pill badge-danger">Trashed {{ record.deleted_at | datetime }}</span>
                        </template>

                        <template v-if="record.archived_at">
                          <span class="badge badge-pill badge-danger">Archived {{ record.archived_at | datetime }}</span>
                        </template>

                      </td>
                      <td>
                        <template v-if="record.updated_at">
                          {{ record.updated_at | datetime }}
                        </template>
                      </td>

                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div><!--Responsive-->


            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      downloading: false,
      downloads: [],
      records: [],
      queries: {},
      options: {},
      total: 0,
      form: {},
      client: {},
      columns: [
        {label: 'Name', key: 'name'},
        {label: 'Phone', key: 'phone'},
        {label: 'Email', key: 'email'},
        {label: 'Status', key: 'status'},
        {label: 'Language', key: 'language'},
        {label: 'Service', key: 'service'},
        {label: 'Location', key: 'location'},
        {label: 'Virtual', key: 'virtual'},
        {label: 'Creator', key: 'created_by'},
        {label: 'Created', key: 'created_at'},
        {label: 'Updated', key: 'updated_at'}
      ],
      languages: [],
      locations: []
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

      if(view !== self.$route.meta.base_url) {
        return;
      }

      self.searchRecords();

    });

  },

  created() {

    this.resetForm();
    this.resetQueries();
    this.setURLParams();
    this.getRecords();
    this.getLanguages();
    this.getLocations();
  },

  methods: {

    resetSearch() {
      this.resetQueries();
      this.searchRecords();
    },

    resetQueries() {
      this.queries = {
        search: '',
        status: null,
        client: null,
        event: null,
        virtual: null,
        inperson: null,
        sort: 'created_at',
        sortDirection: 'desc',
        limit: 10,
        archived: false,
        trashed: false
      }
    },

    setURLParams() {
      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {

        if(!value || value == 'null') {
          return;
        }

        self.queries[key] = value;
      });
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    resetRecords() {
      this.queries.skip = 0;
      this.total = 0;
      this.records = [];
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              this.setTitle();
            }
          })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {

              if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                  self.records.push(data);
                });

              }

              this.queries.skip = this.records.length;

              this.processing = false;
            }
          })
    },

    resetForm() {
      this.form = {
        name: null,
        status: null,
        notes: null,
        service: null,
        location: null,
        virtual: false
      }
    },

    postForm() {

      this.processing = true;

      this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {

              if(response.data.uuid) {
                new this.$noty({text: this.$route.meta.title + ' created'}).show();
                this.records.unshift(response.data);
                this.resetForm();
                window.scrollTo(0,0);
                window.location.href = '/'+this.$route.meta.base_url+'/'+response.data.uuid;
              }

              this.processing = false;

            }
          })
    },

    async downloadPDF() {
      try {

        window.scrollTo(0,0);

        this.downloading = true;

        const element = document.getElementById('main');

        const canvas = await this.$html2canvas(element, {
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          scale: window.devicePixelRatio,
          useCORS: true,
          logging: false
        });

        const imgData = canvas.toDataURL('image/png');

        const pdf = new this.$jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

        pdf.save(name+'.pdf');

        this.downloading = false;
      } catch (error) {
        new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
        console.log(error);
        this.downloading = false;
      }
    },

    downloadCSV () {
      this.downloading = true;

      var queries = this.queries;

      queries.limit = this.total;

      queries.skip = this.downloads.length;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
          .then(response => {
            if(response && response.data && response.data) {
              this.downloads = this.downloads.concat(response.data);

              var csvFile, data, link;

              data = this.convertCSV(this.downloads);

              var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

              csvFile = new Blob([data], {type: "text/csv"});
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(csvFile, file);
              } else {
                link = document.createElement("a");
                link.download = file;
                link.href = window.URL.createObjectURL(csvFile);
                link.style.display = "none";
                document.body.appendChild(link);
                link.click();
              }

              this.downloading = false;

            } else {
              this.downloadCSV();
            }

          });
    },

    convertCSV(records) {

      var results = [];

      if(records[0] && records[0]['uuid']) {
        var headers = Object.keys(records[0]);
      }

      results += headers.join(',')

      results += '\n';

      records.forEach(function(item) {
        var record = '';

        if(!item) {
          return;
        }

        Object.keys(item).forEach(function(key) {
          record += '"' + (item[key] ? item[key] : '') + '",';
        });

        results += record;
        results += '\n';
      });

      return results
    },

    getLanguages () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
          .then(response => {
            if(response && response.data) {
              this.languages = response.data;
            }
          })
    },

    getLocations () {
      this.$http.get(this.buildQueries('/locations', {limit: 1000, fields: 'uuid,name', slim: true, sort: 'name', sortDirection: 'asc'}))
          .then(response => {
            if (response && response.data) {
              this.locations = response.data;
            }
          })
    },

    setLocationName(location) {

      if(!location) {
        return;
      }

      var result = _.find(this.locations, {'uuid': location});

      if(!result || !result.name) {
        return;
      }

      return result.name;
    },

    setLanguageName(language) {

      if(!language) {
        return;
      }

      var result = _.find(this.languages, {'key': language});

      if(!result || !result.label) {
        return language;
      }

      return result.label;
    },

  }

}
</script>