<template>
  <div>
    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row">
      <div class="col-md-8">
          <h1>{{ form.name }} {{ form.value }}</h1>
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-md-4 text-right">
        <div class="btn-group">
          <template v-if="form.uuid && !form.optin">
            <button
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="optIn"
            >
              Opt In
            </button>
          </template>
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">
                <template v-if="form.optin == 1 && form.notify == 1">
                  <a
                    class="dropdown-item"
                    @click="optOut"
                  >Opt Out</a>
                </template>

                <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord"
                >Archive</a>
                <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord"
                >Restore</a>
                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord"
                >Trash</a>
              </div>
            </div>
            
          <button
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="updateRecord"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-md-4">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title">
                  Edit {{ $route.meta.title }}
                </p>
              </div><!--Col-->
              <div class="col-6 text-right">
                <contact-statuses :contact="form" />
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="updateRecord()">
              <div class="form-group mt-3">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                >
              </div>

              <div class="form-group mt-3">
                <label for="value">Contact</label>
                <input
                  id="value"
                  v-model="form.value"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="type">Type</label>
                <input
                  id="type"
                  v-model="form.type"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="channel">Channel</label>
                <select
                  id="channel"
                  v-model="form.channel"
                  class="form-control"
                >
                  <option value="text">
                    Text
                  </option>
                  <option value="voice">
                    Voice
                  </option>
                  <option value="email">
                    Email
                  </option>
                </select>
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Save
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs :form="form" />
      </div><!--Col-->
      <div class="col-md-4">
        <template v-if="form.client">
          <client-card
            :client="form.client"
            class="mb-3"
          />
        </template>

        <template v-if="form && form.uuid">

          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <p class="card-title">Lookup Data</p>
                </div><!--Col-->
                <div class="col-sm-12 col-md-6 text-right">
                  <template v-if="lookup && lookup.uuid">
                    <router-link tag="button" class="btn btn-primary btn-sm" :to="{ 'name': 'lookups.edit', 'params': {'id': lookup.uuid} }">
                      <i class="fa fa-database"></i> View Lookup
                    </router-link>
                  </template>
                </div><!--Col-->

              </div><!--Row-->


              <template v-if="lookup && lookup.uuid">
                <template v-if="lookup.valid">
                  <span class="badge badge-success">VALID</span>
                </template>
                <template v-else>
                  <span class="badge badge-danger">INVALID</span>
                </template>

                <ul class="list-group my-3">

                  <li class="list-group-item">Status {{ lookup.status }}</li>
                  <li class="list-group-item">Created {{ lookup.created_at | dateTimezone | timeago }}</li>

                  <template v-if="lookup.data && lookup.data.carrier">

                    <template v-if="lookup.data.carrier.type">
                      <li class="list-group-item">Type {{ lookup.data.carrier.type }}</li>
                    </template>

                    <template v-if="lookup.data.carrier.name">
                      <li class="list-group-item">Name {{ lookup.data.carrier.name }}</li>
                    </template>

                  </template>

                  <template v-if="lookup.data.caller_name && lookup.data.caller_name.caller_name">
                    <li class="list-group-item">
                      Caller Name {{ lookup.data.caller_name.caller_name }}
                    </li>
                  </template>

                </ul>


              </template>

              <template v-if="!processing && !lookup">
                <div class="alert alert-danger">No lookup found</div>

                <button type="button" class="btn btn-success" @click="runLookup()" :disabled="processing">Run Lookup</button>
              </template>
            </div>
          </div>

        </template>

        <template v-if="form && form.uuid">
          <opts-card :contact="form.uuid" />
        </template>
        
      </div><!--Col-->

      <div class="col-md-4">

        <template v-if="form.valid && form.optin">

        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">
              Send a Message to {{ form.value }}
            </p>

            <form @submit.prevent="postMessage">
              <div class="form-group">
                <label for="from">From
                <template v-if="form.channel == 'email'">
                  Email
                </template>
                  <template v-else>
                    Phone
                  </template>
                </label>
                <select
                  id="from"
                  v-model="messageForm.from"
                  class="form-control"
                >
                  <template v-for="(from, index) in agency_froms">
                    <template v-if="from.phone_number">
                      <option
                        :key="'agency_from_'+index"
                        :value="from.phone_number"
                      >
                        {{ from.phone_number }}
                      </option>
                    </template>
                    <template v-if="from.email">
                      <option
                        :key="'agency_from_'+index"
                        :value="from.email"
                      >
                        {{ from.email }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <template v-if="form.channel === 'email'">
                <div class="form-group">
                  <label for="subject">Subject</label>
                  <input
                    id="subject"
                    v-model="messageForm.subject"
                    type="text"
                    class="form-control"
                  >
                </div>
              </template>

              <div class="form-group">
                <label for="message">Message</label>
                <textarea
                  id="message"
                  v-model="messageForm.content"
                  class="form-control"
                  rows="3"
                />
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group vbDatePicker">
                    <label for="send_date">Send Date</label>
                    <input
                      id="send_date"
                      v-model="messageForm.date"
                      placeholder="mm/dd/yyyy"
                      type="date"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->
                <div class="col-6">
                  <div class="form-group vbTimePicker">
                    <label for="send_time">Send Time</label>
                    <input
                      id="send_time"
                      v-model="messageForm.time"
                      placeholder="hh:mm AM"
                      type="time"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <div class="form-group custom-control custom-switch">
                <input
                  id="test"
                  v-model="messageForm.test"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="test"
                >Simulate</label>
              </div>

              <div class="row">

                <div class="col-6">

                    <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="processing"
                    >
                      Send Message
                    </button>

                </div><!--Col-->

                <div class="col-6 text-right">

                  <div class="btn-group" :hidden="!messageForm.content">
                    <button 
                    type="button" 
                    class="btn btn-outline-success dropdown-toggle" 
                    data-toggle="dropdown" 
                    aria-expanded="false"
                    >
                      <i class="fa fa-language"></i> Auto-Translate
                    </button>
                    <div class="dropdown-menu">

                      <h6 class="dropdown-header">Choose Target Language</h6>

                      <template v-for="(language, lng_key) in languages">

                      <a 
                      :key="'language_'+lng_key" 
                      @click="autoTranslate(language.key)" class="dropdown-item text-capitalize"
                      :hidden="language.key == 'en'"
                      >

                      {{ language.label }}

                      </a>

                      </template>

                    </div>
                  </div>


                </div><!--Col-->

              </div><!--Row-->


            </form>
          </div><!--Body-->
        </div><!--Card-->

        </template>

        <p class="card-title">
          {{ total_messages }} messages
        </p>

        <template v-if="!messages || !messages.length">
          <template v-if="form.uuid && !searching">
            <div class="alert alert-danger">
              No messages found.
            </div>
          </template>
        </template>

        <template v-for="message in messages">
          <message
            :key="message.uuid"
            :message="message"
          />
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {
    
    mixins: [ queries ],

    data () {
        return {
            processing: false,
            searching: false,
            queries: {
                slim: true,
                archived: true,
                trashed: true
            },
            form: {},
            messageForm: {},
            messages: [],
            total: 0,
            total_messages: 0,
            agency_froms: [],
            languages: [],
            lookup: null
        }
    },

    created () {

        this.resetMessageForm();

        this.setURLParams();
        this.getRecord();

        this.getLanguages();

    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });
        },

        getRecord () {
            this.processing = true;
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url + '/' + this.$route.params.id, this.queries))
            .then(response => {
                if (response && response.data) {
                    this.form = response.data;
                    this.processing = false;
                    this.getMessages();

                    if(response.data.value) {
                        document.title = response.data.value + ' | eCourtDate.com';
                    }

                    this.getAgencyFroms();

                    this.getLookup();
                }
            })
        },

      runLookup() {
        if(!this.form.value) {
          return;
        }

        this.processing = true;

        this.$http.post('/lookups', {lookup: this.form.value})
            .then(response => {
              if(response && response.data) {
                this.lookup = response.data;
                this.processing = false;
              }
            })
      },

      getLookup() {

        if(!this.form.value) {
          return;
        }

        this.lookup = null;

        this.$http.get('/lookups?limit=1&sort=created_at&sortDirection=desc&search='+this.form.value)
            .then(response => {
              if(response && response.data && response.data[0]) {
                this.lookup = response.data[0];
              }
            })
      },

        sortMessages() {
            this.messages = this.messages.sort((a, b) => Number(new Date(a.scheduled_at)) - Number(new Date(b.scheduled_at)));
        },

        getInboundMessages(queries) {
            
            queries['from'] = this.form.value;
            queries['to'] = null;

            this.$http.get(this.buildQueries('/messages', queries))
            .then(response => {
                if (response && response.data && response.data.data) {

                    var self = this;

                    response.data.data.forEach(function(data) {
                        self.messages.push(data);
                        self.total_messages++;
                    });

                    this.sortMessages();
                }
            });
        },

        getOutboundMessages(queries) {

            queries['to'] = this.form.value;
            queries['from'] = null;

            this.$http.get(this.buildQueries('/messages', queries))
            .then(response => {
                if (response && response.data && response.data.data) {
                    
                    var self = this;

                    response.data.data.forEach(function(data) {
                        self.messages.push(data);
                        self.total_messages++;
                    });

                    this.sortMessages();
                }
            })
        },

        getMessages () {

            const queries = {
                "limit": 1000,
                "sort": "updated_at",
                "sortDirection": "desc",
                "slim": true,
                "fields": "uuid,content,scheduled_at,last_status,to,from,subject,test,client,direction,last_open"
            }

            this.searching = true;
            this.total = 0;
            this.messages = [];

            this.getInboundMessages(queries);
            this.getOutboundMessages(queries);
        },
        
        restoreRecord () {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
            .then(response => {
                if (response && response.data) {

                    this.processing = false;

                    if(response.status === 200) {
                        new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                        this.getRecord(); 
                    }
                    
                }
            })
        },

        archiveRecord () {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
            .then(response => {
                if (response && response.data) {

                    this.processing = false;

                    if(response.status === 200) {
                        new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                        this.getRecord();
                    }

                }
                
            })
        },

        trashRecord () {
            this.processing = true;
            this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
            .then(() => {
                this.processing = false;
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.getRecord();
            })
        },

        optOut() {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid+'/optout')
            .then(response => {
                if(response && response.data) {

                    this.processing = false;

                    if(response.status === 200) {
                        new this.$noty({text: 'Opting-out '+this.$route.meta.title}).show();
                        this.getRecord();
                    }

                }
            })
        },

        optIn() {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid+'/optin')
            .then(response => {
                if(response && response.data) {

                    this.processing = false;

                    if(response.status === 200) {
                        new this.$noty({text: 'Opting-in '+this.$route.meta.title}).show();
                        this.getRecord();
                    }

                }
            })
        },

        updateRecord() {
            this.processing = true;
            this.$http.patch('/'+this.$route.meta.base_url + '/' + this.form.uuid, this.form)
            .then(response => {
                if(response && response.data) {

                    if(response.data.updated_at) {
                        new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                        this.form.updated_at = response.data.updated_at;
                    }
                    
                    this.processing = false;
                }
            })
        },

        resetMessageForm() {
            this.messageForm = {
                to: null,
                from: null,
                content: null,
                subject: null,
                client: null,
                channel: null,
                test: 0,
                date: '',
                time: ''
            }
        },

        getAgencyFroms() {

            if(!this.form.channel) {
                return;
            }

            var queries = {
                limit: 10,
                sort: 'created_at',
                sortDirection: 'desc',
                slim: true,
                fields: 'uuid,phone_number'
            }

            var url = '/agency_phones';

            if(this.form.channel === 'email') {
                url = '/agency_emails';
                queries['fields'] = 'uuid,email';
            }

            this.$http.get(this.buildQueries(url, queries))
            .then(response => {
                if(response && response.data) {
                    this.agency_froms = response.data;

                    if(!this.messageForm.from && this.agency_froms[0]) {

                      if(this.agency_froms[0]['email']) {
                        this.messageForm.from = this.agency_froms[0]['email'];
                      }

                      if(this.agency_froms[0]['phone_number']) {
                        this.messageForm.from = this.agency_froms[0]['phone_number'];
                      }

                    }
                }
            })
        },

        postMessage() {

            if(!this.form.value) {
                return;
            }

            this.processing = true;

            this.messageForm.to = this.form.value;

            if(this.form.client) {
                this.messageForm.client = this.form.client;
            }

            if(this.form.channel) {
              this.messageForm.channel = this.form.channel;
            }

            this.$http.post('/messages/oneoffs', this.messageForm)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;

                    if(response.status === 201) {
                        this.resetMessageForm();
                        new this.$noty({text: 'Message created'}).show();
                    }
                }
            })

        },

        autoTranslate(language) {

          this.processing = true;

          var translator = {
            source_language: 'en',
            target_language: language,
            content: this.messageForm.content
          }

          this.$http.post('/translator', translator)
          .then(response => {
            if(response && response.data) {

              if(response.data.content) {
                this.messageForm.content = this.messageForm.content + ' (AUTO-TRANSLATION) ' + response.data.content;
                new this.$noty({text: 'Added translation'}).show();
              }

              this.processing = false;
            }
          })
        },

        getLanguages () {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
          .then(response => {
              if(response && response.data) {
                  this.languages = response.data;
              }
          })
      }
    }
}
</script>
