<template>
  <div>
    <div class="row mb-3">
      <div class="col-lg-9 col-md-7 order-1 order-md-0 mt-2 mt-md-0">
        <h4 class="m-0">
          {{ form.firm_name }} {{ form.firm_reference }}
        </h4>
        <span
          v-if="form.first_name"
          class="mr-1"
        >{{ form.first_name }}</span>
        <span
          v-if="form.last_name"
          class="mr-1"
        >{{ form.last_name }}</span>
        <span v-text="form.email" />
      </div>
      <div class="col-lg-3 col-md-5 text-right">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle radius-right-0"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Tools
          </button>
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              @click="trashForm"
            ><i class="fa fa-trash" /> Trash</a>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-success rounded-0"
          :disabled="processing"
          @click="postForm()"
        >
          Save
        </button>
        <router-link
          :to="{ name: 'agents.index' }"
          class="btn btn-danger radius-left-0"
        >
          Close
        </router-link>
      </div><!--Col-->
    </div><!--Row-->
    <div class="row">
      <div class="col-lg-2 col-md-3 mb-3">
        <tab-list :view="'agent'" />
      </div><!--Col-->
      <div class="col-lg-10 col-md-9">
        <loader :is-loading="processing" />
        <template v-if="active_tab == 'overview'">
          <div class="card">
            <div class="card-body">
              <form
                @submit.prevent="postForm"
                @change="changeForm"
                @keyup.enter="postForm"
              >
                <div class="row">
                  <div class="form-group col-6">
                    <label for="firm_name">Firm Name</label>
                    <input
                      id="firm_name"
                      v-model="form.firm_name"
                      type="text"
                      class="form-control"
                      required
                    >
                  </div>
                  <div class="form-group col-6">
                    <label for="agent_reference">Agent Reference</label>
                    <input
                      id="agent_reference"
                      v-model="form.agent_reference"
                      type="text"
                      class="form-control"
                      required
                    >
                  </div>
                  <div class="form-group col">
                    <label for="first_name">First Name</label>
                    <input
                      id="first_name"
                      v-model="form.first_name"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="form-group col-6">
                    <label for="last_name">Last Name</label>
                    <input
                      id="last_name"
                      v-model="form.last_name"
                      type="text"
                      class="form-control"
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label for="email">Email</label>
                    <input 
                      id="email"
                      v-model="form.email" 
                      type="email" 
                      class="form-control"
                      :class="{'is-invalid': errors.email }"
                      required
                    >
                  </div>
                  <div class="form-group col-6">
                    <label for="phone">Phone</label>
                    <input
                      id="email"
                      v-model="form.phone"
                      type="tel"
                      :class="{ 'is-invalid': errors.phone }"
                      class="form-control"
                      required
                    >
                  </div>
                  <div class="col-6">
                    <div class="form-group custom-control custom-switch">
                      <input
                        id="notify"
                        v-model="form.notify"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="notify"
                      >Notify</label>
                    </div>
                  </div>
                </div><!--Row-->
              </form>
            </div><!--Body-->
          </div><!--Card-->
        </template>
        <template v-if="active_tab == 'cases'">
          <template v-if="client_cases && client_cases.length">
            <div class="row">
              <template v-for="client_case in client_cases">
                <div
                  :key="client_case.case_number"
                  class="col-4 mb-3"
                >
                  <div class="card">
                    <div class="card-body">
                      <router-link
                        v-if="client_case.client_uuid"
                        :to="'/clients/' + client_case.client_uuid + '?tab=cases'"
                        class="card-title link-hover"
                      >
                        <h5 class="card-title mt-1">
                          {{ client_case.case_number }}
                        </h5>
                      </router-link>
                      <div
                        v-else
                        class="card-title"
                      >
                        <h5 class="card-title mt-1">
                          {{ client_case.case_number }}
                        </h5>
                      </div>
                      <template v-if="client_case.client_uuid">
                        <router-link
                          :to="'/clients/' + client_case.client_uuid + '?tab=overview'"
                          class="card-title"
                        >
                          <h6 class="card-title mt-1">
                            Client: {{ client_case.first_name + ' ' + client_case.last_name }}
                          </h6>
                        </router-link>
                      </template>
                      <template v-if="client_case.created_at">
                        <div class="small text-muted">
                          Created At: {{ client_case.created_at | datetime }}
                        </div>
                      </template>
                    </div><!--Body-->
                  </div><!--Card-->
                </div><!--Col-->
              </template>
            </div><!--Row-->
          </template>
          <template v-if="!processing && (!client_cases || client_cases.length === 0)">
            <div class="alert alert-warning">
              No cases found.
            </div>
          </template>
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import tabs from '../mixins/tabs';
import forms from '../mixins/forms';

export default {
  mixins: [ tabs, forms ],

  data () {
    return {
      processing: false,
      client_cases: []
    }
  },
  
  created () {
    this.getForm();
  },

  methods: {

    getForm () {
      this.$http.get(this.base_url+'/'+this.$route.params.id)
      .then((response) => {
        if (response && response.data) {
          this.form = response.data;
          this.client_cases = response.data.cases;
          this.$eventBus.$emit('activeForm', this.form);
        }
      })
    },

    trashForm () {
      this.processing = true;
      this.$http.delete(this.base_url+'/'+this.$route.params.id)
      .then(response => {
        if (response) {
          new this.$noty({text: this.title + ' trashed', type: 'error'}).show();
          this.processing = false;
          this.$router.push({name: 'agents.index'});   
        }
      })
    }
  }
}
</script>
