<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row">
      <div class="col-md-4">
          <h1>{{ form.amount }}</h1>
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-md-4">
        <template v-if="setPortalLink()">
        <a :href="setPortalLink()" target="_blank" class="btn btn-outline-secondary"><i class="fa fa-link"></i> Open Portal</a>
        </template>
      </div><!--Col-->

      <div class="col-md-4 text-right">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">

                <a
                  v-if="form.deleted_at || form.archived_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>

                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-lg-4 col-sm-12">
        <div class="card">
          <div class="card-body">

          <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="amount">Amount</label>
                <input id="amount" type="text" class="form-control" v-model="form.amount" required>
              </div>

              <div class="form-group">
                <label for="max_payments">Max Payments</label>
                <input id="max_payments" type="number" class="form-control" v-model="form.max_payments" required>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-lg-4 col-sm-12">

        <template v-if="form.client">
          <client-card :client="form.client" class="mb-3" />
        </template>

        <template v-if="form && form.payment">
          <payment-card
            :payment="form.payment"
            class="mb-3"
          />
        </template>

        <template v-if="pay_plan && pay_plan.uuid">

          <div class="card">
            <div class="card-body">

              <p class="card-title">Pay Plan Settings</p>

              <ul class="list-group">

                <li class="list-group-item">Name: <a :href="'/pay_plans/'+pay_plan.uuid">{{ pay_plan.name }}</a></li>

                <template v-if="pay_plan.max_payments">
                  <li class="list-group-item">Max Payments: {{ pay_plan.max_payments }}</li>
                </template>

                <template v-if="pay_plan.autopay">
                  <li class="list-group-item">AutoPay: {{ pay_plan.autopay }}</li>
                </template>

              </ul>

            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="portal && portal.uuid">
          <div class="card">
            <div class="card-body">
              <p class="card-title">Portal Settings</p>

              <ul class="list-group">
                <li class="list-group-item list-group-item-action">
                  <router-link :to="{ 'name': 'portals.edit', 'params': {'id': portal.uuid} }">
                      {{ portal.name }} {{ portal.link }} {{ portal.domain }}
                  </router-link>
                </li>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

      </div><!--Col-->

      <div class="col-lg-4 col-sm-12">

        <div class="card">
          <div class="card-body">

              <template v-if="total >= 0">
                <p class="card-title"><a :href="'/transactions?client='+form.client+'&plan='+form.uuid">{{ records.length }} of {{ total }} Transactions</a></p>
              </template>

              <ul class="list-group">

                <template v-for="(record, plan_transaction) in records">

                  <li :key="'transaction_'+plan_transaction" class="list-group-item">

                  <div class="row">
                    <div class="col-9">
                    
                  <template v-if="record.amount">
                    <p class="text-muted m-0">Amount: {{ record.amount }}</p>
                  </template>

                    </div><!--Col-->

                    <div class="col-3">

                    <template v-if="record.status">
                      <span class="badge badge-pill badge-primary">{{ record.status }}</span>
                    </template>

                    </div><!--Col-->

                    </div><!--Row-->

                  </li>

                </template>

              </ul>

            </div><!--Body-->
          </div><!--Card-->

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      searching: false,
      form: {},
      pay_plan: {},
      queries: {},
      total: 0,
      client: {},
      records: [],
      portal: {}
    }
  },

  created() {
    this.resetQueries();
    this.resetRecords();
    this.resetForm();
    this.getRecord();
  },

  methods: {

    resetQueries() {
      this.queries = {
        client: null,
        plan: null
      }
    },

    resetForm() {
      this.form = {
        amount: 0,
        client: null
      }

    },

    getRecord() {

        this.processing = true;

        this.$http.get(this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                
                this.form = response.data;

                this.processing = false;

                this.getPayPlan();

                this.getPortal();
            }
        })
    },

    getPayPlan() {

      if(!this.form.plan) {
        return;
      }

      this.$http.get('/pay_plans/'+this.form.plan)
      .then(response => {
        if(response && response.status) {

          if(response.status === 200) {
            this.pay_plan = response.data;
            this.getRecords();
          }
        }
      })
    },

    postForm() {

      this.processing = true;

      this.$http.patch(this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
          }

        }
      })
    },


    restoreRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
      .then(response => {
        if(response && response.data) {
              
              this.processing = false;

              if(response.status === 200) {
                new this.$noty({'text': this.$route.meta.title + ' restored'}).show()
                this.form.deleted_at = null;
                this.form.archived_at = null;
                this.form.updated_at = new Date().toISOString();
              }

        }
      })
    },


  trashRecord () {
      this.processing = true;
      this.$http.delete(this.$route.meta.base_url + '/' + this.form.uuid)
      .then((response) => {
        if(response && response.status) {
          this.processing = false;

          if(response.status === 204) {
            new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
            this.form.deleted_at = new Date().toISOString();
          }
        }
      })
  },

    getRecords() {

      this.searching = true;

      this.setQueries();

      this.getTotal();

      this.$http.get(this.buildQueries('/transactions', this.queries))
      .then(response => {
        if(response && response.data) {
          this.records = response.data;
          this.searching = false;
        }
      })
    },

    setQueries() {
      this.queries = {
        payment: this.form.payment,
        client: this.form.client,
        slim: true,
        fields: 'uuid,amount,status',
        sort: 'created_at',
        sortDirection: 'desc'
      }
    },

    getTotal() {

      this.$http.get(this.buildQueries('/transactions/total', this.queries))
      .then(response => {
        if(response && response.status) {
          this.total = response.data;
        }
      })
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
    },

    getPortal() {

      if(!this.form.portal) {
        return;
      }

      this.$http.get('/portals/'+this.form.portal)
          .then(response => {
            if(response && response.data) {
              this.portal = response.data;
            }
          })
    },

    setPortalLink() {

      if(!this.portal || !this.portal.uuid) {
        return;
      }

      return 'https://'+this.portal.link+'.'+this.portal.domain+'/cp/'+this.form.uuid;
    }



  }
}
</script>