<template>
  <div>

    <page-tabs page="users" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name">Name</label>
                <input
                    id="name"
                    v-model="form.name"
                    class="form-control"
                    required
                >
              </div>

              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                Add
              </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">Search {{ $route.meta.title }}s</p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input id="search" type="search" class="form-control" v-model="queries.search">
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="archived"
                        v-model="queries.archived"
                        type="checkbox"
                        class="custom-control-input"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="trashed"
                        v-model="queries.trashed"
                        type="checkbox"
                        class="custom-control-input"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>

            </form>

          </div>
        </div>

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
                </p>
              </div><!--Col-->

              <div class="col-md-4" /><!--Col-->
            </div><!--Row-->


            <div class="table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                <tr>
                  <template v-for="(column, index) in columns">
                    <th
                        :key="'column_'+index"
                        class="text-uppercase"
                    >
                      <template v-if="column.key">
                        <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
                </thead>

                <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="(record, index) in records">
                    <tr :key="'record_'+index">
                      <td>
                        <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid}}">
                          {{ record.name }}
                        </router-link>
                      </td>
                      <td>
                        <template v-if="record.permissions && record.permissions">
                          <span v-for="(permission, index) in record.permissions" :key="'permission_'+index" class="badge badge-primary mr-1">{{ permission }}</span>
                        </template>
                      </td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.created_at | datetime }}
                      <template v-if="record.archived_at">
                        <span class="badge badge-danger">Archived</span>
                      </template>
                        <template v-if="record.deleted_at">
                          <span class="badge badge-danger">Deleted</span>
                        </template>
                      </td>
                      <td>{{ record.updated_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div><!--Responsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

          <div class="row mt-3">
            <div class="col-12 text-center">

              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>

            </div><!--Col-->
          </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from "lodash";
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      validating: false,
      validated: false,
      total: 0,
      queries: {},
      records: [],
      form: {},
      columns: [
        { key: 'name', label: 'Name'},
        { key: 'permissions', label: 'Permissions'},
        { key: 'created_by', label: 'Creator'},
        { key: 'created_at', label: 'Created'},
        { key: 'updated_at', label: 'Updated'},
      ]
    }
  },

  created() {
    this.resetForm();
    this.resetQueries();
    this.resetRecords();
    this.setURLParams();
    this.getRecords();
  },

  methods: {


    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    setStatusClass(record) {

      if(record.status == 'private') {
        return 'badge-danger';
      }

      if(record.status == 'draft') {
        return 'badge-secondary';
      }

      return 'badge-success';
    },

    resetQueries() {
      this.queries = {
        search: null,
        limit: 10,
        slim: false,
        sort: 'created_at',
        sortDirection: 'desc',
        archived: false,
        trashed: false
      }
    },

    searchRecords() {
      this.queries.skip = 0;
      this.records = [];
      this.getRecords();
    },

    resetForm() {
      this.form = {
        name: null
      }
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    updateSortDirection() {

      if(this.processing) {
        return;
      }

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      if(this.processing) {
        return;
      }

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {

              if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                  self.records.push(data);
                });
              }

              this.queries.skip = this.records.length;

              this.processing = false;
            }
          })
    },

    getTotal() {
      this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              this.setTitle();
            }
          })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    postForm() {
      this.processing = true;
      this.$http.post(this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.status === 201) {
                this.resetForm();
                this.total++;
                this.records.unshift(response.data);
                new this.$noty({text: this.$route.meta.title + ' created'}).show();
              }

            }
          })
    },




  },
}
</script>