<template>
  <div>

    <page-tabs page="portals" />

    <div class="row">
      <div class="col-md-3">

        <div class="card">
          <div class="card-body">

            <p class="card-title">Add {{ $route.meta.title }}</p>
       
            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name" class="form-label">Name</label>
                <input id="name" type="text" class="form-control" v-model="form.name" required>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->


        <div class="card mt-3">
          <div class="card-body">
            <form @submit.prevent="getRecords">
              <div class="form-group">
                <label for="search">Search {{ $route.meta.title }}s</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                  placeholder="Search..."
                >
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->


      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
            </p>

            <table class="table table-striped table-hover table-responsive">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="$route.meta.base_url"
              />

              <tbody>
                <template v-for="record in records">
                  <tr :key="record.uuid">
                    <td>
                      <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                        {{ record.name }}
                      </router-link>
                    </td>
                    <td>{{ record.reference }}</td>
                    <td>{{ record.language }}</td>
                    <td>{{ record.url }}</td>
                    <td>
                      <template v-if="record.status">
                        <span class="badge badge-pill text-uppercase" :class="setStatusClass(record.status)">{{ record.status }}</span>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.client && record.client_uuid">
                        <a :href="'/clients/'+record.client_uuid">
                          {{ record.client.client_reference }}
                          {{ record.client.last_name }}
                          {{ record.client.first_name }}
                        </a>
                      </template>
                    </td>
                    <td>{{ record.opened_at | datetime }}</td>
                    <td>
                      {{ record.expires_at | datetime }}

                      <template v-if="record.expired && record.expired === true">
                        <span class="badge badge-danger">EXPIRED</span>
                      </template>
                    </td>
                    <td>{{ record.created_at | datetime }}</td>
                  </tr>
                </template>
              </tbody>
            </table>

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="loadMore"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

data() {
    return {
        processing: false,
        show_load: false,
        records: [],
        columns: [
            {key: 'name', label: 'Name'},
            {key: 'reference', label: 'Form Reference'},
            {key: 'language', label: 'Language'},
            {key: 'url', label: 'URL'},
            {key: 'status', label: 'Status'},
            {key: 'client', label: 'Client'},
            {key: 'opened_at', label: 'Opened'},
            {key: 'expires_at', label: 'Expires'},
            {key: 'created_at', label: 'Created'}
        ],
        queries: {
            skip: 0,
            limit: 10,
            upload: null
        },
        total: 0,
        form: {}
    }
},

created() {
    this.resetForm();
    this.resetRecords();
    this.setURLParams();
    this.getRecords();
},

methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    resetForm() {
      this.form = {
        name: null,
        reference: null,
        notify: null,
        portal: null,
        status: 'draft',
        language: 'en'
      }
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;      
    },

    setStatusClass(status) {

      if(status == 'private') {
        return 'badge-danger';
      }

      if(status == 'draft') {
        return 'badge-secondary';
      }

      return 'badge-success';
    },

    postForm() {
      this.processing = true;

      this.form.notify = this.$root.current_user.email;

      this.form.reference = this.$options.filters.slugString(this.form.name);

      this.$http.post('/'+this.$route.meta.base_url, this.form)
      .then(response => {
        if(response && response.data) {
          new this.$noty({text: this.$route.meta.title + ' created'}).show();
          this.records.unshift(response.data);
          this.total++;
          this.resetForm();
          this.processing = false;
          this.$router.push({name: this.$route.meta.base_url+'.edit', params: {id: response.data.uuid}});
        }
      })
    },

    getRecords() {

        this.getTotal();

        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
            if(response && response.data) {

                this.records = response.data

                if (response.data.length < this.queries.limit) {
                    this.show_load = false
                } else {
                    this.show_load = true
                }

                this.processing = false
            }
        })
    },

    getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
            if(response && response.status) {
                this.total = response.data;
                this.setTitle();
            }
        })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + ' | eCourtDate.com';
    },

    loadMore() {
      this.processing = true;
      this.show_load = false;

      this.queries.skip = this.records.length;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {

              if(response.data.length < this.queries.limit) {
                this.show_load = false;
              } else {
                this.show_load = true;
              }

              if(response.data.length > 0) {
                response.data.forEach(r => {
                    this.records.push(r);
                    this.total++;
                })                
              }

              this.processing = false;
          } else {
            this.show_load = false;
          }
        });
    },

}

}
</script>