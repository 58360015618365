<template>
  <div>
  
    <page-tabs :page="$route.meta.base_url" />
    
    <div class="row">
      <div class="col-sm-12 col-lg-3 mb-4">
        <div class="card">
          <div class="card-body">

                <p class="card-title">
                  Add {{ $route.meta.title }}
                </p>

            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="name">Action Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="type">Action Type</label>
                <select
                  id="type"
                  v-model="form.type"
                  class="form-control text-capitalize"
                  :disabled="processing"
                >
                  <option
                    v-for="(type, index) in $root.bulk_action_types"
                    :key="'type_'+index"
                    :value="type.value"
                  >
                    {{ type.label }}
                  </option>
                </select>
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->



        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords">
              <div class="form-group">
                <label for="search_name">Action Name</label>
                <input
                  id="search_name"
                  v-model="queries.search"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_type">Action Type</label>
                <select
                  id="search_type"
                  v-model="queries.type"
                  class="form-control text-capitalize"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(search_type, index) in $root.bulk_action_types">
                    <option
                      :key="'search_type_'+index"
                      :value="search_type.value"
                      :disabled="processing"
                    >
                      {{ search_type.label }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="status">Action Status</label>
                <select
                  id="status"
                  v-model="queries.status"
                  class="form-control text-uppercase"
                  :disabled="processing"
                >
                  <option value="" />
                  <option
                    v-for="(status, index) in $root.bulk_action_statuses"
                    :key="'status_'+index"
                    :value="status"
                  >
                    {{ status }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="search_creator">Creator</label>
                <select
                  id="search_creator"
                  v-model="queries.created_by"
                  class="form-control"
                  :disabled="processing"
                >
                  <template v-if="users && users.length > 0">
                    <option value="" />
                    <template v-for="(user, index) in users">
                      <option
                        :key="'user_'+index"
                        :value="user.email"
                      >
                        {{ user.email }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="is_trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="searching || processing"
                  @change="searchRecords()"
                >
                <label
                  class="custom-control-label"
                  for="is_trashed"
                >Trashed</label>
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="searching || processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-lg-9">
        <div class="row">
          <div class="col-md-12">

            <template v-if="records && !records.length && !processing && !loading && !searching && $root.current_region && $root.current_region == 'dev'">
              <div class="card mb-3" :hidden="searched">
                <div class="card-body">
                  <div class="row">
                    <div class="col-9 mx-auto text-center">
                      <h4>Bulk Actions Overview</h4>

                      <p class="lead">Quickly run a bulk action in a few clicks or click on the
                        <router-link tag="a" :to="{'name': 'bulk_templates.index' }">Bulk Templates</router-link>

                        tab to import a sample template.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-md-6">

                        <ul class="list-group">
                          <template v-for="(bulk_action_type, type_index) in $root.bulk_action_types">
                            <button :key="'bulk_action_type_'+type_index" type="button" class="list-group-item list-group-item-action" @click="updateAction(bulk_action_type.value)" :class="setActionClass(bulk_action_type.value)" :disabled="processing">
                              <p class="font-weight-bold m-0">{{ bulk_action_type.label }}</p>
                              {{ bulk_action_type.description }}
                            </button>
                          </template>

                        </ul>

                      <template v-if="$root.current_region">
                      <a class="btn btn-primary my-3" :href="'https://help.ecourtdate.com/articles?category=Bulk%20Actions&search=&region='+$root.current_region" target="_blank"><i class="fa fa-message-question"></i> Learn more about Bulk Actions</a>
                      </template>

                    </div><!--Col-->
                    <div class="col-sm-12 col-md-6">
                      <p class="lead">Watch this short video to learn how to use Bulk Actions.</p>
                      <div class="embed-responsive embed-responsive-16by9">
                        <iframe width="100%" height="600" src="https://www.youtube.com/embed/-e-ZPY2PcOY?si=Sd8OUaYkgCFR6A1M&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      </div>                    </div><!--Col-->
                  </div><!--Row-->
                </div><!--Body-->
              </div><!--Card-->
            </template>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <p class="card-title">
                      {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
                    </p>
                  </div><!--Col-->

                  <div class="col-md-6 text-right">
                    <loader :is-loading="processing" />
                  </div><!--Col-->
                </div><!--Row-->


                <table class="table table-hover table-striped table-responsive">
                  <thead>
                    <tr>
                      <template v-for="(column, index) in columns">
                        <th :key="'column_'+index">
                          <template v-if="column.key">
                            <span
                              class="text-capitalize link-hover"
                              @click="sortColumn(column)"
                            >{{ column.label }}</span>
                          </template>

                          <template v-if="!column.key">
                            <span class="text-capitalize">{{ column.label }}</span>
                          </template>

                          <template v-if="column.key == queries.sort">
                            <button
                              type="button"
                              class="btn btn-outline-secondary btn-sm ml-1"
                              :disabled="processing"
                              @click="updateSortDirection()"
                            >
                              <template v-if="queries.sortDirection == 'asc'">
                                <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                              </template>

                              <template v-if="queries.sortDirection == 'desc'">
                                <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                              </template>
                            </button>
                          </template>
                        </th>
                      </template>
                    </tr>
                  </thead>

                  <tbody>
                    <template v-if="records && records.length">
                      <template v-for="(record, index) in records">
                        <tr :key="'record_'+index">
                          <td>
                            <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid}}">
                             <i class="fa fa-edit"></i> <span class="sr-only">Edit</span> {{ record.name }}
                            </router-link>
                          </td>
                          <th>{{ record.type }}</th>
                          <td>
                            <template v-if="record.status && record.status != '0'">
                              <span
                                class="badge badge-pill text-uppercase"
                                :class="setStatusClass(record.status)"
                              >{{ record.status }}</span>
                            </template>

                            <template v-if="record.scheduled_at">
                              <span class="badge badge-pill badge-primary">SCHEDULED</span>
                            </template>
                          </td>
                          <td>
                            <template v-if="record.template == 1">
                              <i class="fa fa-star text-success"></i> <span class="sr-only">Template</span>
                            </template>
                            <template v-else>
                              <i class="fal fa-star"></i> <span class="sr-only">Not Template</span>
                            </template>
                          <td>
                            <template v-if="record.clients">
                              <span class="badge badge-pill badge-secondary">{{ record.clients }}</span>
                            </template>
                          </td>
                          <td>{{ record.created_by }}</td>
                          <td>{{ record.created_at | datetime }}

                            <template v-if="record.deleted_at">
                              <span class="badge badge-pill badge-danger">TRASHED</span>
                            </template>

                            <template v-if="record.archived_at">
                              <span class="badge badge-pill badge-warning">ARCHIVED</span>
                            </template>

                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>


                <template v-if="records && !records.length && !processing && !loading">
                  <div class="alert alert-warning">
                    No {{ $route.meta.title }}s found.
                  </div>
                </template>
              </div><!--Body-->
            </div><!--Card-->

          </div><!--Col-->
        </div><!--Row-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Col-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>


<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {

mixins: [ queries ],

data() {

    return {
        processing: false,
        searching: false,
        loading: false,
        searched: false,
        records: [],
        users: [],
        queries: {},
        form: {},
        search: {},
        total: 0,
        columns: [
        {label: 'name', key: 'name'},
        {label: 'type', key: 'type'},
        {label: 'status', key: 'status'},
        {label: 'template', key: 'template'},
        {label: '# clients', key: 'clients'},
        {label: 'creator', key: 'created_by'},
        {label: 'created', key: 'created_at'}
        ]
    }
},

mounted() {

  var self = this;


  this.$eventBus.$on(this.$route.meta.base_url, function (data) {
    self.addNewRecord(data);
  });
  
},

created() {

  this.resetQueries();
  this.resetForm();
  this.setURLParams();
  this.getRecords();
  this.getUsers();
  
},

methods: {

      setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
      },

      resetQueries() {
        this.queries = {
          skip: 0,
          limit: 10,
          search: null,
          created_by: null,
          type: null,
          status: null,
          trashed: false,
          sort: 'created_at',
          sortDirection: 'desc'
        }
      },

      setURLParams() {
          var searchParams = new URLSearchParams(window.location.search);

          var self = this;

          searchParams.forEach(function(value, key) {
              self.queries[key] = value;
          });
      },

      searchRecords() {
        this.searched = true;
        this.resetRecords();
        this.getRecords();
      },

      resetRecords() {
        this.records = [];
        this.queries.skip = 0;
        this.total = 0;
      },

      resetForm() {
        this.form = {
          'name': this.$route.meta.title,
          'type': this.$root.bulk_action_types[0]['value'],
          'search': {
            'limit': 250
          }
        }
      },

      postForm() {

        if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.settings) {
          this.form.search['limit'] = this.$root.current_user.current_agency.settings.default_bulk_actions_limit || 250;
        }

        this.processing = true;
        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {
            new this.$noty({text: 'Creating '+this.$route.meta.title}).show();
            this.resetForm();
            this.processing = false;
            if(response.data.uuid) {
              window.location.href = '/'+this.$route.meta.base_url + '/' + response.data.uuid;
            }
          }
          
        })
      },

      setStatusClass(status) {
        
          if(status == 'complete') {
              return 'badge-success';
          }

          if(status == 'draft') {
              return 'badge-light';
          }

          if(['unavailable action type', 'canceled'].includes(status)) {
              return 'badge-danger';
          }

          return 'badge-primary';
      },


      addNewRecord(data) {

        var index = _.findIndex(this.records, {'uuid': data.uuid});

        if(index == -1) {
          
          if(data.updated_at) {
            return;
          }

          this.total++;
          this.records.unshift(data);
          this.setTitle();
          return;
        }

        var records = [];

        this.records.forEach(function(record) {

          if(record.uuid == data.uuid) {
            record = {...record, ...data};
          }

          records.push(record);


        })

        this.records = records;

      },
        
      getRecords() {

        this.searching = true;

        this.updateURL();

        this.getTotal();

        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {
            
            if(response.data.length) {

              var self = this;

              response.data.forEach(function(data) {
                self.records.push(data);
              });
            }

            this.searching = false;

            this.queries.skip = this.records.length;
          }
        })
      },

      updateURL() {
        history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
      },

      getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
          if(response && response.status) {
            this.total = response.data;
            this.setTitle();
          }
        })
      },

      getUsers() {
        this.$http.get('/users?slim=true&fields=email&limit=1000&sort=email&sortDirection=asc')
        .then(response => {
          if(response && response.data) {
            this.users = response.data;
          }
        })
      },

    updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();
      
      this.getRecords();
    },

    sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

  setActionClass(value) {
        if(!this.form.type) {
          return;
        }

        if(this.form.type == value) {
          return 'active';
        }

    },

  updateAction(value) {
        this.form.type = value;
  }
}
}
</script>