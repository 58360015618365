<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row mb-3">
      <div class="col-sm-12 col-md-3">
        <template v-if="form.name">
          <h2 class="m-0">
            {{ form.name }}
          </h2>
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
        </template>

        <template v-if="form.map_url && !form.virtual">
          <a
            :href="form.map_url"
            target="_blank"
          >{{ form.full_address }}</a>
          <merge-tag :merge_tag="'[LocationFullAddress]'" />
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-3">
          <template v-if="portal && portal.uuid">
            <a :href="'https://'+portal.link+'.'+portal.domain+'/l/'+form.uuid" class="btn btn-secondary" target="_blank"><i class="fa fa-link"></i> Go to Portal</a>
          </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-3">
        <template v-if="form.full_address && !form.virtual">
          <map-links :address="form.full_address" />
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-3 text-right">

        <template v-if="form.uuid">
          <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
        </template>

          <div class="btn-group">

            <button
              type="button"
              class="btn btn-secondary dropdown-toggle radius-right-0"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">
              <a
                class="dropdown-item"
                @click="trashRecord()"
              >Trash</a>
            </div>
            <button
              type="submit"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
            >
             Save
            </button>
          </div>
        <router-link
          :to="{ name: $route.meta.base_url+'.index' }"
          class="btn btn-danger"
        >
          Close
        </router-link>
      </div><!--Col-->
    </div><!--Row-->
    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <form
              role="form"
              @submit.prevent="postForm()"
            >

              <div class="form-group">
                <label for="name">Name <merge-tag :merge_tag="'[LocationName]'" /></label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="location_reference">Location Reference</label>
                <input
                  id="location_reference"
                  v-model="form.location_reference"
                  type="text"
                  class="form-control"
                  :disabled="!superAdmin"
                >
              </div>

              <div class="form-group">
                <label for="description">Description <merge-tag :merge_tag="'[LocationDescription]'" /></label>
                <textarea
                  id="description"
                  v-model="form.description"
                  class="form-control"
                  rows="3"
                  maxlength="255"
                />
                <span
                  class="text-muted small"
                  :style="descriptionLength === 255 ? 'color: #dc3545!important' : ''"
                >{{ descriptionLength }} of 255</span>
              </div>

              <template v-if="!form.virtual">

                  <div class="form-group">
                    <label for="address">Address <merge-tag :merge_tag="'[LocationAddress]'" /></label>
                    <input
                      id="address"
                      v-model="form.address"
                      type="text"
                      class="form-control"
                    >
                  </div>

                  <div class="form-group">
                    <label for="address_2">Address 2 <merge-tag :merge_tag="'[LocationAddress2]'" /></label>
                    <input
                      id="address_2"
                      v-model="form.address_2"
                      type="text"
                      class="form-control"
                    >
                  </div>


                  <div class="form-group">
                    <label for="city">City <merge-tag :merge_tag="'[LocationCity]'" /></label>
                    <input
                      id="city"
                      v-model="form.city"
                      type="text"
                      class="form-control"
                    >
                  </div>


                  <div class="form-group">
                    <label for="county">County <merge-tag :merge_tag="'[LocationCounty]'" /></label>
                    <input
                      id="county"
                      v-model="form.county"
                      type="text"
                      class="form-control"
                    >
                  </div>


                  <div class="form-group">
                    <label for="state">State <merge-tag :merge_tag="'[LocationState]'" /></label>
                    <select
                      id="state"
                      v-model="form.state"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value="" />
                      <option
                        v-for="state in states"
                        :key="state.key"
                        :value="state.key"
                      >
                        {{ state.label }}
                      </option>
                    </select>
                  </div>
   
                  <div class="form-group">
                    <label for="zip">Zip <merge-tag :merge_tag="'[LocationZip]'" /></label>
                    <input
                      id="zip"
                      v-model="form.zip"
                      type="text"
                      class="form-control"
                    >
                  </div>

                <div class="form-group">
                  <label for="latitude">Latitude <merge-tag :merge_tag="'[LocationLat]'" /></label>
                  <input
                      id="latitude"
                      v-model="form.lat"
                      type="text"
                      class="form-control"
                  >
                </div>

                <template v-if="form.lat">
                  <button type="button" class="btn btn-outline-secondary btn-sm mb-3" @click="copyToClipboard(form.lat)" :disabled="processing"><i class="fa fa-copy"></i> Copy</button>
                </template>

                <div class="form-group">
                  <label for="longitude">Longitude <merge-tag :merge_tag="'[LocationLng]'" /></label>
                  <input
                      id="longitude"
                      v-model="form.lng"
                      type="text"
                      class="form-control"
                  >
                </div>

                <template v-if="form.lng">
                  <button type="button" class="btn btn-outline-secondary btn-sm mb-3" @click="copyToClipboard(form.lng)" :disabled="processing"><i class="fa fa-copy"></i> Copy</button>
                </template>

              </template>

              <div class="form-group">
                <label for="map_url"><template v-if="form.virtual">Virtual</template> <template v-else>Location</template> Link <merge-tag :merge_tag="'[LocationLink]'" /></label>
                <input
                    id="map_url"
                    v-model="form.map_url"
                    type="text"
                    class="form-control"
                >
              </div>

              <template v-if="form.virtual">

                <div class="form-group">
                <label for="passcode">Virtual Passcode <merge-tag :merge_tag="'[LocationPasscode]'" /></label>
                <input
                    id="passcode"
                    v-model="form.passcode"
                    type="text"
                    class="form-control"
                >
                </div>
              </template>

                <div class="form-group">
                  <label for="phone">Phone <merge-tag :merge_tag="'[LocationPhone]'" /></label>
                  <input
                    id="phone"
                    v-model="form.phone"
                    type="tel"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="email">Email <merge-tag :merge_tag="'[LocationEmail]'" /></label>
                  <input
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="website">Website <merge-tag :merge_tag="'[LocationWebsite]'" /></label>
                  <input
                    id="website"
                    v-model="form.website"
                    type="text"
                    class="form-control"
                  >
                </div>

              <div class="form-group">
                <label for="instructions">Instructions</label>
                <textarea id="instructions" class="form-control" v-model="form.instructions" rows="3"></textarea>
                <small class="text-muted">Add check-in, parking, how to prepare, or other helpful instructions.</small>
              </div>

              <div class="form-group">
                <label for="judge">Default Judge</label>
                <select id="judge" class="form-control" v-model="form.judge" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(judge, judge_index) in judges">
                    <option :key="'judge_'+judge_index" :value="judge.name">{{ judge.name }}</option>
                  </template>
                </select>
                <small class="text-muted">Can be overridden by Event.</small>
              </div>

              <div class="form-group">
                <label for="business_hours">Business Hours</label>
                <input id="business_hours" type="text" class="form-control" v-model="form.business_hours">
              </div>

              <div class="form-group">
                <label for="business_days">Business Days</label>
                <input id="business_days" type="text" class="form-control" v-model="form.business_days">
              </div>

              <div class="form-group">
                <label for="timezone">Time Zone</label>
                <select id="timezone" class="form-control" v-model="form.timezone" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(timezone, timezone_index) in timezones">
                    <option :key="'timezone_'+timezone_index" :value="timezone.key">{{ timezone.label }}</option>
                  </template>
                </select>
                <small class="form-text">Leave blank to use the <a href="/settings?tab=general">Time Zone configured in General Settings</a>.</small>
              </div>


                  <div class="form-group custom-control custom-switch">
                    <input
                        id="virtual"
                        v-model="form.virtual"
                        type="checkbox"
                        class="custom-control-input"
                    >
                    <label
                        class="custom-control-label"
                        for="virtual"
                    > Virtual Location</label>
                  </div>

                  <div class="form-group custom-control custom-switch">
                    <input
                      id="portal"
                      v-model="form.portal"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="portal"
                    > Enable on Portals</label>
                  </div>
               
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="default"
                      v-model="form.default"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="default"
                    > Default Location</label>
                  </div>

                  <div class="form-group custom-control custom-switch">
                    <input
                      id="disable_messages"
                      v-model="form.disable_messages"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="disable_messages"
                    > Auto-Disable Messages</label>
                  </div>
        
                  <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
              
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form && form.uuid">
          <audit-logs class="mt-3" :form="form"></audit-logs>
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <template v-if="form.virtual && form.map_url">

          <div class="card">
            <div class="card-body">
              <p class="card-title">Virtual Location</p>

              <template v-if="platform">
                <p>Platform {{ platform }}</p>
              </template>


                <template v-if="platform && platform != 'unknown'">

                <a :href="setWebLink(form.map_url)" target="_blank">
                    <img :src="$root.assets_url+'images/virtual-logos/'+platform+'.svg'" width="150" :alt="platform">
                </a>

                  <br>

                <div class="btn-group mt-3">
                <a :href="setWebLink(form.map_url)" class="btn btn-primary" target="_blank">Open in Browser</a>
                <template v-if="setAppLink(form.map_url)">
                 <a :href="setAppLink(form.map_url)" class="btn btn-primary text-capitalize" target="_blank">Open in Desktop</a>
                </template>
                </div>

                </template>


            </div>
          </div>

        </template>


        <template v-if="form && form.lat && form.lng && !form.virtual">
          <location-maps :record="form" />
        </template>

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>

<script>
export default {

  data () {
    return {
      processing: false,
      downloading: false,
      form: {},
      states: [],
      timezones: [],
      platform: null,
      portal: {},
      judges: []
    }
  },
  computed: {

    descriptionLength () {
      return this.form.description && this.form.description.length > 0 ? this.form.description.length : 0;
    },

    superAdmin () {
      return this?.$root?.current_user?.super_admin;
    }
  
  },

  mounted() {

    var self = this;

    this.$eventBus.$on('updateCoordinates', function (form) {
      if(form.uuid != self.form.uuid) {
        return;
      }
      self.form = form;
    });
  },
  
  created () {

    window.scrollTo({top: 0, left:0, behavior: 'smooth'});

    this.resetForm();
    this.getRecord();
    this.getStates();
    this.getTimezones();
    this.getJudges();

  },

  methods: {

    resetForm() {
        this.form = {
            name: null,
            address: null,
            address2: null,
            city: null,
            state: null,
            zip: null,
            country: null,
            lat: null,
            lng: null,
            instructions: null,
            judge: null,
            business_hours: null,
            business_days: null,
            timezone: null,
            map_url: null,
            passcode: null,
            virtual: false,
            portal: false,
            default: false,
            disable_messages: false
        }
    },

    getStates () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/states.json')
      .then(response => {
        if (response && response.data) {
          this.states = response.data;
        }
      })
    },

   getTimezones() {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/timezones.json')
        .then(response => {
            if(response && response.data) {
                this.timezones = response.data;
            }
        })
    },

    getRecord () {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
      .then((response) => {
        if (response && response.data) {
          this.form = response.data;
          this.processing = false;

          this.getPortal();

          if(this.form.name) {
            this.setTitle();
          }

          if(this.form.map_url) {
            this.platform = this.setVirtualPlatform(this.form.map_url);
          }
        }
      })
    },

    getPortal() {
        this.$http.get('/portals?domain=ecase.io&default=1&fields=uuid,link,domain,name&limit=1')
            .then(response => {
              if(response && response.data) {

                if(response.data[0]) {
                  this.portal = response.data[0];
                }
              }
            })
    },
    
    setTitle() {
      document.title = this.form.name + ' | Edit '+ this.$route.meta.title;
    },

    postForm () {
      this.processing = true;
      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if (response && response.status) {

          if(response.data.updated_at) {
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
            this.form.updated_at = new Date().toISOString();
          }

          this.processing = false;
        }
      })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
      .then(response => {
        if(response && response.status) {

          if(response.status === 204) {
            new this.$noty({text: this.$route.meta.title + ' trashed', type: 'error'}).show();
            this.form.deleted_at = new Date().toISOString();
          }

          this.processing = false;
        }
      })
    },

    copyToClipboard(value) {
        navigator.clipboard.writeText(value);
        new this.$noty({text: 'Copied to clipboard'}).show();
    },

    async downloadPDF() {
      try {

        window.scrollTo(0,0);

        this.downloading = true;

        const element = document.getElementById('main');

        const canvas = await this.$html2canvas(element, {
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          scale: window.devicePixelRatio,
          useCORS: true,
          logging: false
        });

        const imgData = canvas.toDataURL('image/png');

        const pdf = new this.$jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.message.uuid+'-'+this.$root.current_user.current_agency.name);

        pdf.save(name+'.pdf');

        this.downloading = false;
      } catch (error) {
        new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
        this.downloading = false;
      }
    },

    setVirtualPlatform(link) {

      var platform = this.getSettings('virtual_platform');

      if(platform) {
        return platform.toLowerCase();
      }

      if (/zoom\.us\/j\//i.test(link) || /zoom\.us\/w\//i.test(link)) {
        return 'zoom';
      } else if (/teams\.microsoft\.com/i.test(link)) {
        return 'teams';
      } else if (/webex\.com\/meet\//i.test(link) || /webex\.com\/join\//i.test(link)) {
        return 'webex';
      }
      else {
        return 'unknown';
      }
    },

    getSettings(key) {

      var settings = localStorage.getItem('settings');

      if(settings) {
        settings = JSON.parse(settings);
      }

      if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.settings) {
        settings = this.$root.current_user.current_agency.settings;
      }

      return settings[key] || null;
    },

    setAppLink(link) {
      switch (this.platform.toLowerCase()) {
        case 'zoom':
          if (link.startsWith("https://") || link.startsWith("http://")) {
            return;
          }
          return `zoomus://zoom.us/wc/${link}/join`;

        case 'teams':
          if (link.startsWith("https://") || link.startsWith("http://")) {
            return;
          }
          return `msteams://teams.microsoft.com/l/meetup-join/${link}`;
      }
    },

    setWebLink(link) {
      switch (this.platform.toLowerCase()) {
        case 'zoom':
          if (link.startsWith("https://") || link.startsWith("http://")) {
            return link;
          }

          var url = `https://zoom.us/wc/${link}/join`;

          if(this.form.passcode) {
            url += `?pwd=${this.form.passcode}`;
          }

          return url;

        case 'teams':
          if (link.startsWith("https://") || link.startsWith("http://")) {
            return link;
          }
          return `https://teams.microsoft.com/l/meetup-join/${link}`;

        case 'webex':
          if (link.startsWith("https://") || link.startsWith("http://")) {
            return link;
          }
          return `https://meetings.webex.com/webex/?meetingNumber=${link}`;

      }

      return link;

    },

    getJudges() {
      this.$http.get('/judges?slim=true&fields=uuid,name&limit=1000&sort=name&sortOrder=asc')
          .then(response => {
            if(response && response.data) {
              this.judges = response.data;
            }
          })
    }
  }
}
</script>
