<template>
  <div>
    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-md-8">
        <h3><span v-if="form && form.full_address">{{ form.full_address }}</span></h3>
        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}es</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->


      <div class="col-md-4 text-right">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">
            <a
              v-if="!form.archived_at"
              class="dropdown-item"
              @click="archiveRecord"
            >Archive</a>
            <a
              v-if="!form.deleted_at"
              class="dropdown-item"
              @click="trashRecord"
            >Trash</a>
            <a
              v-if="form.archived_at || form.deleted_at"
              class="dropdown-item"
              @click="restoreRecord"
            >Restore</a>
          </div>

          <template v-if="!form.archived_at && !form.deleted_at">
            <button
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm"
            >
              Save
            </button>
          </template>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <div class="card">
          <div class="card-body">

            <p class="card-title">Edit {{ $route.meta.title }}</p>


            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="address_country">Country</label>
                <select
                  id="address_country"
                  v-model="form.country"
                  class="form-control"
                >
                  <template v-if="countries && countries.length > 0">
                    <template v-for="(country, index) in countries">
                      <option
                        :key="'country_'+index"
                        :value="country.code"
                      >
                        {{ country.code }} - {{ country.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="address_1">Address 1</label>
                <input
                  id="address_1"
                  v-model="form.address_1"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="address_2">Address 2</label>
                <input
                  id="address_2"
                  v-model="form.address_2"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="city">City</label>
                <input
                  id="city"
                  v-model="form.city"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="state">State</label>

                <template v-if="form.country === 'US'">
                  <select
                    id="state"
                    v-model="form.state"
                    class="form-control"
                    :disabled="processing"
                  >
                    <template v-if="states && states.length > 0">
                      <template v-for="(state, index) in states">
                        <option
                          :key="'state_'+index"
                          :value="state.key"
                        >
                          {{ state.label }}
                        </option>
                      </template>
                    </template>
                  </select>
                </template>

                <template v-else>
                  <input
                    v-model="form.state"
                    type="text"
                    class="form-control"
                  >
                </template>
              </div>

              <div class="form-group">
                <label for="postal_code">Postal Code</label>
                <input
                  id="postal_code"
                  v-model="form.postal_code"
                  type="text"
                  class="form-control"
                >
              </div>


              <div class="form-group">
                <label for="address_type">Type</label>
                <select
                  id="address_type"
                  v-model="form.type"
                  class="form-control"
                  @change="setMarker()"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(address_type, index) in address_types">
                    <option
                      :key="'address_type_'+index"
                      :value="address_type"
                    >
                      {{ address_type }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="address_notes">Internal Notes</label>
                <textarea
                  id="address_notes"
                  v-model="form.notes"
                  class="form-control"
                  rows="3"
                />
              </div>

              <div class="form-group">
                <label for="latitude">Latitude</label>
                <input
                  id="latitude"
                  v-model="form.latitude"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="longitude">Longitude</label>
                <input
                  id="longitude"
                  v-model="form.longitude"
                  class="form-control"
                >
              </div>

                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                  :hidden="form.deleted_at || form.archived_at"
                >
                  Save
                </button>

            </form>

          </div><!--Body-->
        </div><!--Card-->


        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">
        <template v-if="form.client">
          <client-card
            :client="form.client"
          />
        </template>
      </div><!--Col-->
      
      <div class="col-sm-12 col-md-4">
        <div
          id="map"
          class="mb-3 map-lg"
        />
        
      </div><!--Col-->
    </div><!--Row-->
  </div><!--Container-->
</template>
<script>
import queries from '../mixins/queries';
export default {
    
    mixins: [ queries ],

    data () {
        return {
            processing: false,
            generating: false,
            address_types: ['home', 'work', 'mail'],
            form: {},
            map: null,
            marker: {},
            loading: false,
            countries: [],
            states: [],
            verification: {},
            mails: [],
            geocodes: [],
            send_mail: {},
            from_addresses: []
        }
    },

    mounted () {

        if(this.map) {
          this.map.remove();
        }
        
        this.initMap();
        this.getRecord();
        this.getCountries();
        this.getStates();
 
    },

      beforeDestroy() {
        if (this.map) {
          this.map.remove();
        }
      },

    methods: {

        getStates() {
            this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/states.json')
            .then(response => {
                if(response && response.data) {
                    this.states = response.data;
                }
            })
        },

        getCountries() {
            this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/country_codes.json')
            .then(response => {
                if(response && response.data) {
                    this.countries = response.data;
                }
            })
        },

        setStatusClass(status) {
            if(status === 'deliverable') {
                return 'badge-success'
            }

            if(status.includes("missing")) {
                return 'badge-warning'
            }

            return 'badge-danger'
        },

        getRecord () {
            this.processing = true
            this.$http.get('/'+this.$route.meta.base_url + '/' + this.$route.params.id)
            .then(response => {
                if (response && response.data) {
                    this.form = response.data

                    if(this.form.full_address) {
                        this.getGeocodes(this.form.full_address);
                    }

                    this.setMarker();

                    this.processing = false;
                }
            })
        },

        getGeocodes(address) {

            if(this.form.latitude && this.form.longitude) {
                return
            }

            this.$http.get('/geocode?address='+address)
            .then(response => {
                if(response && response.data) {
                    this.geocodes = response.data

                    if(this.geocodes[0] && this.geocodes[0]['geometry']) {
                        if(this.geocodes[0]['geometry']['location']) {
                            this.form.latitude = this.geocodes[0]['geometry']['location']['lat'];
                            this.form.longitude = this.geocodes[0]['geometry']['location']['lng'];

                            this.setMarker();
                        }
                    }

                }
            })
        },

        setMarker() {

            if(!this.form.latitude || !this.form.longitude) {
              return;
            }

            if(this.map.hasLayer(this.marker)){
              this.map.removeLayer(this.marker);
            }

            this.map.flyTo([this.form.latitude, this.form.longitude], 14);

            var icons = {
              "home": "house",
              "work": "building",
              "mail": "mailbox"
            }

            const icon = icons[this.form.type] || 'map-marker';

            const markerIcon = window.L.divIcon({
              html: '<i class="fa text-primary fa-2x fa-'+icon+'"></i>',
              iconSize: [20, 20],
              className: 'marker-icon'
            });

            this.marker = window.L.marker([this.form.latitude, this.form.longitude], {icon: markerIcon}).addTo(this.map);

            var self = this;

            this.marker.on('click', function(e) {
              self.map.flyTo(e.latlng, 18);
            });
        },

        initMap() {

            const light = window.L.tileLayer(this.$root.map_url+'alidade_smooth/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
            });

            const dark = window.L.tileLayer(this.$root.map_url+'alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
            });

            const bright = window.L.tileLayer(this.$root.map_url+'osm_bright/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
            });

            const outdoors = window.L.tileLayer(this.$root.map_url+'outdoors/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
            });

            const baseLayers = {
              'Light': light,
              'Dark': dark,
              'Bright': bright,
              'Outdoors': outdoors,
            };

            this.map = window.L.map("map", {layers: light}).setView(this.$root.coordinates, 4);

            window.L.control.layers(baseLayers).addTo(this.map);

        },

        restoreRecord() {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
            .then(response => {
                if(response && response.status === 200) {
                    new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                    this.processing = false;
                    this.form.archived_at = null;
                    this.form.deleted_at = null;
                }
            })
        },

        archiveRecord() {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/archive')
            .then(response => {
                if(response && response.status === 200) {
                    new this.$noty({text: this.$route.meta.title + ' archived'}).show();
                    this.processing = false;
                    this.getRecord();
                }
            })
        },

        trashRecord() {
            this.processing = true;
            this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
            .then(response => {
                if(response && response.status === 204) {
                    new this.$noty({text: this.$route.meta.title + ' trashed', type: 'error'}).show();
                    this.processing = false;
                    this.getRecord();
                }
            })
        },

        postForm() {

            if(this.form.archived_at || this.form.deleted_at) {
                new this.$noty({text: 'Restore to update'})
                return
            }

            this.processing = true

            this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
            .then(response => {
                if(response && response.data && response.status === 200) {
                    this.processing = false;

                    if(response.data.updated_at) {
                        new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                        this.form.updated_at = response.data.updated_at;
                        this.setMarker();
                    }
                    
                }
            })
        }

    }
}
</script>
