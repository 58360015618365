<template>
  <div>

    <page-tabs page="reports" />

    <div class="row">
      <div class="col-12 col-xl-3 mb-3">
            <div class="card">
              <div class="card-body">
                <p class="card-title">
                  Add {{ $route.meta.title }}
                </p>

                <form
                  @submit.prevent="postForm"
                >
                  <div class="form-group">
                    <label for="name">{{ $route.meta.title }} Name</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      placeholder="Monthly Messages"
                      required
                    >
                  </div>
                
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    Add
                  </button>
                </form>
              </div><!--Body-->
            </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                  type="search"
                >
              </div>

              <div class="form-group">
                <label for="export">Auto-Export Frequency</label>
                <select 
                  id="export"
                  v-model="queries.export"
                  class="form-control" 
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(export_frequency, export_index) in export_frequencies">
                    <option 
                      :key="'frequency_'+export_index" 
                      :value="export_frequency['value']"
                    >
                      {{ export_frequency['label'] }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="created_by">Creator</label>
                <select 
                  id="created_by"
                  v-model="queries.created_by"
                  class="form-control" 
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(user, index) in users">
                    <option 
                      :key="'user_'+index" 
                      :value="user.email"
                    >
                      {{ user.name }} {{ user.email }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="trashed"
                >Trashed</label>
              </div>


              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->


      </div><!--Col-->
      <div class="col-12 col-xl-9">

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <template v-if="total >= 0">
                  <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
                  </p>
                </template>
              </div><!--Col-->

              <div class="col-md-6 text-right">
                <loader :is-loading="processing" />
              </div><!--Col-->
            </div><!--Row-->

            <div class="table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <template v-for="(column, index) in columns">
                    <th :key="'column_'+index">
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </thead>
                <tbody>
                  <template v-for="record in records">
                    <tr :key="record.uuid">
                      <td>
                        <router-link :to="{ 'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid} }">
                          {{ record.name }} <template v-if="!record.name">{{ record.uuid }}</template>
                        </router-link>
                      </td>
                      <td>{{ record.description }}</td>
                      <td>
                          <template v-if="record.export">
                            <span class="badge badge-pill badge-primary text-uppercase">{{ record.export }}</span>
                          </template>
                      </td>
                      <td>
                          <template v-if="record.status">
                            <span class="badge badge-pill badge-primary text-uppercase">{{ record.status }}</span>
                          </template>
                      </td>
                      <td>{{ record.created_by }}</td>
                      <td>
                          
                        <template v-if="record.last_active">
                          {{ record.last_active | datetime }}
                          <br><span class="badge badge-pill badge-success">{{ record.last_active | dateTimezone | timeago }}</span>
                        </template>
                        <template v-else>
                          <span class="badge badge-pill badge-danger">Not Active</span>
                        </template>
                        
                      </td>
                      <td>{{ record.created_at | datetime }}
                        <span v-if="record.deleted_at" class="badge badge-pill badge-danger">
                          Trashed
                        </span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>


            </div>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords()"
              >
                Load more
              </button>
            </div>
          </div><!--Row-->
        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data () {
        return {
            processing: false,
            records: [],
            form: {},
            queries: {},
            total: 0,
            export_frequencies: [],
            users: [],
            columns: [
            { key: 'name', label: 'Name'},
            { key: 'description', label: 'Description'},
            { key: 'export', label: 'Auto-Export'},
            { key: 'status', label: 'Status'},
            { key: 'created_by', label: 'Creator'},
            { key: 'last_active', label: 'Last Exported'},
            { key: 'created_at', label: 'Created'}
            ],
        }
    },

    created () {

        this.resetForm();
        this.resetQueries();
        this.setURLParams();
        this.getRecords();
        this.getUsers();
        this.getExportFrequencies();
    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });

        },
        
        resetQueries() {
            this.queries = {
                search: null,
                created_by: null,
                slim: true,
                limit: 10,
                trashed: false,
                export: null,
                sort: 'last_active',
                sortDirection: 'desc'
            }
        },

        resetForm() {
            this.form = {
                name: null
            }
        },

        resetRecords() {
            this.records = [];
            this.queries.skip = 0;
            this.total = 0;
        },

        getExportFrequencies () {
            this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/export_frequencies.json')
            .then(response => {
                if (response && response.data) {
                    this.export_frequencies = response.data;
                }
            })
        },
    
        getUsers() {
          this.$http.get('/users?slim=true&limit=1000&fields=name,email&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.users = response.data;
            }
          })
        },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        getRecords () {

            this.processing = true;

            this.updateURL();
            this.getTotal();

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.status) {

                    var self = this;

                    response.data.forEach(function(data) {
                        self.records.push(data);
                    });

                    this.queries.skip = this.records.length;
                    this.processing = false;
                }
            })
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status === 200) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
        },

        getExport (record, file) {
            this.$http.get('/'+this.$route.meta.base_url+'/export/'+record+'?file=' + file)
            .then(response => {
                if(response && response.data) {
                    window.open(response.data, '_blank');
                }
                this.processing = false;
            })
        },

        saveLastExport(record) {
            this.processing = true;
            this.$http.get('/'+this.$route.meta.base_url+'/'+record.uuid+'/last_export')
            .then(response => {
                if(response && response.data && !response.data.message) {
                    new this.$noty({text: 'Downloading '+this.$route.meta.title}).show();
                    window.open(response.data, '_blank');
                }

                if(response && response.data && response.data.message) {
                    new this.$noty({text: response.data.message}).show();
                }

                this.processing = false;
            })
        },

        updateSortDirection() {

          if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
          } else {
            this.queries.sortDirection = 'asc';
          }

          this.resetRecords();
          this.getRecords();
        },

        sortColumn(column) {

          this.queries.sort = column.key;

          this.updateSortDirection();
        },

        postForm() {
            this.processing = true;
            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                this.form = {}
                if(response && response.status === 201) {
                    this.records.unshift(response.data);
                    this.total++;
                    this.setTitle();
                    this.resetForm();
                    new this.$noty({text: this.$route.meta.title + ' added'}).show();
                    this.processing = false;
                }
            });
        }
    }
}
</script>
