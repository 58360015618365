<template>
  <div>

    <div class="card mb-3">
        <div class="card-body">

            <p class="card-title">Add Transaction</p>

        <form @submit.prevent="postForm()">

          <div class="form-group">
            <label for="transaction_amount">Transaction Amount</label>
            <div class="input-group">
              <span
                class="input-group-text"
                style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
              >$</span>
              <input
                id="transaction_amount"
                v-model="form.amount"
                type="number"
                class="form-control"
                min="0"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="form-group">
            <label for="transaction_status">Status</label>
            <select
              id="transaction_status"
              v-model="form.status"
              class="form-control"
              :disabled="processing"
              required
            >
              <option
                v-for="status in statuses"
                :key="'transaction_status_'+status"
                :value="status"
              >
                {{ status }}
              </option>
            </select>
          </div>

          <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

        </form>

        </div><!--Body-->
    </div><!--Card-->

    <div class="card">
      <div class="card-body" style="overflow-y: auto; max-height: 500px;">

        <div class="row mb-3">
            <div class="col-md-4 col-sm-12">
                <span class="badge badge-pill badge-danger text-uppercase">Outstanding ${{ totalOutstanding.toLocaleString() }}</span>
            </div><!--Col-->

            <div class="col-md-4 col-sm-12">
                <span class="badge badge-pill badge-info text-uppercase">Refunded ${{ totalRefund.toLocaleString() }}</span>
            </div><!--Col-->

            <div class="col-md-4 col-sm-12 text-right">
                <span class="badge badge-pill badge-success text-uppercase">Paid ${{ totalPaid.toLocaleString() }}</span>
            </div><!--Col-->

        </div><!--Row-->

        <div class="row mb-3">
            <div class="col-6">
                <p class="card-title m-0">
                    {{ records.length }} {{ title }}s
                </p>
            </div><!--Col-->
            <div class="col-6 text-right">
                <button type="button" class="btn btn-outline-secondary btn-sm tooltip-left" data-tooltip="Refresh transactions" @click="getRecords()" :disabled="processing"><i class="fa fa-sync"></i></button>
            </div><!--Col-->
        </div><!--Row-->

        <template v-if="records && records.length > 0">

        <ul class="list-group">
          <template v-for="(record, transaction_key) in records">
            <li
              :key="'transaction_'+transaction_key"
              class="list-group-item list-group-item-action link-hover"
            >
              <router-link :to="base_url+'/'+record.uuid">
                <span class="badge badge-pill text-uppercase" :class="setStatusClass(record.status)">{{ record.status }}</span>

                {{ record.amount }}

                <template v-if="record.fee">
                  <small class="text-muted">{{ record.fee }}</small>
                </template>

                <br>
                <small class="text-muted">{{ record.created_at | datetime }} {{ record.created_at | dateTimezone | timeago }}</small>
              </router-link>
            </li>
          </template>
        </ul>

        </template>

        <template v-if="!records || !records.length">
            <div class="alert alert-warning" :hidden="processing">No {{ title }}s found.</div>
        </template>

      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],
    
    props: ['payment'],

    data () {
        return {
            processing: false,
            base_url: '/transactions',
            title: 'Transaction',
            records: [],
            queries: {
                limit: 1000,
                sort: 'created_at',
                sortDirection: 'desc'
            },
            form: {},
            statuses: ['paid', 'refunded']
        }
    },

    computed: {

        totalOutstanding: function() {
            if(!this.payment.amount) {
                return 0;
            }

            return this.payment.amount - (this.totalPaid - this.totalRefund);
        },

        totalPaids() {

            if(!this.records || !this.records.length) {
                return [];
            }

            var result = [];

            var self = this;

            this.records.forEach(function(data) {

                if(!['succeeded', 'paid'].includes(data.status)) {
                    return;
                }

              result.push(parseFloat(self.$options.filters.parseMoney(data.amount)));
            });

            return result;

        },

        totalRefunds() {

            if(!this.records || !this.records.length) {
                return [];
            }

            var result = [];

            var self = this;

            this.records.forEach(function(data) {

                if(!['refunded'].includes(data.status)) {
                    return;
                }

                result.push(parseFloat(self.$options.filters.parseMoney(data.amount)));
            });

            return result;

        },

        totalRefund() {

            if(!this.totalRefunds || !this.totalRefunds.length) {
                return 0;
            }

            return this.totalRefunds.reduce((a, b) => a + b, 0);
        },

        totalPaid() {

            if(!this.totalPaids || !this.totalPaids.length) {
                return 0;
            }

            return this.totalPaids.reduce((a, b) => a + b, 0);
        },

    },

    created () {
        this.resetForm();
        this.getRecords();
    },

    methods: {
        
        setStatusClass(status) {

            var success = ['succeeded', 'paid'];

            if(success.includes(status)) {
                return 'badge-success';
            }

            var danger = ['refund', 'refunded', 'void'];

            if(danger.includes(status)) {
                return 'badge-danger';
            }

            return 'badge-primary';
        },

        resetForm() {
            this.form = {
                amount: 0,
                payment: null,
                client: null,
                status: null,
                token: null
            }
        },

        getRecords () {

            this.processing = true;

            this.queries.payment = this.payment.uuid;

            this.$http.get(this.buildQueries(this.base_url, this.queries))
            .then(response => {
                if (response && response.data) {
                    this.records = response.data;
                    this.processing = false;
                }
            })
        },

        postForm() {

            if(this.payment.uuid) {
                this.form.payment = this.payment.uuid;
            }

            if(this.payment.client) {
                this.form.client = this.payment.client;
            }

            this.processing = true;
            this.$http.post(this.base_url, this.form)
            .then(response => {
                if(response && response.data) {

                    if(response.data.created_at) {
                        new this.$noty({text: this.title + ' created'}).show();
                        this.records.unshift(response.data);
                        this.resetForm();
                    }

                    this.processing = false;
              
                }
            })
        },
    }
}
</script>
