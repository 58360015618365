<template>
  <div>
    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-md-8">
        <h1>{{ form.reference }}</h1>
        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-md-4 text-right">
        <div class="btn-group">

          <div class="btn-group">
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">

              <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord"
              >Archive</a>
              <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord"
              >Restore</a>
              <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord"
              >Trash</a>
            </div>
          </div>

          <button
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="updateRecord"
          >
            Save
          </button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>
        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <div class="card mb-3">
          <div class="card-body">

                <p class="card-title">
                  Edit {{ $route.meta.title }}
                </p>

            <form @submit.prevent="updateRecord()">

              <div class="form-group mt-3">
                <label for="reference">Reference</label>
                <input
                    id="reference"
                    type="text"
                    v-model="form.reference"
                    class="form-control"
                    required
                >
              </div>

              <div class="form-group">
                <label for="type">Type</label>
                <select
                    id="type"
                    v-model="form.type"
                    class="form-control text-uppercase"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(record_type, type_index) in record_types">
                    <option :key="'record_type_'+type_index" :value="record_type">{{ record_type }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="case_number">Case Number</label>
                <input
                    id="case_number"
                    type="text"
                    v-model="form.case_number"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="bond_number">Bond Number</label>
                <input
                    id="bond_number"
                    type="text"
                    v-model="form.bond_number"
                    class="form-control"
                >
              </div>

              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                Save
              </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs :form="form" />
      </div><!--Col-->
      <div class="col-sm-12 col-md-4">
        <template v-if="form.client">
          <client-card
              :client="form.client"
              class="mb-3"
          />
        </template>


      </div><!--Col-->

      <div class="col-sm-12 col-md-4">


      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      searching: false,
      form: {},
      record_types: ['arrest', 'search', 'bench', 'extradition', 'alias']
    }
  },

  created () {
    this.getRecord();
  },

  methods: {

    getRecord () {
      this.processing = true;
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url + '/' + this.$route.params.id, this.queries))
          .then(response => {
            if (response && response.data) {
              this.form = response.data;
              this.processing = false;

              if(response.data.reference) {
                document.title = response.data.reference + ' | '+this.$route.meta.title;
              }
            }
          })
    },

    restoreRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                this.getRecord();
              }

            }
          })
    },

    archiveRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                this.getRecord();
              }

            }

          })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then(() => {
            this.processing = false;
            new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
            this.getRecord();
          })
    },

    updateRecord() {
      this.processing = true;
      this.$http.patch('/'+this.$route.meta.base_url + '/' + this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              if(response.data.updated_at) {
                new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                this.form.updated_at = response.data.updated_at;
              }

              this.processing = false;
            }
          })
    },

  }
}
</script>
