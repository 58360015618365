<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-md-8">
        <h1 class="m-0">{{ form.lookup }}</h1>
        <template v-if="form.valid && form.valid == 1">
          <span class="badge badge-success">VALID</span>
        </template>
        <template v-else>
          <span class="badge badge-danger">INVALID</span>
        </template>
        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-md-4 text-right">
        <div class="btn-group">

          <template v-if="form && form.lookup">
            <button type="button" class="btn btn-secondary d-print-none" @click="downloadPDF()" :disabled="processing || downloading" data-html2canvas-ignore><i class="fa fa-file-pdf"></i> PDF </button>
          </template>

          <div class="btn-group d-print-none" data-html2canvas-ignore>
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">
              <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord"
              >Archive</a>
              <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord"
              >Restore</a>
              <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord"
              >Trash</a>
            </div>
          </div>

          <button
              type="button"
              class="btn btn-success d-print-none"
              :disabled="processing"
              @click="postForm()"
              data-html2canvas-ignore
          >
            Save
          </button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger d-print-none"
              data-html2canvas-ignore
          >
            Close
          </router-link>
        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">Edit {{ $route.meta.title }}</p>

            <form @submit.prevent="postForm">

              <template v-if="form.type">
                <p class="m-0">Type {{ form.type }}</p>
              </template>

              <template v-if="form.status">
                <p class="m-0">Status {{ form.status }}</p>
              </template>

              <div class="form-group mt-3">
                <label for="notes">Internal Notes</label>
                <textarea id="notes" class="form-control" v-model="form.notes" :disabled="processing"></textarea>
              </div>

              <button type="submit" class="btn btn-success d-print-none" :disabled="processing" data-html2canvas-ignore>Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form.uuid">
          <audit-logs :form="form" />
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-6">


          <div class="card">
            <div class="card-body">

              <p class="card-title">Lookup Data</p>

              <template v-if="form && !form.data && !processing">
                <div class="alert alert-danger m-0">No data found</div>
              </template>

              <template v-if="form && form.data">

              <template v-if="form.data.linkedin_profile_url">
                <a :href="form.data.linkedin_profile_url" target="_blank" class="btn btn-secondary btn-sm"><i class="fa fa-linkedin"></i> LinkedIn</a>
              </template>

              <template v-if="form.data.twitter_profile_url">
                <a :href="form.data.twitter_profile_url" target="_blank" class="btn btn-secondary btn-sm"><i class="fa fa-twitter"></i> X (Twitter)</a>
              </template>

              <template v-if="form.data.facebook_profile_url">
                <a :href="form.data.facebook_profile_url" target="_blank" class="btn btn-secondary btn-sm"><i class="fa facebook"></i> Facebook</a>
              </template>

              <template v-if="form.data.profile">

                <template v-if="form.data.profile.name">
                  <p class="m-0">{{ form.data.profile.name }}</p>
                </template>

                <template v-if="form.data.profile.public_identifier">
                  <p class="m-0">@{{ form.data.profile.public_identifier }}</p>
                </template>

                <template v-if="form.data.profile.profile_pic_url">
                  <img :src="form.data.profile.profile_pic_url" width="100" alt="Social media thumbnail">
                </template>

                <template v-if="form.data.profile.background_cover_image_url">
                  <img :src="form.data.profile.background_cover_image_url" width="100" alt="Social media background">
                </template>


              </template>

              <template v-if="form.type == 'email'">

                <p class="m-0">
                  Disposable Email
                  <template v-if="form.data.disposable_email">
                    Yes
                  </template>
                  <template v-else>
                    No
                  </template>
                </p>

                <p class="m-0">
                  Is Free Email
                  <template v-if="form.data.is_free_email">
                  Yes
                  </template>
                  <template v-else>
                    No
                  </template>
                </p>

                <template v-if="form.data.isp_name">
                  <p class="m-0">
                    ISP {{ form.data.isp_name }}
                  </p>
                </template>

              </template>

              <template v-if="form.data.country_code">
                <p class="m-0">
                  Country Code {{ form.data.country_code }}
                </p>
              </template>

              <template v-if="form.data.phone_number">
                <p class="m-0">
                  Phone Number {{ form.data.phone_number }}
                </p>
              </template>

              <template v-if="form.data.national_format">
                <p class="m-0">
                  National Format {{ form.data.national_format }}
                </p>
              </template>

              <template v-if="form.data.fraud">
                <p class="m-0">
                  Fraud {{ form.data.fraud }}
                </p>
              </template>

              <template v-if="form.data.carrier">

              <template v-if="form.data.carrier.name">
                <p class="m-0">
                  Carrier {{ form.data.carrier.name }}
                </p>
              </template>

              <template v-if="form.data.carrier.type">
                <p class="m-0">
                  Line Type {{ form.data.carrier.type }}
                </p>
              </template>

              </template>

              <template v-if="form.data.caller_name && form.data.caller_name.caller_name">
                <p class="m-0">
                  Caller Name {{ form.data.caller_name.caller_name }}
                </p>
              </template>

              <template v-if="form.data.portability">

                <template v-if="form.data.portability.city">
                  <p class="m-0">
                    Portability Location {{ form.data.portability.city }} {{ form.data.portability.state }}
                  </p>
                </template>

                <template v-if="form.data.portability.osn">
                  <p class="m-0">
                    Portability OSN {{ form.data.portability.osn }}
                  </p>
                </template>

              </template>

              </template><!--FormData-->

            </div>
          </div>

            <div class="card my-3" v-show="area_code && area_code.code">
              <div class="card-body">
                <p class="card-title">Area Code</p>

                <ul class="list-group">

                  <li class="list-group-item">Code {{ area_code.code }}</li>

                  <template v-if="area_code.city">
                    <li class="list-group-item">City {{ area_code.city }}</li>
                  </template>

                  <template v-if="area_code.state">
                  <li class="list-group-item">State {{ area_code.state }}</li>
                  </template>

                  <li class="list-group-item">Country {{ area_code.country }}</li>

                </ul>

                <div
                    ref="area_code_map"
                    class="map-sm mt-3"
                />

              </div><!--Body-->
            </div><!--Card-->


      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

        <div class="btn-group mb-3">
          <a :href="'/messages?direction=inbound&search_field=from&search='+form.lookup" class="btn btn-secondary btn-sm"><i class="fa fa-inbox-in"></i> Inbound Messages</a>
          <a :href="'/messages?direction=outbound&search_field=to&search='+form.lookup" class="btn btn-secondary btn-sm"><i class="fa fa-inbox-out"></i> Outbound Messages</a>
        </div>


        <template v-for="(message, message_index) in messages">
          <message :key="'message_'+message_index" :message="message" />
        </template>

        <template v-if="messages && messages.length === 0 && !processing">
          <div class="alert alert-warning">No messages found</div>
        </template>

      </div><!--Col-->


    </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
export default {

  data () {
    return {
      processing: false,
      downloading: false,
      form: {},
      area_codes: [],
      area_code: {'code': null},
      map: null,
      messages: []
    }
  },

  created() {
    this.resetForm();
    this.getRecord();
  },

  beforeDestroy() {
    if(this.map) {
      this.map.remove();
    }
  },

  methods: {

    resetForm() {
      this.form = {
        lookup: null
      }
    },

    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {
              this.processing = false;
              this.form = response.data;

              this.getAreaCodes();

              this.getMessages();
            }
          })
    },

    getMessages() {
      if(!this.form.lookup) {
        return;
      }

      this.$http.get('/messages?sort=scheduled_at&sortDirection=desc&search_field=from&search='+this.form.lookup)
          .then(response => {
            if(response && response.data) {

              if(!response.data.data) {
                return;
              }

                response.data.data.forEach((message) => {
                    this.messages.push(message);
                });
            }
          })

      this.$http.get('/messages?sort=scheduled_at&sortDirection=desc&search_field=to&search='+this.form.lookup)
          .then(response => {
            if(response && response.data) {

              if(!response.data.data) {
                return;
              }

              response.data.data.forEach((message) => {
                this.messages.push(message);
              });
            }
          })
    },

    getAreaCodes() {

      if(this.form.type !== 'phone') {
        return;
      }

      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/area_codes.json')
          .then(response => {
            if(response && response.data) {
              this.area_codes = response.data;

              var code = this.form.lookup;

              code = code.replace('+', '');

              if(code.length === 10) {
                code = '1' + code;
              }

              if(code.length === 11) {
                code = '+' + code;
              }

              code = code.replace('+', '');

              code = code.substring(1, 4);

              this.area_codes.forEach((area_code) => {
                if(area_code.code == code) {
                  this.area_code = area_code;
                  this.initMap();
                }
              });
            }
          })
    },

    postForm() {
      this.processing = true;
      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {
              this.processing = false;
              new this.$noty({text: this.$route.meta.title + ' saved'}).show();
            }
          })
    },

    restoreRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
              }

            }
          })
    },

    archiveRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                this.form.archived_at = new Date().toISOString();
              }

            }

          })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then(() => {
            this.processing = false;
            new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
            this.form.deleted_at = new Date().toISOString();
          })
    },

    initMap() {

      if(!this.area_code || !this.area_code.lat || !this.area_code.lng) {
        return;
      }

      if(this.map) {
        this.map.remove();
        this.map = null;
      }

      this.map = window.L.map(this.$refs.area_code_map).setView([this.area_code.lat, this.area_code.lng], 10);
      window.L.tileLayer(this.$root.map_url+'alidade_smooth/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
      }).addTo(this.map);

      window.L.circle([this.area_code.lat, this.area_code.lng], {
        color: '#1C7AB1',
        fillColor: '#1C7AB1',
        fillOpacity: 0.5,
        radius: 10000
      }).addTo(this.map);

      setTimeout(() => {
        if(!this.map) {
          return;
        }
        this.map.invalidateSize()
      }, 100)
    },

    async downloadPDF() {
      try {

        if(!this.form.lookup) {
          return;
        }

        window.scrollTo(0,0);

        this.downloading = true;

        const element = document.getElementById('main');

        const canvas = await this.$html2canvas(element, {
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          scale: window.devicePixelRatio,
          useCORS: true,
          logging: false
        });

        const imgData = canvas.toDataURL('image/png');

        const pdf = new this.$jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.form.lookup);

        pdf.save(name+'.pdf');

        this.downloading = false;
      } catch (error) {
        new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
        console.log(error);
        this.downloading = false;
      }
    },

  },
}
</script>