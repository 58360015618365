<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-md-4">
        <h1>{{ form.name }}</h1>
        <h3>{{ form.phone_number }}</h3>
        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-md-4">


      </div><!--Col-->


      <div class="col-md-4 text-right">
        <div class="btn-group">

          <div class="btn-group">
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">
              <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
              >Archive</a>
              <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
              >Restore</a>
              <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
              >Trash</a>
            </div>
          </div><!--BtnGroup-->

          <button
              v-if="!form.archived_at && !form.deleted_at"
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
          >
            Save
          </button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title">
                  Edit {{ $route.meta.title }}
                </p>
              </div><!--Col-->

              <div class="col-6 text-right">
              </div><!--Col-->
            </div><!--Row-->

            <form
                @submit.prevent="postForm"
            >

              <div class="form-group">
                <label for="name">Name <merge-tag :merge_tag="'[AssignedOrganizationName]'" /></label>
                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    required
                >
              </div>

              <div class="form-group">
                <label for="reference">Reference</label>
                <input
                    id="reference"
                    v-model="form.reference"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="type">Type</label>
                <select
                    id="type"
                    v-model="form.type"
                    class="form-control text-capitalize"
                >
                  <option value=""></option>
                  <template v-if="(types && types.length > 0)">
                    <template v-for="(type, index) in types">
                      <option :key="'search_type_'+index" :value="type">{{ type }}</option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="address">Address <merge-tag :merge_tag="'[AssignedOrganizationAddress]'" /></label>
                <input
                    id="address"
                    v-model="form.address"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="address_2">Address 2</label>
                <input
                    id="address_2"
                    v-model="form.address_2"
                    type="text"
                    class="form-control">
              </div>

              <div class="form-group">
                <label for="city">City</label>
                <input
                    id="city"
                    v-model="form.city"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="state">State</label>
                <input
                    id="state"
                    v-model="form.state"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="zip">Zip</label>
                <input
                    id="zip"
                    v-model="form.zip"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="county">County</label>
                <input
                    id="county"
                    v-model="form.county"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="phone">Phone <merge-tag :merge_tag="'[AssignedOrganizationPhone]'" /></label>
                <input
                    id="phone"
                    v-model="form.phone"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="email">Email <merge-tag :merge_tag="'[AssignedOrganizationEmail]'" /></label>
                <input
                    id="email"
                    v-model="form.email"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="website">Website <merge-tag :merge_tag="'[AssignedOrganizationWebsite]'" /></label>
                <input
                    id="website"
                    v-model="form.website"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="virtual_link">Virtual Link <merge-tag :merge_tag="'[AssignedOrganizationVirtualLink]'" /></label>
                <input
                    id="virtual_link"
                    v-model="form.virtual_link"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="description">Description</label>
                <textarea
                    v-if="form"
                    id="description"
                    v-model="form.description"
                    type="text"
                    class="form-control"
                ></textarea>
              </div>


              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
            class="mt-3"
            :form="form"
        />
      </div><!--Col-->

      <div class="col-md-3">


      </div><!--Col-->

      <div class="col-md-3">

        <div class="card">
          <div class="card-body">

            <div class="row mb-3">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">{{ clients.length }} Clients</p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-right">
                <button type="button" class="btn btn-danger btn-sm" @click="resetClients()" :disabled="processing" v-if="clients && clients.length > 0">Reset</button>
              </div><!--Col-->
            </div><!--Row-->

            <ul class="list-group" v-if="clients && clients.length > 0">

              <template v-for="(client, client_index) in clients">
                <li :key="'client_'+client_index" class="list-group-item list-group-item-action">
                  <client-button :uuid="client.client"></client-button>
                </li>
              </template>
            </ul>

            <template v-if="clients && clients.length === 0">
             <div class="alert alert-warning">No clients found</div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      total: 0,
      queries: {
        limit: 100,
        slim: true,
        sort: 'name',
        sortDirection: 'asc'
      },
      clients: [],
      form: {},
      types: ['supervision', 'guardian', 'law enforcement', 'employer', 'school', 'court', 'medical', 'other'],
    }
  },

  computed: {

  },

  created() {
    this.resetForm();
    this.setURLParams();
    this.getRecord();
  },

  methods: {

    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    postForm() {
      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.data.updated_at) {
                this.form.updated_at = response.data.updated_at;
                new this.$noty({text: this.$route.meta.title + ' updated'}).show();
              }

            }
          })
    },

    resetForm() {
      this.form = {
        name: null,
        type: null,
        address: null,
        address_2: null,
        city: null,
        state: null,
        zip: null,
        county: null,
        phone: null,
        email: null,
        website: null,
        virtual_link: null,
        description: null
      }
    },

    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {

              if(response.data.uuid) {
                this.form = response.data;
                this.setTitle();
                this.getClients();
              }

              this.processing = false;
            }
          })
    },

    setTitle() {
      document.title = this.form.name + ' | ' + this.$route.meta.title;
    },

    restoreRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
              }

            }
          })
    },

    archiveRecord () {
      this.processing = true
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                this.form.archived_at = new Date().toISOString();
              }

            }

          })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
    },

    getClients() {
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/clients')
          .then(response => {
            if(response && response.data) {
              this.clients = response.data;
            }
          })
    },

    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      new this.$noty({text: 'Copied to clipboard'}).show();
    },

    resetClients() {
        this.processing = true;
        this.$http.delete('/'+this.$route.meta.base_url+'/reset_clients')
            .then(response => {
                if(response && response.status === 204) {
                    this.processing = false;
                    this.clients = [];
                    new this.$noty({text: 'Clients reset'}).show();
                }
            })
    }


  },
}
</script>