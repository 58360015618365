<template>
  <div>

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <h1 class="m-0"><span v-if="form && form.name">{{ form.name }}</span></h1>

        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <template v-if="portal && portal.name && portal.link">
          <a :href="'https://'+portal.link+'.'+portal.domain+'/fl/'+form.url" class="btn btn-secondary" target="_blank"><i class="fa fa-external-link"></i> Open Portal</a>
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

          <button type="button" class="btn btn-primary" @click="generatePDF()" :disabled="processing"><i class="fa fa-file-pdf"></i> Generate PDF</button>
          <button type="button" class="btn btn-success" @click="postForm()" :disabled="processing">Save</button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->

      <div class="row mt-3">
        <div class="col-sm-12 col-md-3">
          <div class="card">
            <div class="card-body">

              <form @submit.prevent="postForm()">

                <div class="form-group">
                  <label for="name">Name</label>
                  <input id="name" class="form-control" type="text" v-model="form.name">
                </div>

                <div class="form-group">
                  <label for="status">Status</label>
                  <select id="status" class="form-control text-uppercase" v-model="form.status">
                    <option value=""></option>
                    <template v-for="(file_status, index) in file_statuses">
                      <option :key="'file_status_'+index" :value="file_status">{{ file_status }}</option>
                    </template>
                  </select>
                </div>

                <div class="form-group custom-control custom-switch">
                  <input
                    id="signature"
                    v-model="form.signature"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                  >
                  <label
                    class="custom-control-label"
                    for="signature"
                  >Require eSignature</label>
                </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

            </div><!--Body-->
          </div><!--Card-->

          <audit-logs
            :form="form"
            class="mt-3"
          />

        </div><!--Col-->

        <div class="col-sm-12 col-md-6">

          <template v-if="form.file">
            <file-card :uuid="form.file" class="mb-3" />
          </template>

          <template v-for="(esignature, index) in esignatures">

            <div :key="'esignature_'+index" class="card mb-3">
              <div class="card-body">

                <p class="card-title">Electronic Signature</p>

                <template v-if="esignature.esignature">
                  <img :src="esignature.esignature" alt="Electronic Signature" class="img-fluid">
                </template>

                <ul class="list-group">

                  <template v-if="esignature.name">
                    <li class="list-group-item">Name {{ esignature.name }}</li>
                  </template>

                  <template v-if="esignature.ip_address">
                  <li class="list-group-item">IP Address <a :href="'/ips/'+esignature.ip_address">{{ esignature.ip_address }}</a></li>
                  </template>

                  <li class="list-group-item">Created {{ esignature.created_at | datetime }}</li>

                </ul>

                <template v-if="esignature.user_agent">
                  <user-agent class="mt-3" :raw="esignature.user_agent" />
                </template>

              </div><!--Body-->
            </div><!--Card-->

          </template>

        </div><!--Col-->

        <div class="col-sm-12 col-md-3">

          <template v-if="form && form.uuid && form.client">
            <client-card :client="form.client" />
            <client-contacts class="mt-3" :client_uuid="form.client" />

            <div class="card my-3">
              <div class="card-body">
                <p class="card-title">Send Message</p>

                <form @submit.prevent="postMessageForm()">

                <div class="form-group">
                  <label for="subject">Subject</label>
                  <input id="subject" type="text" class="form-control" v-model="sendMessage.subject">
                </div>

                <div class="form-group">
                  <label for="content">Message</label>
                  <textarea id="content" class="form-control" v-model="sendMessage.content" rows="6"></textarea>
                </div>

                <button type="submit" class="btn btn-success" :disabled="processing">Send</button>

                </form>

              </div>
            </div>

          </template>

        <p class="card-title mt-3">
          {{ messages.length }} of {{ total_messages }} messages
        </p>

        <template v-for="(message, index) in messages">
          <message
            :key="'message_'+index"
            :message="message"
          />
        </template>

        </div><!--Col-->

      </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
        processing: false,
        form: {},
        clients: [],
        url: null,
        client: {},
        queries: {
          client: null
        },
        total_messages: 0,
        messages: [],
        esignatures: [],
        portal: {},
        sendMessage: {},
        file_statuses: ['draft', 'sent', 'complete', 'canceled']
    }
},


created() {
    this.resetForm();
    this.resetSendMessage();
    this.getRecord();
},

methods: {

    resetForm() {
      this.form = {
        name: null,
        portal: null,
        status: null
      }
    },

    resetSendMessage() {
      this.sendMessage = {
        subject: null,
        content: null,
        client: null
      }
    },

    getRecord() {
        this.processing = true;

        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                this.form = response.data;
                this.setTitle();
                this.processing = false;
                this.getPortal();
                this.getESignatures();
                this.getMessages();
            }
        })
    },

  getPortal() {
      if(!this.form.portal) {
        return;
      }

      this.portal = {};

      this.$http.get('/portals/'+this.form.portal)
          .then(response => {
            if(response && response.data) {
              this.portal = response.data;
            }
          })
  },

    getESignatures() {
      this.$http.get('/esignatures?file='+this.form.uuid)
      .then(response => {
        if(response && response.data) {
          this.esignatures = response.data;
        }
      })
    },

    setTitle() {

      var title = this.form.name || this.$route.meta.title;

      document.title = title + ' | eCourtDate.com';
    },

    resetMessages() {
      this.messages = [];
      this.total_messages = 0;
    },

    getMessages() {

      if(!this.form.uuid) {
        this.resetMessages();
        return;
      }
      
      this.getTotalMessages();

      this.$http.get('/messages?file='+this.form.uuid+'&slim=true&limit=10&direction=outbound&sort=scheduled_at&sortDirection=asc')
      .then(response => {
        if(response && response.data && response.data.data) {
          this.messages = response.data.data;
        }
      })
    },

    getTotalMessages() {
      this.$http.get('/messages/total?file='+this.form.uuid)
      .then(response => {
        if(response && response.status) {
          this.total_messages = response.data;
        }
      })
    },

    postForm() {
      this.processing = true;
      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.status) {
          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = new Date().toISOString();
            new this.$noty({text: 'Sent file updated'}).show();
          }
        }
      })
    },

  generatePDF() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/pdf')
          .then(response => {
            if(response && response.data) {

              if(response.data.url) {
                window.open(response.data.url, '_blank');
              }

              this.processing = false;
            }
          })
  },

  postMessageForm() {

      if(!this.form.client) {
        new this.$noty({text: 'Client is required'}).show();
        return;
      }

      this.processing = true;

      this.sendMessage.file = this.form.uuid;

      this.sendMessage.content += ' https://'+this.portal.link+'.'+this.portal.domain+'/fl/'+this.form.url;

      this.sendMessage.client = this.form.client;

      this.$http.post('/messages', this.sendMessage)
          .then(response => {
            if(response && response.data) {
              this.processing = false;
              this.resetSendMessage();
              this.getMessages();
              new this.$noty({text: 'Message sent'}).show();
            }
          })
  }
}

}
</script>