<template>
  <div>
    <form @submit.prevent="searchRecords">
      <div class="input-group mb-3">
        <label
          class="sr-only"
          for="message_search"
        >{{ searchTitle }}</label>
        <input
          id="message_search"
          v-model="queries.search"
          type="search"
          class="form-control"
          :placeholder="searchTitle"
        >

        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary tooltip-left"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-filter" /> <small class="sr-only">Filter Messages</small>
          </button>
          <div class="dropdown-menu">
            <h6 class="dropdown-header">
              Filter Messages
            </h6>

            <a
              v-for="filter in options.message_filters" 
              :key="filter.key"
              class="dropdown-item text-capitalize"
              :class="{ active: isActiveFilter(filter.key) }"
              @click="setFilters(filter.key)"
            >{{ filter.title }}</a>
          </div>
        </div><!--InputGroup-->

        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary rounded-right tooltip-left"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-sort" /> <small class="sr-only">Sort Messages</small>
          </button>
          <div class="dropdown-menu">
            <h6 class="dropdown-header">
              Sort Messages
            </h6>

            <template v-for="(sort, index) in sorts">
              <a
                :key="'sort_'+index"
                class="dropdown-item text-capitalize"
                :class="{ active: isActiveSort(sort) }"
                @click="setSort(sort)"
              >{{ sort.title }}</a>
            </template>
          </div>
        </div>
      </div>
    </form>

    <template v-if="!processing && !records.length">
      <div class="alert alert-warning">
        No messages found.
      </div>
    </template>

    <div class="messages-feed">
      <template v-for="(record, index) in records">
        <message
          :key="'message_'+index"
          :message="record"
        />
      </template>
    </div>

    <template v-if="records && records.length && total > records.length">
      <div class="row">
        <div class="col-12 text-center">
          <button
            type="button"
            class="btn btn-outline-primary mt-3"
            :disabled="processing"
            @click="loadMore"
          >
            Load more
          </button>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {

    mixins: [ queries ],

    props: ['client_uuid', 'event_uuid', 'payment_uuid'],

    data() {
        return {
            processing: false,
            base_url: '/messages',
            total: 0,
            records: [],
            queries: {},
            options: [],
            sorts: [
              {key: 'updated_at', direction: 'asc', title: 'oldest'},
              {key: 'updated_at', direction: 'desc', title: 'latest'}
            ]
        }
    },

    computed: {

        searchTitle: function() {
            if(this.total >= 0 && !this.processing) {
                return 'Search ' + this.total.toLocaleString() + ' messages';                
            }

            return '';
        }

    },

    mounted() {

      var self = this;

      this.$eventBus.$on('messages', function(data) {

          if(!data.uuid) {
            return;
          }

          self.pushRecord(data);

      });


    },

    created() {

    this.resetQueries();

    this.setURLParams();
        
    this.getOptions();
    this.getRecords();

    this.getErrorCodes();

    var self = this;

    this.$eventBus.$on('messagesRefreshed', function () {
        self.resetRecords();
        self.getRecords();
    });

    },

    methods: {

        resetQueries() {
          this.queries = {
                filters: ['not_scheduled'],
                sort: 'scheduled_at',
                sortDirection: 'desc',
                search: '',
                limit: 20,
                client: null,
                event: null,
                payment: null,
                slim: true,
                total: false,
                status: null,
                fields: 'uuid,content,to,from,client,last_status,direction,scheduled_at,created_at,last_open,channel,test,mms,subject,error_code'
          }
        },

      setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
          self.queries[key] = value;
        });
      },

        setStatusClass(status) {
            if(_.includes(['undelivered', 'failed'], status)) {
                return 'badge badge-danger';
            }
            if(_.includes(['delivered'], status)) {
                return 'badge badge-success';
            }
            if(_.includes(['dispatched'], status)) {
                return 'badge badge-light';
            }
            return 'badge badge-primary';
        }, 

        addMessage(record) {
            this.$http.get(this.base_url + '/' + record.message)
            .then(response => {
                if(response && response.data) {
                    this.records.unshift(response.data);
                }
            })
        },

        resetRecords() {
            this.records = [];
            this.queries.skip = 0;
            this.total = 0;
        },

        updateQueries() {
            if(this.client_uuid) {
                this.queries.client = this.client_uuid;
            }

            if(this.event_uuid) {
              this.queries.event = this.event_uuid;
            }

            if(this.payment_uuid) {
              this.queries.payment = this.payment_uuid;
            }
        },

        getRecords() {

            this.updateQueries();

            this.getTotal();

            this.processing = true;
            this.$http.get(this.buildQueries(this.base_url, this.queries))
            .then(response => {
                if(response && response.status === 200) {

                    this.processing = false;

                    var self = this;

                    if(response.data && response.data.data && response.data.data.length) {
                        response.data.data.forEach(function(data) {
                            self.records.push(data);
                        });
                    }
                }
            })
        },

        getTotal() {
            this.$http.get(this.buildQueries(this.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status === 200) {
                    this.total = response.data;
                }
            })
        },

        loadMore() {
            this.processing = true;
            if(this.records && this.records.length) {
              this.queries.skip = this.records.length;
              this.$http.get(this.buildQueries(this.base_url, this.queries))
                  .then(response => {
                    if(response && response.data && response.data.data && response.data.data.length) {
                      response.data.data.forEach(r => {
                        this.records.push(r);
                      });
                    }
                    this.processing = false;
                  });
            }
        },

        getOptions () {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
          .then(response => {
            if(response && response.data) {
                this.options = response.data;
            }
          })
        },

        searchRecords() {
          this.resetRecords();
          this.getRecords();
        },

        isActiveFilter(filter) {
          if(this.queries.filters.includes(filter)) {
            return true;
          }
        },

        isActiveSort(sort) {
          if(this.queries.sort === sort.key && this.queries.sortDirection === sort.direction) {
            return true;
          }

          return false;
        },

        setFilters(filter) {

          if(this.queries.filters.includes(filter)) {

            this.queries.filters = _.remove(this.queries.filters, function(item) {
              return item != filter;
            })

          } else {
            this.queries.filters.push(filter);
          }

          this.resetRecords();
          this.getRecords();

        },

        setSort(sort) {

          if(sort.key == this.queries.sort) {
            this.queries.sort = null;
          } else {
            this.queries.sort = sort.key;
            this.queries.sortDirection = sort.direction;
          }

          this.resetRecords();
          this.getRecords();
        },

        sortRecords() {

            if(!this.records) {
              return;
            }

            var sort = this.queries.sortDirection || 'asc';

            this.records = _.orderBy(this.records, ['scheduled_at'], [sort]);
        },

        pushRecord(data) {
          
          if(this.client_uuid) {
            if(this.client_uuid != data.client) {
              return;
            }
          }

          if(this.event_uuid) {
            if(this.event_uuid != data.event) {
              return;
            }
          }

          if(this.payment_uuid) {
            if(this.payment_uuid != data.payment) {
              return;
            }
          }

          var diff = this.$moment.utc(data.scheduled_at).diff(this.$moment().utc(), 'seconds');

          if(data.last_status == 'scheduled' || diff >= 30) {
            this.$eventBus.$emit('messageScheduled', data);
            return;
          }

          var index = _.findIndex(this.records, {'uuid': data.uuid});

          if(index != -1) {
            return;
          }

          if(!data.content || !data.content.length) {
            return;
          }

          this.total++;
          this.records.unshift(data);
          this.sortRecords();

        },

      getErrorCodes () {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/error_codes.json')
            .then(response => {
              if (response && response.data) {
                this.$root['error_codes'] = response.data;
              }
            })
      }

    }
}
</script>
