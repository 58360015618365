<template>
  <div>

    <page-tabs page="events" />
    
    <div class="row mb-3">
      <div class="col-12 col-md-6">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle radius-right-0"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="loading"
          >
            <i class="fa fa-landmark-alt" /> <span>Locations</span>
          </button>
          <div class="dropdown-menu">
            <h6 class="dropdown-header">
              Filters Locations
            </h6>
            <div class="locations">
              <a
                v-for="location in locations"
                :key="location.uuid"
                class="dropdown-item link-hover" 
                :class="{ active: isActiveLocation(location.name) }" 
                @click="filterLocations(location.name)"
              >{{ location.name }}</a>
            </div>
          </div>
        </div>
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle radius-left-0"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="loading"
          >
            <i class="fa fa-code-branch" /> <span>Flows</span>
          </button>
          <div class="dropdown-menu">
            <h6 class="dropdown-header">
              Filter Flows
            </h6>
            <a
              v-for="flow in flows"
              :key="flow.uuid"
              class="dropdown-item link-hover"
              :class="{ active: isActiveFlow(flow.name) }" 
              @click="filterFlows(flow.name)"
            >{{ flow.name }}</a>
          </div>
        </div>

        <div class="btn-group">
          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle radius-left-0"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="loading"
          >
            <i class="fa fa-clipboard" /> <span>Event Types</span>
          </button>
          <div class="dropdown-menu">
            <h6 class="dropdown-header">
              Filter Event Types
            </h6>
            <template v-for="(event_type, index) in event_types">
              <a
                :key="'event_types_'+index"
                class="dropdown-item link-hover"
                :class="{ active: isActiveEventType(event_type.type) }"
                @click="filterEventTypes(event_type.type)"
              >{{ event_type.type }}</a>
            </template>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 text-right">
        <button
          id="print-button"
          type="button"
          class="btn btn-outline-danger"
        >
          <i class="fa fa-print" /> Print
        </button>

      </div>
    </div><!--Row-->

    <div class="row">
      <div class="col-12">
        <div id="eventcalendar" style="width:100%; height:600px" />
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>

<script>
import dayGridPlugin from '@fullcalendar/daygrid';
import _ from 'lodash';
import moment from 'moment-timezone';
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data() {
        return {
            event_types: [],
            events_url: [],
            processing: false,
            loading: false,
            base_url: '/events',
            title: 'Calendar | eCourtDate.com',
            preferences: {
                calendar_weekends: false,
                calendar_layout: '',
                calendar_title: [
                    {value: 'type', label: 'Type'},
                    {value: 'client', label: 'Client'},
                    {value: 'flow', label: 'Flow'}
                ]
            },
            events: [],
            flows: [],
            locations: [],
            filter_date: '',
            flows_url: [],
            locations_url: [],
            titles: [
                {value: 'type', label: 'Type'},
                {value: 'flow', label: 'Flow'},
                {value: 'judge', label: 'Judge'},
                {value: 'client', label: 'Client Name'},
                {value: 'client reference', label: 'Client Reference'},
                {value: 'case', label: 'Case #'},
                {value: 'location', label: 'Location'}
            ],
            queries: {},
            calendar: {}
        }
    },

    mounted() {
        this.resetQueries();
        this.initCalendar();
    },

    created () {
        this.getPreferences();
        this.getLocations();
        this.getFlows();
        this.getEventTypes();
    },

    methods: {

        resetQueries() {
            this.queries = {
                limit: 20,
                skip: 0,
                from_date: '',
                to_date: '',
                locations: '',
                flows: '',
                types: '',
                sort: 'date',
                sortDirection: 'asc',
                slim: false
            }
        },

        initCalendar() {

            this.calendar = new this.$calendar(document.getElementById('eventcalendar'), {
              plugins: [dayGridPlugin],
              initialView: 'dayGridMonth',
              timeZone: 'UTC',
              editable: false,
              selectable: true,
              headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth'
              },
              eventClick: function(info) {
                window.open('/events/'+info.event.id, '_blank');
              }
            });

            this.calendar.render();

            this.getEvents();
        },

        // initCalendar() {

        //     var self = this;

        //     this.calendar = mobiscroll.eventcalendar('#eventcalendar', {
        //         theme: 'ios',
        //         themeVariant: 'light',
        //         clickToCreate: false,
        //         dragToCreate: false,
        //         dragToMove: false,
        //         dragToResize: false,
        //         actionableEvents: true,
        //         modules: [ print ],
        //         view: {
        //             calendar: { type: 'month' },
        //             agenda: { type: 'day' }
        //         },
        //         data: [],
        //         onPageLoading: function(event) {
        //             self.setDateRange(moment(), moment(event.lastDay).add(-1, 'days')) 
        //         },
        //         onEventClick: function(click) {
        //             window.open('/events/'+click.event.id, '_blank')
        //         },
        //         onCellClick: function(click) {

        //             self.events = [];
                    
        //             self.queries.from_date = moment(click.date).format('YYYY-MM-DD');

        //             self.queries.skip = 0;

        //             self.getEvents();
        //         }
        //     });

        //     document.getElementById('print-button').addEventListener('click', function () {
        //             self.calendar.print();
        //     });

        //     this.getEvents();
        // },

        setDateRange() {

            var from_date = moment(this.calendar.getDate());

            var to_date = moment(this.calendar.getDate()).add(1, 'months');

            var timezone = moment.tz.guess() || localStorage.getItem('timezone');
            this.queries.from_date = from_date.tz(timezone).format('YYYY-MM-DD');
            this.queries.to_date = to_date.tz(timezone).format('YYYY-MM-DD');
        },

        getPreferences() {
            this.processing = true;
            this.$http.get('users/preferences')
            .then(response => {
                if (response && response.data) {

                    this.preferences = {...this.preferences, ...response.data};
                    this.processing = false;
                }
            })
        },

        getEvents() {

            this.loading = true;

            this.queries['locations'] = this.locations_url;
            this.queries['flows'] = this.flows_url;
            this.queries['types'] = this.events_url;

            this.setDateRange();

            this.$http.get(this.buildQueries(this.base_url, this.queries))
            .then(response => {
                if (response && response.data) {

                    response.data.forEach(event => {

                        var r = this.setEventItem(event);

                        this.events.push(r);

                        this.calendar.addEvent(r);

                    })

                    this.queries.skip += response.data.length;

                    this.loading = false;

            }
        })
        },

        setEventItem(event) {
        
        var time = event.time || '08:00';


         return {
            id: event.uuid,
            start: this.formatDate(event.date + ' ' + time).format('YYYY-MM-DD h:mm'),
            end: this.formatDate(event.date + ' ' + time).add(1, 'hours').format('YYYY-MM-DD h:mm'),
            start_time: time,
            allDay: false,
            title: this.setEventTitle(event)
          }
        },

        setEventTitle(event) {

            var title = '';

            if(event.client.client_reference) {
                title = event.client.client_reference;
            }

            if(event.client.first_name) {
                title = title + ' ' + event.client.first_name;
            }

            if(event.client.last_name) {
                title = title + ' ' + event.client.last_name;
            }

            if(event.description) {
                title = title + ' ' + event.description;
            }

            if(event.type) {
                title = title + ' ' + event.type;
            }

            if(event.status) {
                title = title + ' ' + event.status;
            }

            if(event.event_reference) {
                title = title + ' ' + event.event_reference;
            }

            if(event.case_number) {
                title = title + ' ' + event.case_number;
            }

            return title;
        },

        formatDate(value) {
          if (value && moment(value).isValid()) {

            var timezone = moment.tz.guess() || localStorage.getItem('timezone');

            var date = moment(value).tz(timezone);

            return date.tz(timezone);
          }
        },

        isActiveLocation (name) {
            if (this.locations_url.includes(name)) {
                return true;
            }
        },

        isActiveFlow (name) {
            if (this.flows_url.includes(name)) {
                return true;
            }
        },

        filterLocations (name) {
            if (this.locations_url.includes(name)) {
                this.locations_url = _.remove(this.locations_url, function (item) {
                    return item != name;
                })
            } else {
                this.locations_url.push(name);
            }
            this.resetEvents();

            this.getEvents();
        },

        filterFlows (name) {
            if (this.flows_url.includes(name)) {
                this.flows_url = _.remove(this.flows_url, function (item) {
                    return item != name;
                })
            } else {
                this.flows_url.push(name);
            }
            
            this.resetEvents();

            this.getEvents();
        },

        resetEvents() {
            this.queries.skip = 0;
            this.events = [];
        },

        filterEventTypes(type) {
            if (this.events_url.includes(type)) {
                this.events_url = _.remove(this.events_url, function (item) {
                    return item != type;
                })
            } else {
                this.events_url.push(type);
            }
            
            this.resetEvents();

            this.getEvents();
        },

        isActiveEventType(type) {
            return this.events_url.includes(type);
        },

        getLocations () {
            this.$http.get('/locations?limit=150&slim=y&fields=name,uuid')
            .then(response => {
                if (response && response.data) {
                    this.locations = response.data;
                }
            })
        },

        getFlows () {
            this.$http.get('/flows?limit=150&slim=y&fields=name,uuid')
            .then(response => {
                if (response && response.data) {
                    this.flows = response.data;
                }
            })
        },

        getEventTypes () {
            this.$http.get('/event_types')
            .then(response => {
                if (response && response.data) {
                    this.event_types = response.data;
                }
            })
        }
    }
}
</script>
