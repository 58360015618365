<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row">
      <div class="col-sm-12 col-md-4">
        <template v-if="form.name">
          <h1>{{ form.name }}</h1>
        </template>
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-2">

        <template v-if="portalLink">
          <a :href="portalLink" class="btn btn-outline-primary mr-3" target="_blank" :disabled="processing"><i class="fa fa-link"></i> Public Link</a>
        </template>

        <template v-if="qr_code">
          <a :href="portalLink" target="_blank">
          <img
            :src="'data:image/svg+xml;base64,'+qr_code"
            width="50"
          >
          </a>
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-2">
        <template v-if="total_submissions">
          <router-link :to="{'name': 'forms.submissions', 'params': { 'id': $route.params.id } }" tag="button" class="btn btn-outline-primary">
            <i class="fa fa-file-spreadsheet"></i> View {{ total_submissions }} Submissions
          </router-link>
        </template>
      </div>


      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">
                <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
                >Archive</a>
                <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>
                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-4">
          <div class="card">
            <div class="card-body">

              <p class="card-title">Edit {{ $route.meta.title }}</p>

              <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name" class="form-label">Name</label>
                <input id="name" type="text" class="form-control" v-model="form.name" required>
              </div>

              <div class="form-group">
                <label for="description" class="form-label">Description</label>
                <textarea id="description" class="form-control" v-model="form.description" rows="3"></textarea>
              </div>

              <div class="form-group">
                <label for="language">Default Language</label>
                <select
                  id="language"
                  v-model="form.language"
                  class="form-control"
                  :disabled="processing"
                  required
                >
                  <option value="">
                    Choose Language
                  </option>
                  <option value="en">English</option>

                  <template v-for="(language, language_index) in languages">

                    <option
                      :key="'language_'+language_index"
                      :value="language.key"
                      :hidden="language.key == 'en'"
                    >
                      {{ language.label }}
                    </option>

                  </template>

                </select>
              </div>

              <div class="form-group">
                <label for="reference" class="form-label">Reference</label>
                <input id="reference" type="text" class="form-control" v-model="form.reference" required>
              </div>

              <div class="form-group">
                <label for="portal">Portal

                <template v-if="form.portal">
                  <a
                  :href="'/portals/'+form.portal"
                  target="_blank"
                  data-tooltip="Edit Portal">
                  <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                  </a>
                </template>
                
                </label>
                <select
                  id="portal"
                  v-model="form.portal"
                  class="form-control"
                  :disabled="processing"
                  required
                >
                  <option value="">
                    Choose Portal
                  </option>
                  <template v-for="portal in portals">
                    <option
                      :key="'portal_'+portal.uuid"
                      :value="portal.uuid"
                    >
                      {{ portal.name }}
                    </option>
                  </template>
                </select>
              </div>

                <div class="form-group">
                  <label for="amount">Payment Amount</label>
                  <input id="amount" type="number" class="form-control" step="0.01" v-model="form.amount">
                  <p class="text-muted small">Require a payment after the form is submitted.</p>
                </div>

              <div class="form-group">
                <label for="status">Status</label>
                <select id="status" class="form-control" v-model="form.status" :disabled="processing" required>
                  <template v-for="(status, status_index) in statuses">
                    <option :key="'post_status_'+status_index" :value="status">{{ status }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="redirect" class="form-label">Redirect</label>
                <input id="redirect" type="text" class="form-control" v-model="form.redirect">
              </div>

              <div class="form-group">
                <label for="expires_at" class="form-label">Expires On</label>
                <input id="expires_at" type="datetime-local" class="form-control" v-model="form.expires_at">
              </div>


              <h5>Submission Settings</h5>

              <div class="form-group">
                <label for="submissions_notify" class="form-label">Notify on Submission</label>
                <input id="submissions_notify" type="text" class="form-control" v-model="form.submissions['notify']">
                <small class="text-muted">Comma-separated list of emails</small>
              </div>
              
              <div class="form-group">
                <label for="submissions_auto_message" class="form-label">Auto Message
                  <template v-if="form.submissions['auto_message']">
                    <a
                        :href="'/auto_messages/'+form.submissions['auto_message']"
                        target="_blank"
                        data-tooltip="Edit Auto Message">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Auto Message</span>
                    </a>
                  </template>
                </label>
                <select id="submissions_auto_message" class="form-control" v-model="form.submissions['auto_message']" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(auto_message, index) in auto_messages">
                    <option :key="'auto_message_'+index" :value="auto_message.uuid">{{ auto_message.name }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="submissions_redirect" class="form-label">Redirect on Submission</label>
                <input id="submissions_redirect" type="text" class="form-control" v-model="form.submissions['redirect']">
              </div>

              <div class="form-group">
                <label for="submissions_label" class="form-label">Submit Button Label</label>
                <input id="submissions_label" type="text" class="form-control" v-model="form.submissions['submit_label']">
              </div>

              <div class="form-group">
                <label for="submissions_confirm_alert" class="form-label">Submit Confirm Alert</label>
                <input id="submissions_confirm_alert" type="text" class="form-control" v-model="form.submissions['confirm_alert']">
              </div>

              <div class="form-group">
                <label for="submissions_confirm_description" class="form-label">Submit Confirm Description</label>
                <input id="submissions_confirm_description" type="text" class="form-control" v-model="form.submissions['confirm_description']">
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

              </form>

              <ul class="list-group">

                <template v-if="form.client && form.client_uuid">
                  <li class="list-group-item">
                    Client <a :href="'/clients/'+form.client_uuid">{{ form.client.client_reference }} {{ form.client.last_name }} {{ form.client.first_name }}</a>
                  </li>
                </template>

                <template v-if="form.flow_name">
                  <li class="list-group-item">
                    Flow <a :href="'/flows/'+form.flow">{{ form.flow_name }}</a>
                  </li>
                </template>



                <template v-if="form.opened_at">
                  <li class="list-group-item">
                    Opened At {{ form.opened_at | datetime }}
                  </li>
                </template>

              </ul>

            </div><!--Body-->
          </div><!--Card-->

          <audit-logs :form="form" class="mt-3" />

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <div class="card">
          <div class="card-body">

            <p class="card-title">Add Field</p>

              <template v-for="(field_type, field_type_index) in field_types">

                  <button
                    :key="'field_type_'+field_type_index"
                    type="button"
                    class="btn btn-outline-success btn-sm mr-2 mb-2"
                    @click="postField(field_type)"
                    :disabled="processing"
                  >
                    {{ field_type | rmDashes }}
                  </button>
              </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form && form.fields">

        <div class="card mt-3">
          <div class="card-body" :dir="setLangDirection(form)">

            <p class="card-title">Form Preview</p>

              <draggable
                v-model="form.fields"
                group="fields"
                @start="drag=true"
                @end="drag=false"
              >

              <template v-for="(form_field, field_index) in form.fields">

                <div :key="'form_field_'+field_index">

                    <div class="form-group">
                      <label>Field Title</label>
                      <input :id="'field_'+field_index" type="text" class="form-control" v-model="form.fields[field_index]['title']" @keyup.enter="postForm()">
                    </div>

                  <div class="form-group">
                    <label>Field Type</label>
                    <select :id="'field_type_'+field_index" class="form-control" v-model="form.fields[field_index]['type']" @change="postForm()">
                      <template v-for="(field_type, field_type_index) in field_types">
                        <option :key="'field_type_'+field_type_index" :value="field_type">{{ field_type | rmDashes }}</option>
                      </template>
                    </select>
                  </div>

                    <div class="form-group">
                      <label>Help Text</label>
                      <input type="text" class="form-control" placeholder="Help Text" v-model="form.fields[field_index]['help_text']" @keyup.enter="postForm()">
                    </div>

                      <div class="form-group custom-control custom-switch">
                        <input
                          :id="'field_required_'+field_index"
                          v-model="form.fields[field_index]['required']"
                          type="checkbox"
                          class="custom-control-input"
                          :disabled="processing"
                        >
                        <label
                          class="custom-control-label"
                          :for="'field_required_'+field_index"
                        > Required Field</label>
                      </div>

                      <template v-if="form_field.type == 'checkbox'">

                        <div class="form-group">
                          <label>Checkbox Choices</label>
                          <input type="text" class="form-control" v-model="form.fields[field_index]['options']" @keyup.enter="postForm()" required>
                          <small class="form-text text-muted">Comma-separated list of choices. Checkboxes allow multiple choices.</small>
                        </div>

                      </template>


                      <template v-if="form_field.type == 'radio'">

                        <div class="form-group">
                          <label>Radio Choices</label>
                          <input type="text" class="form-control" v-model="form.fields[field_index]['options']" @keyup.enter="postForm()" required>
                          <small class="form-text text-muted">Comma-separated list of choices. Radios allow a single choice.</small>
                        </div>

                      </template>

                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-secondary mt-3" :disabled="processing"><i class="fa fa-bars"></i> Sort</button>
                    <button type="button" class="btn btn-outline-danger mt-3" @click="deleteFormField(field_index)" :disabled="processing"><i class="fa fa-trash"></i> Delete</button>
                  </div>

                <hr>

                </div>

              </template>

              </draggable>


              <template v-if="form.submissions['submit_label']">

                <button type="button" class="btn btn-success" @click="postForm()" :disabled="processing">{{ form.submissions['submit_label'] }}</button>

                <br>

              </template>


          <template v-if="form.fields && form.fields.length">
            <button type="button" class="btn btn-outline-danger btn-sm mt-3" @click="deleteFormFields()" :disabled="processing"><i class="fa fa-trash"></i> Reset Form</button>
          </template>

          <template v-if="!processing && form && !form.fields.length">

            <div class="alert alert-warning" dir="ltr">Add a field above.</div>

          </template>

          </div><!--Body-->
        </div><!--Card-->

        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <template v-if="!messages || !messages.length">
          <template v-if="!processing">
            <div class="col-12 mt-2">
              <div class="alert alert-danger">
                No messages found
              </div>
            </div>
          </template>
        </template>
        <template v-if="messages && messages.length">
          <p class="card-title m-0 mt-3 mb-1">
            {{ messages.length }} messages
          </p>
          <template v-for="message in messages">
            <message
              :key="message.uuid"
              :message="message"
            />
          </template>
        </template><!--Messages-->
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import draggable from 'vuedraggable';

export default {

components: { draggable },

data () {
    return {
        processing: false,
        loading: false,
        form: {},
        messages: [],
        portal: {},
        portals: [],
        statuses: ['draft', 'private', 'public'],
        field_types: ['short_text', 'long_text', 'date', 'time', 'phone', 'email', 'checkbox', 'radio', 'file'],
        fieldsForm: {},
        languages: [],
        auto_messages: [],
        qr_code: null,
        total_submissions: 0
    }
},

computed: {

  portalLink: function() {

    if(!this.portal || !this.portal.link) {
      return;
    }

    if(!this.form || !this.form.url) {
      return;
    }

    if(this.form.status != 'public') {
      return;
    }

    var language = this.form.language || 'en';

    return 'https://'+this.portal.link+'.'+this.portal.domain+'/w/'+this.form.url+'?lang='+language;
  }
},

created () {
    this.resetForm();
    this.getRecord();
    this.getLanguages();
    this.getPortals();
    this.getAutoMessages();
},

methods: {


    resetForm() {
      this.form = {
        name: null,
        description: null,
        reference: null,
        client: null,
        status: null,
        portal: null,
        flow: null,
        expires_at: null,
        amount: null,
        language: 'en',
        submissions: {
          'notify': null,
          'redirect': null,
          'submit_label': null,
          'confirm_alert': null,
          'confirm_description': null,
          'auto_message': null,
        },
        fields: []
      }
    },

    postForm() {
      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
            this.getPortal();
          }

        }
      })
    },


    getQRCode() {
      this.$http.post('/qrs?content='+this.portalLink)
      .then(response => {
          if(response && response.data) {
              this.qr_code = response.data;
          }
      })
    },

    getLanguages () {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
        .then(response => {
            if(response && response.data) {
                this.languages = response.data;
            }
        })
    },

    setLangDirection(form) {

      if(!form.language) {
        return 'ltr';
      }

      if(this.$root.rtls.includes(form.language)) {
        return 'rtl';
      }

      return 'ltr';
    },

    setFieldType(field) {
      if(field.type == 'phone') {
        return 'tel';
      }

      if(field.type == 'short_text') {
        return 'text';
      }

      return field.type;
    },

    deleteFormField(index) {
     this.form.fields.splice(index, 1);
     new this.$noty({text: 'Save to apply changes.'}).show();
    },

    deleteFormFields() {
      this.form.fields = [];
      new this.$noty({text: 'Save to apply changes.'}).show();
    },

    postField(field) {

      if(!this.form.fields) {
        this.form.fields = [];
      }

      this.form.fields.unshift({type: field, title: 'New Field'});
    },

    getRecord() {
        this.processing = true
        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                this.form = response.data;

                if(!this.form.submissions) {
                  this.form.submissions = {
                    'notify': null,
                    'redirect': null,
                    'submit_label': null
                  }
                }

                this.setTitle();

                this.processing = false;
                this.getMessages();
                this.getPortal();

                this.getTotalSubmissions();
            }
        })
    },

    getTotalSubmissions() {
      this.$http.get('/submissions/total?form='+this.form.uuid+'&limit=1000&sort=created_at&sortDirection=desc')
      .then(response => {
        if(response && response.status) {
          this.total_submissions = response.data || 0;
        }
      })
    },

    setTitle() {
      document.title = this.form.name + ' | eCourtDate.com';
    },
        
      restoreRecord () {
          this.processing = true;
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                      this.form.deleted_at = null;
                      this.form.archived_at = null;
                  }
                  
              }
          })
      },

      archiveRecord () {
          this.processing = true
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                      this.form.archived_at = new Date().toISOString();
                  }

              }
              
          })
      },

      trashRecord () {
          this.processing = true;
          this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
      },

    getMessages() {
        this.processing = true;
        this.$http.get('/messages?form='+this.form.uuid)
        .then(response => {
            if(response && response.data && response.data.data) {
                this.messages = response.data.data;
                this.processing = false;
            }
        })
    },

    getPortals () {
        this.$http.get('/portals?slim=true&limit=1000&sort=name&sortDirection=asc&fields=uuid,name,link&domain=ecase.io')
        .then(response => {
            if (response && response.data) {
                this.portals = response.data;
            }
        })
    },

    getPortal() {

      if(!this.form.portal) {
        return;
      }

      if(this.form.portal == this.portal.uuid) {
        return;
      }

      this.$http.get('/portals/'+this.form.portal+'?slim=true&fields=domain,link')
      .then(response => {
        if(response && response.data) {
          this.portal = response.data;
          this.getQRCode();
        }
      })
    },

  getAutoMessages() {
    this.$http.get('/auto_messages?limit=1000&slim=true&fields=uuid,name&sort=name&sortDirection=asc')
        .then(response => {
          if(response && response.data) {
            this.auto_messages = response.data;
          }
        })
  },
}
}
</script>