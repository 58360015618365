<template>
  <div>

    <page-tabs page="clients" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <template v-if="client && client.uuid">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-6">
                  <p class="card-title">
                    Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                      {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}
                    </router-link>
                  </p>
                </div><!--Col-->

                <div class="col-md-6 text-right">
                  <button
                    type="submit"
                    class="btn btn-danger btn-sm"
                    :disabled="processing"
                    @click="closeClient()"
                  >
                    <i class="fa fa-times" /> <span class="sr-only">Close</span>
                  </button>
                </div><!--Col-->
              </div><!--Row-->

              <ul class="list-group">
                <template v-if="client.client_reference">
                  <li class="list-group-item">
                    Reference {{ client.client_reference }}
                  </li>
                </template>

                <template v-if="client.type">
                  <li class="list-group-item">
                    Type {{ client.type }}
                  </li>
                </template>

                <template v-if="client.group">
                  <li class="list-group-item">
                    Group {{ client.group }}
                  </li>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="client && !client.uuid">
          <client-search />
        </template>

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">
 
              <div class="form-group mt-3">
                <label for="address_1">Address *</label>
                <input
                  id="address_1"
                  v-model="form.address_1"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="address_2">Address 2</label>
                <input
                  id="address_2"
                  v-model="form.address_2"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="city">City</label>
                <input
                  id="city"
                  v-model="form.city"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="state">State</label>
                <select
                  id="state"
                  v-model="form.state"
                  class="form-control"
                  :disabled="processing"
                >
                  <template v-if="states && states.length > 0">
                    <template v-for="(state, index) in states">
                      <option
                        :key="'state_'+index"
                        :value="state.key"
                      >
                        {{ state.label }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="postal_code">Zip</label>
                <input
                  id="postal_code"
                  v-model="form.postal_code"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="address_type">Type</label>
                <select
                    id="address_type"
                    v-model="form.type"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(address_type, index) in address_types">
                    <option
                        :key="'address_type_'+index"
                        :value="address_type"
                    >
                      {{ address_type }}
                    </option>
                  </template>
                </select>
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->            


        <div class="card mt-3">
          <div class="card-body">
            <form @submit.prevent="searchRecords()">

              <p class="card-title">Search {{ $route.meta.title }}s</p>

              <div class="form-group">
                <label for="search">Street Address</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_city">City</label>
                <input
                  id="search_city"
                  v-model="queries.city"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_state">State</label>

                  <select
                    id="search_state"
                    v-model="queries.state"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value=""></option>
                    <template v-if="states && states.length > 0">
                      <template v-for="(state, index) in states">
                        <option
                          :key="'search_state_'+index"
                          :value="state.key"
                        >
                          {{ state.label }}
                        </option>
                      </template>
                    </template>
                  </select>

                <div class="form-group mt-3">
                  <label for="search_postal_code">Zip</label>
                  <input
                      id="search_postal_code"
                      v-model="queries.postal_code"
                      type="text"
                      class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="search_type">Type</label>
                  <select
                      id="search_type"
                      v-model="queries.type"
                      class="form-control"
                      :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="(address_type, index) in address_types">
                      <option
                          :key="'search_type_'+index"
                          :value="address_type"
                      >
                        {{ address_type }}
                      </option>
                    </template>
                  </select>
                </div>

              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">


        <div
          id="map"
          class="map-xl mb-3"
        />

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}es
            </p>

            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <sort-columns
                  :columns="columns"
                  :queries="queries"
                  :processing="processing"
                  :base_url="$route.meta.base_url"
                />

                <tbody>
                  <template v-for="record in records">
                    <tr :key="record.uuid">
                      <td>
                        <router-link :to="{ name: $route.meta.base_url+'.edit', params: {id: record.uuid}}">
                          <i class="fa fa-edit"></i> {{ record.address_1 }}
                        </router-link>
                      </td>
                      <td>
                        <template v-if="record.client">
                          <client-button :uuid="record.client" />
                        </template>
                      </td>
                      <td>{{ record.address_2 }}</td>
                      <td>{{ record.city }}</td>
                      <td>{{ record.state }}</td>
                      <td>{{ record.postal_code }}</td>
                      <td>{{ record.type }}</td>
                      <td>{{ record.created_at | datetime }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>

              <template v-if="!processing && records && !records.length">
                <div class="alert alert-warning">
                  No addresses found.
                </div>
              </template>
            </div>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {
    mixins: [ queries ],
    data () {
        return {
            processing: false,
            form: {
                client: null
            },
            total: 0,
            records: [],
            clients: [],
            client: {},
            states: [],
            search_client: {},
            address: {},
            queries: {
                city: '',
                search: '',
                client: null,
                limit: 10,
                skip: 0,
                sort: 'created_at',
                sortDirection: 'desc'
            },
            address_types: ['home', 'work', 'mail'],
            columns: [
                { key: 'address_1', label: 'Address 1'},
                { key: 'client', label: 'Client'},
                { key: 'address_2', label: 'Address 2'},
                { key: 'city', label: 'City'},
                { key: 'state', label: 'State'},
                { key: 'postal_code', label: 'Zip'},
                { key: 'type', label: 'Type'},
                { key: 'created_at', label: 'Created'}
            ],
            map: {},
            markers: []
        }
    },

    mounted() {

    this.initMap();

    var self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

        if(view !== self.$route.meta.base_url) {
            return;
        }

        self.resetRecords();
        self.getRecords();

    });

    this.$eventBus.$on('search_clients', function(uuid) {

       self.queries.client = uuid;

       self.searchRecords();

    });

    },

    created () {
        this.resetRecords();
        this.resetQueries();
        this.resetForm();
        this.setURLParams();
        this.getRecords();
        this.getStates();
    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });

        },

      resetQueries() {
        this.queries = {
          sortDirection: 'desc',
          sort: 'created_at',
          skip: 0,
          limit: 10,
          client: null,
          archived: false,
          trashed: false,
          search: null,
          type: null,
          city: null,
          state: null,
          postal_code: null
        }
      },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        resetForm() {
          this.form = {
            client: null,
            address: null,
            address_2: null,
            city: null,
            state: null,
            postal_code: null,
            type: null
          }
        },

        resetClient() {
          this.form.client = null;
          this.queries.client = null;
          this.client = {};
        },

        resetRecords() {
            this.records = [];
            this.markers = [];
            this.total = 0;
            this.queries.skip = 0;
        },

        getRecords() {

            this.processing = true;

            this.updateURL();
            this.getTotal();

            this.getClient();

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.data) {

                    this.markers = window.L.markerClusterGroup({
                        spiderfyOnMaxZoom: true,
                        showCoverageOnHover: true,
                        zoomToBoundsOnClick: true
                    });

                    if(response.data.length > 0) {

                        var self = this;

                        response.data.forEach(r => {
                            self.records.push(r);
                            self.setMarker(r);
                        });
                    }

                    this.queries.skip = this.records.length;

                    this.processing = false;

                    this.map.addLayer(this.markers);
                }
            })
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url + '/total', this.queries))
            .then(response => {
                if(response && response.status) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 'es | eCourtDate.com';
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        getStates() {
            this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/states.json')
            .then(response => {
                if(response && response.data) {
                    this.states = response.data;
                }
            })
        },

        postForm() {

            if(this.client && this.client.uuid) {
                this.form.client = this.client.uuid;
            }

            this.processing = true;

            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if(response && response.data) {

                    if(response.data.uuid) {
                        this.records.unshift(response.data);
                        this.setMarker(response.data);
                        this.total++;
                        this.resetForm();
                        new this.$noty({text: 'Address created'}).show();
                    }

                    this.processing = false;
                }
            })
        },

        getClient() {

            if(!this.queries.client) {
                this.client = {};
                return;
            }

            this.$http.get('/clients/'+this.queries.client+'?slim=true&fields=uuid,client_reference,first_name,last_name,group,type')
            .then(response => {
                if(response && response.data) {
                    this.client = response.data;

                    if(!this.form.name && this.client.uuid) {
                        var first_name = this.client.first_name || '';
                        var last_name = this.client.last_name || '';

                        this.form.name = first_name + ' ' + last_name;
                    }
                }
            })
        },

        closeClient() {
          this.resetClient();
          this.searchRecords();
        },

      initMap() {

      const light = window.L.tileLayer(this.$root.map_url+'alidade_smooth/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
      });

      const dark = window.L.tileLayer(this.$root.map_url+'alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
      });

      const bright = window.L.tileLayer(this.$root.map_url+'osm_bright/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
      });

      const outdoors = window.L.tileLayer(this.$root.map_url+'outdoors/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
      });

      const baseLayers = {
        'Light': light,
        'Dark': dark,
        'Bright': bright,
        'Outdoors': outdoors,
      };

      this.map = window.L.map("map", {layers: light}).setView(this.$root.coordinates, 4);

      window.L.control.layers(baseLayers).addTo(this.map);

    },

        setMarker(record) {

        if(!record.latitude || !record.longitude) {
            return;
        }

        var popup = '<h6 class="m-0">Address <a href='+'/'+this.$route.meta.base_url+'/'+record.uuid+'>'+record.address_1+'</a></h6>';

          var icons = {
            "home": "house",
            "work": "building",
            "mail": "mailbox"
          }

          const icon = icons[record.type] || 'map-marker';

          const markerIcon = window.L.divIcon({
            html: '<i class="fa text-primary fa-2x fa-'+icon+'"></i>',
            iconSize: [20, 20],
            className: 'marker-icon'
          });

        var marker = window.L.marker([record.latitude, record.longitude], {icon: markerIcon}).bindPopup(popup);

        this.markers.addLayer(marker);

    }

    }
}
</script>
