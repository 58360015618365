<template>
  <div>
    <template v-if="record && record.uuid">
      <div class="card">
        <div class="card-body">
          <p class="card-title">
            <router-link :to="{'name': 'locations.edit', 'params': {'id': record.uuid }}">
              Edit Location
            </router-link>
          </p>

          <ul class="list-group">
            <template v-if="record.name">
              <li class="list-group-item">
                Name {{ record.name }}
              </li>
            </template>

            <template v-if="record.address">
              <li class="list-group-item">
                Address {{ record.address }}
              </li>
            </template>

            <template v-if="record.address_2">
              <li class="list-group-item">
                Address 2 {{ record.address_2 }}
              </li>
            </template>

            <template v-if="record.description">
              <li class="list-group-item">
                Description {{ record.description }}
              </li>
            </template>
          </ul>

          <template v-if="record.lat && record.lng">
            <location-maps :record="record" />
          </template>

        </div><!--Body-->
      </div><!--Card-->
    </template>
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

props: ['uuid'],

data() {
    return {
        record: {},
        base_url: '/locations',
        queries: {
            slim: true,
            fields: 'uuid,name,address,address_2,description,lat,lng'
        }
    }
},

created() {
    this.getRecord();
},

methods: {

    getRecord() {

        if(!this.uuid) {
            return;
        }

        this.$http.get(this.buildQueries(this.base_url+'/'+this.uuid, this.queries))
        .then(response => {
            if(response && response.data) {
                this.record = response.data;
            }
        })
    }

}

}
</script>