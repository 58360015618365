<template>
  <div>
    <ul class="nav flex-column nav-pills mb-3">
      <li
        v-for="tab in tabs"
        :key="tab.title"
        class="nav-item text-capitalize"
        :class="{ 'd-none': isHidden('clients_'+tab.title) }"
      >
        <template v-if="tab.type == 'tab'">
          <a
            class="nav-link"
            href="#"
            :class="{ active: isActive(tab.title) }"
            @click="changeTab(tab.title)"
          >
            {{ tab.title.replace("_", " ", tab.title) }}
          </a>
        </template>

        <template v-if="tab.type == 'link'">
          <a
            class="nav-link"
            :href="'/'+tab.title+'?client='+uuid"
          >{{ tab.title.replace("_", " ", tab.title) }}</a>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import tabs from '../mixins/tabs';

export default {
  
  mixins: [ tabs ],

  props: ['uuid'],

  data () {
    return {
      active_tab: null,
      tabs: [
        {title: 'overview', type: 'tab'},
        {title: 'events', type: 'link'},
        {title: 'cases', type: 'link'},
        {title: 'payments', type: 'link'},
        {title: 'sent_files', type: 'link'},
        {title: 'addresses', type: 'link'},
        {title: 'messages', type: 'link'},
        {title: 'opens', type: 'link'},
        {title: 'comments', type: 'link'},
        {title: 'identities', type: 'link'}
      ]
    }
  },
  mounted () {
    if (this.$route.query.tab && this.tabs.find(item => item.title === this.$route.query.tab)) {
        this.active_tab = this.$route.query.tab
        this.$eventBus.$emit('tabChange', this.$route.query.tab);
    }
    if (!this.active_tab && this.tabs && this.tabs.length) {
      this.active_tab = this.tabs[0].title;
    }
    if (this.active_tab && this.active_tab.length) {
      this.$eventBus.$emit("tabChange", this.active_tab);
    }
  },

  methods: {

    isHidden(navigation) {
      
        if(!this.$root.current_user || !this.$root.current_user.uuid) {
            return true;
        }

        if(this.$root.current_user.hidden_navigations && this.$root.current_user.hidden_navigations.length > 0) {
            return this.$root.current_user.hidden_navigations.includes(navigation);
        }

        return false;
    },

    isActive (tab) {
      if (tab == this.active_tab) {
        return true;
      }
    },

    changeTab (tab) {
      this.$router.push({ query: Object.assign({}, this.$route.query, { tab: tab }) });
      localStorage.setItem(this.view + '-tab', tab);
      this.$eventBus.$emit('tabChange', tab);
      this.active_tab = tab;
    }
  }
}
</script>
