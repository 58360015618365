<template>
  <div>
    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-md-4">
        <h1>{{ form.direction }}</h1>
        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-md-4">

      </div><!--Col-->

      <div class="col-md-4 text-right">

        <div class="btn-group">

          <div class="btn-group">
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">
              <a
                  v-if="form.deleted_at || form.archived_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
              >Restore</a>
              <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
              >Trash</a>
            </div>
          </div>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>
        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div id="main" class="row">
      <div class="col-sm-12 col-md-4">

        <div class="card">
          <div class="card-body">

            <template v-if="form.direction == 'inbound'">
              <p class="card-title">Reply to {{ $route.meta.title }}</p>

              <form @submit.prevent="postReply()">

                <div class="form-group">
                  <label for="replyContent" class="form-label">Content</label>
                  <textarea
                      id="replyContent"
                      class="form-control"
                      v-model="replyForm.content"
                      rows="3"
                      required
                  ></textarea>
                </div>

                <button type="submit" class="btn btn-success" :disabled="processing">Send</button>

              </form>

            </template>

            <template v-if="form.direction == 'outbound'">

            <p class="card-title">Edit {{ $route.meta.title }}</p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="content" class="form-label">Content</label>
                <textarea
                    id="content"
                    class="form-control"
                    v-model="form.content"
                    rows="3"
                    :readonly="form.direction == 'inbound'"
                    required
                ></textarea>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing || form.deleted_at || form.archived_at">Save</button>

            </form>

            </template>

          </div>
        </div>

        <template v-if="form.uuid">
          <audit-logs
              class="mt-3"
              :form="form"
          />
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">
        <template v-if="form && form.client">
          <client-card class="mb-3" :client="form.client" />
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">
        <template v-if="form.content">
          <p :class="form.direction">{{ form.content }}</p>
        </template>
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import moment from 'moment-timezone';

export default {

  data() {
    return {
      processing: false,
      downloading: false,
      form: {},
      client: {},
      replyForm: {
        content: null
      }
    }
  },

  created() {
    this.resetForm();
    this.resetReplyForm();
    this.getRecord();
  },

  methods: {

    resetForm() {
      this.form = {
        content: null,
        direction: null,
        client: null
      }
    },

    resetReplyForm() {
      this.replyForm = {
        content: null,
        direction: 'outbound',
        client: null
      }
    },

    getRecord() {

      this.processing = true;

      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id+'?slim=true')
          .then(response => {
            if(response && response.data) {

              this.form = response.data;

              this.processing = false;
            }
          })
    },

    postReply() {

      this.processing = true;

      if(this.form.client) {
        this.replyForm.client = this.form.client;
      }

      this.$http.post('/'+this.$route.meta.base_url, this.replyForm)
          .then(response => {
            if(response && response.data) {
              this.resetReplyForm();
              this.processing = false;
            }
          });

    },

    postForm() {

      if(this.form.deleted_at) {
        return;
      }

      this.processing = true;
      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.data.updated_at) {
                new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                this.form.updated_at = response.data.updated_at;
              }
            }
          })
    },

    trashRecord() {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
          .then(response => {
            if(response && response.status === 204) {
              new this.$noty({text: this.$route.meta.title + ' trashed', type: 'error'}).show();
              this.form.deleted_at = new Date().toISOString();
            }
          })
    },

    restoreRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
              }

            }
          })
    },

    async downloadPDF() {
      try {

        window.scrollTo(0,0);

        this.downloading = true;

        const element = document.getElementById('main');

        const canvas = await this.$html2canvas(element, {
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          scale: window.devicePixelRatio,
          useCORS: true,
          logging: false
        });

        const imgData = canvas.toDataURL('image/png');

        const pdf = new this.$jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.form.uuid+'-'+this.$root.current_user.current_agency.name);

        pdf.save(name+'.pdf');

        this.downloading = false;
      } catch (error) {
        new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
        console.log(error);
        this.downloading = false;
      }
    },

  }

}
</script>