<template>
  <div>
    <template v-if="tabs && tabs.length">
      <ul class="nav flex-column nav-pills mb-3">
        <li
          v-for="tab in tabs"
          :key="tab.key"
          class="nav-item text-capitalize"
          :class="{ 'd-none': isHidden('clients_'+tab.title) }"
        >
          <a
            class="nav-link"
            href="#"
            :class="{ active: isActive(tab.title) }"
            @click="changeTab(tab.title)"
          >
            {{ tab.title }}</a>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import tabs from '../mixins/tabs';

export default {
  mixins: [ tabs ],
  props: ['view'],
  data () {
    return {
      active_tab: null,
      tabsData: []
    }
  },
  computed: {
    tabs: function () {
      if (this.view) {
        return this.tabsData[this.view]
      }
      return null
    }
  },
  async mounted () {
    await this.getTabs()
    if (this.$route.query.tab && this.tabs.find(item => item.title === this.$route.query.tab)) {
      this.active_tab = this.$route.query.tab
      this.$eventBus.$emit('tabChange', this.$route.query.tab)
    } else if (localStorage.getItem(this.view + '-tab')) {
      this.$router.push({ query: Object.assign({}, this.$route.query, { tab: localStorage.getItem(this.view + '-tab') }) }).catch(err => { console.log(err) })
      this.active_tab = localStorage.getItem(this.view + '-tab')
    }
    if (!this.active_tab && this.tabs && this.tabs.length) {
      this.active_tab = this.tabs[0].title
    }
    if (this.active_tab && this.active_tab.length) {
      this.$eventBus.$emit("tabChange", this.active_tab)
    }
  },
  created () {
    if (localStorage.getItem(this.view + '-tab')) {
      this.active_tab = localStorage.getItem(this.view + '-tab')
      this.$eventBus.$emit('tabChange', self.active_tab)
    }
  },
  methods: {
    async getTabs () {
      await this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/tabs.json')
      .then(response => {
        if (response && response.data) {
          this.tabsData = response.data
        }
      })
    },

    isHidden(navigation) {
      
        if(!this.$root.current_user || !this.$root.current_user.uuid) {
            return true;
        }

        if(this.$root.current_user.hidden_navigations && this.$root.current_user.hidden_navigations.length > 0) {
            return this.$root.current_user.hidden_navigations.includes(navigation);
        }

        return false;
    },

    isActive (tab) {
      if (tab == this.active_tab) {
        return true
      }
    },
    changeTab (tab) {

      const query = Object.assign({}, this.$route.query);

      if(query.uuid) {
        delete query.uuid;
      }

      query.tab = tab
      
      this.$router.replace({ query });

      localStorage.setItem(this.view + '-tab', tab)
      this.$eventBus.$emit('tabChange', tab)
      this.active_tab = tab
    }
  }
}
</script>
