<template>
  <div>
    
    <onboarding-alert />

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <page-tabs page="messages" />
      </div><!--Col-->

      <div class="col-sm-12 col-md-6">
        <template v-if="$root['deliveryRate']">
        <span class="badge badge-pill badge-success float-right">{{ $root['deliveryRate'] }}% Delivery Rate</span>
        </template>
      </div><!--Col-->

    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-6 col-xl-3">
          <messages-form />
          <messages-scheduled class="mb-3" />
      </div><!--Col-->
      <div class="col-sm-12 col-md-6 col-xl-9">
          <messages-feed />
      </div><!--Col-->
    </div><!--Row-->



  </div>
</template>
<script>
import queries from '../mixins/queries';
export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      queries: {}
    }
  },

  created() {
    this.resetQueries();
    this.setURLParams();
    this.setTitle();
    this.getDeliveryRate();
  },

  methods: {

    resetQueries() {
      this.queries = {
        channel: null,
        from: null,
        direction: 'outbound',
        sent: 1,
      }
    },

    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    setTitle() {
      document.title = this.$route.meta.title + ' | eCourtDate.com';
    },

    getDeliveryRate() {

      if(!this.$root['deliveryRate']) {
        this.$root['deliveryRate'] = 0;
      }

      this.countDelivered();

    },

    countDelivered() {

      var queries = {
        'direction': 'outbound',
        'sent': 1,
        'last_status': 'delivered',
        'filters': 'not_scheduled'
      }

      this.$http.get(this.buildQueries('/messages/total',queries))
          .then(response => {
            if(response && response.status) {
              this.$root['totalDelivered'] = response.data;
              this.countTotal();
            }
          })
    },

    countTotal() {

      var queries = {
        'direction': 'outbound',
        'sent': 1,
        'filters': 'not_scheduled'
      }

      this.$http.get(this.buildQueries('/messages/total', queries))
          .then(response => {
            if(response && response.status) {
              this.$root['totalSent'] = response.data;

              if(this.$root['totalSent'] > 0) {
                this.$root['deliveryRate'] = Math.round((this.$root['totalDelivered'] / this.$root['totalSent']) * 100);
              }
            }
          })
    }
  }
}
</script>