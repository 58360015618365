import Vue from 'vue';

import { datadogRum } from '@datadog/browser-rum';
Object.defineProperty(Vue.prototype, '$datadog', { value: datadogRum });

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

if(process.env.NODE_ENV != 'development') {

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG,
  plugins: [new BugsnagPluginVue()],
  logger: null
});

const bugsnagVue = Bugsnag.getPlugin('vue');
bugsnagVue.installVueErrorHandler(Vue);
}

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import moment from 'moment';
Object.defineProperty(Vue.prototype, '$moment', { value: moment });

import Noty from 'noty';
Object.defineProperty(Vue.prototype, '$noty', { value: Noty });

Noty.overrideDefaults({
	timeout: 750,
	type: 'success',
  layout: 'topRight',
  theme: 'bootstrap-v4',
});

import { Calendar } from '@fullcalendar/core';
Object.defineProperty(Vue.prototype, '$calendar', { value: Calendar });

import axios from 'axios';
Object.defineProperty(Vue.prototype, '$axios', { value: axios });

import Ably from 'ably';
Object.defineProperty(Vue.prototype, '$ably', {value: Ably });

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
Object.defineProperty(Vue.prototype, '$html2canvas', { value: html2canvas });
Object.defineProperty(Vue.prototype, '$jsPDF', { value: jsPDF });

import DOMPurify from 'dompurify';
Object.defineProperty(Vue.prototype, '$DOMPurify', { value: DOMPurify });

import bootstrap from 'bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/fontawesome/css/fontawesome.min.css';
import './assets/fontawesome/css/light.min.css';
import './assets/fontawesome/css/solid.min.css';

import './styles.scss';

import Multiselect from 'vue-multiselect';
Vue.component('multi-select', Multiselect); 

import "leaflet/dist/leaflet.css";
import L from "leaflet";

import markerClusterGroup from "leaflet.markercluster";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
   iconRetinaUrl: import('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: import('leaflet/dist/images/marker-icon.png'),
   shadowUrl: import('leaflet/dist/images/marker-shadow.png'),
});

import App from './views/App.vue';

const components = require.context('./components', true, /\.vue$/i);
components.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], components(key).default));

import router from './router.js';
import http from './http.js';
import filters from './filters.js';
import auth from './mixins/auth';

var store = {
  current_user: {
    current_agency: {}
  },
  current_region: localStorage.getItem('current_region'),
  agency: localStorage.getItem('agency'),
  current_clear_version: '1.0',
  coordinates: [37.090240, -95.712891],
  map_url: 'https://tiles.stadiamaps.com/tiles/',
  map_token: '2741de19-9b38-45f8-bcb7-e66bf3683ce5',
  mapbox_url: 'https://api.mapbox.com/styles/v1/ecourtdate/',
  mapbox_token: 'pk.eyJ1IjoiZWNvdXJ0ZGF0ZSIsImEiOiJjbDJoMTM5MmUwMXVqM2ltaHkwZW4zcXQ1In0.LMUuvwP7jONVzWPzk-G1MA',
  bulk_action_types: [
    { value: 'Send a Message', label: 'Send a Message', description: 'Send a one-off message to a group of clients and contacts.' },
    { value: 'Assign Users', label: 'Assign Users', description: 'Assign and unassign current users to clients.' },
    { value: 'Trash Clients', label: 'Trash Clients', description: 'Bulk trash existing clients.' },
    { value: 'Archive Clients', label: 'Archive Clients', description: 'Bulk archive existing clients.' },
    { value: 'Restore Clients', label: 'Restore Clients', description: 'Bulk restore trashed or archived clients.' },
    { value: 'Transfer Clients', label: 'Transfer Clients', description: 'Transfer clients to another agency.' },
  ],
  bulk_action_statuses: ['complete', 'scheduled', 'dispatching', 'processing', 'canceled'],
  status_types: ['client', 'event', 'case', 'payment', 'contact', 'warrant'],
  assets_url: 'https://ecdassets.s3.amazonaws.com/',
  card_brands: ['visa', 'mastercard', 'discover', 'amex'],
  pay_methods: ['card', 'ach', 'check', 'invoice'],
  rtls: ['ar', 'he', 'fa', 'pa', 'ku'],
  data_sources: ['messages', 'events', 'clients', 'contacts', 'client_cases', 'payments', 'opts']
}

Vue.config.productionTip = false;

new Vue({
  router,
  http,
  filters,
  L,
  markerClusterGroup,
  bootstrap,
  mixins: [ auth ],
  data: store,
  render: h => h(App)
}).$mount('#app');

router.beforeEach((to, from, next) => {

  const token = localStorage.getItem('access_token');
  const requiresAuth = to.matched.some(route => route.meta.requiresAuth);
  if (!requiresAuth) next()
  if(!token && requiresAuth) {
    next({ name: 'login' });
  } else {
    next()
  }

});