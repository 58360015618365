<template>
  <div>
    <template v-if="records && records.length > 0">

      <div class="row">
        
        <div class="col-sm-12 col-md-6">
          <p class="card-title">{{ records.length }} Attachments</p>
        </div><!--Col-->

        <div class="col-sm-12 col-md-6 text-right">
          <loader :isLoading="processing" />
        </div><!--Col-->

      </div><!--Row-->

      <ul class="list-group">

        <template v-for="(record, record_index) in records">
          <li :key="'attach_'+section.title+'_'+record_index" class="list-group-item list-group-item-action link-hover" :class="{'active': record == embed.record }" @click="getRecord(record)">{{ record }}</li>
        </template>
      </ul>

      <template v-if="embed.type && embed.url">
          <attach-embed :type="embed.type" :url="embed.url" />
      </template>

    </template>
  </div>
</template>
<script>
export default {

props: ['test', 'section'],

data() {
    return {
        processing: false,
        records: [],
        embed: {},
        base_url: '/test_attachments'
    }
},

mounted() {

  var self = this

  this.$eventBus.$on('new_test_attachment', function () {
      self.getRecords();
  });

},

created() {
  this.getRecords();
},

methods: {

  resetEmbedForm() {
    this.embed = {
      type: null,
      url: null
    }
  },

  getRecords() {
    this.processing = true;
    this.$http.get(this.base_url+'?file_path='+this.test.uuid+'/'+this.section.title)
    .then(response => {
      if(response && response.data) {
        this.records = response.data;
        this.processing = false;
      }
    })
  },

  getRecord(record) {

    if(this.embed.record && this.embed.record == record) {
      this.resetEmbedForm();
      return;
    }

    this.processing = true;

    this.resetEmbedForm();

    this.$http.get(this.base_url+'?download=true&file_path='+this.test.uuid+'/'+this.section.title+'/'+record)
    .then(response => {
      if(response && response.data) {
        this.embed.url = response.data;
        this.embed.record = record;
        this.downloadFile();
      }
    })
  },

  downloadFile() {
   this.$axios.get(this.embed.url)
    .then(response => {
      if(response && response.headers) {
        this.embed.type = response.headers['content-type'];
        this.processing = false;
      }
  })
  }

}

}
</script>