<template>
  <div>

    <page-tabs page="events" />

    <template v-if="errors && errors.message">
            <div v-if="errors.message != 'Network Error'">
              <div class="alert alert-danger">
                {{ errors.message }}
              </div>
            </div>
    </template>

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">
                  {{ title }} {{ $route.meta.title }}
                </p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-right">

                <template v-if="form.uuid && !processing">
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    @click="resetForm()"
                  >
                    <i class="fa fa-times" /> <span class="sr-only">Close</span>
                  </button>
                </template>
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="color">Color</label>
                <select
                  id="color"
                  v-model="form.color"
                  class="form-control text-uppercase"
                  :disabled="processing"
                  autofocus
                >
                  <option value=""></option>
                  <template v-for="color in colorOptions">
                    <option
                      :key="color"
                      :value="color"
                    >
                      {{ color }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="description">Description</label>
                <textarea
                    id="description"
                    v-model="form.description"
                    class="form-control"
                />
              </div>

              <div class="form-group mt-3">
                <label for="start_date">Start Date</label>
                <input
                  id="start_date"
                  v-model="form.start_date"
                  type="date"
                  class="form-control"
                  required
                  :disabled="processing"
                >
              </div>

              <div class="form-group mt-3">
                <label for="end_date">End Date</label>
                <input
                  id="end_date"
                  v-model="form.end_date"
                  type="date"
                  class="form-control"
                  required
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="start_time">Start Time</label>
                <input
                  id="start_time"
                  v-model="form.start_time"
                  type="time"
                  class="form-control"
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="end_time">End Time</label>
                <input
                  id="end_time"
                  v-model="form.end_time"
                  type="time"
                  class="form-control"
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="location">Location</label>
                <select
                    id="location"
                    v-model="form.location"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(location, index) in locations">
                    <option
                        :key="'location_'+index"
                        :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    <template v-if="form.uuid">
                      Save
                    </template>
                    <template v-else>
                      Add
                    </template>
                  </button>
                </div><!--Col-->


                <div class="col-sm-12 col-md-6 text-right">
                  <template v-if="form.uuid">
                    <button
                      type="button"
                      class="btn btn-outline-danger"
                      :disabled="processing"
                      @click="deleteForm(form.uuid)"
                    >
                      DELETE
                    </button>
                  </template>
                </div><!--Col-->
              </div><!--Row-->
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
          :form="form"
          class="mt-3"
        />

        <div class="card">
          <div class="card-body">

            <p class="card-title">Add Random {{  $route.meta.title }}s</p>

            <form @submit.prevent="postRandomForm()">

              <p class="m-0">{{ colorOptions.length }} Available Colors</p>

              <template v-for="color in colorOptions">
                  <span
                    :key="'randomColorButton_'+color"
                    class="badge badge-pill link-hover btn-outline-secondary text-uppercase"
                    @click="addRandomColor(color)"
                    :disabled="processing"
                  >
                    <i
                      class="fa fa-circle"
                      :style="'color:' + color"
                    /> {{ color }}
                  </span>
              </template>


                <hr>

                <p class="card-title">{{ randomForm.colors.length }} Enabled Colors</p>

                <template v-for="(randomColor, randomColorIndex) in randomForm.colors">

                  <div :key="'enabledColor_'+randomColorIndex">

                  <div class="form-group">
                    <label :for="'color_'+randomColorIndex" class="form-label text-uppercase">

                    <i
                      class="fa fa-circle"
                      :style="'color:' + randomColor.color"
                    /> {{ randomColor.color }} Frequency

                    </label>
                    <select :id="'color_'+randomColorIndex" class="form-control" v-model="randomForm.colors[randomColorIndex].frequency">
                      <option value="4">Weekly</option>
                      <option value="1">Monthly</option>
                      <option value="2">Biweekly</option>
                    </select>
                  </div>

                  <button
                    class="btn btn-danger btn-sm mb-3"
                    @click="removeRandomColor(randomColorIndex)"
                    :disabled="processing"
                  >
                    <i
                      class="fa fa-times"
                    /> <span class="sr-only">Remove</span>
                  </button>

                  </div><!--EnabledColor-->


              </template>

              <div class="form-group mt-3">
                <label for="randomForm_description">Description</label>
                <textarea id="randomForm_description" class="form-control" v-model="randomForm.description"></textarea>
              </div>

              <div class="form-group mt-3">
                <label for="randomForm_start_date">Start Date</label>
                <input
                  id="randomForm_start_date"
                  v-model="randomForm.start_date"
                  type="date"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="randomForm_end_date" class="form-label">End Date</label>
                <select id="randomForm_end_date" class="form-control" v-model="randomForm.end_date" required>
                  <option value="1 year">1 year</option>
                  <option value="1 month">1 month</option>
                  <option value="3 months">3 months</option>
                  <option value="6 months">6 months</option>
                  <option value="9 months">9 months</option>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="randomForm_random_times"
                    v-model="randomForm.random_times"
                    type="checkbox"
                    class="custom-control-input"
                >
                <label
                    class="custom-control-label"
                    for="randomForm_random_times"
                > Random Time Slots</label>
              </div>

              <template v-if="!randomForm.random_times">

              <div class="form-group">
                <label for="randomForm_start_time" class="form-label">Start Time</label>
                <input id="randomForm_start_time" type="time" class="form-control" v-model="randomForm.start_time">
              </div>

              <div class="form-group">
                <label for="randomForm_end_time" class="form-label">End Time</label>
                <input id="randomForm_end_time" type="time" class="form-control" v-model="randomForm.end_time">
              </div>

              </template>

              <div class="form-group custom-control custom-switch">
                <input
                  id="randomForm_default_schedules"
                  v-model="randomForm.default_schedules"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="randomForm_default_schedules"
                > Create Default {{  $route.meta.title }}s</label>
              </div>

              <div class="form-group" :hidden="!randomForm.default_schedules">
                <label for="randomForm_default_colors" class="form-label">Default Colors</label>
                <input id="randomForm_default_colors" type="text" class="form-control" v-model="randomForm.default_colors">
              </div>

              <div class="form-group">
                <label for="randomForm_location">Location</label>
                <select
                    id="randomForm_location"
                    v-model="randomForm.location"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <option value="random">Random</option>
                  <template v-for="(location, index) in locations">
                    <option
                        :key="'random_location_'+index"
                        :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                </select>
              </div>

            <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

            </form>

            <button type="button" class="btn btn-outline-secondary mt-3" @click="resetRandomForm()" :disabled="processing"><i class="fa fa-eraser"></i> Reset</button>


            <template v-if="randomFormAlert && randomFormAlert.length >= 1">
              <div class="alert alert-success mt-3" role="alert">
                {{ randomFormAlert }}
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->



      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <template v-if="portal && portal.link">
          <a :href="'https://'+portal.link+'.'+portal.domain+'/current'" target="_blank" class="btn btn-outline-secondary btn-sm mb-3"><i class="fa fa-external-link-alt"></i> Open Portal Link</a>
        </template>

        <div
          id="calendar"
          style="height:620px;width:100%"
        />

        <div class="card mt-3">
          <div class="card-body">


            <div class="row mb-3">
              <div class="col-sm-12 col-md-6">
                <p class="card-title m-0">
                  {{ total }} {{ $route.meta.title }}s
                </p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">
                <template v-if="queries.from_date && queries.to_date">
                  <span class="text-muted"><strong>FROM:</strong> {{ queries.from_date | datetime }} <strong>TO:</strong> {{ queries.to_date | datetime }}</span>
                </template>
              </div><!--Col-->

              </div><!--Row-->

                <div class="table-responsive">
                <table class="table table-striped table-hover mt-3">
                  <thead>
                    <template v-for="(column, index) in columns">
                      <th :key="'column_'+index">
                        <template v-if="column.key">
                          <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                          >{{ column.label }}</span>
                        </template>

                        <template v-if="!column.key">
                          <span class="text-capitalize">{{ column.label }}</span>
                        </template>

                        <template v-if="column.key == queries.sort">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                          >
                            <template v-if="queries.sortDirection == 'asc'">
                              <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                            </template>

                            <template v-if="queries.sortDirection == 'desc'">
                              <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                            </template>
                          </button>
                        </template>
                      </th>
                    </template>
                    </thead>

                  <tbody>
                    <template v-if="records && records.length > 0">
                      <template v-for="(record, index) in records">
                        <tr :key="'schedule_'+index">
                          <td class="text-uppercase">
                            <button
                              type="button"
                              class="btn btn-dark text-uppercase"
                              :disabled="processing"
                              @click="getRecord(record.id)"
                            >
                              <i
                                class="fa fa-circle"
                                :style="'color:' + record.color"
                              /> {{ record.color }}
                            </button>
                          </td>
                          <td>
                            <template v-if="record.starts">
                              <span class="badge badge-primary">{{ record.starts }}</span>
                            </template>
                          </td>
                          <td>{{ record.start_date | date }}</td>
                          <td>{{ record.end_date | date }}</td>
                          <td>{{ record.start_time | time }}</td>
                          <td>{{ record.end_time | time }}</td>
                          <td>{{ record.description }}</td>
                          <td>
                            <template v-if="record.location">
                              <router-link :to="{'name': 'locations.edit', 'params': {'id': record.location}}">
                                {{ setLocationName(record) }}
                              </router-link>
                            </template>
                          </td>
                          <td>{{ record.created_by }}</td>
                          <td>{{ record.created_at | datetime }}</td>
                          <td>{{ record.updated_at | datetime }}</td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              :disabled="processing"
                              @click="deleteForm(record.id)"
                            >
                              DELETE
                            </button>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
                </div><!--Responsive-->

                <template v-if="records && !records.length">
                  <div class="alert alert-warning" role="alert">
                    No {{ $route.meta.title }}s found.
                  </div>
                </template>

              </div><!--Body-->
            </div><!--Card-->

          </div>
        </div>

            <div class="row mt-3" :hidden="!records || !records.length">
              <div class="col-12 text-right">
                <button type="button" class="btn btn-danger" @click="resetSchedules()" :disabled="processing">Reset All
                  {{  $route.meta.title }}s</button>
              </div><!--Col-->
            </div><!--Row-->

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment-timezone';
import queries from '../mixins/queries';
import _ from "lodash";

export default {

    mixins: [ queries ],

    data() {
        return {
            processing: false,
            loadMore: true,
            queries: {
                search: '',
                limit: 1000,
                skip: 0,
                start_date: null,
                end_date: null,
                sort: 'start_date',
                sortDirection: 'asc'
            },
            form: {},
            colorOptions: ['aquamarine', 'beige', 'black', 'blue', 'brown', 'cyan', 'fuchsia', 'gray', 'green', 'lime', 'orange', 'pink', 'purple', 'red', 'turquoise', 'violet', 'white', 'yellow'],
            calendar: {},
            preferences: {
                calendar_weekends: false,
                calendar_layout: '',
                calendar_title: [
                ]
            },
            records: [],
            total: 0,
            columns: [
              {label: 'Color', key: 'color'},
              {label: 'Starts', key: 'starts'},
              {label: 'Start Date', key: 'start_date'},
              {label: 'End Date', key: 'end_date'},
              {label: 'Start Time', key: 'start_time'},
              {label: 'End Time', key: 'end_time'},
              {label: 'Description', key: 'description'},
              {label: 'Location', key: 'location'},
              {label: 'Creator', key: 'created_by'},
              {label: 'Created', key: 'created_at'},
              {label: 'Updated', key: 'updated_at'},
              {label: 'Delete'}
            ],
            randomForm: {
              colors: {}
            },
            randomFormAlert: null,
            bufferIntervals: ['days', 'weeks', 'months'],
            portal: {},
            locations: [],
            errors: null
        }
    },

    computed: {

        title: function() {
            if(!this.form.uuid) {
                return 'Add';
            }

            return 'Edit';
        }

    },

    mounted() {
      this.initCalendar();

      var self = this;

      this.$eventBus.$on('errors', function (errors) {
        self.processing = false;
        self.errors = errors.data || errors;
      });

      this.$eventBus.$on(this.$route.meta.base_url, function(data) {

        if(!data.uuid) {
          return;
        }

        self.pushEvent(data);

        });

    },

    created() {
      this.resetForm();
      this.resetRandomForm();
      this.resetRecords();
      this.setURLParams();
      this.getRecords();
      this.getPortal();
      this.getLocations();
    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });

        },

        resetForm() {
          this.form = {
            color: null,
            start_date: null,
            end_date: null,
            start_time: '00:00',
            end_time: '18:00',
            description: null,
            location: null
          }

          this.errors = null;
        },

        resetRandomForm() {
          this.randomForm = {
            colors: [],
            description: null,
            location: null,
            start_date: null,
            frequency: null,
            random_times: true,
            end_date: '1 year',
            start_time: '00:00',
            end_time: '18:00',
            max: 1,
            buffer: 1,
            buffer_interval: 'days',
            default_schedules: true,
            default_colors: 'aquamarine, beige, black, blue, brown, cyan, fuchsia, gray, lime, orange, pink, purple, turquoise, violet, white'
          }
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + ' | eCourtDate.com';
        },

        resetRecords() {
          this.records = [];
          this.queries.skip = 0;
          this.total = 0;      
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        getRecords() {

            this.updateURL();

            this.getTotal();

            this.processing = true;

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.data) {

                    if(response.data.length > 0) {

                      var self = this;

                        response.data.forEach(r => {
                          self.pushEvent(r);
                        });
                    }

                    this.processing = false;
                }
            })
        },

        formatEvent(r) {

            var allDay = false;
            var start = r.start_time || '';
            var end = r.end_time || '';

            var start_date = r.start_date + ' ' + start;
            var end_date = r.end_date + ' ' + end;

            if(!start && !end) {
                allDay = true;
                start_date = r.start_date;
                end_date = r.end_date;
            }

            var title = this.$route.meta.title;

            if(r.color) {
                title += ' ' + r.color.toUpperCase();
            }

            if(r.description) {
                title += ' - ' + r.description;
            }

            return {
                id: r.uuid,
                uuid: r.uuid,
                color: r.color,
                start_date: r.start_date,
                start_time: r.start_time,
                end_date: r.end_date,
                end_time: r.end_time,
                // starts: r.starts,
                start: this.formatDate(start_date),
                end: this.formatDate(end_date),
                allDay: allDay,
                title: title,
                location: r.location,
                description: r.description,
                created_by: r.created_by,
                created_at: r.created_at
            }
        },

        getRecord(uuid) {

            if(uuid === this.form.uuid) {
                this.resetForm();
                return;
            }

            this.processing = true;
            this.$http.get('/'+this.$route.meta.base_url+'/'+uuid)
            .then(response => {
                if(response && response.data) {
                    this.form = response.data;
                    this.processing = false;
                }
            })
        },

        pushEvent(record) {
            var event = this.formatEvent(record);

            this.records.push(event);

            this.calendar.addEvent(event);
        },

        getAddSchedule(uuid) {
            this.processing = true;
            this.$http.get('/'+this.$route.meta.base_url+'/'+uuid)
            .then(response => {
                if(response && response.data) {

                    if(response.data.uuid) {
                        this.total++;
                        this.pushEvent(response.data);
                    }

                    this.processing = false;
                }
            })
        },


        formatDate(value) {
          if (value && moment(value).isValid()) {

            var timezone = moment.tz.guess() || localStorage.getItem('timezone');

            var date = moment(value).tz(timezone);

            return date.tz(timezone).format('YYYY-MM-DD');
          }
        },


        postForm () {

            this.processing = true;

            this.errors = null;

            if(!this.form.uuid) {
                this.createForm();
                return;
            }


            this.updateForm(this.form.uuid);
            this.processing = true;
        },

        createForm() {
            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if(response && response.data) {

                    this.processing = false;

                    if(response.status === 201) {
                        this.form.color = null;
                        new this.$noty({text: this.$route.meta.title + ' created'}).show();
                        // this.getAddSchedule(response.data.uuid);
                    }
                    
                }
            })
        },

        updateForm(uuid) {
            this.$http.patch('/'+this.$route.meta.base_url+'/'+uuid, this.form)
            .then(response => {
                if(response && response.data) {


                    if(response.data.updated_at) {
                        this.resetForm();
                        new this.$noty({text: 'Updating '+ this.$route.meta.title}).show();
                        this.records.splice(this.records.findIndex((obj => obj.uuid == uuid)), 1);
                        this.getAddSchedule(uuid);

                    }

                    this.processing = false;
                }
            })
        },

        deleteForm (uuid) {
            this.processing = true;
            this.$http.delete('/'+this.$route.meta.base_url+'/'+uuid)
            .then(response => {
                if(response && response.status) {

                    this.processing = false;

                    if(response.status === 204) {
                        new this.$noty({text: 'Deleting '+this.$route.meta.title, type: 'error'}).show();
                        this.resetForm();
                        this.total--;
                        this.records.splice(this.records.findIndex((obj => obj.uuid == uuid)), 1);
                        this.setTitle();
                    }
                }
            })
        },

        updateSortDirection() {

          if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
          } else {
            this.queries.sortDirection = 'asc';
          }

          this.resetRecords();

          this.getRecords();
        },

        sortColumn(column) {

          this.queries.sort = column.key;

          this.updateSortDirection();

        },

        initCalendar() {

            var self = this;

            this.calendar = new this.$calendar(document.getElementById('calendar'), {
              plugins: [dayGridPlugin],
              initialView: 'dayGridMonth',
              timeZone: 'UTC',
              editable: false,
              selectable: true,
              headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth'
              },
              eventClick: function(info) {
                self.getRecord(info.event.id);
              }
            });

            this.calendar.render();
        },

        postRandomForm() {

          this.randomFormAlert = null;

          this.processing = true;
          this.$http.post('/'+this.$route.meta.base_url+'/random', this.randomForm)
          .then(response => {
            if(response && response.status) {
              this.processing = false;

              this.resetRandomForm();

              if(response.status === 202 && response.data) {

                this.randomFormAlert = 'Generating random schedules. We\'ll email you once complete.';

                new this.$noty({text: this.randomFormAlert}).show();

              }
            }
          })
        },

        resetSchedules() {
          this.processing = true;
          this.$http.delete('/'+this.$route.meta.base_url+'/reset')
          .then(response => {
            if(response && response.status) {

              this.processing = false;

              if(response.status === 204) {
                location.reload();
              }

            }
          })
        },

        addRandomColor(color) {
          this.randomForm.colors.unshift({'color': color, 'frequency': 1});
        },

        removeRandomColor(index) {
          this.randomForm.colors.splice(index, 1);
        },

      getPortal() {
          this.$http.get('/portals?domain=ecase.io&limit=1&sort=default&sortDirection=desc')
              .then(response => {
                if(response && response.data && response.data.length > 0) {
                  this.portal = response.data[0];
                }
              })
      },

      getLocations() {
          this.$http.get('/locations?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
              .then(response => {
                if(response && response.data && response.data.length > 0) {
                  this.locations = response.data;
                }
              })
      },

      setLocationName(record) {

        if(!record.location) {
          return;
        }

        var location = _.find(this.locations, {'uuid': record.location});

        if(!location || !location.name) {
          return;
        }

        return location.name;
      },

    }
}
</script>