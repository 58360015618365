<template>
  <div>
    <div class="card">
      <div class="card-body">

        <template v-if="!form.uuid">
          <div class="alert alert-warning m-0">
            No client found
          </div>
        </template>

        <template v-if="!processing && form.uuid">
          <p class="card-title">
            Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': form.uuid} }">
              {{ form.name }} {{ form.first_name }} {{ form.middle_name }} {{ form.last_name }} {{ form.client_reference }}
            </router-link>
          </p>

          <ul class="list-group">

            <template v-if="form.language">
              <li class="list-group-item">
                Language

                {{ setLanguage(form.language) }}

              </li>
            </template>

            <template v-if="form.type">
              <li class="list-group-item">
                Type {{ form.type }}
              </li>
            </template>

            <template v-if="form.group">
              <li class="list-group-item">
                Group {{ form.group }}
              </li>
            </template>

            <template v-if="form.status">
              <li class="list-group-item">
                Status {{ form.status }}
              </li>
            </template>

          </ul>
        </template>
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
import _ from 'lodash';

export default {

props: ['client'],

data() {
    return {
        processing: false,
        form: {},
        base_url: '/clients',
        languages: []
    }
},

created() {
    this.getRecord();
    this.getLanguages();
},

methods: {

        getRecord() {
            
            if(!this.client) {
                return;
            }

            this.processing = true;
            this.form = {};

            this.$http.get(this.base_url+'?limit=1&slim=true&fields=uuid,first_name,last_name,client_reference,type,group,status,language&uuid='+this.client)
            .then(response => {
                if(response && response.data && response.data.length) {
                    this.form = response.data[0];
                    this.processing = false;
                }
            })
        },

        getLanguages () {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
          .then(response => {
              if(response && response.data) {
                  this.languages = response.data;
              }
          })
        },

        setLanguage(language) {
          
          if(!language) {
            return;
          }

          var result = _.find(this.languages, {'key': language});

          if(!result) {
            return language;
          }

          return result.label;

        }
}

}
</script>