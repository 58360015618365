<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="list-group">
          <p class="m-0 card-title">
            {{ records.length }} Recent Clients
          </p>

          <template v-for="(record, index) in records">
            <router-link
              :key="'recent_client_'+index"
              :to="{'name': 'clients.edit', 'params': {'id': record.uuid}}"
              class="list-group-item list-group-item-action border-0"
            >
              <div class="d-flex w-100 justify-content-between">
                <p class="m-0">

                {{ record.first_name }} {{ record.last_name }}

                <template v-if="!record.first_name || !record.last_name">
                    {{ record.name }}
                </template>

                <template v-if="record.client_reference">
                    <p class="small text-muted m-0"><code>{{ record.client_reference }}</code></p>
                </template>


                  <template v-if="record.group">
                    <span class="ml-1 badge badge-pill badge-secondary">{{ record.group }}</span>
                  </template>

                  <template v-if="record.type">
                    <span class="ml-1 badge badge-pill badge-secondary">{{ record.type }}</span>
                  </template>

                </p>

              </div>
            </router-link>
          </template>
        </div><!--Body-->
      </div><!--Card-->
    </div>
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries],

    data() {
        return {
            records: [],
            base_url: '/clients',
            queries: {}
        }
    },

    created () {
        this.resetRecords();
        this.resetQueries();
        this.getRecords();
    },

    methods: {

        resetRecords() {
            this.records = [];
        },

        resetQueries() {
            this.queries = {
                slim: true,
                sort: 'created_at',
                sortDirection: 'desc',
                limit: 10,
                fields: 'uuid,client_reference,name,first_name,last_name,group,type'
            }
        },

        getRecords() {
            this.$http.get(this.buildQueries(this.base_url, this.queries))
            .then(response => {
                if(response && response.data) {
                    this.records = response.data;
                }
            })
        }
    }

}
</script>
