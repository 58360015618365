<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-md-4">
        <h1 class="m-0"><span v-if="form && form.name">{{ form.name }}</span></h1>

        <template v-if="form.extension">
          <code>Extension: {{ form.extension }}</code>
        </template>

        <template v-if="form.size">
          <code class="ml-3">Size: {{ form.size | fileSize }}</code>
        </template>

        <template v-if="form.downloaded_at">
          <span class="badge badge-pill badge-success ml-3">Downloaded {{ form.downloaded_at | dateTimezone | timeago }}</span>
        </template>

        <template v-if="form.expires_at">
          <span class="badge badge-pill badge-danger">Expiration {{ form.expires_at | dateTimezone | timeago }}</span>
        </template>

        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>

      </div><!--Col-->

      <div class="col-md-4">

        <template v-if="form && form.public">
          <a :href="setPortalLink()" class="btn btn-outline-secondary mr-3" target="_blank" :disabled="processing"><i class="fa fa-link"></i> Public Link</a>
          
          <template v-if="qr_code">
            <a :href="setPortalLink()" target="_blank">
            <img
              :src="'data:image/svg+xml;base64,'+qr_code"
              width="50"
            >
            </a>
          </template>

          </template>

      </div><!--Col-->

      <div class="col-md-4 text-right">
        <div class="btn-group">

          <a
            :href="download_url"
            target="_blank"
            class="btn btn-primary"
            :hidden="!download_url || form.deleted_at"
            >
            Open File
          </a>

          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">

            <a
                v-if="!form.archived_at && !form.deleted_at"
                class="dropdown-item"
                @click="archiveRecord()"
            >Archive</a>

            <a
              v-if="!form.deleted_at"
              class="dropdown-item"
              @click="trashRecord()"
            >Trash</a>
            <a
              v-if="form.deleted_at || form.archived_at"
              class="dropdown-item"
              @click="restoreRecord()"
            >Restore</a>
          </div>

          <template v-if="!form.deleted_at">
            <button
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm"
            >
              Save
            </button>
          </template>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->

      <div class="row mt-3">
        <div class="col-sm-12 col-md-3">
          <div class="card">
            <div class="card-body">

              <form @submit.prevent="postForm">

                <fieldset :disabled="processing">

                <div class="form-group">
                  <label for="name">Name</label>
                  <input id="name" type="text" class="form-control" v-model="form.name">
                </div>

                <div class="form-group">
                  <label for="type">Type</label>
                  <input id="type" type="text" class="form-control" v-model="form.type">
                </div>

                <div class="form-group">
                  <label for="description">Description</label>
                  <textarea id="description" class="form-control" rows="3" v-model="form.description"></textarea>
                </div>

                <div class="form-group">
                  <label for="portal">Portal

                  <template v-if="form.portal">
                    <a
                    :href="'/portals/'+form.portal"
                    target="_blank"
                    data-tooltip="Edit Portal">
                    <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                    </a>
                  </template>
                  
                  </label>
                  <select
                    id="portal"
                    v-model="form.portal"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="">
                      Choose Portal
                    </option>
                    <template v-for="portal in portals">
                      <option
                        :key="portal.uuid"
                        :value="portal.uuid"
                      >
                        {{ portal.name }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="language">Default Language</label>
                  <select
                    id="language"
                    v-model="form.language"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="">
                      Choose Language
                    </option>
                    <option value="en">English</option>

                    <template v-for="(language, language_index) in languages">

                      <option
                        :key="'language_'+language_index"
                        :value="language.key"
                        :hidden="language.key == 'en'"
                      >
                        {{ language.label }}
                      </option>

                    </template>

                  </select>
                </div>

                <div class="form-group">
                  <label for="merge_tag">Merge Tag <merge-tag v-if="form.merge_tag" :merge_tag="'{'+form.merge_tag+'}'" /></label>
                  <input id="merge_tag" type="text" class="form-control" v-model="form.merge_tag">
                </div>

                <div class="form-group">
                  <label for="notes">Internal Notes</label>
                  <textarea id="notes" class="form-control" rows="3" v-model="form.notes"></textarea>
                </div>

                <div class="form-group">
                  <label for="expire_date">Expiration Date</label>
                  <input id="expire_date" class="form-control" type="date" v-model="form.expire_date">
                </div>

                <div class="form-group">
                  <label for="expire_time">Expiration Time</label>
                  <input id="expire_time" class="form-control" type="time" v-model="form.expire_time">
                </div>

                <div class="form-group custom-control custom-switch">
                  <input
                    id="attach_emails"
                    v-model="form.attach_emails"
                    type="checkbox"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="attach_emails"
                  > Attach to Emails</label>
                </div>

                <div class="form-group custom-control custom-switch">
                  <input
                    id="public"
                    v-model="form.public"
                    type="checkbox"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="public"
                  > Allow Public Downloads</label>
                </div>

                <template v-if="!form.deleted_at">
                  <button type="submit" class="btn btn-success">Save</button>
                </template>

                </fieldset>

              </form>

            </div><!--Body-->
          </div><!--Card-->

          <audit-logs
            :form="form"
            class="mt-3"
          />

        </div><!--Col-->

        <div class="col-sm-12 col-md-6">
          <template v-if="form && form.uuid && download_url">
            <file-embed :form="form" :url="download_url" />
          </template>
        </div><!--Col-->

        <div class="col-sm-12 col-md-3">

          <div class="card">
            <div class="card-body">

              <p class="card-title">Send {{ $route.meta.title }}</p>

              <form @submit.prevent="sendFile()">

                <template v-if="client && client.uuid">
                      <div class="row mb-3">
                        <div class="col-md-6">
                          <p class="card-title">
                            Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                              {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}
                            </router-link>
                          </p>
                        </div><!--Col-->

                        <div class="col-md-6 text-right">
                          <button
                            type="submit"
                            class="btn btn-danger btn-sm"
                            :disabled="processing"
                            @click="resetClient()"
                          >
                            <i class="fa fa-times" /> <span class="sr-only">Close</span>
                          </button>
                        </div><!--Col-->
                      </div><!--Row-->

                      <template v-if="!processing && client_contacts.length === 0">
                        <span class="badge badge-pill badge-danger">No Contacts</span>
                      </template>

                    <template v-if="client_contacts && client_contacts.length > 0">
                      <p class="card-title m-0">{{ client_contacts.length }} Contacts</p>
                      <template v-for="(client_contact, index) in client_contacts">
                        <span :key="'client_contact_'+index" class="badge badge-pill badge-primary"><a :href="'/contacts/'+client_contact.uuid" target="_blank" class="text-white">{{ client_contact.value }}</a></span>
                      </template>
                      <hr>
                    </template>

                </template>

                <template v-if="client && !client.uuid">
                  <client-search />
                </template>


                <button
                    type="button"
                    class="btn btn-outline-primary mtb-3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                  Auto Messages
                </button>
                <div class="dropdown-menu">
                  <h6 class="dropdown-header">
                    Send Auto Message
                  </h6>

                  <template v-for="auto_option in auto_messages">
                    <template v-if="auto_option.templates && auto_option.templates['en']">
                      <a
                          :key="'auto_option_'+auto_option.uuid"
                          class="dropdown-item"
                          @click="changeAutoMessage(auto_option.uuid)"
                      >{{ auto_option.name }}</a>
                    </template>
                  </template>
                </div>

                <div class="form-group mt-3">
                  <label for="subject">Subject</label>
                  <input id="subject" class="form-control" type="text" v-model="send_form.subject">
                </div>

                <div class="form-group">
                  <label for="content">Message</label>
                  <textarea id="content" class="form-control" rows="8" v-model="send_form.content"></textarea>
                </div>

                  <div class="form-group custom-control custom-switch">
                    <input
                      id="signature"
                      v-model="send_form.signature"
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="signature"
                    >Require eSignature</label>
                  </div>

                <button type="submit" class="btn btn-success" :disabled="processing">Send</button>

              </form>

            </div><!--Body-->
          </div><!--Card-->


          <p class="m-0 mt-3">{{ sent_files.length }} Sent {{ $route.meta.title }}s</p>

          <ul class="list-group">

          <template v-for="(sent_file, index) in sent_files">

            <router-link
            :key="'sent_file_'+index"
            :to="{ name: 'sent_files.edit', params: { 'id': sent_file.uuid }}"
            tag="li"
            class="list-group-item list-group-item-action link-hover">
            
            <template v-if="sent_file.client">
              <client-name :uuid="sent_file.client" />
            </template>

              <template v-if="sent_file.status">
                <file-status :status="sent_file.status" />
              </template>

            {{ sent_file.created_at | dateTimezone | timeago }}

            </router-link>

          </template>

          </ul>

        </div><!--Col-->

      </div><!--Row-->
  </div>
</template>

<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
        processing: false,
        form: {},
        send_form: {},
        clients: [],
        client: {},
        queries: {
          client: null
        },
        sent_files: [],
        portals: [],
        client_contacts: [],
        auto_messages: [],
        download_url: null,
        languages: [],
        qr_code: null
    }
},

mounted() {

    var self = this;

    this.$eventBus.$on('search_clients', function(uuid) {

       self.queries.client = uuid;

       self.getClient();

    });

},

created() {
    this.resetForm();
    this.resetSendForm();
    this.setURLParams();
    this.getRecord();
    this.getLanguages();
    this.getPortals();
    this.getAutoMessages();
},

methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });

    },

    resetForm() {
      this.form = {
        name: '',
        description: '',
        notes: '',
        status: '',
        type: '',
        expire_date: null,
        expire_time: null,
        attach_emails: false,
        public: false,
        language: 'en'
      }
    },

    resetSendForm() {
      this.send_form = {
        name: null,
        portal: null,
        client: null,
        subject: null,
        content: null,
        status: null,
        language: 'en',
        signature: true
      }
    },

    getClient() {

        this.updateURL();

        if(!this.queries.client) {
            this.resetClient();
            return;
        }

        this.processing = true;

        this.$http.get('/clients/'+this.queries.client+'?slim=true&fields=uuid,client_reference,first_name,last_name,language')
        .then(response => {
            if(response && response.data) {
                this.client = response.data;

                if(this.client.uuid) {
                  this.send_form.client = this.client.uuid;
                  this.client.language = this.client.language || 'en';
                  this.send_form.language = this.client.language;
                  this.getContacts();
                }

                this.processing = false;
            }
        })
    },

    getContacts() {
      this.processing = true;
      this.$http.get('/contacts?client='+this.client.uuid+'&slim=true&valid=1&fields=uuid,value')
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          this.client_contacts = response.data;
        }
      })
    },

    resetClient() {
        this.queries.client = null;
        this.send_form.client = null;
        this.client = {};
        this.client_contacts = [];
        this.updateURL();
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url+'/'+this.$route.params.id, this.queries));
    },

    getRecord() {

        this.processing = true;

        this.updateURL();

        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                this.form = response.data;

                this.setTitle();

                this.getSentFiles();

                this.getClient();

                this.getPortal();

                this.getDownloadURL();
            }
        })
    },

    getSentFiles() {

      this.sent_files = [];

      this.$http.get('/sent_files?file='+this.form.uuid+'&sort=created_at&sortDirection=desc&slim=true&fields=name,uuid,created_at,status,client')
      .then(response => {
        if(response && response.status) {
          this.sent_files = response.data;
        }
      })
    },

    setTitle() {
      document.title = this.form.name +  ' | ' +this.$route.meta.title;
    },

    postForm() {
      this.processing = true;
      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {
          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
            this.processing = false;
          }
        }
      })
    },

    getDownloadURL() {
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/download?skip_downloaded=true')
      .then(response => {
        if(response && response.data) {
          this.download_url = response.data;
          this.processing = false;
        }
      })
    },

  archiveRecord() {
    this.processing = true;
    this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/archive')
        .then(response => {
          if(response && response.status === 200) {
            this.processing = false;
            new this.$noty({text: this.$route.meta.title + ' archived', type: 'error'}).show();
            this.form.archived_at = new Date().toISOString();
          }
        })
  },

    trashRecord() {
        this.processing = true;
        this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
        .then(response => {
            if(response && response.status === 204) {
                this.processing = false;
                new this.$noty({text: this.$route.meta.title + ' trashed', type: 'error'}).show();
                this.form.deleted_at = new Date().toISOString();
            }
        })
    },

    restoreRecord () {
        this.processing = true;
        this.$http.put('/'+this.$route.meta.base_url+'/' + this.form.uuid + '/restore')
        .then(response => {
            if (response && response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
                this.processing = false;
            }
            
        })
    },

    postMessage(file) {

      var form = {};

      form.client = this.send_form.client;
      form.subject = this.send_form.subject;
      form.content = this.send_form.content;
      form.file = file.uuid;
      form.type = 'file:'+file.uuid;
      form.test = 0;
      form.language = this.client.language || 'en';

      if(this.portal && this.portal.link) {
        form.content += ' https://'+this.portal.link+'.'+this.portal.domain+'/fl/'+file.url;
      }

      this.$http.post('/messages', form)
      .then(response => {
        if(response && response.data) {

            this.resetSendForm();
            this.resetClient();

            this.processing = false;
        }
      })
    },

    sendFile() {

      this.send_form.name = this.form.name;
      this.send_form.status = 'sent';

      if(this.form.portal) {
        this.send_form.portal = this.form.portal;
      }

      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/send', this.send_form)
      .then(response => {
        if(response && response.status) {

          if(response.status === 201) {
            new this.$noty({text: 'Sending file'}).show();

            this.postMessage(response.data);

            this.getSentFiles();

            // this.sent_files.unshift(response.data);
          }

        }
      })
    },

    getQRCode() {
      this.qr_code = null;
      this.$http.post('/qrs?content='+this.setPortalLink())
      .then(response => {
          if(response && response.data) {
              this.qr_code = response.data;
          }
      })
    },

    setPortalLink() {
      if(!this.portal || !this.portal.link) {
        return;
      }

      if(!this.form || !this.form.url) {
        return;
      }

      var language = this.form.language || 'en';

      return 'https://'+this.portal.link+'.'+this.portal.domain+'/fi/'+this.form.url+'?lang='+language;
    },

    getPortal() {

      if(!this.form.portal) {
        return;
      }

      this.$http.get('/portals/'+this.form.portal+'?slim=true&fields=domain,link')
      .then(response => {
        if(response && response.data) {
          this.portal = response.data;

          this.getQRCode();
        }
      })
    },

    getPortals() {
        this.$http.get('/portals?limit=1000&slim=true&fields=name,uuid&domain=ecase.io')
        .then(response => {
            if(response && response.data) {
                this.portals = response.data;
            }
        })
    },

    changeAutoMessage (uuid) {
        this.auto_message = _.find(this.auto_messages, {'uuid': uuid});

        var language = this.send_form.language || 'en';

        this.send_form.content = this.auto_message.templates[language] || this.auto_message.templates['en'];

        if(this.auto_message.subject) {
          this.send_form.subject = this.auto_message.subject;
        }
    },

    getAutoMessages () {
        this.$http.get('/auto_messages')
        .then(response => {
            if(response && response.data) {
                this.auto_messages = response.data;
            }
        })
    },

    getLanguages () {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
        .then(response => {
            if(response && response.data) {
                this.languages = response.data;
            }
        })
    },
   

}

}
</script>