<template>
  <div>

    <page-tabs page="payments" />
    
    <div class="row">
      <div class="col-lg-3 col-md-12 mb-3">

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords">

              <div class="form-group">
                <label for="amount">Amount</label>
                <input id="amount" type="text" class="form-control" v-model="queries.amount">
              </div>

              <div class="form-group">
                <label for="recurring">Recurring Template</label>
                <select id="recurring" class="form-control text-uppercase" v-model="queries.recurring" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(recurring, recurring_index) in recurrings">
                    <option :key="'recurring_'+recurring_index" :value="recurring.uuid">{{ recurring.name }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="start_date">Start Date</label>
                <input id="start_date" type="date" class="form-control" v-model="queries.start_date">
              </div>

              <div class="form-group">
                <label for="end_date">End Date</label>
                <input id="end_date" type="date" class="form-control" v-model="queries.end_date">
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search {{ $route.meta.title }}s
              </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
      <div class="col-lg-9 col-md-12">

        <div class="card">
          <div class="card-body">

            <p class="card-title">
              {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
            </p>

            <table class="table table-striped table-hover table-responsive">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="$route.meta.base_url"
              />

              <tbody>
                <template v-for="(record, index) in records">
                  <tr :key="'client_recurring_'+index">
                    <td class="text-capitalize">
                      <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                        ${{ record.amount }}
                      </router-link>
                    </td>
                    <td>
                    <template v-if="record.start_date">
                      {{ record.start_date | date }}
                    </template>
                    </td>
                    <td>
                    <template v-if="record.end_date">
                      {{ record.end_date | date }}
                    </template>
                    </td>
                    <td>
                        <template v-if="record.client">
                          <client-button :uuid="record.client" />
                        </template>
                    </td>
                    <td>{{ record.created_by }}</td>
                    <td>{{ record.created_at | datetime }}</td>
                    <td>{{ record.updated_at | datetime }}</td>
                  </tr>
                </template>
              </tbody>
            </table>

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="records && records.length < total">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary d-print-none"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      records: [],
      processing: false,
      total: 0,
      queries: {},
      form: {},
      columns: [
          {key: 'amount', label: 'Amount'},
          {key: 'start_date', label: 'Start Date'},
          {key: 'end_date', label: 'End Date'},
          {key: 'client', label: 'Client'},
          {key: 'created_by', label: 'Creator'},
          {key: 'created_at', label: 'Created'},
          {key: 'updated_at', label: 'Updated'}
      ],
      recurrings: []
    }
  },

  created () {
    this.resetRecords();
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getRecords();
    this.getRecurrings();
  },

  methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    searchRecords () {
        this.resetRecords();
        this.getRecords();
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    resetForm() {
      this.form = {
        client: null
      }
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'desc',
        client: null,
        amount: null,
        recurring: null,
        start_date: null,
        end_date: null
      }
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
    },

    getRecords () {

      this.processing = true;

      this.updateURL();

      this.totalRecords();

      this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
      .then(response => {
        if (response && response.data) {

          this.processing = false;

          if(response.data.length) {

            this.queries.skip += response.data.length;

            var self = this;

            response.data.forEach(function(data) {
              self.records.push(data);
            });

          }
        }
      })
    },

    totalRecords() {
      this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    getRecurrings() {
      this.$http.get('/recurring_payments?slim=true&fields=uuid,name&limit=100')
      .then(response => {
        if(response && response.data) {
          this.recurrings = response.data;
        }
      })
    }


  }
}
</script>