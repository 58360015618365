<template>
  <div>

    <page-tabs page="messages" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <template v-if="client && client.uuid">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-6">
                  <p class="card-title">
                    Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                      {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}
                    </router-link>
                  </p>
                </div><!--Col-->

                <div class="col-md-6 text-right">
                  <button
                    type="submit"
                    class="btn btn-danger btn-sm"
                    :disabled="processing"
                    @click="closeClient()"
                  >
                    <i class="fa fa-times" /> <span class="sr-only">Close</span>
                  </button>
                </div><!--Col-->
              </div><!--Row-->

              <ul class="list-group">
                <template v-if="client.client_reference">
                  <li class="list-group-item">
                    Reference {{ client.client_reference }}
                  </li>
                </template>

                <template v-if="client.type">
                  <li class="list-group-item">
                    Type {{ client.type }}
                  </li>
                </template>

                <template v-if="client.group">
                  <li class="list-group-item">
                    Group {{ client.group }}
                  </li>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="client && !client.uuid">
          <client-search />
        </template>

        <div class="card">
          <div class="card-body">
            <form @submit.prevent="searchRecords()">
              <p class="card-title">
                Search {{ $route.meta.title }}s
              </p>

              <div class="form-group">
                <label for="from_date">From Date</label>
                <input
                  id="from_date"
                  v-model="queries.from_date"
                  placeholder="mm/dd/yyyy"
                  type="date"
                  class="form-control"
                >
              </div>
                          
              <div class="form-group">
                <label for="from_time">From Time</label>
                <input
                  id="from_time"
                  v-model="queries.from_time"
                  placeholder="hh:mm AM"
                  type="time"
                  class="form-control"
                  step="1"
                >
              </div>
                          
              <div class="form-group">
                <label for="to_date">To Date</label>
                <input
                  id="to_date"
                  v-model="queries.to_date"
                  placeholder="mm/dd/yyyy"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="to_time">To Time</label>
                <input
                  id="to_time"
                  v-model="queries.to_time"
                  placeholder="hh:mm AM"
                  type="time"
                  class="form-control"
                  step="1"
                >
              </div>

              <div class="form-group">
                <label for="country">Country</label>
                <select
                  id="country"
                  v-model="queries.country"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-if="countries && countries.length > 0">
                    <template v-for="(country, index) in countries">
                      <option
                        :key="'country_'+index"
                        :value="country.code"
                      >
                        {{ country.code }} - {{ country.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="client && client.uuid">
          <div class="card mt-3">
            <div class="card-body">
              <p class="card-title">
                Client <a :href="'/clients/'+client.uuid">{{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}</a>
              </p>

              <ul class="list-group">
                <template v-if="client.client_reference">
                  <li class="list-group-item">
                    Reference {{ client.client_reference }}
                  </li>
                </template>

                <template v-if="client.type">
                  <li class="list-group-item">
                    Type {{ client.type }}
                  </li>
                </template>

                <template v-if="client.group">
                  <li class="list-group-item">
                    Group {{ client.group }}
                  </li>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

      </div><!--Col-->

      <div id="main" class="col-sm-12 col-md-9">

        <div
          :id="'map_'+$route.meta.base_url"
          class="map-xl"
        />

        <div class="card mt-3">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none" data-html2canvas-ignore>
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
            <table class="table table-striped table-hover">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="$route.meta.base_url"
              />

              <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="(record, index) in records">
                    <tr :key="'record_'+index">
                      <td>
                        <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid}}">
                          <i class="fa fa-globe"></i> {{ $route.meta.title }} {{ record.country }}
                        </router-link>
                      </td>

                      <td>
                        <template v-if="record.client">
                          <client-button :uuid="record.client" />
                        </template>
                      </td>

                      <td>
                        <template v-if="record.message">
                          <a
                            :href="'/messages/'+record.message"
                            class="btn btn-outline-secondary btn-sm"
                          ><i class="fa fa-comment" /> <span class="sr-only">Message</span></a>
                        </template>
                      </td>

                      <td>{{ record.latitude }}</td>
                      <td>{{ record.longitude }}</td>

                      <td>
                        <template v-if="record.error && record.error.message">
                          <span class="badge badge-pill badge-danger text-uppercase">{{ record.error.message }}</span>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.ip_address">
                          <a :href="'/ips/'+record.ip_address">{{ record.ip_address }}</a>
                        </template>
                      </td>
                      <td>{{ record.created_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{  $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

          <div class="row mt-3">
            <div class="col-12 text-center">

              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>

            </div><!--Col-->
          </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      downloading: false,
      queries: {},
      records: [],
      downloads: [],
      map: null,
      clients: [],
      client: {},
      total: 0,
      columns: [
          {label: 'Geolocation'},
          {key: 'client', label: 'Client'},
          {key: 'message', label: 'Message'},
          {key: 'latitude', label: 'Latitude'},
          {key: 'longitude', label: 'Longitude'},
          {key: 'error', label: 'Error Status'},
          {key: 'ip_address', label: 'IP Address'},
          {key: 'created_at', label: 'Created'}
      ],
      countries: [],
      markers: []
    }
  },

  mounted() {

  var self = this;

  this.$eventBus.$on('refreshRecords', function(view) {

      if(view !== self.$route.meta.base_url) {
          return;
      }

      self.resetRecords();

      self.getRecords();

  });
  
  this.$eventBus.$on('search_clients', function(uuid) {

     self.queries.client = uuid;

     self.resetRecords();

     self.getRecords();

     self.getClient(uuid);

  });

  },

  created () {
    this.resetQueries();
    this.resetRecords();
    this.setURLParams();
    this.getRecords();
    this.getCountries();
  },

  beforeDestroy() {
    if (this.map) {
      this.map.invalidateSize();
      this.map.remove();
      this.map = {};
    }
  },


  methods: {


    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });

        if(this.queries.client) {
          this.getClient(this.queries.client);
        }
    },

    resetQueries() {
      this.queries = {
        client: null,
        room: null,
        country: null,
        from_date: '',
        to_date: '',
        from_time: '',
        to_time: '',
        limit: 100,
        sort: 'created_at',
        sortDirection: 'desc'
      }
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;      
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords () {

      if(this.client && this.client.uuid) {
        this.queries.client = this.client.uuid;
      }

      if(!this.client) {
        this.queries.client = null;
      }

      this.updateURL();

      this.processing = true;
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then((response) => {
        if (response && response.data) {

          if(response.data.length > 0) {

            var self = this;

            response.data.forEach(r => {
              self.records.push(r);
            });
          }

          this.queries.skip = this.records.length;

          this.processing = false;

          this.getTotal();

          if(!this.map) {
            this.initMap();
          }

            this.createMarkers();
            
        }
      })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    initMap() {

      if(this.map) {
        return;
      }

      var map = document.getElementById('map_'+this.$route.meta.base_url);

      if(!map) {
        return;
      }

      const light = window.L.tileLayer(this.$root.map_url+'alidade_smooth/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
      });

      const dark = window.L.tileLayer(this.$root.map_url+'alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
      });

      const outdoors = window.L.tileLayer(this.$root.map_url+'outdoors/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
      });


      const baseLayers = {
        'Outdoors': outdoors,
        'Light': light,
        'Dark': dark,
      };

      this.map = window.L.map('map_'+this.$route.meta.base_url, {layers: dark}).setView(this.setDefaultCoordinates(), 4);

      window.L.control.layers(baseLayers).addTo(this.map);
    },

    setDefaultCoordinates() {

      if(!this.$root.current_user || !this.$root.current_user.current_agency) {
        return this.$root.coordinates;
      }

      if(this.$root.current_user.current_agency.settings.latitude && this.$root.current_user.current_agency.settings.longitude) {
        return [this.$root.current_user.current_agency.settings.latitude, this.$root.current_user.current_agency.settings.longitude];
      }

      return this.$root.coordinates;

    },

    createMarkers () {

      this.markers = window.L.markerClusterGroup({
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true
      });


      this.records.forEach((record) => {

        if(!record.latitude || !record.longitude) {
          return
        }

        const icon = 'map-marker';

        const markerIcon = window.L.divIcon({
          html: '<i class="fa text-primary fa-2x fa-'+icon+'"></i>',
          iconSize: [20, 20],
          className: 'marker-icon'
        });

        var self = this;

        var marker = window.L.marker([record.latitude, record.longitude], {icon: markerIcon})
            .on('click', function(evt) {
              window.location.href = '/'+self.$route.meta.base_url+'/'+record.uuid;
            });;

        this.markers.addLayer(marker);

      });

      if(!this.map) {
        return;
      }

      this.map.addLayer(this.markers);

    },

    searchClients: _.debounce(function (query) {
      this.getSearch(query);
    }, 500),

    getSearch (query) {

      if(!query) {
        return;
      }

      this.$http.get('/search?search='+query)
      .then(response => {
        if (response) {
          this.clients = response.data;
        }
      })
    },


    getClient(uuid) {
        this.$http.get('/clients/'+uuid+'?slim=true&fields=uuid,client_reference,first_name,last_name,group,type')
        .then(response => {
            if(response && response.data) {
                this.client = response.data;
            }
        })
    },

    closeClient() {
        this.queries.client = null;
        this.client = {};
        this.resetRecords();
        this.getRecords();
    },

    getCountries() {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/country_codes.json')
        .then(response => {
            if(response && response.data) {
                this.countries = response.data;
            }
        })
    },

    async downloadPDF() {
      try {

        window.scrollTo(0,0);

        this.downloading = true;

        const element = document.getElementById('main');

        const canvas = await this.$html2canvas(element, {
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          scale: window.devicePixelRatio,
          useCORS: true,
          logging: false
        });

        const imgData = canvas.toDataURL('image/png');

        const pdf = new this.$jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

        pdf.save(name+'.pdf');

        this.downloading = false;
      } catch (error) {
        new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
        console.log(error);
        this.downloading = false;
      }
    },

    downloadCSV () {
      this.downloading = true;

      var queries = this.queries;

      queries.limit = this.total;

      queries.skip = this.downloads.length;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
          .then(response => {
            if(response && response.data && response.data) {
              this.downloads = this.downloads.concat(response.data);

              var csvFile, data, link;

              data = this.convertCSV(this.downloads);

              var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

              csvFile = new Blob([data], {type: "text/csv"});
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(csvFile, file);
              } else {
                link = document.createElement("a");
                link.download = file;
                link.href = window.URL.createObjectURL(csvFile);
                link.style.display = "none";
                document.body.appendChild(link);
                link.click();
              }

              this.downloading = false;

            } else {
              this.downloadCSV();
            }

          });
    },

    convertCSV(records) {

      var results = [];

      if(records[0] && records[0]['uuid']) {
        var headers = Object.keys(records[0]);
      }

      results += headers.join(',')

      results += '\n';

      records.forEach(function(item) {
        var record = '';

        if(!item) {
          return;
        }

        Object.keys(item).forEach(function(key) {
          record += '"' + (item[key] ? item[key] : '') + '",';
        });

        results += record;
        results += '\n';
      });

      return results
    },

  },
}
</script>
