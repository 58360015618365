<template>
  <div>

    <page-tabs page="users" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

    <div class="card mb-3">
      <div class="card-body">
        <p class="card-title">
          Add {{ $route.meta.title }}
        </p>
        <form
          @submit.prevent="postForm()"
        >
          <div class="form-group">
            <label for="user_name">Name</label>
            <input
              id="user_name"
              v-model="form.name"
              type="text"
              class="form-control"
              required
            >
          </div>
          <div class="form-group">
            <label for="user_phone">Phone</label>
            <input
              id="user_phone"
              v-model="form.phone"
              type="tel"
              class="form-control"
            >
          </div>
          <div class="form-group">
            <label for="user_email">Email</label>
            <input
              id="user_email"
              v-model="form.email"
              type="email"
              class="form-control"
              required
            >
          </div>

          <div class="form-group custom-control custom-switch">
            <input
              id="notify"
              v-model="form.notify"
              type="checkbox"
              class="custom-control-input"
              :disabled="processing"
            >
            <label
              class="custom-control-label"
              for="notify"
            >Notify User</label>
          </div>
          <div class="form-group custom-control custom-switch">
            <input
              id="super_admin"
              v-model="form.super_admin"
              type="checkbox"
              class="custom-control-input"
              :disabled="processing"
            >
            <label
              class="custom-control-label"
              for="super_admin"
            >Super Admin</label>
          </div>

          <div class="form-group" :hidden="form.super_admin">
            <label for="roles">Role</label><br>
            <select id="roles" class="form-control" v-model="form.roles">
              <option value="" />
              <template v-for="(role, index) in roles">
                <option
                    :key="'role_'+index"
                    :value="role"
                >
                  {{ role }}
                </option>
              </template>
            </select>
          </div>


          <button
            type="submit"
            class="btn btn-success"
            :disabled="processing"
          >
            Add
          </button>
        </form>
      </div><!--Body-->
    </div><!--Card-->

        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">Search {{ $route.meta.title }}s</p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-right">
                <button type="button" class="btn btn-outline-secondary btn-sm" @click="resetQueries()"><i class="fa fa-eraser"></i> Reset</button>
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="searchRecords()">
              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_email">Email</label>
                <input id="search_email" type="email" class="form-control" v-model="queries.email">
              </div>

              <div class="form-group">
                <label for="search_phone">Phone</label>
                <input id="search_phone" type="tel" class="form-control" v-model="queries.phone">
              </div>

              <div class="form-group">
                <label for="search_language">Language</label>
                <select
                    id="search_language"
                    v-model="queries.language"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(language, language_index) in languages">
                    <option
                        :key="'language_'+language_index"
                        :value="language.key"
                    >
                      {{ language.label }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="role">Role</label>
                <select
                  id="role"
                  v-model="queries.role"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(role, index) in roles">
                    <option
                      :key="'role_'+index"
                      :value="role"
                    >
                      {{ role }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_super_admin"
                    v-model="queries.super_admin"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="search_super_admin"
                >Super Admin</label>
              </div>


              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->
      <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <template v-for="(column, index) in columns">
                    <th :key="'column_'+index">
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="(record, index) in records">
                    <tr :key="'user_'+index">
                      <td><a :href="'/'+$route.meta.base_url+'/'+record.uuid">{{ record.name }}
                        <template v-if="!record.name">
                          {{ record.uuid }}
                        </template>
                      </a></td>
                      <td>{{ record.phone }}
                      <template v-if="record.phone_ext">
                        {{ record.phone_ext }}
                      </template>
                      </td>
                      <td>{{ record.email }}
                        <template v-if="record.email">
                          <button type="button" class="btn btn-secondary btn-sm" @click="copyToClipboard(record.email)" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.language">
                          {{ setLanguageName(record) }}
                        </template>
                      </td>
                      <td>
                        <template v-if="record.super_admin === 1">
                          <span class="badge badge-pill badge-success"><i class="fa fa-check"></i> Super Admin</span>
                        </template>
                        <template v-else>
                          <span class="badge badge-pill badge-secondary"><i class="fa fa-times"></i> Super Admin</span>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.roles && record.roles.length">
                          <template v-for="role in record.roles">
                            <span
                              :key="role"
                              class="badge badge-pill badge-primary mr-1"
                            >{{ role }}</span>
                          </template>
                        </template>
                      </td>

                      <td><total-users :user="record.uuid" :url="'clients'"></total-users></td>

                      <td>
                        <template v-if="record.last_active">
                          {{ record.last_active | datetime }}
                          <br><span class="text-muted small">{{ record.last_active | dateTimezone | timeago }}</span>
                        </template>
                        <template v-else>
                          <span class="badge badge-pill badge-danger">Not Active</span>
                        </template>
                      </td>

                      <td>
                        <template v-if="record.created_at">
                          {{ record.created_at | datetime }}
                          <br><span class="text-muted small">{{ record.created_at | dateTimezone | timeago }}</span>
                        </template>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

            <template v-if="!processing && (!records || !records.length)">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-outline-primary d-print-none mt-3"
              :disabled="processing"
              @click="getRecords"
            >
              Load more
            </button>
          </div>
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from "lodash";

export default {

    mixins: [ queries ],

    data () {
        return {
            processing: false,
            downloading: false,
            records: [],
            downloads: [],
            queries: {},
            current_user: {},
            total: 0,
            columns: [
              {label: 'name', key: 'name'},
              {label: 'phone', key: 'phone'},
              {label: 'email', key: 'email'},
              {label: 'language', key: 'language'},
              {label: 'Super Admin', key: 'super_admin'},
              {label: 'roles', key: 'roles'},
              {label: 'clients'},
              {label: 'last active', key: 'last_active'},
              {label: 'created', key: 'created_at'}
            ],
            roles: [],
            languages: [],
            form: {}
        }
    },

    created () {
      this.resetQueries();
      this.setURLparams();
      this.resetForm();
      this.getRoles();
      this.getRecords();
      this.getLanguages();
    },

    methods: {

      setURLparams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
          self.queries[key] = value;
        });

      },

      resetQueries() {
        this.queries = {
          search: null,
          email: null,
          phone: null,
          language: null,
          role: null,
          sort: 'created_at',
          sortDirection: 'desc',
          limit: 20,
          skip: 0,
          super_admin: false
        }
      },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        getRecords () {

            this.processing = true;

            this.updateURL();
            this.getTotal();

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.data) {

                    if(response.data.length) {

                        var self = this;

                        response.data.forEach(function(data) {
                            self.records.push(data);
                        });
                    }

                    this.queries.skip = this.records.length;

                    this.processing = false;

                }
            })
        },

        resetRecords() {
            this.records = [];
            this.queries.skip = 0;
            this.total = 0;
        },

        resetForm() {
          this.form = {
            name: null,
            email: null,
            phone: null,
            roles: null,
            language: 'en',
            super_admin: true,
            notify: true
          }
        },

        postForm () {
          this.processing = true;
          this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if (response && response.data) {

              if(response.status === 201) {
                this.records.unshift(response.data);
                new this.$noty({text: this.$route.meta.title + ' added'}).show();
                this.total++;
                this.resetForm();
              }

              this.processing = false
            }
          })
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
        },

        updateSortDirection() {

            if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
            } else {
            this.queries.sortDirection = 'asc';
            }

            this.resetRecords();

            this.getRecords();
        },

        sortColumn(column) {

            this.queries.sort = column.key;

            this.updateSortDirection();
        },

        getRoles() {
            this.$http.get('/roles?slim=true&limit=100&fields=name&sort=name&sortdirection=asc')
            .then(response => {
                if(response && response.data) {
                    this.roles = response.data.map(r => r.name);
                }
            })
        },

        copyToClipboard(value) {
            navigator.clipboard.writeText(value);
            new this.$noty({text: 'Copied to clipboard'}).show();
        },

      getLanguages () {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
            .then(response => {
              if(response && response.data) {
                this.languages = response.data;
              }
            })
      },

      async downloadPDF() {
        try {

          window.scrollTo(0,0);

          this.downloading = true;

          const element = document.getElementById('main');

          const canvas = await this.$html2canvas(element, {
            windowWidth: element.scrollWidth,
            windowHeight: element.scrollHeight,
            scrollY: -window.scrollY,
            scrollX: -window.scrollX,
            scale: window.devicePixelRatio,
            useCORS: true,
            logging: false
          });

          const imgData = canvas.toDataURL('image/png');

          const pdf = new this.$jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: [canvas.width, canvas.height]
          });

          pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

          var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

          pdf.save(name+'.pdf');

          this.downloading = false;
        } catch (error) {
          new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
          console.log(error);
          this.downloading = false;
        }
      },

      downloadCSV () {
        this.downloading = true;

        var queries = this.queries;

        queries.limit = this.total;

        queries.skip = this.downloads.length;

        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
            .then(response => {
              if(response && response.data && response.data) {
                this.downloads = this.downloads.concat(response.data);

                var csvFile, data, link;

                data = this.convertCSV(this.downloads);

                var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

                csvFile = new Blob([data], {type: "text/csv"});
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveBlob(csvFile, file);
                } else {
                  link = document.createElement("a");
                  link.download = file;
                  link.href = window.URL.createObjectURL(csvFile);
                  link.style.display = "none";
                  document.body.appendChild(link);
                  link.click();
                }

                this.downloading = false;

              } else {
                this.downloadCSV();
              }

            });
      },

      convertCSV(records) {

        var results = [];

        if(records[0] && records[0]['uuid']) {
          var headers = Object.keys(records[0]);
        }

        results += headers.join(',')

        results += '\n';

        records.forEach(function(item) {
          var record = '';

          if(!item) {
            return;
          }

          Object.keys(item).forEach(function(key) {
            record += '"' + (item[key] ? item[key] : '') + '",';
          });

          results += record;
          results += '\n';
        });

        return results
      },

      setLanguageName(record) {

        if(!record.language) {
          return;
        }

        var language = _.find(this.languages, {'key': record.language});

        if(!language || !language.label) {
          return record.language;
        }

        return language.label;
      },

    }
}
</script>   