<template>
  <div>
    <div class="row mb-3">
      <div class="col-sm-12 col-md-6">
        <p class="card-title">
          <a :href="'/messages?client='+client_uuid+'&event='+event_uuid+'&payment='+payment_uuid" class="text-decoration-none">
          {{ records.length }} of {{ total.toLocaleString() }}
          Scheduled Messages
          </a>
        </p>
      </div>
      <div class="col-sm-12 col-md-6 text-right">
        <div class="btn-group">
          <button
            type="button"
            id="refreshMessages"
            class="btn btn-outline-secondary"
            data-tooltip="Refresh messages"
            :disabled="processing"
            @click="refreshRecords"
          >
            <i class="fa fa-sync" /> <small class="sr-only">Refresh Messages</small>
          </button>
          <div class="btn-group">
            <button
              id="filters"
              class="btn btn-outline-secondary"
              type="button"
              data-toggle="dropdown"
              data-tooltip="Filter messages"
              aria-haspopup="true"
              aria-expanded="false"
              :disabled="processing"
            >
              <i class="fa fa-filter" /> <small class="sr-only">Filter</small>
            </button>
            <div
              class="dropdown-menu"
              aria-labelledby="filters"
            >
              <h6 class="dropdown-header">
                Filter By
              </h6>
              <a
                v-for="filter in filters"
                :key="filter.key"
                class="dropdown-item text-capitalize"
                :class="{ active: isActiveFilter(filter.key) }"
                @click="setFilters(filter.key)"
              >{{ filter.title }}</a>
            </div>
          </div>
          <div class="btn-group">
            <div
              class="dropdown-menu"
              aria-labelledby="sorts"
            >
              <h6 class="dropdown-header">
                Sort By
              </h6>
              <template v-for="(sort, index) in sorts">
                <a
                  :key="'sort_'+index"
                  class="dropdown-item text-capitalize"
                  :class="{ active: isActiveSort(sort) }"
                  @click="setSort(sort)"
                >{{ sort.title }}</a>
              </template>
            </div>
            <button
              id="sorts"
              class="btn btn-outline-secondary"
              type="button"
              data-toggle="dropdown"
              data-tooltip="Sort messages"
              aria-haspopup="true"
              aria-expanded="false"
              :disabled="processing"
            >
              <i class="fa fa-sort" /> <small class="sr-only">Sort</small>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="list-group">
      <template v-for="record in records">
        <div :key="record.uuid">
          <router-link
            class="list-group-item list-group-item-action"
            :to="{ 'name': 'messages.edit', 'params': {'id': record.uuid} }"
          >
            <p class="outbound p-3">
              {{ record.content }}
            </p>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p
                  v-if="record.to"
                  class="m-0"
                >
                  <strong>To</strong> {{ record.to }}
                </p>

                <template v-if="record.test && record.test == '1'">
                <span class="text-small badge badge-light mr-1">SIMULATE</span>
              </template>
              </div>
              <div class="col-sm-12 col-md-6">
                <p
                  v-if="record.scheduled_at"
                  class="m-0"
                >
                  <strong>Scheduled</strong> 

                  {{ record.scheduled_at | datetime }} 

                  <small class="text-muted">{{ record.scheduled_at | dateTimezone | timeago }}</small>
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </template>
    </div>

    <template v-if="records && records.length && total > records.length">
      <div class="row mt-3">
        <div class="col-12 text-center">
          <button
            type="button"
            class="btn btn-outline-primary mt-3 mb-3"
            :disabled="processing"
            @click="loadMore"
          >
            Load more
          </button>
        </div>
      </div>
    </template>

    <template v-if="!processing && !records.length">
      <div class="alert alert-warning">
        No scheduled messages found.
      </div>
    </template>
  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {

    mixins: [ queries ],

    props: ['client_uuid', 'event_uuid', 'payment_uuid'],

    data() {
        return {
            processing: false,
            show_load: false,
            base_url: '/messages',
            records: [],
            total: 0,
            filters: [
            {key: 'text', title: 'TEXTS'},
            {key: 'email', title: 'EMAILS'},
            {key: 'voice', title: 'CALLS'}
            ],
            sorts: [
            {key:'scheduled_at', direction: 'asc', title: 'Earliest Schedule Date'},
            {key:'scheduled_at', direction: 'desc', title: 'Latest Schedule Date'}
            ],
            queries: {}
        }
    },

    mounted() {

        var self = this;
        
        this.$eventBus.$on('searchMessages', function (search) {
            self.queries.search = search;
            self.refreshRecords();
        });

        this.$eventBus.$on('messageScheduled', function (record) {
          self.pushRecord(record);
        });

        this.$eventBus.$on('eventSynced', function (uuid) {
          self.syncEvent(uuid);
        });
    },

    created() {
        this.resetQueries();
        this.getRecords();
    },

    methods: {

        resetQueries() {
          this.queries = {
              filters: ['scheduled', 'outbound'],
              fields: 'content,uuid,scheduled_at,to,from,channel,type,test,subject,language,client,event,payment',
              sort: 'scheduled_at',
              sortDirection: 'asc',
              search: '',
              limit: 10,
              total: false,
              skip: 0,
              slim: true,
              client: null,
              event: null,
              payment: null
          }
        },

        pushRecord(record) {

          if(this.client_uuid) {
            if(this.client_uuid != record.client) {
              return;
            }
          }


          if(this.event_uuid) {
            if(this.event_uuid != record.event) {
              return;
            }
          }


          if(this.payment_uuid) {
            if(this.payment_uuid != record.payment) {
              return;
            }
          }

          var index = _.findIndex(this.records, {'uuid': record.uuid});

          if(index != -1) {
            return;
          }

          this.total++;
          this.records.unshift(record);
          this.sortRecords();
        },

        resetRecords() {
            this.queries.skip = 0;
            this.total = 0;
            this.records = [];
        },

        refreshRecords() {
            this.getRecords();
            this.$eventBus.$emit('messagesRefreshed');
        },

        updateQueries() {

            if(this.client_uuid) {
                this.queries.client = this.client_uuid;
            }

            if(this.event_uuid) {
              this.queries.event = this.event_uuid;
            }

            if(this.payment_uuid) {
              this.queries.payment = this.payment_uuid;
            }

        },

        getRecords() {

            this.processing = true;

            this.updateQueries();

            this.resetRecords();

            this.getTotal();

            this.$http.get(this.buildQueries(this.base_url, this.queries))
            .then(response => {
                if(response && response.status == 200) {

                    this.processing = false;

                    if(response.data.data && response.data.data.length) {
                        var self = this;

                        response.data.data.forEach(function(data) {
                            self.records.push(data);
                        });
                    }

                }
            })
        },

        getTotal() {
            this.$http.get(this.buildQueries(this.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status === 200) {
                    this.total = response.data;
                }
            })
        },

        sortRecords() {

            if(!this.records) {
              return;
            }

            var sort = this.queries.sortDirection || 'asc';

            this.records = _.orderBy(this.records, ['scheduled_at'], [sort]);
        },

        isActiveSort(sort) {

            if(this.queries.sort === sort.key && this.queries.sortDirection === sort.direction) {
                return true;
            }

            return false;
        },

        setSort(sort) {

            if(sort.key == this.queries.sort) {
                this.queries.sort = null;
            } else {
                this.queries.sort = sort.key;
                this.queries.sortDirection = sort.direction;
            }

            this.getRecords();
        },

        isActiveFilter(filter) {
            if(this.queries.filters.includes(filter)) {
                return true;
            }
        },
        
        setFilters(filter) {
            
            if(this.queries.filters.includes(filter)) {
                this.queries.filters = _.remove(this.queries.filters, function(item) {
                    return item != filter;
                });
            } else {
                this.queries.filters.push(filter);
            }

            this.getRecords();
        },

        loadMore() {
            this.processing = true;
            if(this.records && this.records.length) {

                this.queries.skip = this.records.length;

                this.$http.get(this.buildQueries(this.base_url, this.queries))
                .then(response => {
                    if(response && response.data && response.data.data && response.data.data.length) {
                        if(response.data.data.length < this.queries.limit) {
                            this.show_load = false
                        }
                        response.data.data.forEach(r => {  
                            this.records.push(r);
                        });
                        this.processing = false;
                    } else {
                        this.show_load = false;
                    }
                });
            }
        },

      syncEvent(uuid) {

          var records = [];

          var self = this;

          this.records.forEach(function(record) {
            
              if(record.event == uuid) {
                  self.total--;
                  return;
              }

              records.push(record);
          });

          this.records = records;
      }

    }
}

</script>