<template>
  <div>

    <page-tabs page="portals" />

    <div class="row">
      <div class="col-sm-12 col-md-3 d-print-none" data-html2canvas-ignore>
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Generate {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">
              <div class="form-group">
                <label for="content">QR Code Content</label>
                <input
                    id="content"
                    v-model="form.content"
                    class="form-control"
                    required
                >
              </div>

              <div class="form-group">
                <label for="logo">Logo URL</label>
                <input id="logo" class="form-control" type="text" v-model="form.logo">
              </div>

              <template v-if="form.format == 'pdf'">

              <div class="form-group">
                <label for="title">Title</label>
                <input id="title" class="form-control" type="text" v-model="form.title">
              </div>

              <div class="form-group">
                <label for="description">Description</label>
                <textarea id="description" class="form-control" v-model="form.description"></textarea>
              </div>

              <div class="form-group">
                <label for="amount">Payment Amount</label>
                <input id="amount" type="number" class="form-control" step="0.01" v-model="form.amount">
              </div>

                <div class="form-group">
                  <label for="language">Language</label>
                  <select
                      id="language"
                      v-model="form.language"
                      class="form-control"
                      :disabled="processing"
                  >
                    <option value="">
                      Choose Language
                    </option>
                    <option value="en">English</option>

                    <template v-for="(language, language_index) in languages">

                      <option
                          :key="'language_'+language_index"
                          :value="language.key"
                          :hidden="language.key == 'en'"
                      >
                        {{ language.label }}
                      </option>

                    </template>

                  </select>
                </div>

              </template>

              <div class="form-group">
                <fieldset>
                  <legend>Format</legend>

                  <label
                      class="mr-2 text-capitalize"
                      for="pdf"
                  >
                    <input
                        id="pdf"
                        v-model="form.format"
                        type="radio"
                        name="pdf"
                        value="pdf"
                        @change="postForm()"
                        :disabled="processing"
                    > PDF
                  </label>

                  <label
                      class="mr-2 text-capitalize"
                      for="svg"
                  >
                    <input
                        id="svg"
                        v-model="form.format"
                        type="radio"
                        name="svg"
                        value="svg"
                        @change="postForm()"
                        :disabled="processing"
                    > SVG
                  </label>
                  <label
                      class="mr-2 text-capitalize"
                      for="png"
                  >
                    <input
                        id="png"
                        v-model="form.format"
                        type="radio"
                        name="png"
                        value="png"
                        @change="postForm()"
                        :disabled="processing"
                    > PNG
                  </label>

                </fieldset>
              </div>


              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                Generate
              </button>

            </form>

            <template v-if="qr_code">
              <button type="button" class="btn btn-outline-danger my-3" @click="resetQR()" :disabled="processing">Reset</button>
            </template>

            <template v-if="errors && errors.message">
              <div v-if="errors.message != 'Network Error'">
                <div class="alert alert-danger mt-3 mb-0">
                  {{ errors.message }}
                </div>
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <template v-if="qr_code && form.format != 'pdf'">
          <div class="btn-group d-print-none" data-html2canvas-ignore>
          <button type="button" class="btn btn-primary" @click="downloadFile()" :disabled="processing || downloading"><i class="fa file-arrow-down"></i> Download {{ form.format }}</button>
          </div>
        </template>



        <template v-if="qr_code && form.format == 'svg'">
          <img
              :src="'data:image/svg+xml;base64,'+qr_code"
              width="100%"
              height="100%"
          >
        </template>

        <template v-if="qr_code && form.format == 'png'">
          <img
              :src="'data:image/png;base64,'+qr_code"
              width="100%"
              height="100%"
          >

          <template v-if="form.content">
            {{ form.content }}
          </template>

        </template>

        <template v-if="qr_code && form.format == 'pdf'">
          <iframe :src="qr_code" width="100%" height="960" frameborder="0" border="0px"></iframe>
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      downloading: false,
      errors: null,
      form: {},
      qr_code: null,
      settings: {},
      languages: []
    }
  },

  mounted() {
    var self = this;

    this.$eventBus.$on('errors', function (errors) {
      self.errors = errors.data || errors;
      self.processing = false;
    });
  },

  created() {
    this.resetForm();
    this.setURLParams();

    this.setTitle();

    if(localStorage.getItem('settings')) {
      this.settings = JSON.parse(localStorage.getItem('settings'));
    }

    if(this.settings['icon'] && this.settings['icon'].length && !this.form.logo) {
      this.form.logo = this.settings['icon'];
    }

    if(this.settings['logo'] && this.settings['logo'].length && !this.form.logo) {
      this.form.logo = this.settings['logo'];
    }

    if(this.form.content) {
      this.postForm();
    }

    this.getLanguages();
  },

  methods: {

    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.form[key] = value;
      });
    },

    resetForm() {
      this.form = {
        content: null,
        logo: null,
        title: null,
        description: null,
        amount: null,
        language: 'en',
        format: 'pdf'
      }
    },


    resetQR() {
      this.resetForm();
      this.qr_code = null;
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.form));
    },

    setTitle() {
      document.title = this.$route.meta.title + 's | eCourtDate.com';
    },

    postForm() {

      if(!this.form.content) {
        new this.$noty({text: 'Content is required', type: 'error'}).show();
        return;
      }

      this.qr_code = null;
      this.errors = null;
      this.processing = true;

      this.updateURL();

      this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              new this.$noty({text: this.$route.meta.title + ' generated'}).show();

              this.qr_code = response.data;

            }
          })
    },

    downloadFile() {
      this.downloading = true;

      const byteCharacters = atob(this.qr_code);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      var mimeType = 'image/svg+xml';

      if(this.form.format == 'png') {
        mimeType = 'image/png';
      }

      const blob = new Blob(byteArrays, {type: mimeType});

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;

      var title = this.$options.filters.slugString(this.form.content);

      title = title.replace(/-/g, ' ');

      a.download = 'qr-code-'+title+'.'+this.form.format;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      this.downloading = false;
    },

    async downloadPDF() {
      try {

        window.scrollTo(0,0);

        this.downloading = true;

        const element = document.getElementById('main');

        const canvas = await this.$html2canvas(element, {
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          scale: window.devicePixelRatio,
          useCORS: true,
          logging: false
        });

        const imgData = canvas.toDataURL('image/png', 0.3);

        const pdf = new this.$jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height, undefined, 'FAST');

        var title = this.$options.filters.slugString(this.form.content);

        title = title.replace(/-/g, ' ');

        pdf.save(title+'.pdf');

        this.downloading = false;
      } catch (error) {
        new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
        console.log(error);
        this.downloading = false;
      }
    },

    generatePDF(data) {

      const blobUrl = window.URL.createObjectURL(data);

      // Create a hidden anchor element to trigger the download
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobUrl;

      // Set the filename for the downloaded file
      a.download = 'downloaded.pdf';

      // Append the anchor element to the document and trigger the download
      document.body.appendChild(a);
      a.click();

      // Clean up: remove the anchor element and revoke the blob URL
      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    },

    getLanguages () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
          .then(response => {
            if(response && response.data) {
              this.languages = response.data;
            }
          })
    }

  },
}
</script>