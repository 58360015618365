<template>
  <div>

    <page-tabs page="uploads" />
    
    <div class="row">
      <div class="col-lg-3 col-md-12 mb-3">

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>

            <form
              @submit.prevent="postForm"
            >
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>
       
              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords">
              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                  type="search"
                  placeholder="Search by name..."
                >
              </div>

              <div class="form-group">
                <label for="search_method">Method</label>
                <select
                  id="search_method"
                  v-model="queries.method"
                  class="form-control text-uppercase"
                  :disabled="processing"
                >
                  <option value=""></option>
                  <option
                    v-for="(method, method_index) in methods"
                    :key="'search_method_'+method_index"
                    :value="method.value"
                  >
                    {{ method.title }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="search_file_format">File Format</label>
                <select
                  id="search_file_format"
                  v-model="queries.file_format"
                  class="form-control text-uppercase"
                  :disabled="processing"
                >
                  <option value=""></option>
                  <option
                    v-for="file_format in file_formats"
                    :key="'search_file_format_'+file_format"
                    :value="file_format"
                  >
                    {{ file_format }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="created_by">Creator</label>
                <select 
                  id="created_by"
                  v-model="queries.created_by"
                  class="form-control" 
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(user, index) in users">
                    <option 
                      :key="'user_'+index" 
                      :value="user.email"
                    >
                      {{ user.name }} {{ user.email }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="archived"
                  v-model="queries.archived"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="archived"
                >Archived</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="trashed"
                >Trashed</label>
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
      <div class="col-lg-9 col-md-12">

        <div class="card">
          <div class="card-body">

            <p class="card-title">
              {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
            </p>


            <div class="table-responsive">
            <table class="table table-striped table-hover">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="$route.meta.base_url"
              />

              <tbody>
                <template v-for="(record, index) in records">
                  <tr :key="'upload_template_'+index">
                    <td>
                      <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                        <i class="fa fa-edit"></i> {{ record.name }}
                      </router-link>
                    </td>
                    <td>{{ record.method }}</td>
                    <td>{{ record.file_format }}</td>
                    <td>{{ record.created_by }}</td>
                    <td>{{ record.created_at | datetime }}
                      <template v-if="record.deleted_at">
                        <span class="badge badge-danger badge-pill">TRASHED</span>
                      </template>
                      <template v-if="record.archived_at">
                        <span class="badge badge-warning badge-pill">ARCHIVED</span>
                      </template>
                    </td>
                    <td>{{ record.updated_at | datetime }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="records && records.length < total">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary d-print-none"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      records: [],
      processing: false,
      total: 0,
      queries: {},
      form: {},
      methods: [
      {title: 'update by fields', value: 'update_fields'},
      {title: 'load and replace all uploads', value: 'load_replace_all_uploads'},
      {title: 'load and replace all uploads using this template', value: 'load_replace_all_template'}
      ],
      file_formats: ['csv', 'xlsx', 'json', 'txt', 'xml'],
      columns: [
          {key: 'name', label: 'Name'},
          {key: 'method', label: 'Method'},
          {key: 'file_format', label: 'File Format'},
          {key: 'created_by', label: 'Creator'},
          {key: 'created_at', label: 'Created'},
          {key: 'updated_at', label: 'Updated'}
      ],
      users: []
    }
  },

  created () {
    this.resetRecords();
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getRecords();
    this.getUsers();
  },

  methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    searchRecords() {
        this.resetRecords();
        this.getRecords();
    },

    resetForm() {
      this.form = {
        name: null,
        method: 'update_fields',
        delimiter: ',',
        separator: ',',
        date_format: 'auto',
        time_format: 'auto',
        default_group: null,
        fields_prefix: null,
        map_index: false,
        file_format: 'csv',
        auto_create_statuses: true
      }
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        skip: 0,
        sort: 'name',
        sortDirection: 'desc',
        file_format: null,
        created_by: null,
        method: null,
        archived: false,
        trashed: false,
        trigger: null,
        search: null
      }
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords () {

      this.processing = true;

      this.updateURL();

      this.totalRecords();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if (response && response.data) {

          this.processing = false;

          if(response.data.length) {

            this.queries.skip += response.data.length;

            var self = this;

            response.data.forEach(function(data) {
              self.records.push(data);
            });

          }
        }
      })
    },

    totalRecords() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    postForm() {
        this.processing = true;
        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
            if(response && response.status) {

                if(response.status === 201) {
                  this.resetForm();
                  this.total++;
                  this.setTitle();
                  this.records.unshift(response.data);
                  new this.$noty({text: this.$route.meta.title + ' added'}).show();
                }

                this.processing = false;

            }
        });
    },

    getUsers() {
      this.$http.get('/users?slim=true&limit=1000&fields=name,email&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.users = response.data;
        }
      })
    },


  }
}
</script>
