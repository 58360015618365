<template>
  <div>
    <template v-if="$route.meta.requiresAuth">
      <div class="row">
        <div class="col-12 mt-3 mx-auto text-center mb-2">
          <template v-if="$root.current_user && $root.current_user.current_agency">
            <template v-if="$root.current_user.current_agency.settings && $root.current_user.current_agency.settings.footer_text">
              <p class="text-center text-muted mt-3">
                {{ footer_text }}
              </p>
            </template>
            <a
              id="sendModeButton"
              class="badge badge-pill badge-primary text-uppercase d-print-none"
              :class="sendModeClass($root.current_user.current_agency.send_mode)"
              data-target="#sendModeModal"
              data-toggle="modal"
              href="#"
            >{{ sendMode }} Mode</a>

            <p
              class="m-0"
              :class="{ 'd-none': isHidden('about_agency') }"
            >
              <a
                data-toggle="modal"
                data-target="#agencyModal"
                href="#"
              >{{ $root.current_user.current_agency.name }}</a>
            </p>


          <p class="my-1" data-html2canvas-ignore>
            <a href="https://youtube.com/@eCourtDate" target="_blank" class="d-print-none text-decoration-none">
            <img src="https://ecdassets.s3.amazonaws.com/images/social/youtube.svg" width="20" height="20" class="mr-1" alt="YouTube">
            YouTube Channel</a>
          </p>


          <ul class="nav justify-content-center text-muted d-print-none" data-html2canvas-ignore>
            
            <template v-if="service_status">
            <li class="nav-item" style="z-index: 0">
              <a class="nav-link active small text-muted" 
              :href="'https://ecourtstatus.com?region='+$root.current_region" 
              target="_blank"
              :data-tooltip="$root.current_region"
              > 
                <i
                  class="fa fa-circle"
                  :class="service_status"
                />
                Status</a>
            </li>
            </template>

            <li class="nav-item d-print-none">
              <router-link tag="a" class="nav-link small text-muted" :to="{name: 'checklist.index'}">Checklist</router-link>
            </li>

            <template v-if="$root.current_user && $root.current_user.super_admin === 1">

            <li class="nav-item d-print-none" data-html2canvas-ignore>
              <a class="nav-link small text-muted" href="https://console.ecourtdate.com" target="_blank">Console</a>
            </li>

            </template>

            <li class="nav-item d-print-none">
              <a class="nav-link small text-muted" href="/referrals">Referrals</a>
            </li>

          </ul>

            <button type="button" class="btn btn-outline-danger btn-sm d-print-none mt-3" data-target="#getHelpModal" data-toggle="modal" :disabled="processing" :hidden="hide_help_form" data-html2canvas-ignore><i class="fa fa-exclamation-circle"></i> Report Issue</button>

            <template v-if="integration && integration.uuid">

              <p
                class="small text-muted m-0 d-print-none"
                :class="{ 'd-none': isHidden('about_agency') }"
              >
                <a
                  data-toggle="modal"
                  data-target="#integrationModal"
                  href="#"
                >Automated with {{ integration.name }}</a>
              </p>

            </template>

          </template>
        </div>
      </div>


      <template v-if="$root.current_user.current_agency">
        <div
          id="agencyModal"
          class="modal fade"
          tabindex="-1"
          role="dialog"
        >
          <div
            class="modal-dialog modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <p class="lead modal-title">
                  About This Agency
                </p>

                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
                <ul class="list-group mb-3">
                  <template v-if="$root.current_user.current_agency.name">
                    <li class="list-group-item">
                      Name: <span
                        class="link-hover"
                        @click="copyToClipboard($root.current_user.current_agency.name)"
                      >{{ $root.current_user.current_agency.name }}</span> 

                      <a
                        :href="'https://console.ecourtdate.com/agencies/'+$root.current_user.current_agency.uuid"
                        class="btn btn-outline-secondary btn-sm ml-1"
                        target="_blank"><i class="fa fa-link"></i> <span class="sr-only">Open Console</span>
                      </a>
                      <button type="button" class="btn btn-outline-secondary btn-sm" @click="copyToClipboard($root.current_user.current_agency.name)" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>

                    </li>
                  </template>

                  <template v-if="$root.current_user.current_agency.reference">
                    <li
                      class="list-group-item link-hover"
                      @click="copyToClipboard($root.current_user.current_agency.reference)"
                    >
                      Reference: {{ $root.current_user.current_agency.reference }}
                    </li>
                  </template>

                  <template v-if="$root.current_user.current_agency.uuid">
                    <li
                      class="list-group-item link-hover"
                      @click="copyToClipboard($root.current_user.current_agency.uuid)"
                    >
                      UUID: {{ $root.current_user.current_agency.uuid }}
                    </li>
                  </template>

                  <template v-if="$root.current_user.current_agency.customer">
                    <li class="list-group-item">
                      Billing: {{ $root.current_user.current_agency.customer }} 
                      
                      <a
                        :href="'https://billing.ecourtdate.com/dashboard?role=viewer&customer='+$root.current_user.current_agency.customer"
                        class="btn btn-outline-secondary btn-sm ml-1"
                        target="_blank"><i class="fa fa-link"></i> <span class="sr-only">Open Billing</span>
                      </a>

                      <button type="button" class="btn btn-outline-secondary btn-sm" @click="copyToClipboard($root.current_user.current_agency.customer)" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>

                    </li>
                  </template>

                  <template v-if="$root.current_user.current_agency.region">
                    <li class="list-group-item">
                      Region: {{ $root.current_user.current_agency.region }}
                    </li>
                  </template>

                  <template v-if="$root.current_user.current_agency.created_at">
                    <li class="list-group-item">
                      Created: {{ $root.current_user.current_agency.created_at | datetime }} - {{ $root.current_user.current_agency.created_at | dateTimezone | timeago }}
                    </li>
                  </template>
                </ul>

              </div>

            </div>
          </div>
        </div><!--Modal-->


        <div
          id="sendModeModal"
          class="modal fade"
          tabindex="-1"
          role="dialog"
        >
          <div
            class="modal-dialog modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  Send Mode
                </h5>

                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">

                <template v-if="$root.current_user && $root.current_user.current_agency && $root.current_user.current_agency.region === 'staging'">
                  <div class="alert alert-warning">Live Mode is disabled in staging.</div>
                </template>


                <template v-if="sendMode == 'live'">
                  <p class="lead">
                    Send Mode is currently <span class="badge badge-success">LIVE</span>. Outbound messages will be sent to the intended recipient.
                  </p>
                </template>

                <template v-if="sendMode == 'simulate'">
                  <p class="lead">
                    Send Mode is currently <span class="badge badge-primary">SIMULATE</span>. Outbound messages will be simulated and not actually sent to the intended recipient.
                  </p>
                </template>
              </div>

              <div class="modal-footer">
                <loader :is-loading="processing" />

                <template v-if="!processing && $root.current_user">
                  <template v-if="$root.current_user.super_admin === 1">
                      <template v-if="sendMode == 'simulate'">
                        <button
                          type="button"
                          class="btn btn-success"
                          @click="toggleSendMode"
                          :disabled="processing"
                        >
                          Switch to LIVE Mode
                        </button>
                    </template>                                

                    <template v-if="sendMode == 'live'">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        @click="toggleSendMode"
                        :disabled="processing"
                      >
                        Switch to SIMULATE Mode
                      </button>
                    </template>
                  </template>
                </template>

              </div>
            </div>
          </div>
        </div><!--Modal-->

        <template v-if="integration && integration.uuid">

        <div
          id="integrationModal"
          class="modal fade"
          tabindex="-1"
          role="dialog"
        >
          <div
            class="modal-dialog modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <p class="lead modal-title">
                  Automated with {{ integration.name }}
                </p>

                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">

                <template v-if="integration.logo">
                  <img :src="'https://ecdassets.s3-us-gov-east-1.amazonaws.com/img/integrations/'+integration.logo" width="200">
                </template>

                <p class="mt-3">This eCourtDate agency is automatically integrated with {{ integration.name }}.</p>

                <hr>

                <ul class="list-group mt-3">

                <li class="list-group-item"><strong>Integration Landing Page</strong> <a :href="'https://ecourtdate.com/partners/'+integration.reference" data-tooltip="Coming soon" class="btn btn-outline-secondary" target="_blank"><i class="fa fa-link"></i></a></li>

                <template v-if="integration.reference">
                  <li class="list-group-item"><strong>Integration Support Email</strong> <a @click="copyToClipboard($root.current_user.current_agency.name)">{{ integration.reference }}@ecourtdate.com</a></li>
                </template>

                <template v-if="integration.frequency">
                  <li class="list-group-item"><strong>Integration Frequency</strong> {{ integration.frequency }}</li>
                </template>

                </ul>
                
              </div>

              <div class="modal-footer">

              </div>
            </div>
          </div>
        </div><!--IntegrationModal-->

        </template>

      </template>

      <div
          id="getHelpModal"
          ref="getHelpModal"
          class="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="getHelpTitle"
          aria-hidden="true"
          :hidden="hide_help_form"
      >
        <div
            class="modal-dialog modal-dialog-scrollable modal-lg"
            role="document"
        >
          <div class="modal-content">
            <div class="modal-header">

              <h5
                  id="getHelpTitle"
                  class="modal-title"
              >
                Report Issue
              </h5>

              <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">

              <template v-if="help_ticket && help_ticket.uuid">
                <div class="alert alert-success"><a :href="'https://help.ecourtdate.com/tickets/'+help_ticket.uuid" class="alert-link" target="_blank">✅ We've created a help ticket here.</a></div>
              </template>

              <form @submit.prevent="postHelpForm()">

                <div class="form-group">
                  <label for="description">Issue Description</label>
                  <textarea id="description" class="form-control" v-model="helpForm.description" rows="5" required></textarea>
                </div>

                <div class="form-group custom-control custom-switch">
                  <input
                    id="helpPriority"
                    v-model="helpForm.priority"
                    type="checkbox"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="helpPriority"
                  > High Priority</label>
                </div>

                <button type="submit" class="btn btn-danger" :disabled="processing">Report Issue</button>

              </form>
            </div><!--Body-->

            <div class="modal-footer">
              <p><a :href="'https://help.ecourtdate.com?region='+$root.current_region" target="_blank"><i class="fa fa-link"></i> Go to Help Center</a></p>
            </div><!--Footer-->

          </div>
      </div><!--GetHelpModal-->

  </div>

    </template>
  </div>
</template>
<script>
import jsonParse from '../mixins/jsonParse';

export default {
    mixins: [ jsonParse ],

    data() {
        return {
            processing: false,
            hide_help_form: false,
            service_status: '',
            integration: {},
            helpForm: {
              description: null
            },
            help_ticket: {}
        }
    },

    computed: {

        sendMode: function () {
            if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.send_mode) {
                return this.$root.current_user.current_agency.send_mode;
            }

            return '';
        },

        footer_text: function () {
            if (this.$root && this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.footer_text) {
                return this.$root.current_user.current_agency.footer_text
            }
            return null;
        }
    },

    mounted() {

      var self = this;

      this.$eventBus.$on('settings', function (data) {

        if(data.send_mode) {
          self.$root.current_user.current_agency.send_mode = data.send_mode;
        }

      });

    },

    created () {

        this.resetHelpForm();
        this.getServiceStatus();

    },

    methods: {

        isHidden(navigation) {

            if(!this.$root.current_user || !this.$root.current_user.uuid) {
                return true;
            }

            if(this.$root.current_user.hidden_navigations && this.$root.current_user.hidden_navigations.length > 0) {
                return this.$root.current_user.hidden_navigations.includes(navigation);
            }

            return false;
        },

        sendModeClass(send_mode) {
            
            if(send_mode === 'live') {
                return 'bg-success';
            }

            return 'bg-primary';
        },

        toggleSendMode() {

            let sendMode = 'live';

            if(this.sendMode == 'live') {
                sendMode = 'simulate';
            }

            let form = {};

            form.send_mode = sendMode;

            this.processing = true;

            this.$http.put('/settings', form)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    new this.$noty({text: 'Send Mode updated to ' + sendMode.toUpperCase()}).show();
                    this.$router.go();
                }
            })

        },

        copyToClipboard(value) {
            navigator.clipboard.writeText(value);
            new this.$noty({text: 'Copied to clipboard'}).show();
        },

        getServiceStatus() {

            if(!this.$root.current_region) {
              return;
            }

            this.$axios.get('https://api.ecourtstatus.com/status?region='+this.$root.current_region)
            .then(response => {
                if(response && response.data && response.data.status) {
                    if(response.data.status === 'up') {
                        this.service_status = 'text-success';
                    }
                    if(response.data.status === 'down') {
                        this.service_status = 'text-danger';
                    }

                    this.getIntegrationStatus();

                }
            })
        },


        getIntegrationStatus() {

            if(!this.$root.current_user || !this.$root.current_user.current_agency) {
              return;
            }

            var partner = this.$root.current_user.current_agency.partner;

            if(!partner) {
              return;
            }

            this.integration = {};

            this.$http.get('/partners/'+this.$root.current_user.current_agency.partner)
            .then(response => {
                if(response && response.data && response.data.uuid) {
                   this.integration = response.data;
                } else {
                  this.integration = {};
                }
            })
        },

        resetHelpForm() {
          this.helpForm = {
            description: null,
            priority: false
          }
        },

      postHelpForm() {

        this.processing = true;

        this.help_ticket = null;

        this.helpForm.ticket_url = window.location.href;

        this.$http.post('/help_tickets', this.helpForm)
            .then(response => {
              if(response && response.status === 201) {
                this.processing = false;

                this.resetHelpForm();

                this.help_ticket = response.data;

                if(response.data.message) {
                  new this.$noty({text: response.data.message}).show();
                }
              }

            })
      }
    
    },

}
</script>
