import { render, staticRenderFns } from "./LocationsUpload.vue?vue&type=template&id=cf2d6bf0"
import script from "./LocationsUpload.vue?vue&type=script&lang=js"
export * from "./LocationsUpload.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports