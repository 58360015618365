<template>
  <div>
    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
    <div class="row my-3">
      <div class="col-sm-12 col-md-4">
        <h1>
          <template v-if="form && form.date">
            {{ form.date | date }} {{ form.time | time }}
          </template>
        </h1>
        <template v-if="form && form.date && form.time">
          <span class="badge badge-pill badge-secondary mr-1 text-uppercase">{{ setDateDiff(form) }}</span>
        </template>
        <template v-if="form.event_reference">
          <span class="badge badge-pill badge-secondary link-hover mr-1" @click="copyToClipboard(form.event_reference)">Event Ref {{ form.event_reference }}</span>
        </template>
        <template v-if="form.case_number">
          <span class="badge badge-pill badge-secondary link-hover" @click="copyToClipboard(form.case_number)">Case Number {{ form.case_number }}</span>
        </template>
        <p class="my-1"><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-2">

        <template v-if="form.url && form.url.includes('http')">
            <a :href="form.url" target="_blank" class="btn btn-secondary-outline" :disabled="processing"><i class="fa fa-link"></i> Event Link</a>
        </template>

        <template v-if="cmsURL">
            <a :href="cmsURL" target="_blank" class="btn btn-secondary-outline" :disabled="processing"><i class="fa fa-link"></i> CMS</a>
        </template>

        <div class="btn-group">
            <div class="dropdown">
              <button
                id="dropdownMenuButton"
                class="btn btn-outline-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :disabled="processing"
              >
                Add to Calendar
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >

              <a class="dropdown-item"
              @click="saveICS()"
              target="_blank">
                iCalendar <small>ics</small>
              </a>

                <template v-for="(calendar_link, index) in calendar_links">
                  <a
                    :key="'calendar_link_'+index"
                    class="dropdown-item text-capitalize"
                    :href="calendar_link.link"
                    target="_blank"
                  >{{ calendar_link.title }}</a>
                </template>
              </div>
            </div>
        </div>
      </div><!--Col-->

      <div class="col-sm-12 col-md-2">
        <template v-if="form.url && !form.portal">
            <portal-links :url="'e/'+form.url"></portal-links>
        </template>

        <template v-if="form.url && form.portal">
          <portal-link :url="'e/'+form.url" :uuid="form.portal" />
        </template>
        
      </div><!--Col-->

      <div class="col-sm-12 col-md-2">
        <div class="btn-group">
          <button type="button" class="btn btn-outline-success" @click="updateAppeared('appeared')" :disabled="processing">Appeared</button>
          <button type="button" class="btn btn-outline-danger" @click="updateAppeared('fta')" :disabled="processing">Failed to Appear</button>
        </div><!--Group-->

        <template v-if="form.reached && form.reached == 1">
          <span class="badge badge-pill badge-success">REACHED</span>
        </template>

        <template v-if="form.reached && form.reached != 1 && unreachables && unreachables[form.reached]">
          <span class="badge badge-pill badge-danger">{{ unreachables[form.reached] }}</span>
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-2 text-right">
        <div class="btn-group">

          <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>

          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">
            <a
              v-if="!form.archived_at && !form.deleted_at"
              class="dropdown-item"
              @click="syncRecord"
            >Sync Messages</a>
            <a
                v-if="!form.archived_at && !form.deleted_at"
                class="dropdown-item"
                @click="duplicateRecord"
            >Duplicate</a>
            <a
              v-if="!form.archived_at"
              class="dropdown-item"
              @click="archiveRecord"
            >Archive</a>
            <a
              v-if="form.deleted_at || form.archived_at"
              class="dropdown-item"
              @click="restoreRecord"
            >Restore</a>
            <a
              v-if="!form.deleted_at"
              class="dropdown-item"
              @click="trashRecord"
            >Trash</a>
          </div>

          <button
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
          <div class="card-body">

            <div class="row">
                <div class="col-6">
                    <p class="card-title">
                      Edit {{ $route.meta.title }}
                    </p>
                </div><!--Col-->
                <div class="col-6 text-right">
                    <template v-if="form.status">
                        <disable-status-button :status="form.status"></disable-status-button>
                    </template>
                </div><!--Col-->
            </div><!--Row-->

    <form @submit.prevent="postForm">
      <div class="form-group custom-control custom-switch">
        <input
          id="virtual"
          v-model="form.virtual"
          type="checkbox"
          class="custom-control-input"
        >
        <label
          class="custom-control-label"
          for="virtual"
        >Virtual</label>
      </div>

      <div class="row">

        <div class="col-sm-12 col-md-6">

          <div class="form-group">
            <label for="date" class="form-label">Start Date</label>
            <input
              id="date"
              v-model="form.date"
              type="date"
              class="form-control"
            >
          </div>

        </div><!--Col-->

        <div class="col-sm-12 col-md-6">

          <div class="form-group">
            <label for="end_date" class="form-label">End Date</label>
            <input id="end_date" type="date" class="form-control" v-model="form.end_date">
          </div>

        </div><!--Col-->

      </div><!--Row-->

      <div class="row">
        <div class="col-sm-12 col-md-6">

          <div class="form-group">
            <label for="time">Start Time</label>
            <input
              id="time"
              v-model="form.time"
              type="time"
              class="form-control"
            >
          </div>

        </div><!--Col-->

        <div class="col-sm-12 col-md-6">

          <div class="form-group">
            <label for="end_time">End Time</label>
            <input
              id="end_time"
              v-model="form.end_time"
              type="time"
              class="form-control"
            >
          </div>

        </div><!--Col-->

      </div><!--Row-->


          <div class="form-group">
            <label for="event_reference">Event Reference</label>
            <input
              id="event_reference"
              v-model="form.event_reference"
              type="text"
              class="form-control"
            >
          </div>


      <div class="form-group">
        <label for="flow">Flow

        <template v-if="form.flow">
          <a
          :href="'/flows/'+form.flow"
          target="_blank"
          data-tooltip="Edit Flow">
          <i class="fa fa-edit"></i> <span class="sr-only">Edit Flow</span>
          </a>
        </template>

        </label>
        <select
          id="flow"
          v-model="form.flow"
          class="form-control"
          :disabled="processing"
        >
          <option value="" />
          <template v-for="(flow, index) in flows">
            <option
              :key="'flow_'+index"
              :value="flow.uuid"
            >
              {{ flow.name }}
            </option>
          </template>
        </select>
      </div>

      <div class="form-group">
        <label for="portal">Portal

        <template v-if="form.portal">
          <a
          :href="'/portals/'+form.portal"
          target="_blank"
          data-tooltip="Edit Portal">
          <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
          </a>
        </template>

        </label>
        <select
          id="portal"
          v-model="form.portal"
          class="form-control"
          :disabled="processing"
        >
          <option value="" />
          <template v-for="(portal, index) in portals">
            <option
              :key="'portal_'+index"
              :value="portal.uuid"
            >
              {{ portal.name }}
            </option>
          </template>
        </select>
      </div>


      <div class="form-group">
        <label for="location">Location

        <template v-if="form.location">
          <a
          :href="'/locations/'+form.location"
          target="_blank"
          data-tooltip="Edit Location">
          <i class="fa fa-edit"></i> <span class="sr-only">Edit Location</span>
          </a>
        </template>

        </label>
        <select
          id="location"
          v-model="form.location"
          class="form-control"
          :disabled="processing"
        >
          <option value="" />
          <template v-for="(location, index) in locations">
            <option
              :key="'location_'+index"
              :value="location.uuid"
            >
              {{ location.name }}
            </option>
          </template>
        </select>
      </div>

    <template v-if="form.location">

    <div class="form-group">
        <label for="location_notes">Location Notes</label>
        <input
          id="location_notes"
          v-model="form.location_notes"
          type="text"
          class="form-control"
        >
    </div>

    </template>

    <template v-if="form.virtual">

      <div class="form-group">
        <label for="virtual_notes">Virtual Notes</label>
        <input
          id="virtual_notes"
          v-model="form.virtual_notes"
          type="text"
          class="form-control"
        >
      </div>

    </template>

      <div class="form-group">
        <label for="event_description">Event Description</label>
        <input
          id="event_description"
          v-model="form.description"
          type="text"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <label for="type">Event Type
          <button type="button" class="btn btn-sm m-0 p-0" @click="showNewType"><i class="fa fa-plus"></i> <span class="sr-only">Add new</span></button>
        </label>
        <template v-if="!show_new_type">
        <select
          id="type"
          class="form-control"
          v-model="form.type"
          :disabled="processing">
        <option value=""></option>
        <template v-for="(event_type, index) in event_types">
          <option :key="'event_type_'+index" :value="event_type.type">{{ event_type.type }}</option>
        </template>
        </select>
        </template>

        <template v-if="show_new_type">

          <input id="event_type" type="text" class="form-control" v-model="form.type">

        </template>

      </div>


      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="status">Event Status

              <template v-if="form.status">
                <a
                    :href="'/statuses?name='+form.status"
                    target="_blank"
                    data-tooltip="Edit Status">
                  <i class="fa fa-edit"></i> <span class="sr-only">Edit Status</span>
                </a>
              </template>

            </label>
            <select
              id="status"
              v-model="form.status"
              class="form-control"
              :disabled="processing"
            >
              <option value="" />
              <template v-for="(status, index) in statuses">
                <option
                  :key="'status_'+index"
                  :value="status.name"
                >
                  {{ status.name }}
                </option>
              </template>
            </select>

          </div>
        </div><!--Col-->

          <div class="col-sm-12 col-md-6">
            <div class="form-group">
              <label for="appeared">Appearance Status

                <template v-if="form.appeared">
                  <a
                      :href="'/statuses?name='+form.appeared"
                      target="_blank"
                      data-tooltip="Edit Status">
                    <i class="fa fa-edit"></i> <span class="sr-only">Edit Status</span>
                  </a>
                </template>

              </label>
              <select
                  id="appeared"
                  v-model="form.appeared"
                  class="form-control"
                  :disabled="processing"
              >
                <option value="" />
                <template v-for="(status, index) in statuses">
                  <option
                      :key="'appeared_'+index"
                      :value="status.name"
                  >
                    {{ status.name }}
                  </option>
                </template>
              </select>

            </div>
          </div><!--Col-->

      </div><!--Row-->

      <div class="form-group">
        <label for="judge_name">Assigned Judge</label>
        <select
          id="judge_name"
          v-model="form.judge_name"
          class="form-control"
          :disabled="processing"
        >
          <option value="" />
          <option value="new_judge">Add a new Judge</option>
          <template v-for="(judge, judge_index) in judges">
            <option
              :key="'judge_'+judge_index"
              :value="judge.name"
            >
              {{ judge.name }}
            </option>
          </template>
        </select>

        <template v-if="form.judge_name == 'new_judge'">

          <input id="event_judge" type="text" class="form-control my-3" placeholder="New Judge Name" v-model="new_judge" required>

        </template>

      </div>

      <div class="form-group">
        <label for="internal_notes">Internal Notes</label>
        <textarea
          id="internal_notes"
          v-model="form.notes"
          class="form-control"
          rows="3"
        />
      </div>

      <div class="form-group">
        <label for="url">Event URL</label>
        <input id="url" class="form-control" type="text" v-model="form.url">
      </div>

      <button
        type="submit"
        class="btn btn-success"
        :disabled="processing"
      >
        <template v-if="form && form.uuid">
          Save
        </template>
        <template v-else>
          Add
        </template>
      </button>
    </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
          :form="form"
          class="mt-3"
        />
      </div><!--Col-->
      <div class="col-sm-12 col-md-6 col-lg-4">

            <client-attach :event="form" />

            <template v-if="clients && clients.length > 0">
              <template v-for="(client_attach, attach_index) in clients">

                <client-detach
                :key="'client_attach_'+attach_index"
                class="mb-3"
                :client="client_attach"
                />

              </template>
            </template>


            <template v-if="form && form.client && form.client.uuid">

              <client-card :client="form.client.uuid" />

              <client-contacts :client_uuid="form.client.uuid" class="mt-3" />

            </template>

        <div class="card my-3" v-if="caseSearch">
          <div class="card-body">

          
            <template v-if="event_case && event_case.uuid">

              <div class="row">

                <div class="col-sm-12 col-md-6">
                  <p class="card-title">Case Details</p>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-right">
                  <router-link
                    tag="button"
                    class="btn btn-sm btn-outline-primary"
                    :to="{name: 'cases.edit', params: {id: event_case.uuid}}"
                  >
                    <i class="fa fa-edit"></i> Edit Case
                  </router-link>
                </div><!--Col-->

              </div><!--Row-->

              <p class="m-0">Case Number {{ event_case.case_number }}</p>
              
              <template v-if="event_case.type">
                <p class="m-0">Type {{ event_case.type }}</p>
              </template>

              <template v-if="event_case.status">
                <p class="m-0">Status {{ event_case.status }}</p>
              </template>

              <template v-if="event_case.description">
                <p class="m-0">Description {{ event_case.description }}</p>
              </template>

            </template>

            <template v-if="!form.case_number">
            <form @submit.prevent="searchCases()">
            <div class="form-group">
              <label for="search_cases">Search Cases</label>
              <input
                id="search_cases"
                v-model="search_cases"
                type="search"
                class="form-control"
                required
              >
            </div>

            <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>
            </form>

            <template v-if="cases_results && cases_results.length > 0">
              <ul class="list-group my-3">
                <template v-for="(case_result, case_result_index) in cases_results">
                  <li :key="'case_result_'+case_result_index" class="list-group-item list-group-item-action">{{ case_result.case_number }}
                  
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-outline-primary" @click="form.case_number = case_result.case_number">Select</button>
                      <button type="button" class="btn btn-sm btn-outline-primary" @click="getClient(case_result.client_uuid)">Client</button>
                    </div>
                  </li>
                </template>
              </ul>
            </template>

            </template>


          </div><!--Body-->
        </div><!--Card-->

            <template v-if="form.location">
              <location-card :uuid="form.location" />
            </template><!--Location-->


            <template v-if="bonds && bonds.length > 0">
              <div class="card mt-3">
                <div class="card-body">
                  <p class="card-title">
                    <a :href="'/bonds?case_number='+caseSearch">{{ bonds.length }} Bonds</a>
                  </p>

                  <ul class="list-group">
                    <template v-for="(bond, index) in bonds">
                      <router-link
                        :key="'bond_'+index"
                        tag="li"
                        class="list-group-item list-group-item-action link-hover"
                        :to="{'name': 'bonds.edit', 'params': {id: bond.uuid }}"
                      >
                        {{ bond.bond_number }} {{ bond.collateral_type }} {{ bond.status }}
                      </router-link>
                    </template>
                  </ul>
                </div><!--Body-->
              </div><!--Card-->
            </template><!--Bonds-->

     </div><!--Col-->

          <div class="col-sm-12 col-md-6 col-lg-4">
            <template v-if="form && form.uuid">

              <messages-form
                :client="form.client"
                :event="form.uuid"
                class="mb-3"
              />

              <messages-scheduled
                :event_uuid="form.uuid"
                class="mb-3"
              />
              <messages-feed :event_uuid="form.uuid" />

            </template>

          </div><!--Col-->
        </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

    data () {
        return {
            processing: false,
            downloading: false,
            form: {},
            client: {},
            // contacts: [],
            messages: [],
            total_messages: 0,
            locations: [],
            portals: [],
            flows: [],
            statuses: [],
            bonds: [],
            event_case: {},
            event_status: {},
            calendar_links: [],
            event_types: [],
            judges: [],
            default_queries: {
                slim: true,
                limit: 1000,
                sort: 'name',
                sortDirection: 'desc',
                fields: 'name,uuid'
            },
            show_new_type: false,
            new_judge: null,
            clients: [],
            unreachables: {},
            search_cases: null,
            cases_results: [],
        }
    },
    computed: {

        caseSearch: function() {
            return this.form.case_number || this.form.event_reference || null;
        },

        cmsURL: function() {
            if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.settings) {
                if(this.$root.current_user.current_agency.settings.cms_url && this.caseSearch) {
                    return this.$root.current_user.current_agency.settings.cms_url + this.caseSearch;
                }
            }

            return false;
        },

    },
    
mounted() {

    var self = this;

    this.$eventBus.$on('attach_client', function() {
      self.getAttached();
    });

    this.$eventBus.$on('detach_client', function() {
      self.getAttached();
    });
},

    created () {

        this.resetForm();

        this.getRecord();

        this.getOptions();
        this.getUnreachables();
        this.getFlows();
        this.getLocations();
        this.getPortals();
        this.getStatuses();
        this.getEventTypes();
        this.getJudges();
    },

    methods: {

        getRecord () {

            this.processing = true;

            this.$http.get('/'+this.$route.meta.base_url + '/' + this.$route.params.id)
            .then(response => {
                if (response && response.data) {

                    this.form = response.data;

                    this.setTitle();

                    if(this.form.uuid) {
                        this.getStatus(this.form.uuid);
                    }

                    this.getEventCase();

                    this.getAttached();

                    this.getBonds();

                    this.getCalendars();

                    this.processing = false;
                }
            })
        },

        setTitle() {
            var title = this.form.event_reference || this.form.case_number || 'Edit Event';

            document.title = title + ' | eCourtDate.com';
        },

        getBonds() {

            if(!this.caseSearch) {
                return;
            }

            this.$http.get('/bonds?case_number='+this.caseSearch)
            .then(response => {
                if(response && response.data) {
                    this.bonds = response.data;
                }
            })
        },

        searchCases() {
          this.processing = true;
          this.$http.get('/cases?slim=true&limit=1000&fields=case_number,uuid&search='+this.search_cases)
          .then(response => {
            if(response && response.data) {
              this.cases_results = response.data;
              this.processing = false;
            }
          })
        },

        getEventCase() {

            if(!this.caseSearch) {
                return;
            }

            this.$http.get('/cases?slim=true&limit=1&case_number='+this.caseSearch)
            .then(response => {
                if(response && response.data && response.data.length > 0) {
                    this.event_case = response.data[0];
                }
            })
        },

        getStatus(uuid) {
            this.$http.get('/'+this.$route.meta.base_url+'/'+uuid+'/status')
            .then(response => {
                if(response && response.data) {
                    this.event_status = response.data;
                }
            })
        },

        getCalendars() {

            if(!this.form.uuid) {
                return;
            }

            this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/calendars')
            .then(response => {
                if(response && response.data) {
                    this.calendar_links = response.data;
                }
            })
        },

        restoreRecord () {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url+'/' + this.form.uuid + '/restore')
            .then(response => {
                if (response && response.data) {
                  new this.$noty({text: response.data.message || 'Restored'}).show();
                  this.form.deleted_at = null;
                  this.form.archived_at = null;
                  this.processing = false;
                }
            })
        },

        archiveRecord () {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url+'/' + this.form.uuid + '/archive')
            .then(response => {
                if (response && response.status) {
                    new this.$noty({text: response.data.message || 'Archived'}).show();
                    this.form.archived_at = new Date().toISOString();
                    this.processing = false;
                }
            })
        },

        trashRecord () {
            this.processing = true;
            this.$http.delete('/'+this.$route.meta.base_url+'/' + this.form.uuid)
            .then(response => {
                if(response && response.status === 204) {
                    new this.$noty({text: this.$route.meta.title + ' trashed', type: 'error'}).show();
                    this.form.deleted_at = new Date().toISOString();
                    this.processing = false;
                }
            })
        },

        syncRecord () {
            new this.$noty({text: 'Syncing messages'}).show();
            this.$eventBus.$emit('eventSynced', this.form.uuid);
            this.$http.post('/'+this.$route.meta.base_url+'/' + this.form.uuid + '/sync')
            .then(response => {
                if(response && response.status === 202) {
                  new this.$noty({text: 'Messages synced'}).show();
                }
            })
        },

      duplicateRecord() {

        this.processing = true;

        var form = {};

        form = this.form;

        var fields = ['uuid', 'client', 'upload', 'url', 'flow_name', 'location_name', 'date_time', 'is_now', 'is_past', 'first_name', 'last_name', 'client_uuid', 'language', 'timezone', 'reached', 'appeared', 'created_by', 'created_at', 'updated_at', 'archived_at', 'deleted_at'];

        fields.forEach(f => {
            delete form[f];
        });

        this.$http.post('/'+this.$route.meta.base_url, form)
            .then(response => {
              if(response && response.status === 201) {
                this.processing = false;
                new this.$noty({text: this.$route.meta.title + ' duplicated'}).show();
                this.$router.push('/'+this.$route.meta.base_url+'/'+response.data.uuid);
              }
            })
      },

        saveICS() {
            this.processing = true;
            this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/ics')
            .then(response => {
                if(response && response.data) {

                    window.open( "data:text/calendar;charset=utf8," + escape(response.data), '_blank');

                    this.processing = false;
                }
            })
        },

    getOptions () {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
        .then(response => {
            if (response && response.data) {
                this.options = response.data;
            }
        })
    },

    getUnreachables() {
      this.$axios.get("https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/unreachables.json")
          .then(response => {
            if(response && response.data) {
              this.unreachables = response.data;
            }
          })
    },

    getFlows () {
        this.$http.get(this.buildQueries('/flows', this.default_queries))
        .then(response => {
            if (response && response.data) {
                this.flows = response.data;
            }
        })
    },

    getLocations () {
        this.$http.get(this.buildQueries('/locations', this.default_queries))
        .then(response => {
            if (response && response.data) {
                this.locations = response.data;
            }
        })
    },

    getPortals () {
        this.$http.get(this.buildQueries('/portals', this.default_queries))
        .then(response => {
            if (response && response.data) {
                this.portals = response.data;
            }
        })
    },

    resetForm() {
        this.form = {
            client: null,
            date: null,
            end_date: null,
            time: null,
            end_time: null,
            event_reference: null,
            case_number: null,
            flow: null,
            portal: null,
            location: null,
            location_notes: null,
            virtual_notes: null,
            description: null,
            type: null,
            status: null,
            appeared: null,
            virtual: false,
            judge_name: null,
            internal_notes: null,
            url: null
        }
    },

    setNewJudge() {
      if(this.form.judge_name == 'new_judge') {
        this.form.judge_name = this.new_judge;
        this.new_judge = null;

        if(!this.judges) {
          this.judges = [];
        }

        this.judges.push({name: this.form.judge_name});
      }
    },

    postForm() {

        this.processing = true;

        this.setNewJudge();

        this.$eventBus.$emit('eventSynced', this.form.uuid);

        this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
        .then(response => {
            if(response && response.data) {

                if(response.data.updated_at) {
                    this.form = {...this.form, ...response.data};
                    new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                }

                this.processing = false;
          
            }
        })
    },

    getStatuses() {
        this.$http.get('/statuses?slim=true&limit=1000&sort=name&sortDirection=asc&type=event&fields=name')
        .then(response => {
            if(response && response.data) {
                this.statuses = response.data;
            }
        })
    },

    getEventTypes() {
        this.$http.get('/event_types')
        .then(response => {
            if(response && response.data) {
                this.event_types = response.data;
            }
        })
    },

    showNewType() {
      if(this.show_new_type === true) {
        this.show_new_type = false;
        return;
      }

      this.show_new_type = true;
    },

    getJudges() {
        this.$http.get('/judges?limit=1000&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.judges = response.data;
            }
        })
    },

    getClient(uuid) {
      this.processing = true;
      this.$http.get('/clients/'+uuid)
      .then(response => {
        if(response && response.status === 200) {

          if(response.data && response.data.uuid) {
            this.form.client = response.data;
          }

          this.processing = false;
        }
      })
    },

    getAttached() {
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/attached')
      .then(response => {
        if(response && response.data) {
          this.clients = response.data;
        }
      })
    },

    updateAppeared(value) {

      if(!this.form.uuid) {
        return;
      }

      this.form.appeared = value;
      this.postForm();
    },

    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      new this.$noty({text: 'Copied to clipboard'}).show();
    },

    setDateDiff(form) {
        if(!form.date || !form.time) {
          return;
        }

        return this.$options.filters.timeago(form.date+' '+form.time);
      },

      async downloadPDF() {
        try {

          window.scrollTo(0,0);

          this.downloading = true;

          const element = document.getElementById('main');

          const canvas = await this.$html2canvas(element, {
            windowWidth: element.scrollWidth,
            windowHeight: element.scrollHeight,
            scrollY: -window.scrollY,
            scrollX: -window.scrollX,
            scale: window.devicePixelRatio,
            useCORS: true,
            logging: false
          });

          const imgData = canvas.toDataURL('image/png');

          const pdf = new this.$jsPDF({
            orientation: 'landscape',
            unit: 'px',
            format: [canvas.width, canvas.height]
          });

          pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

          var name = this.$options.filters.slugString(this.$route.meta.title+'-'+this.$root.current_user.current_agency.name);

          pdf.save(name+'.pdf');

          this.downloading = false;
        } catch (error) {
          new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
          console.log(error);
          this.downloading = false;
        }
      },

    }
}
</script>
