<template>
  <div class="row">
    <div class="col-12">

        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">
              Current Agency
            </p>

            <template v-if="$root.current_user && !$root.current_user.current_agency">
              <div class="alert alert-danger">No agencies found.</div>
            </template>

            <template v-if="$root.current_user && $root.current_user.current_agency">
              <h4><a :href="base_url+'/'+$root.current_user.uuid">{{ $root.current_user.current_agency.name }}</a></h4>

              <p class="m-0"><strong>Region:</strong> {{ $root.current_user.current_agency.region }}</p>

            </template>
             
          </div><!--Body-->
        </div><!--Card-->

        <p class="card-title">{{ records.length }} Agencies</p>

      <div class="row">

          <template v-for="(record, index) in records">

            <div :key="'agency_'+index" class="col-md-4 mb-3">
              <div class="card" :class="isCurrentRecord(record)">
                <div class="card-body">

                  <p class="card-title">{{ record.name }}</p>

                  <p class="m-0"><strong>Region:</strong> {{ record.region }}</p>

                    <a :href="'https://'+record.region+'.ecourtdate.com'" target="_blank" class="btn btn-success">Go to {{ record.region }}</a>

                </div><!--Body-->
              </div><!--Card-->
            </div><!--Col-->

          </template>

        </div><!--Col-->

      </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data () {
        return {
            processing: false,
            base_url: '/users',
            records: [],
            queries: {
                sort: 'reference',
                sortDirection: 'asc',
                limit: 1000,
                skip: 0,
                slim: true,
                fields: 'uuid,name,reference,region'
            }
        }
    },


    created () {
        this.getRecords();
    },

    methods: {

        isCurrentRecord(record) {
          if(!this.$root.current_user || !this.$root.current_user.current_agency) {
            return;
          }

          if(this.$root.current_user.current_agency.uuid === record.uuid) {
            return 'bg-primary text-white';
          }
        },

        getRecords () {

          var user = localStorage.getItem('user');

          user = user || this.$root.current_user.uuid;

          if(!user) {
            return;
          }

            this.processing = true;
            this.$http.get(this.buildQueries(this.base_url+'/'+user+'/agencies', this.queries))
            .then(response => {
                if(response && response.data) {

                    if(response.data.length) {

                        var self = this;

                        response.data.forEach(function(data) {
                            self.records.push(data);
                        });
                    }

                    this.queries.skip = this.records.length;

                    this.processing = false;

                    this.setTitle();

                }
            })
        },

        setTitle() {
          document.title = this.records.length + ' ' + this.$route.meta.title + ' | eCourtDate.com';
        }

    }
}
</script>   