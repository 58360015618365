<template>
  <div>
    <div class="row">
      <div class="col-md-4 col-lg-3 mb-3">

        <div class="card">
          <div class="card-body">

            <p class="card-title">Add an Agent</p>

              <form
                @submit.prevent="postForm()"
              >
                <div class="form-group">
                  <label for="firm_name">Firm Name</label>
                  <input
                    id="firm_name"
                    v-model="form.firm_name"
                    type="text"
                    class="form-control"
                    required
                  >
                </div>
                <div class="form-group">
                  <label for="agent_reference">Agent Reference</label>
                  <input
                    id="agent_reference"
                    v-model="form.agent_reference"
                    type="text"
                    class="form-control"
                    required
                  >
                </div>
                <div class="form-group">
                  <label for="first_name">First Name</label>
                  <input
                    id="first_name"
                    v-model="form.first_name"
                    type="text"
                    class="form-control"
                    required
                  >
                </div>
                <div class="form-group">
                  <label for="last_name">Last Name</label>
                  <input
                    id="last_name"
                    v-model="form.last_name"
                    type="text"
                    class="form-control"
                    required
                  >
                </div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input 
                    id="email"
                    v-model="form.email" 
                    type="email" 
                    class="form-control"
                    required 
                  >
                </div>
                <div class="form-group">
                  <label for="phone">Phone</label>
                  <input 
                    id="phone"
                    v-model="form.phone"
                    type="tel"
                    class="form-control"
                  >
                </div>
                <div class="form-group custom-control custom-switch">
                  <input
                    id="notify"
                    v-model="form.notify"
                    type="checkbox"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="notify"
                  >Notify</label>
                </div>
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
                >
                  Add Agent
                </button>
              </form>

          </div><!--Body-->

        </div><!--Card-->

      </div>
      <div class="col-md-9">
        <template v-if="records && records.length">
          <div class="row">
            <template v-for="(record, index) in records">
              <div
                :key="'record_'+index"
                class="col-md-6 col-lg-4 mb-3"
              >
                <div
                  class="card"
                  style="height: 100%"
                >
                  <div class="card-body">
                    <h5 class="card-title">
                      <router-link
                        :to="'/agents/' + record.uuid"
                        class="link-hover"
                      >
                        <template v-if="record.firm_name">
                          {{ record.firm_name }}
                        </template>
                        <template v-if="!record.firm_name && (record.first_name || record.last_name)">
                          {{ record.first_name }} {{ record.last_name }}
                        </template>
                        <template v-if="!record.firm_name && !record.first_name">
                          {{ record.email }}
                        </template>
                      </router-link>
                    </h5>
                    <template v-if="record.firm_name && record.first_name">
                      <p class="m-0">
                        Name: {{ record.first_name }} {{ record.last_name }}
                      </p>
                    </template>
                    <template v-if="record.agent_reference">
                      <p class="m-0">
                        Reference: {{ record.agent_reference }}
                      </p>
                    </template>
                    <template v-if="(record.firm_name || record.first_name) && record.email">
                      <p class="m-0">
                        Email: {{ record.email }}
                      </p>
                    </template>
                    <template v-if="record.phone">
                      <p class="m-0">
                        Phone: {{ record.phone }}
                      </p>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-if="!processing && (!records || records.length === 0)">
          <div class="alert alert-warning">
            No agents found
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data () {
        return {
            processing: true,
            base_url: '/agents',
            queries: {
              slim: true,
              limit: 10,
              sort: 'created_at',
              sortDirection: 'desc'
            },
            total: 0,
            records: []
        }
    },
    
    created () {
        this.resetForm();
        this.resetRecords();
        this.setURLParams();
        this.getRecords();
    },


    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });

        },

        resetRecords() {
            this.records = [];
            this.total = 0;
            this.queries.skip = 0;
        },

        resetForm() {
          this.form = {
            firm_name: null,
            agent_reference: null,
            first_name: null,
            last_name: null,
            email: null,
            phone: null,
            notify: false
          }
        },

        getRecords () {

            this.getTotal();

            this.processing = true;
            this.$http.get(this.base_url)
            .then(response => {
                if (response && response.data) {
                    
                    if(response.data.length) {

                        var self = this;

                        response.data.forEach(function(data) {
                            self.records.push(data);
                        });
                    }

                    this.processing = false;
                }
            })
        },

        getTotal() {
          this.$http.get(this.buildQueries(this.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status) {
              this.total = response.data;
              this.setTitle();
            }
          })
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + ' | eCourtDate.com';
        },

        postForm () {
            this.processing = true;
            this.$http.post(this.base_url, this.form)
            .then(response => {
                if (response && response.data) {
                    this.resetForm();
                    new this.$noty({text: 'Agent added'}).show();
                    this.processing = false;
                }
            })
        }
    }
}
</script>
