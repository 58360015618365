<template>
  <div>

    <template v-if="changed">
      <div class="alert alert-warning">Click <strong>Save</strong> to apply changes.</div>
    </template>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <template v-if="errors && errors.message">
      <template v-if="errors.message != 'Network Error'">
        <div class="alert alert-danger">
          {{ errors.message }}
        </div>
      </template>
    </template>
        
    <div class="row">
      <div class="col-sm-12 col-md-4">
          <h1>{{ form.name }}</h1>
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">
        <template v-if="form.link && form.domain">

          <a :href="'https://'+form.link+'.'+form.domain" target="_blank" class="btn btn-outline-primary btn-sm text-uppercase">
            <template v-if="portal_status == 'offline'">
              <i class="fa fa-circle text-danger"></i>
            </template>
            <template v-else-if="portal_status == 'online'">
              <i class="fa fa-circle text-success"></i>
            </template>
            <template v-else-if="portal_status == 'loading'">
              <div class="spinner-grow spinner-grow-sm text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </template>

            Portal Link
          </a>

        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">
                <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
                >Archive</a>
                <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>
                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-md-3 col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title">
                  Edit {{ $route.meta.title }}
                </p>
              </div><!--Col-->

              <div class="col-6 text-right">
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="postForm()">
              <div class="form-group">
                <label for="name">{{ $route.meta.title }} Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="portal_reference">Portal Reference</label>
                <input
                  id="portal_reference"
                  v-model="form.portal_reference"
                  type="text"
                  class="form-control"
                  :disabled="!superAdmin"
                >
              </div>

              <div class="form-group">
                <label for="merge_tag">Merge Tag <button type="button" class="btn btn-secondary btn-sm" @click="copyToClipboard('{'+form.merge_tag+'}')" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button></label>
                <input
                    id="merge_tag"
                    v-model="form.merge_tag"
                    type="text"
                    class="form-control"
                >
              </div>

              <template v-if="!isPayPortal">

              <div class="form-group custom-control custom-switch">
                <input
                    id="weather"
                    v-model="form.weather"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="weather"
                > Show Weather</label>
              </div>

              </template>

              <div class="form-group custom-control custom-switch">
                <input
                  id="default"
                  v-model="form.default"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="default"
                >Default</label>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
  
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">
            <p class="card-title"><i class="fa fa-palette"></i> Theme and Brand</p>

            <div class="form-group">
              <label for="theme">Color Theme</label>
              <select id="theme" class="form-control" v-model="form.theme" @change="postForm()" :disabled="processing">
                <option value=""></option>
                <template v-for="(theme, theme_index) in themes">
                  <option :key="'theme_'+theme_index" :value="theme">{{ theme }}</option>
                </template>
              </select>
            </div>

            <label class="form-label">LOGO</label>
            <div class="input-group">
              <div class="custom-file">
                <input
                    id="logoUpload"
                    ref="logo"
                    type="file"
                    class="custom-file-input"
                    aria-describedby="logoUpload"
                    @change="storeLogo()"
                    :disabled="processing"
                >
                <label
                    class="custom-file-label"
                    for="logoUpload"
                >
                  Upload Logo
                </label>
              </div>
            </div>

            <template v-if="logoURL">

              <img
                  width="100"
                  class="mr-3"
                  :src="logoURL"
                  :alt="form.name + ' Logo'"
              >

              <div class="btn-group my-3">

                <button
                    type="button"
                    class="btn btn-outline-secondary btn-sm"
                    @click="copyToClipboard(logoURL)"
                    :disabled="processing">
                  <i class="fa fa-copy"></i> Copy
                </button>

                <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click="trashLogo()"
                    :disabled="processing"
                    :hidden="!form || !form.logo"
                >
                  <i class="fa fa-trash" /> Trash
                </button>
              </div>
            </template>

            <hr class="my-3">

            <label class="form-label">ICON</label>
            <div class="input-group">
              <div class="custom-file">
                <input
                    id="iconUpload"
                    ref="icon"
                    type="file"
                    class="custom-file-input"
                    aria-describedby="iconUpload"
                    @change="storeIcon"
                    :disabled="processing"
                >
                <label
                    class="custom-file-label"
                    for="iconUpload"
                >
                  Upload Icon
                </label>
              </div>
            </div>

            <template v-if="iconURL">

              <img
                  width="32"
                  class="mr-3"
                  :src="iconURL"
                  :alt="form.name + ' Icon'"
              >

              <div class="btn-group my-3">
                <button
                    type="button"
                    class="btn btn-outline-secondary btn-sm"
                    @click="copyToClipboard(iconURL)"
                    :disabled="processing">
                  <i class="fa fa-copy"></i> Copy
                </button>

                <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click="trashIcon()"
                    :disabled="processing"
                    :hidden="!form || !form.icon"
                >
                  <i class="fa fa-trash" /> Trash
                </button>
              </div>
            </template>

            <div class="form-group mt-3">
              <label for="custom_css">Custom CSS</label>
              <textarea
                  id="custom_css"
                  v-model="form.custom_css"
                  class="form-control"
                  @change="postForm()"
              />
            </div>
            <div class="form-group">
              <label for="custom_js">Custom JS</label>
              <textarea
                  id="custom_js"
                  v-model="form.custom_js"
                  class="form-control"
                  @change="postForm()"
              />
            </div>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title"><i class="fa fa-link"></i> Links and Redirects</p>

            <form @submit.prevent="postForm()">

            <div class="form-group">
              <label for="link">Portal Link</label>
              <input
                  id="link"
                  v-model="form.link"
                  class="form-control"
                  required
              >
            </div>

            <div class="form-group">
              <label for="domain">Domain</label>
              <select
                  id="domain"
                  v-model="form.domain"
                  class="form-control"
                  :disabled="processing"
                  required
              >
                <template v-for="(domain, domain_key) in domains">
                  <option :key="'domain_'+domain_key" :value="domain.apex">{{ domain.apex }}</option>
                </template>
              </select>
              <template v-if="form.alias">
                <p class="form-text text-muted small">Alias for {{ form.alias }}</p>
              </template>
            </div>

            <div class="form-group">
                <label for="redirect_link">Redirect Link</label>
                <input
                    id="redirect_link"
                    v-model="form.redirect"
                    type="text"
                    class="form-control"
                >
                <p class="form-text text-muted small">Auto-redirect to an external link.</p>
            </div>

            <template v-if="!isPayPortal">

              <div id="virtualLink" class="form-group">
                <label for="virtual_link">Virtual Link</label>
                <input
                    id="virtual_link"
                    v-model="form.virtual"
                    type="text"
                    class="form-control"
                >
                <p class="form-text text-muted small">Default virtual meeting link. Can be overridden on the event or location level.</p>
              </div>

              <template v-if="form.virtual">
                <div class="form-group">
                  <label for="virtual_code">Virtual Code</label>
                  <input
                      id="virtual_code"
                      v-model="form.virtual_code"
                      type="text"
                      class="form-control"
                  >
                </div>
              </template>

            </template>

            <button type="submit" class="btn btn-success sr-only" :disabled="processing">Save</button>

            </form>

          </div>
        </div>

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title"><i class="fa fa-map-location-dot"></i> Tracking and Authentication</p>

            <div class="form-group mt-3">
              <label for="geolocation">Geolocation Tracking</label>
              <select
                  id="geolocation"
                  v-model="form.geolocation"
                  class="form-control"
                  :disabled="processing"
              >
                <option value=""></option>
                <template v-for="(geolocation, geolocation_index) in geolocations">
                  <option :key="'geolocation_'+geolocation_index" :value="geolocation">{{ geolocation }}</option>
                </template>
              </select>
              <p class="form-text text-muted small">Can be overridden by Flow.</p>
            </div>

            <div class="form-group">
              <label for="portal_verify">Client Verification</label>
              <select
                  id="portal_verify"
                  v-model="form.verify"
                  class="form-control"
                  :disabled="processing"
              >
                <option value=""></option>
                <template v-for="(verification, verification_index) in verifications">
                  <option :key="'verification_'+verification_index" :value="verification">{{ verification }}</option>
                </template>
              </select>
            </div>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
              <div class="card-body">

                <p class="card-title"><i class="fa fa-share"></i> Embed and Share</p>


             <div class="form-group">
              <label for="embed_code">iFrame Embed Code</label>
              <textarea
                  id="embed_code"
                  v-model="embedCode"
                  class="form-control"
                  rows="3"
                  @click="copyToClipboard(embedCode)"
                  readonly
              />
            </div>

              </div><!--Body-->
        </div><!--Card-->

        <audit-logs
          class="mt-3"
          :form="form"
        />
      </div><!--Col-->

      <div class="col-sm-12 col-md-6">

        <template v-if="!isPayPortal">

          <div class="card mb-3">
            <div class="card-body">
              <p class="card-title">
                <i class="fa fa-qrcode"></i> Self-Enroll
              </p>

              <p>Allow clients to sign up directly through the Portal.</p>

              <div class="row mb-3">

                <div class="col-sm-12 col-md-6">
                  <a
                    :href="setPortalLink()"
                    target="_blank"
                    class="btn btn-outline-primary"
                  ><i class="fa fa-link" /> Self-Enroll Link</a>

                  <template v-if="qr_code">
                    <a :href="setPortalLink()" target="_blank">
                      <img
                          :src="'data:image/svg+xml;base64,'+qr_code"
                          width="50"
                      >
                    </a>
                  </template>

                </div><!--Col-->


                <div class="col-sm-12 col-md-6">
                    <button type="button" class="btn btn-secondary" @click="setDefaultEnrollFields()" :disabled="processing">
                      <i class="fa fa-check"></i> Set Default
                    </button>
                </div><!--Col-->

                </div><!--Row-->

          <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="enroll_title">Self-Enroll Title</label>
                <input
                    id="enroll_title"
                    v-model="form.enroll_title"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="enroll_description">Self-Enroll Description</label>
                <input
                    id="enroll_description"
                    v-model="form.enroll_description"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="enroll_contacts">Self-Enroll Notification Contacts</label>
                <input
                    id="enroll_contacts"
                    v-model="form.enroll_contacts"
                    type="text"
                    class="form-control"
                >
                <p class="small text-muted">
                  Comma-separated list of phones and emails
                </p>
              </div>

              <div class="form-group">
                <label for="enroll_fields">Self-Enroll Enabled Fields</label>
                <multi-select
                    id="enroll_fields"
                    v-model="form.enroll_enabled_fields"
                    :options="enroll_fields"
                    label="name"
                    track-by="key"
                    :multiple="true"
                    :close-on-select="false"
                />
              </div>

              <template v-if="form.enroll_enabled_fields && form.enroll_enabled_fields.length > 0">
                <ul class="list-group mb-3">
                  <draggable
                      v-model="form.enroll_enabled_fields"
                      group="fields"
                      @start="drag=true"
                      @end="drag=false"
                  >
                    <li
                        v-for="field in form.enroll_enabled_fields"
                        :key="'enabled_fields_'+field.key"
                        class="list-group-item"
                    >
                      <div class="row">
                        <div class="col-3">

                          <div class="form-group">
                          <label :for="'enabled_fields_'+field.name">Field Name</label>
                          <input
                              :id="'enabled_fields_'+field.name"
                              v-model="field.name"
                              type="text"
                              class="form-control form-control-sm"
                              required
                          >
                          </div>

                          <div class="form-group">
                          <label :for="'default_value_'+field.default">Default Value</label>
                          <input
                              :id="'default_value_'+field.default"
                              v-model="field.default"
                              type="text"
                              class="form-control form-control-sm"
                          >
                          </div>

                        </div><!--Col-->

                        <div class="col-3">
                          <div class="form-group custom-control custom-switch">
                            <input
                                :id="'required_field_'+field.key"
                                v-model="field.required"
                                type="checkbox"
                                class="custom-control-input"
                            >
                            <label
                                class="custom-control-label"
                                :for="'required_field_'+field.key"
                            > Required</label>
                          </div>

                          <div class="form-group custom-control custom-switch">
                            <input
                                :id="'hidden_field_'+field.key"
                                v-model="field.hidden"
                                type="checkbox"
                                class="custom-control-input"
                            >
                            <label
                                class="custom-control-label"
                                :for="'hidden_field_'+field.key"
                            > Hidden</label>
                          </div>
                        </div><!--Col-->

                        <div class="col-3">
                          <span class="badge badge-primary mr-1">{{ field.type }}</span>
                        </div>

                        <div class="col-3 text-right">
                          <button
                              type="button"
                              class="btn btn-secondary btn-sm"
                          >
                            <i class="fa fa-bars" /> <span class="sr-only">Move</span>
                          </button>
                        </div><!--Col-->
                      </div><!--Row-->
                    </li>
                  </draggable>
                </ul>
              </template>

              <button type="submit" class="btn btn-success sr-only" :disabled="processing">Save</button>

              </form>


            </div><!--Body-->
          </div><!--Card-->

        </template>


        <template v-if="isPayPortal">

        <div class="card mb-3">
          <div class="card-body">

            <div class="row mb-3">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">Payment Options</p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-right">
                <button type="button" class="btn btn-secondary btn-sm" @click="setDefaultPaymentFields()" :disabled="processing"><i class="fa fa-check"></i> Set Default</button>
              </div><!--Col-->
            </div><!--Row-->

              <div class="form-group custom-control custom-switch">
                <input
                  id="pay_card"
                  v-model="paymentsForm['card']"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="pay_card"
                > Cards and Wallets</label>
              </div>

            <template v-if="paymentsForm['card']">
              <p class="m-0">Accept credit, debit, and wallet-based payments online.</p>
              <p class="m-0 mb-3">Clients pay an additional convenience fee of 6% per transaction.</p>
            </template>

            <div class="form-group custom-control custom-switch">
              <input
                  id="pay_ach"
                  v-model="paymentsForm['ach']"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
              >
              <label
                  class="custom-control-label"
                  for="pay_ach"
              > ACH</label>
            </div>

            <template v-if="paymentsForm['ach']">
              <p class="m-0">Accept ACH payments online.</p>
              <p class="m-0 mb-3">Clients pay an additional convenience fee of 3% per transaction.</p>
            </template>

            <div class="form-group custom-control custom-switch">
              <input
                  id="pay_paypal"
                  v-model="paymentsForm['paypal']"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
              >
              <label
                  class="custom-control-label"
                  for="pay_paypal"
              > PayPal</label>
            </div>

            <template v-if="paymentsForm['paypal']">

              <p class="m-0">Accept PayPal payments online using your PayPal account.</p>
              <p class="m-0 mb-3">No convenience fee.</p>

              <div class="form-group">
                <label for="paypal_email">PayPal Email</label>
                <input id="paypal_email" type="email" class="form-control" v-model="paymentsForm['paypal_email']">
              </div>

            </template>

            <div class="form-group custom-control custom-switch">
              <input
                  id="external_portal"
                  v-model="paymentsForm['external_portal']"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
              >
              <label
                  class="custom-control-label"
                  for="external_portal"
              > External Portal</label>
            </div>

            <template v-if="paymentsForm['external_portal']">

              <p>Link to an external payment portal. No convenience fee is charged.</p>

            <div class="form-group">
              <label for="external_portal_link">External Portal Link</label>
              <input id="external_portal_link" type="text" class="form-control" v-model="paymentsForm['external_portal_link']" placeholder="https://pay.court.gov">
            </div>

            <div class="form-group">
              <label class="form-label" for="external_portal_instructions">External Portal Instructions</label>
              <textarea id="external_portal_instructions" class="form-control" v-model="paymentsForm['external_portal_instructions']" rows="5"></textarea>
            </div>

            </template>

            <div class="form-group custom-control custom-switch">
              <input
                  id="pay_phone"
                  v-model="paymentsForm['phone']"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
              >
              <label
                  class="custom-control-label"
                  for="pay_phone"
              > Pay by Phone</label>
            </div>

            <template v-if="paymentsForm['phone']">

              <p>Accept payments through an automated phone number.</p>

              <div class="form-group">
                <label for="pay_phone_number">Phone Number</label>
                <input id="pay_phone_number" type="text" class="form-control" v-model="paymentsForm['phone_number']">
              </div>

              <div class="form-group">
                <label class="form-label" for="phone_instructions">Phone Instructions</label>
                <textarea id="phone_instructions" class="form-control" v-model="paymentsForm['phone_instructions']" rows="5"></textarea>
              </div>

            </template>


              <div class="form-group custom-control custom-switch">
                <input
                  id="pay_mail"
                  v-model="paymentsForm['mail']"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="pay_mail"
                > Postal Mail</label>
              </div>

            <template v-if="paymentsForm['mail']">

              <div class="form-group">
                <label class="form-label" for="pay_mail_instructions">Mail Instructions</label>
                <textarea id="pay_mail_instructions" class="form-control" v-model="paymentsForm['mail_instructions']" rows="5"></textarea>
              </div>

              <div class="form-group">
                <label class="form-label" for="pay_mail_address">Postal Address</label>
                <input id="pay_mail_address" class="form-control" v-model="paymentsForm['mail_address']">
              </div>

              <div class="form-group">
                <label class="form-label" for="pay_mail_pdf">PDF Template</label>
                <input id="pay_mail_pdf" class="form-control" v-model="paymentsForm['mail_pdf']">
              </div>

            </template>

              <div class="form-group custom-control custom-switch">
                <input
                  id="pay_inperson"
                  v-model="paymentsForm['inperson']"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="pay_inperson"
                > In-Person</label>
              </div>

              <div class="form-group" :hidden="!paymentsForm['inperson']">
                <label class="form-label" for="pay_inperson_instructions">In-Person Instructions</label>
                <textarea id="pay_inperson_instructions" class="form-control" v-model="paymentsForm['inperson_instructions']" rows="3"></textarea>
              </div>

            <template v-if="paymentsForm['card'] || paymentsForm['ach']">

              <div class="form-group custom-control custom-switch">
                <input
                  id="partial_payments"
                  v-model="paymentsForm['partial_payments']"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="partial_payments"
                > Allow Partial Payments</label>
              </div>
              
              <div class="form-group">
                <label for="pay_notify">Transaction Notification Email</label>
                <input id="pay_notify" type="email" class="form-control" v-model="paymentsForm['notify']" @keyup.enter="postForm()">
              </div>

            </template>

              <div class="form-group">
                <label for="terms">Terms and Conditions <code>HTML</code></label>
                <textarea id="terms" class="form-control" rows="3" v-model="paymentsForm['terms']"></textarea>
              </div>

              <div class="form-group m-0">
                <fieldset>
                  <legend>Processing Mode</legend>
                  <label
                      class="mr-2 text-capitalize"
                      for="pay_mode_test"
                  >
                    <input
                        id="pay_mode_test"
                        v-model="paymentsForm['mode']"
                        type="radio"
                        name="pay_mode"
                        value="test"
                        @change="postForm()"
                    > Test
                  </label>
                  <label
                      class="mr-2 text-capitalize"
                      for="pay_mode_live"
                  >
                    <input
                        id="pay_mode_live"
                        v-model="paymentsForm['mode']"
                        type="radio"
                        name="pay_mode"
                        value="live"
                        @change="postForm()"
                    > Live
                  </label>
                </fieldset>
              </div>

              <div class="form-group">
                <label for="pay_account">Payout Account
                <template v-if="paymentsForm['payout']">
                  <a :href="'https://billing.ecourtdate.com/payout_accounts/'+paymentsForm['payout']" target="_blank"><i class="fa fa-edit"></i> <span class="sr-only">Edit</span></a>
                </template>
                </label>
                <input id="pay_account" type="text" class="form-control" v-model="paymentsForm['payout']" @keyup.enter="postForm()">
                <small class="text-muted small"><a href="https://billing.ecourtdate.com/payout_accounts" target="_blank">Payout Accounts</a></small>
              </div>

            <template v-if="paymentsForm['mode'] && paymentsForm['mode'] == 'test'">
              <h6>How to Simulate Payment Processing</h6>
              <p>Use one of the following to test a card transaction. Any future expiration date will work.</p>
              <ul class="list-group">
                <li class="list-group-item">
                  <img :src="$root.assets_url+'images/payments/visa.svg'" width="40" alt="Visa logo">
                  <mark>4242424242424242</mark>
                  <span class="badge badge-pill badge-secondary" @click="copyToClipboard('123')">CVC 123</span>
                  <button type="button" class="btn btn-secondary btn-sm float-right" @click="copyToClipboard('4242424242424242')" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
                </li>
                <li class="list-group-item">
                  <img :src="$root.assets_url+'images/payments/mastercard.svg'" width="40" alt="MasterCard logo">
                  <mark>5555555555554444</mark>
                  <span class="badge badge-pill badge-secondary" @click="copyToClipboard('123')">CVC 123</span>
                  <button type="button" class="btn btn-secondary btn-sm float-right" @click="copyToClipboard('5555555555554444')" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
                </li>
                <li class="list-group-item">
                  <img :src="$root.assets_url+'images/payments/discover.svg'" width="40" alt="Discover logo">
                  <mark>6011111111111117</mark>
                  <span class="badge badge-pill badge-secondary" @click="copyToClipboard('123')">CVC 123</span>
                  <button type="button" class="btn btn-secondary btn-sm float-right" @click="copyToClipboard('6011111111111117')" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
                </li>
                <li class="list-group-item">
                  <img :src="$root.assets_url+'images/payments/amex.svg'" width="40" alt="American Express logo">
                  <mark>378282246310005</mark>
                  <span class="badge badge-pill badge-secondary" @click="copyToClipboard('1234')">CVC 1234</span>
                  <button type="button" class="btn btn-secondary btn-sm float-right" @click="copyToClipboard('378282246310005')" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
                </li>
              </ul>
            </template>


          </div><!--Body-->
        </div><!--Card-->

        </template><!--Payments-->


        <div class="card">
          <div class="card-body">

              <template v-if="!processing && !languages.length">
                <div class="alert alert-danger">Choose enabled languages in
                  <a href="/settings?tab=general">General Settings</a>
                </div>
              </template>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p class="card-title"><i class="fa fa-language"></i> Multilingual Settings</p>
                <p>Multilingual settings default to English. All options support merge tags.</p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-right">
                <merge-tags />
              </div><!--Col-->
            </div><!--Row-->

            <p class="card-title">Portal Page Title</p>

              <template v-for="(language, index) in languages">

                <div :key="'title_language_'+index" class="form-group mt-3">
                  <label
                    for="portal_title"
                    class="text-capitalize"
                  >
                  {{ language.label }}
                  Title <code>HTML</code>

                <template v-if="language.key == 'en'">
                  <span class="badge badge-success text-uppercase text-small ml-1">Default</span>
                </template>

                  <template v-if="language.key !== 'en' && form.title['en']">
                    <button 
                      type="button" 
                      class="btn btn-outline-success btn-sm text-capitalize ml-3"
                      :disabled="processing"
                      @click="autoTranslate(language, 'title')"
                      >
                     Auto-Translate
                    </button>
                  </template>

                </label>
                  <input
                    id="portal_title"
                    v-model="form.title[language.key]"
                    class="form-control"
                    rows="5"
                  />
                </div>

              </template>

            <hr>

            <p class="card-title">Portal Alerts</p>

            <p>Inform visitors of any important alerts or notices.</p>

              <template v-for="(language, index) in languages">

                <div :key="'alerts_language_'+index" class="form-group mt-3">


                  <label
                    for="portal_alerts"
                    class="text-capitalize mb-3"
                  >
                  {{ language.label }}
                   Alerts <code>HTML</code>

                <template v-if="language.key == 'en'">
                  <span class="badge badge-success text-uppercase text-small ml-1">Default</span>
                </template>

                  <template v-if="language.key !== 'en' && form.alerts['en']">
                    <button 
                      type="button" 
                      class="btn btn-outline-success btn-sm text-capitalize ml-3"
                      :disabled="processing"
                      @click="autoTranslate(language, 'alerts')"
                      >
                     Auto-Translate
                    </button>
                  </template>

                 </label>

                  <textarea
                    id="portal_alerts"
                    v-model="form.alerts[language.key]"
                    class="form-control"
                    rows="5"
                  />
                </div>

              </template>

            <template v-if="!isPayPortal">

              <hr>

              <p class="card-title">Portal Welcome Page</p>

              <template v-for="(language, index) in languages">

                <div :key="'welcome_language_'+index" class="form-group mt-3">
                  <label
                      for="portal_welcome"
                      class="text-capitalize"
                  >
                    {{ language.label }}
                    Welcome <code>HTML</code>

                    <template v-if="language.key == 'en'">
                      <span class="badge badge-success text-uppercase text-small ml-1">Default</span>
                    </template>

                    <template v-if="language.key !== 'en' && form.welcome['en']">
                      <button
                          type="button"
                          class="btn btn-outline-success btn-sm text-capitalize ml-3"
                          :disabled="processing"
                          @click="autoTranslate(language, 'welcome')"
                      >
                        <i class="fa fa-language"></i> Auto-Translate
                      </button>
                    </template>

                  </label>
                  <textarea
                      id="portal_welcome"
                      v-model="form.welcome[language.key]"
                      class="form-control"
                      rows="5"
                  />
                </div>

              </template>



                        <hr>

            <p class="card-title">Portal Home Page</p>

            <template v-for="(language, index) in languages">

              <div :key="'home_language_'+index" class="form-group mt-3">
                <label
                    for="portal_home"
                    class="text-capitalize"
                >
                  {{ language.label }}
                  Home <code>HTML</code>

                  <template v-if="language.key == 'en'">
                    <span class="badge badge-success text-uppercase text-small ml-1">Default</span>
                  </template>

                  <template v-if="language.key !== 'en' && form.home['en']">
                    <button
                        type="button"
                        class="btn btn-outline-success btn-sm text-capitalize ml-3"
                        :disabled="processing"
                        @click="autoTranslate(language, 'home')"
                    >
                      <i class="fa fa-language"></i> Auto-Translate
                    </button>
                  </template>

                </label>
                <textarea
                    id="portal_home"
                    v-model="form.home[language.key]"
                    class="form-control"
                    rows="5"
                />
              </div>

            </template>


            <hr>

            <p class="card-title">Portal Sidebar</p>

              <template v-for="(language, index) in languages">

                <div :key="'sidebar_language_'+index" class="form-group mt-3">
                  <label
                    for="portal_sidebar"
                    class="text-capitalize"
                  >
                  {{ language.label }}
                  Sidebar <code>HTML</code>

                <template v-if="language.key == 'en'">
                  <span class="badge badge-success text-uppercase text-small ml-1">Default</span>
                </template>

                  <template v-if="language.key !== 'en' && form.sidebar['en']">
                    <button 
                      type="button" 
                      class="btn btn-outline-success btn-sm text-capitalize ml-3"
                      :disabled="processing"
                      @click="autoTranslate(language, 'sidebar')"
                      >
                     Auto-Translate
                    </button>
                  </template>

                </label>
                  <textarea
                    id="portal_sidebar"
                    v-model="form.sidebar[language.key]"
                    class="form-control"
                    rows="5"
                  />
                </div>

              </template>

            <hr>

            <p class="card-title">Portal Body</p>

              <template v-for="(language, index) in languages">

                <div :key="'body_language_'+index" class="form-group mt-3">
                  <label
                    for="portal_body"
                    class="text-capitalize"
                  >
                  {{ language.label }}
                  Body <code>HTML</code>

                <template v-if="language.key == 'en'">
                  <span class="badge badge-success text-uppercase text-small ml-1">Default</span>
                </template>

                  <template v-if="language.key !== 'en' && form.body['en']">
                    <button 
                      type="button" 
                      class="btn btn-outline-success btn-sm text-capitalize ml-3"
                      :disabled="processing"
                      @click="autoTranslate(language, 'body')"
                      >
                     Auto-Translate
                    </button>
                  </template>

                </label>
                  <textarea
                    id="portal_body"
                    v-model="form.body[language.key]"
                    class="form-control"
                    rows="5"
                  />
                </div>

              </template>

              <template v-for="(language, index) in languages">

                <div :key="'footer_language_'+index" class="form-group mt-3">
                  <label
                    for="portal_footer"
                    class="text-capitalize"
                  >
                  {{ language.label }}
                  Footer <code>HTML</code>

                <template v-if="language.key == 'en'">
                  <span class="badge badge-success text-uppercase text-small ml-1">Default</span>
                </template>

                  <template v-if="language.key !== 'en' && form.footer['en']">
                    <button 
                      type="button" 
                      class="btn btn-outline-success btn-sm text-capitalize ml-3"
                      :disabled="processing"
                      @click="autoTranslate(language, 'footer')"
                      >
                     Auto-Translate
                    </button>
                  </template>

                </label>
                  <textarea
                    id="portal_footer"
                    v-model="form.footer[language.key]"
                    class="form-control"
                    rows="5"
                  />
                </div>

              </template>


            <p class="card-title">Add to Calendar Templates</p>

              <template v-for="(language, index) in languages">

                <div :key="'calendar_language_'+index">

                <p class="card-title">
                {{ language.label }}

                <template v-if="language.key == 'en'">
                  <span class="badge badge-success text-uppercase text-small">Default</span>
                </template>

                </p>

                <div class="form-group mt-3">
                  <label for="calendar_title">Calendar Title</label>
                  <input id="calendar_title" class="form-control" v-model="form.calendar_title[language.key]">
                </div>

                <div class="form-group">
                  <label for="calendar_description">Calendar Description</label>
                  <textarea id="calendar_description" class="form-control" rows="3" v-model="form.calendar_description[language.key]"></textarea>
                </div>

                <hr>

                </div>

              </template>

              <p class="card-title">Opt-out Survey</p>

              <template v-for="(language, optout_key) in languages">

                <div :key="'optout_language_'+optout_key">

                <p class="card-title">
                {{ language.label }}

                <template v-if="language.key == 'en'">
                  <span class="badge badge-success text-uppercase text-small">Default</span>
                </template>

                </p>

                <div class="form-group">
                  <label for="optout_title">Opt-out Title</label>
                  <input id="optout_title" class="form-control" v-model="form.optouts['title'][language.key]">
                </div>

                <div class="form-group">
                  <label for="optout_reasons">Opt-out Reasons</label>
                  <textarea id="optout_reasons" class="form-control" rows="3" v-model="form.optouts['reasons'][language.key]"></textarea>
                </div>

                <hr>

                </div>

              </template>

              <div class="form-group custom-control custom-switch">
                <input
                  id="optouts_comments"
                  v-model="form.optouts['comments']"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="optouts_comments"
                > Allow Comments</label>
              </div>

              </template><!--Languages-->

          </div><!--Body-->
        </div><!--Card-->


      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

            <p class="card-title">
              {{ total_messages }} {{ $route.meta.title }} messages
            </p>

            <template v-if="messages && messages.length">
              <template v-for="message in messages">
                <message
                  :key="message.uuid"
                  :message="message"
                />
              </template>
            </template><!--Messages-->

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';
import draggable from 'vuedraggable';
import MergeTag from "@/components/MergeTag.vue";

export default {

  components: {MergeTag, draggable },

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      changed: false,
      total: 0,
      queries: {
        limit: 100,
        slim: true,
        fields: 'name,uuid,link,portal_reference,redirect,created_at',
        sort: 'name',
        sortDirection: 'asc'
      },
      records: [],
      form: {
        name: null,
        link: null
      },
      asset_url: 'https://ecdportals.s3-us-gov-east-1.amazonaws.com',
      columns: ['name', 'link', 'reference', 'redirect', 'created_at'],
      enroll_fields: [],
      languages: [],
      qr_code: null,
      total_messages: 0,
      messages: [],
      message_queries: {
          slim: true,
          limit: 100,
          direction: 'outbound',
          sort: 'scheduled_at',
          sortDirection: 'desc',
          fields: 'uuid,content,last_status,test,last_open,client,to,from,scheduled_at,type,direction'
      },
      paymentsForm: {},
      domains: [],
      errors: null,
      geolocations: ['disabled', 'optional', 'required', 'continuous'],
      verifications: ['disabled', 'one-time-password', 'contact', 'biometric'],
      portal_status: 'loading',
      themes: ['auto', 'light', 'dark'],
    }
  },

  computed: {

        superAdmin () {
          return this?.$root?.current_user?.super_admin;
        },


        logoURL() {

          if(this.form && this.form.logo) {
            return this.asset_url+'/'+this.form.logo;
          }

          if(this.$root.current_user && this.$root.current_user.current_agency) {
            if(this.$root.current_user.current_agency.settings && this.$root.current_user.current_agency.settings.logo) {
              return this.$root.current_user.current_agency.settings.logo;
            }
          }
        },

        iconURL() {

          if(this.form && this.form.icon) {
            return this.asset_url+'/'+this.form.icon;
          }

          if(this.$root.current_user && this.$root.current_user.current_agency) {
            if(this.$root.current_user.current_agency.settings && this.$root.current_user.current_agency.settings.icon) {
              return this.$root.current_user.current_agency.settings.icon;
            }
          }
        },

        isPayPortal() {
         if(!this.form || !this.form.domain) {
           return false;
         }

         if(this.form.domain == 'ecourtpayment.com') {
           return true;
         }

         if(this.form.alias == 'ecourtpayment.com') {
           return true;
         }

         return false;
        },

        embedCode() {
          if(!this.form || !this.form.link) {
            return;
          }

          return '<iframe src="https://'+this.form.link+'.'+this.form.domain+'" title="'+this.form.name+'" width="100%" height="100%" frameborder="0" border="0" loading="eager" allowfullscreen="true" allowpaymentrequest="'+this.isPayPortal+'"></iframe>';
        },

  },

  mounted() {

    var self = this;

    this.$eventBus.$on('errors', function (errors) {
      self.errors = errors.data || errors;
      self.processing = false;
    });

  },

  created() {
    this.resetForm();
    this.resetPaymentsForm();
    this.setURLParams();
    this.getDomains();
    this.getRecord();
    this.getEnrollFields();
    this.getLanguages();
  },

  methods: {

    setTitle() {
        document.title = this.form.name + ' | eCourtDate.com';
    },

    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    postForm() {
      this.processing = true;
      
      this.form.payments = this.paymentsForm;

      this.errors = null;

      this.changed = false;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
          }

        }
      })
    },

    resetForm() {

        this.qr_code = null;

        this.form = {
            name: '',
            link: '',
            domain: '',
            redirect: null,
            title: {
              'en': ''
            },
            welcome: {
              'en': ''
            },
            home: {
              'en': ''
            },
            sidebar: {
                'en': ''
            },
            body: {
                'en': ''
            },
            footer: {
                'en': ''
            },
            alerts: {
                'en': ''
            },
            calendar_title: {
                'en': ''
            },
            calendar_description: {
              'en': ''
            },
            optouts: {
              reasons: {
                'en': ''
              },
              title: {
                'en': ''
              }
            },
            logo: '',
            icon: '',
            geolocation: '',
            calendar: null,
            calendar_url: null,
            default: false,
            weather: true,
            virtual: null,
            virtual_code: null,
            verify: '',
            services: '',
            merchant_id: '',
            paypal_email: '',
            self_resources: [],
            enroll_enabled_fields: []
        }
    },

    resetPaymentsForm() {

      this.paymentsForm = {
          mode: 'test',
          card: true,
          paypal: false,
          mail: false,
          ach: true,
          partial_payments: true,
          inperson: false,
          processing_fee: null,
          external_portal: false,
          external_portal_link: null,
          external_portal_instructions: null,
          mail_instructions: null,
          mail_address: null,
          mail_pdf: null,
          inperson_instructions: null,
          ach_instructions: null,
          phone: false,
          phone_number: null,
          phone_instructions: null,
          notify: null,
          terms: null,
          payout: null,
      }

    },

   getRecord() {
    this.processing = true;
    this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
    .then(response => {
      if(response && response.data) {

        if(response.data.uuid) {
          this.form = response.data;
          this.setTitle();
          this.setFields();

          if(response.data.payments) {
            this.paymentsForm = response.data.payments;
          }

          this.getQRCode();
          this.getMessages();

          this.getPortalStatus();
        }

        this.processing = false;
      }
    })
   },

    getPortalStatus() {

      if(!this.form.link || !this.form.domain) {
        return;
      }

      this.portal_status = 'loading';

      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/status')
          .then(response => {
            if(response && response.status) {
              this.portal_status = response.data;
            }
          })
    },

   setFields() {

    var fields = ['title', 'alerts', 'sidebar', 'welcome', 'home', 'footer', 'body', 'calendar_title', 'calendar_description'];

    fields.forEach(f => {
        if(!this.form[f]) {
            this.form[f] = {'en': ''};
        }
    });

    if(!this.form['optouts'] || !this.form['optouts']['reasons']) {
      this.form['optouts'] = {
        reasons: {
          'en': ''
        }
      }
    }

    if(!this.form['optouts']['title']) {
      this.form['optouts']['title'] = {
        'en': ''
      }
    }
    
   },

        getLanguages () {
            this.$http.get('languages')
            .then(response => {
                if(response && response.data && response.data.length) {
                    this.languages = response.data;
                }
            })
        },

        trashLogo() {
            this.form.logo = '';
            this.changed = true;
        },

        trashIcon() {
            this.form.icon = '';
            this.changed = true;
        },

      getEnrollFields () {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/fields.json')
          .then(response => {
              if(response && response.data) {
                    this.enroll_fields = response.data;
              }
          })
      },

        storeLogo () {
            if (this?.$refs?.logo?.files?.length > 0) {
                this.processing = true
                this.storeUpload(this.$refs.logo.files[0])
                .then(response => {
                    if (response && response.uuid) {
                        this.form.logo = response.uuid;
                        this.processing = false;
                    }
                })
            }
        },

        storeIcon () {
            if (this?.$refs?.icon?.files?.length > 0) {
                this.processing = true
                this.storeUpload(this.$refs.icon.files[0])
                .then(response => {
                    if (response && response.uuid) {
                        this.form.icon = response.uuid;
                        this.processing = false;
                    }
                })
            }
        },

        async storeUpload (file) {
            const response = await this.$http.post(this.$apiURL + 'v1/signed_url', {
                'bucket': 'ecdportals',
                'content_type': file.type,
                'content_length': file.size,
                'expires': '',
                'visibility': ''
            })
            if (response && response.data) {
              
                let headers = response.data.headers;

                if ('Host' in headers) {
                    delete headers.Host
                }
                await this.$axios.put(response.data.url, file, {
                    headers: headers
                });

                response.data.extension = file.name.split('.').pop();

                return response.data;
            }
            return null;
        },
   

      getQRCode() {

        if(!this.form.link) {
          return;
        }

        this.$http.post('/qrs?content='+this.setPortalLink())
        .then(response => {
            if(response && response.data) {
                this.qr_code = response.data;
            }
        })
      },

      autoTranslate(language, key) {

        this.processing = true;

        var translator = {
          source_language: 'en',
          target_language: language.key,
          content: this.form[key]['en']
        }

        this.$http.post('/translator', translator)
        .then(response => {
          if(response && response.data) {

            if(response.data.content) {
              this.form[key][language.key] = response.data.content;
              new this.$noty({text: 'Added translation to '+key + ' ' + language.label}).show();
            }

            this.processing = false;
          }
        })
      },

      getMessages() {
          
          this.message_queries.search = 'content:'+this.form.link+'.'+this.form.domain;

          this.getTotalMessages();

          this.$http.get(this.buildQueries('/messages', this.message_queries))
          .then(response => {
              if(response && response.data && response.data.data) {
                  this.messages = response.data.data;
                  this.sortMessages();
              }
          })
      },

      getTotalMessages() {
          this.$http.get(this.buildQueries('/messages/total', this.message_queries))
          .then(response => {
              if(response && response.status === 200) {
                  this.total_messages = response.data;
              }
          })
      },

      sortMessages() {
          this.messages = this.messages.sort((a, b) => Number(new Date(a.scheduled_at)) - Number(new Date(b.scheduled_at)));
      },

      restoreRecord () {
          this.processing = true;
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                      this.form.deleted_at = null;
                      this.form.archived_at = null;
                  }
                  
              }
          })
      },

      archiveRecord () {
          this.processing = true
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                      this.form.archived_at = new Date().toISOString();
                  }

              }
              
          })
      },

      trashRecord () {
          this.processing = true;
          this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
      },

    getDomains() {
      this.$http.get('/domains?fields=domain&limit=100&sortDirection=asc&sort=domain&alias=ecase.io')
      .then(response => {
        if(response && response.status) {
          this.domains = response.data;
          
          this.domains.unshift({'apex': 'ecourtpayment.com'});
          this.domains.unshift({'apex': 'ecase.io'});
        }
      })
    },

    setPortalLink() {

      if(!this.form.link) {
        return;
      }

      var domain = this.form.domain || 'ecase.io';

      return 'https://'+this.form.link+'.'+domain+'/enroll';
    },

    copyToClipboard(value) {
        navigator.clipboard.writeText(value);
        new this.$noty({text: 'Copied to clipboard'}).show();
    },

    setDefaultEnrollFields() {

      this.form.enroll_enabled_fields = [
        {name: "First Name", key: "clients.first_name", type: "Short Text", required: 1, hidden: false, default: null},
        {name: "Last Name", key: "clients.last_name", type: "Short Text", required: 1, hidden: false, default: null},
        {name: "Email", key: "email", type: "Email", required: 1, hidden: false, default: null},
        {name: "Phone", key: "phone", type: "Telephone", required: 1, hidden: false, default: null},
      ];

      this.form.enroll_contacts = this.$root.current_user.email;
      this.form.enroll_title = 'Sign Up to Receive Case Notifications';
      this.form.enroll_description = 'Please fill out the form below to receive notifications.';

      this.changed = true;
    },

    setDefaultPaymentFields() {
      this.resetPaymentsForm();

      this.paymentsForm['notify'] = this.$root.current_user.email;

      this.changed = true;
    }

  },
}
</script>