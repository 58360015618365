<template>
  <div>

    <template v-if="legacy === true">
        <div class="row">
            <div class="col-12">
                <div class="alert alert-warning">Legacy browser detected. Use a modern browser.</div>
            </div><!--Col-->
        </div><!--Row-->
    </template>

    <template v-if="offline === true && !isOnline()">
        <div class="row">
            <div class="col-12">
                <div class="alert alert-danger">No network detected. Refresh or <a @click="reloadPage()" class="alert-link link-hover">click here once back online</a>.</div>
            </div><!--Col-->
        </div><!--Row-->
    </template>

    <template v-if="errors && errors.length > 0">
      <template v-for="(error, index) in errors">
        <div
          :key="index"
          class="alert alert-danger"
        >
          {{ error }}

          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
export default {

data() {
    return {
        offline: false,
        legacy: false,
        errors: []
    }
},

    mounted() {

    var self = this;

    this.$eventBus.$on('errors', function (error) {

    if(error.data && error.data.message) {
      if(error.data.message == 'Network Error') {
        self.errors.unshift(error);
      }
    }

    });

    window.addEventListener("offline", () => {
      this.offline = true;
    });


    },

    created() {
        this.isLegacy();
        this.isOffline();
    },

methods: {

    isLegacy() {

      if (navigator.userAgent.indexOf("MSIE") != -1 ) {
        this.legacy = true;
      }
    
      
    },

    isOffline() {
      
        if(navigator.onLine) {
            this.offline = false;
            return;
        }

        this.offline = true;
    },

    isOnline() {

      if(navigator.onLine) {
        return true;
      }

      return false;
    },

    reloadPage() {
        window.location.reload();
    }
}

}
</script>