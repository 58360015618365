<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="title"
    />

    <div class="row mb-3">
      <div class="col-2 d-none d-print-block">
        <img
          src="https://ecdassets.s3-us-gov-east-1.amazonaws.com/img/logo.svg"
          width="100"
          alt="eCourtDate logo"
        >
      </div>
      <div class="col-md-6 col-lg-3 order-2 order-md-1">
        <template v-if="form.name">
          <h1>{{ form.name }}</h1>
          <template v-if="form.description">
            <p class="m-0">{{ form.description }}</p>
          </template>
          <p><router-link :to="{name: 'reports.index'}">{{ title }}s</router-link> / Edit {{ title }}</p>
        </template>

        <template v-if="form.from_date && form.to_date">
          <p class="m-0">
            {{ form.from_date | datetime }} - {{ form.to_date | datetime }}
          </p>
        </template>

        <template v-if="form.last_active">
          <span class="badge badge-pill badge-success">Last Active {{ form.last_active | dateTimezone | timeago }}</span>
        </template>
        <template v-else>
          <span class="badge badge-pill badge-danger">Not Active</span>
        </template>

      </div><!--Col-->
      <div class="col-md-6 offset-6 offset-md-0 col-lg-9 align-items-start justify-content-between d-print-none order-md-2 order-0 text-right">
        <div class="btn-group d-print-none">
        

          <button
            type="button"
            class="btn btn-secondary dropdown-toggle rounded-0"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            id="toolsButton"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu" aria-labelledby="toolsButton">

            <a
              class="dropdown-item"
              @click="printReport"
            >Print</a>
            <a
              v-if="form.deleted_at || form.archived_at"
              class="dropdown-item"
              @click="restoreRecord()"
            >Restore</a>
            <a
              v-if="!form.deleted_at"
              class="dropdown-item"
              @click="trashRecord()"
            >Trash</a>
          </div>
          <button
            v-if="!form.deleted_at"
            type="submit"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>
          <router-link
            :to="{ name: 'reports.index' }"
            class="btn btn-danger radius-left-0"
          >
            Close
          </router-link>
        </div>
      </div><!--Col-->
    </div><!--Row-->
    <div class="row">

        <div class="col-sm-12 col-md-3 d-print-none mb-3">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="postForm()">
                <div class="form-group">
                  <label for="name">{{ title }} Name</label>
                  <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    class="form-control"
                  >
                </div>
                <div class="form-group">
                  <label for="description">{{ title }} Description</label>
                  <textarea
                    id="description"
                    v-model="form.description"
                    class="form-control"
                    rows="3"
                  />
                </div>

                <div class="form-group">
                  <label for="from_date">Default From Date</label>
                  <select
                    id="from_date"
                    v-model="from_date"
                    class="form-control"
                  >
                    <option value="" />
                    <option value="custom_from">
                      Custom
                    </option>
                    <option
                      v-for="date in date_filters"
                      :key="date.key"
                      :value="date.key"
                    >
                      {{ date.label }}
                    </option>
                  </select>
                </div>
                <template v-if="from_date === 'custom_from'">
                  <div class="form-group vbDatePicker">
                    <label for="custom_from_date">Custom From Date</label>
                    <input
                      id="custom_from_date"
                      v-model="custom_from_date"
                      placeholder="mm/dd/yyyy"
                      type="date"
                      class="form-control"
                    >
                  </div>

                  <div class="form-group vbTimePicker">
                    <label for="custom_from_time">Custom From Time</label>
                    <input
                      id="custom_from_time"
                      v-model="custom_from_time"
                      placeholder="hh:mm AM"
                      type="time"
                      class="form-control"
                    >
                  </div>
                </template>
                <div class="form-group">
                  <label for="to_date">Default To Date</label>
                  <select
                    id="to_date"
                    v-model="to_date"
                    class="form-control"
                  >
                    <option value="" />
                    <option value="custom_to">
                      Custom
                    </option>
                    <option
                      v-for="date in date_filters"
                      :key="date.key"
                      :value="date.key"
                    >
                      {{ date.label }}
                    </option>
                  </select>
                </div>
                <template v-if="to_date === 'custom_to'">
                  <div class="form-group vbDatePicker">
                    <label for="custom_to_date">Custom To Date</label>
                    <input
                      id="custom_to_date"
                      v-model="custom_to_date"
                      placeholder="mm/dd/yyyy"
                      type="date"
                      class="form-control"
                    >
                  </div>
                  <div class="form-group vbTimePicker">
                    <label for="custom_to_time">Custom To Time</label>
                    <input
                      id="custom_to_time"
                      v-model="custom_to_time"
                      placeholder="hh:mm AM"
                      type="time"
                      class="form-control"
                    >
                  </div>
                </template>
                <div class="form-group">
                  <label for="datapoints">Datapoints</label><br>
                  <multi-select 
                    id="datapoints"
                    v-model="form.datapoints" 
                    :options="datapoints_options"
                    :multiple="true"
                    :close-on-select="false"
                    track-by="title"
                    label="title"
                    :max="20"
                  />
                </div>
                <div class="form-group">
                  <label for="export">Auto-Export</label>
                  <select
                    id="export"
                    v-model="form.export"
                    class="form-control"
                  >
                    <option value="" />
                    <option
                      v-for="(date, date_key) in export_dates"
                      :key="'auto_export_'+date_key"
                      :value="date.value"
                    >
                      {{ date.label }}
                    </option>
                  </select>
                </div>

                <template v-if="form.export">

                <div class="form-group">
                  <label
                    for="chosenEmails"
                    class="text-capitalize"
                  >Notify When Auto-Export is Complete</label><br>
                  <multi-select 
                    id="chosenEmails"
                    v-model="chosenEmails"
                    :options="emailTo"
                    :multiple="true"
                    group-values="data"
                    group-label="category"
                    :group-select="false"
                    placeholder="Type to search"
                    :close-on-select="false"
                    label="label"
                    track-by="value"
                  />
                </div>

                <div class="form-group custom-control custom-switch">
                  <input
                    id="attach_emails"
                    v-model="form.attach_emails"
                    type="checkbox"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="attach_emails"
                  > Attach to Emails</label>
                </div>


                </template>

                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                  :hidden="form.deleted_at || form.archived_at"
                >
                  Save
                </button>
              </form>

            </div><!--Body-->
          </div><!--Card-->

          <audit-logs :form="form" class="mt-3" />

        </div><!--Col-->

      <div class="col-sm-12 col-md-6">
        <template v-if="form.datapoints && form.datapoints.length">
          <datapoints
            :datapoints="form.datapoints"
            :from_date="form.from_date"
            :from_time="form.from_time"
            :to_date="form.to_date"
            :to_time="form.to_time"
          />
        </template>

        <template v-if="!form.deleted_at && !form.archived_at">

        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">
              Add Dataset
            </p>
            <form @submit.prevent="addDataset">
              <div class="form-group">
                <label for="add_dataset_name">Name</label>
                <input
                    id="add_dataset_name"
                    v-model="datasetForm.name"
                    type="text"
                    class="form-control"
                    required
                >
              </div>
              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                Add Dataset
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        </template>

        <template v-if="datasets && datasets.length">
          <template v-for="dataset in datasets">
            <dataset
              :key="dataset.uuid"
              :dataset="dataset"
              :from_date="form.from_date"
              :to_date="form.to_date"
              class="m-0 mb-3"
              :report="form"
            />
          </template>
        </template>
        <template v-if="!processing && !datasets.length">
          <div class="alert alert-warning">
            No datasets found.
          </div>
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

            <div
              class="card"
              style="max-height:600px; overflow-y:auto"
            >
              <div class="card-body">

                <div class="row">
                  <div class="col-6">
                    <p class="card-title">{{ report_exports.length }} Auto-Exports</p>
                  </div><!--Col-->
                  <div class="col-6 text-right">

                    <template v-if="form.status == 'exporting'">

                      <div class="spinner-grow text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>

                    </template>

                    <template v-if="form.status != 'exporting'">
                    <button type="button" class="btn btn-outline-success" @click="exportOneoff()" :disabled="processing" hidden>Export One-Off</button>
                    </template>

                  </div><!--Col-->
                </div><!--Row-->

                <loader :is-loading="searching" />

                <form @submit.prevent="getExports()">
                  <div class="input-group mt-3" :hidden="searching">
                    <input
                      id="search_exports"
                      v-model="search_exports"
                      type="search"
                      class="form-control"
                      placeholder="Search by file name..."
                    >
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary"
                        type="submit"
                        :disabled="searching"
                      >
                       Search
                      </button>
                    </div>
                  </div>
                </form>

              </div><!--Body-->
            </div><!--Card-->

                <ul class="list-group mt-3">
                  <template v-if="report_exports && report_exports.length > 0">
                    <template v-for="(report_export, index) in report_exports">
                      <button
                        :key="'export_report_'+index"
                        type="button"
                        class="list-group-item list-group-item-action"
                        aria-current="true"
                        @click="saveExport(report_export.file)"
                      >
                        {{ report_export.file }}

                        <span class="ml-2 small text-muted">{{ report_export.size | fileSize }}</span>
                      </button>
                    </template>
                  </template>
                </ul>

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>

<script>
// import _ from 'lodash';
import jsonParse from '../mixins/jsonParse';

export default {
    mixins: [jsonParse],
    data() {
        return {
            processing: false,
            searching: false,
            title: 'Report',
            base_url: '/reports',
            form: {
                datapoints: [],
                attach_emails: true
            },
            from_date: 'start_month',
            to_date: 'end_month',
            filter_to_date: '',
            custom_from_date: '',
            custom_from_time: '00:00',
            custom_to_date: '',
            custom_to_time: '23:59',
            search_exports: '',
            datasets: [],
            report: [],
            report_exports: [],
            emails: [],
            emailTo: [
            {category: 'Roles', data: []},
            {category: 'Users', data: []}
            ],
            chosenEmails: [],
            date_format: 'MM/DD/YY HH:mm z',
            custom_date_format: 'YYYY-MM-DD HH:mm',
            datapoints_options: [],
            date_filters: [],
            export_dates: [],
            datasetForm: {
                report: null,
                name: null
            }
        }
    },

    created () {

        this.getRecord();        
        this.getUsers();
        this.getRoles();
        this.getDateFilters();
        this.getExportDateFilters();
        this.getDatapoints();

        var self = this;

        this.$eventBus.$on('datasetCreated', function (dataset) {
            self.datasets.unshift(dataset);
        });

        this.$eventBus.$on('datasetDeleted', function (dataset) {
            self.datasets.splice(self.datasets.findIndex((obj => obj.uuid == dataset)), 1);
        });

    },

    methods: {

        getExportDateFilters () {
            this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/export_frequencies.json')
            .then(response => {
                if (response && response.data) {
                    this.export_dates = response.data;
                }
            })
        },

        getDateFilters () {
            this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/date_filters.json')
            .then(response => {
                if(response && response.data) {
                    this.date_filters = response.data;
                }
            })
        },

        postForm() {
            this.processing = true;

            let preferences = {
                from_date: this.$options.filters.formatDate(this.from_date),
                to_date: this.$options.filters.formatDate(this.to_date),
                custom_from_date: this.$options.filters.formatDate(this.custom_from_date),
                custom_from_time: this.$options.filters.formatTime(this.custom_from_time),
                custom_to_date: this.$options.filters.formatDate(this.custom_to_date),
                custom_to_time: this.$options.filters.formatTime(this.custom_to_time)
            }

            this.form.preferences = JSON.stringify(preferences);

            this.form.emails = JSON.stringify(this.chosenEmails.map(item => item.value));

            this.$http.patch(this.base_url+'/'+this.form.uuid, this.form)
            .then(response => {
                if (response && response.data) {
                    if(response.data.updated_at) {
                      this.form.updated_at = new Date().toISOString();
                      new this.$noty({text: this.title + ' updated'}).show();
                      this.$eventBus.$emit('reportUpdated', {});
                    }

                    this.processing = false;
                }
            })
        },

        setFields () {

            if (this.form.preferences) {
                this.preferences = this.form.preferences;
                this.from_date = this.preferences.from_date;
                this.to_date = this.preferences.to_date;
                this.custom_from_date = this.preferences.custom_from_date;
                this.custom_from_time = this.preferences.custom_from_time;
                this.custom_to_date = this.preferences.custom_to_date;
                this.custom_to_time = this.preferences.custom_to_time;
            }


            if (this.form.datasets && this.form.datasets.length) {
                this.datasets = this.form.datasets;
            }
            if (this.form.emails && this.form.emails.length) {
                var emailTo = this.parseJSON(this.form.emails).filter(item => typeof(item) === 'string');
                var emails = emailTo.filter(item => item.includes('@'));
                var roles = emailTo.filter(item => !item.includes('@'));
                var chosenEmails = emails.map((email) => {
                    var user = this.emails.find(user => user.email === email)
                    if (user) {
                        return {label: email + ', ' + user.name, value: email };
                    } else {
                        return { label: email, value: email };
                    }
                })
                var chosenRoles = roles.map(role => { return {label: role, value: role} });
                this.chosenEmails = [...chosenRoles, ...chosenEmails];
            }

            this.setTitle();

            this.$eventBus.$emit('reportUpdated');
            this.$eventBus.$emit('reportChanged', this.form.name);
        },

        setTitle() {
            if(this.form.name) {
                document.title = this.form.name + ' | eCourtDate.com';
            }
        },

        getRecord () {
            this.processing = true;
            this.$http.get(this.base_url+'/'+this.$route.params.id)
            .then(response => {
                if (response && response.data) {
                    
                    this.form = response.data;
                    this.setFields();

                    if(this.form.uuid) {
                      this.getExports();                      
                    }

                    this.processing = false;
                }
            })
        },

        getExports() {
            this.searching = true;
            this.$http.get(this.base_url+'/'+this.form.uuid+'/exports?limit=1000&search='+this.search_exports)
            .then(response => {
                if(response && response.status) {
                    this.report_exports = response.data;
                    this.searching = false;
                }
            })
        },

        restoreRecord() {
          this.processing = true;
          this.$http.put(this.base_url+'/'+this.form.uuid+'/restore')
          .then(response => {
            if (response && response.status) {

              if(response.status === 200) {
                new this.$noty({ text: this.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
                this.form.updated_at = new Date().toISOString();
              }

              this.processing = false;
            }
          })
        },

        trashRecord () {
            this.processing = true;
            this.$http.delete(this.base_url+'/'+this.form.uuid)
            .then(response => {
                if(response && response.status) {

                  if(response.status === 204) {
                    new this.$noty({text: this.title + ' trashed', type: 'error'}).show();
                    this.form.deleted_at = new Date().toISOString();
                  }

                  this.processing = false;

                }
            })
        },

        getDatapoints () {
            this.processing = true;
            this.$http.get('/datapoints')
            .then(response => {
                if (response && response.data) {
                    this.datapoints_options = response.data;
                }
                this.processing = false;
            })
        },

        printReport () {
            const sleep = (milliseconds) => {
                return new Promise(resolve => setTimeout(resolve, milliseconds))
            }
            sleep(250).then(() => {
                window.print();
            })      
        },

        getUsers () {
            this.$http.get('/users?limit=100')
            .then(response => {
                if (response && response.data) {
                    this.emails = Object.values(response.data);
                    var data = response.data.map((item) => { return {label: item.email + ', ' + item.name, value: item.email }});
                    this.emailTo[1].data = data;
                }
            })
        },

        getRoles () {
            this.$http.get('/roles')
            .then(response => {
                if (response && response.data) {
                    let roles = Object.values(response.data);
                    this.emails = this.emails.concat(roles);
                    var data = roles.map((item) => { return {label: item.name, value: item.name }});
                    this.emailTo[0].data = data;
                }
            })
        },

        saveExport (file) {
            this.$http.get("/reports/export/" + this.form.uuid + '?file=' + file)
            .then(response => {
                if(response && response.data) {
                    new this.$noty({text: 'Downloading ' + file}).show();
                    window.open(response.data, '_blank');
                }

                this.processing = false;
            })
        },

        addDataset () {

            this.processing = true;
            this.datasetForm.report = this.form.uuid;

            this.$http.post('/datasets', this.datasetForm)
            .then(response => {
                if (response && response.data) {

                    new this.$noty({text: 'Adding dataset ' + this.datasetForm.name}).show();

                    this.datasetForm = {
                        report: null,
                        name: null
                    }

                    this.postForm();
                }
            })
        },

        exportOneoff() {

          this.form.status = 'exporting';

          this.postForm();
        }
    }
}
</script>
