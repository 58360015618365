<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row mb-3">
      <div class="col-md-8">
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-md-4 text-right">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">

                <a
                  v-if="form.deleted_at || form.archived_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>

                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <div class="card">
          <div class="card-body">

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="type">Type</label>
                <select id="type" class="form-control text-uppercase" v-model="form.type" :disabled="processing" required>
                  <template v-for="(method_type, type_index) in $root.pay_methods">
                    <option :key="'method_type_'+type_index" :value="method_type">{{ method_type }}</option>
                  </template>
                </select>
              </div>

              <template v-if="form.type && form.type == 'card'">

              <div class="form-group">
                <label for="brand">Brand</label>
                <select id="brand" class="form-control text-uppercase" v-model="form.brand" :disabled="processing" required>
                  <template v-for="(method_brand, type_index) in $root.card_brands">
                    <option :key="'method_brand_'+type_index" :value="method_brand">{{ method_brand }}</option>
                  </template>
                </select>
              </div>

                <template v-if="form.brand">
                  <img :src="$root.assets_url+'images/payments/'+form.brand+'.svg'" width="80" :alt="form.brand" class="mb-1">
                </template>

              <div class="form-group">
                <label for="last4">Last 4</label>
                <input id="last4" type="text" class="form-control" v-model="form.last4">
              </div>

              <div class="form-group">
                <label for="exp_month">Expiration Month</label>
                <input id="exp_month" type="text" class="form-control" v-model="form.exp_month">
              </div>

                <div class="form-group">
                  <label for="exp_year">Expiration Year</label>
                  <input id="exp_year" type="text" class="form-control" v-model="form.exp_year">
                </div>

              </template>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form.gateway && form.gateway.id">

          <div class="card mt-3">
            <div class="card-body">

              <p class="card-title">Payment Gateway</p>

              <ul class="list-group">

              <template v-if="form.gateway.card">

                <template v-if="form.gateway.card.funding">
                  <li class="list-group-item">Funding <span class="badge badge-pill badge-secondary text-uppercase">{{ form.gateway.card.funding }}</span></li>
                </template>

                <li class="list-group-item">Live Mode

                  <template v-if="form.gateway.livemode == true">
                          <span class="badge badge-pill badge-success"><i class="fa fa-check"></i> YES</span>
                  </template>
                  <template v-else>
                          <span class="badge badge-pill badge-danger"><i class="fa fa-times"></i> NO</span>
                  </template>
                </li>

                <template v-if="form.gateway.card.checks">
                    <template v-for="(card_check, check_index) in form.gateway.card.checks">
                      <li :key="'card_check_'+check_index" class="list-group-item text-uppercase">{{ check_index | rmDashes }} 
                        <template v-if="card_check == 'pass'">
                          <span class="badge badge-pill badge-success"><i class="fa fa-check"></i> PASS</span>
                        </template>
                        <template v-else>
                          <span class="badge badge-pill badge-danger"><i class="fa fa-times"></i> {{ card_check || 'No Data' }}</span>
                        </template>
                      </li>
                    </template>
                </template>

                <template v-if="form.gateway.card.three_d_secure_usage">
                  <li class="list-group-item">3D Supported 
                    <template v-if="form.gateway.card.three_d_secure_usage.supported == true">
                      <span class="badge badge-pill badge-success"><i class="fa fa-check"></i> <span class="sr-only">Supported</span></span>
                    </template>
                    <template v-else>
                      <span class="badge badge-pill badge-danger"><i class="fa fa-times"></i> <span class="sr-only">Not Supported</span></span>
                    </template>
                  </li>
                </template>

                <li class="list-group-item">Wallet

                  <template v-if="form.gateway.card.wallet">
                      <span class="badge badge-pill badge-success"><i class="fa fa-check"></i> <span class="sr-only">Yes</span></span>
                  </template>
                  <template v-else>
                      <span class="badge badge-pill badge-danger"><i class="fa fa-times"></i> <span class="sr-only">No</span></span>
                  </template>

                </li>

                <template v-if="form.gateway.card.fingerprint">
                  <li class="list-group-item">Fingerprint <i class="fa fa-fingerprint"></i> {{ form.gateway.card.fingerprint }}</li>
                </template>

              </template><!--CardGateway-->

              </ul>

            </div><!--Body-->
          </div><!--Card-->

        </template>

        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-lg-6 col-sm-12">

        <template v-if="form.client">
          <client-card :client="form.client" class="mb-3" />
        </template>
       
      </div><!--Col-->

    </div>
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      uploading: false,
      form: {},
      queries: {},
      total: 0,
      records: []
    }
  },

  created() {
    this.resetRecords();
    this.resetForm();
    this.getRecord();
  },

  methods: {


    resetRecords() {
      this.records = [];
      this.total = 0;
    },

    resetForm() {
      this.form = {
        client: null,
        type: null,
        brand: null,
        last4: null,
        exp_month: null,
        exp_year: null
      }
    },


    getRecord() {

        this.processing = true;

        this.$http.get(this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                
                this.form = response.data;

                this.processing = false;

                this.getRecords();
            }
        })
    },

    postForm() {

      this.processing = true;

      this.$http.patch(this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
          }

        }
      })
    },


    restoreRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
      .then(response => {
        if(response && response.data) {
              
              this.processing = false;

              if(response.status === 200) {
                new this.$noty({'text': this.$route.meta.title + ' restored'}).show()
                this.form.deleted_at = null;
                this.form.archived_at = null;
                this.form.updated_at = new Date().toISOString();
              }

        }
      })
    },


  trashRecord () {
      this.processing = true;
      this.$http.delete(this.$route.meta.base_url + '/' + this.form.uuid)
      .then((response) => {
        if(response && response.status) {
          this.processing = false;

          if(response.status === 204) {
            new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
            this.form.deleted_at = new Date().toISOString();
          }
        }
      })
  },

  }
}
</script>