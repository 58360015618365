<template>
  <div>
    <template v-if="payments && payments.length > 0">
      <ul class="list-group mt-3">
        <template v-for="payment in payments">
          <li
            :key="payment.uuid"
            class="list-group-item"
          >
            <a :href="'/payments/'+payment.uuid">Amount: ${{ payment.amount }} 

              <template v-if="payment.issued_at">
                Issued {{ payment.issued_at | date }}
              </template>

              <template v-if="payment.due_at">
                Due {{ payment.due_at | date }}
              </template>

              <template v-if="payment.status">
                <span class="badge badge-primary">{{ payment.status }}</span>
              </template>

            </a>
          </li>
        </template>
      </ul>
    </template>
  </div>
</template>
<script>
export default {

props: [ 'payments' ],

data () {
    return {

    }
},

methods: {


}
}
</script>