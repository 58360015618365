<template>
  <div class="container py-5">
    <div class="d-flex flex-column align-items-center justify-content-center py-5">
      <div class="row">
        <div class="col-12 text-center">
          <div class="card">
            <div class="card-body">

        <a href="https://ecourtdate.com/referrals" target="_blank">
          <img
          class="mb-3"
          :src="$root.assets_url+'images/logos/logo-blue.png'"
          height="40"
          alt="eCourtDate.com Referrals Program"
          loading="lazy"
        ></a>

              <h1>Refer eCourtDate <i class="fa-solid fa-handshake"></i></h1>
              <h3>Let's spread the word and benefit together.</h3>

              <p class="lead">Know of an agency that would find our platform useful?</p>

              <template v-if="$root.current_user && $root.current_user.current_agency">
                
                <h4 class="pt-5">What {{ $root.current_user.current_agency.name }} Gets:</h4>

                <p><i class="fa-solid fa-check"></i> When trial is activated: 1K Message Credits</p>

                <p><i class="fa-solid fa-check"></i> When plan is activated: 10K Message Credits</p>

                <h4 class="pb-5">What Referrals Get:</h4>

                <p><i class="fa-solid fa-check"></i> Free 30 day trial and 100 free message credits</p>

                <p><i class="fa-solid fa-check"></i> 10K message credits with any <a href="https://ecourtdate.com/pricing" target="_blank">Subscription Plan</a></p>

                <h4 class="pt-5 mt-3">Share Your Referral Link</h4>

                <p><a :href="referralLink" target="_blank"><mark>{{ referralLink }}</mark></a></p>

              <button type="button" class="btn btn-secondary" @click="copyToClipboard(referralLink)" :disabled="processing"><i class="fa fa-copy"></i> Copy Referral Link</button>

              <form @submit.prevent="postForm()">

              <div class="form-group mt-3 pt-5">
                <label for="referral_to">Or Send a Message (Phone or Email)</label>
                <input id="referral_to" type="text" class="form-control" v-model="form.to" required>
                <small class="text-muted">We'll send a one-off message with a referral link.</small>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Send Referral Invite</button>

              </form>

              <p class="mt-5 text-muted"><a :href="'https://billing.ecourtdate.com/referrals?r='+$root.current_user.current_agency.reference" target="_blank">Track referral history here</a>.</p>

              </template>

            </div><!--Body-->
          </div><!--Card-->


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import confetti from "canvas-confetti";

export default {

    data() {
        return {
          processing: false,
          form: {},
          customer: {}
        }
    },

    computed: {


      referralLink: function() {

        if(!this.$root.current_user) {
          return;
        }

        var ref = this.$root.current_user.current_agency.reference;

        if(this.customer && this.customer['referral_code']) {
          ref = this.customer['referral_code'];
        }

        return 'https://ecourtdate.com/referrals?r='+ref;
      }
    },



    created () {
      this.resetForm();
      this.getCustomer();
    },

    methods: {

      resetForm() {
        this.form = {
          to: null,
          referral_code: null
        }
      },

      copyToClipboard(value) {
          this.processing = true;
          navigator.clipboard.writeText(value);
          new this.$noty({text: 'Referral link copied to clipboard'}).show();
          this.processing = false;
      },

      getCustomer() {
        this.customer = {};
        this.processing = true;
        this.$http.get('/agencies/customer')
            .then(response => {
              if(response && response.data) {
                this.customer = response.data;
                this.processing = false;
              }
            })
      },

      postForm() {
        this.processing = true;

        if(this.customer && this.customer['referral_code']) {
          this.form.referral_code = this.customer['referral_code'];
        }

        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {
            new this.$noty({text: 'Thank you for your referral!'}).show();

            this.processing = false;

            confetti({
              particleCount: 150
            });

            setTimeout(() => {
              confetti.reset();
            }, 10000);   
    
            this.resetForm();
          }
        })
      }

    }

}
</script>