<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />

    <template v-if="errors && errors.message">
            <div v-if="errors.message != 'Network Error'">
              <div class="alert alert-danger">
                {{ errors.message }}
              </div>
            </div>
    </template>

    <div class="row">
      <div class="col-md-4">
          <h1>{{ form.name }}</h1>
        <template v-if="form.phone_number">
          <h3 class="font-weight-bold"><a :href="'tel:'+form.phone_number">{{ form.phone_number | formatPhone }}</a></h3>
        </template>
        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-md-4">
        <template v-if="form.phone_number">

          <div class="btn-group">
            <button type="button" class="btn btn-outline-secondary" @click="copyToClipboard(form.phone_number)"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
          </div>

        </template>


      </div><!--Col-->


      <div class="col-md-4 text-right">
        <div class="btn-group">

          <div class="btn-group">
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">
              <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
              >Archive</a>
              <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
              >Restore</a>
              <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
              >Trash</a>
            </div>
          </div><!--BtnGroup-->

          <button
              v-if="!form.archived_at && !form.deleted_at"
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
          >
            Save
          </button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-md-3 col-sm-12">
        <div class="card">
          <div class="card-body">

            <form
                @submit.prevent="postForm"
                @keyup.enter="postForm"
            >
              <div class="row mb-3" v-if="phone_status && phone_status.gateway">

                  <div class="col-sm-12 col-md-6">


                    <template v-if="form.type != 'short_code' && form.type != 'port' && form.gateway && phone_status && phone_status.status">
                    <p class="small m-0 text-muted">Gateway Status {{ form.gateway }}</p>
                    <span class="badge badge-pill text-uppercase" :class="setStatusClass(phone_status.status)">{{ phone_status.status }}</span>
                    </template>

                    <template v-if="form.type && form.type == 'port'">
                      <span class="badge badge-pill badge-primary">PORT</span>
                    </template>


                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">

                    <template v-if="phone_status && phone_status.gateway && phone_status.gateway.emergency_status">

                      <p class="small m-0 text-muted">e911 Status</p>
                      <span class="badge badge-pill text-uppercase" :class="setStatusClass(phone_status.gateway.emergency_status)">{{ phone_status.gateway.emergency_status }}</span>

                    </template>

                  </div><!--Col-->


              </div><!--Row-->

              <div class="form-group">
                <label for="name">Name</label>
                <input id="name" class="form-control" v-model="form.name">
                <small class="text-muted">Used as the outbound Caller ID name</small>
              </div>

              <div class="form-group">
                <label for="phone_number">Phone Number</label>
                <input id="phone_number" class="form-control" v-model="form.phone_number" disabled>
              </div>

              <template v-if="form.outside_business">

                <p class="m-0">
                  <strong>Business Hours</strong>
                </p>

                    <div class="form-group">
                      <label for="from_time">From Time</label>
                      <input
                          id="from_time"
                          v-model="form.from_time"
                          type="time"
                          class="form-control"
                      >
                      <small class="form-text muted">Defaults to Business From Time</small>
                    </div>

                    <div class="form-group">
                      <label for="to_time">To Time</label>
                      <input
                          id="to_time"
                          v-model="form.to_time"
                          type="time"
                          class="form-control"
                      >
                      <small class="form-text muted">Defaults to Business To Time</small>
                    </div>

                <div class="form-group">
                  <label for="business_days">Business Days</label>
                  <multi-select
                      id="business_days"
                      v-model="form.business_days"
                      :options="business_days"
                      :multiple="true"
                      :close-on-select="false"
                  />
                </div>

              </template>

              <div class="form-group custom-control custom-switch">
                <input
                    id="primary"
                    v-model="form.primary"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="primary"
                >Primary</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="record"
                    v-model="form.record"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="record"
                >Record Calls</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="outsideBusiness"
                    v-model="form.outside_business"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="outsideBusiness"
                >Outside of Business Hours</label>
              </div>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
            class="mt-3"
            :form="form"
        />
      </div><!--Col-->

      <div class="col-md-6 col-sm-12">

        <template v-if="form.type && form.type == 'short_code'">
        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">Short Code</p>

            <div class="btn-group">
              <a href="https://www.usshortcodes.com/find-short-code" target="_blank" class="btn btn-outline-primary btn-sm"><i class="fa fa-link"></i> Short Code Lookup</a>
              <a :href="$root.assets_url+'files/shortcode-authorization.pdf'" target="_blank" class="btn btn-outline-primary btn-sm"><i class="fa fa-file-pdf"></i> Download Authorization Form</a>
            </div><!--Group-->

          </div><!--Body-->
        </div><!--Card-->
        </template>

        <template v-if="form.type && form.type == 'port'">
        
        <div class="card mb-3">
          <div class="card-body">

            <p class="card-title m-0">Portability Checker</p>

            <template v-if="port_status && port_status.record_type">

              <template v-if="port_status.portable == true">
                <span class="badge badge-pill badge-success">PORTABLE</span>
              </template>
              <template v-else>
                <span class="badge badge-pill badge-danger">NOT PORTABLE</span>
              </template>

              <template v-if="port_status.fast_portable == true">
                <span class="badge badge-pill badge-success"><i class="fa fa-bolt-lightning"></i> FAST PORT</span>
              </template>

              <template v-if="port_status.fast_portable == false">
                <span class="badge badge-pill badge-danger">NOT FAST PORT</span>
              </template>

              <template v-if="port_status.messaging_capable == true">
                <span class="badge badge-pill badge-success"><i class="fa fa-sms"></i> MESSAGING CAPABLE</span>
              </template>
              <template v-else>
                <span class="badge badge-pill badge-danger">NO MESSAGING SUPPORT</span>
              </template>

              <template v-if="port_status.portable === true">
              <p class="m-0 mt-3">Standard Port Timeframe: 7 - 10 business days.</p>
              <p class="m-0 mb-3">Fast Port Timeframe: 2 - 3 business days.</p>
              </template>

            <template v-if="port_status.phone_number">
              <p class="m-0">
                <strong class="mr-1">Phone Number:</strong>
                <a :href="'tel:'+port_status.phone_number">{{ port_status.phone_number | formatPhone }}</a>
              </p>
            </template>

            <template v-if="port_status.phone_number_type">
              <p class="m-0">
                <strong class="mr-1">Type:</strong>
                {{ port_status.phone_number_type }}
              </p>
            </template>

            <template v-if="port_status.carrier_name">
              <p class="m-0">
                <strong class="mr-1">Carrier:</strong>
                {{ port_status.carrier_name }}
              </p>
            </template>

              <template v-if="port_status.not_portable_reason">
                <p class="m-0">
                  <strong class="mr-1">Not Portable Reason:</strong>
                  {{ port_status.not_portable_reason }}
                </p>
              </template>

              <template v-if="port_status.not_portable_reason_description">
                <p class="m-0">
                  <strong class="mr-1">Not Portable Description:</strong>
                  {{ port_status.not_portable_reason_description }}
                </p>
              </template>

              <template v-if="port_status.portable == true">
                <a :href="$root.assets_url+'files/loa-form.pdf'" target="_blank" class="btn btn-outline-primary my-3"><i class="fa fa-file-pdf"></i> LOA Form</a>
              </template>

              <template v-if="form.type == 'toll_free'">
                <a :href="$root.assets_url+'files/loa-form-toll-free.pdf'" target="_blank" class="btn btn-outline-primary my-3"><i class="fa fa-file-pdf"></i> Toll-Free LOA Form</a>
              </template>

            </template>


          </div><!--Body-->
        </div><!--Card-->
        
        </template>


        <div class="card">
          <div class="card-body">

            <div class="row mb-3">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">Inbound Settings</p>

                <template v-if="form.is_outside_business">
                  <span class="badge badge-danger">Outside Business Now</span>
                </template>

                <template v-if="!form.is_outside_business">
                  <span class="badge badge-success">Inside Business Now</span>
                </template>

              </div>
              <div class="col-sm-12 col-md-6 text-right">
                <button
                    type="button"
                    class="btn btn-outline-success btn-sm text-capitalize"
                    @click="autoTranslate(current_language)"
                    :disabled="processing || current_language.key == 'en'"
                >
                  <i class="fa fa-language"></i> Auto Translate
                </button>
                <div class="btn-group">
                <button
                    type="button"
                    class="btn btn-outline-primary btn-sm dropdown-toggle text-uppercase"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    :disabled="processing"
                >
                  {{ current_language.label }}
                </button>
                <div class="dropdown-menu text-uppercase">
                  <template v-for="language in languages">
                    <a
                        :key="language.key"
                        class="dropdown-item"
                        @click="changeLanguage(language)"
                    >
                      {{ language.label }}
                    </a>
                  </template>
                </div>
                  </div><!--Group-->
              </div><!--Col-->
            </div><!--Row-->

            <div class="row">
              <div class="col-sm-12 col-xl-6">
                <div class="form-group">
                  <label for="inbound_calls">Inbound Calls Response</label>
                  <textarea
                      id="inbound_calls"
                      v-model="form.inbound_calls[current_language.key]"
                      type="text"
                      class="form-control"
                      rows="5"
                  />
                </div>
              </div><!--Col-->

              <div class="col-sm-12 col-xl-6">
                <p>Preview</p>

                <template v-if="preview_inbound_calls && preview_inbound_calls.length">
                  <p
                      :class="setClass()"
                      :dir="current_direction"
                      :lang="current_language.key"
                  >
                    {{ preview_inbound_calls }}
                  </p>
                </template>

                <div class="btn-group">
                  <button
                      type="button"
                      class="btn btn-outline-secondary btn-sm"
                      :disabled="playing"
                      @click="updatePreviews()"
                  >
                    Refresh Preview
                  </button>

                  <button
                      type="button"
                      class="btn btn-outline-secondary btn-sm"
                      :disabled="playing"
                      @click="synthesizeContent(preview_inbound_calls)"
                  >
                    <template v-if="!playing">
                      Play Audio
                    </template>
                    <template v-else>
                          <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                          />
                      Loading...
                    </template>
                  </button>

                  <template v-if="audio_url">
                    <a
                        :href="audio_url"
                        class="btn btn-outline-secondary btn-sm"
                        target="_blank"
                        :disabled="playing"
                    ><i class="fa fa-link" /> MP3</a>
                  </template>
                </div>
              </div><!--Col-->
            </div><!--Row-->

            <div class="form-group">
              <label for="inbound_texts">Inbound Texts Auto-Reply</label>
              <textarea
                  id="inbound_texts"
                  v-model="form.inbound_texts"
                  class="form-control"
                  rows="5"
              />
            </div>

            <div class="form-group">
              <label for="inbound_enrolls">Enroll Auto-Reply</label>
              <textarea
                  id="inbound_enrolls"
                  v-model="form.inbound_enrolls"
                  class="form-control"
                  rows="5"
              />
            </div>

            <div class="form-group">
              <label for="optout_reply">Opt-out Auto-Reply</label>
              <textarea
                  id="optout_reply"
                  v-model="form.optout_reply"
                  class="form-control"
                  rows="5"
              />
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="forward_calls">Auto-Forward Inbound Calls</label>
                  <input
                      id="forward_calls"
                      v-model="form.forward_calls"
                      type="text"
                      class="form-control"
                  >
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="forward_texts">Auto-Forward Inbound Texts</label>
                  <input
                      id="forward_texts"
                      v-model="form.forward_texts"
                      type="text"
                      class="form-control"
                  >
                  <small class="text-muted">Comma-separated phones and emails</small>
                </div>
              </div>
            </div>

            <p for="forward_roles" class="fw-bold m-0">Auto-Forward Inbound Texts to Users by Role</p>

            <template v-for="(role, role_index) in roles">
            <span :key="'forward_role_'+role_index" class="badge badge-pill link-hover my-3" :class="{'badge-primary': isEnabledRole('forward_roles', role) }" @click="toggleRole('forward_roles', role)">{{ role.name }}</span>
            </template>

            <p for="forward_groups" class="fw-bold m-0">Auto-Forward Inbound Texts to Clients by Group</p>

            <template v-for="(group, group_index) in groups">
            <span :key="'forward_group_'+group_index" class="badge badge-pill link-hover my-3" :class="{'badge-primary': isEnabledGroup('forward_groups', group) }" @click="toggleGroup('forward_groups', group)">{{ group.name }}</span>
            </template>

            <div class="form-group custom-control custom-switch mt-3">
              <input
                  id="forwardAssigned"
                  v-model="form.forward_assigned"
                  type="checkbox"
                  class="custom-control-input"
              >
              <label
                  class="custom-control-label"
                  for="forwardAssigned"
              >Forward to Assigned Users</label>
            </div>

            <template v-if="form.outside_business">
              <h4>Outside of Business Hours</h4>

              <div class="row">
                <div class="col-sm-12 col-xl-6">
                  <div class="form-group">
                    <label for="outside_business_inbound_calls">Inbound Calls Response</label>
                    <textarea
                        id="outside_business_inbound_calls"
                        v-model="form.outside_business_inbound_calls[current_language.key]"
                        type="text"
                        class="form-control"
                        rows="5"
                    />
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-xl-6">
                  <p>Preview</p>

                  <template v-if="preview_inbound_calls_outside_business && preview_inbound_calls_outside_business.length">
                    <p
                        :class="setClass()"
                        :dir="current_direction"
                        :lang="current_language.key"
                    >
                      {{ preview_inbound_calls_outside_business }}
                    </p>
                  </template>

                  <div class="btn-group">
                    <button
                        type="button"
                        class="btn btn-outline-secondary btn-sm"
                        :disabled="playing"
                        @click="updatePreviews()"
                    >
                      Refresh Preview
                    </button>

                    <button
                        type="button"
                        class="btn btn-outline-secondary btn-sm"
                        :disabled="playing"
                        @click="synthesizeContent(preview_inbound_calls_outside_business)"
                    >
                      <template v-if="!playing">
                        Play Audio
                      </template>
                      <template v-else>
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                        Loading...
                      </template>
                    </button>

                    <template v-if="audio_url">
                      <a
                          :href="audio_url"
                          class="btn btn-outline-secondary btn-sm"
                          target="_blank"
                          :disabled="playing"
                      ><i class="fa fa-link" /> MP3</a>
                    </template>
                  </div>
                </div><!--Col-->
              </div><!--Row-->


              <div class="form-group">
                <label for="outside_business_inbound_texts">Inbound Texts Response</label>
                <textarea
                    id="outside_business_inbound_texts"
                    v-model="form.outside_business_inbound_texts"
                    type="text"
                    class="form-control"
                    rows="5"
                />
              </div>


              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="outside_business_forward_calls">Auto-Forward Inbound Calls</label>
                    <input
                        id="outside_business_forward_calls"
                        v-model="form.outside_business_forward_calls"
                        type="tel"
                        class="form-control"
                    >
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="outside_business_forward_texts">Auto-Forward Inbound Texts</label>
                    <input
                        id="outside_business_forward_texts"
                        v-model="form.outside_business_forward_texts"
                        type="text"
                        class="form-control"
                    >
                    <small class="text-muted">Comma-separated phones and emails</small>
                  </div>
                </div>
              </div>

              <p for="outside_business_forward_roles" class="m-0">Auto-Forward Inbound Texts by User Role</p>

              <template v-for="(role, role_index) in roles">
              <span :key="'forward_roles_outside_business_'+role_index" class="badge badge-pill link-hover" :class="{'badge-primary': isEnabledRole('outside_business_forward_roles', role) }" @click="toggleRole('outside_business_forward_roles', role)">{{ role.name }}</span>
              </template>

              <div class="form-group custom-control custom-switch mt-3">
                <input
                    id="outsideBusinessforwardAssigned"
                    v-model="form.outside_business_forward_assigned"
                    type="checkbox"
                    class="custom-control-input"
                >
                <label
                    class="custom-control-label"
                    for="outsideBusinessforwardAssigned"
                >Forward to Assigned Users</label>
              </div>
            </template>

          </div>
        </div>
      </div><!--Col-->

      <div class="col-md-3 col-sm-12">

        <template v-if="messages && messages.length > 0">

          <template v-for="(message, message_index) in messages">

            <message :key="'message_'+message_index" :message="message" />

          </template>

        </template>

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      errors: null,
      total: 0,
      queries: {
        limit: 100,
        slim: true,
        fields: 'name,uuid,link,portal_reference,redirect,created_at',
        sort: 'name',
        sortDirection: 'asc'
      },
      messages: [],
      form: {},
      business_days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
      languages: [],
      current_language: {
        key: 'en',
        label: 'English'
      },
      preview_inbound_calls: '',
      preview_inbound_calls_outside_business: '',
      playing: false,
      audio_url: '',
      phone_status: {},
      port_status: {},
      rtl_langs: ['ar', 'he', 'fa', 'pa', 'ku'],
      roles: [],
      groups: []
    }
  },

  computed: {

    current_direction: function() {
      if(this.rtl_langs.includes(this.current_language.key)) {
        return 'rtl';
      }

      return 'ltr';
    }

  },

  mounted() {

    if (!("Notification" in window)) {
      alert("This browser does not support desktop notifications.");
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission();
    }

    var self = this;

    this.$eventBus.$on('messages', function (data) {

      if (!data.uuid || !data.direction) {
        return;
      }

      if (data.direction == 'inbound' && data.to != self.form.phone_number) {
        return;
      }

      if (data.direction == 'outbound' && data.from != self.form.phone_number) {
        return;
      }

      self.messages.unshift(data);

      if (Notification.permission === "granted") {
        new Notification('New message', {
          body: data.content
        });
      }

    });

    this.$eventBus.$on('errors', function (errors) {
        self.processing = false;
        self.errors = errors.data || errors;
    });

  },

  created() {
    this.resetForm();
    this.setURLParams();
    this.getRecord();
    this.getLanguages();
    this.getRoles();
    this.getGroups();
  },

  methods: {

    setClass() {
      var style = 'inbound'
      if(this.current_direction == 'rtl') {
        style = style + ' text-right'
      }
      return style
    },

    setStatusClass(status) {

      if(['active'].includes(status)) {
        return 'badge-success';
      }

      if(['disabled', 'inactive'].includes(status)) {
        return 'badge-danger';
      }

      return 'badge-primary';

    },

    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    postForm() {
      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.data.updated_at) {
                this.form = {...this.form, ...response.data};
                new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                this.getRecord();
              }

            }
          })
    },

    getPortStatus() {

      if(!this.form.phone_number || !this.form.type || this.form.type != 'port') {
        return;
      }

      this.port_status = {};

      this.$http.post('/'+this.$route.meta.base_url+'/port', {'phone_number': this.form.phone_number})
          .then(response => {
            if(response && response.data) {
              this.port_status = response.data;
            }
          })
    },

    resetForm() {

      this.resetPreviews();

      this.form = {
        phone_number: null,
        primary: false,
        record: false,
        inbound_calls: {
          'en': ''
        },
        forward_calls: null,
        forward_texts: null,
        forward_roles: null,
        forward_groups: null,
        inbound_texts: null,
        optout_reply: null,
        voice: null,
        voice_speed: null,
        inbound_enrolls: null,
        forward_assigned: null,
        business_days: null,
        to_time: null,
        from_time: null,
        outside_business_inbound_calls: {
          'en': ''
        },
        outside_business_inbound_texts: null,
        outside_business: false,
        outside_business_forward_calls: false,
        outside_business_forward_assigned: false,
        outside_business_forward_texts: false,
        outside_business_forward_roles: null
      }
    },

    getRecord() {

      this.resetPhoneStatus();

      this.errors = null;

      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {

              if(response.data.uuid) {
                this.form = response.data;

                if(!this.form.inbound_calls) {
                  this.form.inbound_calls = {
                    'en': ''
                  }
                }

                if(!this.form.outside_business_inbound_calls) {
                  this.form.outside_business_inbound_calls = {
                    'en': ''
                  }
                }

                this.setTitle();

                this.getPhoneStatus();

                this.getPortStatus();

                this.updatePreviews();

                this.getMessages();
              }

              this.processing = false;
            }
          })
    },

    setTitle() {
        document.title = this.form.phone_number + ' | ' + this.$route.meta.title;
    },

    getMessages() {
      if(!this.form.phone_number) {
        return;
      }

      this.getInboundMessages();

      this.$http.get('/messages?search=from:'+this.form.phone_number+'&sort=scheduled_at&sortDirection=desc')
          .then(response => {
            if(response && response.data && response.data.data) {
              if(response.data.data.length) {
                response.data.data.forEach(message => {
                  this.messages.push(message);
                });
              }
            }
          })
    },

    getInboundMessages() {
      this.$http.get('/messages?search=to:'+this.form.phone_number+'&sort=created_at&sortDirection=desc')
          .then(response => {
            if(response && response.data && response.data.data) {
              if(response.data.data.length) {
                response.data.data.forEach(message => {
                  this.messages.push(message);
                });
              }
            }
          })
    },

    resetPhoneStatus() {
      this.phone_status = {
        status: null
      }
    },

    getPhoneStatus() {

      if(this.form.type == 'port' || this.form.type == 'short_code') {
        return;
      }

      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/status')
          .then(response => {
            if(response && response.status === 200) {
              this.phone_status = response.data;
            }
          })
    },

    restoreRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
              }

            }
          })
    },

    archiveRecord () {
      this.processing = true
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                this.form.archived_at = new Date().toISOString();
              }

            }

          })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
    },

    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      new this.$noty({text: 'Copied to clipboard'}).show();
    },

    getLanguages () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
          .then(response => {
            if(response && response.data) {
              this.languages = response.data;
            }
          })
    },

    resetPreviews() {
      this.preview_inbound_calls = {};
      this.preview_inbound_calls_outside_business = {};
      this.audio_url = '';
      this.audio_url_outside_business = '';
    },

    changeLanguage(language) {

      if(language.key !== this.current_language.key) {
        this.resetPreviews();
      }

      this.current_language = language;

      this.updatePreviews();
    },

    synthesizeContent(content) {
      this.playing = true
      this.$http.post('/synthesize?content='+content+'&language='+this.current_language.key)
          .then(response => {
            if(response && response.data) {
              var audio = new Audio(response.data);

              audio.play();

              this.playing = false;
            }
          })
    },

    updatePreviews() {

      var language = this.current_language.key || 'en'

      if(!this.form.inbound_calls[language] && !this.form.outside_business_inbound_calls[language]) {
        return;
      }

      this.resetPreviews();

      this.playing = true;

      this.updateOutsideBusiness(language);

      this.updateInsideBusiness(language);
    },

    updateOutsideBusiness(language) {

      if(!this.form.outside_business_inbound_calls || !this.form.outside_business_inbound_calls[language]) {
        return;
      }

      this.$http.get('/messages/merger?language='+language+'&content='+this.form.outside_business_inbound_calls[language])
          .then(response => {
            if(response && response.data) {
              this.preview_inbound_calls_outside_business = response.data;
            }
          })
    },

    updateInsideBusiness(language) {

      if(!this.form.inbound_calls || !this.form.inbound_calls[language]) {
        return;
      }

      this.$http.get('/messages/merger?language='+language+'&content='+this.form.inbound_calls[language])
          .then(response => {
            if(response && response.data) {
              this.preview_inbound_calls = response.data;
              this.playing = false;
            }
          })
    },

    autoTranslate(language) {

      var fields = ['inbound_calls', 'outside_business_inbound_calls'];

      var self = this;

      fields.forEach(field => {
        if(self.form[field] && self.form[field]['en']) {
          self.translateLanguage(field, language);
        }
      })

    },

    translateLanguage(field, language) {
      this.processing = true;

      var translator = {
        source_language: 'en',
        target_language: language.key,
        content: this.form[field]['en']
      }

      this.$http.post('/translator', translator)
          .then(response => {
            if(response && response.data) {

              if(response.data.content) {
                this.form[field][language.key] = response.data.content;
                new this.$noty({text: 'Auto translated to '+language.label}).show();
                this.updatePreviews();
              }

              this.processing = false;
            }
          })
    },

    getRoles() {
      this.$http.get('/roles?slim=true&fields=name&limit=100&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.roles = response.data;
        }
      })
    },

    getGroups () {
      this.$http.get('/client_groups?limit=1000&sort=group&sortDirection=name')
      .then(response => {
        if(response && response.data) {
          this.groups = response.data.map(group => {
            group.name = group.group;
            return group;
          });
        }    
      })
      },

    isEnabledRole(field, role) {
      if(!this.form[field]) {
        return false;
      }

      if(this.form[field].includes(role.name)) {
        return true;
      }

      return false;
    },

    toggleRole(field, role) {

      if(!this.form[field]) {
        this.form[field] = [];
      }

      if(this.form[field].includes(role.name)) {
        var index = this.form[field].indexOf(role.name);
        this.form[field].splice(index, 1);
      } else {
        this.form[field].push(role.name);
      }
    },

    isEnabledGroup(field, group) {
      if(!this.form[field]) {
        return false;
      }

      if(this.form[field].includes(group.name)) {
        return true;
      }

      return false;
    },

    toggleGroup(field, group) {

      if(!this.form[field]) {
        this.form[field] = [];
      }

      if(this.form[field].includes(group.name)) {
        var index = this.form[field].indexOf(group.name);
        this.form[field].splice(index, 1);
      } else {
        this.form[field].push(group.name);
      }
    },



  },
}
</script>