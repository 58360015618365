<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      title="Comment"
    />
        
    <div class="row">
      <div class="col-md-8">

        <template v-if="form.created_by">
          <h3>{{ form.created_by }} commented {{ form.created_at | dateTimezone | timeago }}</h3>
        </template>

          <p><router-link :to="{name: 'comments.index'}">Comments</router-link> / Edit Comment</p>
      </div><!--Col-->

      <div class="col-md-4 text-right">
        <div class="btn-group">


            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">

                <a
                  v-if="!form.archived_at && !form.deleted_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
                >Archive</a>

                <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>

                <a
                  v-if="form.deleted_at || form.archived_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>

              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save Comment
          </button>

          <router-link
            :to="{ name: 'comments.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-md-4">

        <div class="card">
          <div class="card-body">

            <p class="card-title">Edit Comment</p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label id="content" class="form-label">Comment</label>
                <textarea id="content" class="form-control" v-model="form.content" rows="5" required></textarea>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save Comment</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs :form="form" class="mt-3" />

      </div><!--Col-->
      <div class="col-md-8">


        <div class="row">
          <div class="col-6">

        <template v-if="form.client">
          <client-card
            :client="form.client"
          />
        </template>

          </div><!--Col-->

          <div class="col-6">

            <p class="card-title">{{ records.length }} Client Comments</p>

            <template v-if="records && records.length > 0">

              <template v-for="(record, index) in records">

                <div :key="'comments_'+index" class="mb-3">
                <p class="m-0" :class="setCommentClass(record)">
                
                  <template v-if="record.uuid === form.uuid">
                    {{ form.content }}
                  </template>
                  <template v-else>
                    {{ record.content }}
                  </template>
                </p>
                <small class="text-muted m-0">{{ record.created_by }} commented {{ record.created_at | dateTimezone | timeago }}</small>
                </div>

              </template>

            </template>


          </div><!--Col-->
        </div><!--Row-->
      </div><!--Col-->
    </div><!--Row-->
  </div><!--Container-->
</template>
<script>
import queries from '../mixins/queries';
export default {
    
    mixins: [ queries ],

    data () {
        return {
            processing: false,
            base_url: '/comments',
            form: {},
            records: []
        }
    },

    created() {
      this.resetForm();
      this.getRecord();
    },


    methods: {

      resetForm() {
        this.form = {
          client: null,
          content: null
        }
      },

      setCommentClass(record) {
        return record.uuid === this.form.uuid ? 'outbound' : 'inbound';
      },

      getRecord() {
        this.processing = true;
        this.$http.get(this.base_url+'/'+this.$route.params.id)
        .then(response => {
          if(response && response.data) {
            this.form = response.data;
            this.processing = false;

            this.getRecords();
          }
        })
      },

      getRecords() {

        if(!this.form.client) {
          return;
        }

        var queries = {
          client: this.form.client,
          sort: 'created_at',
          sortDirection: 'desc',
          limit: 1000,
          slim: true
        }

        this.$http.get(this.buildQueries(this.base_url, queries))
        .then(response => {
          if(response && response.data) {
            this.records = response.data;
          }
        })
      },

      postForm() {

        if(!this.form.uuid) {
          return;
        }

        this.processing = true;
        this.$http.patch(this.base_url+'/'+this.form.uuid, this.form)
        .then(response => {
          if(response && response.status) {


            if(response.data.updated_at) {
              new this.$noty({text: 'Comment updated'}).show();
              this.form.updated_at = response.data.updated_at;
            }

            this.processing = false;

          }
        })
      },

      trashRecord() {
          this.processing = true;
          this.$http.delete(this.base_url+'/'+this.form.uuid)
          .then(response => {
              if(response && response.status === 204) {
                  new this.$noty({text: 'Comment deleted', type: 'error'}).show();
                  this.form.deleted_at = new Date().toISOString();
              }
          })
      },

      archiveRecord() {
          this.processing = true;
          this.$http.put(this.base_url+'/'+this.form.uuid+'/archive')
          .then(response => {
              if(response && response.status === 200) {
                  new this.$noty({text: 'Comment archived', type: 'error'}).show();
                  this.form.archived_at = new Date().toISOString();
              }
          })
      },

      restoreRecord () {
          this.processing = true;
          this.$http.put(this.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      new this.$noty({text: 'Comment restored'}).show();
                      this.form.deleted_at = null;
                      this.form.archived_at = null;
                  }
                  
              }
          })
      },
        

    }
}
</script>
