<template>
  <div>
    <div class="container">
      <logo-bar />
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card card-default">
            <div class="card-header">
              Logout
            </div>
            <div class="card-body text-center">
              <p>You are logged out.</p>

              <a
                class="btn btn-primary"
                href="/login"
              >Login to eCourtDate</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data () {
    return {
    }
  },

  mounted () {
    this.clearUser();
  },

  methods: {

    clearUser () {
      localStorage.clear();
      delete this.$http.defaults.headers.common['Authorization'];
    }

  }
}
</script>
