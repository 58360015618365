<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />
    <div class="row my-3">

      <div class="col-sm-12 col-md-8">
        <h1>{{ form.name }}</h1>
        <template v-if="form.status">
          <span class="badge text-uppercase" :class="setCheckinStatus(form.status)">{{ form.status }}</span>
        </template>

        <p class="my-1"><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">
          <button
              type="button"
              class="btn btn-outline-primary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">

          </div>

          <template v-if="!form.archived_at && !form.deleted_at">
            <button
                type="button"
                class="btn btn-success"
                :disabled="processing"
                @click="postForm"
            >
              Save
            </button>
          </template>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->


    </div><!--Row-->

    <div class="row">

      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Edit {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name" class="form-label">Name</label>
                <input id="name" type="text" class="form-control" v-model="form.name" required>
              </div>

              <div class="form-group">
                <label for="phone" class="form-label">Phone</label>
                <input id="phone" type="tel" class="form-control" v-model="form.phone">
              </div>

              <div class="form-group">
                <label for="email" class="form-label">Email</label>
                <input id="email" type="email" class="form-control" v-model="form.email">
              </div>

              <div class="form-group">
                <label for="location">Location</label>
                <select
                    id="location"
                    v-model="form.location"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-if="locations && locations.length > 0">
                    <template v-for="(location, index) in locations">
                      <option
                          :key="'location_'+index"
                          :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="service">Service</label>
                <input id="service" type="text" class="form-control" v-model="form.service">
              </div>

              <div class="form-group">
                <label for="department">Department</label>
                <input id="department" type="text" class="form-control" v-model="form.department">
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="virtual"
                    v-model="form.virtual"
                    type="checkbox"
                    class="custom-control-input"
                >
                <label
                    class="custom-control-label"
                    for="virtual"
                >Virtual</label>
              </div>

              <div class="form-group text-capitalize">
                <label for="language">Language</label>
                <select
                    id="language"
                    v-model="form.language"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <option
                      v-for="language in languages"
                      :key="language.key"
                      :value="language.key"
                  >
                    {{ language.label }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="notes">Notes</label>
                <textarea id="notes" class="form-control" v-model="form.notes"></textarea>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form.uuid">
          <audit-logs :form="form" class="my-3" />
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-6">
        <template v-if="form.event">
        <event-card
            :event="form.event"
            class="mb-3"
        />
        </template>

        <template v-if="form.client">
        <client-card
            :client="form.client"
        />
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-3">
        <p class="card-title">{{ messages.length }} Messages</p>
      </div><!--Col-->

    </div><!--Row-->

    </div>
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

    data () {
        return {
          processing: false,
          form: {},
          event: {},
          messages: [],
          languages: [],
          locations: []
        }
    },

    created () {
        this.resetForm();
        this.getRecord();
        this.getLanguages();
        this.getLocations();
    },
    methods: {

        resetForm() {
            this.form = {
              client: null,
              event: null,
              status: null,
              language: null,
              service: null,
              department: null,
              notes: null,
              location: null,
              virtual: false
            }
        },

      setTitle() {
        document.title = this.form.name + ' | ' + this.$route.meta.title;
      },

        getRecord() {
            this.processing = true;
            this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
            .then(response => {
                if(response && response.data) {
                    this.form = response.data;
                    this.processing = false;

                    this.setTitle();
                }
            })
        },

      setCheckinStatus(status) {
          if(!status) {
            return 'badge-secondary';
          }

          if(status == 'checked-in') {
            return 'badge-success';
          }

          if(status == 'checked-out') {
            return 'badge-danger';
          }

          return 'badge-secondary';
        },

        postForm() {
            this.processing = true;
            this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    this.form = {...this.form, ...response.data};
                    new this.$noty({text: this.$route.meta.title + ' saved'}).show();
                }
            })
        },

      getLanguages () {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
            .then(response => {
              if(response && response.data) {
                this.languages = response.data;
              }
            })
      },

      getLocations () {
        this.$http.get(this.buildQueries('/locations', {limit: 1000, fields: 'uuid,name', slim: true, sort: 'name', sortDirection: 'asc'}))
            .then(response => {
              if (response && response.data) {
                this.locations = response.data;
              }
            })
      },

    }
}
</script>
