<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row">
      <div class="col-md-4">
        <template v-if="form.title && form.language && form.title[form.language]">
          <h1>{{ form.title[form.language] }}</h1>
        </template>
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-md-4">

        <template v-if="form && form.status == 'public'">
          <a :href="setPortalLink()" class="btn btn-outline-secondary mr-3" target="_blank" :disabled="processing"><i class="fa fa-link"></i> Public Link</a>

          <template v-if="qr_code">
            <a :href="setPortalLink()" target="_blank">
            <img
              :src="'data:image/svg+xml;base64,'+qr_code"
              width="50"
            >
            </a>
          </template>

        </template>

      </div><!--Col-->


      <div class="col-md-4 text-right">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">
                <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
                >Archive</a>
                <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>
                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-md-3 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title">
                  Edit {{ $route.meta.title }}
                </p>
              </div><!--Col-->

              <div class="col-6 text-right">
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="title">Title</label>
                <input
                  id="title"
                  v-model="form.title[form.language]"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="status">Status</label>
                <select id="status" class="form-control" v-model="form.status" :disabled="processing" required>
                  <template v-for="(status, status_index) in statuses">
                    <option :key="'post_status_'+status_index" :value="status">{{ status }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="portal">Portal

                <template v-if="form.portal">
                  <a
                  :href="'/portals/'+form.portal"
                  target="_blank"
                  data-tooltip="Edit Portal">
                  <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                  </a>
                </template>
                
                </label>
                <select
                  id="portal"
                  v-model="form.portal"
                  class="form-control"
                  :disabled="processing"
                  required
                >
                  <option value="">
                    Choose Portal
                  </option>
                  <template v-for="portal in portals">
                    <option
                      :key="portal.uuid"
                      :value="portal.uuid"
                    >
                      {{ portal.name }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="location">Location

                  <template v-if="form.location">
                    <a
                        :href="'/locations/'+form.location"
                        target="_blank"
                        data-tooltip="Edit Location">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Location</span>
                    </a>
                  </template>

                </label>
                <select
                    id="location"
                    v-model="form.location"
                    class="form-control"
                    :disabled="processing"
                    required
                >
                  <option value="">
                    Choose Location
                  </option>
                  <template v-for="location in locations">
                    <option
                        :key="location.uuid"
                        :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="language">Default Language</label>
                <select
                  id="language"
                  v-model="form.language"
                  class="form-control"
                  :disabled="processing"
                  required
                >
                  <option value="">
                    Choose Language
                  </option>
                  <option value="en">English</option>

                  <template v-for="(language, language_index) in languages">

                    <option
                      :key="'language_'+language_index"
                      :value="language.key"
                      :hidden="language.key == 'en'"
                    >
                      {{ language.label }}
                    </option>

                  </template>

                </select>
              </div>

              <div class="form-group">
                <label for="summary">Summary</label>
                <textarea id="summary" class="form-control" v-model="form.summary[form.language]"></textarea>
              </div>


              <div class="form-group">
                <label for="description">Description</label>
                <textarea id="description" class="form-control" v-model="form.description[form.language]"></textarea>
              </div>

              <div class="form-group">
                <label for="url">URL</label>
                <input id="url" class="form-control" v-model="form.url" required>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="comments"
                  v-model="form.comments"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="comments"
                > Allow Comments</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="social_share"
                  v-model="form.social_share"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="social_share"
                > Allow Sharing</label>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
  
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
          class="mt-3"
          :form="form"
        />
      </div><!--Col-->

      <div class="col-md-6">

        <div class="card mb-3">
          <div class="card-body">

            <p class="card-title">Add Section</p>

            <form @submit.prevent="postSectionForm()">

            <div class="form-group">
              <label for="section_title">Section Title *</label>
              <input id="section_title" type="text" class="form-control" v-model="sectionForm.title" required>
            </div>

            <div class="form-group">
              <fieldset>
                <legend>Section Type</legend>

                <template v-for="(section_type, section_type_index) in section_types">

                <label
                  class="mr-2 text-capitalize"
                  :key="'section_type_'+section_type_index"
                  :for="section_type"
                >
                  <input
                    :id="section_type"
                    v-model="sectionForm.type"
                    type="radio"
                    :value="section_type"
                  > {{ section_type }}
                </label>
                
                </template>
              </fieldset>
            </div>

            <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

      
        <div class="card">
          <div class="card-body" :lang="form.language" :dir="setLangDirection(form)">

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">Post Preview</p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-right">

                <template v-if="currentLanguage && currentLanguage.label">
                <span class="badge badge-pill badge-light">{{ currentLanguage.label }}</span>
                </template>

              </div><!--Col-->
            </div><!--Row-->

            <template v-if="form.title && form.title[form.language]">
                <h1>{{ form.title[form.language] }}</h1>
            </template>

            <template v-if="form.summary && form.summary[form.language]">
              <span class="text-muted small">Summary</span><br>
                <h3>{{ form.summary[form.language] }}</h3>
            </template>

            <template v-if="form.description && form.description[form.language]">
              <span class="text-muted small">Description</span><br>
                <p v-html="form.description[form.language]"></p>
            </template>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="form.sections">

              <draggable
                v-model="form.sections"
                group="fields"
                @start="drag=true"
                @end="drag=false"
              >

          <template v-for="(form_section, section_index) in form.sections">

            <div :key="'form_section_'+section_index" class="card mt-3">
              <div class="card-body">

                <div class="row">
                  <div class="col-md-9">
                    <p class="card-title">Edit Section</p>
                  </div><!--Col-->
                  <div class="col-md-3 text-right">
                    <button type="button" class="btn btn-outline-secondary" :disabled="processing"><i class="fa fa-bars"></i> Move</button>
                  </div><!--Col-->
                </div><!--Row-->

                  <div class="form-group">
                    <label :for="'section_title_'+section_index">Title</label>
                    <input :id="'section_title_'+section_index" type="text" class="form-control" v-model="form.sections[section_index].title" @change="postForm()" required>
                  </div>

                  <div class="form-group">
                    <label :for="'section_description_'+section_index">Description</label>
                    <textarea :id="'section_description_'+section_index" class="form-control" v-model="form.sections[section_index].description"></textarea>
                  </div>

                  <div class="form-group">
                    <label>Section Type</label>
                    <select class="form-control" v-model="form.sections[section_index].type" required :disabled="processing">
                      <template v-for="(section_type, type_index) in section_types">
                        <option :key="'type_section_'+type_index" :value="section_type">{{ section_type }}</option>
                      </template>
                    </select>
                  </div>

                  <template v-if="form_section.type == 'content'">
                    <div class="form-group">
                      <label>Content</label>
                      <textarea class="form-control" v-model="form.sections[section_index].data['content']"></textarea>
                    </div>
                  </template>

                  <button type="button" class="btn btn-outline-danger mt-3" @click="deleteFormSection(section_index)" :disabled="processing"><i class="fa fa-trash"></i> Delete</button>

              </div><!--Body-->
            </div><!--Card-->

          </template>

          </draggable>

        </template>


          <template v-if="form.sections && form.sections.length">
            <button type="button" class="btn btn-outline-danger btn-sm mt-3" @click="deleteFormSections()" :disabled="processing"><i class="fa fa-trash"></i> Reset Sections</button>
          </template>

      </div><!--Col-->

      <div class="col-md-3">

        <template v-if="form.comments">

          <p class="card-title">0 Comments</p>

          <div class="alert alert-info">Public comments feature coming soon.</div>

        </template>

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import queries from '../mixins/queries';

export default {

  components: { draggable },

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      total: 0,
      queries: {
        limit: 100,
        slim: true,
        fields: 'name,uuid,link,portal_reference,redirect,created_at',
        sort: 'name',
        sortDirection: 'asc'
      },
      records: [],
      form: {language: 'en'},
      languages: [],
      qr_code: null,
      statuses: ['draft', 'private', 'public'],
      portals: [],
      locations: [],
      portal: {},
      sectionForm: {},
      section_types: ['content', 'file', 'form']
    }
  },

  computed: {

    currentLanguage: function() {
        
        var lang_index = this.languages.findIndex((obj => obj.key == this.form.language))

        if(lang_index == -1) {
          return;
        }

        return this.languages[lang_index];
    }

  },

  created() {
    this.resetForm();
    this.resetSectionForm();
    this.setURLParams();
    this.getRecord();
    this.getLanguages();
    this.getPortals();
    this.getLocations();
  },

  methods: {

    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    setLangDirection(form) {

      if(!form.language) {
        return 'ltr';
      }

      if(this.$root.rtls.includes(form.language)) {
        return 'rtl';
      }

      return 'ltr';
    },

    postForm() {
      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
            this.getPortal();
          }

        }
      })
    },

    resetForm() {

        this.qr_code = null;

        this.form = {
            title: {
              'en': null
            },
            summary: {
              'en': null
            },
            description: {
              'en': null
            },
            status: null,
            portal: null,
            location: null,
            url: null,
            language: 'en',
            comments: false,
            social_share: false
        }
    },

    resetSectionForm() {
      this.sectionForm = {
        title: null,
        description: null,
        type: 'content',
        data: {}
      }
    },

   getRecord() {
    this.processing = true;
    this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
    .then(response => {
      if(response && response.data) {

        if(response.data.uuid) {
          this.form = response.data;
          this.getPortal();

          if(!this.form.summary) {
            this.form.summary = {
              'en': null
            }
          }

          if(!this.form.description) {
            this.form.description = {
              'en': null
            }
          }
        }

        this.processing = false;
      }
    })
   },

    getPortals () {
        this.$http.get('/portals?slim=true&limit=1000&sort=name&sortDirection=asc&fields=uuid,name,link&domain=ecase.io')
        .then(response => {
            if (response && response.data) {
                this.portals = response.data;
            }
        })
    },

    getLocations () {
      this.$http.get('/locations?slim=true&limit=1000&sort=name&sortDirection=asc&fields=uuid,name,link')
          .then(response => {
            if (response && response.data) {
              this.locations = response.data;
            }
          })
    },

    getPortal() {

      if(!this.form.portal) {
        return;
      }

      if(this.portal && this.portal.uuid) {
        if(this.form.portal == this.portal.uuid) {
          return;
        }
      }

      this.$http.get('/portals/'+this.form.portal+'?slim=true&fields=domain,link')
      .then(response => {
        if(response && response.data) {
          this.portal = response.data;

          this.getQRCode();
        }
      })
    },


    getLanguages () {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
        .then(response => {
            if(response && response.data) {
                this.languages = response.data;
            }
        })
    },
   

      getQRCode() {
        this.qr_code = null;
        this.$http.post('/qrs?content='+this.setPortalLink())
        .then(response => {
            if(response && response.data) {
                this.qr_code = response.data;
            }
        })
      },


      restoreRecord () {
          this.processing = true;
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                      this.form.deleted_at = null;
                      this.form.archived_at = null;
                  }
                  
              }
          })
      },

      archiveRecord () {
          this.processing = true
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                      this.form.archived_at = new Date().toISOString();
                  }

              }
              
          })
      },

      trashRecord () {
          this.processing = true;
          this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
      },

    copyToClipboard(value) {
        navigator.clipboard.writeText(value);
        new this.$noty({text: 'Copied to clipboard'}).show();
    },

    postSectionForm() {

      if(!this.form.sections) {
        this.form.sections = [];
      }

      this.form.sections.push(this.sectionForm);

      this.resetSectionForm();
    },


    deleteFormSections() {
      this.form.sections = [];
      new this.$noty({text: 'Save to apply changes.'}).show();
    },

    deleteFormSection(index) {
     this.form.sections.splice(index, 1);
     new this.$noty({text: 'Save to apply changes.'}).show();
    },

    setPortalLink() {
      if(!this.portal || !this.portal.link) {
        return;
      }

      if(!this.form || !this.form.url) {
        return;
      }

      if(this.form.status != 'public') {
        return;
      }

      var language = this.form.language || 'en';

      return 'https://'+this.portal.link+'.'+this.portal.domain+'/p/'+this.form.url+'?lang='+language;
    },

  },
}
</script>