<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row">
      <div class="col-md-4">
          <h1>{{ form.name || $route.meta.title }}</h1>
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-md-4">

        <button type="button" class="btn btn-outline-success" @click="postSimulateFile" :disabled="processing"><i class="fa fa-file-csv"></i> Simulate File</button>
        
      </div><!--Col-->

      <div class="col-md-4 text-right">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">

                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="duplicateRecord()"
                >Duplicate</a>

                <a
                    v-if="form.deleted_at || form.archived_at"
                    class="dropdown-item"
                    @click="restoreRecord()"
                >Restore</a>

                <a
                    v-if="!form.deleted_at || !form.archived_at"
                    class="dropdown-item"
                    @click="archiveRecord()"
                >Archive</a>

                <a
                  v-if="!form.deleted_at || !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-8">
        <div class="card">
          <div class="card-body">

            <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="template_name">{{ $route.meta.title }} Name</label>
                <input
                  id="template_name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>
              <div class="form-group">
                <label for="template_method">Processing Method</label>
                <select
                  id="template_method"
                  v-model="form.method"
                  class="form-control text-capitalize"
                  :disabled="processing"
                  required
                >
                <option value=""></option>
                  <option
                    v-for="method in methods"
                    :key="method.value"
                    :value="method.value"
                  >
                    {{ method.title }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="unique_client">Update Existing Clients By</label>
                <multi-select 
                  id="unique_client"
                  v-model="form.unique_client"
                  placeholder="Choose client fields to reference"
                  :multiple="true" 
                  :options="client_fields"
                  track-by="name"
                  label="name"
                  :close-on-select="false"
                  :class="setUniqueClientsClass()"
                />
              </div>

              <div class="form-group">
                <label for="unique_case">Update Existing Cases By</label>
                <multi-select 
                  id="unique_case"
                  v-model="form.unique_case"
                  placeholder="Choose case fields to reference"
                  :multiple="true" 
                  :options="case_fields"
                  track-by="name"
                  label="name"
                  :close-on-select="false"
                />
              </div>
              <div class="form-group">
                <label for="unique_event">Update Existing Events By</label>
                <multi-select 
                  id="unique_event"
                  v-model="form.unique_event"
                  placeholder="Choose event fields to reference"
                  :multiple="true" 
                  :options="event_fields"
                  track-by="name"
                  label="name"
                  :close-on-select="false"
                />
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="load_replace_clients"
                  v-model="form.load_replace_clients"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="load_replace_clients"
                >Load and Replace Clients</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="load_replace_contacts"
                  v-model="form.load_replace_contacts"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="load_replace_contacts"
                >Load and Replace Contacts</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="load_replace_cases"
                  v-model="form.load_replace_cases"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="load_replace_cases"
                >Load and Replace Existing Cases</label>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="default_client_status">Default Client Status</label>
                    <input
                      id="default_client_status"
                      v-model="form.default_client_status"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->

                <div class="col-6">
                  <div class="form-group">
                    <label for="default_event_status">Default Event Status</label>
                    <input
                      id="default_event_status"
                      v-model="form.default_event_status"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->

              </div><!--Row-->

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="default_payment_status">Default Payment Status</label>
                    <input
                      id="default_payment_status"
                      v-model="form.default_payment_status"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->

                <div class="col-6">
                  <div class="form-group">
                    <label for="default_payment_amount">Default Payment Amount</label>
                    <input
                      id="default_payment_amount"
                      type="number"
                      v-model="form.default_payment_amount"
                      class="form-control"
                      step="0.01"
                    >
                  </div>
                </div><!--Col-->

              </div><!--Row-->

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="default_location">Default Location</label>
                    <select
                      id="default_location"
                      v-model="form.location"
                      class="form-control"
                    >
                      <option value="" />
                      <template v-if="locations && locations.length > 0">
                        <option
                          v-for="location in locations"
                          :key="location.uuid"
                          :value="location.uuid"
                        >
                          {{ location.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div><!--Col-->

                <div class="col-6">
                  <div class="form-group">
                    <label for="default_portal">Default Portal</label>
                    <select
                      id="default_portal"
                      v-model="form.default_portal"
                      class="form-control"
                    >
                      <option value="" />
                      <template v-if="portals && portals.length > 0">
                        <option
                          v-for="portal in portals"
                          :key="portal.uuid"
                          :value="portal.uuid"
                        >
                          {{ portal.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="default_flow">Default Flow</label>
                    <select
                      id="default_flow"
                      v-model="form.flow"
                      class="form-control"
                    >
                      <option value="" />
                      <option
                        v-for="flow in flows"
                        :key="flow.uuid"
                        :value="flow.uuid"
                      >
                        {{ flow.name }}
                      </option>
                    </select>
                  </div>
                </div><!--Col-->

                <div class="col-6">
                  <div class="form-group">
                    <label for="default_group">Default Group</label>
                    <input
                      id="default_group"
                      v-model="form.default_group"
                      type="text"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="fields_prefix">Field Prefix</label>
                    <input
                      id="fields_prefix"
                      v-model="form.fields_prefix"
                      type="text"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->


                <div class="col-6">
                  <div class="form-group">
                    <label for="date_format">Date Format</label>
                    <select
                      id="date_format"
                      v-model="form.date_format"
                      class="form-control"
                    >
                      <option
                        v-for="date_format in date_formats"
                        :key="'date_formats_'+date_format"
                        :value="date_format"
                      >
                        {{ date_format }}
                      </option>
                    </select>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="time_format">Time Format</label>
                    <select
                      id="time_format"
                      v-model="form.time_format"
                      class="form-control"
                    >
                      <option
                        v-for="time_format in time_formats"
                        :key="'time_formats_'+time_format"
                        :value="time_format"
                      >
                        {{ time_format }}
                      </option>
                    </select>
                  </div>
                </div><!--Col-->

                <template v-if="form.file_format == 'csv' || form.file_format == 'txt'">

                <div class="col-6">
                  <div class="form-group">
                    <label for="delimiter">Field Delimiter</label>
                    <select
                      id="delimiter"
                      v-model="form.delimiter"
                      class="form-control"
                    >
                      <option
                        v-for="delimiter in delimiters"
                        :key="'delimeters_'+delimiter.key"
                        :value="delimiter.key"
                      >
                        {{ delimiter.title }}
                      </option>
                    </select>
                  </div>
                </div><!--Col-->

                </template>

              </div><!--Row-->

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="separator">Cell Separator</label>
                    <select
                      id="separator"
                      v-model="form.separator"
                      class="form-control"
                    >
                      <option
                        v-for="separator in delimiters"
                        :key="'separators_'+separator.key"
                        :value="separator.key"
                      >
                        {{ separator.title }}
                      </option>
                    </select>
                  </div>
                </div><!--Col-->


                <div class="col-6">
                  <div class="form-group">
                    <label for="file_format">File Format</label>
                    <select
                      id="file_format"
                      v-model="form.file_format"
                      class="form-control text-uppercase"
                      :disabled="processing"
                    >
                      <option
                        v-for="file_format in file_formats"
                        :key="'file_formats_'+file_format"
                        :value="file_format"
                      >
                        {{ file_format }}
                      </option>
                    </select>
                    <p class="small">
                      Defaults to CSV
                    </p>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="notify_emails">Notify Emails</label>
                    <input
                      id="notify_emails"
                      v-model="form.notify"
                      type="text"
                      class="form-control"
                    >
                    <p class="small">
                      Comma-separated
                    </p>
                  </div>
                </div><!--Col-->

                <div class="col-6">
                  <div class="form-group">
                    <label for="processing_order">Processing Order</label>
                    <select
                      id="processing_order"
                      v-model="form.processing_order"
                      class="form-control"
                    >
                      <option
                        v-for="processing_order in processing_orders"
                        :key="'processing_orders_'+processing_order.value"
                        :value="processing_order.value"
                      >
                        {{ processing_order.title }}
                      </option>
                    </select>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <p class="card-title">
                Exclude Row from Processing
              </p>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="exclude_field">Exclude Field</label>
                    <select
                      id="exclude_field"
                      v-model="form.exclude_field"
                      class="form-control"
                    >
                      <option value="" />
                      <template v-for="(default_field, index) in default_fields">
                        <option
                          :key="'exclude_field_'+index"
                          :value="default_field.key"
                        >
                          {{ default_field.name }} <small>{{ default_field.key }}</small>
                        </option>
                      </template>
                    </select>
                  </div>
                </div><!--Col-->

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="exclude_operator">Exclude Operator</label>
                    <select
                      id="exclude_operator"
                      v-model="form.exclude_operator"
                      class="form-control"
                    >
                      <option value="" />
                      <template v-for="(operator, index) in operators">
                        <option
                          :key="'operator_'+index"
                          :value="operator"
                        >
                          {{ operator }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div><!--Col-->

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="exclude_value">Exclude Value</label>
                    <input
                      id="exclude_value"
                      v-model="form.exclude_value"
                      class="form-control"
                    >
                    <p class="small text-muted">
                      Case insensitive
                    </p>
                  </div>
                </div><!--Col-->
              </div><!--Row-->


              <div class="row">
                <div class="col-4">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="auto_create_statuses"
                      v-model="form.auto_create_statuses"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="auto_create_statuses"
                    >Auto Create Statuses</label>
                  </div>

                  <div class="form-group custom-control custom-switch">
                    <input
                        id="delay_sync"
                        v-model="form.delay_sync"
                        type="checkbox"
                        class="custom-control-input"
                    >
                    <label
                        class="custom-control-label"
                        for="delay_sync"
                    > Delay Syncing Messages</label>
                    <p class="text-muted small">Delay syncing messages until after the upload finishes processing.</p>
                  </div>

                </div><!--Col-->

                <div class="col-4">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="notify_assigned_users"
                      v-model="form.notify_assigned_users"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="notify_assigned_users"
                    >Notify Assigned Users</label>
                  </div>

                  <div class="form-group custom-control custom-switch">
                    <input
                      id="require_assigned_users"
                      v-model="form.require_assigned_users"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="require_assigned_users"
                    >Require Assigned Users</label>
                  </div>

                </div><!--Col-->

                <div class="col-4">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="disable_notify_contacts"
                      v-model="form.disable_notify_contacts"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="disable_notify_contacts"
                    >Don't Notify New Contacts</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button type="submit" class="btn btn-success mt-3" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <div class="card">
          <div class="card-body">

            <p class="card-title m-0">Map Fields</p>

            <template v-if="form.fields">
              <span class="badge badge-pill bg-secondary">{{ Object.keys(form.fields).length }} of {{ default_fields.length }} fields mapped</span>
            </template>


            <form
              @submit.prevent="postForm"
            >

                <div class="form-group custom-control custom-switch my-3">
                  <input
                    id="map_index"
                    v-model="form.map_index"
                    type="checkbox"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="map_index"
                  >Map with Column #</label>
                </div>

                <div class="alert alert-primary">
                  <template v-if="form.map_index">
                    Each field mapping should match the numerical position of the column in your file. First column is 0.
                  </template>

                  <template v-else>
                    Each field mapping should match the column header in your file. Case sensitive. Must be an exact match.
                  </template>
                </div>

              <div class="form-group">
                <div class="custom-file">
                  <label
                      class="custom-file-label"
                      for="sampleFileUpload"
                  >Upload a Sample File</label>
                  <input
                      id="sampleFileUpload"
                      ref="sample_file"
                      type="file"
                      class="custom-file-input"
                      aria-describedby="sampleFileUpload"
                      accept=".csv"
                      @change="uploadSampleFile"
                  >
                </div>
              </div>

                <template v-if="sample_diffs && sample_diffs.length > 0">
                  <div class="alert alert-warning">
                    {{ sample_diffs.length }} unrecognized fields
                  </div>

                  <ul class="list-group mb-3">
                    <template v-for="(sample_diff, index) in sample_diffs">
                      <li
                        :key="'sample_diff_'+index"
                        class="list-group-item"
                      >
                        {{ sample_diff }}
                      </li>
                    </template>
                  </ul>
                </template>

              <div class="btn-group mb-3">
                <button type="button" class="btn btn-outline-primary" @click="mapDefaultFields()" :disabled="processing">Map Default Fields</button>
                <button type="button" class="btn btn-outline-danger" @click="resetFields()" :disabled="processing">Reset All Fields</button>
              </div>

                <template v-for="(default_field, key) in default_fields">
                  <template v-if="showField(default_field)">
                    <div
                      :key="key"
                      class="row"
                    >
                      <div class="col">
                        <div class="form-group">
                          <label :for="default_field.key">{{ default_field.name }}</label>
                          <span class="badge badge-primary mx-1">{{ default_field.model }}</span> 
                          <span
                            v-if="default_field.pii"
                            class="badge badge-danger"
                          >PII</span>
                          <template v-if="default_field.split">
                            <span class="badge badge-light">SPLIT</span>
                          </template>
                          <input
                            :id="default_field.key"
                            v-model="form.fields[default_field.key]"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </template>

                <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

              </form>

            </div><!--Body-->
          </div><!--Card-->
      </div><!--Col-->

    </div><!--Row-->


  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [queries],

  data() {
    return {
      processing: false,
      uploading: false,
      form: {},
      queries: {},
      flows: [],
      locations: [],
      methods: [
        {title: 'update by fields', value: 'update_fields'},
        {title: 'load and replace all uploads', value: 'load_replace_all_uploads'},
        {title: 'load and replace all uploads using this template', value: 'load_replace_all_template'}
      ],
      operators: ['=', '!=', '>=', '<=', '>', '<'],
      date_formats: [],
      time_formats: [],
      file_formats: ['csv', 'xlsx', 'txt'],
      processing_orders: [
        {title: 'per row descending (default)', value: 'desc'},
        {title: 'per row ascending', value: 'asc'}
      ],
      delimiters: [
        {title: 'comma', key: ','},
        {title: 'pipe', key: '|'},
        {title: 'tab', key: '\t'}
      ],
      default_fields: [],
      records: [],
      sample_diffs: [],
      portals: [],
      total: 0,
      simulateForm: {
        headers_only: false,
        limit: 100
      }
    }
  },

  computed: {

    client_fields: function () {
      return _.filter(this.default_fields, ['model', 'Client']);
    },
    event_fields: function () {
      return _.filter(this.default_fields, ['model', 'Event']);
    },
    case_fields: function () {
      return _.filter(this.default_fields, ['model', 'Case']);
    }

  },

  created() {
    this.resetForm();
    this.getRecord();
    this.getDefaultFields();
    this.getOptions();
    this.getPortals();
  },

  methods: {

    resetForm() {
      this.form = {
        method: 'update_fields',
        delimiter: ',',
        separator: ',',
        date_format: 'Y-m-d',
        time_format: 'H:i:s',
        require_assigned_users: false,
        notify_assigned_users: false,
        default_group: null,
        default_portal: null,
        fields_prefix: null,
        fields: {},
        map_index: 0,
        load_replace_clients: false,
        load_replace_contacts: false,
        load_replace_cases: false,
        delay_sync: false
      }
    },

    setFields() {
      if (!this.form.fields || Array.isArray(this.form.fields)) {
        this.form.fields = {};
      }
    },

    getPortals() {
      this.$http.get('/portals?slim=true&limit=150&fields=name,uuid')
          .then(response => {
            if (response && response.data) {
              this.portals = response.data;
            }
          })
    },

    getRecord() {

      this.processing = true;

      this.$http.get('/' + this.$route.meta.base_url + '/' + this.$route.params.id)
          .then(response => {
            if (response && response.data) {

              this.form = response.data;

              if (!response.data.fields) {
                this.form.fields = {};
              }

              this.setTitle();

              this.processing = false;
            }
          })
    },

    setTitle() {
      document.title = this.form.name + ' ' + this.$route.meta.title + ' | eCourtDate.com';
    },

    postForm() {

      this.processing = true;

      this.$http.patch('/' + this.$route.meta.base_url + '/' + this.form.uuid, this.form)
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if (response.data.updated_at) {
                this.form = {...this.form, ...response.data};
                new this.$noty({text: this.$route.meta.title + ' updated'}).show();
              }

            }
          })
    },

    getDefaultFields() {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/fields.json')
          .then(response => {
            if (response && response.data) {
              this.default_fields = response.data;
            }
          })
    },

    showField(field) {

      if (field.upload !== 1) {
        return false;
      }

      if (field.key == 'event_date' || field.key == 'event_time') {
        if (this.form.fields['event_date_time'] && this.form.fields['event_date_time'].length) {
          return false;
        }
      }
      return true;
    },

    getOptions() {

      this.$http.get('/locations?slim=true&fields=name,uuid&sort=name&sortDirection=asc')
          .then(response => {
            if (response && response.data) {
              this.locations = response.data;
            }
          })

      this.$http.get('/flows?slim=true&fields=name,uuid&sort=name&sortDirection=asc')
          .then(response => {
            if (response && response.data) {
              this.flows = response.data;
            }
          })

      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
          .then(response => {
            if (response && response.data) {
              this.date_formats = response.data.date_formats.map(item => item.key);
              this.time_formats = response.data.time_formats.map(item => item.key);
              this.file_formats = response.data.file_formats.map(item => item.key);
            }
          })
    },

    resetFields() {
      this.form.fields = {};
    },

    mapDefaultFields() {

      this.form.fields = {};

      var count = 0;

      this.default_fields.forEach(field => {

        if (field.upload !== 1 || field.split === 1) {
          return;
        }

        var models = ['Form', 'Bond', 'Attorney'];

        if (models.indexOf(field.model) > -1) {
          return;
        }

        var excludes = ['agency', 'action'];

        if (excludes.indexOf(field.key) > -1) {
          return;
        }

        var value = field.name;

        if (this.form.map_index === true) {
          value = count;
        }

        this.form.fields[field.key] = value;

        count++;

      })
    },

    useSampleFile(file) {
      this.sample_diffs = [];
      this.processing = true;
      this.$http.post('/sample_uploads?file=' + file)
          .then(response => {
            if (response && response.data) {

              if (response.data.guesses) {
                this.form.fields = response.data.guesses;
              }

              if (response.data.differences) {
                this.sample_diffs = response.data.differences;
              }

              new this.$noty({'text': 'Sample file mapped'}).show();

              this.processing = false;
            }
          })
    },

    uploadSampleFile() {

      const files = this.$refs.sample_file.files;

      if (!files && files.length > 0) {
        return;
      }

      this.uploading = true;

      this.uploadFile(files[0]);
  },

  async uploadFile(file, options = {}) {
    const response = await this.$http.post(this.$apiURL + 'v1/files', {
      'bucket': 'ecdfiles',
      'file_name': file.name || options.fileName || 'sample-file',
      'content_type': options.contentType || file.type,
      'content_length': file.size,
      'expires': options.expires || '',
      'visibility': options.visibility || ''
    }, {
      baseURL: options.baseURL || null,
      headers: options.headers || {},
      ...options.options
    });
    if (response && response.data) {

      let headers = response.data.upload_headers;

      if ('Host' in headers) {
        delete headers.Host;
      }

      if (typeof options.progress === 'undefined') {
        options.progress = () => {
        };
      }

      const cancelToken = options.cancelToken || ''

      var uuid = response.data.uuid;

      await this.$axios.put(response.data.upload_url, file, {
        cancelToken: cancelToken,
        headers: headers
      }).then(response => {
        if(response && response.status) {
          this.uploading = false;
          this.useSampleFile(uuid);
        }
      })

      return response;
    }
    return null
  },

  setUniqueClientsClass() {

    if (!this.form || !this.form.uuid) {
      return;
    }

    if (!this.form.unique_client || !this.form.unique_client.length) {
      return 'border border-danger';
    }

    if (this.hasClientReference()) {
      return 'border border-success';
    }

    if (this.form.length >= 5) {
      return 'border border-success';
    }

    return 'border border-warning';
  },

  hasClientReference() {

    var hasClientReference = false;

    this.form.unique_client.forEach(field => {

      if (field.key == 'clients.client_reference') {
        hasClientReference = true
      }

    })

    return hasClientReference;
  },

  duplicateRecord() {
    this.processing = true;
    this.$http.post('/' + this.$route.meta.base_url + '/' + this.form.uuid + '/duplicate')
        .then(response => {
          if (response && response.data) {

            this.processing = false;

            if (response.data.uuid) {
              new this.$noty({'text': this.$route.meta.title + ' duplicated'}).show()
              window.location.href = '/' + this.$route.meta.base_url + '/' + response.data.uuid;
            }

          }
        })
  },

  archiveRecord() {
    this.processing = true;
    this.$http.put('/' + this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
        .then(response => {
          if (response && response.status) {

            if (response.status === 200) {
              new this.$noty({text: this.$route.meta.title + ' archived', type: 'error'}).show();
              this.form.archived_at = new Date().toISOString();
            }

            this.processing = false;
          }
        })
  },

  restoreRecord() {
    this.processing = true;
    this.$http.put('/' + this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
        .then(response => {
          if (response && response.status) {

            if (response.status === 200) {
              new this.$noty({text: this.$route.meta.title + ' restored'}).show();
              this.form.deleted_at = null;
              this.form.archived_at = null;
              this.form.updated_at = new Date().toISOString();
            }

            this.processing = false;
          }
        })
  },

  trashRecord() {
    this.processing = true;
    this.$http.delete('/' + this.$route.meta.base_url + '/' + this.form.uuid)
        .then((response) => {
          if (response && response.status) {
            this.processing = false;

            if (response.status === 204) {
              new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
              this.form.deleted_at = new Date().toISOString();
            }
          }
        })
  },

  postSimulateFile() {

    this.processing = true;

    this.$http.post('/' + this.$route.meta.base_url + '/' + this.form.uuid + '/simulate', this.simulateForm)
        .then(response => {
          if (response && response.data) {
            this.processing = false;
            this.downloadCSV(response.data);
          } else {
            this.processing = false;
          }
        })
  },

  downloadCSV(sample_file) {

    var csvFile, data, link;

    data = this.convertCSV(sample_file);

    csvFile = new Blob([data], {type: "text/csv"});

    new this.$noty({text: 'Downloading file ' + sample_file.file}).show();

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(csvFile, sample_file.file);
    } else {
      link = document.createElement("a");
      link.download = sample_file.file;
      link.href = window.URL.createObjectURL(csvFile);
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
    }

  },

  convertCSV(sample_file) {

    var results = [];

    if (sample_file['headers'] && sample_file['headers'].length > 0) {
      results += sample_file['headers'].join(',')
      results += '\n';
    }

    if (!sample_file['rows'] || !sample_file['rows'].length) {
      return results;
    }

    sample_file['rows'].forEach(function (row) {
      var record = ''
      Object.keys(row).forEach(function (key) {
        record += '"' + (row[key] ? row[key] : '') + '",';
      });
      results += record
      results += '\n';
    });
    return results;
  },


  }
}
</script>