<template>
  <div>

    <page-tabs page="cases" />

    <div class="row">
      <div class="col-md-4 col-lg-3 mb-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>
            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="firm_name">Firm Name</label>
                <input
                  id="firm_name"
                  v-model="form.firm_name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>
              <div class="form-group">
                <label for="bar_number">Bar Number</label>
                <input
                  id="bar_number"
                  v-model="form.bar_number"
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="form-group">
                <label for="first_name">First Name</label>
                <input
                  id="first_name"
                  v-model="form.first_name"
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="form-group">
                <label for="last_name">Last Name</label>
                <input
                  id="last_name"
                  v-model="form.last_name"
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="form-group">
                <label for="phone">Phone</label>
                <input
                  id="phone"
                  v-model="form.phone"
                  type="tel"
                  class="form-control"
                >
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  id="email"
                  v-model="form.email"
                  type="email"
                  class="form-control"
                >
              </div>
              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <form @submit.prevent="searchRecords()">
              <p class="card-title">
                Search {{ $route.meta.title }}s
              </p>

              <div class="form-group">
                <label for="bar_number">Bar Number</label>
                <input
                  id="bar_number"
                  v-model="queries.bar_number"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="firm_name">Firm Name</label>
                <input
                  id="firm_name"
                  v-model="queries.firm_name"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="phone">Phone</label>
                <input
                  id="phone"
                  v-model="queries.phone"
                  type="tel"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="email">Email</label>
                <input
                  id="email"
                  v-model="queries.email"
                  type="email"
                  class="form-control"
                >
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->
      <div class="col-md-8 col-lg-9">
        <div class="card">
          <div class="card-body">
            <template v-if="total >= 0">
              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
              </p>
            </template>

            <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Bar Number</th>
                  <th>Firm Name</th>
                  <th>Attorney</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Created</th>
                  <th>Updated</th>
                </tr>
              </thead>

              <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="(record, index) in records">
                    <tr :key="'record_'+index">
                      <td>
                        <router-link :to="{ 'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid} }">
                          <i class="fa fa-edit"></i> <span class="sr-only">Edit</span> {{  record.bar_number }}
                        </router-link>
                      </td>
                      <td>{{ record.firm_name }}</td>
                      <td>{{ record.first_name }} {{ record.last_name }}</td>
                      <td>{{ record.phone }}</td>
                      <td>{{ record.email }}</td>
                      <td>{{ record.created_at | datetime }}</td>
                      <td>{{ record.updated_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
              </div><!--Responsive-->

              <template v-if="records && records.length == 0">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found
                </div>
              </template>
            
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

        <div class="row">
          <div class="col-12 text-center">

              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>    

          </div><!--Col-->
        </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries'

export default {

    mixins: [ queries ],

    data () {
        return {
            processing: true,
            records: [],
            queries: {
                sort: '',
                search: '',
                limit: 10
            },
            total: 0,
            form: {}
        }
    },
    created () {
        this.getRecords();
        this.resetForm();
    },

    methods: {

        resetRecords() {
            this.records = [];
            this.queries.skip = 0;
        },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        getRecords () {

            this.updateURL();

            this.getTotal();
            this.processing = true
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if (response && response.data) {

                    var self = this;
                    response.data.forEach(data => {
                        self.records.push(data);
                    })

                    this.queries.skip = this.records.length;

                    this.processing = false;
                }
            })
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + ' | eCourtDate.com';
        },

        resetForm() {
            this.form = {
                firm_name: null,
                bar_number: null,
                first_name: null,
                last_name: null,
                phone: null,
                email: null
            }
        },

        postForm () {
            this.processing = true;
            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if (response && response.data) {
                        
                    if(response.data.uuid) {
                        this.resetForm();
                        new this.$noty({text: this.$route.meta.title + ' added'}).show();
                        this.records.unshift(response.data);
                        this.total++;
                    }

                    this.processing = false;
                }
                
            })
        }
    }
}
</script>
