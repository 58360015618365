<template>
  <div>

<template v-if="currentTabs && currentTabs.length">

<nav class="nav nav-tabs mb-3 d-print-none" data-html2canvas-ignore>

    <template v-for="(tab, tab_index) in currentTabs">

      <router-link
        :key="'page_tab_'+tab_index"
        :to="{ name: tab+'.index' }"
        class="nav-link text-capitalize"
        :class="setTabStyle(tab)"
      >
        {{ tab.replace("_", " ", tab) }}
      </router-link>

    </template>

</nav>

</template>

  </div>
</template>
<script>
export default {

props: ['page'],

data() {
    return {
      tabs: {
        "events": ['events', 'calendars', 'waitlists', 'unreachables', 'schedules', 'availabilities'],
        "clients": ['clients', 'contacts', 'addresses', 'identities', 'sent_files'],
        "payments": ['payments', 'transactions', 'pay_links', 'pay_plans', 'pay_methods', 'recurring_payments', 'gift_cards', 'terminals'],
        "cases": ['cases', 'attorneys', 'bonds', 'warrants'],
        "uploads": ['uploads', 'upload_templates', 'file_templates'],
        "portals": ['portals', 'files', 'posts', 'forms', 'submissions', 'qrs', 'redirects'],
        "users": ['users', 'roles'],
        "message_templates": ['message_templates', 'auto_messages', 'flows', 'sample_templates', 'preview', 'merge_tags'],
        "auto_messages": ['auto_messages', 'auto_templates'],
        "messages": ['dashboard', 'messages', 'chats', 'opens', 'geolocations', 'lookups'],
        "bulk_actions": ['bulk_actions', 'bulk_templates'],
        "tests": ['tests', 'test_templates'],
        "reports": ['reports', 'dashboards', 'datapoints', 'dashboard_templates'],
        "settings": ['settings', 'agency_phones', 'agency_emails', 'statuses', 'locations', 'organizations', 'judges']
      }
    }
},

computed: {

  currentTab: function() {
    var location = window.location;

    return location.pathname.replace("/", "");
  },

  currentTabs: function() {
    if(this.tabs[this.page]) {
      return this.tabs[this.page]
    }

    return "";
  }

},

created() {
},

methods: {

  setTabStyle(tab) {
    if(this.currentTab == tab) {
      return 'active';
    }
  }


}

}
</script>