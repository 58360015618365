<template>
  <div>

    <page-tabs page="messages" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">

            <p class="card-title">Search {{ $route.meta.title }}s</p>

            <form @submit.prevent="searchRecords">

              <div class="form-group">
                <label for="search">Search</label>
                <input id="search" type="search" class="form-control" v-model="queries.search">
              </div>

              <div class="form-group">
                <label for="created_by">Creator</label>
                <select
                    id="created_by"
                    v-model="queries.created_by"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(user, index) in users">
                    <option
                        :key="'user_'+index"
                        :value="user.email"
                    >
                      {{ user.name }} {{ user.email }}
                    </option>
                  </template>
                </select>
              </div>

              <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div id="main" class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none" data-html2canvas-ignore>
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <sort-columns
                    :columns="columns"
                    :queries="queries"
                    :processing="processing"
                    :base_url="$route.meta.base_url"
                />

                <tbody>
                <template>
                  <template v-for="(record, index) in records">
                    <tr :key="'chat_'+index">
                      <td>
                        <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                          {{ record.content }}
                        </router-link>
                      </td>
                      <td>{{ record.direction }}</td>
                      <td>{{ record.type }}</td>
                      <td>
                        <template v-if="record.client">
                          <client-button :uuid="record.client" />
                        </template>
                      </td>

                      <td>{{ record.created_by }}</td>
                      <td>{{ record.created_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div><!--Responsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <div class="row">
          <div class="col-12 text-center">
            <template v-if="records && records.length && total > records.length">
              <div class="d-flex justify-content-center align-items-center py-3">
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    :disabled="processing"
                    @click="getRecords"
                >
                  Load more
                </button>
              </div>
            </template>
          </div><!--Col-->
        </div><!--Row-->
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from "lodash";

export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      downloading: false,
      total: 0,
      records: [],
      downloads: [],
      queries: {},
      columns: [
        {key: 'content', label: 'content'},
        {key: 'direction', label: 'direction'},
        {key: 'type', label: 'type'},
        {key: 'client', label: 'client'},
        {key: 'created_by', label: 'Creator'},
        {key: 'created_at', label: 'Created'}
      ],
      users: []
    }
  },


  created() {

    this.resetQueries();
    this.resetRecords();
    this.setURLParams();

    this.getRecords();

    this.getUsers();
  },

  mounted() {
    var self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

      if(view !== self.$route.meta.base_url) {
        return;
      }

      self.resetRecords();
      self.getRecords();

    });

  },

  methods: {

    resetQueries() {
      this.queries = {
        search: null,
        created_by: null,
        limit: 10,
        skip: 0,
        client: null,
        sort: 'scheduled_at',
        sortDirection: 'desc',
        slim: true
      }
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    setURLParams() {
      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {

        if(!value || value == 'undefined' || value == 'null') {
          return;
        }

        self.queries[key] = value;

      });

    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords() {

      this.processing = true;

      this.updateURL();

      this.getTotal();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.data) {
                this.records = this.records.concat(response.data);
                this.queries.skip = this.records.length;
              }
            }
          })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status) {
              this.total = response.data;
              this.setTitle();
            }
          })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    searchRecords () {
      this.resetRecords();
      this.getRecords();
    },

    getUsers() {
      this.$http.get('/users?slim=true&limit=1000&fields=name,email&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.users = response.data;
            }
          })
    },

    async downloadPDF() {
      try {

        window.scrollTo(0,0);

        this.downloading = true;

        const element = document.getElementById('main');

        const canvas = await this.$html2canvas(element, {
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          scale: window.devicePixelRatio,
          useCORS: true,
          logging: false
        });

        const imgData = canvas.toDataURL('image/png');

        const pdf = new this.$jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

        pdf.save(name+'.pdf');

        this.downloading = false;
      } catch (error) {
        new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
        console.log(error);
        this.downloading = false;
      }
    },

    downloadCSV () {
      this.downloading = true;

      var queries = this.queries;

      queries.limit = this.total;

      queries.skip = this.downloads.length;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
          .then(response => {
            if(response && response.data && response.data.data) {
              this.downloads = this.downloads.concat(response.data.data);

              var csvFile, data, link;

              data = this.convertCSV(this.downloads);

              var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

              csvFile = new Blob([data], {type: "text/csv"});
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(csvFile, file);
              } else {
                link = document.createElement("a");
                link.download = file;
                link.href = window.URL.createObjectURL(csvFile);
                link.style.display = "none";
                document.body.appendChild(link);
                link.click();
              }

              this.downloading = false;

            } else {
              this.downloadCSV();
            }

          });
    },

    convertCSV(records) {

      var results = [];

      if(records[0] && records[0]['uuid']) {
        var headers = Object.keys(records[0]);
      }

      results += headers.join(',')

      results += '\n';

      records.forEach(function(item) {
        var record = '';

        if(!item) {
          return;
        }

        Object.keys(item).forEach(function(key) {
          record += '"' + (item[key] ? item[key] : '') + '",';
        });

        results += record;
        results += '\n';
      });

      return results
    },

  }

}
</script>