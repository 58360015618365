<template>
  <div>

    <page-tabs page="clients" />

    <div class="row">
      <div class="col-12 col-lg-3 mb-3">
        <client-add />

    <div class="card mt-3">
      <div class="card-body">

        <form @submit.prevent="searchRecords">

        <div class="row">
          <div class="col-6">
            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>
          </div><!--Col-->
          <div class="col-6 text-right">
            <div class="btn-group">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              :disabled="processing"
              @click="resetSearch"
            >
            Reset
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              :disabled="processing"
              @click="searchRecords"
            >
            Search
            </button>
            </div><!--BtnGroup-->
          </div><!--Col-->
        </div><!--Row-->

          <div class="form-group">
            <label for="search_clients">Search Clients</label>
            <input
              id="search_clients"
              v-model="queries.search"
              type="text"
              class="form-control"
            >
          </div>

          <template v-if="clientGroups && clientGroups.length">

          <label class="form-label">{{ chosen_groups.length }} of {{ clientGroups.length}} Client Groups</label><br>

          <template v-for="(client_group, index) in clientGroups">
            <span
              :key="'client_group_'+index"
              :value="client_group.group"
              class="badge badge-pill mr-1 mb-3 link-hover"
              :class="setGroupClass(client_group)"
              @click="toggleClientGroup(client_group)"
            >
              {{ client_group }}
            </span>
          </template>

          </template>

          <div class="form-group">
            <label for="client_type">Client Type</label>
            <select
              id="client_type"
              v-model="queries.type"
              class="form-control"
              :disabled="processing"
            >
              <option value="" />
              <template v-for="(client_type, index) in client_types">
                <option
                  :key="'client_type_'+index"
                  :value="client_type"
                >
                  {{ client_type }}
                </option>
              </template>
            </select>
          </div>

          <div class="form-group">
            <label for="language">Preferred Language</label>
            <select
              id="language"
              v-model="queries.language"
              class="form-control text-capitalize"
              :disabled="processing"
            >
              <option value="" />
              <template v-for="(language, index) in languages">
                <option
                  :key="'language_'+index"
                  :value="language.key"
                >
                  {{ language.label }}
                </option>
              </template>
            </select>
          </div>

          <div class="form-group">
            <label for="status">Status</label>
            <input id="status" type="text" class="form-control" v-model="queries.status">
          </div>

          <div class="form-group">
            <label for="user">Assigned User</label>
            <select id="user" class="form-control" v-model="queries.user" :disabled="processing">
              <option value=""></option>
              <template v-for="(user, index) in users">
                <option :key="'user_'+index" :value="user.uuid">{{ user.name }} {{ user.email }}</option>
              </template>
            </select>
          </div>

          <div class="form-group">
            <label for="created_by">Creator</label>
            <select
              id="created_by"
              v-model="queries.created_by"
              class="form-control"
              :disabled="processing"
            >
              <option value="" />
              <template v-for="(creator, index) in users">
                <option
                  :key="'creator_'+index"
                  :value="creator.email"
                >
                  {{ creator.name }} {{ creator.email }}
                </option>
              </template>
            </select>
          </div>

          <div class="row">

            <div class="col-6">

              <div class="form-group custom-control custom-switch">
                <input
                  id="is_archived"
                  v-model="queries.archived"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="is_archived"
                >Archived</label>
              </div>

            </div><!--Col-->

            <div class="col-6">

              <div class="form-group custom-control custom-switch">
                <input
                  id="is_trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="is_trashed"
                >Trashed</label>
              </div>

            </div><!--Col-->

          </div><!--Row-->

          <button
            type="submit"
            class="btn btn-primary"
            :disabled="processing"
          >
            Search
          </button>
        </form>
      </div><!--Body-->
    </div><!--Card-->
      </div><!--Col-->

      <div class="col-12 col-lg-9">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-md-8">
                <template v-if="total >= 0">
                  <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
                  </p>
                </template>
              </div><!--Col-->
              <div class="col-md-4 text-right">
                <div class="btn-group">
                  <a
                    href="/stats"
                    class="btn btn-secondary btn-sm"
                  >STATS</a>
                </div>
              </div><!--Col-->
            </div><!--Row-->

            <div class="table-responsive">

            <table class="table table-hover table-striped">
              <thead>
                <tr>
                  <template v-for="(column, index) in columns">
                    <th :key="'column_'+index">
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ setColumnTitle(column) }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-for="(record, index) in records">
                  <tr :key="'client_'+index">
                    <td>
                      <router-link :to="{ 'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid} }">
                        <i class="fa fa-edit"></i>
                        
                        <span v-if="record.name && !record.first_name">
                          {{ record.name }}
                        </span>
                        <span v-if="record.first_name">
                          {{ record.first_name }}
                        </span>
                        <span v-if="!record.name && !record.first_name">
                          <i class="fa fa-user-circle" /> {{ $route.meta.title }}
                        </span>
                      </router-link>
                    </td>
                    <td>{{ record.middle_name }}</td>
                    <td>{{ record.last_name }}</td>
                    <td>{{ record.client_reference }}</td>
                    <td>
                      <template v-if="record.group">
                        <span class="badge badge-pill text-uppercase link-hover" :class="setGroupClass(record.group)" @click="toggleClientGroup(record.group)">{{ record.group }}</span>
                      </template>
                    </td>
                    <td>{{ record.type }}</td>
                    <td>{{ record.status }}</td>
                    <td>{{ setLanguageName(record) }}</td>
                    <td>
                      <total-button
                        :client="record.uuid"
                        :url="'contacts'"
                      />
                    </td>
                    <td>
                      <total-button
                        :client="record.uuid"
                        :url="'messages'"
                      />
                    </td>
                    <td>{{ record.created_by }}</td>
                    <td>
                      <template v-if="record.deleted_at">
                        <span class="badge badge-danger badge-pill">TRASHED</span><br>
                      </template>
                      <template v-if="record.archived_at">
                        <span class="badge badge-warning badge-pill">ARCHIVED</span><br>
                      </template>
                      {{ record.created_at | datetime }}
                    </td>
                    <td>{{ record.updated_at | datetime }}</td>
                  </tr>
                </template><!--Row-->
              </tbody>
            </table>
            </div><!--TableResponsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
        <div class="row mt-3">
          <div class="col-12 text-center">
            <button
              type="button"
              class="btn btn-outline-primary"
              :disabled="processing"
              @click="getRecords"
            >
              Load more
            </button>
          </div><!--Col-->
        </div><!--Row-->
        </template><!--LoadMore-->


      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      processing: false,
      records: [],
      total: 0,
      queries: {},
      columns: [
      {label: 'first name', key: 'first_name'},
      {label: 'middle name', key: 'middle_name'},
      {label: 'last name', key: 'last_name'},
      {label: 'reference', key: 'client_reference'},
      {label: 'group', key: 'group'},
      {label: 'type', key: 'type'},
      {label: 'status', key: 'status'},
      {label: 'language', key: 'language'},
      {label: 'contacts'},
      {label: 'messages'},
      {label: 'creator', key: 'created_by'},
      {label: 'created', key: 'created_at'},
      {label: 'updated', key: 'updated_at'}
      ],
      client_groups: [],
      chosen_groups: [],
      client_types: [],
      users: [],
      languages: []
    }

  },

  computed: {

    clientGroups: function() {

      if(!this.$root.current_user || !this.$root.current_user.current_agency) {
        return;
      }

      var groups = [];

      if(this.$root.current_user.current_agency.settings && this.$root.current_user.current_agency.settings.default_groups) {
        groups = this.$root.current_user.current_agency.settings.default_groups;

        groups = groups.split(",");

        groups = groups.map(g => g.trim());
      }

      var client_groups = [];

      if(this.client_groups && this.client_groups.length > 0) {
        client_groups = this.client_groups.map(r => r.group);

        client_groups.forEach(g => {
          if(!groups.includes(g)) {
            groups.push(g);
          }
        })
      }

      if(!groups) {
        return [];
      }

      return groups.map(g => g.trim());
    }

  },

  mounted () {

    var self = this;
    
    this.$eventBus.$on('addClient', function (data) {
       self.addNewRecord(data);
    });

  },
  
  created () {
    this.resetQueries();
    this.resetRecords();
    this.setURLParams();
    this.getRecords();
    this.getLanguages();
    this.getUsers();
    this.getClientGroups();
    this.getClientTypes();
  },

  methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {

            if(!value || value == 'null') {
              return;
            }

            self.queries[key] = value;
        });
    },

    resetSearch() {
      this.resetQueries();
      this.updateURL();
      this.resetRecords();
      this.getRecords();
    },

    resetQueries() {
      this.queries = {
        sort: 'created_at',
        sortDirection: 'desc',
        search: '',
        skip: 0,
        limit: 10,
        total: false,
        slim: true,
        status: null,
        upload: null,
        groups: [],
        user: null,
        language: null,
        type: null,
        created_by: null,
        archived: false,
        trashed: false
      }

      this.chosen_groups = [];
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    setLanguageName(record) {

      if(!record.language) {
        return;
      }

      var language = _.find(this.languages, {'key': record.language});

      if(!language || !language.label) {
        return record.language;
      }

      return language.label;
    },

    setGroupClass(group) {

      if(this.chosen_groups && this.chosen_groups.includes(group)) {
        return 'badge-primary';
      }

      return 'badge-secondary';
    },

    toggleClientGroup(group) {

      if(this.chosen_groups && !this.chosen_groups.includes(group)) {
        this.chosen_groups.push(group);

        this.searchRecords();
        return;
      }

      this.chosen_groups.splice(this.chosen_groups.findIndex((obj => obj === group)), 1);

      this.searchRecords();
    },

    getRecords() {

      if(this.chosen_groups) {
        this.queries.groups = this.chosen_groups;
        this.queries.groups.join(',');
      }

      this.setAssignedUser();

      this.getTotal();

      this.updateURL();

      this.processing = true;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if(response && response.data) {
          if(response.data.length) {

              var self = this;

              response.data.forEach(function(data) {
                  self.records.push(data);
              });
          }

          this.queries.skip = this.records.length;
          this.processing = false;
        }
      })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setAssignedUser() {
      if(!this.$root.current_user || !this.$root.current_user) {
        return;
      }

      if(this.$root.current_user.permissions && this.$root.current_user.permissions.length > 0) {
          if(!this.queries.user && this.$root.current_user.permissions.includes('only_assigned_clients')) {
            this.queries.user = this.$root.current_user.uuid;
          }
      }
    },

    setTitle() {
      document.title = this.total + ' '+this.$route.meta.title+'s | eCourtDate.com';
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    addNewRecord(data) {

      var index = _.findIndex(this.records, {'uuid': data.uuid});

      if(index == -1) {
        this.total++;
        this.records.unshift(data);
        this.setTitle();
        return;
      }

      var records = [];

      this.records.forEach(function(record) {

        if(record.uuid == data.uuid) {
          record = {...record, ...data};
        }

        records.push(record);


      })

      this.records = records;

    },

    updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    searchRecords () {
      this.resetRecords();
      this.getRecords();

      new this.$noty({ text: 'Searching '+this.$route.meta.title+'s' }).show();

    },

    getUsers () {
      this.$http.get('users?limit=1000&slim=y&fields=name,email,uuid&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.users = response.data;
        }
      })
    },

    getClientGroups () {
      this.$http.get('/client_groups?limit=100')
      .then(response => {
        if(response && response.data) {
          this.client_groups = response.data;
        }
      })
    },

    getClientTypes () {
      this.$http.get('/client_types?limit=20')
      .then(response => {
        if(response && response.data && response.data.length) {
          this.client_types = response.data.map((r) => r.type);
        }
      })
    },

    getLanguages () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
      .then(response => {
          if(response && response.data) {
              this.languages = response.data;
          }
      })
    },

    setColumnTitle(field) {

        var fields = localStorage.getItem('fields');

        if(!fields || !fields.length) {
          return field.label;
        }

        var search = field.key.toLowerCase();

        var title = _.find(JSON.parse(fields), { 'key': 'clients.'+search });      

        if(!title || !title.name) {
          return field.label;
        }

        return title.name;
    },

    setFieldTitle(field) {

        var fields = localStorage.getItem('fields');

        if(!fields || !fields.length) {
          return field;
        }

        var search = field.toLowerCase();

        var title = _.find(JSON.parse(fields), { 'key': 'clients.'+search });      

        if(!title || !title.name) {
          return field;
        }

        return title.name;
    }

  }
}
</script>