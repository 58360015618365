<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row">
      <div class="col-md-4">
          <h1>
            <template v-if="form.amount >= 0">${{ form.amount }}</template>
          </h1>
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-md-4">


      </div><!--Col-->

      <div class="col-md-4 text-right">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">

                <a
                  v-if="form.deleted_at || form.archived_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>

                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-lg-4 col-sm-12">
        <div class="card">
          <div class="card-body">

          <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="recurring_amount">Recurring Amount</label>
                <div class="input-group">
                  <span
                    class="input-group-text"
                    style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
                  >$</span>
                  <input
                    id="recurring_amount"
                    v-model="form.amount"
                    type="number"
                    class="form-control"
                    min="0"
                    step="0.01"
                    required
                  >
                </div>
              </div>

              <div class="form-group">
                <label for="start_date">Start Date</label>
                <input id="start_date" type="date" class="form-control" v-model="form.start_date">
              </div>

              <div class="form-group">
                <label for="end_date">End Date</label>
                <input id="end_date" type="date" class="form-control" v-model="form.end_date">
              </div>


            </form>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-lg-4 col-sm-12">

        <template v-if="form.client">
          <client-card :client="form.client" class="mb-3" />
        </template>

        <template v-if="recurring && recurring.uuid">

          <div class="card">
            <div class="card-body">

              <p class="card-title">Recurring Payment Settings</p>

              <ul class="list-group">

                <li class="list-group-item">Name: <a :href="'/recurring_payments/'+recurring.uuid">{{ recurring.name }}</a></li>

                <li class="list-group-item">Default Amount: ${{ recurring.amount }}</li>

                <template v-if="recurring.frequency">
                <li class="list-group-item">Frequency: <span class="badge badge-pill badge-primary text-uppercase">{{ recurring.frequency }}</span></li>
                </template>

                <template v-if="recurring.flow">
                <li class="list-group-item">Flow: <flow-button :uuid="recurring.flow"></flow-button></li>
                </template>

                <template v-if="recurring.location">
                <li class="list-group-item">Location: <location-button :uuid="recurring.location"></location-button></li>
                </template>

                <template v-if="recurring.portal">
                <li class="list-group-item">Portal: <portal-button :uuid="recurring.portal"></portal-button></li>
                </template>

              </ul>

            </div><!--Body-->
          </div><!--Card-->
        </template>

      </div><!--Col-->

      <div class="col-lg-4 col-sm-12">

        <div class="card">
          <div class="card-body">

              <template v-if="total >= 0">
                <p class="card-title"><a :href="'/payments?client='+form.client+'&recurring='+form.uuid">{{ records.length }} of {{ total }} Payments</a></p>
              </template>

              <ul class="list-group">

                <template v-for="(record, recurring_payment) in records">

                  <li :key="'recurring_payment_'+recurring_payment" class="list-group-item">

                  <div class="row">
                    <div class="col-9">
                    
                    <a :href="'/payments/'+record.uuid">{{ record.payment_reference }}</a>

                  <template v-if="record.amount">
                    <p class="text-muted m-0">Amount: ${{ record.amount }}</p>
                  </template>

                  <template v-if="record.due_at">
                    <p class="text-muted m-0">Due: {{ record.due_at | date }}</p>
                  </template>

                    </div><!--Col-->

                    <div class="col-3">

                    <template v-if="record.status">
                      <span class="badge badge-pill badge-primary">{{ record.status }}</span>
                    </template>

                    </div><!--Col-->

                    </div><!--Row-->

                  </li>

                </template>

              </ul>

            </div><!--Body-->
          </div><!--Card-->

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      searching: false,
      form: {},
      recurring: {},
      queries: {},
      total: 0,
      client: {},
      records: []
    }
  },

  created() {
    this.resetQueries();
    this.resetRecords();
    this.resetForm();
    this.getRecord();
  },

  methods: {

    resetQueries() {
      this.queries = {
        client: null,
        recurring: null
      }
    },

    resetForm() {
      this.form = {
        amount: 0,
        start_date: null,
        end_date: null
      }

    },

    getRecord() {

        this.processing = true;

        this.$http.get(this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                
                this.form = response.data;

                this.processing = false;

                this.getRecurring();
            }
        })
    },

    getRecurring() {

      if(!this.form.recurring) {
        return;
      }

      this.$http.get('/recurring_payments/'+this.form.recurring)
      .then(response => {
        if(response && response.status) {

          if(response.status === 200) {
            this.recurring = response.data;
            this.getRecords();
          }
        }
      })
    },

    postForm() {

      this.processing = true;

      this.$http.patch(this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
          }

        }
      })
    },


    restoreRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
      .then(response => {
        if(response && response.data) {
              
              this.processing = false;

              if(response.status === 200) {
                new this.$noty({'text': this.$route.meta.title + ' restored'}).show()
                this.form.deleted_at = null;
                this.form.archived_at = null;
                this.form.updated_at = new Date().toISOString();
              }

        }
      })
    },


  trashRecord () {
      this.processing = true;
      this.$http.delete(this.$route.meta.base_url + '/' + this.form.uuid)
      .then((response) => {
        if(response && response.status) {
          this.processing = false;

          if(response.status === 204) {
            new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
            this.form.deleted_at = new Date().toISOString();
          }
        }
      })
  },

    getRecords() {

      this.searching = true;

      this.setQueries();

      this.getTotal();

      this.$http.get(this.buildQueries('/payments', this.queries))
      .then(response => {
        if(response && response.data) {
          this.records = response.data;
          this.searching = false;
        }
      })
    },

    setQueries() {
      this.queries = {
        recurring: this.form.uuid,
        client: this.form.client,
        slim: true,
        fields: 'uuid,payment_reference,amount,status,due_at',
        sort: 'created_at',
        sortDirection: 'desc'
      }
    },

    getTotal() {

      this.$http.get(this.buildQueries('/payments/total', this.queries))
      .then(response => {
        if(response && response.status) {
          this.total = response.data;
        }
      })
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
    },



  }
}
</script>