<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      title="Availability"
    />

    <div class="row mb-3">
      <div class="col-sm-12 col-md-3">
        <h1>{{ form.name }}</h1>
        <p><router-link :to="{name: 'availabilities.index'}">Availabilities</router-link> / Edit Availability</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-3">
        <template v-if="form.last_active">
          <a :href="'/geolocations?room='+form.uuid"><span class="badge badge-pill badge-success text-uppercase mr-1">Last Active {{ form.last_active | dateTimezone | timeago }}</span></a>
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-6 text-right">
        <div class="btn-group">

          <template v-if="!form.deleted_at">

          <template v-if="form.portal">
            <portal-link :uuid="form.portal" :url="'schedule?template='+form.uuid" />
          </template>

          <button
            type="submit"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          </template>

          <button
            type="button"
            class="btn btn-outline-secondary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">
                <a
                  v-if="form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>
                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
          </div>

          <router-link
            :to="{ name: 'availabilities.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Edit Availability
            </p>

            <form @submit.prevent="postForm()">

              <div class="form-group mt-3">
                <label for="name">Event Name *</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="portal">Portal

                  <template v-if="form.portal">
                    <router-link :to="{name: 'portals.edit', params: {id: form.portal}}"><i class="fa fa-edit"></i> <span class="sr-only">Edit</span></router-link>
                  </template>
                </label>
                <select
                  id="portal"
                  v-model="form.portal"
                  class="form-control"
                  required
                  :disabled="processing"
                >
                <option value=""></option>
                  <template v-for="(portal_option, portal_index) in portals">
                    <option
                      :key="'portal_option_'+portal_index"
                      :value="portal_option.uuid"
                    >
                      {{ portal_option.name }} ({{ portal.link }}.{{ portal.domain }})
                    </option>
                  </template>
                </select>

              </div>

              <div class="form-group">
                <label for="location">Location

                  <template v-if="form.location">
                    <router-link :to="{name: 'locations.edit', params: {id: form.location}}"><i class="fa fa-edit"></i> <span class="sr-only">Edit</span></router-link>
                  </template>
                </label>
                <select
                  id="location"
                  v-model="form.location"
                  class="form-control"
                  :disabled="processing"
                >
                <option value=""></option>
                  <template v-for="(location, index) in locations">
                    <option
                      :key="'location_'+index"
                      :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                </select>

              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="virtual"
                  v-model="form.virtual"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="virtual"
                >Virtual</label>
              </div>
      
              <div class="form-group">
                <label for="event_type" class="form-label">Event Type</label>
                <input id="event_type" type="text" class="form-control" v-model="form.event_type">
              </div>

              <div class="form-group">
                <label for="event_status" class="form-label">Event Status</label>
                <input id="event_status" type="text" class="form-control" v-model="form.event_status">
              </div>


              <div class="form-group">
                <label for="description">Description</label>
                <textarea id="description" class="form-control" rows="3" v-model="form.description"></textarea>
              </div>

            <div class="form-group">
              <label for="duration">Event Duration</label>
              <input id="duration" type="number" class="form-control" v-model="form.duration">
              <small class="text-muted form-text">In minutes</small>
            </div>

            <div class="form-group">
              <label for="increment">Start Time Increments</label>
              <input id="increment" type="number" class="form-control" v-model="form.increment">
              <small class="text-muted form-text">In minutes</small>
            </div>

            <div class="form-group">
              <label for="min_notice">Minimum Notice</label>
              <input id="min_notice" type="number" class="form-control" v-model="form.min_notice" min="0" step="1">
              <small class="text-muted form-text">In hours</small>
            </div>

            <div class="form-group">
              <label for="max_daily">Max Daily Events</label>
              <input id="max_daily" type="number" class="form-control" v-model="form.max_daily" min="0" step="1">
            </div>

            <div class="form-group">
              <label for="max_future">Max Days Ahead</label>
              <input id="max_future" type="number" class="form-control" v-model="form.max_future" min="0" step="1">
            </div>

            <div class="form-group">
              <label for="pre_buffer">Buffer Before Event</label>
              <input id="pre_buffer" type="number" class="form-control" v-model="form.pre_buffer" min="0" step="1">
              <small class="text-muted form-text">In minutes</small>
            </div>

            <div class="form-group">
              <label for="post_buffer">Buffer After Event</label>
              <input id="post_buffer" type="number" class="form-control" v-model="form.post_buffer" min="0" step="1">
              <small class="text-muted form-text">In minutes</small>
            </div>

            <div class="form-group">
              <label for="notify_bookings">Notify Bookings</label>
              <input id="notify_bookings" type="text" class="form-control" v-model="form.notify_bookings">
              <small class="text-muted form-text">Comma-separated emails and phones</small>
            </div>

            <div class="form-group">
              <label for="confirm_message">Confirmation Message

                  <template v-if="form.confirm_message">
                    <a :href="'/settings?tab=auto_messages&uuid='+form.confirm_message"><i class="fa fa-edit"></i> <span class="sr-only">Edit</span></a>
                  </template>
              </label>
              <select id="confirm_message" class="form-control" v-model="form.confirm_message">
                <option value=""></option>
                <template v-for="(auto_message, index) in auto_messages">
                  <option :key="'auto_message_'+index" :value="auto_message.uuid">{{ auto_message.name }}</option>
                </template>
              </select>
            </div>

                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                  Save
                </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs :form="form" class="mt-3"></audit-logs>

      </div><!--Col-->

      <div class="col-md-9">

        <p class="card-title">Next Availabilities</p>

        <ul class="list-group">

          <template v-for="(availability, index) in availabilities">

            <li :key="'availability_'+index" class="list-group-item">{{ availability }}</li>

          </template>

        </ul>
        
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      base_url: '/availability_templates',
      form: {},
      auto_messages: [],
      availabilities: [],
      portal: {},
      portals: [],
      locations: []
    }
  },

  created() {
    this.resetForm();
    this.getRecord();
    this.getAutoMessages();
    this.getPortals();
    this.getLocations();
  },

  methods: {

    resetForm() {
      this.form = {
        name: null,
        portal: null,
        location: null,
        virtual: false,
        description: null,
        event_type: null,
        event_status: null,
        duration: null,
        increment: null,
        min_notice: null,
        max_daily: null,
        max_future: null,
        pre_buffer: null,
        post_buffer: null,
        notify_bookings: null,
        confirm_message: null,
      }
    },

    getAutoMessages() {
      this.$http.get('/auto_messages?limit=1000&slim=true&fields=uuid,name&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.auto_messages = response.data;
        }
      })
    },

    getRecord() {
      this.processing = true;
      this.$http.get(this.base_url+'/'+this.$route.params.id)
      .then(response => {
        if(response && response.data) {
          this.form = response.data;
          this.processing = false;
          this.setTitle();
          this.getAvailabilities();

          if(this.form.portal) {
            this.getPortal(this.form.portal);
          }
        }
      })
    },

    setTitle() {
      document.title = this.form.name + ' | ' + this.$route.meta.title + ' | eCourtDate.com';
    },

    getAvailabilities() {
      var queries = this.form;
      queries.limit = 20;
      this.$http.get(this.buildQueries('/availabilities', queries))
      .then(response => {
        if(response && response.data) {
          this.availabilities = response.data;
        }
      })
    },

    postForm() {

      this.processing = true;
      this.$http.patch(this.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            new this.$noty({text: 'Availability updated'}).show();
            this.getAvailabilities();
          }

        }
      })
    },

    restoreRecord () {
        this.processing = true;
        this.$http.put(this.base_url + '/' + this.form.uuid + '/restore')
        .then(response => {
            if (response && response.data) {

                this.processing = false;

                if(response.status === 200) {
                    new this.$noty({text: 'Availability Restored'}).show();
                    this.form.deleted_at = null;
                }
                
            }
        })
    },

    trashRecord() {
        this.processing = true;
        this.$http.delete(this.base_url+'/'+this.form.uuid)
        .then(response => {
            if(response && response.status === 204) {
                new this.$noty({text: 'Availability trashed', type: 'error'}).show();
                this.form.deleted_at = new Date().toISOString();
                this.processing = false;
            }
        })
    },
   
    getPortal(uuid) {
        this.$http.get('/portals/'+uuid+'?slim=true&fields=link')
        .then(response => {
            if(response && response.data) {
                this.portal = response.data;
            }
        })
    },

    getPortals() {
        this.$http.get('/portals?limit=1000&slim=true&fields=name,uuid,link,domain')
        .then(response => {
            if(response && response.data) {
              
                this.portals = [];

                var self = this;

                if(response.data.length > 0) {
                  response.data.forEach(portal => {

                    if(portal.domain == 'ecourtpayment.com') {
                      return;
                    }

                    self.portals.push(portal);
                  });
                }

                if(this.portals.length > 0 && !this.form.portal) {
                    this.form.portal = this.portals[0];
                }
            }
        })
    },

    getLocations() {
        this.$http.get('/locations?limit=1000&slim=true&fields=name,uuid')
        .then(response => {
            if(response && response.data) {
                this.locations = response.data;
            }
        })
    }

  },
}
</script>