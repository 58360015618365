<template>
  <div>

    <page-tabs page="settings" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">

            <div class="form-group">
                <fieldset>
                  <legend>Phone Number Type</legend>
                  <template v-for="(phone_type, type_index) in phone_types">
                    <label
                        class="mr-2 text-capitalize"
                        :for="phone_type.key"
                    >
                      <input
                          :id="phone_type.key"
                          v-model="form.type"
                          type="radio"
                          name="type"
                          :value="phone_type.key"
                      > {{ phone_type.label }}
                    </label>
                  </template>
                  <template v-if="form.type">
                    <p class="form-text text-muted small m-0">{{ setPhoneType(form.type).description }}</p>
                  </template>
                </fieldset>
              </div>

              <template v-if="form.type == 'local'">

                <div class="form-group">
                <label for="state">Browse by State</label>
                <select
                    id="state"
                    v-model="form.state"
                    class="form-control"
                    :required="!form.area_code"
                    :disabled="processing"
                    @change="postForm()"
                >
                  <option value="" />
                  <option
                      v-for="state in states"
                      :key="state.key"
                      :value="state.key"
                  >
                    {{ state.label }}
                  </option>
                </select>
              </div>

                <div class="form-group" v-if="form.state">
                <label for="area_code">Filter by Area Code</label>
                <select
                    id="area_code"
                    v-model="form.area_code"
                    class="form-control"
                    :disabled="processing"
                    @change="postForm()"
                >
                  <option value="" />
                  <option
                      v-for="(area_code, area_index) in area_codes"
                      :key="'area_'+area_index"
                      :value="area_code.code"
                      :hidden="isAreaCodeHidden(area_code)"
                  >
                    {{ area_code.code }} {{ area_code.city }} {{ area_code.state }}
                  </option>
                </select>
              </div>

              </template>

              <template v-if="form.type == 'short_code'">
              <p>This process may take 5 - 7 business days. Once activated, you'll be able to choose a random or memorable short code.</p>
              </template>

              <template v-if="form.type == 'port'">
              <p>Port your existing phone number to eCourtDate.</p>
              <div class="form-group">
                <label for="phone_number">Phone Number</label>
                <input id="phone_number" type="tel" class="form-control" v-model="form.phone_number" required>
              </div>
              <p class="form-text small">You can review number portability before completing the port.</p>
              </template>

              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                <template v-if="form.type == 'local' || form.type == 'toll_free'">
                  Browse Numbers
                </template>

                <template v-if="form.type == 'short_code' || form.type == 'port'">
                  Add
                </template>
              </button>

            </form>

            <template v-if="available_phones && available_phones.length && !processing">
              <ul class="list-group mt-3" :hidden="form.type == 'short_code' || form.type == 'port'">
                <template v-for="(available_phone, available_index) in available_phones">
                  <li
                      :key="'available_phone_'+available_index"
                      class="list-group-item"
                  >
                    <p class="card-text font-weight-bold m-0">{{ available_phone.phone_number }}</p>

                    <button
                        type="button"
                        class="btn btn-outline-success btn-sm float-right"
                        @click="activatePhone(available_phone)"
                        :disabled="processing"
                    >
                     <i class="fa fa-check-circle"></i> Activate Now
                    </button>

                    <template v-if="available_phone.features && available_phone.features.length > 0">
                      <p class="small text-muted m-0">Features</p>
                      <template v-for="(available_feature, available_feature_index) in available_phone.features">
                        <span :key="'available_feature_'+available_feature_index" class="badge badge-pill badge-primary text-uppercase mr-1">{{ available_feature.name }}</span>
                      </template>
                    </template>

                  </li>
                </template>
              </ul>
            </template>


          </div><!--Body-->
        </div><!--Card-->


        <div class="card mt-3">
          <div class="card-body">

            <p class="card-title">Search {{ $route.meta.title }}s</p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input id="search" type="search" class="form-control" v-model="queries.search">
              </div>

              <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>

            </form>

          </div>
        </div>


      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

              </div><!--Row-->


            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <thead>
                <tr>
                  <template v-for="(column, column_index) in columns">
                    <th
                        :key="'column_'+column_index"
                        class="text-uppercase"
                    >
                      <template v-if="column.key">
                        <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
                </thead>

                <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="(record, record_index) in records">
                    <tr :key="'record_'+record_index">
                      <td>
                        <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid}}">
                          <i class="fa fa-edit"></i> {{ record.name }}
                        </router-link>
                      </td>
                      <td>{{ record.phone_number }} <button type="button" class="btn btn-secondary btn-sm" @click="copyToClipboard(record.phone_number)" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button></td>
                      <td>{{ record.type }}</td>
                      <td>
                        <template v-if="record.primary">
                          <i class="fa fa-check-circle text-success"></i> <span class="sr-only">Primary Phone</span>
                        </template>
                        <template v-else>
                          <i class="fa fa-check-times text-danger"></i> <span class="sr-only">Not Primary Phone</span>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.record_calls">
                          <i class="fa fa-check-circle text-success"></i> <span class="sr-only">Record Calls Enabled</span>
                        </template>
                        <template v-else>
                          <i class="fa fa-check-times text-danger"></i> <span class="sr-only">Record Calls Disabled</span>
                        </template>
                      </td>
                      <td>
                      <template v-if="record.outside_business">
                        Outside Business
                        {{ record.business_days }}
                        {{ record.from_time }} {{ record.to_time }}
                      </template>
                      </td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.updated_by }}</td>
                      <td>{{ record.created_at | datetime }}</td>
                      <td>{{ record.updated_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div><!--Responsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

          <div class="row mt-3">
            <div class="col-12 text-center">

              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>

            </div><!--Col-->
          </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from "lodash";
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      validating: false,
      validated: false,
      downloading: false,
      total: 0,
      queries: {},
      records: [],
      area_codes: [],
      downloads: [],
      form: {},
      columns: [
        { key: 'name', label: 'Name'},
        { key: 'phone_number', label: 'Phone Number'},
        { key: 'type', label: 'Type'},
        { key: 'primary', label: 'Primary'},
        { key: 'record_calls', label: 'Record Calls'},
        { key: 'outside_business', label: 'Outside Business'},
        { key: 'created_by', label: 'Created'},
        { key: 'updated_by', label: 'Updated'},
        { key: 'created_at', label: 'Created'},
        { key: 'updated_at', label: 'Updated'}
      ],
      states: [],
      available_phones: [],
      phone_types: [
        { key: 'local', label: 'Local', description: 'Activate a new 10-digit local area code phone number.'},
        { key: 'toll_free', label: 'Toll-Free', description: 'Activate a new toll-free phone number.'},
        { key: 'short_code', label: 'Short Code', description: 'Activate a new shortcode phone number.'},
        { key: 'port', label: 'Port', description: 'Transfer an existing phone number (local, toll free, and shortcode).'}
      ],
      port_data: {}
    }
  },

  created() {
    this.resetForm();
    this.resetQueries();
    this.resetRecords();
    this.setURLParams();
    this.getRecords();
    this.getStates();
    this.getAreaCodes();
  },

  methods: {


    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    setStatusClass(record) {

      if(record.status == 'private') {
        return 'badge-danger';
      }

      if(record.status == 'draft') {
        return 'badge-secondary';
      }

      return 'badge-success';
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        slim: false,
        search: null,
        sort: 'created_at',
        sortDirection: 'desc'
      }
    },

    resetForm() {
      this.form = {
        area_code: null,
        city: null,
        state: null,
        search: null,
        type: 'local'
      }
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    updateSortDirection() {

      if(this.processing) {
        return;
      }

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      if(this.processing) {
        return;
      }

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {

              if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                  self.records.push(data);
                });
              }

              this.queries.skip = this.records.length;

              this.processing = false;
            }
          })
    },

    getTotal() {
      this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              this.setTitle();
            }
          })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },


    postForm() {

      if(this.form.type == 'local' || this.form.type == 'toll_free') {
        this.searchPhones();
        return;
      }

      this.createPhone();
    },

    createPhone() {

      this.processing = true;

      this.$http.post('/'+this.$route.meta.base_url, this.form)
      .then(response => {
        if(response && response.data) {
            
            this.resetForm();
  
            if(response.data.uuid) {
              new this.$noty({text: this.$route.meta.title + ' added'}).show();
              window.location.href = '/'+this.$route.meta.base_url+'/'+response.data.uuid;
            }
  
            this.processing = false;
        }
      })
    },

    searchPhones() {


      this.processing = true;

      if(this.form.type == 'toll_free') {
        this.form.gateway = 'bandwidth';
        this.form.state = null;
        this.form.area_code = null;
      }

      if(this.form.type == 'local') {
        this.form.gateway = 'auto';
      }

      this.available_phones = [];

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/search', this.form))
          .then(response => {
            if(response && response.status === 200) {

              this.processing = false;

              if(response.data && response.data.length) {
                this.available_phones = response.data;
                this.noResults = false;
              } else {
                this.available_phones = [];
                this.noResults = true;
              }

            }

          })
    },

    activatePhone(phone) {

      this.processing = true;

      phone.type = this.form.type;

      this.processing = false;

      this.$http.post('/agency_phones', phone)
          .then(response => {
            if(response && response.data && response.data.uuid) {
              new this.$noty({text: this.$route.meta.title + ' added'}).show();
              this.$router.push('/'+this.$route.base_url+'/'+response.data.uuid);
            }
          })

    },

    resetSearch() {
      this.show_search = true;
      this.resetForm();
      this.available_phones = [];
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    getStates () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/states.json')
          .then(response => {
            if (response && response.data) {
              this.states = response.data;
            }
          })
    },

    getAreaCodes() {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/area_codes.json')
      .then(response => {
        if(response && response.data) {
          this.area_codes = response.data;
        }
      })
    },

    setPhoneType(value) {
      if(!value) {
        return;
      }

      return _.find(this.phone_types, { 'key': value });
    },

    isAreaCodeHidden(area_code) {
        
        if(!area_code) {
          return;
        }

        if(!this.form.state) {
          return false;
        }

        var state = _.find(this.states, { 'key': this.form.state });

        if(!state) {
          return false;
        }


        if(state.label != area_code.state) {
          return true;
        }
  
        return false;
    },

    async downloadPDF() {
    try {

      window.scrollTo(0,0);

      this.downloading = true;

      const element = document.getElementById('main');

      const canvas = await this.$html2canvas(element, {
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight,
        scrollY: -window.scrollY,
        scrollX: -window.scrollX,
        scale: window.devicePixelRatio,
        useCORS: true,
        logging: false
      });

      const imgData = canvas.toDataURL('image/png');

      const pdf = new this.$jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height]
      });

      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

      var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

      pdf.save(name+'.pdf');

      this.downloading = false;
    } catch (error) {
      new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
      console.log(error);
      this.downloading = false;
    }
  },

  downloadCSV () {
    this.downloading = true;

    var queries = this.queries;

    queries.limit = this.total;

    queries.skip = this.downloads.length;

    this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
        .then(response => {
          if(response && response.data && response.data) {
            this.downloads = this.downloads.concat(response.data);

            var csvFile, data, link;

            data = this.convertCSV(this.downloads);

            var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

            csvFile = new Blob([data], {type: "text/csv"});
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(csvFile, file);
            } else {
              link = document.createElement("a");
              link.download = file;
              link.href = window.URL.createObjectURL(csvFile);
              link.style.display = "none";
              document.body.appendChild(link);
              link.click();
            }

            this.downloading = false;

          } else {
            this.downloadCSV();
          }

        });
  },

  convertCSV(records) {

    var results = [];

    if(records[0] && records[0]['uuid']) {
      var headers = Object.keys(records[0]);
    }

    results += headers.join(',')

    results += '\n';

    records.forEach(function(item) {
      var record = '';

      if(!item) {
        return;
      }

      Object.keys(item).forEach(function(key) {

        if(key == 'inbound_calls') {
          item[key] = JSON.stringify(item[key]);
        }

        record += '"' + (item[key] ? item[key] : '') + '",';
      });

      results += record;
      results += '\n';
    });

    return results
  },

  copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      new this.$noty({text: 'Copied to clipboard'}).show();
  },


  },
}
</script>