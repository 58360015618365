<template>
<div>
<template v-if="records && records.length > 0">
  <div class="card my-3">
    <div class="card-body">
      <p class="card-title">
       {{ records.length }} Sent Files
      </p>


    <template v-for="(record, index) in records">

    <li :key="'sent_file_'+index" class="list-group-item">
        <a :href="'/sent_files/'+record.uuid+'?client='+uuid">

        <template v-if="record.name">
            {{ record.name }}
        </template>

        <template v-if="!record.name">
            {{ record.uuid }}
        </template>

        </a>

        <template v-if="record.status">
            <file-status :status="record.status" />
        </template>

        <small class="text-muted">{{ record.created_at | dateTimezone | timeago }}</small>
        
        </li>

    </template>


    </div><!--Body-->
</div><!--Card-->
</template>
</div>
</template>
<script>
export default {

  props: {
    uuid: {
      type: String,
      default: null
    }
  },

    data () {
        return {
            processing: false,
            base_url: '/sent_files',
            records: []
        }
    },

    created() {
        this.getRecords();
    },

    methods: {

        getRecords() {
            this.processing = true;
            this.$http.get(this.base_url+'?client='+this.uuid)
            .then(response => {
                if(response && response.data) {
                    this.records = response.data;
                    this.processing = false;
                }
            })
        }
    }

}
</script>
