<template>
  <div>
    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <h1>
          <template v-if="form && form.amount >= 0">
            ${{ form.amount }}
          </template> {{ form.payment_reference }}
        </h1>

        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-2">
           <template v-if="portalLink">
             <a :href="portalLink" target="_blank" title="Go to Portal" class="btn btn-primary"><i class="fa fa-link"></i> Go to Portal</a>
            </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-2 text-right">
          <template v-if="qr_code">
            <a :href="portalLink" target="_blank" title="Go to Portal">
            <img
              :src="'data:image/svg+xml;base64,'+qr_code"
              width="60"
              height="60"
            >
            </a>
          </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">

          <template v-if="form.disable_messages && form.disable_messages === true">
            <span class="badge badge-danger">MESSAGES DISABLED</span>
          </template>

        <div class="btn-group">

          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle radius-right-0"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              @click="syncRecord"
            >Sync Messages</a>
            <a
              class="dropdown-item"
              @click="duplicateRecord()">
              Duplicate
            </a>
            <a
              v-if="!form.archived_at"
              class="dropdown-item"
              @click="archiveRecord"
            >Archive</a>
            <a
              v-if="form.archived_at"
              class="dropdown-item"
              @click="restoreRecord"
            >Restore</a>
            <a
              class="dropdown-item"
              @click="trashRecord"
            >Trash</a>
          </div>

          <button
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
          <div class="card-body">

    <form @submit.prevent="postForm()">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="amount">Payment Amount</label>
            <div class="input-group">
              <span
                class="input-group-text"
                style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
              >$</span>
              <input
                id="amount"
                v-model="form.amount"
                type="number"
                class="form-control"
                min="0"
                step="0.01"
                required
              >
            </div>
          </div>
        </div><!--Col-->
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="payment_reference">Payment Reference</label>
            <input
              id="payment_reference"
              v-model="form.payment_reference"
              type="text"
              class="form-control"
            >
          </div>
        </div><!--Col-->
      </div><!--Row-->

      <div class="form-group">
        <label for="contact">Contact</label>
        <input id="contact" type="text" class="form-control" v-model="form.contact">
        <small class="form-text text-muted">Phone number or email address of the payee.</small>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="flow">Flow

            <template v-if="form.flow">
              <a
              :href="'/flows/'+form.flow"
              target="_blank"
              data-tooltip="Edit Flow">
              <i class="fa fa-edit"></i> <span class="sr-only">Edit Flow</span>
              </a>
            </template>

            </label>
            <select
              id="flow"
              v-model="form.flow"
              class="form-control"
              :disabled="processing"
            >
              <option value=""></option>
              <template v-for="flow in flows">
                <option
                  :key="flow.uuid"
                  :value="flow.uuid"
                >
                  {{ flow.name }}
                </option>
              </template>
            </select>
          </div>
        </div>

        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="payment_location">Location

        <template v-if="form.location">
          <a
          :href="'/locations/'+form.location"
          target="_blank"
          data-tooltip="Edit Location">
          <i class="fa fa-edit"></i> <span class="sr-only">Edit Location</span>
          </a>
        </template>

            </label>
            <select
              id="payment_location"
              v-model="form.location"
              class="form-control"
              :disabled="processing"
            >
              <option value=""></option>
              <template v-for="location in locations">
                <option
                  :key="location.uuid"
                  :value="location.uuid"
                >
                  {{ location.name }}
                </option>
              </template>
            </select>
          </div>
        </div><!--Col-->
      </div><!--Row-->

      <div class="form-group">
        <label for="location_notes">Location Notes</label>
        <input
          id="location_notes"
          v-model="form.location_notes"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <label for="portal">Portal

        <template v-if="form.portal">
          <a
          :href="'/portals/'+form.portal"
          target="_blank"
          data-tooltip="Edit Portal">
          <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
          </a>
        </template>
        
        </label>
        <select
          id="portal"
          v-model="form.portal"
          class="form-control"
          :disabled="processing"
        >
          <option value="">
            Choose Portal
          </option>
          <template v-for="portal in portals">
            <option
              :key="portal.uuid"
              :value="portal.uuid"
            >
              {{ portal.name }}
            </option>
          </template>
        </select>
      </div>

      <div class="row">
        <div class="form-group col vbDatePicker">
          <label for="issued_at">Issue Date</label>
          <input
            id="issued_at"
            v-model="form.issued_at"
            type="date"
            class="form-control"
            :disabled="processing"
          >
        </div>
        <div class="form-group col">
          <label for="due_at">Due Date</label>
          <input
            id="due_at"
            v-model="form.due_at"
            type="date"
            class="form-control"
            :disabled="processing"
          >
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="status">Status</label>
            <select
              id="status"
              v-model="form.status"
              class="form-control"
              :disabled="processing"
            >
              <option value="" />
              <template v-if="options && options['payment_statuses']">
              <option
                v-for="option in options['payment_statuses']"
                :key="option.label"
                :value="option.label"
              >
                {{ option.label }}
              </option>
              </template>
            </select>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="payment_type">Type</label>
            <input
              id="payment_type"
              v-model="form.type"
              type="text"
              class="form-control"
            >
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="case_number">Case Number</label>
        <input id="case_number" type="text" class="form-control" v-model="form.case_number">
      </div>

      <div class="form-group">
        <label for="citation">Citation</label>
        <input id="citation" type="text" class="form-control" v-model="form.citation">
      </div>

      <div class="form-group">
        <label for="payment_description">Description</label>
        <textarea
          id="payment_description"
          v-model="form.description"
          class="form-control"
          rows="3"
        />
      </div>

      <div class="form-group">
        <label for="payment_notes">Internal Notes</label>
        <textarea
          id="payment_notes"
          v-model="form.notes"
          class="form-control"
          rows="3"
        />
      </div>

      <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
      
    </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs class="mt-3" :form="form"></audit-logs>

      </div><!--Col-->

      <div class="col-sm-12 col-md-6 col-lg-4">

        <template v-if="form && form.uuid">
            <payment-transactions :payment="form" />
        </template>
        
      </div><!--Col-->

      <div class="col-sm-12 col-md-6 col-lg-4">

        <template v-if="!form.client">

          <form @submit.prevent="searchClients()">
            <div class="input-group mb-3">
              <input
                  v-model="search"
                  type="text"
                  class="form-control text-lowercase"
                  aria-label="Client"
                  aria-describedby="client"
              >
              <div class="input-group-append">
                <button
                    id="client"
                    class="btn btn-primary"
                    type="submit"
                    :disabled="processing"
                >
                  Search Clients
                </button>
              </div>
            </div>

            <template v-if="clients && clients.length > 0">
              <ul class="list-group mb-3">
                <template v-for="(client, client_index) in clients">
                  <li
                      :key="'client_'+client_index"
                      class="list-group-item"
                  >
                    <a :href="'/clients/'+client.uuid" target="_blank">{{ client.name }}</a>

                    <button type="button" class="btn btn-success float-right" @click="attachClient(client)" :disabled="processing">Attach</button>
                  </li>
                </template>
              </ul>
            </template>
          </form>
        </template>


        <div class="card">
          <div class="card-body">

            <template v-if="!client.uuid">
              <div class="alert alert-danger m-0">
                No client found.
              </div>
            </template>

            <template v-if="!processing && client.uuid">

              <div class="row">

                <div class="col-sm-12 col-md-6">

                  <p class="card-title">
                    Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                    {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }} {{ client.client_reference }}
                  </router-link>
                  </p>

                </div>

                <div class="col-sm-12 col-md-6 text-right">
                  <div class="btn-group">
                  <button type="button" class="btn btn-primary btn-sm" @click="generateLookup(client)" :disabled="processing">Portal Profile</button>
                  <button type="button" class="btn btn-danger btn-sm" @click="detachClient(client)" :disabled="processing">Detach</button>
                  </div>
                </div><!--Col-->

              </div><!--Row-->

              <ul class="list-group">

                <template v-if="client.language">
                  <li class="list-group-item">
                    Language

                    {{ setLanguage(client.language) }}

                  </li>
                </template>

                <template v-if="client.type">
                  <li class="list-group-item">
                    Type {{ client.type }}
                  </li>
                </template>

                <template v-if="client.group">
                  <li class="list-group-item">
                    Group {{ client.group }}
                  </li>
                </template>

                <template v-if="client.status">
                  <li class="list-group-item">
                    Status {{ client.status }}
                  </li>
                </template>

              </ul>
            </template>


          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form.client">
          <client-contacts
              :client_uuid="form.client"
              class="my-3"
          />
        </template>

        <template v-if="form && form.uuid && client && client.uuid">

          <messages-form
            :client="form.client"
            :payment="form.uuid"
          />

          <messages-scheduled
            :payment_uuid="form.uuid"
            class="mb-3"
          />

          <messages-feed :payment_uuid="form.uuid" />

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],
    
    data () {

        return {
          processing: false,
          form: {},
          client: {},
          flows: [],
          locations: [],
          portals: [],
          portal: {},
          options: [],
          qr_code: null,
          search: null,
          clients: []
        }
    },

    computed: {

      portalLink: function() {

        if(!this.portal || !this.portal.link) {
          return;
        }

        return 'https://'+this.portal.link+'.'+this.portal.domain+'/p/'+this.form.url;
      }

    },

    mounted() {

        var self = this;

        this.$eventBus.$on('paymentUpdated', function (data) {
            self.form = {...self.form, ...data};
        });


    },

    created () {

        this.resetForm();

        this.getRecord();

        this.getOptions();
        this.getFlows();
        this.getLocations();
        this.getPortals();
        this.getLanguages();
    },

methods: {

  getOptions() {
    this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
        .then(response => {
          if (response && response.data) {
            this.options = response.data;
          }
        })
  },

  getFlows() {
    this.$http.get('/flows?slim=true&limit=1000&sort=name&sortDirection=asc&fields=uuid,name')
        .then(response => {
          if (response && response.data) {
            this.flows = response.data;
          }
        })
  },

  getLocations() {
    this.$http.get('/locations?slim=true&limit=1000&sort=name&sortDirection=asc&fields=uuid,name')
        .then(response => {
          if (response && response.data) {
            this.locations = response.data;
          }
        })
  },

  getPortals() {
    this.$http.get('/portals?slim=true&limit=1000&sort=name&sortDirection=asc&fields=uuid,name,link&domain=ecourtpayment.com')
        .then(response => {
          if (response && response.data) {
            this.portals = response.data;
          }
        })
  },

  getPortal() {

    if (!this.form.portal) {
      this.portal = {};
      this.qr_code = null;
      return;
    }

    if(this.portal && this.portal.uuid && this.portal.uuid == this.form.portal) {
      return;
    }

    this.$http.get('/portals/' + this.form.portal + '?slim=true&fields=uuid,name,domain,link')
        .then(response => {
          if (response && response.data) {
            this.portal = response.data;

            this.getQRCode();
          }
        })
  },

  getQRCode() {
    this.$http.post('/qrs?content=' + this.portalLink)
        .then(response => {
          if (response && response.data) {
            this.qr_code = response.data;
          }
        })
  },

  resetForm() {
    this.form = {
      client: null,
      contact: null,
      amount: null,
      payment_reference: null,
      flow: null,
      location_notes: null,
      location: null,
      portal: null,
      issued_at: null,
      due_at: null,
      status: null,
      type: null,
      citation: null,
      case_number: null,
      description: null,
      notes: null
    }

    this.qr_code = null;
  },

  postForm() {
    this.processing = true;
    this.$http.patch('/' + this.$route.meta.base_url + '/' + this.form.uuid, this.form)
        .then(response => {
          if (response && response.data) {

            if (response.data.updated_at) {
              new this.$noty({text: this.$route.meta.title + ' updated'}).show();
              this.form.updated_at = response.data.updated_at;
            }

            this.getPortal();

            this.processing = false;

          }
        })
  },

  getRecord() {
    this.processing = true;
    this.$http.get('/' + this.$route.meta.base_url + '/' + this.$route.params.id)
        .then(response => {
          if (response && response.data) {

            if (response.data.uuid) {

              this.form = response.data;

              this.setTitle();

              this.getClient();

              this.getPortal();

            }

            this.processing = false;
          }
        })
  },

  getClient() {

    if(!this.form.client) {
      return;
    }

    this.$http.get('/clients/' + this.form.client + '?slim=true&fields=uuid,first_name,middle_name,last_name,group,status,type,language')
        .then(response => {
          if (response && response.data) {
            this.client = response.data;
          }
        })
  },

  setTitle() {
    document.title = this.form.formatted_amount + ' | ' + this.form.payment_reference + ' | ' + this.$route.meta.title;
  },

  restoreRecord() {
    this.processing = true;
    this.$http.put('/' + this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
        .then(response => {
          if (response && response.data && response.data.message) {
            new this.$noty({text: response.data.message}).show();
            this.getRecord();
          }
        })
  },

  archiveRecord() {
    this.processing = true;
    this.$http.put('/' + this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
        .then(response => {
          if (response && response.data && response.data.message) {
            new this.$noty({text: response.data.message}).show();
            this.getRecord();
          }
        })
  },

  trashRecord() {
    this.processing = true;
    this.$http.delete('/' + this.$route.meta.base_url + '/' + this.form.uuid)
        .then(response => {
          if (response && response.status === 204) {
            this.form.deleted_at = new Date().toISOString();
            new this.$noty({text: this.$route.meta.title + ' trashed', type: 'error'}).show();
            this.processing = false;
          }
        })
  },

  syncRecord() {
    this.processing = true;
    this.$http.post('/' + this.$route.meta.base_url + '/' + this.form.uuid + '/sync')
        .then(response => {
          if (response && response.status === 202) {
            new this.$noty({text: 'Syncing messages'}).show();
            this.processing = false;
          }
        })
  },

  searchClients() {
    this.processing = true;

    if(!this.search) {
      this.search = '';
    }

    this.$http.get('/clients?search=' + this.search || '')
        .then(response => {
          if (response && response.data) {
            this.processing = false;
            this.clients = response.data;
          }
        })
  },

  attachClient(client) {
    this.form.client = client.uuid;
    this.search = null;
    this.clients = [];
    this.postForm();
    this.getClient();
  },

  detachClient(client) {
    this.form.client = null;
    this.client = {};
    this.search = null;
    this.clients = [];
    this.postForm();
  },

  duplicateRecord() {
    var form = this.form;

    delete form.uuid;
    delete form.client;
    delete form.updated_at;

    this.$http.post('/'+this.$route.meta.base_url, form)
        .then(response => {
          if (response && response.data) {
            this.$router.push({name: this.$route.name, params: {id: response.data.uuid}});
            new this.$noty({text: this.$route.meta.title + ' duplicated'}).show();
          }
        })
  },

  getLanguages () {
    this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
        .then(response => {
          if(response && response.data) {
            this.languages = response.data;
          }
        })
  },


  setLanguage(language) {

    if(!language) {
      return;
    }

    var result = _.find(this.languages, {'key': language});

    if(!result) {
      return;
    }

    return result.label;

  },

  generateLookup(client) {

    if(!this.form.portal) {
      new this.$noty({text: 'Please select a portal'}).show();
      return;
    }

    this.processing = true;

    var form = {
      client: client.uuid,
      portal: this.form.portal
    }

    this.$http.post('/payment_lookups', form)
        .then(response => {
          if (response && response.data) {
            this.processing = false;
            window.open('https://'+this.portal.link+'.'+this.portal.domain+'/c/'+response.data.url, '_blank');
            new this.$noty({text: 'Payment Lookup created'}).show();
          }
        })
  }

}
}
</script>
