<template>
  <div>

    <page-tabs page="message_templates" />

    <div class="row">
      <div class="col-lg-3 col-md-12 mb-3">

        <div class="card">
          <div class="card-body">

            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>

            <form
              @submit.prevent="postForm"
            >
              <div class="form-group">
                <label for="name">{{ $route.meta.title }} Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>
       
              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                  type="search"
                  placeholder="Search by name..."
                >
              </div>

              <div class="form-group">
                <label for="search_trigger">Trigger</label>
                <select id="search_trigger" class="form-control" v-model="queries.trigger" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(trigger, trigger_index) in triggers">
                    <option :key="'search_trigger_'+trigger_index" :value="trigger.value">{{ trigger.label }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_value">Field Value</label>
                <select id="search_value" class="form-control" v-model="queries.value" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(status, status_index) in statuses">
                    <option :key="'search_value_'+status_index" :value="status.name">{{ status.name }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="simulate"
                    v-model="queries.simulate"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="simulate"
                >Simulate Mode</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="trashed"
                >Trashed</label>
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
      <div id="main" class="col-lg-9 col-md-12">

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none" data-html2canvas-ignore>
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  <button type="button" class="btn btn-secondary" :class="{'active': layout === 'table'}" @click="setLayout('table')" :disabled="processing"><i class="fa fa-table"></i> <span class="sr-only">Table</span></button>
                  <button type="button" class="btn btn-secondary" :class="{'active': layout === 'grid'}" @click="setLayout('grid')" :disabled="processing"><i class="fa fa-grid"></i> <span class="sr-only">Grid</span></button>
                </div>
              </div><!--Col-->

            </div><!--Row-->


            <div class="table-responsive my-3" v-if="layout == 'table'">

            <table class="table table-striped table-hover">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="$route.meta.base_url"
              />

              <tbody>
                <template v-for="(record, index) in records">
                  <tr :key="'auto_message_'+index">
                    <td>
                      <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                        {{ record.name }}
                      </router-link>
                    </td>
                    <td>
                      <template v-if="record.trigger">
                        {{ filterTrigger(record.trigger) }}
                      </template>
                    </td>
                    <td>
                      <template v-if="record.simulate == 1">
                        <i class="fa fa-check text-success" /> <span class="sr-only">SIMULATE MODE</span>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.delay">
                        {{ record.delay }}m
                      </template>
                      <template v-else>
                        Immediate
                      </template>
                    <td>{{ record.created_by }}</td>
                    <td>{{ record.created_at | datetime }}

                      <template v-if="record.deleted_at">
                        <span class="badge badge-pill badge-danger">TRASHED</span>
                      </template>
                    </td>
                    <td>{{ record.updated_at | datetime }}</td>
                  </tr>
                </template>
              </tbody>
            </table>

              </div><!--Table responsive-->

            <template v-if="layout == 'grid'">

              <div class="row my-3">

              <template v-for="(record, record_grid) in records">

                <div :key="'record_grid_'+record_grid" class="col-sm-12 col-md-4 mb-3">

                    <div class="card">
                      <div class="card-body">

                        <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                          {{ record.name }}
                        </router-link>

                        <template v-if="record.trigger">
                          <p class="m-0">
                            <strong>Trigger:</strong> {{ filterTrigger(record.trigger) }}
                          </p>

                          <template v-if="record.value">
                            when field is <span class="badge badge-pill badge-primary">{{ record.value }}</span>
                          </template>
                        </template>

                        <template v-if="record.subject">
                          <p class="m-0">
                            <strong>Subject:</strong> {{ record.subject }}
                          </p>
                        </template>

                        <template v-if="record.file_url">
                          <p class="m-0">
                            <strong>File:</strong> {{ record.file_url }}
                          </p>
                        </template>

                        <template v-if="record.simulate == 1">
                          <span class="badge badge-pill badge-primary">Simulate Mode</span>
                        </template>

                        <template v-if="record.templates">
                          <template v-for="(template, template_index) in record.templates">
                            <p :hidden="!template">
                              <strong>{{ setLanguageName(template_index) }}:</strong>
                            </p>
                            <template v-if="template">
                              <p class="outbound">{{ template }}</p>
                            </template>
                          </template>
                        </template>

                      </div><!--Body-->
                    </div><!--Card-->

                </div><!--Col-->

              </template>

              </div><!--Row-->

            </template>

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="records && records.length < total">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary d-print-none"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      records: [],
      downloads: [],
      processing: false,
      downloading: false,
      layout: 'table',
      total: 0,
      queries: {},
      form: {},
      columns: [
          {key: 'name', label: 'Name'},
          {key: 'trigger', label: 'Trigger'},
          {key: 'simulate', label: 'Simulate'},
          {key: 'delay', label: 'Delay'},
          {key: 'created_by', label: 'Creator'},
          {key: 'created_at', label: 'Created'},
          {key: 'updated_at', label: 'Updated'}
      ],
      triggers: [],
      languages: [],
      statuses: []
    }
  },

  created () {
    this.resetRecords();
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getTriggers();
    this.getLanguages();
    this.getRecords();
    this.getStatuses();
    this.setDefaultLayout();
  },

  methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    resetForm() {
      this.form = {
        name: null,
        trigger: 'manual'
      }
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        skip: 0,
        sort: 'name',
        sortDirection: 'desc',
        trigger: null,
        value: null,
        search: null,
        trashed: false,
        archived: false,
        simulate: false
      }
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords () {

      this.processing = true;

      this.updateURL();

      this.totalRecords();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if (response && response.data) {

          this.processing = false;

          if(response.data.length) {

            this.queries.skip += response.data.length;

            var self = this;

            response.data.forEach(function(data) {
              self.records.push(data);
            });

          }
        }
      })
    },

    totalRecords() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    postForm() {
        this.processing = true;
        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
            if(response && response.status) {

              this.processing = false;

                if(response.status === 201) {
                  this.resetForm();
                  this.total++;
                  this.setTitle();
                  this.records.unshift(response.data);
                  new this.$noty({text: this.$route.meta.title + ' added'}).show();
                  window.location.href = '/'+this.$route.meta.base_url+'/'+response.data.uuid;
                }


            }
        });
    },

    filterTrigger(trigger) {

        var result = _.find(this.triggers, {'value': trigger});

        if(!result) {
          return 'manual';
        }

        return result.label;
    },

    getTriggers() {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
      .then(response => {
        if(response && response.data && response.data.auto_message_triggers) {
          this.triggers = response.data.auto_message_triggers;
        }
      })
    },

    getStatuses() {
      this.$http.get('/statuses?limit=100&fields=name&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.statuses = response.data;
        }
      })
    },

    getLanguages () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
          .then(response => {
            if(response && response.data) {
              this.languages = response.data;
            }
          })
    },

    async downloadPDF() {
      try {

        window.scrollTo(0,0);

        this.downloading = true;

        const element = document.getElementById('main');

        const canvas = await this.$html2canvas(element, {
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          scale: window.devicePixelRatio,
          useCORS: true,
          logging: false
        });

        const imgData = canvas.toDataURL('image/png');

        const pdf = new this.$jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

        pdf.save(name+'.pdf');

        this.downloading = false;
      } catch (error) {
        new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
        console.log(error);
        this.downloading = false;
      }
    },

    downloadCSV () {
      this.downloading = true;

      var queries = this.queries;

      queries.limit = this.total;

      queries.skip = this.downloads.length;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
          .then(response => {
            if(response && response.data && response.data) {
              this.downloads = this.downloads.concat(response.data);

              var csvFile, data, link;

              data = this.convertCSV(this.downloads);

              var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

              csvFile = new Blob([data], {type: "text/csv"});
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(csvFile, file);
              } else {
                link = document.createElement("a");
                link.download = file;
                link.href = window.URL.createObjectURL(csvFile);
                link.style.display = "none";
                document.body.appendChild(link);
                link.click();
              }

              this.downloading = false;

            } else {
              this.downloadCSV();
            }

          });
    },

    convertCSV(records) {

      var results = [];

      if(records[0] && records[0]['uuid']) {
        var headers = Object.keys(records[0]);
      }

      results += headers.join(',')

      results += '\n';

      records.forEach(function(item) {
        var record = '';

        if(!item) {
          return;
        }

        Object.keys(item).forEach(function(key) {

          if(key == 'messages') {
            Object.keys(item[key]).forEach(function(message) {
              if(item[key][message]['name']) {
                record += '"' + item[key][message]['name'] + '",';
              }
              if(item[key][message]['templates'] && item[key][message]['templates']['en']) {
                record += '"' + item[key][message]['templates']['en'] + '",';
              }
            });
            return;
          }

          record += '"' + (item[key] ? item[key] : '') + '",';
        });

        results += record;
        results += '\n';
      });

      return results
    },

    setDefaultLayout() {
      var preferences = localStorage.getItem('preferences');

      if(preferences) {
        preferences = JSON.parse(preferences);
        this.layout = preferences['default_layout'] || this.layout;
      }

    },

    setLayout(layout) {
      this.$eventBus.$emit('updatePreferences', {'default_layout': layout})
      this.layout = layout;
    },

    setLanguageName(language) {

      if(!language) {
        return;
      }

      var language = _.find(this.languages, {'key': language});

      if(!language || !language.label) {
        return language;
      }

      return language.label;
    },

  }
}
</script>
