<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row">
      <div class="col-sm-12 col-md-4">
          <h1>
            <template v-if="form.amount">{{ form.amount }}</template>
          </h1>

          <template v-if="form.fee"><span class="badge badge-pill badge-secondary">Convenience Fee {{ form.fee }}</span></template>

          <template v-if="form.status"><span class="badge badge-pill text-uppercase" :class="setStatusClass(form)">{{ form.status }}</span></template>

          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <template v-if="payment && payment.portal && portalLink">

          <a :href="portalLink" class="btn btn-outline-secondary" target="_blank" :disabled="processing"><i class="fa fa-link"></i> Portal Link</a>

          <template v-if="qr_code">
            <a :href="portalLink" target="_blank" title="Go to Portal">
            <img
              :src="'data:image/svg+xml;base64,'+qr_code"
              width="50"
              height="50"
            >
            </a>
          </template>

        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">

                <a
                  v-if="form.deleted_at || form.archived_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>

                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-lg-4 col-sm-12">

        <template v-if="form && form.pay_data">

          <div class="card">
            <div class="card-body">

              <p class="card-title">Transaction Data</p>

              <ul class="list-group">

                <template v-if="form.pay_data.card && form.method == 'card'">
                  <li class="list-group-item">Method <span class="text-uppercase">{{ form.method }}</span> <img :src="$root.assets_url+'images/payments/'+form.pay_data.card.brand+'.svg'" width="40" :alt="form.pay_data.card.brand"></li>
                  <li class="list-group-item">Network <span class="text-uppercase">{{ form.pay_data.card.network }}</span></li>
                  <li class="list-group-item">Last 4 {{ form.pay_data.card.last4 }}</li>
                  <li class="list-group-item">Exp Month {{ form.pay_data.card.exp_month }}</li>
                  <li class="list-group-item">Exp Year {{ form.pay_data.card.exp_year }}</li>
                  <li class="list-group-item">Funding {{ form.pay_data.card.funding }}</li>
                </template>

              </ul>

            </div><!--Body-->
          </div><!--Card-->

        </template>

        <template v-if="form && form.charge">

          <div class="card mt-3">
            <div class="card-body">

              <p class="card-title">Fraud Checks</p>

              <ul class="list-group">

                <template v-if="form.charge && form.charge.outcome.risk_level">
                  <li class="list-group-item">Risk Level {{ form.charge.outcome.risk_level }}</li>
                </template>

                <template v-if="form.pay_data && form.pay_data.card && form.pay_data.card.checks">
                  <template v-for="(pay_check, check_index) in form.pay_data.card.checks">
                    <li :key="'pay_check_'+check_index" class="list-group-item text-uppercase"><span class="mr-3">{{ check_index | rmDashes }}</span>
                      <template v-if="pay_check == 'pass'">
                        <span class="badge badge-success">Pass</span>
                      </template>
                      <template v-if="pay_check == 'fail'">
                        <span class="badge badge-danger">Fail</span>
                      </template>
                      <template v-if="!pay_check">
                        <span class="badge badge-secondary">N/A</span>
                      </template>
                    </li>
                  </template>

                </template>

              </ul>

            </div><!--Body-->
          </div><!--Card-->

        </template>

        <template v-if="form.status == 'succeeded'">

          <div class="card mt-3">
            <div class="card-body">
              <p class="card-title">Refund Transaction</p>

              <form @submit.prevent="postRefund()">

                <div class="form-group">
                  <label for="refund_reason">Reason</label>
                  <select id="refund_reason" class="form-control text-uppercase" v-model="refund.reason" :disabled="processing">
                    <option value=""></option>
                    <template v-for="(reason, reason_index) in refund_reasons">
                      <option :key="'reason_'+reason_index" :value="reason">{{ reason }}</option>
                    </template>
                  </select>
                </div>

              <button type="submit" class="btn btn-danger">Submit Refund</button>

              </form>

            </div>
          </div>

        </template>

        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-lg-4 col-sm-12">

        <template v-if="form.client">
          <client-card :client="form.client" class="mb-3" />
        </template>

      <template v-if="payment && payment.uuid">

      <div class="card">
        <div class="card-body">
          <p class="card-title">
            Payment <a :href="'/payments/'+payment.uuid">{{ payment.payment_reference }}</a>
          </p>

          <ul class="list-group">
            <template v-if="payment.amount">
              <li class="list-group-item">
                AMOUNT ${{ payment.amount }}
              </li>
            </template>

            <template v-if="payment.issued_at">
              <li class="list-group-item">
                ISSUED {{ payment.issued_at | date }}
              </li>
            </template>
          
            <template v-if="payment.due_at">
              <li class="list-group-item">
                DUE {{ payment.due_at | date }}
              </li>
            </template>

            <template v-if="payment.status">
              <li class="list-group-item">
                STATUS {{ payment.status }}
              </li>
            </template>
          </ul>
        </div><!--Body-->
      </div><!--Card-->

      </template>


      </div><!--Col-->

      <div class="col-lg-4 col-sm-12">

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      searching: false,
      form: {},
      queries: {},
      client: {},
      qr_code: null,
      refund: {},
      payment: {},
      portal: {},
      refund_reasons: ['duplicate', 'fraudulent', 'requested_by_customer'],
      statuses: ['succeeded', 'viewed', 'failed', 'refunded']
    }
  },

  computed: {


    portalLink: function() {
      if(!this.portal.link) {
        return;
      }

      return 'https://'+this.portal.link+'.'+this.portal.domain+'/p/'+this.payment.url;
    }

  },


  created() {
    this.resetQueries();
    this.resetForm();
    this.resetRefund();
    this.getRecord();
  },

  methods: {

    resetQueries() {
      this.queries = {
        client: null,
        payment: null
      }
    },

    resetForm() {
      this.form = {
        amount: 0,
        client: null
      }

    },

    resetRefund() {
      this.refund = {
        amount: 0,
        reason: null
      }
    },

    getRecord() {

        this.processing = true;

        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                
                this.form = response.data;

                this.processing = false;

                this.getPayment();
            }
        })
    },

    getPayment() {

        if(!this.form.payment) {
          return;
        }

        this.$http.get('/payments/'+this.form.payment)
        .then(response => {
            if(response && response.data) {
                
                this.payment = response.data;

                this.getPortal();

            }
        })
    },

    getPortal() {

      if(!this.payment.portal) {
        return;
      }

      this.$http.get('/portals/'+this.payment.portal)
      .then(response => {
        if(response && response.data) {
          this.portal = response.data;
          this.getQRCode();
        }
      })
    },

    postForm() {

      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
          }

        }
      })
    },


    restoreRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
      .then(response => {
        if(response && response.data) {
              
              this.processing = false;

              if(response.status === 200) {
                new this.$noty({'text': this.$route.meta.title + ' restored'}).show()
                this.form.deleted_at = null;
                this.form.archived_at = null;
                this.form.updated_at = new Date().toISOString();
              }

        }
      })
    },


  trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
      .then((response) => {
        if(response && response.status) {
          this.processing = false;

          if(response.status === 204) {
            new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
            this.form.deleted_at = new Date().toISOString();
          }
        }
      })
  },

    getQRCode() {

      if(!this.portalLink) {
        return;
      }

      this.qr_code = null;

      this.$http.post('/qrs?content='+this.portalLink)
      .then(response => {
          if(response && response.data) {
              this.qr_code = response.data;
          }
      })
    },

    postRefund() {

      if(!this.form.amount) {
        return;
      }

      this.refund.amount = this.form.amount;

      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/refunds', this.refund)
          .then(response => {
              if(response.status === 201) {
                new this.$noty({text: 'Refund submitted'}).show();
                this.processing = false;
                this.resetRefund();
              }
          })
    },

    setMaxRefund() {
      if(!this.form.amount) {
        return 0;
      }

      return this.form.amount.replace([',', '$'], '');
    },

    setStatusClass(record) {
      if(!record.status) {
        return;
      }

      if(record.status == 'succeeded') {
        return 'badge-success';
      }

      if(record.status == 'viewed') {
        return 'badge-warning';
      }

      if(record.status == 'failed') {
        return 'badge-danger';
      }

      return 'badge-secondary';
    }


  }
}
</script>