<template>
  <div>

    <template v-if="url">

      <template v-if="isImage()">

        <a :href="url" target="_blank">
          <img :src="url" class="img-fluid" :alt="form.name">
        </a>

      </template>

      <template v-if="isPDF()">
        <iframe :src="url" width="100%" height="960" frameborder="0" border="0px"></iframe>
      </template>

      <template v-if="isVideo()">

        <video preload=auto width="100%" height="600" controls>
          <source :src="url" :type="form.type">
        </video>

      </template>

      <template v-if="isAudio()">

        <audio controls>
          <source :src="url" :type="form.type">
        </audio>

      </template>

      <template v-if="isCSV()">

        <template v-if="rows && rows.length > 0">

          <div class="card">
            <div class="card-body">

        <div class="table-responsive">
        <table class="table table-hover table-striped">
          <thead>
          <tr>
            <template v-for="(column, column_index) in rows[0].split(',')">
              <th :key="'column_'+column_index" class="font-weight-bold">{{ column }}</th>
            </template>
          </tr>
          </thead>
          <tbody>

        <template v-for="(row, row_index) in rows">
          <tr :key="'row_'+row_index" :hidden="row_index == 0">
            <template v-for="(column, column_index) in row.split(',')">
              <td :key="'column_'+column_index">{{ column.replace(/["']/g, "") }}</td>
            </template>
          </tr>
        </template>

          </tbody>
        </table>
        </div><!--Responsive-->

            </div>
          </div>

        </template><!--Table-->


      </template><!--CSV-->

    </template>

  </div>
</template>
<script>
export default {

props: ['form', 'url'],

data() {
    return {
      processing: false,
      base_url: '/files',
      download: {},
      rows: []
    }
},

  created() {
      this.downloadFile();
  },

methods: {

    isImage() {

      if(!this.form || !this.form.type) {
        return false;
      }

      const types = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg', 'image/svg', 'image/svg+xml'];

      return types.includes(this.form.type);
    },

    isVideo() {

      if(!this.form || !this.form.type) {
        return false;
      }

      const types = ['video/mp4', 'video/ogg', 'video/webm', 'video/mp3'];

      return types.includes(this.form.type);
    },

    isAudio() {

      if(!this.form || !this.form.type) {
        return false;
      }

      const types = ['audio/mpeg', 'audio/mp4', 'application/ogg', 'audio/ogg', 'audio/x-wav', 'audio/wav', 'audio/webm', 'audio/aac', 'audio/aacp', 'audio/webm', 'audio/x-caf', 'audio/flac'];

      return types.includes(this.form.type);
    },

    isPDF() {

      if(!this.form || !this.form.type) {
        return false;
      }

      const types = ['application/pdf'];

      return types.includes(this.form.type);
    },

    isCSV() {

      if(!this.form || !this.form.type) {
        return false;
      }

      const types = ['text/csv'];

      return types.includes(this.form.type);
    },

    downloadFile() {

      if(!this.isCSV()) {
        return;
      }

      this.$axios.get(this.url)
          .then(response => {
            if (response && response.data) {
              this.download = response.data;

              this.parseCSV();
            }
          })
    },

  parseCSV() {
      if(!this.download) {
        return;
      }

    this.rows = this.download.trim().split('\n');
  }

}

}
</script>