export default {


methods: {


buildQueries(url, queries) {

    var filters = ""

    for(const query in queries) {

        if(queries[query]) {
            filters += encodeURIComponent(query) + "=" + encodeURIComponent(queries[query]) + "&";
        }
        
    }

    if(filters.length) {
        filters = filters.substring(0, filters.length-1);
    }

    return url + "?" + filters
},

    setDocTitle() {

    var title = this.$route.meta.title + ' | eCourtDate.com';

    if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.name) {
        title = this.$route.meta.title + ' | ' + this.$root.current_user.current_agency.name;
    }

    document.title = title;
    },

    isUUID(uuid) {

      const regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

      return regex.test(uuid);
    }

}

}