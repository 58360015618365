<template>
  <div>
    <template v-if="contact.valid == 1">
      <span class="badge badge-success mr-1">VALID</span>
    </template>

    <template v-if="contact.notify == 1 && contact.optin != 0">
      <span class="badge badge-success mr-1">NOTIFY</span>
    </template>

    <template v-if="contact.primary == 1">
      <span class="badge badge-success mr-1">PRIMARY</span>
    </template>

    <template v-if="contact.optin == 1">
      <span class="badge badge-success mr-1">OPTED-IN</span>
    </template>

    <template v-if="contact.optin == 0">
      <span class="badge badge-danger mr-1">OPTED-OUT</span>
    </template>
    <template v-if="contact.valid == 0">
      <span class="badge badge-danger mr-1">INVALID</span>
    </template>

    <template v-if="contact.notify == 0">
      <span class="badge badge-danger mr-1">INACTIVE</span>
    </template>

    <template v-if="contact.deleted_at">
      <span class="badge badge-danger">TRASHED</span>
    </template>
  </div>
</template>

<script>

export default {

props: ['contact'],

}

</script>