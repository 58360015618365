<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Opened {{ record.created_at | dateTimezone | timeago }}

              <template v-if="ip_address.location && ip_address.location.country_flag_emoji">
                {{ ip_address.location.country_flag_emoji }}
              </template>
            </p>

            <ul class="list-group">
              <template v-if="record.ip_address">
                <li class="list-group-item">
                  IP ADDRESS <a :href="'/ips/'+record.ip_address">{{ record.ip_address }}</a>
                </li>
              </template>

              <template v-if="record.url">
                <li class="list-group-item">
                  URL {{ record.url }}
                </li>
              </template>

              <template v-if="record.platform">
                <li class="list-group-item">
                  PLATFORM {{ record.platform }}
                </li>
              </template>

              <template v-if="record.os">
                <li class="list-group-item">
                  OS {{ record.os.name }} {{ record.os.Name }}
                </li>
              </template>
            </ul>

            <timestamps :form="record" />
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="record.user_agent">
          <user-agent
            :raw="record.user_agent"
            class="mt-3"
          />
        </template>

        <template v-if="record.message">
          <message-card
            :message="record.message"
            class="mt-3"
          />
        </template>
      </div><!--Col-->

      <div class="col-md-9">
        <div
          id="map"
          class="map-lg"
        />
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
export default {

  data () {
    return {
      processing: false,
      record: {},
      map: null,
      ip_address: {}
    }
  },

  created() {
    this.getRecord();
  },

  methods: {

    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          this.record = response.data;

          this.getIPAddress();
        }
      })
    },

    getIPAddress() {

      if(!this.record.ip_address) {
        return;
      }

      this.$http.get('/ips/'+this.record.ip_address)
      .then(response => {
        if(response && response.data) {
          this.ip_address = response.data;

          if(this.ip_address.latitude && this.ip_address.longitude) {
            this.initMap();
          }
        }
      })
    },


    initMap() {
        this.map = window.L.map("map").setView([this.ip_address.latitude, this.ip_address.longitude], 8);
        window.L.tileLayer(this.$root.map_url+'alidade_smooth/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
        }).addTo(this.map);

        window.L.circle([this.ip_address.latitude, this.ip_address.longitude], {
          color: 'red',
          fillColor: '#f03',
          fillOpacity: 0.5,
          radius: 10000
        }).addTo(this.map);
    },

  },
}
</script>