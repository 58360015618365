import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from './views/Login.vue';
import DashboardIndex from './views/DashboardIndex.vue';
import PhonesIndex from './views/PhonesIndex.vue';
import DatapointsIndex from './views/DatapointsIndex.vue';
import DatapointsEdit from './views/DatapointsEdit.vue';
import DashboardsIndex from './views/DashboardsIndex.vue';
import DashboardsEdit from './views/DashboardsEdit.vue';
import DashboardTemplatesIndex from './views/DashboardTemplatesIndex.vue';
import ClientsIndex from './views/ClientsIndex.vue';
import ClientsEdit from './views/ClientsEdit.vue';
import StatsIndex from './views/StatsIndex.vue';
import BulkActionsIndex from './views/BulkActionsIndex.vue';
import BulkActionsEdit from './views/BulkActionsEdit.vue';
import BulkTemplatesIndex from './views/BulkTemplatesIndex.vue';
import UsersIndex from './views/UsersIndex.vue';
import UsersEdit from './views/UsersEdit.vue';
import AttorneysIndex from './views/AttorneysIndex.vue';
import AttorneysEdit from './views/AttorneysEdit.vue';
import EventsIndex from './views/EventsIndex.vue';
import EventsEdit from './views/EventsEdit.vue';
import CalendarsIndex from './views/CalendarsIndex.vue';
import CheckinsIndex from './views/CheckinsIndex.vue';
import CheckinsEdit from './views/CheckinsEdit.vue';
import CasesIndex from './views/CasesIndex.vue';
import CasesEdit from './views/CasesEdit.vue';
import BondsIndex from './views/BondsIndex.vue';
import BondsEdit from './views/BondsEdit.vue';
import WarrantsIndex from './views/WarrantsIndex.vue';
import WarrantsEdit from './views/WarrantsEdit.vue';
import PaymentsIndex from './views/PaymentsIndex.vue';
import PaymentsEdit from './views/PaymentsEdit.vue';
import TransactionsIndex from './views/TransactionsIndex.vue';
import TransactionsEdit from './views/TransactionsEdit.vue';
import TerminalsIndex from './views/TerminalsIndex.vue';
import TerminalsEdit from './views/TerminalsEdit.vue';
import GiftCardsIndex from './views/GiftCardsIndex.vue';
import GiftCardsEdit from './views/GiftCardsEdit.vue';
import PayLinksIndex from './views/PayLinksIndex.vue';
import PayLinksEdit from './views/PayLinksEdit.vue';
import PayPlansIndex from './views/PayPlansIndex.vue';
import PayPlansEdit from './views/PayPlansEdit.vue';
import PayMethodsIndex from './views/PayMethodsIndex.vue';
import PayMethodsEdit from './views/PayMethodsEdit.vue';
import RecurringPaymentsIndex from './views/RecurringPaymentsIndex.vue';
import RecurringPaymentsEdit from './views/RecurringPaymentsEdit.vue';
import ClientRecurringsIndex from './views/ClientRecurringsIndex.vue';
import ClientRecurringsEdit from './views/ClientRecurringsEdit.vue';
import ClientPlansIndex from './views/ClientPlansIndex.vue';
import ClientPlansEdit from './views/ClientPlansEdit.vue';
import FlowsIndex from './views/FlowsIndex.vue';
import FlowsEdit from './views/FlowsEdit.vue';
import SampleTemplatesIndex from './views/SampleTemplatesIndex.vue';
import AutoTemplatesIndex from './views/AutoTemplatesIndex.vue';
import AutoMessagesIndex from './views/AutoMessagesIndex.vue';
import AutoMessagesEdit from './views/AutoMessagesEdit.vue';
import MessageTemplatesIndex from './views/MessageTemplatesIndex.vue';
import LocationsIndex from './views/LocationsIndex.vue';
import LocationsEdit from './views/LocationsEdit.vue';
import UploadsIndex from './views/UploadsIndex.vue';
import UploadsEdit from './views/UploadsEdit.vue';
import UploadTemplatesIndex from './views/UploadTemplatesIndex.vue';
import UploadTemplatesEdit from './views/UploadTemplatesEdit.vue';
import FileTemplatesIndex from './views/FileTemplatesIndex.vue';
import AgencyPhonesIndex from './views/AgencyPhonesIndex.vue';
import AgencyPhonesEdit from "./views/AgencyPhonesEdit.vue";
import AgencyEmailsIndex from './views/AgencyEmailsIndex.vue';
import StatusesIndex from './views/StatusesIndex.vue';
import StatusesEdit from './views/StatusesEdit.vue';
import OrganizationsIndex from './views/OrganizationsIndex.vue';
import OrganizationsEdit from './views/OrganizationsEdit.vue';
import JudgesIndex from './views/JudgesIndex.vue';
import JudgesEdit from './views/JudgesEdit.vue';
import RolesIndex from "./views/RolesIndex.vue";
import RolesEdit from "./views/RolesEdit.vue";
import AgencyEmailsEdit from "./views/AgencyEmailsEdit.vue";
import SettingsIndex from './views/SettingsIndex.vue';
import ReportsIndex from './views/ReportsIndex.vue';
import ReportsEdit from './views/ReportsEdit.vue';
import ReportsDownload from './views/ReportsDownload.vue';
import MessagesIndex from './views/MessagesIndex.vue';
import MessagesEdit from './views/MessagesEdit.vue';
import ChatsIndex from './views/ChatsIndex.vue';
import ChatsEdit from './views/ChatsEdit.vue';
import ContactsIndex from './views/ContactsIndex.vue';
import ContactsEdit from './views/ContactsEdit.vue';
import FormsIndex from './views/FormsIndex.vue';
import FormsEdit from './views/FormsEdit.vue';
import SubmissionsViewer from './views/SubmissionsViewer.vue';
import SubmissionsIndex from './views/SubmissionsIndex.vue';
import SubmissionsEdit from './views/SubmissionsEdit.vue';
import QRCodesIndex from './views/QRCodesIndex.vue';
import AddressesIndex from './views/AddressesIndex.vue';
import AddressesEdit from './views/AddressesEdit.vue';
import UnreachablesIndex from './views/UnreachablesIndex.vue';
import WaitlistsIndex from './views/WaitlistsIndex.vue';
import SchedulesIndex from './views/SchedulesIndex.vue';
import NotFound from './views/NotFound.vue';
import Logout from './views/Logout.vue';
import NoAgencies from './views/NoAgencies.vue';
import AgenciesIndex from './views/AgenciesIndex.vue';
import AgentsIndex from './views/AgentsIndex.vue';
import AgentsEdit from './views/AgentsEdit.vue';
import Preview from './views/Preview.vue';
import MergeTags from './views/MergeTags.vue';
import SetupIndex from './views/SetupIndex.vue';
import FilesIndex from './views/FilesIndex.vue';
import FilesEdit from './views/FilesEdit.vue';
import SentFilesIndex from './views/SentFilesIndex.vue';
import SentFilesEdit from './views/SentFilesEdit.vue';
import GeolocationsIndex from './views/GeolocationsIndex.vue';
import GeolocationsEdit from './views/GeolocationsEdit.vue';
import IPAddressesEdit from './views/IPAddressesEdit.vue';
import OpensIndex from './views/OpensIndex.vue';
import OpensEdit from './views/OpensEdit.vue';
import LookupsIndex from './views/LookupsIndex.vue';
import LookupsEdit from './views/LookupsEdit.vue';
import IdentitiesIndex from './views/IdentitiesIndex.vue';
import IdentitiesEdit from './views/IdentitiesEdit.vue';
import PortalsIndex from './views/PortalsIndex.vue';
import PortalsEdit from './views/PortalsEdit.vue';
import PostsIndex from './views/PostsIndex.vue';
import PostsEdit from './views/PostsEdit.vue';
import AvailabilitiesIndex from './views/AvailabilitiesIndex.vue';
import AvailabilitiesEdit from './views/AvailabilitiesEdit.vue';
import ChecklistIndex from './views/ChecklistIndex.vue';
import CommentsIndex from './views/CommentsIndex.vue';
import CommentsEdit from './views/CommentsEdit.vue';
import ReferralsIndex from './views/ReferralsIndex.vue';

import ViewsIndex from './views/ViewsIndex.vue';
import ViewsEdit from './views/ViewsEdit.vue';

Vue.use(VueRouter);

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
      title: 'Login to eCourtDate'
    }
  },
  {
    path: '/sign_in',
    name: 'sign_in',
    component: Login,
    meta: {
      requiresAuth: false,
      title: 'Login to eCourtDate'
    }
  },
  {
    path: '/oauth/:id',
    name: 'oauth',
    component: Login,
    meta: {
      requiresAuth: false,
      title: 'Login to eCourtDate'
    }
  },
  {
    path: '/',
    redirect: { name: 'login' }
  },
  {
    path: '/dashboard',
    name: 'dashboard.index',
    component: DashboardIndex,
    meta: {
      requiresAuth: true,
      title: 'Dashboard'
    }
  },
  {
    path: '/phones',
    name: 'phones.index',
    component: PhonesIndex,
    meta: {
        requiresAuth: true,
        title: 'Phone',
        base_url: 'phones'
    }
  },
  {
    path: '/dashboards',
    name: 'dashboards.index',
    component: DashboardsIndex,
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
      base_url: 'dashboards'
    }
  },
  {
    path: '/dashboards/:id',
    name: 'dashboards.edit',
    component: DashboardsEdit,
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
      base_url: 'dashboards'
    }
  },
  {
    path: '/dashboard_templates',
    name: 'dashboard_templates.index',
    component: DashboardTemplatesIndex,
    meta: {
      requiresAuth: true,
      title: 'Dashboard Template',
      base_url: 'dashboards'
    }
  },
  {
    path: '/datapoints',
    name: 'datapoints.index',
    component: DatapointsIndex,
    meta: {
      requiresAuth: true,
      title: 'Datapoint',
      base_url: 'datapoints'
    }
  },
  {
    path: '/datapoints/:id',
    name: 'datapoints.edit',
    component: DatapointsEdit,
    meta: {
      requiresAuth: true,
      title: 'Datapoint',
      base_url: 'datapoints'
    }
  },
  {
    path: '/clients',
    name: 'clients.index',
    component: ClientsIndex,
    meta: {
      requiresAuth: true,
      title: 'Client',
      base_url: 'clients'
    }
  },
  {
    path: '/clients/:id',
    name: 'clients.edit',
    component: ClientsEdit,
    meta: {
      requiresAuth: true,
      title: 'Client',
      base_url: 'clients'
    }
  },
  {
    path: '/stats',
    name: 'stats.index',
    component: StatsIndex,
    meta: {
      requiresAuth: true,
      title: 'Stats'
    }
  },
  {
    path: '/bulk_actions',
    name: 'bulk_actions.index',
    component: BulkActionsIndex,
    meta: {
      requiresAuth: true,
      title: 'Bulk Action',
      base_url: 'bulk_actions'
    }
  },
  {
    path: '/bulk_actions/:id',
    name: 'bulk_actions.edit',
    component: BulkActionsEdit,
    meta: {
      requiresAuth: true,
      title: 'Bulk Action',
      base_url: 'bulk_actions'
    }
  },
  {
    path: '/bulk_templates',
    name: 'bulk_templates.index',
    component: BulkTemplatesIndex,
    meta: {
      requiresAuth: true,
      title: 'Bulk Action Templates',
      base_url: 'bulk_templates'
    }
  },
  {
    path: '/events',
    name: 'events.index',
    component: EventsIndex,
    meta: {
      requiresAuth: true,
      title: 'Event',
      base_url: 'events'
    }
  },
  {
    path: '/events/:id',
    name: 'events.edit',
    component: EventsEdit,
    meta: {
      requiresAuth: true,
      title: 'Event',
      base_url: 'events'
    }
  },
  {
    path: '/calendars',
    name: 'calendars.index',
    component: CalendarsIndex,
    meta: {
      requiresAuth: true,
      title: 'Calendars'
    }
  },
  {
    path: '/checkins',
    name: 'checkins.index',
    component: CheckinsIndex,
    meta: {
        requiresAuth: true,
        title: 'Check-in',
        base_url: 'checkins'
    }
  },
  {
    path: '/checkins/:id',
    name: 'checkins.edit',
    component: CheckinsEdit,
    meta: {
      requiresAuth: true,
      title: 'Check-in',
      base_url: 'checkins'
    }
  },
  {
    path: '/cases',
    name: 'cases.index',
    component: CasesIndex,
    meta: {
      requiresAuth: true,
      title: 'Case',
      base_url: 'cases'
    }
  },
  {
    path: '/cases/:id',
    name: 'cases.edit',
    component: CasesEdit,
    meta: {
      requiresAuth: true,
      title: 'Case',
      base_url: 'cases'
    }
  },
  {
    path: '/bonds',
    name: 'bonds.index',
    component: BondsIndex,
    meta: {
      requiresAuth: true,
      title: 'Bond',
      base_url: 'bonds'
    }
  },
  {
    path: '/bonds/:id',
    name: 'bonds.edit',
    component: BondsEdit,
    meta: {
      requiresAuth: true,
      title: 'Bond',
      base_url: 'bonds'
    }
  },
  {
    path: '/warrants',
    name: 'warrants.index',
    component: WarrantsIndex,
    meta: {
      requiresAuth: true,
      title: 'Warrant',
      base_url: 'warrants'
    }
  },
  {
    path: '/warrants/:id',
    name: 'warrants.edit',
    component: WarrantsEdit,
    meta: {
      requiresAuth: true,
      title: 'Warrant',
      base_url: 'warrants'
    }
  },
  {
    path: '/payments',
    name: 'payments.index',
    component: PaymentsIndex,
    meta: {
      requiresAuth: true,
      title: 'Payment',
      base_url: 'payments'
    }
  },
  {
    path: '/payments/:id',
    name: 'payments.edit',
    component: PaymentsEdit,
    meta: {
      requiresAuth: true,
      title: 'Payment',
      base_url: 'payments'
    }
  },
  {
    path: '/transactions',
    name: 'transactions.index',
    component: TransactionsIndex,
    meta: {
      requiresAuth: true,
      title: 'Transaction',
      base_url: 'transactions'
    }
  },
  {
    path: '/transactions/:id',
    name: 'transactions.edit',
    component: TransactionsEdit,
    meta: {
      requiresAuth: true,
      title: 'Transaction',
      base_url: 'transactions'
    }
  },
  {
    path: '/terminals',
    name: 'terminals.index',
    component: TerminalsIndex,
    meta: {
      requiresAuth: true,
      title: 'Terminal',
      base_url: 'terminals'
    }
  },
  {
    path: '/terminals/:id',
    name: 'terminals.edit',
    component: TerminalsEdit,
    meta: {
      requiresAuth: true,
      title: 'Terminal',
      base_url: 'terminals'
    }
  },
  {
    path: '/gift_cards',
    name: 'gift_cards.index',
    component: GiftCardsIndex,
    meta: {
      requiresAuth: true,
      title: 'Gift Card',
      base_url: 'gift_cards'
    }
  },
  {
    path: '/gift_cards/:id',
    name: 'gift_cards.edit',
    component: GiftCardsEdit,
    meta: {
        requiresAuth: true,
        title: 'Gift Card',
        base_url: 'gift_cards'
    }
  },
  {
    path: '/pay_links',
    name: 'pay_links.index',
    component: PayLinksIndex,
    meta: {
      requiresAuth: true,
      title: 'Pay Link',
      base_url: 'pay_links'
    }
  },
  {
    path: '/pay_links/:id',
    name: 'pay_links.edit',
    component: PayLinksEdit,
    meta: {
      requiresAuth: true,
      title: 'Pay Link',
      base_url: 'pay_links'
    }
  },
  {
    path: '/pay_plans',
    name: 'pay_plans.index',
    component: PayPlansIndex,
    meta: {
      requiresAuth: true,
      title: 'Pay Plan',
      base_url: 'pay_plans'
    }
  },
  {
    path: '/pay_plans/:id',
    name: 'pay_plans.edit',
    component: PayPlansEdit,
    meta: {
      requiresAuth: true,
      title: 'Pay Plan',
      base_url: 'pay_plans'
    }
  },
  {
    path: '/recurring_payments',
    name: 'recurring_payments.index',
    component: RecurringPaymentsIndex,
    meta: {
      requiresAuth: true,
      title: 'Recurring Template',
      base_url: 'recurring_payments'
    }
  },
  {
    path: '/recurring_payments/:id',
    name: 'recurring_payments.edit',
    component: RecurringPaymentsEdit,
    meta: {
      requiresAuth: true,
      title: 'Recurring Template',
      base_url: 'recurring_payments'
    }
  },
  {
    path: '/client_recurrings',
    name: 'client_recurrings.index',
    component: ClientRecurringsIndex,
    meta: {
      requiresAuth: true,
      title: 'Client Recurring Payment',
      base_url: 'client_recurrings'
    }
  },
  {
    path: '/client_recurrings/:id',
    name: 'client_recurrings.edit',
    component: ClientRecurringsEdit,
    meta: {
      requiresAuth: true,
      title: 'Client Recurring Payment',
      base_url: 'client_recurrings'
    }
  },
  {
    path: '/client_plans',
    name: 'client_plans.index',
    component: ClientPlansIndex,
    meta: {
      requiresAuth: true,
      title: 'Client Plan',
      base_url: 'client_plans'
    }
  },
  {
    path: '/client_plans/:id',
    name: 'client_plans.edit',
    component: ClientPlansEdit,
    meta: {
      requiresAuth: true,
      title: 'Client Plan',
      base_url: 'client_plans'
    }
  },
  {
    path: '/pay_methods',
    name: 'pay_methods.index',
    component: PayMethodsIndex,
    meta: {
      requiresAuth: true,
      title: 'Pay Method',
      base_url: 'pay_methods'
    }
  },
  {
    path: '/pay_methods/:id',
    name: 'pay_methods.edit',
    component: PayMethodsEdit,
    meta: {
      requiresAuth: true,
      title: 'Pay Method',
      base_url: 'pay_methods'
    }
  },
  {
    path: '/users',
    name: 'users.index',
    component: UsersIndex,
    meta: {
      requiresAuth: true,
      title: 'User',
      base_url: 'users'
    }
  },
  {
    path: '/users/:id',
    name: 'users.edit',
    component: UsersEdit,
    meta: {
      requiresAuth: true,
      title: 'User',
      base_url: 'users'
    }
  },
  {
    path: '/attorneys',
    name: 'attorneys.index',
    component: AttorneysIndex,
    meta: {
      requiresAuth: true,
      title: 'Attorney',
      base_url: 'attorneys'
    }
  },
  {
    path: '/attorneys/:id',
    name: 'attorneys.edit',
    component: AttorneysEdit,
    meta: {
      requiresAuth: true,
      title: 'Attorney',
      base_url: 'attorneys'
    }
  },
  {
    path: '/agents',
    name: 'agents.index',
    component: AgentsIndex,
    meta: {
      requiresAuth: true,
      title: 'Agents'
    }
  },
  {
    path: '/agents/:id',
    name: 'agents.edit',
    component: AgentsEdit,
    meta: {
      requiresAuth: true,
      title: 'Edit Agent'
    }
  },
  {
    path: '/locations',
    name: 'locations.index',
    component: LocationsIndex,
    meta: {
      requiresAuth: true,
      title: 'Location',
      base_url: 'locations'
    }
  },
  {
    path: '/locations/:id',
    name: 'locations.edit',
    component: LocationsEdit,
    meta: {
      requiresAuth: true,
      title: 'Location',
      base_url: 'locations'
    }
  },
  {
    path: '/uploads',
    name: 'uploads.index',
    component: UploadsIndex,
    meta: {
      requiresAuth: true,
      title: 'Upload',
      base_url: 'uploads'
    }
  },
  {
    path: '/uploads/:id',
    name: 'uploads.edit',
    component: UploadsEdit,
    meta: {
      requiresAuth: true,
      title: 'Upload',
      base_url: 'uploads'
    }
  },
  {
    path: '/upload_templates',
    name: 'upload_templates.index',
    component: UploadTemplatesIndex,
    meta: {
      requiresAuth: true,
      title: 'Upload Template',
      base_url: 'upload_templates'
    }
  },
  {
    path: '/upload_templates/:id',
    name: 'upload_templates.edit',
    component: UploadTemplatesEdit,
    meta: {
      requiresAuth: true,
      title: 'Upload Template',
      base_url: 'upload_templates'
    }
  },
  {
    path: '/file_templates',
    name: 'file_templates.index',
    component: FileTemplatesIndex,
    meta: {
      requiresAuth: true,
      title: 'File Template',
      base_url: 'upload_templates'
    }
  },
  {
    path: '/flows',
    name: 'flows.index',
    component: FlowsIndex,
    meta: {
      requiresAuth: true,
      title: 'Flow',
      base_url: 'flows'
    }
  },
  {
    path: '/flows/:id',
    name: 'flows.edit',
    component: FlowsEdit,
    meta: {
      requiresAuth: true,
      title: 'Flow',
      base_url: 'flows'
    }
  },
  {
    path: '/sample_templates',
    name: 'sample_templates.index',
    component: SampleTemplatesIndex,
    meta: {
      requiresAuth: true,
      title: 'sample Template',
      base_url: 'sample_templates'
    }
  },
  {
    path: '/auto_messages',
    name: 'auto_messages.index',
    component: AutoMessagesIndex,
    meta: {
      requiresAuth: true,
      title: 'Auto Message',
      base_url: 'auto_messages'
    }
  },
  {
    path: '/auto_messages/:id',
    name: 'auto_messages.edit',
    component: AutoMessagesEdit,
    meta: {
      requiresAuth: true,
      title: 'Auto Message',
      base_url: 'auto_messages'
    }
  },
  {
    path: '/auto_templates',
    name: 'auto_templates.index',
    component: AutoTemplatesIndex,
    meta: {
      requiresAuth: true,
      title: 'Auto Message Template',
      base_url: 'auto_messages'
    }
  },
  {
    path: '/message_templates',
    name: 'message_templates.index',
    component: MessageTemplatesIndex,
    meta: {
      requiresAuth: true,
      title: 'Message Template',
      base_url: 'message_templates'
    }
  },
  {
    path: '/roles',
    name: 'roles.index',
    component: RolesIndex,
    meta: {
        requiresAuth: true,
        title: 'Role',
        base_url: 'roles'
    }
  },
  {
    path: '/roles/:id',
    name: 'roles.edit',
    component: RolesEdit,
    meta: {
        requiresAuth: true,
        title: 'Role',
        base_url: 'roles'
    }
  },
  {
    path: '/statuses',
    name: 'statuses.index',
    component: StatusesIndex,
    meta: {
        requiresAuth: true,
        title: 'Status',
        base_url: 'statuses'
    }
  },
  {
    path: '/statuses/:id',
    name: 'statuses.edit',
    component: StatusesEdit,
    meta: {
        requiresAuth: true,
        title: 'Status',
        base_url: 'statuses'
    }
  },
  {
    path: '/organizations',
    name: 'organizations.index',
    component: OrganizationsIndex,
    meta: {
        requiresAuth: true,
        title: 'Organization',
        base_url: 'organizations'
    }
  },
  {
    path: '/organizations/:id',
    name: 'organizations.edit',
    component: OrganizationsEdit,
    meta: {
        requiresAuth: true,
        title: 'Organization',
        base_url: 'organizations'
    }
  },
  {
    path: '/judges',
    name: 'judges.index',
    component: JudgesIndex,
    meta: {
        requiresAuth: true,
        title: 'Judge',
        base_url: 'judges'
    }
  },
  {
    path: '/judges/:id',
    name: 'judges.edit',
    component: JudgesEdit,
    meta: {
        requiresAuth: true,
        title: 'Judge',
        base_url: 'judges'
    }
  },
  {
    path: '/agency_phones',
    name: 'agency_phones.index',
    component: AgencyPhonesIndex,
    meta: {
        requiresAuth: true,
        title: 'Agency Phone',
        base_url: 'agency_phones'
    }
  },
  {
    path: '/agency_phones/:id',
    name: 'agency_phones.edit',
    component: AgencyPhonesEdit,
    meta: {
        requiresAuth: true,
        title: 'Agency Phone',
        base_url: 'agency_phones'
    }
  },
  {
    path: '/agency_emails',
    name: 'agency_emails.index',
    component: AgencyEmailsIndex,
    meta: {
      requiresAuth: true,
      title: 'Agency Email',
      base_url: 'agency_emails'
    }
  },
  {
    path: '/agency_emails/:id',
    name: 'agency_emails.edit',
    component: AgencyEmailsEdit,
    meta: {
      requiresAuth: true,
      title: 'Agency Email',
      base_url: 'agency_emails'
    }
  },
  {
    path: '/settings',
    name: 'settings.index',
    component: SettingsIndex,
    meta: {
      requiresAuth: true,
      title: 'Settings',
      base_url: 'settings'
    }
  },
  {
    path: '/reports',
    name: 'reports.index',
    component: ReportsIndex,
    meta: {
      requiresAuth: true,
      title: 'Report',
      base_url: 'reports'
    }
  },
  {
    path: '/reports/:id',
    name: 'reports.edit',
    component: ReportsEdit,
    meta: {
      requiresAuth: true,
      title: 'Report'
    }
  },
  {
    path: '/reports/download/:id',
    name: 'reports.download',
    component: ReportsDownload,
    meta: {
      requiresAuth: true,
      title: 'Report Download'
    }
  },
  {
    path: '/messages',
    name: 'messages.index',
    component: MessagesIndex,
    meta: {
      requiresAuth: true,
      title: 'Message',
      base_url: 'messages'
    }
  },
  {
    path: '/messages/:id',
    name: 'messages.edit',
    component: MessagesEdit,
    meta: {
      requiresAuth: true,
      title: 'Message',
      base_url: 'messages'
    }
  },
  {
    path: '/chats',
    name: 'chats.index',
    component: ChatsIndex,
    meta: {
      requiresAuth: true,
      title: 'Chat',
      base_url: 'chats'
    }
  },
  {
    path: '/chats/:id',
    name: 'chats.edit',
    component: ChatsEdit,
    meta: {
      requiresAuth: true,
      title: 'Chat',
      base_url: 'chats'
    }
  },
  {
    path: '/contacts',
    name: 'contacts.index',
    component: ContactsIndex,
    meta: {
      requiresAuth: true,
      title: 'Contact',
      base_url: 'contacts'
    }
  },
  {
    path: '/contacts/:id',
    name: 'contacts.edit',
    component: ContactsEdit,
    meta: {
      requiresAuth: true,
      title: 'Contact',
      base_url: 'contacts'
    }
  },
  {
    path: '/forms',
    name: 'forms.index',
    component: FormsIndex,
    meta: {
      requiresAuth: true,
      title: 'Form',
      base_url: 'forms'
    }
  },
  {
    path: '/forms/:id',
    name: 'forms.edit',
    component: FormsEdit,
    meta: {
      requiresAuth: true,
      title: 'Form',
      base_url: 'forms'
    }
  },
  {
    path: '/forms/:id/submissions',
    name: 'forms.submissions',
    component: SubmissionsViewer,
    meta: {
      requiresAuth: true,
      title: 'Submission',
      base_url: 'submissions'
    }
  },
  {
    path: '/submissions',
    name: 'submissions.index',
    component: SubmissionsIndex,
    meta: {
      requiresAuth: true,
      title: 'Submission',
      base_url: 'submissions'
    }
  },
  {
    path: '/submissions/:id',
    name: 'submissions.edit',
    component: SubmissionsEdit,
    meta: {
      requiresAuth: true,
      title: 'Submission',
      base_url: 'submissions'
    }
  },
  {
    path: '/qrs',
    name: 'qrs.index',
    component: QRCodesIndex,
    meta: {
      requiresAuth: true,
      title: 'QR Code',
      base_url: 'qrs'
    }
  },
  {
    path: '/addresses',
    name: 'addresses.index',
    component: AddressesIndex,
    meta: {
      requiresAuth: true,
      title: 'Address',
      base_url: 'addresses'
    }
  },
  {
    path: '/addresses/:id',
    name: 'addresses.edit',
    component: AddressesEdit,
    meta: {
      requiresAuth: true,
      title: 'Address',
      base_url: 'addresses'
    }
  },
  {
    path: '/unreachables',
    name: 'unreachables.index',
    component: UnreachablesIndex,
    meta: {
      requiresAuth: true,
      title: 'Unreachables'
    }
  },
  {
    path: '/waitlists',
    name: 'waitlists.index',
    component: WaitlistsIndex,
    meta: {
      requiresAuth: true,
      title: 'Waitlist'
    }
  },
  {
    path: '/schedules',
    name: 'schedules.index',
    component: SchedulesIndex,
    meta: {
      requiresAuth: true,
      title: 'Schedule',
      base_url: 'schedules'
    }
  },
  {
    path: '/preview',
    name: 'preview.index',
    component: Preview,
    meta: {
      requiresAuth: true,
      title: 'Preview',
      base_url: 'preview'
    }
  },
  {
    path: '/merge_tags',
    name: 'merge_tags.index',
    component: MergeTags,
    meta: {
      requiresAuth: true,
      title: 'Merge Tags',
      base_url: 'merge_tags'
    }
  },
  {
    path: '/checklist',
    name: 'checklist.index',
    component: ChecklistIndex,
    meta: {
      requiresAuth: true,
      title: 'Agency Checklist'
    }
  },
  {
    path: '/setup',
    name: 'setup.index',
    component: SetupIndex,
    meta: {
      requiresAuth: true,
      title: 'Agency Setup'
    }
  },
  {
    path: '/files',
    name: 'files.index',
    component: FilesIndex,
    meta: {
      requiresAuth: true,
      title: 'File',
      base_url: 'files'
    }
  },
  {
    path: '/files/:id',
    name: 'files.edit',
    component: FilesEdit,
    meta: {
      requiresAuth: true,
      title: 'File',
      base_url: 'files'
    }
  },
  {
    path: '/sent_files',
    name: 'sent_files.index',
    component: SentFilesIndex,
    meta: {
        requiresAuth: true,
        title: 'Sent File',
        base_url: 'sent_files'
    }
  },
  {
    path: '/sent_files/:id',
    name: 'sent_files.edit',
    component: SentFilesEdit,
    meta: {
      requiresAuth: true,
      title: 'Sent File',
      base_url: 'sent_files'
    }
  },
  {
    path: '/geolocations',
    name: 'geolocations.index',
    component: GeolocationsIndex,
    meta: {
      requiresAuth: true,
      title: 'Geolocation',
      base_url: 'geolocations'
    }
  },
  {
    path: '/geolocations/:id',
    name: 'geolocations.edit',
    component: GeolocationsEdit,
    meta: {
      requiresAuth: true,
      title: 'Geolocation',
      base_url: 'geolocations'
    }
  },
  {
    path: '/ips/:id',
    name: 'ips.edit',
    component: IPAddressesEdit,
    meta: {
      requiresAuth: true,
      title: 'Edit IP Address'
    }
  },
  {
    path: '/opens',
    name: 'opens.index',
    component: OpensIndex,
    meta: {
      requiresAuth: true,
      title: 'Open',
      base_url: 'opens'
    }
  },
  {
    path: '/opens/:id',
    name: 'opens.edit',
    component: OpensEdit,
    meta: {
      requiresAuth: true,
      title: 'Open',
      base_url: 'opens'
    }
  },
  {
    path: '/lookups',
    name: 'lookups.index',
    component: LookupsIndex,
    meta: {
        requiresAuth: true,
        title: 'Lookup',
        base_url: 'lookups'
    }
  },
  {
    path: '/lookups/:id',
    name: 'lookups.edit',
    component: LookupsEdit,
    meta: {
        requiresAuth: true,
        title: 'Lookup',
        base_url: 'lookups'
    }
  },
  {
    path: '/identities',
    name: 'identities.index',
    component: IdentitiesIndex,
    meta: {
      requiresAuth: true,
      title: 'Identities',
      base_url: 'identities'
    }
  },
  {
    path: '/identities/:id',
    name: 'identities.edit',
    component: IdentitiesEdit,
    meta: {
      requiresAuth: true,
      title: 'Identity',
      base_url: 'identities'
    }
  },
  {
    path: '/portals',
    name: 'portals.index',
    component: PortalsIndex,
    meta: {
      requiresAuth: true,
      title: 'Portal',
      base_url: 'portals'
    }
  },
  {
    path: '/portals/:id',
    name: 'portals.edit',
    component: PortalsEdit,
    meta: {
      requiresAuth: true,
      title: 'Portal',
      base_url: 'portals'
    }
  },
  {
    path: '/posts',
    name: 'posts.index',
    component: PostsIndex,
    meta: {
      requiresAuth: true,
      title: 'Post',
      base_url: 'posts'
    }
  },
  {
    path: '/posts/:id',
    name: 'posts.edit',
    component: PostsEdit,
    meta: {
      requiresAuth: true,
      title: 'Post',
      base_url: 'posts'
    }
  },
  {
    path: '/redirects',
    name: 'redirects.index',
    component: ViewsIndex,
    meta: {
      requiresAuth: true,
      title: 'Redirect',
      base_url: 'redirects',
      tabs: 'portals'
    }
  },
  {
    path: '/redirects/:id',
    name: 'redirects.edit',
    component: ViewsEdit,
    meta: {
      requiresAuth: true,
      title: 'Redirect',
      base_url: 'redirects'
    }
  },
  {
    path: '/availabilities',
    name: 'availabilities.index',
    component: AvailabilitiesIndex,
    meta: {
      requiresAuth: true,
      title: 'Availabilities'
    }
  },
  {
    path: '/availabilities/:id',
    name: 'availabilities.edit',
    component: AvailabilitiesEdit,
    meta: {
      requiresAuth: true,
      title: 'Edit Availability'
    }
  },
  {
    path: '/comments',
    name: 'comments.index',
    component: CommentsIndex,
    meta: {
      requiresAuth: true,
      title: 'Comments'
    }
  },
  {
    path: '/comments/:id',
    name: 'comments.edit',
    component: CommentsEdit,
    meta: {
      requiresAuth: true,
      title: 'Edit Comment'
    }
  },
  {
    path: '/agencies',
    name: 'agencies.index',
    component: AgenciesIndex,
    meta: {
      requiresAuth: true,
      title: 'Agencies'
    }
  },
  {
    path: '/no-agencies',
    name: 'no-agencies',
    component: NoAgencies,
    meta: {
      requiresAuth: true,
      title: 'No Agencies'
    }
  },
  {
    path: '/referrals',
    name: 'referrals.index',
    component: ReferralsIndex,
    meta: {
      requiresAuth: true,
      title: 'Referrals',
      base_url: 'referrals'
    }
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      requiresAuth: false,
      title: 'Page Not Found'
    }
  },
  {
    path: '*',
    redirect: '/login',
    meta: { requiresAuth: false }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      requiresAuth: false,
      title: 'Logout from eCourtDate'
    }
  },
  { path: '/onboarding', redirect: { name: 'checklist.index' } }
]

export const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
