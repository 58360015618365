<template>

        <div class="btn-group d-print-none" style="z-index:9" data-html2canvas-ignore>

          <div class="dropdown" id="searchAgencies">

              <template v-if="current_agency.name">
              <button id="searchAgenciesButton" class="btn btn-secondary" type="button" data-toggle="dropdown" data-tooltip="Agency Switcher" aria-expanded="false">
                <i class="fa fa-landmark-dome"></i> {{ current_agency.name }}
              </button>
              </template>

                <div
                  class="dropdown-menu p-2"
                  aria-labelledby="searchAgenciesButton"
                >
                  <h6 class="dropdown-header">
                    <template v-if="processing">Searching agencies</template>
                    <template v-if="!processing && agencies && agencies.length > 0">Click an agency to switch</template>
                    <template v-if="!processing && agencies && !agencies.length">Search agencies</template>
                  </h6>

                  <form @submit.prevent="getAgencies">
                    <div class="input-group">
                      <label
                        class="sr-only"
                        for="search_agencies"
                        autofocus
                      >Search</label>
                      <input
                        id="search_agencies"
                        v-model="search_agencies"
                        type="text"
                        class="form-control"
                        placeholder="Search agencies..."
                      >
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-primary"
                          type="submit"
                          :disabled="processing"
                        >
                        <template v-if="!processing">
                          <i class="fa fa-search" /> <small class="sr-only">Search</small>
                        </template>
                        <template v-if="processing">
                          <loader :isLoading="processing" />
                        </template>
                        </button>
                      </div>
                    </div>

                    <template v-if="isGlobalDomain">
                      <div class="form-group mt-1">
                        <fieldset>
                          <legend class="m-0">
                            Region
                          </legend>

                          <template v-for="(region, index) in regions">
                            <label
                              :key="'regions_'+index"
                              class="m-0 mr-2"
                              :for="region"
                            >
                              <input
                                :id="region"
                                v-model="agencies_region"
                                type="radio"
                                :name="region"
                                :value="region"
                              > {{ region }}
                            </label>
                          </template>
                        </fieldset>
                      </div>
                    </template>

                    <div class="form-group mt-1">
                      <select class="form-control" v-model="agencies_sort" @change="getAgencies" :disabled="processing">
                        <template v-for="(agency_sort, agency_sort_index) in agency_sorts">
                          <option :key="'agency_sort_'+agency_sort_index" :value="agency_sort.value">Sort by {{ agency_sort.title }}</option>
                        </template>
                      </select>
                    </div>

                  </form>

                  <template v-if="!agencies || !agencies.length">

                    <template v-if="lastAgency && lastAgency.uuid">

                      <a class="dropdown-item" @click="switchAgency(lastAgency)"><small class="text-muted">Last Agency</small><br>{{ lastAgency.name }}<br><small>{{ lastAgency.reference }} {{ lastAgency.region }}</small></a>

                    </template>

                  </template>

                  <template v-if="agencies && agencies.length > 0">
                    <template v-for="agency in agencies">
                      <a
                        :key="agency.uuid"
                        class="dropdown-item"
                        :class="{ active: isActiveAgency(agency) }"
                        @click="switchAgency(agency)"
                      >{{ agency.name }}<br><small>{{ agency.reference }} {{ agency.region }}</small>
                      <template v-if="agency.status == 0">
                        <span class="badge badge-pill bg-danger">INACTIVE</span>
                      </template>
                      <template v-if="agency.status == 2">
                        <span class="badge badge-pill bg-success">NEW</span>
                      </template>
                    </a>
                    </template>

                  </template>
                </div>

              </div><!--AgencyDropdown-->

          <div class="dropdown">
            <a class="btn btn-secondary" href="#" role="button" data-toggle="dropdown" aria-expanded="false" data-tooltip="Region Switcher">
              <i class="fa fa-globe"></i>
            </a>

            <div class="dropdown-menu">
              <h6 class="dropdown-header">Click to go to a region</h6>
              <template v-for="(region, region_index) in regions">
                <a
                    :key="'region_'+region_index"
                    class="dropdown-item"
                    :href="'https://'+region+'.ecourtdate.com'"
                    target="_blank"
                    :class="{ active: $root.current_region == region }"
                >
                  <template v-if="region == 'app'">
                    main
                  </template>
                  {{ region }}</a>
              </template>
            </div>
          </div>

        </div><!--Group-->

</template>
<script>
export default {

    data () {
        return {
            agencies: [],
            processing: false,
            search_agencies: '',
            agencies_region: '',
            agencies_sort: 'name_asc',
            agency_sorts: [
              {title: 'Name a-z', value: 'name_asc'},
              {title: 'Name z-a', value: 'name_desc'},
              {title: 'Reference a-z', value: 'reference_asc'},
              {title: 'Reference z-a', value: 'reference_desc'},
              {title: 'Status active', value: 'status_desc'},
              {title: 'Status inactive', value: 'status_asc'}
            ],
            current_agency: {},
            regions: ['app', 'us-east', 'us-west', 'us-south', 'staging']
        }
    },

    computed: {

        isGlobalDomain() {

            var localURL = process.env.VUE_APP_API_URL;

            if(localURL != null) {
                return false;
            }

            const host = window.location.host;
            const parts = host.split('.');

            if (parts[0] === 'app') {
              return true;
            }

            return false;
        },

        lastAgency() {

          var agency = localStorage.getItem('last_agency');

          if(!agency) {
            return false;
          }

          return JSON.parse(agency);

        }

    },

  created() {

      if(localStorage.getItem('settings')) {
        var settings = JSON.parse(localStorage.getItem('settings'));

        if(settings['name']) {
          this.current_agency = settings;
        }
      }

      this.getAgencies();
  },

    methods: {

        getAgencies() {
            this.processing = true;

            this.agencies = [];

            var sort = 'name';
            var direction = 'asc';

            if(this.agencies_sort == 'name_asc') {
              sort = 'name';
              direction = 'asc';
            }

            if(this.agencies_sort == 'name_desc') {
              sort = 'name';
              direction = 'desc';
            }

            if(this.agencies_sort == 'reference_asc') {
              sort = 'reference';
              direction = 'asc';
            }

            if(this.agencies_sort == 'reference_desc') {
              sort = 'reference';
              direction = 'desc';
            }

            if(this.agencies_sort == 'status_asc') {
              sort = 'status';
              direction = 'asc';
            }

            if(this.agencies_sort == 'status_desc') {
              sort = 'status';
              direction = 'desc';
            }

            this.$http.get('/agencies?limit=25&slim=y&fields=uuid,name,reference,region,status&search='+this.search_agencies+'&region='+this.agencies_region+'&sort='+sort+'&sortDirection='+direction)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    this.agencies = response.data;
                }
            })
        },

        switchAgency (agency) {

          new this.$noty({text: 'Switching Agency'}).show();

          var settings = localStorage.getItem('settings');

          if(settings) {
            settings = JSON.parse(settings);
          }

          if(settings && settings['name']) {

            var last_agency = {
              "name": settings['name'],
              "region": settings['region'],
              "reference": settings['reference'],
              "uuid": settings['uuid'],
            }

            localStorage.setItem('last_agency', JSON.stringify(last_agency));
          }

          this.current_agency = agency;

          localStorage.setItem('settings', JSON.stringify(agency));

          this.$http.post('switch_agency/'+agency.uuid+'?region='+agency.region)
          .then(response => {
            if (response && response.status === 200) {

              this.clearAgencySession();

              location.reload();
            }
          })
        },

        clearAgencySession() {
          
          var fields = ['fields', 'timezone', 'agency', 'send_mode'];

          fields.forEach(field => {
            localStorage.removeItem(field);
          })

        },

        isActiveAgency(agency) {

            if(agency.uuid === localStorage.getItem('agency')) {
                return true;
            }

            return false;
        },

    },
    
}
</script>