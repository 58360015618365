<template>
  <div>
   
      <div class="card mb-3">
        <div class="card-body">

          <div class="row">

            <div class="col-sm-12 col-md-8">

          <p class="card-title">
            Send a {{ title }}

            <template v-if="!form.uuid && tos && tos.length > 0">
              to {{ tos.length }} Contacts
            </template>

          </p>

          </div><!--Col-->

          <div class="col-sm-12 col-md-4">
            <button type="button" class="btn btn-sm btn-outline-secondary" :class="{'active': show_options === true}" @click="toggleOptions()"><i class="fa fa-grid"></i> Options</button>
          </div><!--Col-->

        </div><!--Row-->


          <template v-if="form.test == 1">
            <div class="alert alert-warning">
              Simulate Mode is enabled for this message. <button
                class="btn btn-light"
                :disabled="processing"
                @click="sendLive"
              >
                Send Live
              </button>
            </div>
          </template>

          <template v-if="!form.uuid && !client">

            <template v-if="tos && tos.length">
              <div class="row mb-3">
                <div class="col-12">
                <template v-for="(to, to_index) in tos">
                  <span :key="'to_'+to_index" class="badge badge-pill badge-primary link-hover mr-1" @click="removeTo(to.to)">{{ to.to }}  <i class="fa fa-times"></i> </span>
                </template>
                </div><!--Col-->
              </div><!--Row-->
            </template>

          <form @submit.prevent="addTo">

              <label for="add_to">To (Client, Phone, or Email)</label><br>
              <div class="input-group mb-3">
                <input
                  v-model="add_to"
                  id="add_to"
                  type="text"
                  class="form-control text-lowercase"
                  :class="setAddToClass()"
                  aria-label="To"
                  aria-describedby="add-to"
                  @input="searchClients()"
                  autofocus
                  required
                >
                <div class="input-group-append">
                  <button
                    id="add-to"
                    class="btn btn-success"
                    type="submit"
                  >
                   <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>

          </form>

            <template v-if="search_results && search_results.length > 0">

              <div class="list-group mb-3">

              <template v-for="(search_result, search_result_index) in search_results">

                <button :key="'search_result_'+search_result_index" class="list-group-item list-group-item-action" @click="addClient(search_result.uuid)">{{ search_result.result }}</button>

              </template>

              </div>

            </template>

          </template>

        <form @submit.prevent="postForm">

          <div class="row" :hidden="!show_options">
            <div class="col-12 col-md-6">
              <div class="form-group vbDatePicker">
                <label for="send_date">Send Date</label>
                <input
                  id="send_date"
                  v-model="form.date"
                  placeholder="mm/dd/yyyy"
                  type="date"
                  class="form-control"
                >
              </div>
            </div><!--Col-->
            <div class="col-12 col-md-6">
              <div class="form-group vbTimePicker">
                <label for="send_time">Send Time</label>
                <input
                  id="send_time"
                  v-model="form.time"
                  placeholder="hh:mm AM"
                  type="time"
                  class="form-control"
                >
              </div>
            </div><!--Col-->
          </div><!--Row-->

          <div class="row mb-3" :hidden="!show_options">

            <div class="col-4">

              <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Auto Messages
              </button>
              <div class="dropdown-menu">
                <h6 class="dropdown-header">
                  Send Auto Message
                </h6>

                <template v-for="auto_option in auto_messages">
                  <template v-if="auto_option.templates && auto_option.templates['en']">
                    <a
                      :key="'auto_option_'+auto_option.uuid"
                      class="dropdown-item"
                      @click="changeAutoMessage(auto_option.uuid)"
                    >{{ auto_option.name }}</a>
                  </template>
                </template>
              </div>

            </div><!--Col-->

            <div class="col-4">

              <merge-tags
                @add-tag-to-message="addTagToMessage"
              />

            </div><!--Col-->

            <div class="col-4">

                    <button
                      type="button"
                      class="btn btn-outline-primary btn-sm"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="filesButton"
                      :disabled="processing"
                    >
                      Attach Files
                    </button>
                    <div class="dropdown-menu" aria-labelledby="filesButton">
                      <h6 class="dropdown-header">
                        <a href="/files" target="_blank">{{ files.length }} Files</a>
                      </h6>

                      <template v-for="(file, file_index) in files">
                          <a
                            :key="'file_'+file_index"
                            class="dropdown-item"
                            @click="attachFile(file.merge_tag)"
                          >{{ file.name }} </a>
                      </template>

                      <template v-if="files && !files.length && !processing">
                        <a class="dropdown-item" href="/files">Upload Files</a>
                      </template>

                    </div>

            </div><!--Col-->

          </div><!--Row-->

          <div class="form-group">
            <label for="subject">Subject</label>
            <input id="subject" type="text" class="form-control" v-model="form.subject">
          </div>

          <div class="form-group">
            <label for="message">Message <template v-if="form.content && form.content.length >= 1"><span class="badge badge-pill" :class="setCharacterClass(form.content)">{{ form.content.length }}</span></template></label>
            <textarea
              id="message"
              ref="formContent"
              v-model="form.content"
              class="form-control"
              rows="3"
              required
            />
          </div>

          <div class="form-group">
            <label for="attachments">Attachments</label>
            <input
              id="attachments"
              ref="files"
              type="file"
              class="form-control"
              multiple="true"
              @change="updateAttachments()"
              :disabled="processing"
            >
          </div>

          <template v-if="uploadProgress > 1 && uploadProgress < 100">
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :style="'width:'+uploadProgress+'%'"
              >
                {{ uploadProgress }}%
              </div>
            </div>
          </template>

          <template v-if="attachments && attachments.length > 0">

            <template v-for="(attachment, attach_index) in attachments">

              <li :key="'attachment_'+attach_index" class="list-group-item">{{ attachment.name }}</li>

            </template>

          </template>

    

          <div class="row mt-3">
            <div class="col-md-8">
              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing || isDisabled"
              >
                Send Message
              </button>
            </div><!--Col-->
            <div class="col-md-4 text-right">
              <div class="btn-group">

                <template v-if="client && client.language && client.language != 'en'">

                <div class="btn-group" :hidden="!form.content">
                  <button type="button" class="btn btn-outline-success" @click="autoTranslate(client.language)" :disabled="processing"><i class="fa fa-language"></i> Auto-Translate</button>
                  <button type="button" class="btn btn-outline-success dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-expanded="false" :disabled="processing">
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu">
                    <template v-for="(language, language_index) in languages">
                      <button :key="'language_'+language_index" type="button" class="dropdown-item" @click="autoTranslate(language.key)" :class="{'active': language.key == client.language }">{{ language.label }}</button>
                    </template>
                  </div>
                </div>

                </template>

                <template v-if="form.uuid">
                  <button
                    type="button"
                    class="btn btn-outline-danger btn-sm"
                    :disabled="processing"
                    @click="trashForm"
                  >
                    Trash
                  </button>
                </template>

              </div>
            </div><!--Col-->
          </div><!--Row-->

        </form>

        </div><!--Body-->
      </div><!--Card-->


  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';

export default {

  props: ['client', 'event', 'payment', 'content', 'subject'],

  data () {
    return {
      base_url: '/messages',
      title: 'Message',
      form: {},
      processing: false,
      searching: null,
      show_options: false,
      clients: [],
      uploadProgress: null,
      auto_messages: [],
      auto_message: {},
      languages: [],
      files: [],
      tos: [],
      add_to: null,
      invalid_to: false,
      search_results: [],
      attachments: []

    }
  },

  computed: {

    timezone: function() {

      if(this.form.timezone) {
        return this.form.timezone;
      }

      if(this.$root.current_user.current_agency && this.$root.current_user.current_agency.timezone) {
        return this.$root.current_user.current_agency.timezone;
      }

      return localStorage.getItem('timezone') || moment.tz.guess() || 'UTC';
    },

    isDisabled: function() {

      if(this.attachments && this.attachments.length > 0) {
        return false;
      }

      if(!this.form.content || !this.form.content.length) {
        return true;
      }

      if(this.client && this.client.uuid) {
        return false;
      }

      if(this.tos && this.tos.length > 0) {
        return false;
      }

      return true;
    },

  },

  created() {
    this.resetForm();

    if(this.content) {
      this.form.content = this.content;
    }

    if(this.subject) {
      this.form.subject = this.subject;
    }

    this.getLanguages();
    this.getAutoMessages();
    this.getFiles();
  },

  methods: {

    sendLive() {
      this.form.test = 0;
      new this.$noty({text: 'Message changed to Live Mode'}).show();
    },

    attachFile (merge_tag) {
      if(this.form.content.includes(merge_tag)) {
        return;
      }

      this.form.content += ' {'+merge_tag+'}';
    },

    changeAutoMessage (uuid) {
        this.auto_message = _.find(this.auto_messages, {'uuid': uuid});
        
        this.form.content = this.auto_message.templates['en'];
        this.form.subject = this.auto_message.subject || '';
        this.form.test = this.auto_message.simulate || 0;

        if(this.auto_message.file_url) {
          this.getFile(this.auto_message.file_url);
        }
    },

    getFile(url) {
      this.processing = true;
      this.$http.get('/files?limit=1&slim=true&fields=uuid&url='+url)
      .then(response => {
        if(response && response.status) {

          if(response.data && response.data[0] && response.data[0]['uuid']) {
            this.form.file = response.data[0]['uuid'];
            this.form.file_url = url;
          }

          this.processing = false;
        }
      })
    },

    getAutoMessages () {
        this.$http.get('/auto_messages?limit=1000&fields=name,uuid,templates,file_url&slim=false&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.auto_messages = response.data;
            }
        })
    },

    getFiles() {
      this.$http.get('/files?limit=1000&fields=name,uuid,merge_tag&slim=true&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.files = response.data;
        }
      })
    },

    addTagToMessage (value) {

      let selectionIndex = this.$refs.formContent.selectionStart;
      let newValue = '';
      if (this.form.content) {
        newValue = this.form.content.slice(0, selectionIndex) + value + this.form.content.slice(selectionIndex, this.form.content.length);
      } else {
        newValue = value;
      }
      this.form.content = newValue;

    },

    postForm () {

      this.processing = true;

      if(this.client) {
        this.form.client = this.client.uuid;
      }

      if(this.event) {
        this.form.event = this.event;
      }

      if(this.payment) {
        this.form.payment = this.payment;
      }

      if(this.$refs.files.files && this.$refs.files.files.length > 0) {
        this.form.mms = this.$refs.files.files.length;
      }

      if(this.form.uuid) {
        this.updateForm();
        return;
      }

      if(this.client) {
        this.createForm();
        return;
      }

      if(this.tos && this.tos.length > 0) {

        var self = this;

        var form = this.form;

        this.tos.forEach(to => {

          form.to = to.to;

          form.client = null;

          if(to.client) {
            form.client = to.client;
          }

          self.createFormTo(form);

        });

        this.resetAddTo();
        this.resetForm();
        this.tos = [];
      }
    },

    removeTo(to) {
      
      var exists = _.find(this.tos, {'to': to});

      if(!exists) {
        return;
      }

      var results = [];

      this.tos.forEach(r => {

        if(r.to == to) {
          return;
        }

        results.push(r);

      });

      this.tos = results;
    },

    isEmail(value) {
      const reg_email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

      return reg_email.test(value);
    },

    isPhone(value) {
      const reg_phone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

      return reg_phone.test(value);
    },

    addTo() {

      this.invalid_to = false;

      if(!this.isEmail(this.add_to) && !this.isPhone(this.add_to)) {
        this.invalid_to = true;
        return;
      }

      var exists = _.find(this.tos, {'to': this.add_to});

      if(exists) {
        this.invalid_to = true;
        return;
      }

      this.tos.unshift({'to': this.add_to});

      this.resetAddTo();
    },

    setAddToClass() {
      if(this.invalid_to) {
        return 'is-invalid';
      }
    },

    resetAddTo() {
      this.add_to = null;
      this.invalid_to = false;
      this.searching = null;
      this.search_results = [];
    },

    addClient(uuid) {
      this.getContacts(uuid);

      this.resetAddTo();
    },

    getContacts(uuid)
    {
      this.processing = true;
      this.$http.get('/contacts?fields=value&slim=true&valid=1&notify=1&optout=false&client='+uuid)
      .then(response => {
        if(response && response.status === 200) {

          this.processing = false;

          if(!response.data || response.data.length == 0) {
            new this.$noty({text: 'No contacts found for this client', type: 'error'}).show();
            return;
          }

          if(response.data && response.data.length > 0) {

              var self = this;

              response.data.forEach(r => {

                var exists = _.find(self.tos, {'to': r.value});

                if(exists) {
                  return;
                }

                self.tos.unshift({'to': r.value, 'client': uuid});
              });


          }
        }
      })
    },

    toggleOptions() {
      if(this.show_options) {
        this.show_options = false;
        return;
      }

      this.show_options = true;
    },

    searchClients: _.debounce(function (query) {
      this.getSearch(query);
    }, 500),

    getSearch() {

      if(!this.add_to || this.add_to.length < 3) {
        return;
      }

      if(this.add_to == this.searching) {
        return;
      }

      this.searching = this.add_to;

      this.$http.get('/search?limit=3&search='+this.searching)
      .then(response => {
        if(response && response.status === 200) {

          if(response.data && response.data.length > 0) {
            this.search_results = response.data;            
          }

          this.processing = false;

          this.searching = null;
        }
      })
    },

    createFormTo(form) {

      if(!form.date) {
        form.time = null;
      }

      var url = this.base_url;

      if(!this.client) {
        url = this.base_url+'/oneoffs';
      }

      this.$http.post(url, form)
      .then(response => {
        if (response && response.data) {

          this.resetForm();

          if(response.data.uuid) {
            this.responseMessage(response.data);
            this.processing = false;
            return;
          }

          if(!Array.isArray(response.data)) {
            return;
          }

          var self = this;

          response.data.forEach(r => {
            self.responseMessage(r);
          })

          this.processing = false;

        }
      })      
    },

    createForm () {

      if(!this.form.date) {
        this.form.time = null;
      }

      var url = this.base_url;

      if(!this.client) {
        url = this.base_url+'/oneoffs';
      }

      this.$http.post(url, this.form)
      .then(response => {
        if (response && response.data) {

          this.resetForm();

          if(response.data.uuid) {
            this.responseMessage(response.data);
            this.processing = false;
            return;
          }

          if(!Array.isArray(response.data)) {
            return;
          }

          var self = this;

          response.data.forEach(r => {
            self.responseMessage(r);
          })

          this.processing = false;

        }
      })
    },

    responseMessage(message) {

      if(!message.uuid) {
        return;
      }

      new this.$noty({text: this.title + ' scheduled'}).show();
      this.$eventBus.$emit('messageScheduled', message);
      this.storeFiles(message.uuid);
    },

    updateForm () {
      this.$http.patch(this.base_url + '/' + this.form.uuid, this.form)
      .then(response => {
        if (response && response.data) {
          this.resetForm();
          new this.$noty({text: this.title + ' updated'}).show();
          this.processing = false;
        }
      })
    },

    resetForm() {
      
      this.form = {
        clients: [],
        client: null,
        subject: null,
        content: "",
        signature: 0,
        test: 0,
        scheduled_at: null,
        date: null,
        time: '06:00',
        file_url: null,
        file: null
      };

      this.attachments = [];
    },

    trashForm() {
        this.processing = true;
        this.$http.delete(this.base_url+'/'+this.form.uuid)
        .then(response => {
            if(response && response.status >= 200) {

                this.processing = false;

                if(response.status === 204) {
                  this.resetForm();
                  new this.$noty({text: this.title + ' trashed', type: 'error'}).show();                  
                }
            }
            
        })
    },

    autoTranslate(language) {

      this.processing = true;

      var translator = {
        source_language: 'en',
        target_language: language,
        content: this.form.content
      }

      this.$http.post('/translator', translator)
      .then(response => {
        if(response && response.data) {

          if(response.data.content) {
            this.form.content = this.form.content + ' (Auto-Translate) '+response.data.content;
          }

          this.processing = false;
        }
      })

    },


    getLanguages () {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
          .then(response => {
              if(response && response.data) {
                  this.languages = response.data;
              }
          })
    },
    
    storeFiles(uuid) {

        if(!this.$refs.files || !this.$refs.files.files) {
          return;
        }

        const files = this.$refs.files.files;

        if(!files || !files.length) {
          return;
        }

        for (let i = 0; i < files.length; i++) {

            this.processing = true;
            
            let file = files.item(i);

            file.file_path = uuid+'/'+file.name;

            this.uploadFiles(file, {
                progress: progress => {
                    this.uploadProgress = Math.round(progress * 100);
                }
            }).then(response => {
              if(response && response.data) {
                this.processing = false;
              }
            })
        }

        this.$refs.files.name = null;
        this.$refs.files.value = null;
    },

    async uploadFiles(file, options = {}) {
        const response = await this.$http.post(this.$apiURL + 'v1/signed_url', {
            'bucket': 'ecdmms',
            'file_path': file.file_path,
            'content_type': options.contentType || file.type,
            'content_length': file.size,
            'expires': options.expires || '',
            'visibility': options.visibility || ''
        }, {
            baseURL: options.baseURL || null,
            headers: options.headers || {},
            ...options.options
        });
        if (response && response.data) {
            let headers = response.data.headers;
            if ('Host' in headers) {
                delete headers.Host;
            }
            if (typeof options.progress === 'undefined') {
                options.progress = () => {};
            }
            const cancelToken = options.cancelToken || ''
            await this.$axios.put(response.data.url, file, {
                cancelToken: cancelToken,
                headers: headers,
                onUploadProgress: (progressEvent) => {
                    options.progress(progressEvent.loaded / progressEvent.total);
                }
            })
            response.data.extension = file.name.split('.').pop();
            return response.data;
        }
        return null
    },

    updateAttachments() {

      const files = this.$refs.files.files;

      if(!files || !files.length) {
        return;
      }

      this.attachments = files;
    },

    setCharacterClass(value) {

      if(value.length > 360) {
        return 'badge-warning';
      }

      if(value.length > 10 && value.length < 360) {
        return 'badge-success';
      }

      return 'badge-secondary';
    },


  }
}
</script>
