<template>
  <div>

    <page-tabs :page="$route.meta.base_url" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">
              <div class="form-group">
                <label for="name">Portal Name *</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="domain">Domain *</label>
                <select
                  id="domain"
                  v-model="form.domain"
                  class="form-control"
                  :disabled="processing"
                  required
                >
                  <template v-for="(domain, domain_key) in domains">
                    <option :key="'domain_'+domain_key" :value="domain.domain">{{ domain.domain }}</option>
                  </template>
                </select>
              </div>

              <template v-if="form.domain == 'ecase.io'">
                <p>Launch a <strong>Web Portal</strong> to use with Events, Posts, Forms, and Self-Enroll Features.</p>
              </template>

              <template v-if="form.domain == 'ecourtpayment.com'">
                <p>Launch a <strong>Payment Portal</strong> to accept online payments and provide offline payment instructions.</p>
              </template>

                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                  Add
                </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>
              </div><!--Col-->

              <div class="col-md-4" /><!--Col-->
            </div><!--Row-->

            <table class="table table-striped table-hover table-responsive">
              <thead>
                <tr>
                  <template v-for="(column, index) in columns">
                    <th
                      :key="'column_'+index"
                      class="text-uppercase"
                    >
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>

              <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="(record, index) in records">
                    <tr :key="'record_'+index">
                      <td>
                        <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid}}">
                          <i class="fa fa-edit"></i> <span class="sr-only">Edit</span> {{ record.name }} <template v-if="!record.name">{{ record.link }}</template>
                        </router-link>
                      </td>
                      <td>{{ record.link }}</td>
                      <td>{{ record.domain }}</td>
                      <td>
                        <template v-if="record.link && record.domain">
                          <a :href="'https://'+record.link+'.'+record.domain" target="_blank"><i class="fa fa-link"></i></a>
                        </template>
                      </td>
                      <td>{{ record.portal_reference }}</td>
                      <td>
                      <template v-if="record.default === 1">
                        <i class="fa fa-check text-success" /> <span class="sr-only">DEFAULT</span>
                      </template>
                      <template v-else>
                        <i class="fa fa-times text-danger" /> <span class="sr-only">NOT DEFAULT</span>
                      </template>
                      </td>
                      <td>
                        <template v-if="record.redirect">
                          <a
                            :href="record.redirect"
                            class="btn btn-outline-secondary btn-sm"
                          ><i class="fa fa-link" /> <span class="sr-only">Link</span></a>
                        </template>
                      </td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.created_at | datetime }}</td>
                      <td>{{ record.updated_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{  $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

        <div class="row mt-3">
          <div class="col-12 text-center">

              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>

          </div><!--Col-->
        </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      validating: false,
      total: 0,
      queries: {
        limit: 10,
        slim: true,
        sort: 'name',
        sortDirection: 'asc'
      },
      records: [],
      form: {},
      columns: [
      { key: 'name', label: 'Name'},
      { key: 'link', label: 'Subdomain'},
      { key: 'domain', label: 'Domain'},
      { label: 'Link'},
      { key: 'reference', label: 'Reference'},
      { key: 'default', label: 'Default'},
      { key: 'redirect', label: 'Redirect'},
      { key: 'creator', label: 'Creator'},
      { key: 'created_at', label: 'Created'},
      { key: 'updated_at', label: 'Updated'}
      ],
      domains: []
    }
  },

  created() {
    this.resetForm();
    this.resetRecords();
    this.setURLParams();
    this.getRecords();
    this.getDomains();
  },

  methods: {

    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    resetForm() {
      this.form = {
        link: null,
        name: null,
        domain: 'ecase.io',
        geolocation: 'disabled',
        verify: 'disabled',
        weather: true
      }
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    updateSortDirection() {

      if(this.processing) {
        return;
      }

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      if(this.processing) {
        return;
      }

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if(response && response.data) {
            
            if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                    self.records.push(data);
                });
            }
            
            this.queries.skip = this.records.length;

            this.processing = false;
        }
      })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    setApexDomain(value) {
      if(value) {
        var parts = value.split('.');
        if(parts.length > 2) {
          return parts[parts.length - 2] + '.' + parts[parts.length - 1];
        }
      }
      return value;
    },

    setSubdomain(value) {
      if(value) {
        var parts = value.split('.');
        if(parts.length > 2) {
          return parts[0];
        }
      }
      return value;
    },

    postForm() {

      if(this.form.domain) {
        var domain = _.find(this.domains, {'domain': this.form.domain});

        if(domain.alias) {
          this.form.alias = domain.alias;
          this.form.domain = this.setApexDomain(domain.domain);
          this.form.link = this.setSubdomain(domain.domain);
        }
      }
      
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.status === 201) {
            this.resetForm();
            this.total++;
            this.records.unshift(response.data);
            new this.$noty({text: this.$route.meta.title + ' created'}).show();
            this.$router.push('/'+this.$route.meta.base_url+'/'+response.data.uuid);
          }

        }
      })
    },

    getDomains() {
      this.$http.get('/domains?fields=domain,alias&slim=true&limit=100&sortDirection=asc&sort=domain&alias=ecase.io')
      .then(response => {
        if(response && response.status) {
          this.domains = response.data;
          
          this.domains.unshift({'domain': 'ecourtpayment.com'});
          this.domains.unshift({'domain': 'ecase.io'});
        }
      })
    }
   

  },
}
</script>