<template>
  <div>

    <page-tabs page="message_templates" />

    <div class="row">
      <div class="col-sm-12 col-md-3 d-print-none" data-html2canvas-ignore>

    <div class="card">
      <div class="card-body">
        <p class="card-title m-0">
          Add {{ $route.meta.title }}
        </p>

        <template v-if="form.type">
          <p class="mb-1">Create a flow to send {{ form.type }} notifications and reminders.</p>
        </template>

        <form
          @submit.prevent="postForm"
        >
          <div class="form-group">
            <label for="name">Flow Name</label>
            <input
              id="name"
              v-model="form.name"
              type="text"
              class="form-control"
              required
            >
          </div>

          <div class="form-group">
            <label for="type">Type</label>
            <select id="type" class="form-control" v-model="form.type" :disabled="processing" required>
              <option value="" />
              <option
                  v-for="(flow_type, type_index) in flow_types"
                  :key="'flow_type_'+type_index"
                  :value="flow_type"
              >
                {{ flow_type }}
              </option>
            </select>

          </div>

          <button
            type="submit"
            class="btn btn-success"
            :disabled="processing"
          >
            Add
          </button>
        </form>
      </div><!--Body-->
    </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">
              Search {{  $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords">
              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                  type="search"
                  placeholder="Search by name or reference..."
                >
              </div>

              <div class="form-group">
                <label for="search_portal">Portal</label>
                <select
                    id="search_portal"
                    v-model="queries.portal"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Portal
                  </option>
                  <template v-if="portals && portals.length > 0">
                    <template v-for="(portal, index) in portals">
                      <option
                          :key="'portal_'+index"
                          :value="portal.uuid"
                      >
                        {{ portal.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_phone">From Phone</label>
                <select
                    id="search_phone"
                    v-model="queries.phone"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Phone
                  </option>
                  <template v-if="phones && phones.length > 0">
                    <template v-for="(phone, index) in phones">
                      <option
                          :key="'phone_'+index"
                          :value="phone.phone_number"
                      >
                        <template v-if="phone.name == phone.phone_number">
                          {{ phone.phone_number }}
                        </template>
                        <template v-else>
                          {{ phone.name }} {{ phone.phone_number }}
                        </template>
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_email">From Email</label>
                <select
                    id="search_email"
                    v-model="queries.email"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Email
                  </option>
                  <template v-if="emails && emails.length > 0">
                    <template v-for="(email, index) in emails">
                      <option
                          :key="'email_'+index"
                          :value="email.email"
                      >
                      <template v-if="email.name == email.email">
                        {{ email.email }}
                      </template>
                      <template v-else>
                        {{ email.name }} {{ email.email }}
                      </template>
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_creator">Creator</label>
                <select
                    id="search_creator"
                    v-model="queries.created_by"
                    class="form-control"
                    :disabled="processing"
                >
                  <template v-if="users && users.length > 0">
                    <option value="" />
                    <template v-for="(user, index) in users">
                      <option
                          :key="'user_'+index"
                          :value="user.email"
                      >
                        {{ user.email }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="simulate"
                    v-model="queries.simulate"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="simulate"
                >Simulate Mode</label>
              </div>


              <div class="form-group custom-control custom-switch">
                <input
                    id="search_default"
                    v-model="queries.default"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="search_default"
                > Is Default</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_virtual"
                    v-model="queries.virtual"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="search_virtual"
                > Virtual</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="trashed"
                >Trashed</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="archived"
                    v-model="queries.archived"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="archived"
                >Archived</label>
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
      <div class="col-sm-12 col-md-9" id="main">

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none" data-html2canvas-ignore>
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  <button type="button" class="btn btn-secondary" :class="{'active': layout === 'table'}" @click="setLayout('table')" :disabled="processing"><i class="fa fa-table"></i> <span class="sr-only">Table</span></button>
                  <button type="button" class="btn btn-secondary" :class="{'active': layout === 'grid'}" @click="setLayout('grid')" :disabled="processing"><i class="fa fa-grid"></i> <span class="sr-only">Grid</span></button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3" v-if="layout == 'table'">
              <table class="table table-striped table-hover">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="$route.meta.base_url"
              />

              <tbody>
                <template v-for="(record, index) in records">
                  <tr :key="'flow_table_'+index">
                    <td>
                      <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                        <i class="fa fa-edit"></i> {{ record.name }}
                      </router-link>
                    </td>
                    <td>
                    <template v-if="record.flow_reference">
                      <mark>{{ record.flow_reference }}</mark>
                    </template>
                    </td>
                    <td>
                      <a 
                      :href="'/preview?flow='+record.uuid" 
                      class="btn btn-outline-secondary btn-sm"
                      target="_blank"
                      >Preview</a></td>
                    <td>
                      <template v-if="record.portal">
                        <router-link :to="{'name': 'portals.edit', 'params': {'id': record.portal}}">
                          {{ setPortalName(record) }}
                        </router-link>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.default === 1">
                        <i class="fa fa-check text-success" /> <span class="sr-only">DEFAULT</span>
                      </template>
                      <template v-else>
                        <i class="fa fa-times text-danger" /> <span class="sr-only">NOT DEFAULT</span>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.simulate === 1">
                        <i class="fa fa-check text-success" /> <span class="sr-only">SIMULATE</span>
                      </template>
                      <template v-else>
                        <i class="fa fa-times text-danger" /> <span class="sr-only">NOT SIMULATE</span>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.virtual === 1">
                        <i class="fa fa-check text-success" /> <span class="sr-only">VIRTUAL</span>
                      </template>
                      <template v-else>
                        <i class="fa fa-times text-danger" /> <span class="sr-only">NOT VIRTUAL</span>
                      </template>
                    </td>
                    <td>{{ record.phone }}</td>
                    <td>{{ record.email }}</td>
                    <td>{{ record.created_by }}</td>
                    <td>{{ record.created_at | datetime }}
                      <template v-if="record.deleted_at">
                        <span class="badge badge-pill badge-danger">TRASHED</span>
                      </template>
                      <template v-if="record.archived_at">
                        <span class="badge badge-pill badge-warning">ARCHIVED</span>
                      </template>
                    </td>
                    <td>{{ record.updated_at | datetime }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

            <template v-if="layout == 'grid'">

              <div class="row my-3">
                <template v-for="(record, index) in records">
                  <div :key="'flow_grid_'+index" class="col-sm-12 col-md-6 mb-3">

                    <div class="card">
                      <div class="card-body">

                        <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}" class="text-decoration-none">
                          <p class="card-title m-0"><i class="fa fa-edit"></i> {{ record.name }}</p>
                        </router-link>

                        <template v-if="record.flow_reference">
                          <span class="badge badge-secondary">{{ record.flow_reference }}</span>
                        </template>

                        <template v-if="record.phone">
                          <p class="m-0">From Phone {{ record.phone }}</p>
                        </template>

                        <template v-if="record.email">
                          <p class="m-0">From Email {{ record.email }}</p>
                        </template>

                        <template v-if="record.portal">
                            <p class="m-0">Portal
                              <router-link tag="a" :to="{'name': 'portals.edit', 'params': {'id': record.portal}}">
                              {{ setPortalName(record) }}
                              </router-link>
                            </p>
                        </template>

                        <template v-if="record.geolocation">
                          <p class="m-0">Geolocation {{ record.geolocation }}</p>
                        </template>

                        <template v-if="record.types">
                          <p class="m-0">Types {{ record.types }}</p>
                        </template>

                        <template v-if="record.locations && record.locations.length > 0">
                          <p class="m-0">Locations
                          <template v-for="(location, location_index) in record.locations">
                            <router-link :key="'location_'+location_index" :to="{'name': 'locations.edit', 'params': {'id': location.uuid}}">
                              {{ location.name }}
                            </router-link>
                          </template>
                          </p>
                        </template>

                        <template v-if="record.simulate === 1">
                          <span class="badge badge-pill badge-primary">Simulate Mode</span><br>
                        </template>

                        <template v-if="record.default === 1">
                          <p class="m-0"><i class="fa fa-circle text-success"></i> Default Flow</p>
                        </template>

                    <template v-if="record.messages">

                      <template v-for="(message, message_index) in record.messages">

                        <div :key="'grid_message_'+message_index" class="my-3">

                          <p class="sms outbound">{{ message.templates['en'] }}</p>

                          <p class="small text-muted m-0">{{ setMessageName(message) }}

                            <template v-if="message.trigger == 'event_date' || message.trigger == 'payment_issue_date' || message.trigger == 'payment_due_date'">
                              {{ message.difference }} {{ message.unit }}
                              <template v-if="message.operation === '-'">
                                before
                              </template>
                              <template v-if="message.operation === '+'">
                                after
                              </template>
                            </template>

                            <template v-if="message.status">
                              if <span class="badge badge-secondary">{{ message.status }}</span>
                            </template>

                          </p>

                          <div class="btn-group">
                            <a :href="'/preview?content='+message.templates['en']" class="btn btn-secondary" :disabled="processing"><i class="fal fa-mobile"></i> Preview</a>
                            <button type="button" class="btn btn-primary" @click="copyToClipboard(message.templates['en'])" :disabled="processing"><i class="fa fa-copy"></i> Copy</button>
                          </div>

                        </div><!--Message-->

                      </template><!--Message-->

                    </template><!--Messages-->

                        <template v-if="record.created_at">
                          <timestamps :form="record" />
                        </template>

                        </div><!--Body-->
                      </div><!--Card-->


                  </div><!--Col-->


                </template>
              </div><!--Row-->

            </template><!--Layout-->

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="records && records.length < total">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary d-print-none"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from "lodash";

export default {

  mixins: [ queries ],

  data () {
    return {
      records: [],
      options: [],
      layout: 'table',
      processing: false,
      queries: {},
      form: {},
      total: 0,
      columns: [
          {key: 'name', label: 'Name'},
          {key: 'flow_reference', label: 'Reference'},
          {label: 'Preview'},
          {key: 'portal', label: 'portal'},
          {key: 'default', label: 'default'},
          {key: 'simulate', label: 'simulate'},
          {key: 'virtual', label: 'virtual'},
          {key: 'phone', label: 'From Phone'},
          {key: 'email', label: 'From Email'},
          {key: 'created_by', label: 'Creator'},
          {key: 'created_at', label: 'Created'},
          {key: 'updated_at', label: 'Updated'}
      ],
      flow_types: ['event', 'payment', 'case', 'warrant', 'form'],
      portals: [],
      users: [],
      phones: [],
      emails: [],
      downloading: false,
      downloads: [],
      triggers: []
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

        if(view !== self.$route.meta.base_url) {
            return;
        }

        self.resetRecords();
        self.getRecords();

    });

  },

  created () {
    this.resetQueries();
    this.resetForm();
    this.resetRecords();
    this.setURLParams();
    this.setDefaultLayout();

    this.getRecords();
    this.getOptions();
    this.getPortals();
    this.getUsers();
    this.getPhones();
    this.getEmails();
    this.getTriggers();
  },

  methods: {

    setDefaultLayout() {

      var preferences = localStorage.getItem('preferences');

      preferences = JSON.parse(preferences);

      if(this.$root.preferences) {
        preferences = this.$root.preferences;
      }

      if(preferences && preferences['default_layout']) {
        this.layout = preferences['default_layout'];
      }
    },

    resetQueries() {
      this.queries = {
        search: '',
        portal: null,
        created_by: null,
        phone: null,
        email: null,
        limit: 10,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'desc',
        simulate: false,
        default: false,
        virtual: false,
        trashed: false,
        archived: false
      }
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords () {

      this.processing = true;

      this.updateURL();

      this.totalRecords();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if (response && response.data) {

          this.setDefaultLayout();

          this.processing = false;

          if(response.data.length) {

            this.queries.skip += response.data.length;

            var self = this;

            response.data.forEach(function(data) {
              self.records.push(data);
            });

          }
        }
      })
    },

    totalRecords() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    previewFlow(flow) {
      window.location.href = '/preview?flow='+flow.uuid;
    },

    resetForm() {
      this.form = {
        name: null,
        type: 'event'
      }
    },

    postForm() {
        this.processing = true;

        if(!this.form.type) {
          this.form.type = 'event';
        }

        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
            if(response && response.status) {

                if(response.status === 201) {
                  this.resetForm();
                  response.data.is_new = true;
                  this.total++;
                  this.setTitle();
                  this.records.unshift(response.data);
                  new this.$noty({text: this.$route.meta.title + ' added'}).show();
                }

                this.processing = false;

            }
        });
    },

    getOptions () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
      .then(response => {
          if(response && response.data) {
              this.options = response.data;
          }
      })
    },

    getPortals() {
      this.$http.get('/portals?slim=true&limit=150&fields=name,uuid')
          .then(response => {
            if(response && response.data) {
              this.portals = response.data;
            }
          })
    },

    getUsers() {
      this.$http.get('/users?slim=true&limit=150&fields=name,uuid,email')
          .then(response => {
            if(response && response.data) {
              this.users = response.data;
            }
          })
    },

    getPhones() {
      this.$http.get('/agency_phones?slim=true&limit=150&fields=name,uuid,phone_number')
          .then(response => {
            if(response && response.data) {
              this.phones = response.data;
            }
          })
    },

    getEmails() {
      this.$http.get('/agency_emails?slim=true&limit=150&fields=name,uuid,email')
          .then(response => {
            if(response && response.data) {
              this.emails = response.data;
            }
          })
    },

    setPortalName(record) {

      if(!record.portal) {
        return;
      }

      var portal = _.find(this.portals, {'uuid': record.portal});

      if(!portal || !portal.name) {
        return;
      }

      return portal.name;
    },

    async downloadPDF() {
      try {

        window.scrollTo(0,0);

        this.downloading = true;

        const element = document.getElementById('main');

        const canvas = await this.$html2canvas(element, {
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          scale: window.devicePixelRatio,
          useCORS: true,
          logging: false
        });

        const imgData = canvas.toDataURL('image/png');

        const pdf = new this.$jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

        pdf.save(name+'.pdf');

        this.downloading = false;
      } catch (error) {
        new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
        console.log(error);
        this.downloading = false;
      }
    },

    downloadCSV () {
      this.downloading = true;

      var queries = this.queries;

      queries.limit = this.total;

      queries.skip = this.downloads.length;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
          .then(response => {
            if(response && response.data && response.data) {
              this.downloads = this.downloads.concat(response.data);

              var csvFile, data, link;

              data = this.convertCSV(this.downloads);

              var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

              csvFile = new Blob([data], {type: "text/csv"});
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(csvFile, file);
              } else {
                link = document.createElement("a");
                link.download = file;
                link.href = window.URL.createObjectURL(csvFile);
                link.style.display = "none";
                document.body.appendChild(link);
                link.click();
              }

              this.downloading = false;

            } else {
              this.downloadCSV();
            }

          });
    },

    convertCSV(records) {

      var results = [];

      if(records[0] && records[0]['uuid']) {
        var headers = Object.keys(records[0]);
      }

      results += headers.join(',')

      results += '\n';

      records.forEach(function(item) {
        var record = '';

        if(!item) {
          return;
        }

        Object.keys(item).forEach(function(key) {

          if(key == 'messages') {
            Object.keys(item[key]).forEach(function(message) {
              if(item[key][message]['name']) {
                record += '"' + item[key][message]['name'] + '",';
              }
              if(item[key][message]['templates'] && item[key][message]['templates']['en']) {
                record += '"' + item[key][message]['templates']['en'] + '",';
              }
            });
            return;
          }

          record += '"' + (item[key] ? item[key] : '') + '",';
        });

        results += record;
        results += '\n';
      });

      return results
    },

    setLayout(layout) {
      this.$eventBus.$emit('updatePreferences', {'default_layout': layout})
      this.layout = layout;
    },

    getTriggers() {

      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
          .then(response => {
            if(response && response.data) {
              this.triggers = response.data.flow_message_triggers;
            }
          })

    },

    setMessageName(message) {
      var trigger = _.find(this.triggers, {'value': message.trigger});

      if(trigger && trigger.label) {
        return trigger.label;
      }

      return message.trigger;
    },

    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      new this.$noty({text: 'Copied to clipboard'}).show();
    },

  }
}
</script>
