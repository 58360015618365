<template>
  <div>

    <page-tabs page="payments" />
    
    <div class="row">
      <div class="col-lg-3 col-md-12 mb-3">

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords">

              <div class="form-group">
                <label for="amount">Amount</label>
                <input id="amount" type="number" class="form-control" v-model="queries.amount" step="0.01" min="0">
              </div>

              <div class="form-group">
                <label for="status">Status</label>
                <select id="status" class="form-control text-uppercase" v-model="queries.status" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(status, status_index) in statuses">
                    <option :key="'status_'+status_index" :value="status">{{ status }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="method">Method</label>
                <select id="method" class="form-control text-uppercase" v-model="queries.method" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(method, method_index) in methods">
                    <option :key="'method_'+method_index" :value="method">{{ method }}</option>
                  </template>
                </select>
              </div>

              <template v-if="queries.method == 'card'">

                <div class="form-group">
                  <label for="brand">Card Brand</label>
                  <select id="brand" class="form-control text-uppercase" v-model="queries.brand" :disabled="processing">
                    <option value=""></option>
                    <template v-for="(brand, brand_index) in card_brands">
                      <option :key="'brand_'+brand_index" :value="brand">{{ brand }}</option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="last4">Card Last Four</label>
                  <input id="last4" type="number" class="form-control" v-model="queries.last4" min="1000" max="9999">
                </div>

              </template>

              <div class="form-group">
                <label for="min_amount">Min Amount</label>
                <input id="min_amount" type="number" class="form-control" v-model="queries.min_amount" step="0.01" min="0">
              </div>

              <div class="form-group">
                <label for="max_amount">Max Amount</label>
                <input id="max_amount" type="number" class="form-control" v-model="queries.max_amount" step="0.01" min="0">
              </div>

              <div class="form-group">
                <label for="date">Transaction Date</label>
                <input id="date" type="date" class="form-control" v-model="queries.date">
              </div>

              <div class="form-group">
                <label for="from_date">Transaction From Date</label>
                <input id="from_date" type="date" class="form-control" v-model="queries.from_date">
              </div>

              <div class="form-group">
                <label for="to_date">Transaction To Date</label>
                <input id="to_date" type="date" class="form-control" v-model="queries.to_date">
              </div>

              <div class="btn-group">

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>

              <input type="reset" class="btn btn-secondary">

              </div>

            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
      <div id="main" class="col-lg-9 col-md-12">

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none" data-html2canvas-ignore>
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
            <table class="table table-striped table-hover">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="$route.meta.base_url"
              />

              <tbody>
                <template v-for="(record, index) in records">
                  <tr :key="'client_recurring_'+index">
                    <td class="text-capitalize">
                      <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                        <i class="fa fa-edit"></i>
                        {{ record.amount }}
                      </router-link>
                    </td>
                    <td>
                      <template v-if="record.payment">
                        <router-link :to="{'name': 'payments.edit', 'params': {'id': record.payment }}">
                          Payment
                        </router-link>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.status">
                        <span class="badge badge-pill text-uppercase" :class="setStatusClass(record)">{{ record.status }}</span>
                      </template>
                    </td>
                    <td>
                        <template v-if="record.client">
                          <client-button :uuid="record.client" />
                        </template>
                    </td>
                    <td class="text-uppercase">{{ record.method }}</td>
                    <td>
                      <template v-if="record.pay_data && record.pay_data.card && record.pay_data.card.brand">
                      <img :src="$root.assets_url+'images/payments/'+record.pay_data.card.brand+'.svg'" width="40" :alt="record.pay_data.card.brand">
                      <span class="badge badge-secondary badge-pill">Last 4: {{ record.pay_data.card.last4 }}</span>
                      </template>
                    </td>
                    <td>{{ record.created_at | datetime }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="records && records.length < total">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary d-print-none"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      records: [],
      downloads: [],
      processing: false,
      downloading: false,
      total: 0,
      queries: {},
      form: {},
      columns: [
          {key: 'amount', label: 'Amount'},
          {key: 'payment', label: 'Payment'},
          {key: 'status', label: 'Status'},
          {key: 'client', label: 'Client'},
          {key: 'method', label: 'Method'},
          {key: 'pay_data', label: 'Transaction'},
          {key: 'created_at', label: 'Created'}
      ],
      methods: ['card', 'ach', 'paypal', 'applepay', 'googlepay', 'manual'],
      statuses: ['succeeded', 'viewed', 'failed', 'refunded'],
      card_brands: ['visa', 'mastercard', 'amex', 'discover', 'jcb', 'diners'],
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on('transactionCreated', function (data) {
      self.records.unshift(data);
      self.total++;
    });

    this.$eventBus.$on('refreshRecords', function(view) {

      if(view !== self.$route.meta.base_url) {
        return;
      }

      self.resetRecords();

      self.getRecords();

    });

  },

  created () {
    this.resetRecords();
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getRecords();
    this.getPlans();
  },

  methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    searchRecords () {
        this.resetRecords();
        this.getRecords();
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    resetForm() {
      this.form = {
        client: null
      }
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'desc',
        client: null,
        amount: null,
        plan: null,
        min_amount: null,
        max_amount: null,
        date: null,
        from_date: null,
        to_date: null,
        last4: null,
        brand: null
      }
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
    },

    getRecords () {

      this.processing = true;

      this.updateURL();

      this.totalRecords();

      this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
      .then(response => {
        if (response && response.data) {

          this.processing = false;

          if(response.data.length) {

            this.queries.skip += response.data.length;

            var self = this;

            response.data.forEach(function(data) {
              self.records.push(data);
            });

          }
        }
      })
    },

    totalRecords() {
      this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    getPlans() {
      this.$http.get('/pay_plans?slim=true&fields=uuid,name&limit=100')
      .then(response => {
        if(response && response.data) {
          this.plans = response.data;
        }
      })
    },

    setStatusClass(record) {
      if(!record.status) {
        return;
      }

      if(record.status == 'succeeded') {
        return 'badge-success';
      }

      if(record.status == 'viewed') {
        return 'badge-warning';
      }

      if(record.status == 'failed') {
        return 'badge-danger';
      }

      return 'badge-secondary';
    },

    async downloadPDF() {
      try {

        window.scrollTo(0,0);

        this.downloading = true;

        const element = document.getElementById('main');

        const canvas = await this.$html2canvas(element, {
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          scale: window.devicePixelRatio,
          useCORS: false,
          logging: false
        });

        const imgData = canvas.toDataURL('image/png');

        const pdf = new this.$jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

        pdf.save(name+'.pdf');

        this.downloading = false;
      } catch (error) {
        new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
        console.log(error);
        this.downloading = false;
      }
    },

    downloadCSV () {
      this.downloading = true;

      var queries = this.queries;

      queries.limit = this.total;

      queries.skip = this.downloads.length;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
          .then(response => {
            if(response && response.data && response.data) {
              this.downloads = this.downloads.concat(response.data);

              var csvFile, data, link;

              data = this.convertCSV(this.downloads);

              var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

              csvFile = new Blob([data], {type: "text/csv"});
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(csvFile, file);
              } else {
                link = document.createElement("a");
                link.download = file;
                link.href = window.URL.createObjectURL(csvFile);
                link.style.display = "none";
                document.body.appendChild(link);
                link.click();
              }

              this.downloading = false;

            } else {
              this.downloadCSV();
            }

          });
    },

    convertCSV(records) {

      var results = [];

      if(records[0] && records[0]['uuid']) {
        var headers = Object.keys(records[0]);
      }

      results += headers.join(',')

      results += '\n';

      records.forEach(function(item) {
        var record = '';

        if(!item) {
          return;
        }

        Object.keys(item).forEach(function(key) {
          record += '"' + (item[key] ? item[key] : '') + '",';
        });

        results += record;
        results += '\n';
      });

      return results
    },


  }
}
</script>