<template>
  <div>

    <template v-if="record && record.uuid">

    <p class="card-title mt-3"><a :href="base_url+'/'+record.uuid">Test Template</a></p>

    <ul class="list-group">

      <li class="list-group-item">Name <a :href="base_url+'/'+record.uuid">{{ record.name || record.uuid }}</a></li>

      <template v-if="record.version">
        <li class="list-group-item">Version {{ record.version }}</li>
      </template>

      <template v-if="record.state">
        <li class="list-group-item">State {{ record.state }}</li>
      </template>

      <template v-if="record.county">
        <li class="list-group-item">County {{ record.county }}</li>
      </template>

      <template v-if="record.language">
        <li class="list-group-item">Language {{ record.language }}</li>
      </template>

    </ul>

    </template>

  </div>
</template>
<script>
export default {

props: ['template'],

data() {
    return {
        processing: false,
        base_url: '/test_templates',
        record: {}
    }
},

created() {
    this.getRecord();
},

methods: {

   getRecord() {

    if(!this.template) {
      return;
    }

    this.$http.get(this.base_url+'/'+this.template)
    .then(response => {
      if(response && response.data) {

        this.record = response.data;

        this.processing = false;

      }
    })

   },
}

}
</script>