<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <p class="card-title">
            <i class="fa fa-browser"></i> User Agent
          </p>
        </div><!--Col-->

        <div class="col-md-6 text-right">
          <template v-if="user_agent.os && user_agent.os.icon_large">
            <img
              :src="user_agent.os.icon_large"
              width="48"
            >
          </template>
        </div><!--Col-->
      </div><!--Row-->

      <ul class="list-group my-3">
        <template v-if="user_agent.type">
          <li class="list-group-item">
            TYPE {{ user_agent.type }}
          </li>
        </template>

        <template v-if="user_agent.device && user_agent.device.type">
          <li class="list-group-item">
            DEVICE TYPE {{ user_agent.device.type }}
          </li>
        </template>


        <template v-if="user_agent.brand">
          <li class="list-group-item">
            BRAND {{ user_agent.brand }}
          </li>
        </template>

        <template v-if="user_agent.os && user_agent.os.name">
          <li class="list-group-item">
            OS {{ user_agent.os.name }}
          </li>
        </template>

        <template v-if="user_agent.browser && user_agent.browser.name">
          <li class="list-group-item">
            BROWSER {{ user_agent.browser.name }} {{ user_agent.browser.version }}
          </li>
        </template>
      </ul>
    </div><!--Body-->
  </div><!--Card-->
</template>
<script>
export default {

// props: ['raw'],

props: {
  raw: {
    type: String,
    default: null
  }
},

data() {
    return {
        base_url: '/user_agents',
        user_agent: {}
    }
},


created() {
    this.getUserAgent();
},

methods: {


    getUserAgent() {

        if(!this.raw) {
            return;
        }

        this.$http.get(this.base_url+'?ua='+this.raw)
        .then(response => {
            if(response && response.data) {
                this.user_agent = response.data;
            }
        })
    }

}

}
</script>