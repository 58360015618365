<template>
  <div>

    <page-tabs :page="$route.meta.base_url" />

      <div class="row">
        <div class="col-sm-12 col-md-3">
          <div class="card">
            <div class="card-body">

              <p class="card-title">Search {{ $route.meta.title }}s</p>

              <form @submit.prevent="searchRecords">

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        <template v-if="queries.search_field">{{ queries.search_field }}</template>
                        <template v-else>Search</template>
                      </button>
                      <div class="dropdown-menu">
                        <template v-for="(search_field, search_field_index) in search_fields">
                        <button :key="'search_field_'+search_field_index" type="button" class="dropdown-item" @click="setSearchField(search_field)" :disabled="processing">{{ search_field }}</button>
                        </template>
                      </div>
                    </div>
                    <input type="text" class="form-control" aria-label="Search messages" v-model="queries.search">
                  </div>

                <div class="form-group">
                  <label for="subject">Subject</label>
                  <input
                    id="subject"
                    v-model="queries.subject"
                    type="subject"
                    class="form-control"
                  >
                </div>


                <div class="form-group vbDatePicker">
                  <label for="from_date">From Date</label>
                  <input
                    id="from_date"
                    v-model="queries.from_date"
                    placeholder="mm/dd/yyyy"
                    type="date"
                    class="form-control"
                  >
                </div>

                <div class="form-group vbTimePicker">
                  <label for="from_time">From Time</label>
                  <input
                    id="from_time"
                    v-model="queries.from_time"
                    placeholder="hh:mm AM"
                    type="time"
                    class="form-control"
                  >
                </div>

                <div class="form-group vbDatePicker">
                  <label for="to_date">To Date</label>
                  <input
                    id="to_date"
                    v-model="queries.to_date"
                    placeholder="mm/dd/yyyy"
                    type="date"
                    class="form-control"
                  >
                </div>

                <div class="form-group vbTimePicker">
                  <label for="to_time">To Time</label>
                  <input
                    id="to_time"
                    v-model="queries.to_time"
                    placeholder="hh:mm AM"
                    type="time"
                    class="form-control"
                  >
                </div>

                <template v-if="options.message_filters">
                  <div class="form-group">
                    <label for="filters">Filters</label><br>
                    <multi-select 
                      id="filters"
                      v-model="message_filters" 
                      :options="options.message_filters"
                      track-by="key"
                      label="title"
                      :multiple="true"
                      placeholder="Choose filters"
                      :close-on-select="false"
                    />
                  </div>
                </template>

                <div class="form-group">
                  <label for="status">Status</label>
                  <select
                    id="status"
                    v-model="queries.status"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value=""></option>
                    <template v-for="(status, index) in options.message_statuses">
                      <option
                        :key="'status_'+index"
                        :value="status"
                      >
                        {{ status }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="type">Type</label>
                  <select
                    id="type"
                    v-model="queries.type"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="(message_type, index) in message_types">
                      <option
                        :key="'message_type_'+index"
                        :value="message_type"
                      >
                        {{ message_type }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="error_code">Error Code</label>
                  <select
                    id="error_code"
                    v-model="queries.error_code"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="(error_code, index) in error_codes">
                      <option
                        :key="'error_code_'+index"
                        :value="index"
                      >
                        {{ index }} {{ error_code }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="created_by">Creator</label>
                  <select 
                    id="created_by"
                    v-model="queries.created_by"
                    class="form-control" 
                    :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="(user, index) in users">
                      <option 
                        :key="'user_'+index" 
                        :value="user.email"
                      >
                        {{ user.name }} {{ user.email }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group custom-control custom-switch">
                <input
                    id="simulate"
                    v-model="queries.simulate"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="simulate"
                >Simulate Mode</label>
              </div>

                <div class="form-group">
                  <label for="search_credits">Credits</label>
                  <input id="search_credits" type="number" step="1" min="0" v-model="queries.credits" class="form-control">
                </div>

                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
                >
                  Search
                </button>
              </form>
            </div><!--Body-->
          </div><!--Card-->
        </div><!--Col-->

        <div id="main" class="col-sm-12 col-md-9">
          <div class="card">
            <div class="card-body">

              <div class="row">

                <div class="col-sm-12 col-md-6">

                  <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                  </p>

                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-right">

                  <div class="btn-group d-print-none" data-html2canvas-ignore>
                    <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                    <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  </div>
                </div><!--Col-->

              </div><!--Row-->

              <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <sort-columns
                  :columns="columns"
                  :queries="queries"
                  :processing="processing"
                  :base_url="$route.meta.base_url"
                />

                <tbody>
                  <template>
                    <template v-for="(record, index) in records">
                      <tr :key="'message_'+index">
                        <td>
                          <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                          <i class="fa fa-edit"></i>  {{ record.type }}
                            <template v-if="!record.type && record.direction == 'inbound'">Inbound</template>
                            <template v-if="!record.type && record.direction != 'inbound'">Edit</template>
                          </router-link>
                        </td>
                        <td>
                          <template v-if="record.client">
                            <client-button :uuid="record.client" />
                          </template>
                        </td>
                        <td>
                          <template v-if="record.event">
                            <router-link :to="{'name': 'events.edit', 'params': {'id': record.event }}">
                              <i class="fa fa-calendar"></i> Event
                            </router-link>
                          </template>
                        </td>
                        <td>
                          <template v-if="record.payment">
                            <router-link :to="{'name': 'payments.edit', 'params': {'id': record.payment }}">
                              <i class="fa fa-credit-card"></i> Payment
                            </router-link>
                          </template>
                        </td>
                        <td>
                          <a
                            :href="'/contacts?search='+record.to"
                          >{{ record.to }}</a>
                        </td>
                        <td>
                          <a
                            :href="'/contacts?search='+record.from"
                          >{{ record.from }}</a>
                        </td>
                        <td>
                          <template v-if="record.last_status">
                            <span class="badge badge-pill text-uppercase" :class="setStatusClass(record.last_status)">{{ record.last_status }}</span>
                          </template>

                          <template v-if="record.error_code">
                            <p class="badge badge-pill badge-secondary">
                            {{ record.error_code }}
                            {{ setErrorCode(record.error_code) }}
                            </p>
                          </template>

                          <template v-if="record.test">
                            <span class="badge badge-primary badge-pill">SIMULATED</span>
                          </template>
                        </td>

                        <td>{{ record.subject }}</td>
                        <td>{{ record.content }}</td>
                        <td>{{ record.created_by }}</td>
                        <td>{{ record.scheduled_at | datetime }}</td>
                        <td>{{ record.created_at | datetime }}</td>
                        <td>{{ record.segments }}</td>
                        <td>{{ record.credits }}</td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
              </div><!--Responsive-->

              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found.
                </div>
              </template>
            </div><!--Body-->
          </div><!--Card-->

          <div class="row">
            <div class="col-12 text-center">
              <template v-if="records && records.length && total > records.length">
                <div class="d-flex justify-content-center align-items-center py-3">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    :disabled="processing"
                    @click="getRecords"
                  >
                    Load more
                  </button>
                </div>
              </template>
            </div><!--Col-->
          </div><!--Row-->
        </div><!--Col-->
      </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from "lodash";

export default {

mixins: [ queries ],

data() {
    return {
        processing: false,
        downloading: false,
        total: 0,
        records: [],
        downloads: [],
        options: [],
        queries: {},
        filters_url: [],
        message_filters: [],
        message_types: [],
        columns: [
            {key: 'type', label: 'Type'},
            {label: 'Client'},
            {label: 'Event'},
            {label: 'Payment'},
            {key: 'to', label: 'To'},
            {key: 'from', label: 'From'},
            {key: 'last_status', label: 'Status'},
            {key: 'subject', label: 'Subject'},
            {key: 'content', label: 'Content'},
            {key: 'created_by', label: 'Creator'},
            {key: 'scheduled_at', label: 'Scheduled'},
            {key: 'created_at', label: 'Created'},
            {key: 'segments', label: 'Segments'},
            {key: 'credits', label: 'Credits'}
        ],
        search_fields: ['from', 'to', 'status', 'type', 'subject', 'content', 'channel', 'error_code', 'language', 'direction'],
        error_codes: [],
        users: []
    }
},


created() {

    this.resetQueries();
    this.resetRecords();
    this.setURLParams();
    this.getOptions();
    this.getMessageTypes();

    if(this.$route.query.type) {
        this.queries.type = this.$route.query.type;
    }

    if(this.$route.query.search) {
        this.queries.search = this.$route.query.search;
    }

    this.getErrorCodes();

    this.getRecords();

    this.getUsers();
},

mounted() {
    var self = this;

  this.$eventBus.$on('refreshRecords', function(view) {

      if(view !== self.$route.meta.base_url) {
          return;
      }

      self.resetRecords();
      self.getRecords();

  });

},

methods: {

    resetQueries() {
      this.queries = {
          search: null,
          search_field: null,
          subject: null,
          from_date: null,
          from_time: null,
          to_date: null,
          to_time: null,
          type: null,
          live: null,
          created_by: null,
          client: null,
          status: null,
          credits: null,
          limit: 10,
          skip: 0,
          filters: [],
          sort: 'scheduled_at',
          sortDirection: 'desc',
          slim: true,
          simulate: false
      }
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;      
    },

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {

          if(!value || value == 'undefined' || value == 'null') {
            return;
          }
          
          self.queries[key] = value;
          
        });

    },

  setStatusClass(status) {
    if(_.includes(['undelivered', 'failed', 'duplicate', 'bounced', 'missed', 'invalid'], status)) {
      return 'badge badge-danger'
    }
    if(_.includes(['delivered', 'opened', 'answered'], status)) {
      return 'badge badge-success'
    }
    if(_.includes(['dispatched', 'sent'], status)) {
      return 'badge badge-primary'
    }
    return 'badge badge-primary'
  },

  getOptions () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
      .then(response => {
        if(response && response.data) {
            this.options = response.data;
        }
      })
    },

    getMessageTypes() {
        this.$http.get('/message_types')
        .then(response => {
            if(response && response.data) {
               this.message_types = response.data;
            }
        })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords() {

        this.processing = true;

        let message_filters = this.message_filters.map((field) => {return field.key});

        this.queries.filters = message_filters.toString();

        this.updateURL();

        this.getTotal();

        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
            if(response && response.data) {

                this.processing = false;

                if(response.data.data) {
                    this.records = this.records.concat(response.data.data);
                    this.queries.skip = this.records.length;                    
                }
            }
        })
    },

    getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
            if(response && response.status) {
                this.total = response.data;
                this.setTitle();
            }
        })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    setSearchField(field) {
      this.queries.search_field = field;
    },

    searchRecords () {
      this.resetRecords();
      this.getRecords();        
    },

    getErrorCodes() {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/error_codes.json')
        .then(response => {
            if(response && response.data) {
                this.error_codes = response.data;
            }
        })
    },

    getUsers() {
      this.$http.get('/users?slim=true&limit=1000&fields=name,email&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.users = response.data;
        }
      })
    },

  async downloadPDF() {
    try {

      window.scrollTo(0,0);

      this.downloading = true;

      const element = document.getElementById('main');

      const canvas = await this.$html2canvas(element, {
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight,
        scrollY: -window.scrollY,
        scrollX: -window.scrollX,
        scale: window.devicePixelRatio,
        useCORS: true,
        logging: false
      });

      const imgData = canvas.toDataURL('image/png');

      const pdf = new this.$jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height]
      });

      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

      var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

      pdf.save(name+'.pdf');

      this.downloading = false;
    } catch (error) {
      new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
      console.log(error);
      this.downloading = false;
    }
  },

  downloadCSV () {
    this.downloading = true;

    var queries = this.queries;

    queries.limit = this.total;

    queries.skip = this.downloads.length;

    this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
        .then(response => {
          if(response && response.data && response.data.data) {
            this.downloads = this.downloads.concat(response.data.data);

            var csvFile, data, link;

            data = this.convertCSV(this.downloads);

            var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

            csvFile = new Blob([data], {type: "text/csv"});
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(csvFile, file);
            } else {
              link = document.createElement("a");
              link.download = file;
              link.href = window.URL.createObjectURL(csvFile);
              link.style.display = "none";
              document.body.appendChild(link);
              link.click();
            }

            this.downloading = false;

          } else {
            this.downloadCSV();
          }

        });
  },

  convertCSV(records) {

    var results = [];

    if(records[0] && records[0]['uuid']) {
      var headers = Object.keys(records[0]);
    }

    results += headers.join(',')

    results += '\n';

    records.forEach(function(item) {
      var record = '';

      if(!item) {
        return;
      }

      Object.keys(item).forEach(function(key) {
        record += '"' + (item[key] ? item[key] : '') + '",';
      });

      results += record;
      results += '\n';
    });

    return results
  },

  setErrorCode(code) {

    if(!code) {
      return;
    }

    if(!this.$root['error_codes']) {
      return;
    }

    if(!this.$root['error_codes'][code]) {
      return;
    }

    return this.$root['error_codes'][code];
  }

  }

}
</script>