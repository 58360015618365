<template>
  <div>

    <page-tabs :page="$route.meta.base_url" />

    <div class="container">

    <div class="row">
      <div class="col-md-6">
        <template v-if="form.name">
          <h1 class="m-0">
            {{ form.name }}
          </h1>

          <h4>Agency {{ $route.meta.title }}</h4>

        </template>
      </div>
      <div class="col-md-6 text-right">
        <button
          id="settingsForm"
          type="submit"
          class="btn btn-success"
          :disabled="processing"
          @click="postForm()"
        >
          Save
        </button>
      </div>
    </div>
    <div class="row mt-3">

      <div class="col-12">
          <form
            ref="settingsForm"
            role="form"
            @submit.prevent="postForm()"
          >
            <div class="card">
              <div class="card-body">
                
                <div class="form-group">
                  <label for="send_mode">Send Mode</label>
                  <select
                    id="send_mode"
                    v-model="form.send_mode"
                    class="form-control text-capitalize"
                    :disabled="processing"
                  >
                    <option
                      v-for="send_mode in send_modes"
                      :key="send_mode.key"
                      :value="send_mode.key"
                    >
                      {{ send_mode.title }}
                    </option>
                  </select>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="name">Agency Name <merge-tag :merge_tag="'[AgencyName]'" /></label>
                      <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        class="form-control"
                        required
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="reference">Agency Reference</label>
                      <input
                        id="reference"
                        v-model="form.reference"
                        type="text"
                        class="form-control"
                        :disabled="!superAdmin"
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 col-sm-4">
                    <div class="form-group">
                      <label for="phone">Agency Phone <merge-tag :merge_tag="'[AgencyPhone]'" /></label>
                      <select id="phone" class="form-control" v-model="form.phone" :disabled="processing">
                        <option value=""></option>
                        <template v-for="(phone, phone_index) in phones">
                          <option :key="'phone_'+phone_index" :value="phone.phone_number">{{ phone.phone_number }}</option>
                        </template>
                      </select>
                      <router-link :to="{name: 'agency_phones.index'}" class="form-text text-muted small">Manage Agency Phones</router-link>
                    </div>
                  </div>
                  <div class="col-6 col-sm-4">
                    <div class="form-group">
                      <label for="email">Agency Email <merge-tag :merge_tag="'[AgencyEmail]'" /></label>
                      <select id="email" class="form-control" v-model="form.email" :disabled="processing">
                        <option value=""></option>
                        <template v-for="(email, email_index) in emails">
                          <option :key="'email_'+email_index" :value="email.email">{{ email.email }}</option>
                        </template>
                      </select>
                      <router-link :to="{name: 'agency_emails.index'}" class="form-text text-muted small">Manage Agency Emails</router-link>
                    </div>
                  </div>
                  <div class="col-6 col-sm-4">
                    <div class="form-group">
                      <label for="website">Agency Website <merge-tag :merge_tag="'[AgencyWebsite]'" /></label>
                      <input
                        id="website"
                        v-model="form.website"
                        type="text"
                        class="form-control"
                      >
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="agency_address">Agency Address <merge-tag :merge_tag="'[AgencyAddress]'" /></label>
                  <input
                    id="agency_address"
                    v-model="form.address"
                    type="text"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="agency_address_2">Address 2 <merge-tag :merge_tag="'[AgencyAddress2]'" /></label>
                  <input
                    id="agency_address_2"
                    v-model="form.address_2"
                    type="text"
                    class="form-control"
                  >
                </div>

                <div class="row">

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group">
                      <label for="agency_city">City <merge-tag :merge_tag="'[AgencyCity]'" /></label>
                      <input
                        id="agency_city"
                        v-model="form.city"
                        type="text"
                        class="form-control"
                      >
                    </div>

                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">

                  <div class="form-group">
                    <label for="agency_state">State <merge-tag :merge_tag="'[AgencyState]'" /></label>
                    <select
                      id="agency_state"
                      v-model="form.state"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value="" />
                      <option
                        v-for="(state, state_index) in states"
                        :key="'state_'+state_index"
                        :value="state.key"
                      >
                        {{ state.label }}
                      </option>
                    </select>
                  </div>

                  </div><!--Col-->

                </div><!--Row-->


                <div class="row">

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group">
                      <label for="agency_zip">Zip <merge-tag :merge_tag="'[AgencyZip]'" /></label>
                      <input
                        id="agency_zip"
                        v-model="form.zip"
                        type="text"
                        class="form-control"
                      >
                    </div>

                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group">
                      <label for="agency_county">County <merge-tag :merge_tag="'[AgencyCounty]'" /></label>
                      <input
                        id="agency_county"
                        v-model="form.county"
                        type="text"
                        class="form-control"
                      >
                    </div>

                  </div><!--Col-->

                </div><!--Row-->

                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label for="latitude">Latitude</label>
                      <input
                          id="latitude"
                          v-model="form.latitude"
                          type="text"
                          class="form-control"
                          :disabled="processing"
                      >
                    </div>
                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label for="longitude">Longitude</label>
                      <input
                          id="longitude"
                          v-model="form.longitude"
                          type="text"
                          class="form-control"
                          :disabled="processing"
                      >
                    </div>
                  </div><!--Col-->

                </div><!--Row-->

                <div class="form-group">
                  <label for="timezone">Time Zone <merge-tag :merge_tag="'[TimeZone]'" /></label>
                  <select
                    id="timezone"
                    v-model="form.timezone"
                    class="form-control"
                  >
                    <option
                      v-for="timezone in timezones"
                      :key="timezone.key"
                      :value="timezone.key"
                    >
                      {{ timezone.label }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="languages">Languages 
                    <template v-if="form.languages && form.languages.length">
                      <span class="badge badge-primary badge-pill">{{ form.languages.length }}</span>
                    </template>
                  </label>
                  <multi-select
                    id="languages"
                    v-model="form.languages"
                    placeholder="Choose languages"
                    :close-on-select="false"
                    :multiple="true"
                    :options="languages"
                    label="label"
                    track-by="label"
                  />

                  <button
                    type="button"
                    class="btn btn-secondary-outline"
                    @click="addLanguages"
                  >
                    Add All
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger-outline"
                    @click="removeLanguages"
                  >
                    Remove All
                  </button>
                </div>

                <div class="row my-3">

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group">
                      <label for="logo">Logo URL</label>
                      <input id="logo" type="text" class="form-control" v-model="form.logo">
                    </div>

                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group">
                      <label for="icon">Icon URL</label>
                      <input id="icon" type="text" class="form-control" v-model="form.icon">
                    </div>

                  </div><!--Col-->

                </div><!--Row-->
                                
                <div class="mt-3 mt-md-0">
                  <p class="m-0">
                    <strong>Business Hours</strong>
                  </p>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group vbTimePicker">
                        <label for="business_from_time">From Time</label>
                        <input
                          id="business_from_time"
                          v-model="form.business_from_time"
                          placeholder="hh:mm AM"
                          type="time"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group vbTimePicker">
                        <label for="business_to_time">To Time</label>
                        <input
                          id="business_to_time"
                          v-model="form.business_to_time"
                          placeholder="hh:mm AM"
                          type="time"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="business_days">Business Days</label>
                  <multi-select 
                    id="business_days"
                    v-model="form.business_days" 
                    :options="business_days"
                    :multiple="true"
                    :close-on-select="false"
                  />
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="footer_text">Footer Text</label>
                      <input
                        id="footer_text"
                        v-model="form.footer_text"
                        type="text"
                        class="form-control"
                      >
                    </div>
                  </div><!--Col-->

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="help_desk">Internal Help Desk</label>
                      <input
                        id="help_desk"
                        v-model="form.help_desk"
                        type="text"
                        class="form-control"
                        placeholder="help@court.gov"
                      >
                    </div>
                  </div><!--Col-->
                </div><!--Row-->


                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="message_delay">Default Message Delay</label>
                      <input
                        id="message_delay"
                        v-model="form.message_delay"
                        type="number"
                        class="form-control"
                        min="0"
                        step="1"
                      >
                      <small class="form-text text-muted">In minutes. Defaults to 5.</small>
                    </div>
                  </div>

                </div><!--Row-->

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="waitlist_cutoff">Waitlist Cutoff</label>
                      <input
                        id="waitlist_cutoff"
                        v-model="form.waitlist_cutoff"
                        type="number"
                        class="form-control"
                      >
                      <small class="form-text text-muted">In hours.</small>
                    </div>
                  </div><!--Col-->

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="issues_email">Issues Emails</label>
                      <input
                        id="issues_email"
                        v-model="form.issues_email"
                        type="text"
                        class="form-control"
                      >
                    </div>
                    <small class="form-text text-muted">Comma-separated list of emails</small>
                  </div><!--Col-->
                </div><!--Row-->

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="client_display">Client Display Name</label>
                      <select
                        id="client_display"
                        v-model="form.client_display"
                        class="form-control"
                      >
                        <option value="" />
                        <option
                          v-for="display in options.client_displays"
                          :key="display.key"
                          :value="display.key"
                        >
                          {{ display.label }}
                        </option>
                      </select>
                    </div>
                  </div><!--Col-->


                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="client_required_fields">Add Client Required Fields</label><br>
                      <multi-select 
                        id="client_required_fields"
                        v-model="form.required_fields" 
                        :options="options.required_fields"
                        label="label"
                        track-by="key"
                        :multiple="true"
                        :close-on-select="false"
                      />
                    </div>
                  </div><!--Col-->
                </div><!--Row-->


                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group" :hidden="form.disable_optout">
                      <label for="optout_keywords">Opt-out Keywords</label>
                      <input
                        id="optout_keywords"
                        v-model="form.optout_keywords"
                        type="text"
                        class="form-control"
                      >
                      <small class="form-text text-muted">Comma-separated. Default: stop, unsubscribe, cancel, quit, wrong number</small>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3">
                      <input
                        id="disable_optout"
                        v-model="form.disable_optout"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="disable_optout"
                      > Disable Opt-out by Keywords</label>
                    </div>

                  </div><!--Col-->

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="client_duplicates">Client Duplicates</label><br>
                      <multi-select 
                        id="client_duplicates"
                        v-model="form.client_duplicates" 
                        :options="options.required_fields"
                        label="label"
                        track-by="key"
                        :multiple="true"
                        :close-on-select="false"
                      />
                    </div>
                  </div><!--Col-->
                </div><!--Row-->

                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label for="payment_due_date">Default Payment Due Date</label>
                      <input
                        id="payment_due_date"
                        v-model="form.payment_due_date"
                        type="number"
                        class="form-control"
                      >
                      <small class="form-text text-muted">In days. Defaults to 30.</small>
                    </div>

                    <div class="form-group">
                      <label for="payment_status">Default Payment Status</label>
                      <select id="payment_status" class="form-control" v-model="form.payment_status">
                        <option value="draft">draft</option>
                        <option value="outstanding">outstanding</option>
                      </select>
                      <small class="form-text text-muted">Defaults to draft.</small>
                    </div>
                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label for="event_duration">Default Event Duration</label>
                      <input
                        id="event_duration"
                        v-model="form.event_duration"
                        type="number"
                        class="form-control"
                      >
                      <small class="form-text text-muted">In minutes. Defaults to 60.</small>
                    </div>

                    <div class="form-group">
                      <label for="payment_type">Default Payment Type</label>
                      <input id="payment_type" class="form-control" type="text" v-model="form.payment_type">
                    </div>
                  </div><!--Col-->
                </div><!--Row-->

                <div class="form-group">
                  <label for="cms_url">Case Management System URL</label>
                  <input
                    id="cms_url"
                    v-model="form.cms_url"
                    class="form-control"
                  >
                </div>

                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="allow_transfers_from">Allow Transfers From</label>
                      <input
                        id="allow_transfers_from"
                        v-model="form.allow_transfers_from"
                        class="form-control"
                      >
                      <small class="form-text text-muted">Comma-separated agency UUIDs</small>
                    </div>
                  </div><!--Col-->

                  <div class="col-4">
                    <div class="form-group">
                      <label for="allow_transfers_to">Allow Transfers To</label>
                      <input
                        id="allow_transfers_to"
                        v-model="form.allow_transfers_to"
                        class="form-control"
                      >
                      <small class="form-text text-muted">Comma-separated agency UUIDs</small>
                    </div>
                  </div><!--Col-->

                  <div class="col-4">
                    <div class="form-group">
                      <label for="transfers_notify">Notify on Transfers</label>
                      <input
                        id="transfers_notify"
                        v-model="form.transfers_notify"
                        class="form-control"
                      >
                      <small class="form-text text-muted">Comma-separated emails and phones</small>
                    </div>
                  </div><!--Col-->
                </div><!--Row-->

                <div class="row">
                  <div class="col-sm-12 col-lg-6">
                    <div class="form-group">
                      <label for="geo_denied_notify">Notify on Geolocation Denied</label>
                      <input
                        id="geo_denied_notify"
                        v-model="form.geo_denied_notify"
                        class="form-control"
                      >
                      <small class="form-text text-muted">Comma-separated emails and phones</small>
                    </div>
                  </div><!--Col-->

                  <div class="col-sm-12 col-lg-6">
                    <div class="form-group">
                      <label for="default_bulk_actions_limit">Default Bulk Actions Limit</label>
                      <input
                        id="default_bulk_actions_limit"
                        v-model="form.default_bulk_actions_limit"
                        type="number"
                        class="form-control"
                      >
                      <small class="form-text text-muted">Defaults to 250</small>
                    </div>
                  </div><!--Col-->

                </div><!--Row-->

                <div class="row">

                  <div class="col-sm-12 col-lg-6">
                    <div class="form-group">
                      <label for="auto_trash_archived">Auto-Trash Archived Data</label>
                      <input
                        id="auto_trash_archived"
                        v-model="form.auto_trash_archived"
                        type="number"
                        class="form-control"
                        step="1"
                      >
                      <small class="form-text text-muted">In number of days since a record is archived. Defaults to never.</small>
                    </div>
                  </div><!--Col-->

                  <div class="col-sm-12 col-lg-6">
                    <div class="form-group">
                      <label for="auto_delete_trashed">Auto-Delete Trashed Data</label>
                      <input
                        id="auto_delete_trashed"
                        v-model="form.auto_delete_trashed"
                        type="number"
                        class="form-control"
                        step="1"
                        min="1"
                        max="180"
                      >
                      <small class="form-text text-muted">In number of days since a record is trashed. Defaults to 30.</small>
                    </div>
                  </div><!--Col-->

                  </div><!--Row-->

                  <div class="form-group">
                    <label for="default_groups">Default Groups</label>
                    <input id="default_groups" type="text" class="form-control" v-model="form.default_groups">
                    <small class="text-muted">Comma-separated list of groups. Example: 1, 2, 3</small>
                  </div>

                  <div class="form-group">
                    <label for="default_judge">Primary Judge Name</label>
                    <input
                      id="default_judge"
                      v-model="form.default_judge"
                      type="text"
                      class="form-control"
                    >
                    <small class="text-muted">Default Judge name to use for new events if no Judge is provided.</small>
                  </div>

                  <div class="form-group">
                    <label for="default_judges">Available Judge Names</label>
                    <input id="default_judges" type="text" class="form-control" v-model="form.default_judges">
                    <small class="text-muted">Comma-separated list of Judge names. Example: Lincoln, George, Roberts</small>
                  </div>

                <div class="form-group">
                  <label for="virtual_platform">Virtual Platform</label>
                  <select id="virtual_platform" class="form-control" v-model="form.virtual_platform" :disabled="processing">
                    <option value=""></option>
                    <template v-for="(virtual_platform, platform_index) in virtual_platforms">
                      <option :key="'platform_'+platform_index" :value="virtual_platform">{{ virtual_platform }}</option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="virtual_link">Virtual Base Link</label>
                  <input id="virtual_link" type="text" class="form-control" v-model="form.virtual_link">
                </div>

                <div class="row">

                  <div class="col-4">
                    <div class="form-group custom-control custom-switch mt-3">
                      <input
                        id="geocode_addresses"
                        v-model="form.geocode_addresses"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="geocode_addresses"
                      >Geocode Addresses</label>
                    </div>
                  </div><!--Col-->

                  <div class="col-4">
                    <div class="form-group custom-control custom-switch mt-3">
                      <input
                        id="assign_clients_creator"
                        v-model="form.assign_clients_creator"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="assign_clients_creator"
                      >Assign Clients to Creator</label>
                    </div>
                  </div><!--Col-->

                  <div class="col-4">
                    <div class="form-group custom-control custom-switch mt-3">
                      <input
                        id="disable_weekend_events"
                        v-model="form.disable_weekend_events"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="disable_weekend_events"
                      >Disable Messages for Weekend Events</label>
                    </div>
                  </div><!--Col-->
                </div><!--Row-->

                <div class="row">

                  <div class="col-4">
                    <div class="form-group custom-control custom-switch mt-3">
                      <input
                        id="detect_language"
                        v-model="form.detect_language"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="detect_language"
                      >Detect Language</label>
                    </div>
                  </div><!--Col-->

                  <div class="col-4">
                    <div class="form-group custom-control custom-switch mt-3">
                      <input
                        id="auto_client_reference"
                        v-model="form.auto_client_reference"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="auto_client_reference"
                      >Auto-Generate Client Reference</label>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3">
                      <input
                          id="auto_lookups"
                          v-model="form.auto_lookups"
                          type="checkbox"
                          class="custom-control-input"
                      >
                      <label
                          class="custom-control-label"
                          for="auto_lookups"
                      >Automatically Run Lookups</label>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3">
                      <input
                          id="auto_replace_links"
                          v-model="form.auto_replace_links"
                          type="checkbox"
                          class="custom-control-input"
                      >
                      <label
                          class="custom-control-label"
                          for="auto_replace_links"
                      >Automatically Replace Links</label>
                    </div>

                  </div><!--Col-->

                </div><!--Row-->

                <div class="row">

                  <div class="col-sm-12 col-md-4">

                    <div class="form-group custom-control custom-switch mt-3">
                      <input
                          id="enable_chat"
                          v-model="form.enable_chat"
                          type="checkbox"
                          class="custom-control-input"
                      >
                      <label
                          class="custom-control-label"
                          for="enable_chat"
                      >Enable Chat</label>
                    </div>

                  </div><!--Col-->

                  <div class="col-sm-12 col-md-4">

                  </div><!--Col-->

                </div><!--Row-->

                <div class="form-group custom-control custom-switch mt-3">
                  <input
                      id="enable_checkins"
                      v-model="form.enable_checkins"
                      type="checkbox"
                      class="custom-control-input"
                  >
                  <label
                      class="custom-control-label"
                      for="enable_checkins"
                  >Enable Check-ins</label>
                </div>

                <template v-if="form.enable_checkins">

                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="form-group">
                        <label for="checkins_earliest">Earliest Check-in</label>
                        <input id="checkins_earliest" type="number" class="form-control" v-model="form.checkins_earliest">
                        <p class="form-text text-muted">The earliest checkin allowed for a scheduled event. Defaults to 24 hours prior the scheduled event date.</p>
                      </div>
                    </div><!--Col-->

                    <div class="col-sm-12 col-md-6">
                      <div class="form-group">
                        <label for="checkins_latest">Latest Check-in</label>
                        <input id="checkins_latest" type="number" class="form-control" v-model="form.checkins_latest">
                        <p class="form-text text-muted">The earliest checkin allowed for a scheduled event. Defaults to 24 hours after the scheduled event date.</p>
                      </div>
                    </div><!--Col-->
                  </div>

                </template>

                <h4>Customize Fields</h4>

                <div class="row">

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group">
                      <label>Choose Field</label>
                      <select class="form-control" v-model="customField" @change="addCustomField()" :disabled="processing">
                        <template v-for="(field_option, option_index) in fields">
                          <option :key="'field_option_'+option_index" :value="option_index">{{ field_option.name }}</option>
                        </template>
                      </select>
                    </div>

                    <template v-for="(custom_field, custom_index) in form.fields">

                      <div :key="'custom_field_'+custom_index" class="form-group" :disabled="processing" required>
                        <label>{{ custom_field.key }}</label>
                        <input type="text" class="form-control" v-model="form.fields[custom_index].name">
                      </div>
                    </template>

                  </div><!--Col-->

                </div><!--Row-->


                <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

              </div>
            </div>

          </form>

          <div class="row mt-3">
            <div class="col-sm-12 col-md-6">
              <button type="button" class="btn btn-danger" @click="resetSettings()" :disabled="processing">Reset {{  $route.meta.title }}</button>
            </div><!--Col-->

            <div class="col-sm-12 col-md-6">

              <template v-if="form && form.created_at">
                <audit-logs class="mt-3" :form="form" />
              </template>

            </div><!--Col-->

          </div>

      </div>

      </div>
    </div>
  </div>
</template>
<script>
import queries from "../mixins/queries";
import jsonParse from '../mixins/jsonParse';

export default {
    
    mixins: [ queries, jsonParse ],

    data() {
        return {
          processing: false,
          languages: [],
          options: {
              required_fields: []
          },
          fields: [],
          form: {},
          send_modes: [
          {key: 'simulate', title: 'send simulate'},
          {key: 'live', title: 'send live'}
          ],
          timezones: [],
          tabs: [
          {key: 'general'},
          {key: 'agency_phones'},
          {key: 'agency_emails'},
          {key: 'statuses'},
          {key: 'roles'}
          ],
          business_days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
          customField: {},
          states: [],
          default_settings: {
            send_mode: 'live',
            business_from_time: '08:00',
            business_to_time: '18:00',
            business_days: ['mon', 'tue', 'wed', 'thu', 'fri'],
            geocode_addresses: 1,
            detect_language: 1,
            assign_clients_creator: 1,
            disable_weekend_events: 1,
            enable_chat: 1,
            enable_checkins: 1,
            auto_lookups: 1,
            languages: [
              {key: 'en', label: 'English'},
              {key: 'es', label: 'Español (spanish)'}
            ]
          },
          virtual_platforms: ['Zoom', 'WebEx', 'Teams', 'GoToMeeting', 'Google Meet', 'Skype', 'Other'],
          phones: [],
          emails: []
        }
    },

    computed: {

      superAdmin () {
          return this?.$root?.current_user?.super_admin;
      }

    },

      mounted() {

        var self = this;

        this.$eventBus.$on('settingsProcessing', function (data) {
         self.processing = data;
        });

        this.$eventBus.$on('settings', function (data) {
          self.form = {...self.form, ...data};
        });

      },

    created() {
        
        this.resetForm();
        this.setURLParams();
        this.getOptions();
        this.getFields();
        this.getLanguages();
        this.getStates();
        this.getSettings();
        this.getTimezones();
        this.getPhones();
        this.getEmails();
    },


    methods: {

    resetForm() {
      this.form = {
        timezone: 'UTC',
        languages: [],
        fields: [],
        phone: null,
        email: null,
        website: null,
        address: null,
        address_2: null,
        city: null,
        state: null,
        county: null,
        zip: null,
        country: null,
        business_from_time: null,
        business_to_time: null,
        business_days: [],
        latitude: null,
        longitude: null,
        send_mode: null,
        virtual_link: null,
        virtual_platform: null
      }
    },
    
    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

    },

    addCustomField() {
      
      if(!this.form.fields) {
        this.form.fields = [];
      }

      var field = {};

      if(!this.fields[this.customField].name) {
        return;
      }

      field.name = this.fields[this.customField].name;
      field.key = this.fields[this.customField].key;

      this.form.fields.push(field);
    },


        getLanguages () {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
          .then(response => {
              if(response && response.data) {
                  this.languages = response.data;
              }
          })
      },

        getOptions () {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
          .then(response => {
              if(response && response.data) {
                  this.options = response.data;
              }
          })
      },

      getFields() {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/fields.json')
        .then(response => {
          if(response && response.data) {
            this.fields = response.data;
          }
        })
      },
    
      getTimezones() {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/timezones.json')
        .then(response => {
            if(response && response.data) {
                this.timezones = response.data;
            }
        })
    },

    getStates () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/states.json')
      .then(response => {
        if (response && response.data) {
          this.states = response.data;

          if(this.$root.current_user && this.$root.current_user.current_agency) {
            this.form.name = this.$root.current_user.current_agency.name;
          }
        }
      })
    },

    getSettings() {
      this.processing = true;
        this.$http.get('/'+this.$route.meta.base_url)
        .then(response => {
            if(response && response.data) {
                this.form = response.data;

                this.setTitle();

                this.getGeocodes();

                this.processing = false;
            }
        })
    },

      setTitle() {
        if(this.form.name) {
          document.title = this.form.name + ' | Agency ' + this.$route.meta.title;
        }
      },

      getGeocodes() {

      if(this.form.latitude && this.form.longitude) {
        return;
      }

      if(!this.form.address) {
        return;
      }

      var queries = {
        address: this.form.address,
        city: this.form.city,
        state: this.form.state,
        zip_code: this.form.zip
      }

        this.$http.get(this.buildQueries('/geocode', queries))
            .then(response => {
              if(response && response.data && response.data[0]) {

               var result = response.data[0];

               if(!result['geometry']['location'] ||  !result['geometry']['location']['lat'] || !result['geometry']['location']['lng']) {
                  return;
               }

               this.form.latitude = result['geometry']['location']['lat'];
               this.form.longitude = result['geometry']['location']['lng'];
               this.postForm();

              }
            })
      },

        postForm() {

            if(this.$route.query.tab && this.$route.query.tab != 'general') {
                return;
            }

            this.processing = true;

            if(this.$root.current_region == 'staging') {
                this.form.send_mode = 'simulate';
            }

            this.$http.patch('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if(response && response.data) {

                    this.processing = false;

                    if(response.data.updated_at) {
                        this.$root.current_user.current_agency.settings = response.data;
                        localStorage.setItem('settings', JSON.stringify(response.data));
                        new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                        this.form.updated_at = response.data.updated_at;
                    }

                }
            })
        },

        setSettings() {

            if(this.form.settings && this.form.settings.length > 5) {
                let settings = this.parseJSON(this.form.settings)
                this.form = {...this.form, ...settings}
                delete this.form.settings
            }

            if (this.form.business_from_time) {
                this.form.business_from_time = this.$options.filters.formatFormTime(this.form.business_from_time)
            }

            if (this.form.business_to_time) {
                this.form.business_to_time = this.$options.filters.formatFormTime(this.form.business_to_time)
            }

            if(this.form.languages) {
                this.form.languages = this.parseJSON(this.form.languages);
            }
        },

        addLanguages() {
            if(this.languages) {
                this.form.languages = this.languages;
            }
            
        },

        removeLanguages() {
            this.form.languages = []
        },

      resetSettings() {

        this.form = {...this.form, ...this.default_settings};

        this.form.timezone = this.$moment.tz.guess();

        if(['dev', 'staging'].includes(this.$root.current_region)) {
          this.form.send_mode = 'simulate';
        }

        this.postForm();
      },

      getPhones() {
      this.$http.get('/agency_phones?slim=true&fields=phone_number,uuid&limit=100&sort=name&sortDirection=desc')
          .then(response => {
            if(response && response.data) {
              this.phones = response.data;
            }
          })
      },

      getEmails() {
        this.$http.get('/agency_emails?slim=true&fields=email,uuid&limit=100&sort=name&sortDirection=desc')
            .then(response => {
              if(response && response.data) {
                this.emails = response.data;
              }
            })
      }
    }
}
</script>