<template>
  <div>
    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row mb-3">
      <div class="col-sm-12 col-md-6">
        <template v-if="form.uuid">
          <geolocation-status :client="form.uuid" />
        </template>
        
        <template v-if="form.name">
          <h1 class="m-0" id="clientName">
            {{ form.name }}
          </h1>
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{  $route.meta.title }}</p>
        </template>
        
        <template v-if="!form.name">
          <h2 class="m-0">
            {{ form.first_name }} {{ form.middle_name }} {{ form.last_name }}
          </h2>
        </template>

        <template v-if="form.status">
          <disable-status-button :status="form.status"></disable-status-button>
        </template>

        <template v-if="form.client_reference">
          <span
            id="clientReference"
            class="badge badge-pill badge-secondary"
            @click="copyToClipboard"
          >{{ form.client_reference }}</span>
        </template>

        <template v-if="form.unreachable === true">
          <span class="badge badge-pill badge-danger text-uppercase">Unreachable</span>
        </template>
      </div><!--Col-->
      <div class="col-sm-6 col-md-2">

        <div class="dropdown">
              <button
                type="button"
                id="filesButton"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                v-if="files && files.length"
              >
                Send File
              </button>
              <div class="dropdown-menu">
                <h6 class="dropdown-header">
                  Send File
                </h6>
                <template v-for="file in files">
                  <a
                    :key="file.uuid"
                    class="dropdown-item"
                    :href="'/files/'+file.uuid+'?client='+form.uuid"
                    target="_blank"
                  >{{ file.name }}</a>
                </template>
              </div>
        </div><!--Dropdown-->

    </div><!--Col-->

      <div class="col-sm-6 col-md-2">

        <div class="dropdown">

              <button
                type="button"
                id="autoMessagesButton"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                v-if="auto_messages && auto_messages.length"
              >
                Send Auto Message
              </button>
              <div class="dropdown-menu">
                <h6 class="dropdown-header">
                  Send Auto Message
                </h6>
                <template v-for="auto_message in auto_messages">
                  <a
                    :key="auto_message.uuid"
                    class="dropdown-item"
                    @click.prevent="sendAutoMessage(auto_message.uuid)"
                  >{{ auto_message.name }}</a>
                </template>
              </div>

        </div><!--Dropdown-->

    </div><!--Col-->

    <div class="col-sm-6 col-md-2 text-right">

      <div class="btn-group">
            <button
              type="button"
              class="btn btn-secondary dropdown-toggle radius-right-0"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :class="isHidden('clients_tools')"
            >
              Tools
            </button>
            <div class="dropdown-menu">

              <a
                v-if="!form.archived_at && !form.deleted_at"
                class="dropdown-item"
                :class="isHidden('clients_sync')"
                @click="syncRecord"
              >Sync Messages</a>

              <a
                v-if="!form.archived_at && !form.deleted_at"
                class="dropdown-item"
                :class="isHidden('clients_archive')"
                @click="archiveRecord"
              >Archive</a>
              <a
                v-if="form.archived_at || form.deleted_at"
                class="dropdown-item"
                :class="isHidden('clients_restore')"
                @click="restoreRecord"
              >Restore</a>
              <a
                v-if="!form.deleted_at && !form.archived_at"
                class="dropdown-item"
                :class="isHidden('clients_trash')"
                @click="trashRecord"
              >Trash</a>
              <a
                class="dropdown-item"
                data-target="#mergeClientModal"
                data-toggle="modal"
                :class="isHidden('clients_merge')"
              >Merge</a>
              <a
                class="dropdown-item"
                data-target="#transferClientModal"
                data-toggle="modal"
                :class="isHidden('clients_transfer')"
              >Transfer</a>

            </div>

          <button
            type="button"
            class="btn btn-success"
            :disabled="processing"
            :class="isHidden('clients_save')"
            @click="saveClient()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

      </div><!--Group-->

    </div><!--Col-->

    </div><!--Row-->
    <div class="row">
      <div class="col-lg-2 col-md-3">
        <template v-if="form.uuid">
          <client-tabs :uuid="form.uuid" />
        </template>
      </div><!--Col-->
      <div class="col-lg-10 col-md-9">
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="card">
                <div class="card-body">

                  <p class="card-title">Client Details</p>

                  <form
                    @keyup.enter="saveClient"
                    @submit.prevent="saveClient"
                  >
                    <div class="row">
                      <div class="col-4">
                        <div class="form-group">
                          <label for="client_reference">{{ setFieldTitle('client_reference') }}</label>
                          <input
                            id="client_reference"
                            v-model="form.client_reference"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->
                      <div class="col-4">
                        <div class="form-group">
                          <label for="group">Group
                            <button type="button" class="btn btn-light btn-sm p-0" @click="showNewGroup" data-tooltip="Edit Group"><i class="fa fa-edit"></i></button>
                          </label>
                          <template v-if="!show_new_group">
                          <select
                            id="group"
                            class="form-control"
                            v-model="form.group"
                            :disabled="processing">
                          <option value=""></option>
                          <template v-for="(client_group, group_index) in clientGroups">
                            <option :key="'client_group_'+group_index" :value="client_group">{{ client_group }}</option>
                          </template>
                          </select>
                          </template>
  
                          <template v-if="show_new_group">

                            <input id="group" type="text" class="form-control" v-model="form.group">

                          </template>

                        </div>

                      </div><!--Col-->
                      <div class="col-4">
                        <div class="form-group">
                          <label for="type">{{ setFieldTitle('type') }}</label>
                          <input
                            id="type"
                            v-model="form.type"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                    </div><!--Row-->

                    <div class="form-group">
                      <label for="name">Name</label>
                      <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        class="form-control"
                      >
                    </div>

                    <div class="row">
                      <div class="col-4">
                        <div class="form-group text-capitalize">
                          <label for="first_name">{{ setFieldTitle('first_name') }}</label>
                          <input
                            id="first_name"
                            v-model="form.first_name"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->
                      <div class="col-4">
                        <div class="form-group text-capitalize">
                          <label for="middle_name">{{ setFieldTitle('middle_name') }}</label>
                          <input
                            id="middle_name"
                            v-model="form.middle_name"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->
                      <div class="col-4">
                        <div class="form-group text-capitalize">
                          <label for="last_name">{{ setFieldTitle('last_name') }}</label>
                          <input
                            id="last_name"
                            v-model="form.last_name"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->
                    </div><!--Row-->

                    <div class="form-group">
                      <label for="aliases">Aliases</label>
                      <input id="aliases" type="text" class="form-control" v-model="form.aliases">
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group vbDatePicker text-uppercase">
                          <label for="dob">{{ setFieldTitle('dob') }} <small>{{ age }}</small></label>
                          <input
                            id="dob"
                            v-model="form.dob"
                            placeholder="mm/dd/yyyy"
                            type="date"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->
                      <div class="col-md-6">
                        <div class="form-group text-capitalize">
                          <label for="language">{{ setFieldTitle('language') }}</label>
                          <select
                            id="language"
                            v-model="form.language"
                            class="form-control"
                          >
                            <option
                              v-for="language in languages"
                              :key="language.key"
                              :value="language.key"
                            >
                              {{ language.label }}
                            </option>
                          </select>
                        </div>
                      </div><!--Col-->
                    </div><!--Row-->

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="birth_place">Birth Place</label>
                          <input
                            id="birth_place"
                            v-model="form.birth_place"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->

                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="birth_country">Birth Country</label>
                          <select
                            id="birth_country"
                            v-model="form.birth_country"
                            class="form-control"
                          >
                            <option value="" />
                            <template v-if="countries && countries.length > 0">
                              <template v-for="(country, index) in countries">
                                <option
                                  :key="'country_'+index"
                                  :value="country.code"
                                >
                                  {{ country.code }} - {{ country.name }}
                                </option>
                              </template>
                            </template>
                          </select>
                        </div>
                      </div><!--Col-->
                    </div><!--Row-->

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="citizenship">Citizenship</label>
                          <select
                            id="citizenship"
                            v-model="form.citizenship"
                            class="form-control"
                          >
                            <option value="" />
                            <template v-if="countries && countries.length > 0">
                              <template v-for="(country, index) in countries">
                                <option
                                  :key="'country_'+index"
                                  :value="country.code"
                                >
                                  {{ country.code }} - {{ country.name }}
                                </option>
                              </template>
                            </template>
                          </select>
                        </div>
                      </div><!--Col-->

                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="race">Race</label>
                          <select
                            id="race"
                            v-model="form.race"
                            class="form-control"
                          >
                            <option value="" />
                            <option
                              v-for="race in races"
                              :key="race.key"
                              :value="race.label"
                            >
                              {{ race.label }}
                            </option>
                          </select>
                        </div>
                      </div><!--Col-->
                    </div><!--Row-->

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="gender">Gender</label>
                          <select
                            id="gender"
                            v-model="form.gender"
                            class="form-control"
                          >
                            <option value="" />
                            <option
                              v-for="gender in genders"
                              :key="gender.key"
                              :value="gender.label"
                            >
                              {{ gender.label }}
                            </option>
                          </select>
                        </div>
                      </div><!--Col-->

                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="marital_status">Marital Status</label>
                          <select
                            id="marital_status"
                            v-model="form.marital_status"
                            class="form-control"
                          >
                            <option value="" />
                            <option value="single">
                              single
                            </option>
                            <option value="married">
                              married
                            </option>
                            <option value="divorced">
                              divorced
                            </option>
                          </select>
                        </div>
                      </div><!--Col-->
                    </div><!--Row-->

                    <div class="form-group">
                      <label for="status">Status</label>
                      <input id="status" class="form-control" v-model="form.status">
                    </div>

                    <div class="form-group">
                      <label for="assigned_users">Assigned Users</label>
                      <multi-select
                        id="assigned_users"
                        v-model="form.users"
                        placeholder="Choose users"
                        :options="users"
                        label="name"
                        track-by="uuid"
                        :multiple="true"
                        :close-on-select="false"
                      />
                    </div>

                    <div class="form-group">
                      <label for="notes">Internal Notes</label>
                      <textarea
                        id="notes"
                        v-model="form.notes"
                        type="text"
                        class="form-control"
                        rows="3"
                      />
                    </div>
                  </form>
                </div><!--CardBody-->
              </div><!--Card-->

              <template v-if="form && form.uuid">

                <div class="card my-3">
                  <div class="card-body">
                    <p class="card-title">Identity Details</p>

                    <div class="form-group">
                      <div class="custom-file">
                        <input
                            id="photosUpload"
                            ref="photos"
                            type="file"
                            class="custom-file-input"
                            accept="image/*"
                            aria-describedby="photosUpload"
                            @change="storePhotos()"
                            multiple="true"
                            :disabled="uploadProgress !== null || processing"
                        >
                        <label
                            class="custom-file-label"
                            for="photosUpload"
                        >Upload Photos</label>
                      </div>
                    </div>

                    <template v-if="processing && uploadProgress">
                      <div class="progress">
                        <div
                            class="progress-bar"
                            role="progressbar"
                            :style="'width:'+uploadProgress+'%'"
                        >
                          {{ uploadProgress }}%
                        </div>
                      </div>
                    </template>

                    <template v-if="photos && photos.length > 0">

                      <div class="row">
                        <div class="col-sm-12 col-md-6">
                          <p>{{ photos.length }} Photos</p>
                        </div><!--Col-->

                        <div class="col-sm-12 col-md-6 text-right">
                          <div class="btn-group">
                            <button type="button" class="btn btn-secondary btn-sm" @click="getPhotos()" data-tooltip="Refresh photos" :disabled="processing"><i class="fa fa-refresh"></i></button>
                            <button type="button" class="btn btn-danger btn-sm" @click="resetPhotos()" :data-tooltip="'Delete '+photos.length + ' photos'" :disabled="processing"><i class="fa fa-trash-can-list"></i></button>
                          </div>
                        </div><!--Col-->

                      </div>

                        <template v-for="(photo, index) in photos">
                          <div
                            :key="'photo_'+index"
                            class="my-3"
                          >
                            <a :href="photo.url" target="_blank">
                            <img
                              :src="photo.url"
                              width="100%"
                              class="img-fluid"
                            >
                            </a>

                           <button type="button" class="btn btn-danger btn-sm float-right" @click="deletePhoto(photo)" data-tooltip="Delete photo" :disabled="processing"><i class="fa fa-trash"></i> </button>

                          </div>
                        </template>

                    </template>

                    <p class="mt-3"><a :href="'/identities?client='+form.uuid" class="text-decoration-none mb-3">{{ identities.length }} Identities</a></p>

                    <template v-if="identities && identities.length">

                    <template v-for="(identity, index) in identities">
                      <a :key="'identity_'+index" :href="'/identities/'+identity.uuid" class="list-group-item list-group-item-action">{{ identity.type }} {{ identity.reference }}</a>
                    </template>

                    </template>

                    <template v-else>
                      <div class="alert alert-warning">No identities found.</div>
                    </template>

                  </div>
                </div>


                <audit-logs class="mt-3" :form="form" />
              </template>
            </div><!--Col-->
            <div class="col-md-12 col-lg-6">
              <template v-if="form && form.uuid">
                <client-contacts
                  :client_uuid="form.uuid"
                  class="mb-3"
                />

                <messages-form
                  :client="form"
                  class="mb-3"
                />

                <messages-scheduled
                  :client_uuid="form.uuid"
                  class="mb-3"
                />
                <messages-feed :client_uuid="form.uuid" />
                
              </template>
            </div>
          </div><!--Row-->
      </div><!--Col-->
    </div><!--Row-->


    <div
      id="mergeClientModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog modal-xl modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Merge Client
            </h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <form @submit.prevent="postMergeClient">
            <div class="modal-body">
              <div class="form-group">
                <label for="from_client">From Client UUID</label>
                <input
                  id="from_client"
                  v-model="merge_form.from_client"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="to_client">To Client UUID</label>
                <input
                  id="to_client"
                  v-model="merge_form.to_client"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <template v-if="merge_form.from_client && merge_form.to_client">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="flipMerge"
                >
                  <i class="fa fa-repeat" /> Flip Merge
                </button>

                <div class="alert alert-warning mt-3">
                  All data associated to client: {{ merge_form.from_client }} will be merged to <a
                    :href="'/clients/'+merge_form.to_client"
                    target="_blank"
                  >{{ merge_form.to_client }}</a>
                </div>
              </template>

              <template v-if="merge_results && merge_results.length > 0">
                <p class="lead m-0">
                  Merge Results
                </p>

                <ul class="list-group">
                  <template v-for="(merge_result, index) in merge_results">
                    <li
                      :key="'merge_result_'+index"
                      class="list-group-item"
                    >
                      <code>{{ merge_result.count }}</code> {{ merge_result.table }} merged
                    </li>
                  </template>
                </ul>
              </template>
            </div>

            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-outline-success"
                :disabled="processing"
              >
                Merge Client
              </button>
            </div>
          </form>
        </div>
      </div>
    </div><!--Modal-->



    <div
      id="transferClientModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog modal-xl modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Transfer Client
            </h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <form @submit.prevent="postTransferClient">
            <div class="modal-body">
              <template v-if="transfer_response">
                <div class="alert alert-success">
                  {{ transfer_response }}
                </div>
              </template>

              <div class="form-group">
                <label for="transfer_to_agency">To Agency</label>
                <input
                  id="transfer_to_agency"
                  v-model="transfer_form.to_agency"
                  class="form-control"
                >
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="transfer_archive"
                  v-model="transfer_form.archive"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="transfer_archive"
                >Archive After Transfer is Complete</label>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-outline-success"
                :disabled="transfering"
              >
                Transfer Client
              </button>
            </div>
          </form>
        </div>
      </div>
    </div><!--Modal-->
  </div>
</template>

<script>
import _ from 'lodash';
import tabs from '../mixins/tabs';
import moment from 'moment';
import queries from '../mixins/queries';

export default {

  mixins: [ tabs, queries ],
  
  data () {
    return {
      form: {},
      processing: false,
      errors: {},
      auto_messages: [],
      files: [],
      languages: [],
      base_url: '/clients',
      users: [],
      genders: [
      {"label": "male", "key": "m"},
      {"label": "female", "key": "f"},
      {"label": "unspecified", "key": "u"},
      {"label": "non-binary", "key": "n"}
      ],
      races: [
      {"label": "white"},
      {"label": "african-american"},
      {"label": "hispanic"},
      {"label": "asian"}
      ],
      merge_form: {
        from_client: null,
        to_client: null
      },
      transfer_form: {
        to_agency: null,
        archive: true,
        client: null
      },
      transfer_response: null,
      transfering: false,
      merge_results: [],
      countries: [],
      client_groups: [],
      show_new_group: false,
      queries: {},
      identities: [],
      photos: [],
      uploadProgress: null,
    }
  },
  computed: {

    age: function () {
      if (this.form.dob) {
        var dob = moment(this.form.dob, 'YYYY-MM-DD', true).isValid();
        if (dob) {
          return moment().diff(this.form.dob, 'years') + ' years old';
        }
      }
      return '';
    },

    clientGroups: function() {

      if(!this.$root.current_user || !this.$root.current_user.current_agency) {
        return;
      }

      var groups = [];

      if(this.$root.current_user.current_agency.settings && this.$root.current_user.current_agency.settings.default_groups) {
        groups = this.$root.current_user.current_agency.settings.default_groups;

        groups = groups.split(",");

        groups = groups.map(g => g.trim());
      }

      var client_groups = [];

      if(this.client_groups && this.client_groups.length > 0) {
        client_groups = this.client_groups.map(r => r.group);

        client_groups.forEach(g => {
          if(!groups.includes(g)) {
            groups.push(g);
          }
        })
      }

      if(!groups) {
        return [];
      }

      return groups.map(g => g.trim());
    }

  },

  mounted() {

    var self = this;
    
    this.$eventBus.$on('clients', function (data) {
      self.updateRecord(data);
    });
    
  },

  created () {
    this.resetQueries();
    this.setURLParams();
    this.getRecord();
  },

  methods: {

    resetQueries() {
      this.queries = {
        ref: null
      }
    },

    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    isHidden(navigation) {
      
        if(!this.$root.current_user || !this.$root.current_user.uuid) {
            return true;
        }

        if(this.$root.current_user.hidden_navigations && this.$root.current_user.hidden_navigations.length > 0) {
            return this.$root.current_user.hidden_navigations.includes(navigation);
        }

        return false;
    },

    updateRecord(data) {
      if(!this.form.uuid || !data.uuid || !data.updated_at) {
        return;
      }

      if(data.uuid != this.form.uuid) {
        return;
      }

      this.form = {...this.form, ...data};

      this.setTitle();
    },

    getRecord () {
      this.processing = true;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/'+this.$route.params.id, this.queries))
      .then((response) => {
        if (response && response.data) {
          
          this.form = response.data;

          this.merge_form.from_client = this.form.uuid;

          this.resetQueries();

          this.setTitle();

          this.processing = false;

          this.getOptions();

          this.getIDs();

          this.getPhotos();

        }
      })
    },

    setTitle() {

        var title = this.form.name || 'Client';

        if(this.form.client_reference) {
          title = title + ' ' + this.form.client_reference;
        }

        document.title = title + ' | Edit Client';
    },

    getOptions() {
      this.getCountries();
      this.getAutoMessages();
      this.getFiles();
      this.getLanguages();
      this.getUsers();
      this.getGroups();
    },

    saveClient () {

      this.$eventBus.$emit("saveClient");

      if(this.$route.query.tab && this.$route.query.tab != 'overview') {
          return;
      }

      this.processing = true;
      let form = {...this.form};
      if (this.form.users && this.form.users.length > 0) {
        form.users = this.form.users.map((u) => u.uuid);
      }
      this.$http.patch('/'+this.$route.meta.base_url+'/' + this.form.uuid, form)
      .then(response => {
        if (response && response.data) {
          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: 'Client updated'}).show();
          }
          
        }
      })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url+'/' + this.form.uuid)
      .then(response => {
        if(response && response.status === 204) {
          new this.$noty({text: this.$route.meta.title + ' trashed', type: 'error'}).show();
          this.form.deleted_at = new Date().toISOString();
          this.processing = false;
        }
      })  
    },

    syncRecord () {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/' + this.form.uuid + '/sync')
      .then(response => {
        if(response && response.status === 202) {
          new this.$noty({text: 'Sync client messages'}).show();
          this.processing = false;
        }
      })
    },

    getAutoMessages () {
      this.$http.get('/auto_messages?limit=1000&sort=name&sortDirection=asc&slim=true&fields=name,uuid')
      .then(response => {
        if(response && response.data) {
          this.auto_messages = response.data;
        }
      })
    },

    sendAutoMessage(auto_message) {
      this.processing = true;
      this.$http.post('auto_messages/'+auto_message+'/send?client='+this.form.uuid)
      .then((response) => {
        if(response && response.data) {
            new this.$noty({text: 'Sending message ' + response.data.content}).show();
            this.processing = false;
        }
      })
    },

    getFiles() {
        this.$http.get('/files?slim=true&limit=1000&sort=name&sortDirection=asc&fields=name,uuid')
        .then(response => {
            if(response && response.data) {
                this.files = response.data;
            }
        })
    },

    getUsers () {
      this.$http.get('/users?limit=1000&slim=y&fields=name,email,uuid&sort=name&sortDirection=asc')
      .then(response => {
        if (response && response.data) {
          this.users = response.data;         
        }
      })
    },

    archiveRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url +'/' + this.form.uuid + '/archive')
      .then(response => {
        if (response && response.status === 200) {

          if(response.data.message) {
            new this.$noty({text: response.data.message}).show();            
          }

          this.form.archived_at = new Date().toISOString();
        }
      })
    },

    restoreRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url +'/' + this.form.uuid + '/restore')
      .then(response => {
        if (response && response.status === 200) {
            this.processing = false;
          
            if(response.data.message) {
              new this.$noty({text: response.data.message}).show();            
            }

            this.getRecord();
        }
      })
    },

        getLanguages () {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
          .then(response => {
              if(response && response.data) {
                  this.languages = response.data;
              }
          })
      },

    copyToClipboard () {
      var range = document.createRange();
      range.selectNode(document.getElementById("clientReference"));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      new this.$noty({text: 'Client reference copied to clipboard'}).show();
    },

    postMergeClient() {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/merge', this.merge_form)
      .then(response => {
        if(response && response.data) {
          this.merge_results = response.data;
          new this.$noty({text: 'Merge client complete'}).show();
          this.processing = false;
        }
      })
    },

    flipMerge() {
      var from = this.merge_form.from_client || null;
      var to = this.merge_form.to_client || null;

      this.merge_form.to_client = from;
      this.merge_form.from_client = to;

      new this.$noty({text: 'Merge client flipped'}).show();
    },

    postTransferClient() {
      this.transfering = true;
      this.transfer_response = null;
      this.transfer_form.client = this.form.uuid;
      this.$http.post(this.base_url+'/transfer', this.transfer_form)
      .then(response => {
        if(response && response.data && response.data.message) {
          new this.$noty({text: response.data.message}).show();
          this.transfering = false;
          this.transfer_response = response.data.message;
          this.transfer_form.client = null;
        }
      })
    },

    getCountries() {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/country_codes.json')
        .then(response => {
            if(response && response.data) {
                this.countries = response.data;
            }
        })
    },

    getGroups() {
      this.$http.get('/client_groups')
      .then(response => {
        if(response && response.data) {
          this.client_groups = response.data;
        }
      })
    },

    showNewGroup() {
      if(this.show_new_group === true) {
        this.show_new_group = false;
        return;
      }

      this.show_new_group = true;
    },


    setFieldTitle(field) {

        var fields = localStorage.getItem('fields');

        if(!fields || !fields.length) {
          return this.$options.filters.rmDashes(field);
        }


        var title = _.find(JSON.parse(fields), { 'key': 'clients.'+field });

        if(!title || !title.name) {
          return this.$options.filters.rmDashes(field);
        }

        return title.name;
    },

    getIDs() {
      if(!this.form.uuid) {
        return;
      }

      this.$http.get('/identities?client='+this.form.uuid)
      .then(response => {
        if(response && response.data) {
          this.identities = response.data;
        }
      })
    },

    resetPhotos() {
      if(!this.form.uuid) {
        return;
      }

      this.processing = true;

      this.$http.delete('/clients/'+this.form.uuid+'/photos')
          .then(response => {
            if(response && response.status) {
              new this.$noty({text: 'Photos reset'}).show();
              this.photos = [];

              this.processing = false;
            }
          })
    },

    getPhotos() {
      if(!this.form.uuid) {
        return;
      }

      this.processing = true;

      this.$http.get('/clients/'+this.form.uuid+'/photos')
          .then(response => {
            if(response && response.data) {
              this.processing = false;
              this.photos = response.data;
            }
          })
    },

    storePhotos() {

      const files = this.$refs.photos.files;

      if(files && files.length > 0) {

        this.processing = true;

        for (let i = 0; i < files.length; i++) {

          let file = files.item(i);

          this.uploadPhoto(file, {
            progress: progress => {
              this.uploadProgress = Math.round(progress * 100);

              if(this.uploadProgress === 100) {
                new this.$noty({text: 'Photo uploaded'}).show();
                this.uploadProgress = null;
              }
            }
          }).then(response => {
            this.processing = false;

            if(response.uuid) {
              this.getPhotos();
            }
          })

        }

      }
    },


    async uploadPhoto(file, options = {}) {
      const response = await this.$http.post(this.$apiURL + 'v1/signed_url', {
        'file_path': this.form.uuid+'/'+file.name,
        'bucket': 'ecdphotos',
        'name': file.name,
        'size': file.size,
        'type': file.type,
        'content_length': file.size,
        'extension': file.name.split('.').pop(),
        'content_type': options.contentType || file.type,
        'expires': options.expires || '',
        'visibility': options.visibility || ''
      }, {
        baseURL: options.baseURL || null,
        headers: options.headers || {},
        ...options.options
      });
      if (response && response.data) {
        let headers = response.data.headers;

        if ('Host' in headers) {
          delete headers.Host;
        }

        if (typeof options.progress === 'undefined') {
          options.progress = () => {};
        }

        const cancelToken = options.cancelToken || ''

        await this.$axios.put(response.data.url, file, {
          cancelToken: cancelToken,
          headers: headers,
          onUploadProgress: (progressEvent) => {
            options.progress(progressEvent.loaded / progressEvent.total);
          }
        }).catch(function (error) {
          if(error.response) {
            console.log(error.response.data);
          }
        });

        response.data.extension = file.name.split('.').pop();

        return response.data;
      }
      return null;
    },

    deletePhoto(photo) {
      this.processing = true;
      this.$http.delete('/clients/'+this.form.uuid+'/photos?name='+photo.name)
          .then(response => {
            if(response && response.data) {
              this.photos = this.photos.filter(p => p.name != photo.name);
              this.processing = false;
            }
          })
    }

  }
}
</script>
