<template>
  <div>

    <page-tabs page="payments" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name">Name</label>
                <input
                    id="name"
                    v-model="form.name"
                    class="form-control"
                    placeholder="Cashier 1"
                    required
                >
              </div>

                <div class="form-group">
                  <fieldset>
                    <legend>Terminal Type</legend>
                    <label
                        class="mr-2 text-capitalize"
                        for="mobile"
                    >
                      <input
                          id="mobile"
                          v-model="form.type"
                          type="radio"
                          name="type"
                          value="mobile"
                      > Mobile Reader
                    </label>
                    <label
                        class="mr-2 text-capitalize"
                        for="dedicated"
                    >
                      <input
                          id="dedicated"
                          v-model="form.type"
                          type="radio"
                          name="type"
                          value="dedicated"
                      > Dedicated Reader
                    </label>
                  </fieldset>
                </div>

              <template v-if="form.type">
                <img :src="'https://ecdassets.s3.amazonaws.com/images/payment-readers/'+form.type+'-terminal.png'" class="img-fluid" :alt="form.type + ' Card Terminal'" width="50%" />
              </template>

              <div class="form-group">
                <label for="portal">Portal

                  <template v-if="form.portal">
                    <a
                        :href="'/portals/'+form.portal"
                        target="_blank"
                        data-tooltip="Edit Portal">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                    </a>
                  </template>

                </label>
                <select
                    id="portal"
                    v-model="form.portal"
                    class="form-control"
                    :disabled="processing"
                    required
                >
                  <option value="">
                    Choose Portal
                  </option>
                  <template v-for="portal in portals">
                    <option
                        :key="portal.uuid"
                        :value="portal.uuid"
                    >
                      {{ portal.name }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="location">Location

                  <template v-if="form.location">
                    <a
                        :href="'/locations/'+form.location"
                        target="_blank"
                        data-tooltip="Edit Location">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Location</span>
                    </a>
                  </template>

                </label>
                <select
                    id="location"
                    v-model="form.location"
                    class="form-control"
                    :disabled="processing"
                    required
                >
                  <option value="">
                    Choose Location
                  </option>
                  <template v-for="location in locations">
                    <option
                        :key="location.uuid"
                        :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                </select>
              </div>

              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                Add
              </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
                </p>
              </div><!--Col-->

              <div class="col-md-4" /><!--Col-->
            </div><!--Row-->


            <div class="table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                <tr>
                  <template v-for="(column, index) in columns">
                    <th
                        :key="'column_'+index"
                        class="text-uppercase"
                    >
                      <template v-if="column.key">
                        <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
                </thead>

                <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="(record, index) in records">
                    <tr :key="'record_'+index">
                      <td>
                        <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid}}">
                          <i class="fa fa-edit"></i>
                          {{ record.name || record.uuid }}
                        </router-link>
                      </td>
                      <td>{{ record.type }}</td>
                      <td><span class="badge badge-pill text-uppercase" :class="setStatusClass(record)">{{ record.status }}</span></td>
                      <td>
                        <template v-if="record.portal">
                          <router-link :to="{'name': 'portals.edit', 'params': {'id': record.uuid}}">
                            {{ setPortalName(record) }}
                          </router-link>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.location">
                          <router-link :to="{'name': 'locations.edit', 'params': {'id': record.uuid}}">
                            {{ setLocationName(record) }}
                          </router-link>
                        </template>
                      </td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.updated_by }}</td>
                      <td>{{ record.created_at | datetime }}
                        <template v-if="record.archived_at">
                          <span class="badge badge-pill badge-warning">Archived</span>
                        </template>
                        <template v-if="record.deleted_at">
                          <span class="badge badge-pill badge-danger">Trashed</span>
                        </template>
                      </td>
                      <td>{{ record.updated_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div><!--Responsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

          <div class="row mt-3">
            <div class="col-12 text-center">

              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>

            </div><!--Col-->
          </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import _ from "lodash";
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      records: [],
      form: {},
      queries: {},
      total: 0,
      columns: [
        { key: 'name', label: 'Name'},
        { key: 'type', label: 'Type'},
        { key: 'status', label: 'Status'},
        { key: 'portal', label: 'Portal'},
        { key: 'location', label: 'Location'},
        { key: 'created_by', label: 'Created'},
        { key: 'updated_by', label: 'Updated'},
        { key: 'created_at', label: 'Created'},
        { key: 'updated_at', label: 'Updated'}
      ],
      portals: [],
      locations: []
    }
  },

  created () {
    this.resetRecords();
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getRecords();
    this.getLocations();
    this.getPortals();
  },

  methods: {

    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        slim: false,
        sort: 'created_at',
        sortDirection: 'desc'
      }
    },

    resetForm() {
      this.form = {
        name: null,
        type: 'mobile',
        location: null,
        portal: null,
        status: 'pending'
      }
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    updateSortDirection() {

      if(this.processing) {
        return;
      }

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      if(this.processing) {
        return;
      }

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {

              if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                  self.records.push(data);
                });
              }

              this.queries.skip = this.records.length;

              this.processing = false;
            }
          })
    },

    getTotal() {
      this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              this.setTitle();
            }
          })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
    },

    setTitle() {
      document.title = this.$route.meta.title + 's | eCourtDate.com';
    },

    postForm() {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.status === 201) {
                this.resetForm();
                this.total++;
                this.records.unshift(response.data);
                new this.$noty({text: this.$route.meta.title + ' created'}).show();
              }

            }
          })
    },

    setStatusClass(record) {

      if(record.status == 'pending') {
        return 'badge-danger';
      }

      return 'badge-success';
    },

    getLocations() {
      this.$http.get('/locations?limit=1000&slim=true&fields=name,uuid&sort=default&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.locations = response.data;

              if(this.locations[0]) {
                this.form.location = this.locations[0]['uuid'];
              }
            }
          })
    },

    getPortals() {
      this.$http.get('/portals?limit=1000&slim=true&fields=name,uuid,link,domain&sort=default&sortDirection=asc&domain=ecourtpayment.com')
          .then(response => {
            if(response && response.data) {
              this.portals = response.data;

              if(this.portals[0]) {
                this.form.portal = this.portals[0]['uuid'];
              }
            }
          })
    },

    setLocationName(record) {

      if(!record.location) {
        return;
      }

      var location = _.find(this.locations, {'uuid': record.location});

      if(!location || !location.name) {
        return;
      }

      return location.name;
    },

    setPortalName(record) {

      if(!record.portal) {
        return;
      }

      var portal = _.find(this.portals, {'uuid': record.portal});

      if(!portal || !portal.name) {
        return;
      }

      return portal.name;
    },

  }
}
</script>