<template>
  <div>
    <div class="container">

      <template v-if="errors && errors.message">
          <div class="alert alert-danger">
            {{ errors.message }}
          </div>
      </template>

        <div class="row my-3">
          <div class="col-md-9 mx-auto">

            <div class="card">
              <div class="card-body">

                <template v-if="connectStatus !== 'connected'">
                  <button
                      type="button"
                      @click="connectPhone()"
                      class="btn btn-success btn-block"
                      :disabled="processing"
                  >
                    Connect
                  </button>
                </template>

                <template v-if="connectStatus == 'connected'">
                  <button
                      type="button"
                      @click="disconnectPhone()"
                      class="btn btn-danger btn-block"
                      :disabled="processing"
                  >
                    Disconnect
                  </button>
                </template>

                <template v-if="connectStatus">
                  <span class="badge badge-pill text-uppercase" :class="setStatusClass(connectStatus)">{{ connectStatus }}</span>
                </template>

                <form @submit.prevent="postCall()">

                  <div class="form-group mt-3">
                    <label class="form-label" for="microphone">Microphone</label>
                    <select id="microphone" class="form-control" v-model="form.microphone" :disabled="processing">
                      <option value=""></option>
                      <template v-for="(microphone, microphone_index) in microphones">
                        <option :key="'microphone_'+microphone_index" :option="microphone['deviceId']">{{ microphone['label'] }}</option>
                      </template>
                    </select>
                  </div>

                  <div class="form-group">
                    <label class="form-label" for="speaker">Speaker</label>
                    <select id="speaker" class="form-control" v-model="form.speaker" :disabled="processing">
                      <option value=""></option>
                      <template v-for="(speaker, speaker_index) in speakers">
                        <option :key="'speaker_'+speaker_index" :option="speaker['deviceId']">{{ speaker['label'] }}</option>
                      </template>
                    </select>
                  </div>

                  <div class="form-group">
                    <label class="form-label" for="call_id">Call ID <small>(optional)</small></label>
                    <input
                        type="text"
                        class="form-control"
                        id="call_id"
                        v-model="form.call_id"
                    />
                  </div>

                  <div class="form-group">
                    <label for="call_from">Call From</label>
                    <select id="call_from" class="form-control" v-model="form.from" :disabled="processing" required>
                      <option value=""></option>
                      <template v-for="(agency_phone, index) in agency_phones">
                        <option :key="'agency_phone_'+index" :value="agency_phone.phone_number">{{ agency_phone.name }}
                          <template v-if="agency_phone.phone_number != agency_phone.name">
                            ({{ agency_phone.phone_number }})
                          </template>
                        </option>
                      </template>
                    </select>
                  </div>

                <div class="form-group">
                  <label class="form-label" for="call_to">Call To</label>
                  <input
                      type="text"
                      class="form-control"
                      id="call_to"
                      v-model="form.to"
                      placeholder="Enter a Phone Number to Dial"
                  />
                </div>

                  <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="processing || connectStatus != 'connected'"
                      :hidden="currentCall && currentCall.state && currentCall.state != 'destroy'"
                  >
                    Call
                  </button>

                </form>

              <template v-if="currentCall && currentCall.state && currentCall.state != 'destroy'">
                <button
                    class="btn btn-danger"
                    @click="disconnectCall()"
                    :disabled="processing || connectStatus != 'connected'"
                >
                  Hang up
                </button>
              </template>

                <audio id="callMedia" autoplay="true" />

              </div>
            </div>
          </div>
      </div>
      </div><!--Container-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      queries: {},
      form: {},
      callForm: {},
      client: null,
      currentCall: null,
      connectStatus: null,
      agency_phones: [],
      errors: {},
      microphones: [],
      speakers: [],
      devices: []
    }
  },

  created() {

    document.title = this.$route.meta.title +'s | eCourtDate.com';

    this.resetForm();
    this.setURLParams();
    this.getDevices();
    this.getAgencyPhones();

  },

  methods: {

    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.form[key] = value;
      });
    },

    resetForm() {
      this.form = {
        call_id: null,
        microphone: null,
        speaker: null,
        from: null,
        to: null
      }
    },

    getAgencyPhones() {
      this.$http.get('/agency_phones?limit=100&slim=true&fields=name,uuid,phone_number&sort=default&sortDirection=asc&gateway=auto')
          .then(response => {
            if(response && response.data) {
              this.agency_phones = response.data;
            }
          })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.form));
    },

    connectPhone() {

      if(this.currentCall) {
        return;
      }

      this.processing = true;
      this.connectStatus = null;

      this.$http.post('/'+this.$route.meta.base_url)
          .then(response => {
            if(response && response.data) {
              this.processing = false;

              if(response.data.token && !this.currentCall) {
                this.connect(response.data.token);
              }
            }
          })
    },

  connect(token) {

      if(this.connectStatus == 'disconnected') {
        return;
      }

      this.initClient(token);

      this.connectClient();
    },

    connectClient() {
      this.processing = true;
      // this.errors = {};
      this.connectStatus = 'connecting';

      this.client.connect();
    },

    disconnectPhone() {
      this.processing = true;
      this.connectStatus = 'disconnecting';
      this.client.disconnect();
      this.currentCall = null;
      // this.errors = null;
      this.processing = false;
    },

    initClient(token) {

      if(this.client) {
        return;
      }

      this.client = new TelnyxWebRTC.TelnyxRTC({
        env: 'production',
        login_token: token
      });

      this.client.enableMicrophone();

      this.client.remoteElement = 'callMedia';

      var self = this;

      this.client.on('telnyx.ready', function () {
        self.processing = false;
        // self.errors = {};
        self.connectStatus = 'connected';
      });

      this.client.on('telnyx.socket.close', function () {
        self.processing = false;
        // self.errors = {};
        self.connectStatus = 'disconnected';
        self.currentCall = null;
        self.client.disconnect();
      });

      this.client.on('telnyx.error', function (error) {
        self.errors = error;
        self.processing = false;
        self.currentCall = null;
        self.connectStatus = 'disconnected';
        self.client.disconnect();
      });

    },

handleNotification(notification) {
      switch (notification.type) {
        case 'callUpdate':
          handleCallUpdate(notification.call);
          break;
        case 'userMediaError':
          console.log(
              'Permission denied or invalid audio params on `getUserMedia`'
          );
          break;
      }
    },

    handleCallUpdate(call) {
      this.currentCall = call;
      switch (call.state) {
        case 'new':
          break;
        case 'trying':
          break;
        case 'recovering':
          if (confirm('Recover the previous call?')) {
            this.currentCall.answer();
          } else {
            this.currentCall.hangup();
          }
          break;
        case 'ringing':

          setTimeout(function () {
            if (confirm('Pick up the call?')) {
              this.currentCall.answer();
            } else {
              this.currentCall.hangup();
            }
          }, 1000);
          break;
        case 'active':
          break;
        case 'hangup':
          break;
        case 'destroy':
          this.currentCall = null;
          break;
      }
    },

    postCall() {

      var options = {
        callerName: this.form.call_id || '',
        destinationNumber: this.form.to,
        callerNumber: this.form.from,
        micId: this.form.microphone || '',
        speakerId: this.form.speaker || '',
        audio: 1
      }

      this.currentCall = this.client.newCall(options);
    },

    disconnectCall() {
      if (this.currentCall) {
        this.currentCall.hangup();
      }
    },

    setStatusClass(status) {

      if(['connecting', 'disconnecting'].includes(status)) {
        return 'badge-warning';
      }

      if(status == 'connected') {
        return 'badge-success';
      }

      if(status == 'disconnected') {
        return 'badge-danger';
      }

      return 'badge-secondary';
    },

    async getDevices() {

        this.devices = await navigator.mediaDevices.enumerateDevices();

        if(!this.devices) {
          return;
        }

        this.microphones = this.devices.filter(d => d.kind == 'audioinput');

        this.speakers = this.devices.filter(d => d.kind == 'audiooutput');

        var result = null;

        result = this.devices.find(d => d.kind === 'audioinput' && d.isDefault === true);

        if(result && !this.form.microphone) {
          this.form.microphone = result['deviceId'];
        }

        result = this.devices.find(d => d.kind === 'audiooutput' && d.isDefault === true);

        if(result && !this.form.speaker) {
          this.form.speaker = result['deviceId'];
        }

    }

  }
}
</script>