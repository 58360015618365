<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row mb-3">
      <div class="col-lg-9 col-md-7 order-1 order-md-0 mb-1 mt-2 mt-md-0">
        <h1 class="m-0">
          {{ form.name }}
        </h1>

        <template v-if="form.flow_reference">
          <span class="badge badge-pill bg-secondary">{{ form.flow_reference }}</span>
        </template>

        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->
      <div class="col-lg-3 col-md-5 text-right">
        <div class="btn-group">

          <a 
          :href="'/preview?flow='+form.uuid" 
          class="btn btn-outline-primary"
          target="_blank"
          :disabled="processing"
          >
          Preview
          </a>

          <button
            type="button"
            class="btn btn-secondary dropdown-toggle radius-right-0"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">

            <a
              v-if="form.deleted_at || form.archived_at"
              class="dropdown-item"
              @click="restoreRecord"
            >Restore</a>
            <a
              v-if="!form.deleted_at || !form.archived_at"
              class="dropdown-item"
              @click="archiveRecord"
            >Archive</a>
            <a
              v-if="!form.deleted_at || !form.archived_at"
              class="dropdown-item"
              @click="trashRecord"
            >Trash</a>

            <a
              v-if="!form.deleted_at && !form.archived_at"
              class="dropdown-item"
              @click="validateRecord()"
              >
              Validate Messages
            </a>

              <a
              v-if="!form.deleted_at && !form.archived_at"
              class="dropdown-item"
              @click="syncMessages()"
            >Sync Messages</a>            

          </div>
          <button
            v-if="!form.deleted_at"
            type="submit"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>
          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div>
      </div><!--Col-->
    </div><!--Row-->
    <div class="row">
      <div class="col-lg-3 col-md-12">
          <div class="card">
            <div class="card-body">

              <p class="card-title">{{ $route.meta.title }} Settings</p>

              <form
                role="form"
                @keyup.enter="postForm()"
              >

                    <div class="form-group">
                      <label for="name">Flow Name</label>
                      <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        class="form-control"
                        required
                        autofocus
                      >
                    </div>

                    <div class="form-group">
                      <label for="flow_reference">Flow Reference</label>
                      <input
                        id="flow_reference"
                        v-model="form.flow_reference"
                        type="text"
                        class="form-control"
                        :disabled="!superAdmin"
                      >
                    </div>

                    <div class="form-group">
                      <label for="phone">Agency Phone</label>
                      <select
                        id="phone"
                        v-model="form.phone"
                        class="form-control"
                        :disabled="processing"
                      >
                        <option value="" />
                        <option
                          v-for="phone in phones"
                          :key="phone.uuid"
                          :value="phone.phone_number"
                        >
                          <template v-if="phone.name == phone.phone_number">
                            {{ phone.phone_number }}
                          </template>
                          <template v-else>
                            {{ phone.name }} {{ phone.phone_number }}
                          </template>
                        </option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="email">Agency Email</label>
                      <select
                        id="email"
                        v-model="form.email"
                        class="form-control"
                        :disabled="processing"
                      >
                        <option value="" />
                        <option
                          v-for="email in emails"
                          :key="email.uuid"
                          :value="email.email"
                        >
                          <template v-if="email.name == email.email">
                            {{ email.email }}
                          </template>
                          <template v-else>
                            {{ email.name }} {{ email.email }}
                          </template>
                        </option>
                      </select>
                    </div>
 


                    <div class="form-group">
                      <label for="portal">Portal Link

                        <template v-if="form.portal">
                          <a
                          :href="'/portals/'+form.portal"
                          target="_blank"
                          data-tooltip="Edit Portal">
                          <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                          </a>
                        </template>
                      </label>
                      <select
                        id="portal"
                        v-model="form.portal"
                        class="form-control"
                        :disabled="processing"
                      >
                        <option value="" />
                        <option
                          v-for="portal in portals"
                          :key="portal.uuid"
                          :value="portal.uuid"
                        >
                          {{ portal.name }} https://{{ portal.link }}.{{ portal.domain }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="geolocation">Geolocation Tracking</label>
                      <select
                        id="geolocation"
                        v-model="form.geolocation"
                        class="form-control"
                        :disabled="processing"
                      >
                        <option value=""></option>
                        <template v-for="(geolocation, geolocation_index) in geolocations">
                          <option :key="'geolocation_'+geolocation_index" :value="geolocation">{{ geolocation }}</option>
                        </template>
                      </select>
                      <p class="form-text text-muted small">Overrides the portal geolocation.</p>
                    </div>


                <div class="form-group">
                  <label for="type">Type</label>
                  <select id="type" class="form-control" v-model="form.type" :disabled="processing" required>
                    <option value="" />
                    <option
                      v-for="(flow_type, type_index) in flow_types"
                      :key="'flow_type_'+type_index"
                      :value="flow_type"
                    >
                      {{ flow_type }}
                    </option>
                  </select>
                </div>


                    <div class="form-group custom-control custom-switch">
                      <input
                        id="default"
                        v-model="form.default"
                        type="checkbox"
                        class="custom-control-input"
                        @change="postForm()"
                        :disabled="processing"
                      >
                      <label
                        class="custom-control-label"
                        for="default"
                      >Default</label>
                      <p class="form-text text-muted small">Assign this flow if no flow is chosen or auto-assigned.</p>
                    </div>

                    <div class="form-group custom-control custom-switch">
                      <input
                        id="virtual"
                        v-model="form.virtual"
                        type="checkbox"
                        class="custom-control-input"
                        @change="postForm()"
                        :disabled="processing"
                      >
                      <label
                        class="custom-control-label"
                        for="virtual"
                      >Virtual</label>
                    </div>

                    <div class="form-group custom-control custom-switch">
                      <input
                        id="simulate"
                        v-model="form.simulate"
                        type="checkbox"
                        class="custom-control-input"
                        @change="postForm()"
                        :disabled="processing"
                      >
                      <label
                        class="custom-control-label"
                        for="simulate"
                      >Simulate Mode</label>
                    </div>

                      <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

              </form>


            </div><!--Body-->
          </div><!--Card-->

          <audit-logs
            class="mt-3"
            :form="form"
          />

        </div><!--Col-->

            <div class="col-lg-3 mb-3">
                <p class="card-title">
                  {{ messages.length }} Flow Messages
                </p>

                <template v-if="!processing && !messages.length">
                  <div class="alert alert-warning">No Flow Messages found.</div>
                </template>

                <div class="list-group">
                  <template v-for="(message, index) in messages">
                    <button
                      :key="'flow_message_'+index"
                      type="button"
                      class="list-group-item list-group-item-action"
                      :class="{ active: isActive(message.uuid) }"
                      @click="getMessage(message.uuid)"
                      :disabled="processing"
                    >
                      {{ setMessageName(message) }}

                      <template v-if="message.trigger == 'event_date' || message.trigger == 'payment_issue_date' || message.trigger == 'payment_due_date'">
                       <small class="form-text"> 
                        {{ message.difference }} {{ message.unit }} 
                        <template v-if="message.operation === '-'">
                          before
                        </template>
                        <template v-if="message.operation === '+'">
                          after
                        </template>
                        </small>
                      </template>

                      <template v-if="message.status">
                        <span class="badge badge-pill bg-secondary">STATUS {{ message.status }}</span>
                      </template>

                      <template v-if="message.templates && message.templates['en']">
                          <p class="small m-0">{{ message.templates['en'] }}</p>                        
                      </template>

                    </button>
                  </template>
                </div><!--ListGroup-->

                <merge-tags-copy class="mt-3" />

            </div><!--Col-->

            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <form @submit.prevent="postForm()">
                    <form-title
                      :title="'Flow Message'"
                      :form="active_message"
                    />
                    <div class="form-group">
                      <label for="trigger">Trigger</label> <br>
                      <select
                        id="trigger"
                        v-model="active_message.trigger"
                        class="form-control"
                        :disabled="processing"
                        autofocus
                      >
                        <option value="">Choose a trigger</option>

                        <template v-for="(trigger, index) in triggers">
                          <option
                            :key="'trigger_'+index"
                            :value="trigger.value"
                          >
                          {{ trigger.label }}
                        </option>
                        </template>

                      </select>
                    </div>
                    <template v-if="active_message.trigger === 'event_date' || active_message.trigger === 'payment_issue_date' || active_message.trigger === 'payment_due_date'">
                      <div class="form-group">
                        <label v-if="active_message.trigger === 'event_date'">Difference from Event Date</label>
                        <label v-if="active_message.trigger === 'payment_issue_date'">Difference from Payment Issue Date</label>
                        <label v-if="active_message.trigger === 'payment_due_date'">Difference from Payment Due Date</label>
                        <input
                          v-model="active_message.difference"
                          type="number"
                          class="form-control"
                        >
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="unit">Unit</label> <br>
                            <template v-for="unit in units">
                              <div :key="unit">
                                <label
                                  class="mr-2 text-capitalize"
                                  :for="unit.value"
                                >
                                  <input
                                    :id="unit"
                                    v-model="active_message.unit"
                                    type="radio"
                                    name="unit"
                                    :value="unit"
                                  > {{ unit }}
                                </label>
                              </div>
                            </template>
                          </div>
                        </div><!--Col-->
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="operation">Operation</label> <br>
                            <template v-for="operation in event_operations">
                              <div :key="operation.value">
                                <label
                                  class="mr-2 text-capitalize"
                                  :for="operation.value"
                                >
                                  <input
                                    :id="operation.value"
                                    v-model="active_message.operation"
                                    type="radio"
                                    name="operation"
                                    :value="operation.value"
                                  > {{ operation.label }}
                                </label>
                              </div>
                            </template>
                          </div>
                        </div><!--Row-->
                      </div><!--Row-->
                    </template>

                    <template v-if="active_message.trigger">

                      <p class="m-0">Has Status</p>
                      <p class="m-0">Choose the enabled statuses for this flow message.</p>

                      <template v-for="(status, status_index) in statuses">
                        <span :key="'status_'+status_index" class="badge badge-pill link-hover" :class="{'badge-success': isEnabledStatus(status) }" @click="addStatus(status)">{{ status }}</span>
                      </template>

                      <hr>

                    </template>

                    <template v-if="active_message.trigger === 'event_status' || active_message.trigger === 'event_appearance' || active_message.trigger === 'case_status' || active_message.trigger === 'event_created' || active_message.trigger === 'event_updated' || active_message.trigger === 'payment_created' || active_message.trigger === 'payment_status'">
                      <div class="form-group">
                        <label v-if="active_message.trigger === 'payment_created' || active_message.trigger === 'payment_status'">Maximum Number of Days After Payment Date</label>
                        <label v-else>Maximum Number of Days After Event Date</label>
                        <input
                          v-model="active_message.difference"
                          type="number"
                          class="form-control"
                        >
                        <small class="form-text text-muted">In days. Defaults to 1 day.</small>
                      </div>
                    </template>

                    <template v-if="active_message.trigger">

                    <div class="row align-items-end">
                      <div
                        v-if="active_message.trigger === 'event_date' || active_message.trigger === 'payment_issue_date' || active_message.trigger === 'payment_due_date'"
                        class="col-md-4"
                      >
                        <div class="form-group vbTimePicker">
                          <label for="send_time">Send Time</label>
                          <input
                            id="send_time"
                            v-model="active_message.send_time"
                            placeholder="hh:mm AM"
                            type="time"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->


                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="delay_min">Delay in Minutes</label>
                          <input
                            id="delay_min"
                            v-model="active_message.delay_min"
                            type="number"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->
      
                      <div class="col-md-4">
                        <div class="form-group custom-control custom-switch">
                          <input
                            id="delay_business"
                            v-model="active_message.delay_business"
                            type="checkbox"
                            class="custom-control-input"
                            :disabled="processing"
                          >
                          <label
                            class="custom-control-label"
                            for="delay_business"
                          >Delay to Business Hours</label>
                        </div>
                      </div><!--Col-->
                    </div><!--Row-->

                    <div class="form-group">
                      <label for="file_url">Attach a File

                        <template v-if="active_message.file_url">
                          <a :href="'/files?url='+active_message.file_url" target="_blank">
                            <i class="fa fa-link"></i>
                            <span class="sr-only">Link to file</span>
                          </a>
                        </template>

                      </label>
                      <select
                        id="file_url"
                        v-model="active_message.file_url"
                        class="form-control"
                      >
                        <option value="" />
                        <template v-if="files && files.length > 0">
                          <template v-for="(file, index) in files">
                            <option
                              :key="'file_'+index"
                              :value="file.url"
                            >
                              {{ file.name }}
                            </option>
                          </template>
                        </template>
                      </select>
                      <p class="small text-muted">
                        <a
                          href="/files"
                          target="_blank"
                        >Upload files</a>
                      </p>
                    </div>

                    </template>

                    <template v-if="!processing && !languages.length">
                      <div class="alert alert-danger">Choose enabled languages in
                        <a href="/settings">General Settings</a>
                      </div>
                    </template>

                    <template v-for="(language, index) in languages">

                      <div :key="'language_template_'+index">

                        <div
                            class="form-group mt-3"
                            :hidden="!active_message.trigger"
                        >
                          <label
                              :for="'language_subject_'+language.key"
                              class="text-capitalize"
                          >
                            {{ language.label }} Subject</label>
                          <input :id="'language_subject_'+language.key" class="form-control" v-model="active_message.subjects[language.key]" :disabled="processing" />
                        </div>

                    <div 
                      class="form-group mt-3"
                      :hidden="!active_message.trigger"
                      >
                      <label
                        for="language_templates"
                        class="text-capitalize"
                      >
                    {{ language.label }} Message</label>
                      <textarea
                        id="language_templates"
                        :ref="'template_'+language.key"
                        v-model="active_message.templates[language.key]"
                        class="form-control"
                        rows="8"
                        :disabled="processing"
                      />
                    
                    <template v-if="language.key !== 'en' && active_message.templates['en']">
                      <button 
                        type="button" 
                        class="btn btn-outline-success mt-3 btn-sm text-capitalize"
                        @click="autoTranslate(language)"
                        :disabled="processing"
                        >
                        Auto-Translate {{ language.label }}
                      </button>
                    </template>

                    </div>

                      </div>

                    </template>

                    <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

                  </form>
                </div>
              </div><!--Card-->


              <template v-if="active_message && active_message.uuid">
                <div class="row mt-3">
                  <div class="col-md-6">
                    <audit-logs :form="active_message" />
                  </div><!--Col-->

                  <div class="col-md-6">
                    <button
                      type="button"
                      class="btn btn-danger btn-sm text-uppercase float-right"
                      :disabled="processing"
                      @click="trashMessage(active_message.uuid)"
                    >
                      Trash Message
                    </button>
                  </div><!--Col-->
                </div><!--Row-->
              </template>

        </div><!--Col-->

    </div><!--Row-->
  </div>
</template>

<script>
import _ from 'lodash';
import queries from '../mixins/queries';
import jsonParse from '../mixins/jsonParse';

export default {
  
  mixins: [ queries, jsonParse ],

  data () {
    return {
      processing: false,
      form: {},
      active_message: {},
      messages: [],
      languages: [],
      triggers: [],
      current_language: {label: 'English', key: 'en'},
      phones: [],
      emails: [],
      portals: [],
      types: [],
      locations: [],
      files: [],
      statuses: [],
      payment_statuses: [],
      units: ['minutes', 'hours', 'days', 'weeks'],
      flow_types: ['event', 'payment', 'case', 'warrant', 'form'],
      geolocations: ['disabled', 'optional', 'required', 'persistent'],
      event_operations: [{value: '+', label: 'after'}, {value: '-', label: 'before'}],
      queries: {
        flow: null,
        uuid: null
      }
    }
  },

  computed: {

    superAdmin () {
        return this?.$root?.current_user?.super_admin;
    }
  
  },

  created () {
    this.resetMessage();
    this.getLanguages();
    this.getOptions();
    this.getRecord();
    this.getPortals();
    this.getLocations();
    this.getFiles();
  },

  methods: {

    getOptions() {
        
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
        .then(response => {
          if(response && response.data) {
            this.payment_statuses = response.data.payment_statuses;
            this.triggers = response.data.flow_message_triggers;
          }
        })

        this.$http.get('/agency_phones?slim=true&fields=uuid,phone_number,name&limit=1000&sort=phone_number&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.phones = response.data;
            }
        })

        this.$http.get('/agency_emails?slim=true&fields=uuid,email,name&limit=1000&sort=email&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.emails = response.data;
            }
        })

    },

    getPortals() {
        this.$http.get('portals?slim=true&fields=uuid,name,domain,link&limit=1000&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.portals = response.data;
            }
        })
    },

    setMessageName(message) {
        var trigger = _.find(this.triggers, {'value': message.trigger});

        if(trigger && trigger.label) {
          return trigger.label;
        }

        return message.trigger;
    },

    getLocations() {
      this.$http.get('/locations?limit=1000&fields=name,uuid&slim=true&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.locations = response.data;
        }
      })
    },

    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/' + this.$route.params.id+'?slim=true')
      .then(response => {
        if (response && response.data) {
          
          this.form = response.data;

          if(this.form.locations) {
            this.form.locations = this.parseJSON(this.form.locations);
          }

          this.getStatuses();

          this.getMessages();          
        }
        
      })
    },

    getMessages() {
      
      if(!this.form.uuid) {
        return;
      }

      this.$http.get('/flow_messages?flow='+this.form.uuid+'&slim=false&sort=difference&sortDirection=desc')
      .then(response => {
        if(response && response.status) {

          if(response.data) {
            this.messages = response.data;            
          }

          this.setURLParams();

          this.setTitle();

          this.processing = false;
        }
      })
    },

    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });

        if(this.queries.uuid) {
          this.getMessage(this.queries.uuid);
        }
    },

    setTitle() {
        if(this.form.name) {
          document.title = this.form.name + ' | ' + this.messages.length + ' Messages | eCourtDate.com';
        }
    },

    postForm() {
      
      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/' + this.form.uuid, this.form)
      .then(response => {
        if (response && response.data) {

          new this.$noty({ text: this.$route.meta.title + ' updated'}).show();

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
          }

          this.processing = false;

          this.postMessage();
        }
      })
    },

    syncMessages() {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/sync/'+this.form.uuid+'?past=n')
      .then(response => {
        if (response && response.data && response.data.message) {
          new this.$noty({ text: response.data.message }).show();
        }
        this.processing = false;
      })
    },

    archiveRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/archive')
          .then(response => {
            if (response && response.status) {

              if(response.status === 200) {
                new this.$noty({ text: this.$route.meta.title + ' archived', type: 'error' }).show();
                this.form.archived_at = new Date().toISOString();
              }

              this.processing = false;
            }
          })
    },

    trashRecord() {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
      .then(response => {
        if (response && response.status) {

          if(response.status === 204) {
            new this.$noty({ text: this.$route.meta.title + ' trashed', type: 'error' }).show();
            this.form.deleted_at = new Date().toISOString();
          }

          this.processing = false;
        }
      })
    },

    validateRecord() {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/validate')
          .then(response => {
            if(response && response.status) {
              if(response.data.message) {
                new this.$noty({text: response.data.message}).show();
              }

              this.processing = false;
            }
          })
    },

    restoreRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
      .then(response => {
        if (response && response.status) {

          if(response.status === 200) {
            new this.$noty({ text: this.$route.meta.title + ' restored'}).show();
            this.form.deleted_at = null;
            this.form.archived_at = null;
            this.form.updated_at = new Date().toISOString();
          }

          this.processing = false;
        }
      })
    },

    isActive(uuid) {
        if (uuid === this.active_message.uuid) {
            return true;
        }
    },

    resetMessage() {
      this.active_message = {
        templates: {
          'en': ''
        },
        subjects: {
          'en': ''
        },
        trigger: '',
        file_url: '',
        delay_min: '',
        delay_business: false,
        send_time: '',
        status: '',
        operation: '-',
        unit: '',
        difference: ''
      }
    },

    updateURL() {
      var params = new URLSearchParams(window.location.search);
       
      params.set("uuid", this.active_message.uuid);
       
      history.replaceState(null, null, "?"+params.toString());
    },

    resetURL() {
      history.pushState({}, null, '/'+this.$route.meta.base_url+'/'+this.$route.params.id);
    },

    getMessage(uuid) {

      if(uuid === this.active_message.uuid) {
        this.resetMessage();
        this.resetURL();
        return;
      }

      this.processing = true;

      this.$http.get('/flow_messages/'+uuid)
      .then(response => {
        if(response && response.status) {

          if(response.data.uuid) {
            this.active_message = response.data;

            if(!this.active_message.subjects) {
              this.active_message.subjects = {
                'en': ''
              };
            }


            this.updateURL();            
          }

          this.processing = false;
        }
      })
    },

    addTagToMessage(value) {
      let selectionIndex = this.$refs.template_en.selectionStart;
      let newValue = this.active_message.templates['en'].slice(0, selectionIndex) + value + this.active_message.templates['en'].slice(selectionIndex, this.active_message.templates['en'].length);
      this.active_message.templates['en'] = newValue;
    },

    postMessage() {

      if (this.active_message.uuid) {
        this.updateMessage();
      } else {
        this.createMessage();
      }
    },

    updateMessage() {

      this.processing = true;

      this.$http.patch('/flow_messages/'+ this.active_message.uuid, this.active_message)
        .then(response => {
          if (response && response.data) {

            if(response.data.updated_at) {
              this.active_message.updated_at = response.data.updated_at;
              new this.$noty({ text: 'Flow Message updated' }).show();
            }

            this.processing = false;
          }
        })
    },

    createMessage() {

      if(!this.active_message.trigger) {
        return;
      }

      this.processing = true;

      this.active_message.flow = this.form.uuid;

      this.$http.post('/flow_messages', this.active_message)
        .then(response => {
          if (response && response.status) {

            if(response.status === 201) {
              new this.$noty({ text: 'Flow Message added' }).show();
              this.messages.unshift(response.data);
              this.resetMessage();
            }

            this.processing = false;
          }
        })
    },

    getLanguages() {
      this.$http.get('/languages')
      .then(response => {
        if(response && response.data) {
          this.languages = response.data;
        }
      })
    },

    trashMessage(uuid) {
        this.processing = true;
        this.$http.delete('/flow_messages/'+uuid)
        .then(response => {
            if(response && response.status === 204) {
                this.resetMessage();
                this.resetURL();
                this.messages.splice(this.messages.findIndex((obj => obj.uuid === uuid)), 1);
                new this.$noty({text: 'Flow Message trashed', type: 'error'}).show();
                this.processing = false;
            }
            
        })
    },

    getFiles() {
        this.$http.get('/files?slim=true&fields=name,url')
        .then(response => {
            if(response && response.data) {
                this.files = response.data;
            }
        })
    },

    getStatuses() {
      this.$http.get('/statuses?limit=100&slim=true&fields=name&sort=name&sortDirection=asc&type='+this.form.type)
          .then(response => {
            if(response && response.status === 200) {
              this.statuses = response.data.map(status => status.name);

              if(this.form.type == 'payment') {
                this.payment_statuses.forEach(status => {
                  if(!this.statuses.includes(status.label)) {
                    this.statuses.push(status.label);
                  }
                })
              }

            }
          })
    },

    autoTranslate(language) {

      this.processing = true;

      var translator = {
        source_language: 'en',
        target_language: language.key,
        content: this.active_message['templates']['en']
      }

      this.$http.post('/translator', translator)
      .then(response => {
        if(response && response.data) {

          if(response.data.content) {
            this.active_message['templates'][language.key] = response.data.content;
            new this.$noty({text: 'Added translation to '+language.label}).show();
          }

          this.processing = false;
        }
      })
    },

    isEnabledStatus(status) {

      if(!status) {
        return;
      }

      if(!this.active_message.status) {
        return false;
      }

      var statuses = this.active_message.status.split(',');

      if(!statuses) {
        return false;
      }

      return statuses.includes(status);
    },

    addStatus(status) {

      if(!status) {
        return;
      }

      if(!this.active_message.status) {
        this.active_message.status = status;
        return;
      }

      var statuses = this.active_message.status.split(',');

      if(!statuses) {
        this.active_message.status = status;
        return;
      }

      if(statuses.includes(status)) {
        statuses.splice(statuses.indexOf(status), 1);
      } else {
        statuses.push(status);
      }

      this.active_message.status = statuses.join(',');
    }
  }
}
</script>
