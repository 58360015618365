<template>
<div>
  <button
    type="button"
    class="btn btn-outline-primary btn-sm"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    id="mergeTagButton"
  >
    Merge Tags
  </button>
  <div class="dropdown-menu" aria-labelledby="mergeTagButton">
    <h6 class="dropdown-header">
      Use a Merge Tag
    </h6>
    <template v-for="(merge_tag, index) in merge_tags">
        <a
          :key="'merge_tag_'+index"
          class="dropdown-item"
          @click="selectTag(merge_tag.value)"
        >{{ merge_tag.value }}</a>
    </template>
  </div>
</div>
</template>
<script>
export default {
  
  data() {
      return {
        merge_tags: [],
        value: ""
      }
  },

  mounted () {
    this.getMergeTags();
  },

  methods: {

    selectTag (value) {
      this.value = '';
      this.$emit("add-tag-to-message", value);
    },

    getMergeTags () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/mergetags.json')
      .then(response => {
          if(response && response.data) {
            this.merge_tags = response.data;
          }
      })
    }
  }
}
</script>
