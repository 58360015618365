<template>
  <div>

    <page-tabs page="cases" />

    <template v-if="errors && errors.message">
            <div v-if="errors.message != 'Network Error'">
              <div class="alert alert-danger">
                {{ errors.message }}
              </div>
            </div>
    </template>

    <div class="row">
      <div class="col-md-3">
        <template v-if="client && client.uuid">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-sm-12 col-md-6">
                  <p class="card-title">
                    Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                      {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}
                    </router-link>
                  </p>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-right">
                  <button
                    type="submit"
                    class="btn btn-danger btn-sm"
                    data-toggle="tooltip"
                    data-tooltip="Close client"
                    :disabled="processing"
                    @click="closeClient"
                  >
                    <i class="fa fa-times" /> <span class="sr-only">Close</span>
                  </button>
                </div><!--Col-->
              </div><!--Row-->

              <ul class="list-group">
                <template v-if="client.client_reference">
                  <li class="list-group-item">
                    Reference {{ client.client_reference }}
                  </li>
                </template>

                <template v-if="client.type">
                  <li class="list-group-item">
                    Type {{ client.type }}
                  </li>
                </template>

                <template v-if="client.group">
                  <li class="list-group-item">
                    Group {{ client.group }}
                  </li>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="client && !client.uuid">
          <client-search />
        </template>

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              
              Add {{ $route.meta.title }}

              <template v-if="client && client.uuid">
                for {{ client.client_reference }} {{ client.first_name }} {{ client.last_name }}
              </template>
            </p>

            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="case_number">Case Number</label>
                <input
                  id="case_number"
                  v-model="form.case_number"
                  type="text"
                  class="form-control"
                  required
                  autofocus
                  :disabled="processing"
                >
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">

            <p class="card-title">Search {{ $route.meta.title }}s</p>

            <form @submit.prevent="searchRecords()">
              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                  placeholder="Search..."
                >
              </div>

              <div class="form-group">
                <label for="search_location">Location</label>
                <select
                    id="search_location"
                    v-model="queries.location"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Location
                  </option>
                  <template v-if="locations && locations.length > 0">
                    <template v-for="(search_location, search_location_index) in locations">
                      <option
                          :key="'search_location_'+search_location_index"
                          :value="search_location.uuid"
                      >
                        {{ search_location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group" :hidden="!processing && !statuses.length">
                <label for="search_status">Status</label>
                <select
                  id="search_status"
                  v-model="queries.status"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <template v-if="statuses && statuses.length > 0">
                    <template v-for="(search_status, search_status_index) in statuses">
                      <option
                        :key="'search_status_'+search_status_index"
                        :value="search_status.name"
                      >
                        {{ search_status.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>
              
              <template v-if="!processing && !statuses.length">
                  <div class="alert alert-info">
                    <a href="/statuses?type=case" class="alert-info" target="_blank">No case statuses found</a>

                    <button type="button" class="btn btn-secondary btn-sm float-right" @click="getStatuses()" :disabled="processing">
                      <i class="fa fa-refresh"></i>
                    </button>

                  </div>

              </template>

              <div class="form-group">
                <label for="search_file_date">File Date</label>
                <input
                  id="search_file_date"
                  v-model="queries.file_date"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_arrest_date">Arrest Date</label>
                <input
                  id="search_arrest_date"
                  v-model="queries.arrest_date"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_offense_date">Offense Date</label>
                <input
                  id="search_offense_date"
                  v-model="queries.offense_date"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_offense_description">Offense</label>
                <select
                  id="search_offense_description"
                  v-model="queries.offense_description"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-if="distincts && distincts['offense_description']">
                  <template v-for="(offense_desc, offense_desc_key) in distincts['offense_description']">
                    <option :key="'offense_description_'+offense_desc_key" :value="offense_desc">{{ offense_desc }}</option>
                  </template>
                  </template>
                </select>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="custom-control-input"
                      @change="searchRecords()"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="custom-control-input"
                      @change="searchRecords()"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

              </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <tr>
                  <template v-for="(column, index) in columns">
                    <th :key="'column_'+index">
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
                <tbody>
                  <template v-for="(record, index) in records">
                    <tr :key="'case_'+index">
                      <td>
                        <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid}}">
                          <i class="fa fa-edit"></i> {{ record.case_number }}
                        </router-link>
                      </td>
                      <td>
                        <template v-if="record.client">
                          <client-button :uuid="record.client" />
                        </template>
                      </td>
                      <td>
                        <template v-if="record.location">
                          <a :href="'/locations/'+record.location">{{ setLocationName(record) }}</a>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.status">
                            <span class="badge badge-pill badge-secondary">{{ record.status }}</span>
                        </template>
                      </td>
                      <td>{{ record.type }}</td>
                      <td>{{ record.description }}</td>
                      <td>{{ record.file_date | date }}</td>
                      <td>{{ record.arrest_date | date }}</td>
                      <td>{{ record.offense_date | date }}</td>
                      <td>{{ record.offense_description }}</td>
                      <td>{{ record.created_at | datetime }}
                      <template v-if="record.archived_at">
                        <span class="badge badge-warning">Archived</span>
                      </template>
                      <template v-if="record.trashed_at">
                        <span class="badge badge-danger">Trashed</span>
                      </template>
                      </td>
                      <td>{{ record.updated_at | datetime }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

            </div>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Col-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';
import _ from "lodash";

export default {
    mixins: [ queries ],
    data () {
        return {
            processing: false,
            searching: false,
            downloading: false,
            errors: null,
            search_clients: '',
            total: 0,
            form: {},
            client: {},
            clients: [],
            records: [],
            downloads: [],
            statuses: [],
            queries: {
                search: '',
                case_number: '',
                channel: '',
                optin: '',
                arrest_date: null,
                offense_date: null,
                file_date: null,
                offense_description: null,
                limit: 10,
                skip: 0,
                slim: true,
                upload: null,
                archived: false,
                trashed: false,
                sort: 'created_at',
                sortDirection: 'desc'
            },
            columns: [
            { key: 'case_number', label: 'Case Number'},
            { key: 'client', label: 'Client'},
            { key: 'location', label: 'Location'},
            { key: 'status', label: 'Status'},
            { key: 'type', label: 'Type'},
            { key: 'description', label: 'Description'},
            { key: 'file_date', label: 'File Date'},
            { key: 'arrest_date', label: 'Arrest Date'},
            { key: 'offense_date', label: 'Offense Date'},
            { key: 'offense_description', label: 'Offense'},
            { key: 'created_at', label: 'Created'},
            { key: 'updated_at', label: 'Updated'}
            ],
            distincts: [],
            locations: []
        }
    },

    mounted() {

        var self = this;

        this.$eventBus.$on('search_clients', function(uuid) {
           self.queries.client = uuid;
           self.getClient();

        });

        this.$eventBus.$on('errors', function (errors) {
            self.processing = false;
            self.errors = errors.data || errors;
        });

    },

    created () {

        this.resetForm();
        this.resetQueries();
        this.setURLParams();

        if(this.queries.client) {
            this.getClient();
        }

        if(!this.queries.client) {
            this.getRecords();        
        }

        this.getDistincts('offense_description');
        this.getLocations();
    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });

        },

        resetQueries() {
          this.queries = {
            search: null,
            location: null,
            archived: false,
            trashed: false,
            limit: 10,
            skip: 0
          }
        },

        searchRecords () {
            this.resetRecords();
            this.getRecords();
        },

        resetRecords() {
            this.records = [];
            this.total = 0;
            this.queries.skip = 0;
        },

        getRecords () {

            this.updateURL();

            this.getTotal();

            this.processing = true;
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.data) {

                    if(response.data.length) {

                        var self = this;

                        response.data.forEach(function(data) {
                            self.records.push(data);
                        });
                    }

                    this.queries.skip = this.records.length;
                    this.processing = false;

                    this.getStatuses();

                }
            })
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status === 200) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        closeClient() {
            this.queries.client = null;
            this.client = {};
            this.resetRecords();
            this.getRecords();
        },

        getClient() {

            if(!this.queries.client) {
                this.client = {};
                return;
            }

            this.$http.get('/clients/'+this.queries.client+'?slim=true')
            .then(response => {
                if(response && response.data) {
                    this.client = response.data;
                    this.resetRecords();
                    this.getRecords();
                }
            })
        },

        updateSortDirection() {

          if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
          } else {
            this.queries.sortDirection = 'asc';
          }

          this.resetRecords();

          this.getRecords();
        },

        sortColumn(column) {

          this.queries.sort = column.key;

          this.updateSortDirection();

        },

        resetForm() {
            this.form = {
                case_number: null,
                client: null
            }

            this.errors = null;
        },

        postForm() {

            if(this.client && this.client.uuid) {
                this.form.client = this.client.uuid;
            }
            
            this.processing = true;

            this.errors = null;

            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if(response && response.data) {

                    if(response.data.uuid) {
                        this.resetForm();
                        this.records.unshift(response.data);
                        this.total++;
                        new this.$noty({text: this.$route.meta.title + ' created'}).show();
                    }

                    this.processing = false;
                }
            })
        },


        searchClients() {
            this.searching = true;
            this.$http.get('/search?search='+this.search_clients)
            .then(response => {
                if(response && response.data) {
                    this.searching = false;
                    this.clients = response.data;
                }
            })
        },

        setClientCase(client) {
            this.form.client = client.uuid;
            this.queries.client = client.uuid;
            this.clients = [];
            this.getRecords();
        },

        getDistincts(field) {
          this.$http.get('/'+this.$route.meta.base_url+'/distincts?field='+field)
          .then(response => {
            if(response && response.data) {
              this.distincts[field] = response.data;
            }
          })
        },

      setLocationName(record) {

        if(!record.location) {
          return;
        }

        var location = _.find(this.locations, {'uuid': record.location});

        if(!location || !location.name) {
          return;
        }

        return location.name;
      },

      getLocations () {
        this.$http.get('/locations?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
            .then(response => {
              if (response && response.data) {
                this.locations = response.data;
              }
            })
      },

      getStatuses() {

        if(!this.processing) {
          this.processing = true;
        }

        this.$http.get('/statuses?slim=true&fields=name,uuid&limit=100&sort=name&sortDirection=asc&type=case')
            .then(response => {
              if (response && response.data) {
                this.statuses = response.data;
                if(this.processing === true) {
                  this.processing = false;
                }
              }
            })
      },

    async downloadPDF() {
    try {

      window.scrollTo(0,0);

      this.downloading = true;

      const element = document.getElementById('main');

      const canvas = await this.$html2canvas(element, {
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight,
        scrollY: -window.scrollY,
        scrollX: -window.scrollX,
        scale: window.devicePixelRatio,
        useCORS: true,
        logging: false
      });

      const imgData = canvas.toDataURL('image/png');

      const pdf = new this.$jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height]
      });

      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

      var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

      pdf.save(name+'.pdf');

      this.downloading = false;
    } catch (error) {
      new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
      console.log(error);
      this.downloading = false;
    }
  },

  downloadCSV () {
    this.downloading = true;

    var queries = this.queries;

    queries.limit = this.total;

    queries.skip = this.downloads.length;

    this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
        .then(response => {
          if(response && response.data && response.data) {
            this.downloads = this.downloads.concat(response.data);

            var csvFile, data, link;

            data = this.convertCSV(this.downloads);

            var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

            csvFile = new Blob([data], {type: "text/csv"});
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(csvFile, file);
            } else {
              link = document.createElement("a");
              link.download = file;
              link.href = window.URL.createObjectURL(csvFile);
              link.style.display = "none";
              document.body.appendChild(link);
              link.click();
            }

            this.downloading = false;

          } else {
            this.downloadCSV();
          }

        });
  },

  convertCSV(records) {

    var results = [];

    if(records[0] && records[0]['uuid']) {
      var headers = Object.keys(records[0]);
    }

    results += headers.join(',')

    results += '\n';

    records.forEach(function(item) {
      var record = '';

      if(!item) {
        return;
      }

      Object.keys(item).forEach(function(key) {
        record += '"' + (item[key] ? item[key] : '') + '",';
      });

      results += record;
      results += '\n';
    });

    return results
  },
    }
}
</script>
