<template>
  <div>
    <div
      id="map"
      class="map-lg"
    />
  </div>
</template>


<script>
export default {

props: ['record'],

data() {
    return {
        infowindow: {},
        place: {},
        map: {},
        zoom: 4
    }
},

mounted() {
    this.initMap();

    this.setMarker();
},

methods: {
      
    initMap() {

          const light = window.L.tileLayer(this.$root.map_url+'alidade_smooth/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
          });

          const dark = window.L.tileLayer(this.$root.map_url+'alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
          });

          const bright = window.L.tileLayer(this.$root.map_url+'osm_bright/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
          });

          const outdoors = window.L.tileLayer(this.$root.map_url+'outdoors/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
          });

          const baseLayers = {
            'Light': light,
            'Dark': dark,
            'Bright': bright,
            'Outdoors': outdoors,
          };

        this.map = window.L.map("map", {layers: light}).setView(this.$root.coordinates, 4);

        window.L.control.layers(baseLayers).addTo(this.map);

    },

    setMarker() {

        if(!this.record.lat || !this.record.lng) {
            return;
        }

        const courtIcon = window.L.divIcon({
          html: '<i class="fa fa-building-columns text-primary fa-2x"></i>',
          iconSize: [20, 20],
          className: 'map-icon'
        });

        var popup = '<p class="m-0">Address: '+this.record.full_address+'</p>';

        var marker = window.L.marker([this.record.lat, this.record.lng], {icon: courtIcon, draggable: true}).addTo(this.map).bindPopup(popup);

        var self = this;

        marker.on('dragend', function(event) {
          var coords = marker.getLatLng();
          self.record.lat = coords.lat;
          self.record.lng = coords.lng;
          self.$eventBus.$emit('updateCoordinates', self.record);
        });

        this.map.flyTo([this.record.lat, this.record.lng], 14);
    }


    }
}
</script>