<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row">
      <div class="col-sm-12 col-md-2">
          <h1>{{ form.name }}</h1>
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-2">
        <template v-if="qr_code">
          <a :href="setPortalLink()" target="_blank">
            <img
                :src="'data:image/svg+xml;base64,'+qr_code"
                width="50"
            >
          </a>
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">

        <div class="btn-group">
            <a :href="setPortalLink()" target="_blank" class="btn btn-outline-secondary" :data-tooltip="'Open '+$route.meta.title" :disabled="processing"><i class="fa fa-link"></i> Open</a>
            <button type="button" class="btn btn-outline-secondary" @click="copyToClipboard(setPortalLink())" :data-tooltip="'Copy '+$route.meta.title+' Link'" :disabled="processing"><i class="fa fa-copy"></i> Copy</button>
        </div>

      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">

                <a
                  v-if="form.deleted_at || form.archived_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>

                <a
                    v-if="!form.deleted_at && !form.archived_at"
                    class="dropdown-item"
                    @click="archiveRecord()"
                >Archive</a>

                <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <div class="card">
          <div class="card-body">

    <form @submit.prevent="postForm()">

    <div class="form-group">
        <label for="pay_link_name">Pay Link Name</label>
        <input id="pay_link_name" type="text" class="form-control" v-model="form.name"
        >
    </div>

      <div class="form-group">
        <label for="portal">Portal

          <template v-if="form.portal">
            <a
                :href="'/portals/'+form.portal"
                target="_blank"
                data-tooltip="Edit Portal">
              <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
            </a>
          </template>

        </label>
        <select
            id="portal"
            v-model="form.portal"
            class="form-control"
            :disabled="processing"
            required
        >
          <option value="">
            Choose Portal
          </option>
          <template v-for="portal in portals">
            <option
                :key="portal.uuid"
                :value="portal.uuid"
            >
              {{ portal.name }}
            </option>
          </template>
        </select>
      </div>

      <div class="form-group">
        <label for="location">Location

          <template v-if="form.location">
            <a
                :href="'/locations/'+form.location"
                target="_blank"
                data-tooltip="Edit Location">
              <i class="fa fa-edit"></i> <span class="sr-only">Edit Location</span>
            </a>
          </template>

        </label>
        <select
            id="location"
            v-model="form.location"
            class="form-control"
            :disabled="processing"
        >
          <option value="">
            Choose Location
          </option>
          <template v-for="location in locations">
            <option
                :key="location.uuid"
                :value="location.uuid"
            >
              {{ location.name }}
            </option>
          </template>
        </select>
      </div>

      <div class="form-group">
        <label for="amount">Default Amount</label>
        <div class="input-group">
        <span
            class="input-group-text"
            style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
        >$</span>
          <input
              id="amount"
              v-model="form.amount"
              type="number"
              class="form-control"
              step="0.01"
          >
        </div>
      </div>


      <div class="form-group">
        <label for="min_amount">Minimum Amount</label>
        <div class="input-group">
        <span
            class="input-group-text"
            style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
        >$</span>
          <input
              id="min_amount"
              v-model="form.min_amount"
              type="number"
              class="form-control"
              step="0.01"
          >
        </div>
      </div>


      <div class="form-group">
        <label for="max_amount">Maximum Amount</label>
        <div class="input-group">
        <span
            class="input-group-text"
            style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
        >$</span>
          <input
              id="max_amount"
              v-model="form.max_amount"
              type="number"
              class="form-control"
              step="0.01"
          >
        </div>
      </div>

      <div class="form-group">
        <label for="auto_message" class="form-label">Auto Message
          <template v-if="form.auto_message">
            <a
                :href="'/auto_messages/'+form.auto_message"
                target="_blank"
                data-tooltip="Edit Auto Message">
              <i class="fa fa-edit"></i> <span class="sr-only">Edit Auto Message</span>
            </a>
          </template>
        </label>
        <select id="auto_message" class="form-control" v-model="form.auto_message" :disabled="processing">
          <option value=""></option>
          <template v-for="(auto_message, index) in auto_messages">
            <option :key="'auto_message_'+index" :value="auto_message.uuid">{{ auto_message.name }}</option>
          </template>
        </select>
      </div>

      <p>Enabled Fields</p>
      <template v-for="(field, field_index) in availableFields">
        <button :key="'field_'+field_index" type="button" class="btn btn-sm" :class="setEnabledFieldClass(field)" @click="toggleField(field)" :disabled="processing">{{ field.name }}</button>
      </template>

      <template v-if="form.fields && form.fields.length > 0">
        <ul class="list-group my-3">
          <draggable
              v-model="form.fields"
              group="fields"
              @start="drag=true"
              @end="drag=false"
          >
            <li
                v-for="field in form.fields"
                :key="'enabled_fields_'+field.key"
                class="list-group-item"
            >
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <label :for="'enabled_fields_'+field.name">Edit {{ field.name }}</label>
                  <input
                      :id="'enabled_fields_'+field.name"
                      v-model="field.name"
                      type="text"
                      class="form-control form-control-sm"
                  >
                </div><!--Col-->

                <div class="col-sm-12 col-md-3">
                  <div class="form-group custom-control custom-switch">
                    <input
                        :id="'required_field_'+field.key"
                        v-model="field.required"
                        type="checkbox"
                        class="custom-control-input"
                    >
                    <label
                        class="custom-control-label"
                        :for="'required_field_'+field.key"
                    > Required</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-3 text-right">
                  <button
                      type="button"
                      class="btn btn-secondary btn-sm"
                  >
                    <i class="fa fa-bars" /> <span class="sr-only">Move</span>
                  </button>
                </div><!--Col-->
              </div><!--Row-->
            </li>
          </draggable>
        </ul>
      </template>

      <br>

      <button type="submit" class="btn btn-success my-3" :disabled="processing">Save</button>

    </form>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">
        <div class="card">
          <div class="card-body">

            <div class="row mb-3">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">Multilingual Title</p>
                <p class="card-text">Add a title in any enabled language.</p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-right">
                <div class="btn-group">
                  <button type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false" :disabled="processing">
                    <i class="fa fa-qrcode"></i> Generate QR
                  </button>
                  <div class="dropdown-menu">
                    <template v-for="(language, qr_language) in languages">
                      <a :key="'qr_language_'+qr_language" class="dropdown-item" :href="setQRLink(language.key)" target="_blank">{{ language.label }}</a>
                    </template>
                  </div>
                </div>
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="postForm()">

              <template v-for="(language, index) in languages">

                <div :key="'language_title_'+index">

                  <template v-if="form.title && form.title[language.key]">

                    <div class="form-group mt-3">
                      <label
                          :for="'title_'+index"
                          class="text-capitalize"
                      >
                        {{ language.label }} Title</label>
                      <input
                          :id="'title_'+index"
                          class="form-control"
                          :dir="setLangDirection(language)"
                          v-model="form.title[language.key]"
                      />
                    </div>

                  </template>

                  <template v-else>
                    <button type="button" class="btn btn-outline-primary btn-sm mb-1" @click="addTitle(language)" :disabled="processing">Add {{ language.label }} Title</button>
                  </template>

                </div>

                <template v-if="form.title[language.key]">
                <button type="button" class="btn btn-outline-success btn-sm my-1" @click="autoTranslate('title', language)" :disabled="processing" :hidden="language.key == 'en'">Auto Translate {{ language.label }}</button>
                </template>

              </template>

              <hr class="my-3">

              <p class="card-title">Multilingual Description</p>

              <p class="card-text">Add a description in any enabled language.</p>

              <template v-for="(language, index) in languages">

                <div :key="'language_description_'+index">

                  <template v-if="form.description && form.description[language.key]">

                    <div class="form-group mt-3">
                      <label
                          :for="'description_'+index"
                          class="text-capitalize"
                      >
                        {{ language.label }} Description</label>
                      <textarea
                          :id="'description_'+index"
                          v-model="form.description[language.key]"
                          class="form-control"
                          :dir="setLangDirection(language)"
                          rows="5"
                      />
                    </div>

                  </template>

                  <template v-else>
                    <button type="button" class="btn btn-outline-primary btn-sm my-1" @click="addDescription(language)" :disabled="processing">Add {{ language.label }} Description</button>
                  </template>

                  <template v-if="form.description[language.key]">
                  <button type="button" class="btn btn-outline-success btn-sm my-1" @click="autoTranslate('description', language)" :disabled="processing" :hidden="language.key == 'en'">Auto Translate {{ language.label }}</button>
                  </template>

                </div>


              </template>

              <button type="submit" class="btn btn-success" :disabled="processing" hidden>Save</button>

            </form>

          </div>
        </div>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">
          <p class="card-title"><a :href="'/payments'">{{ payments.length }} of {{ total_payments }} Payments</a></p>

          <template v-if="payments && payments.length > 0">
            <ul class="list-group">
            <template v-for="(payment, payment_index) in payments">
              <router-link :key="'payment_'+payment_index" tag="li" :to="{ 'name': 'payments.edit', 'params': {'id': payment.uuid} }" class="list-group-item list-group-item-action">
                {{ payment.amount | currency }} Due {{ payment.due_at | date }} <template v-if="payment.status"><span class="badge badge-pill" :class="setStatusClass(payment.status)">{{ payment.status }}</span></template>
              </router-link>
            </template>
            </ul>
          </template>


      </div><!--Col-->


    </div><!--Row-->

  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';
import draggable from 'vuedraggable';

export default {
  components: {draggable},

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      uploading: false,
      form: {},
      queries: {},
      portals: [],
      portal: {},
      qr_code: null,
      locations: [],
      languages: [],
      payments: [],
      auto_messages: [],
      total_payments: 0,
      availableFields: [
        {key: 'contact', type: 'text', name: 'Phone or Email'},
        {key: 'payment_reference', type: 'text', name: 'Payment Reference'},
        {key: 'citation', type: 'text', name: 'Citation Number'},
        {key: 'case_number', type: 'text', name: 'Case Number'},
        {key: 'type', type: 'text', name: 'Payment Type'},
        {key: 'notes', type: 'text', name: 'Notes'}
      ]
    }
  },

  created() {
    this.resetForm();
    this.getLanguages();
    this.getRecord();
    this.getPortals();
    this.getLocations();
    this.getAutoMessages();
  },

  methods: {

    resetForm() {
      this.form = {
          amount: 0,
          min_amount: 0,
          max_amount: 0,
          name: null,
          description: {
            'en': ''
          },
          title: {
            'en': ''
          },
          portal: null,
          location: null
      }
    },

    setPortalLink() {

      if(!this.portal || !this.portal.link) {
        return;
      }

      if(!this.form || !this.form.url) {
        return;
      }

      var language = this.form.language || 'en';

      return this.buildQueries('https://'+this.portal.link+'.'+this.portal.domain+'/l/'+this.form.url, {lang: language});
    },

    setQRLink(language) {

      if(!this.portal || !this.portal.link) {
        return;
      }

      if(!this.form || !this.form.url) {
        return;
      }

      var queries = {};

      queries['content'] = 'https://'+this.portal.link+'.'+this.portal.domain+'/l/'+this.form.url+'?lang='+language;

      queries['format'] = 'pdf';

      queries['language'] = language;

      if(this.form.title && this.form.title[language]) {
        queries['title'] = this.form.title[language];
      }

      if(this.form.description && this.form.description[language]) {
        queries['description'] = this.form.description[language];
      }

      if(this.form.amount && this.form.amount > 0) {
        queries['amount'] = this.form.amount;
      }

      if(this.portal && this.portal.logo) {
        queries['logo'] = 'https://ecdportals.s3-us-gov-east-1.amazonaws.com/'+this.portal.logo;
      }

      return this.buildQueries('/qrs', queries);
    },

    downloadQR() {

        this.$http.post('/qrs?content='+this.setPortalLink())
        .then(response => {
            if(response && response.data) {
                this.processing = false;
                let a = document.createElement('a');
                a.href = 'data:;base64,' + response.data;

                a.download = this.slugString(this.form.name + '-' + this.$route.meta.title)+'.png';

                a.click();
            }
        })
    },

    slugString(str) {

        str = str.replace(/^\s+|\s+$/g, '');
        str = str.toLowerCase();

        str = str.replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-'); 

        return str;
    },

    getLocations () {
        this.$http.get('/locations?limit=1000&sort=name&sortDirection=asc&slim=true&fields=uuid,name')
        .then(response => {
            if (response && response.data) {
                this.locations = response.data;
            }
        })
    },

    getPortals() {
      this.$http.get('/portals?slim=true&limit=100&fields=name,uuid,logo&domain=ecourtpayment.com')
      .then(response => {
        if(response && response.data) {
          this.portals = response.data;
        }
      })
    },

    getPortal() {

      if(!this.form.portal) {
        return;
      }

      if(this.portal && this.portal.uuid) {
        if(this.form.portal == this.portal.uuid) {
          return;
        }
      }

      this.$http.get('/portals/'+this.form.portal+'?slim=true&fields=uuid,domain,link')
          .then(response => {
            if(response && response.data) {
              this.portal = response.data;

              this.getQRCode();
            }
          })
    },

    getQRCode() {
      this.qr_code = null;
      this.$http.post('/qrs?content='+this.setPortalLink())
          .then(response => {
            if(response && response.data) {
              this.qr_code = response.data;
            }
          })
    },

    getRecord() {

        this.processing = true;

        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                
                this.form = response.data;

                if(!response.data.fields) {
                  this.form.fields = [];
                }

                if(!response.data.description) {
                  this.form.description = {
                    'en': ''
                  }
                }

                if(!response.data.title) {
                  this.form.title = {
                    'en': ''
                  }
                }

                this.processing = false;

                this.setTitle();

                this.getPortal();

                this.getPayments();
            }
        })
    },

    setTitle() {
      document.title = this.form.name + ' | ' + this.$route.meta.title;
    },

    getPayments() {

      this.$http.get('/payments/total')
          .then(response => {
            if(response && response.status) {
              this.total_payments = response.data;
            }
          })


      this.$http.get('/payments')
          .then(response => {
            if(response && response.data) {
              this.payments = response.data;
            }
          })
    },

    getLanguages() {
        this.$http.get('/languages')
        .then(response => {
            if(response && response.data) {
                this.languages = response.data;
            }
        })
    },

    addTitle(language) {
      this.form.title[language.key] = language.label + ' Title here';

      this.postForm();
    },

    addDescription(language) {
      this.form.description[language.key] = language.label + ' Description here';

      this.postForm();
    },

    postForm() {

      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
          }

        }
      })
    },


    restoreRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
      .then(response => {
        if(response && response.data) {
              
              this.processing = false;

              if(response.status === 200) {
                new this.$noty({'text': this.$route.meta.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
                this.form.updated_at = new Date().toISOString();
              }

        }
      })
    },

    archiveRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/archive')
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({'text': this.$route.meta.title + ' archive'}).show();
                this.form = {...this.form, ...response.data};
              }

            }
          })
    },

  trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
      .then((response) => {
        if(response && response.status) {
          this.processing = false;

          if(response.status === 204) {
            new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
            this.form.deleted_at = new Date().toISOString();
          }
        }
      })
  },

    setLanguageName(record) {

      if(!record.language) {
        return;
      }

      var language = _.find(this.languages, {'key': record.language});

      if(!language || !language.label) {
        return record.language;
      }

      return language.label;
    },

    setLangDirection(language) {

      if(!language.key) {
        return 'ltr';
      }

      if(this.$root.rtls.includes(language.key)) {
        return 'rtl';
      }

      return 'ltr';
    },

    setStatusClass(status) {
      if(status == 'outstanding') {
        return 'badge-danger';
      }

      if(status == 'paid') {
        return 'badge-success';
      }

      return 'badge-primary';
    },

    toggleField(field) {

      if(!this.form.fields) {
        this.form.fields = [];
      }

      if(_.find(this.form.fields, {'key': field.key})) {
        this.form.fields = _.reject(this.form.fields, {'key': field.key});
        return;
      }

      field.required = true;

      this.form.fields.push(field);
    },

    setEnabledFieldClass(field) {
      if(_.find(this.form.fields, {'key': field.key})) {
        return 'btn-primary';
      }

      return 'btn-secondary';
    },

    autoTranslate(field, language) {

      this.processing = true;

      this.errors = null;

      var translator = {
        source_language: 'en',
        target_language: language.key,
        content: this.form[field]['en']
      }

      this.$http.post('/translator', translator)
          .then(response => {
            if(response && response.data) {

              if(response.data.content) {
                this.form[field][language.key] = response.data.content;
                new this.$noty({text: 'Added translation'}).show();
              }

              this.processing = false;
            }
          })
    },

    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      new this.$noty({text: 'Copied to clipboard'}).show();
    },

    getAutoMessages() {
      this.$http.get('/auto_messages?limit=1000&slim=true&fields=uuid,name&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.auto_messages = response.data;
            }
          })
    },

  }
}
</script>