<template>
    <div>

<div class="row">

<div class="col-sm-12 col-md-4">

    <h4>{{ form.name }} {{ $route.meta.title }}s</h4>

  <p class="card-title">
    {{ records.length }} of {{ total.toLocaleString() }} Total {{ $route.meta.title }}s
  </p>

</div><!--Col-->

<div class="col-sm-12 col-md-4">
    <router-link :to="{'name': 'forms.edit', 'params': { 'id': $route.params.id } }" tag="button" class="btn btn-outline-primary" :disabled="processing">
            <i class="fa fa-edit"></i> Edit Form
    </router-link>
</div>

<div class="col-sm-12 col-md-4 text-right">

  <div class="btn-group d-print-none">
    <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
    <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
  </div>
</div><!--Col-->

</div><!--Row-->


    <table class="table table-hover table-striped" style="width:100%">
      <thead>
        <tr>
        <th style="width:5%">Edit</th>
          <th v-for="key in tableColumns" :key="key" :hidden="isHiddenColumn(key)">{{ key }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in records" :key="index">
            <td>
                <template v-if="current_row !== index">
                <router-link :to="{'name': 'submissions.edit', 'params': { 'id': row.uuid } }" tag="button" class="btn btn-outline-primary" :disabled="processing">
                    <i class="fa fa-edit"></i> Edit
                </router-link>
                </template>

                <template v-if="current_row === index">
                   <loader :isLoading="processing"></loader>
                </template>
            </td>
            <td v-for="(value, key) in row" :key="key" contenteditable @input="updateCell(index, key, $event)" :hidden="isHiddenColumn(key)">{{ value }}</td>
        </tr>
      </tbody>
    </table>
  
    </div>
  </template>
  <script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {

mixins: [ queries ],
  
  data() {
      return {
        processing: false,
        downloading: false,
        form: {},
        records: [],
        downloads: [],
        total: 0,
        current_row: null
      }
  },
  
  computed: {
  
    tableColumns: function() {
  
      if(!this.records || !this.records.length) {
        return [];
      }
  
      return Object.keys(this.records[0]);
    }
  
  },

  mounted() {

    var self = this;

    this.$eventBus.$on(this.$route.meta.base_url, function(data) {

        if(!data.uuid || !data.form) {
          return;
        }

        if(data.form !== self.form.uuid) {
          return;
        }

        if(!self.records || !self.records.length) {
          self.records = [];
        }

        var index = self.records.findIndex(function(record) {
          return record.uuid === data.uuid;
        });

        if(index > -1) {
          self.records[index] = self.mapRecord(data);
        } else {
          self.records.unshift(self.mapRecord(data));
        }

    });


},
  
  created() {
      this.getForm();
      this.getRecords();
  },
  
  methods: {

    getForm() {
            
            if(!this.$route.params.id) {
                return;
            }
    
            this.$http.get('/forms/'+this.$route.params.id)
            .then(response => {
                if(response && response.data) {
                    this.form = response.data;

                    this.setTitle();
                }
            })
    },

    setTitle() {
      if(!this.form.name) {
        return;
      }

      document.title = this.form.name + ' ' + this.$route.meta.title + 's';
    },

    mapRecord(record) {
        var data = record.data;
        data.uuid = record.uuid;
        data.created_at = record.created_at;
        data.updated_at = record.updated_at;
        data.archived_at = record.archived_at;
        data.deleted_at = record.deleted_at;
        return data;
    },
  
      getRecords() {
        

          this.processing = true;
  
          this.records = [];
  
          this.getTotal();
  
          this.$http.get('/'+this.$route.meta.base_url+'?form='+this.$route.params.id)
          .then(response => {
              if(response && response.data) {
  
                this.records = response.data.map(this.mapRecord);

                this.processing = false;
              }
          })
      },
  
      getTotal() {
          
          this.total = 0;
  
          this.$http.get('/'+this.$route.meta.base_url+'/total?form='+this.$route.params.id)
          .then(response => {
              if(response && response.status === 200) {
                  this.total = response.data;
              }
          })
      },
  
      updateCell(rowIndex, key, event) {
        this.records[rowIndex][key] = event.target.textContent;

        this.updateSubmission(rowIndex);
      },

      updateSubmission: _.debounce(function (index) {
        this.postSubmission(index)
        }, 600),

      postSubmission(index) {

        var form = {
            data: this.records[index]
        }

        this.current_row = index;

        this.processing = true;
        this.$http.patch('/'+this.$route.meta.base_url+'/'+this.records[index].uuid, form)
        .then(response => {
            if(response && response.data) {
                this.processing = false;
                this.records[index].updated_at = response.data.updated_at;
                this.current_row = null;
                new this.$noty({text: this.$route.meta.title + ' updated'}).show();
            }
        })
      },
  
      isHiddenColumn(key) {
  
        var hiddenColumns = ['uuid', 'agency', 'region', 'portal', 'location', 'archived_at', 'deleted_at'];
  
        return hiddenColumns.indexOf(key) > -1;
      },

      async downloadPDF() {
    try {

      window.scrollTo(0,0);

      this.downloading = true;

      const element = document.getElementById('main');

      const canvas = await this.$html2canvas(element, {
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight,
        scrollY: -window.scrollY,
        scrollX: -window.scrollX,
        scale: window.devicePixelRatio,
        useCORS: true,
        logging: false
      });

      const imgData = canvas.toDataURL('image/png');

      const pdf = new this.$jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height]
      });

      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

      var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

      pdf.save(name+'.pdf');

      this.downloading = false;
    } catch (error) {
      new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
      console.log(error);
      this.downloading = false;
    }
  },

  downloadCSV () {

    if(!this.total) {
      return;
    }

    this.downloading = true;

    var queries = {};

    queries.limit = this.total;

    queries.skip = this.downloads.length;

    this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
        .then(response => {
          if(response && response.data && response.data) {


            this.downloads = response.data.map(function(record) {
                    var data = record.data;
                    data.uuid = record.uuid;
                    data.created_at = record.created_at;
                    data.updated_at = record.updated_at;
                    data.archived_at = record.archived_at;
                    data.deleted_at = record.deleted_at;
                    return data;
                  });

            // this.downloads = this.downloads.concat(response.data);

            this.downloads = _.uniqBy(this.downloads, 'uuid');



            var csvFile, data, link;

            data = this.convertCSV(this.downloads);

            var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

            csvFile = new Blob([data], {type: "text/csv"});
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(csvFile, file);
            } else {
              link = document.createElement("a");
              link.download = file;
              link.href = window.URL.createObjectURL(csvFile);
              link.style.display = "none";
              document.body.appendChild(link);
              link.click();
            }

            this.downloading = false;

          } else {
            this.downloadCSV();
          }

        });
  },

  convertCSV(records) {

    var results = [];

    if(records[0] && records[0]['uuid']) {
      var headers = Object.keys(records[0]);
    }

    results += headers.join(',')

    results += '\n';

    records.forEach(function(item) {
      var record = '';

      if(!item) {
        return;
      }

      Object.keys(item).forEach(function(key) {
        record += '"' + (item[key] ? item[key] : '') + '",';
      });

      results += record;
      results += '\n';
    });

    return results
  },
  
  }
  
  }
  </script>