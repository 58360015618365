<template>
  <div>

<page-tabs page="message_templates" />

      <div class="row" id="main">
        <div class="col-sm-12 col-md-3">

          <div class="card">
            <div class="card-body">

              <p class="card-title">{{ merge_tags.length }} Merge Tags</p>

              <template v-if="merge_tags && merge_tags.length">
                <ul class="list-group mb-3">
                  <template v-for="(tag, index) in merge_tags">
                    <button
                      :key="'merge_tag_'+index"
                      type="button"
                      class="list-group-item list-group-item-action"
                    >
                      {{ tag }}
                    </button>
                  </template>
                </ul>
              </template>


            </div><!--Body-->

        </div><!--Card-->

        </div><!--Col-->
        
      </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
        processing: false,
        merge_tags: [],
    }
},



created() {
  this.getMergeTags();
},

methods: {


    getMergeTags () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/mergetags.json')
      .then(response => {
          if (response && response.data) {
            this.merge_tags = response.data.map(tag => { return tag.value });
          }
      })
    },

  

}

}
</script>