<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              IP Address 

              <template v-if="ip_address.location && ip_address.location.country_flag_emoji">
                {{ ip_address.location.country_flag_emoji }}
              </template>
            </p>

            <ul class="list-group">
              <template v-if="ip_address.ip">
                <li class="list-group-item">
                  IP ADDRESS {{ ip_address.ip }}
                </li>
              </template>

              <template v-if="ip_address.type">
                <li class="list-group-item">
                  TYPE {{ ip_address.type }}
                </li>
              </template>

              <template v-if="ip_address.region_name">
                <li class="list-group-item">
                  LOCATION {{ ip_address.city }} {{ ip_address.region_name }} {{ ip_address.zip }} {{ ip_address.country_code }}
                </li>
              </template>

              <template v-if="ip_address.connection && ip_address.connection.isp">
                <li class="list-group-item">
                  ISP {{ ip_address.connection.isp }}
                </li>
              </template>

              <template v-if="ip_address.time_zone && ip_address.time_zone.id">
                <li class="list-group-item">
                  TIME ZONE {{ ip_address.time_zone.id }} {{ ip_address.time_zone.code }}
                </li>
              </template>

              <template v-if="ip_address.latitude">
                <li class="list-group-item">
                  Latitude {{ ip_address.latitude}}
                </li>
              </template>

              <template v-if="ip_address.longitude">
                <li class="list-group-item">
                  Longitude {{ ip_address.longitude }}
                </li>
              </template>
            </ul>

            <timestamps :form="ip_address" />
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">
              {{ geolocations.length }} Geolocations
            </p>

            <ul class="list-group">
              <template v-if="geolocations && geolocations.length > 0">
                <template v-for="(geo_option, index) in geolocations">
                  <li
                    :key="'geo_option_'+index"
                    class="list-group-item"
                  >
                    <a :href="'/geolocations/'+geo_option.uuid">{{ geo_option.latitude }} {{ geo_option.longitude }} <span
                      v-if="geo_option.error && geo_option.error.message"
                      class="badge badge-pill badge-danger text-uppercase"
                    >{{ geo_option.error.message }}</span></a>
                  </li>
                </template>
              </template>
            </ul>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-md-9">
        <div
          id="map"
          class="map-lg"
        />
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
export default {

  data () {
    return {
      processing: false,
      geolocation: {},
      map: null,
      client: {},
      geolocations: [],
      ip_address: {}
    }
  },

  created() {
    this.getIPAddress()
    this.getGeolocations()
  },

  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  },

  methods: {

    getIPAddress() {
      this.$http.get('/ips/'+this.$route.params.id)
      .then(response => {
        if(response && response.data) {
          this.ip_address = response.data

          if(this.ip_address.latitude && this.ip_address.longitude) {
            this.initMap()
          }
        }
      })
    },

    getGeolocations() {
      this.$http.get('/geolocations?ip_address='+this.$route.params.id)
      .then(response => {
        if(response && response.data) {
          this.geolocations = response.data
        }
      })
    },

    initMap() {
        this.map = window.L.map("map").setView([this.ip_address.latitude, this.ip_address.longitude], 10);
        window.L.tileLayer(this.$root.map_url+'alidade_smooth/{z}/{x}/{y}{r}.png?api_key='+this.$root.map_token, {
        }).addTo(this.map);

        window.L.circle([this.ip_address.latitude, this.ip_address.longitude], {
          color: 'red',
          fillColor: '#f03',
          fillOpacity: 0.5,
          radius: 10000
        }).addTo(this.map);
    },

  },
}
</script>