<template>
  <div>


    <template v-if="data_points && data_points.length && !processing">
      <div class="row mb-3">
        <template v-for="data_point in data_points">
          <div
            :key="data_point.label"
            class="col-3 mb-3"
          >
            <div
              class="card text-center"
              style="height: 100%"
            >
              <div
                class="card-body d-flex justify-content-center flex-column"
                style="padding:.4rem !important"
              >
                <h4 class="m-0">
                  <template v-if="data_point.value">
                    {{ data_point.value }}
                  </template>
                  <template v-if="!data_point.value">
                    0
                  </template>
                  <small v-if="data_point.symbol">{{ data_point.symbol }}</small>
                </h4>

                <p class="card-title m-0 text-capitalize">
                  <a :href="'/datapoints/'+data_point.title+'?from_date='+from_date+'&from_time='+from_time+'&to_date='+to_date+'&to_time='+to_time+'&interval=1&period=month'">{{ data_point.title }}</a>
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {

  props: ['datapoints', 'from_date', 'to_date', 'from_time', 'to_time'],

  data () {
    return {
      base_url: '/datapoints',
      processing: false,
      form: {},
      data_points: {}
    }
  },

  created () {
    
    this.getDatapoints();

    var self = this;

    this.$eventBus.$on('reportUpdated', function () {
      self.getDatapoints();
    });

  },
  methods: {
    getDatapoints () {
      this.processing = true;
      this.form.dataset = this.dataset;
      this.form.report = this.$route.params.id;
      this.form.from_date = this.from_date;
      this.form.from_time = this.from_time;
      this.form.to_date = this.to_date;
      this.form.to_time = this.to_time;
      const dataPoints = this.datapoints.map((datapoint) => datapoint.title);

      if(!dataPoints) {
        return;
      }

      this.form.datapoints = dataPoints;
      this.$http.post(this.base_url, this.form)
      .then(response => {
        if (response && response.data) {
          const datapoints = Object.assign(this.datapoints, response.data);
          this.data_points = datapoints;
          this.processing = false;
        }
      })
    }
  }
}
</script>
