<template>
  <div>

    <page-tabs page="settings" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name">Name</label>
                <input
                    id="name"
                    v-model="form.name"
                    class="form-control"
                    required
                >
              </div>

              <div class="form-group">
                <label for="reference">Reference</label>
                <input
                    id="reference"
                    v-model="form.reference"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="type">Type</label>
                <select id="type" class="form-control text-capitalize" v-model="form.type">
                  <option value=""></option>
                  <template v-if="(types && types.length > 0)">
                    <template v-for="(type, index) in types">
                      <option :key="'type_'+index" :value="type">{{ type }}</option>
                    </template>
                  </template>
                </select>
              </div>

              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                Add
              </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">Search {{ $route.meta.title }}s</p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input id="search" type="search" class="form-control" v-model="queries.search">
              </div>

              <div class="form-group">
                <label for="search_reference">Reference</label>
                <input id="search_reference" type="search" class="form-control" v-model="queries.reference">
              </div>

              <div class="form-group">
                <label for="search_phone">Phone</label>
                <input id="search_phone" type="search" class="form-control" v-model="queries.phone">
              </div>

              <div class="form-group">
                <label for="search_email">Email</label>
                <input id="search_email" type="search" class="form-control" v-model="queries.email">
              </div>

              <div class="form-group">
                <label for="search_website">Website</label>
                <input id="search_website" type="search" class="form-control" v-model="queries.website">
              </div>

              <div class="form-group">
                <label for="search_type">Type</label>
                <select id="search_type" class="form-control text-capitalize" v-model="queries.type" :disabled="processing">
                  <option value=""></option>
                  <template v-if="(types && types.length > 0)">
                    <template v-for="(type, index) in types">
                      <option :key="'search_type_'+index" :value="type">{{ type }}</option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="archived"
                        v-model="queries.archived"
                        type="checkbox"
                        class="custom-control-input"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="trashed"
                        v-model="queries.trashed"
                        type="checkbox"
                        class="custom-control-input"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>

            </form>

          </div>
        </div>

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
                </p>
              </div><!--Col-->

              <div class="col-md-4" /><!--Col-->
            </div><!--Row-->


            <div class="table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                <tr>
                  <template v-for="(column, index) in columns">
                    <th
                        :key="'column_'+index"
                        class="text-uppercase"
                    >
                      <template v-if="column.key">
                        <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
                </thead>

                <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="(record, index) in records">
                    <tr :key="'record_'+index">
                      <td>
                        <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid}}">
                          <i class="fa fa-edit"></i> <span class="sr-only">Edit</span> {{ record.name }}
                        </router-link>
                      </td>
                      <td>
                        <template v-if="record.reference">
                          <span class="badge badge-secondary">{{ record.reference }}</span>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.type">
                          <span class="badge badge-secondary text-uppercase">{{ record.type }}</span>
                        </template>
                      </td>
                      <td>{{ record.address }}</td>
                      <td>{{ record.phone }}</td>
                      <td>{{ record.email }}</td>
                      <td>{{ record.description }}</td>
                      <td>{{ record.website }}</td>
                      <td>{{ record.virtual_link }}</td>
                      <td>{{ record.created_by }}

                        <template v-if="record.archived_at">
                          <span class="badge badge-warning">Archived</span>
                        </template>

                        <template v-if="record.trashed_at">
                          <span class="badge badge-danger">Trashed</span>
                        </template>

                      </td>
                      <td>{{ record.created_at | datetime }}</td>
                      <td>{{ record.updated_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div><!--Responsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

          <div class="row mt-3">
            <div class="col-12 text-center">

              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>

            </div><!--Col-->
          </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from "lodash";
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      validating: false,
      validated: false,
      total: 0,
      queries: {},
      records: [],
      form: {},
      columns: [
        { key: 'name', label: 'Name'},
        { key: 'reference', label: 'Reference'},
        { key: 'type', label: 'Type'},
        { key: 'address', label: 'Address'},
        { key: 'phone', label: 'Phone'},
        { key: 'email', label: 'Email'},
        { key: 'description', label: 'Description'},
        { key: 'website', label: 'Website'},
        { key: 'virtual_link', label: 'Virtual Link'},
        { key: 'created_by', label: 'Creator'},
        { key: 'created_at', label: 'Created'},
        { key: 'updated_at', label: 'Updated'}
      ],
      types: ['supervision', 'guardian', 'law enforcement', 'employer', 'school', 'court', 'medical', 'other'],
      locations: [],
      portals: []
    }
  },

  created() {
    this.resetForm();
    this.resetQueries();
    this.resetRecords();
    this.setURLParams();
    this.getRecords();
    this.getPortals();
    this.getLocations();
  },

  methods: {


    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    setStatusClass(record) {

      if(record.status == 'private') {
        return 'badge-danger';
      }

      if(record.status == 'draft') {
        return 'badge-secondary';
      }

      return 'badge-success';
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        name: null,
        search: null,
        reference: null,
        type: null,
        phone: null,
        email: null,
        website: null,
        address: null,
        slim: false,
        sort: 'created_at',
        sortDirection: 'desc',
        archived: false,
        trashed: false
      }
    },

    resetForm() {
      this.form = {
        name: null,
        reference: null,
        type: null,
        address: null,
        phone: null,
        email: null,
        website: null,
        description: null
      }
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    updateSortDirection() {

      if(this.processing) {
        return;
      }

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      if(this.processing) {
        return;
      }

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {

              if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                  self.records.push(data);
                });
              }

              this.queries.skip = this.records.length;

              this.processing = false;
            }
          })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              this.setTitle();
            }
          })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    postForm() {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.status === 201) {
                this.resetForm();
                this.total++;
                this.records.unshift(response.data);
                new this.$noty({text: this.$route.meta.title + ' created'}).show();
              }

            }
          })
    },

    setPortalName(record) {

      if(!record.portal) {
        return;
      }

      var portal = _.find(this.portals, {'uuid': record.portal});

      if(!portal || !portal.name) {
        return;
      }

      return portal.name;
    },

    setLocationName(record) {

      if(!record.location) {
        return;
      }

      var location = _.find(this.locations, {'uuid': record.location});

      if(!location || !location.name) {
        return;
      }

      return location.name;
    },

    setLanguageName(record) {

      if(!record.language) {
        return;
      }

      var language = _.find(this.languages, {'key': record.language});

      if(!language || !language.label) {
        return record.language;
      }

      return language.label;
    },

    getLocations () {
      this.$http.get('/locations?slim=true&limit=1000&sort=name&sortDirection=asc&fields=uuid,name')
          .then(response => {
            if (response && response.data) {
              this.locations = response.data;
            }
          })
    },

    getPortals () {
      this.$http.get('/portals?slim=true&limit=1000&sort=name&sortDirection=asc&fields=uuid,name,link')
          .then(response => {
            if (response && response.data) {
              this.portals = response.data;
            }
          })
    },


  },
}
</script>