<template>
  <div>
    <div class="row mb-3">

      <div class="col-sm-12 col-md-6">
        <template v-if="form.photo">
          <a :href="form.photo" target="_blank">
          <img
            :src="form.photo"
            class="rounded-circle float-left mr-1"
            width="50"
            height="50"
            :alt="form.name"
          />
          </a>
        </template>
        <template v-if="form.name">
          <h2 class="m-0">
            {{ form.name }}
          </h2>
        </template>
        <span
          class="mr-1"
          v-text="form.email"
        />
        <template v-if="form.email">
          <button type="button" class="btn btn-secondary btn-sm" @click="copyToClipboard(form.email)" data-tooltip="Copy Email to Clipboard" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
        </template>

        <template v-if="form.last_active">
          <br>
          <span class="badge badge-pill badge-success">Last Active {{ form.last_active | datetime }}</span>
        </template>

        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div>

      <div class="col-sm-12 col-md-6 text-right">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-secondary dropdown-toggle radius-right-0"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Tools
          </button>
          <div class="dropdown-menu">
            
            <a
            class="dropdown-item"
            @click="trashForm()"
            >Trash</a>
          </div>
          <button
            type="submit"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>
          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-3 mb-3">
        <template v-if="form && form.uuid">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a
                class="nav-link active"
                :href="'/'+$route.meta.base_url+'/'+form.uuid"
              >User Overview</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :href="'/clients?user='+form.uuid"
              >Assigned Clients</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :href="'/events?user='+form.uuid"
              >Assigned Events</a>
            </li>
          </ul>
        </template>
      </div>
      <div class="col-lg-10 col-md-9">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="postForm()">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="name">Name</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      required
                    >
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="language">Preferred Language</label>
                    <select
                      id="language"
                      v-model="form.language"
                      class="form-control"
                    >
                      <option value="" />
                      <option
                        v-for="language in languages"
                        :key="language.key"
                        :value="language.label"
                      >
                        {{ language.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="phone">Phone</label>
                    <input
                      id="phone"
                      v-model="form.phone"
                      type="tel"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->

                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="phone_ext">Extension</label>
                    <input
                      id="phone_ext"
                      v-model="form.phone_ext"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->
              </div>

              <div class="form-group">
                <label for="photo">Photo URL</label>
                <input id="photo" type="text" class="form-control" v-model="form.photo">
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="super_admin"
                      v-model="form.super_admin"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="super_admin"
                    >Super Admin</label>
                  </div>
                </div><!--Col-->

                <div class="col-lg-6">
                  <template v-if="superAdmin">
                    <div class="form-group">
                      <label for="roles">Roles</label><br>
                      <multi-select 
                        id="roles"
                        v-model="form.roles"
                        :multiple="true" 
                        placeholder="Select Roles"
                        :options="roles"
                        :close-on-select="false"
                      />
                    </div>
                  </template>

                  <template v-if="form.permissions">
                    <p class="text-muted small m-0">
                      {{ form.permissions.length }} Permissions
                    </p>
                    <span
                      v-for="permission in form.permissions"
                      :key="permission"
                      class="badge badge-pill badge-light"
                    >{{ permission }}</span>
                  </template>
                </div>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form && form.uuid">
          <audit-logs
            class="mt-3"
            :form="form"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import jsonParse from '../mixins/jsonParse';

export default {

  mixins: [ jsonParse ],
  data() {
    return {
      processing: false,
      form: {},
      languages: [],
      roles: []
    };
  },

  computed: {

    superAdmin () {
      return this?.$root?.current_user?.super_admin;
    }

  },

  created() {

    this.getRecord();
    this.getLanguages();
    this.getRoles();

  },

  methods: {

    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
      .then((response) => {
        if(response && response.data) {
          this.processing = false;
          this.form = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.form.email + ' | ' + this.$route.meta.title;
    },

    trashForm() {
      this.$http.delete('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
      .then(response => {
        if(response && response.status >= 200) {

          this.processing = false;

          this.$router.push('/'+this.$route.meta.base_url);

        }
      })
    },

    postForm() {

      this.processing = true;
      let form = {...this.form};

      delete form.email;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form = {...this.form, ...response.data};
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
          }
        }
      })
    },

    getLanguages() {
      this.$http.get('languages')
      .then(response => {
        if(response && response.data) {
          this.languages = this.parseJSON(response.data);
        }
      })
    },

    getRoles() {
      this.$http.get('roles')
      .then(response => {
        if(response && response.data) {
          this.roles = response.data.map(r => r.name);
        }
      })
    },

    copyToClipboard(value) {
        navigator.clipboard.writeText(value);
        new this.$noty({text: 'Copied to clipboard'}).show();
    }

    
  }
}
</script>
