<template>
  <div>

    <page-tabs page="events" />

    <div class="row">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add Availability
            </p>

            <form @submit.prevent="postForm()">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                  required
                >
              </div>

                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                  Add Availability
                </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->


        <div class="card mt-3">
          <div class="card-body">

            <p class="card-title">Search Availabilities</p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input id="search" type="search" class="form-control" v-model="queries.search">
              </div>

              <div class="form-group">
                <label for="created_by">Creator</label>
                <select 
                  id="created_by"
                  v-model="queries.created_by"
                  class="form-control" 
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(user, index) in users">
                    <option 
                      :key="'user_'+index" 
                      :value="user.email"
                    >
                      {{ user.name }} {{ user.email }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="trashed"
                >Trashed</label>
              </div>

              <button type="submit" class="btn btn-primary" :disabled="processing">Search Availabilities</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} Availabilities
                </p>
              </div><!--Col-->

              <div class="col-md-4" /><!--Col-->
            </div><!--Row-->

            <table class="table table-striped table-hover table-responsive">
              <thead>
                <tr>
                  <template v-for="(column, index) in columns">
                    <th :key="'column_'+index">
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>

              <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="(record, index) in records">
                    <tr :key="'record_'+index">
                      <td>
                        <router-link
                        :to="{name: 'availabilities.edit', params: {id: record.uuid}}"
                        >
                          <i class="fa fa-edit"></i>
                          <template v-if="record.name">{{ record.name }}</template>
                          <template v-else>{{ record.uuid }}</template>
                        </router-link>
                      </td>
                      <td>
                        <template v-if="record.portal">
                          <portal-button :uuid="record.portal"></portal-button>
                        </template>
                      </td>
                      <td>{{ record.event_type }}</td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.created_at | datetime }}

                        <span v-if="record.deleted_at" class="badge badge-pill badge-danger text-uppercase">Trashed</span>
                      </td>
                      <td>{{ record.updated_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No availabilities found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

        <div class="row mt-3">
          <div class="col-12 text-center">

              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>

          </div><!--Col-->
        </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      validating: false,
      validated: false,
      total: 0,
      base_url: '/availability_templates',
      queries: {
        limit: 10,
        slim: true,
        trashed: false,
        created_by: null,
        sort: 'name',
        sortDirection: 'asc'
      },
      records: [],
      form: {},
      columns: [
      { key: 'name', label: 'Name'},
      { key: 'portal', label: 'Portal'},
      { key: 'event_type', label: 'Event Type'},
      { key: 'creator', label: 'Creator'},
      { key: 'created_at', label: 'Created'},
      { key: 'updated_at', label: 'Updated'}
      ],
      users: []
    }
  },

  created() {
    this.resetForm();
    this.setURLParams();
    this.getRecords();
    this.getUsers();
  },

  methods: {

    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    resetForm() {
      this.form = {
        name: null
      }
    },

    updateSortDirection() {

      if(this.processing) {
        return;
      }

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      if(this.processing) {
        return;
      }

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    resetRecords() {
      this.total = 0;
      this.records = [];
      this.queries.skip = 0;
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries(this.base_url, this.queries))
      .then(response => {
        if(response && response.data) {
            if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                    self.records.push(data);
                });
            }
            
            this.queries.skip = this.records.length;

            this.processing = false;
        }
      })
    },

    getTotal() {
      this.$http.get(this.buildQueries(this.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/availabilities', this.queries));
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + ' | eCourtDate.com';
    },

    postForm() {

      this.processing = true;
      this.$http.post(this.base_url, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.status === 201) {
            this.resetForm();
            this.total++;
            this.records.unshift(response.data);
            new this.$noty({text: 'Portal created'}).show();
          }

        }
      })
    },

    getUsers() {
      this.$http.get('/users?slim=true&limit=1000&fields=name,email&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.users = response.data;
        }
      })
    }
   

  },
}
</script>