<template>
  <div>

    <page-tabs page="portals" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">
            <form @submit.prevent="searchRecords()">
              <div class="form-group">
                <label for="search">Search {{ $route.meta.title }}s</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                  placeholder="Search..."
                >
              </div>

              <div class="form-group">
                <label for="search_form">Form</label>
                <select id="search_form" class="form-control" v-model="queries.form" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(form_template, form_index) in form_templates">
                    <option :key="'form_template_'+form_index" :value="form_template.uuid">{{ form_template.name }}</option>
                  </template>
                </select>
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->


      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
            <table class="table table-striped table-hover">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="'/'+$route.meta.base_url"
              />

              <tbody>
                <template v-for="record in records">
                  <tr :key="record.uuid">
                    <td>
                      <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                        {{ record.name || record.uuid }}
                      </router-link>
                    </td>
                    <td>{{ record.form }}</td>
                    <td>{{ record.language }}</td>
                    <td>{{ record.phone }}</td>
                    <td>{{ record.email }}</td>
                    <td>{{ record.opened_at | datetime }}</td>
                    <td>
                      {{ record.expires_at | datetime }}

                      <template v-if="record.expired && record.expired === true">
                        <span class="badge badge-danger">EXPIRED</span>
                      </template>
                    </td>
                    <td>{{ record.created_at | datetime }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="loadMore"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

data() {
    return {
        processing: false,
        downloading: false,
        show_load: false,
        records: [],
        downloads: [],
        columns: [
            {key: 'name', label: 'Name'},
            {key: 'form', label: 'Form'},
            {key: 'language', label: 'Language'},
            {key: 'phone', label: 'Phone'},
            {key: 'email', label: 'Email'},
            {key: 'opened_at', label: 'Opened'},
            {key: 'expires_at', label: 'Expires'},
            {key: 'created_at', label: 'Created'}
        ],
        queries: {},
        total: 0,
        form: {},
        form_templates: []
    }
},

created() {
    this.resetForm();
    this.resetRecords();
    this.resetQueries();
    this.setURLParams();
    this.getRecords();
    this.getForms();
},

methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    resetQueries() {
      this.queries = {
        skip: 0,
        limit: 10,
        client: null,
        form: null,
        language: null,
        sort: 'created_at',
        sortDirection: 'desc'
      }
    },

    resetForm() {
      this.form = {
        name: null,
        status: 'draft',
        language: 'en'
      }
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;      
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    setStatusClass(status) {

      if(status == 'private') {
        return 'badge-danger';
      }

      if(status == 'draft') {
        return 'badge-secondary';
      }

      return 'badge-success';
    },

    postForm() {
      this.processing = true;

      this.$http.post('/'+this.$route.meta.base_url, this.form)
      .then(response => {
        if(response && response.data) {
          new this.$noty({text: this.$route.meta.title + ' created'}).show();
          this.records.unshift(response.data);
          this.resetForm();
        }
      })
    },

    getForms() {
      this.$http.get('/forms?slim=true&limit=1000&sort=name&sortDirection=desc&fields=name,uuid')
      .then(response => {
        if(response && response.data) {
          this.form_templates = response.data;
        }
      })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords() {

      this.processing = true;

      this.updateURL();

      this.getTotal();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
          if(response && response.data) {

              this.records = response.data

              if (response.data.length < this.queries.limit) {
                  this.show_load = false
              } else {
                  this.show_load = true
              }

              this.processing = false
          }
      })
    },

    getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
            if(response && response.status) {
                this.total = response.data;
                this.setTitle();
            }
        })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    loadMore() {
      this.processing = true;
      this.show_load = false;

      this.queries.skip = this.records.length;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {

              if(response.data.length < this.queries.limit) {
                this.show_load = false;
              } else {
                this.show_load = true;
              }

              if(response.data.length > 0) {
                response.data.forEach(r => {
                    this.records.push(r);
                    this.total++;
                })                
              }

              this.processing = false;
          } else {
            this.show_load = false;
          }
        });
    },

  async downloadPDF() {
    try {

      window.scrollTo(0,0);

      this.downloading = true;

      const element = document.getElementById('main');

      const canvas = await this.$html2canvas(element, {
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight,
        scrollY: -window.scrollY,
        scrollX: -window.scrollX,
        scale: window.devicePixelRatio,
        useCORS: true,
        logging: false
      });

      const imgData = canvas.toDataURL('image/png');

      const pdf = new this.$jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height]
      });

      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

      var name = this.$options.filters.slugString(this.$route.meta.title+'s-'+this.total+'-'+this.$root.current_user.current_agency.name);

      pdf.save(name+'.pdf');

      this.downloading = false;
    } catch (error) {
      new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
      console.log(error);
      this.downloading = false;
    }
  },

  downloadCSV () {
    this.downloading = true;

    var queries = this.queries;

    queries.limit = this.total;

    queries.skip = this.downloads.length;

    this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
        .then(response => {
          if(response && response.data && response.data) {
            this.downloads = this.downloads.concat(response.data);

            var csvFile, data, link;

            data = this.convertCSV(this.downloads);

            var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';

            csvFile = new Blob([data], {type: "text/csv"});
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(csvFile, file);
            } else {
              link = document.createElement("a");
              link.download = file;
              link.href = window.URL.createObjectURL(csvFile);
              link.style.display = "none";
              document.body.appendChild(link);
              link.click();
            }

            this.downloading = false;

          } else {
            this.downloadCSV();
          }

        });
  },

  convertCSV(records) {

    var results = [];

    if(records[0] && records[0]['uuid']) {
      var headers = Object.keys(records[0]);
    }

    results += headers.join(',')

    results += '\n';

    records.forEach(function(item) {
      var record = '';

      if(!item) {
        return;
      }

      Object.keys(item).forEach(function(key) {

        if(item[key] && key == 'data' && typeof item[key] === 'object') {
          // var data = JSON.parse(item[key]);
          var data = item[key];
          Object.keys(data).forEach(function(k) {
            record += '"' + (data[k] ? data[k] : '') + '",';
          });
          return;
        }

        record += '"' + (item[key] ? item[key] : '') + '",';
      });

      results += record;
      results += '\n';
    });

    return results
  },

}

}
</script>