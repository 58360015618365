<template>
  <div>

    <page-tabs page="uploads" />

    <template v-if="records && records.length > 0">
      <p class="card-title">{{ records.length }} {{ $route.meta.title }}s</p>
    </template>

    <div class="row">

      <template v-for="(record, record_index) in records">

        <div :key="'record_'+record_index" class="col-sm-12 col-md-4">

        <div class="card mb-3">
          <div class="card-body">

            <div class="row mb-3">

              <div class="col-sm-12 col-md-9">
                <p class="card-title"><a href="#" target="_blank">{{ record.name }}</a></p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-3 text-right">
                  <button type="button" class="btn btn-success btn-sm" @click="importRecord(record)" :disabled="processing"><i class="fa fa-download"></i> Import</button>
              </div><!--Col-->

            </div><!--Row-->

            <template v-if="record.fields">

            <p class="fw-bold">{{ Object.keys(record.fields).length }} Mapped Fields</p>

            <ul class="list-group">

              <template v-for="(field, field_index) in record.fields">

                <li :key="'field_'+field_index" class="list-group-item list-group-item-action" v-if="field">{{ field }}
                  <br><span class="badge badge-secondary">{{ field_index }}</span>
                </li>

              </template>

            </ul>

            </template>

          </div><!--Body-->
        </div><!--Card-->

        </div><!--Col-->

      </template>

    </div><!--Row--> 

  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      processing: false,
      records: [],
      queries: {},
      triggers: [],
      templates: [],
      form: {}
    }
  },

  created () {
    this.resetQueries();
    this.resetForm();
    this.getRecords();
  },

  methods: {

    resetQueries() {
      this.queries = {
        slim: false
      }
    },

    resetForm() {
      this.form = {
        name: null 
      }
    },

    getRecords() {
      this.processing = true;
      this.$axios.get('https://ecdassets.s3.amazonaws.com/demo/upload_templates.json')
      .then(response => {
        if(response && response.data) {
          this.records = response.data;
          this.processing = false;
        }
      })
    },

    copyToClipboard(value) {
        navigator.clipboard.writeText(value);
        new this.$noty({text: 'Copied to clipboard'}).show();
    },


    importRecord(record) {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url, record)
      .then(response => {
        if(response && response.data) {
          
          this.processing = false;

          if(response.data.uuid) {
            this.$router.push('/'+this.$route.meta.base_url+'/'+response.data.uuid);
          }

        }
      })
    },

  }
}
</script>
