<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <template v-if="form.amount >= 0">
          <h1>${{ form.amount }}</h1>
        </template>
        <template v-if="form.status">
          <span class="badge badge-pill text-uppercase" :class="setStatusClass(form.status)">{{ form.status }}</span>
        </template>
        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">
        <template v-if="portal && portal.uuid">
          <a :href="setPortalLink()" target="_blank" class="btn btn-outline-secondary" :data-tooltip="'Open '+$route.meta.title"><i class="fa fa-link"></i> Open</a>
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

          <div class="btn-group">
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">

              <a
                  v-if="form.deleted_at || form.archived_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
              >Restore</a>

              <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
              >Trash</a>
            </div>
          </div><!--BtnGroup-->

          <button
              v-if="!form.archived_at && !form.deleted_at"
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
          >
            Save
          </button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-body">

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="amount">Amount</label>
                <div class="input-group">
              <span
                  class="input-group-text"
              >$</span>
                  <input
                      id="amount"
                      v-model="form.amount"
                      type="number"
                      class="form-control"
                      min="0"
                      step="0.01"
                      required
                      readonly
                  >
                </div>
              </div>

              <div class="form-group">
                <label for="quantity">Quantity</label>
                <input id="quantity" type="number" class="form-control" v-model="form.quantity" step="1" min="1" max="100" required readonly>
              </div>

              <div class="form-group">
                <label for="contact">Phone or Email</label>
                <input id="contact" type="text" class="form-control" v-model="form.contact">
              </div>

              <div class="form-group">
                <label for="portal">Payment Portal

                  <template v-if="form.portal">
                    <a
                        :href="'/portals/'+form.portal"
                        target="_blank"
                        data-tooltip="Edit Portal">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                    </a>
                  </template>

                </label>
                <select
                    id="portal"
                    v-model="form.portal"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="">
                    Choose Portal
                  </option>
                  <template v-for="portal in portals">
                    <option
                        :key="portal.uuid"
                        :value="portal.uuid"
                    >
                      {{ portal.name }}
                    </option>
                  </template>
                </select>
              </div>

              <template v-if="!processing && !portals.length">
                <router-link tag="button" :to="{'name': 'portals.index' }" class="btn btn-secondary">Go to Portals</router-link>
              </template>

              <div class="form-group">
                <label for="notes">Internal Notes</label>
                <textarea id="notes" class="form-control" v-model="form.notes"></textarea>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">
            <p class="card-title">Gift Card Details</p>

            <template v-if="form.data">

              <template v-if="form.data.cardNumber">
                <p class="h4 text-uppercase">Claim Code <span class="text-success mr-1">{{ form.data.cardNumber }}</span>
                <button type="button" class="btn btn-secondary btn-sm" @click="copyToClipboard(form.data.cardNumber)"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
                </p>
              </template>

              <template v-if="form.data.pinCode">
                <p class="text-uppercase">Pin Code {{ form.data.pinCode }}</p>
              </template>

              <template v-if="form.data.product">
                <p class="m-0">Product {{ form.data.product.productName }}</p>
                <p class="m-0">Country {{ form.data.product.countryCode }}</p>
                <p class="m-0">Amount ${{ form.data.product.totalPrice }}</p>
                <p class="m-0">Fee $2.00</p>
              </template>

            </template>

          </div><!--Body-->
        </div><!--Card-->

       <template v-if="message && message.uuid">
         <message :message="message" class="my-3" />
       </template>


      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">

            <p class="card-title">How to Redeem</p>

            <template v-if="currentCard">

            <template v-if="currentCard.key">
              <img :src="$root.assets_url+'images/gift_cards/cards/'+currentCard.key+'.png'" class="img-fluid mb-3" width="100" />
            </template>

            <template v-if="currentCard.instructions">
              <p class="m-0">REDEEM INSTRUCTIONS</p>
            </template>

            </template>

          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

    </div><!--Row-->

  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      uploading: false,
      form: {},
      queries: {},
      brand: {},
      message: {},
      portals: [],
      languages: [],
      total: 0,
      brand: {},
      gift_cards: []
    }
  },

  computed: {

    portal() {
      if(!this.form.portal) {
        return {};
      }

      return _.find(this.portals, {uuid: this.form.portal});
    },

    currentCard() {
      if(!this.form.brand) {
        return;
      }

      var card = this.gift_cards.find(card => card.name === this.form.brand);

      if(!card) {
        return;
      }

      return card;
    }

},

  created() {
    this.resetForm();
    this.getRecord();
  },

  methods: {

    resetForm() {
      this.form = {
        amount: null,
        contact: null,
        client: null,
        token: null,
        status: null,
        notes: null
      }
    },


    getPortals() {
      this.$http.get('/portals?slim=true&limit=150&fields=name,uuid,link,domain&domain=ecourtpayment.com')
          .then(response => {
            if(response && response.data) {
              this.portals = response.data;
            }
          })
    },

    getRecord() {

      this.processing = true;

      this.$http.get(this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {

              this.form = response.data;

              this.processing = false;

              this.getPortals();

              this.getGiftCards();

              this.getMessage();

            }
          })
    },

    getMessage() {
      if(!this.form.contact) {
        return;
      }
      if(!this.form.portal) {
        return;
      }

      this.message = {};

      this.$http.get('/messages?type=gift_card&to='+this.form.contact+'&search:content='+this.form.url+'&limit=1&slim=true')
          .then(response => {
            if(response && response.data && response.data.data && response.data.data[0]) {
              this.message = response.data.data[0];
            }
          })
    },


    postForm() {

      this.processing = true;

      this.$http.patch(this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.data.updated_at) {
                this.form.updated_at = response.data.updated_at;
                new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                this.getMessage();
              }

            }
          })
    },


    restoreRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({'text': this.$route.meta.title + ' restored'}).show()
                this.form.deleted_at = null;
                this.form.archived_at = null;
                this.form.updated_at = new Date().toISOString();
              }

            }
          })
    },


    trashRecord () {
      this.processing = true;
      this.$http.delete(this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
    },

    setPortalLink() {
      if(!this.portal || !this.portal.uuid) {
        return;
      }

      return 'https://'+this.portal.link+'.'+this.portal.domain+'/g/'+this.form.url;
    },

    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      new this.$noty({text: 'Copied to clipboard'}).show();
    },

    setStatusClass(status) {
      if(!status) {
        return;
      }

      status = status.toLowerCase();

      if(status == 'successful') {
        return 'badge-primary';
      }

      if(status == 'failed') {
        return 'badge-danger';
      }

      if(status == 'pending') {
        return 'badge-warning';
      }

      if(status == 'activated') {
        return 'badge-success';
      }

      return 'badge-secondary';
    },

    getGiftCards () {
      this.$axios.get(this.$root.assets_url+'js/gift_cards.json')
      .then(response => {
          if(response && response.data) {
              this.gift_cards = response.data;
          }
      })
    }

  }
}
</script>